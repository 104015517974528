
.videoPlayer-controlsContainer{
	position: absolute;
	// bottom: $videoPlayerControlsInset;	
	bottom: 0px;
	left: $videoPlayerControlsInset;	
	right: $videoPlayerControlsInset;

	left: 4px;	
	right: 4px;

	box-sizing: border-box;
	padding-bottom: $videoPlayerControlsInset;
	padding-bottom: 5px;
	z-index: 10;	

	opacity: 1;
	transition: opacity 75ms linear;
}

.videoPlayer-controlsContainer[data-visible-state='false']:not(:hover){
	opacity: 0;
}

.videoPlayer-controls{
	height: 44px;
	width: 100%;

	background: rgba(0,0,0,0.5);
	// backdrop-filter: blur(22px) saturate(110%);

	border-radius: 4px;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;

	position: relative;
}


@import 'VideoPlayer-Controls-SeekBar';



//

$videoPlayControls-bottomOffsetPadding: 4px;

.videoPlayer-controls-btnBar{
	width: 100%;
	height: 100%;
	// background: $tempr;

	box-sizing: border-box;
	padding-top: 9px;
	padding-bottom: 0px;

	display: flex;
	align-items: center;

	box-sizing: border-box;
	// padding-left: 4px;
	// padding-right: 4px;
}

.videoPlayer-controls-playPauseBtn{
	width: 48px;
	box-sizing: border-box;
	height: 100%;	
	padding-bottom: $videoPlayControls-bottomOffsetPadding;

	// background: $tempr;
}

// .videoPlayer-controls-playPauseBtn:hover{
// 	background: rgba(255,255,255,0.05);
// }

.videoPlayer-controls-playPauseBtn svg{
	width: 17px;
	height: 17px;
	fill: white;
	opacity: 0.85;
}

.videoPlayer-controls-playPauseBtn:hover svg{
	opacity: 1;
}

.videoPlayer-controls-playPauseBtn svg.icon--name--playbackPlay{
	position: relative;
	left: 1px;
}

.videoPlayer-controls-hSpacer{
	flex-grow: 1;
	flex-shrink: 1;
}

.videoPlayer-controls-timestamps{
	display: flex;
	cursor: default;
	user-select: none;
	pointer-events: none;
}

.videoPlayer-controls-timestamps-timestamp{
	font-size: 15.25px;

  font-variation-settings: "wght" 480;

  letter-spacing: -0.008em;
  font-feature-settings: "tnum";

  color: var(--text600);

	color: white;
	position: relative;
	top: -0.5px;

	padding-right: 7px;
	padding-bottom: $videoPlayControls-bottomOffsetPadding;

}


.videoPlayer-controls-timestamps-timestamp--total{
	opacity: 0.75;
}



.videoPlayer-controls-btn{
	width: 36px;
	height: 100%;
	box-sizing: border-box;
	padding-bottom: $videoPlayControls-bottomOffsetPadding;
}

.videoPlayer-controls-btn--showCaptions{
	width: 44px;
	padding-right: 0px;
	// padding-left: 4px;
	//margin-left: 4px;
}

.videoPlayer-controls-btn--zoom{
	// background: $tempr;
	width: 55px;
	padding-right: 10px;
	padding-left: 5px;
}

.videoPlayer-controls-btn svg{
	width: 20px;
	height: 20px;
	fill: var(--text500);
	opacity: 0.75;
}

.videoPlayer-controls-btn:hover svg{
	opacity: 1;
}

.videoPlayer-controls-btn svg.icon--name--playbackVolume{
	width: 20px;
	height: 20px;
}

.videoPlayer-controls-btn svg.icon--name--playbackCC{
	width: 22px;
	height: 22px;
}


.videoPlayer-controls-btn svg.icon--name--playbackZoomOnly{
	width: 24px;
	height: 24px;
}


//
// Volume Slider
.videoPlayer-controls-volumeSlider {
  display: flex;
  height: 100%;
}

.videoPlayer-controls-volumeSlider-muteButton {
  width: 34px;
  height: 100%;

}

.videoPlayer-controls-volumeSlider-muteButton-iconContainer svg{
	width: 24px;
	height: 24px;
	fill: var(--text500);
	opacity: 0.8;
}

.videoPlayer-controls-volumeSlider-minBtn,
.videoPlayer-controls-volumeSlider-maxBtn{
	height: 100%;
	width: 12px;
	// background: $tempr;
}

.videoPlayer-controls-volumeSlider-sliderContainer{
	width: 100px;
	height: 100%;	
	position: relative;
}

.videoPlayer-controls-volumeSlider-renderSliderContainer{
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	height: 100%;
	pointer-events: none !important;
}

.videoPlayer-controls-volumeSlider-slider {
  width: 100%;  
  height: 100%;
}

.videoPlayer-controls-volumeSlider-renderSlider{
	position: absolute;
	top: 14px;
	left: 0px;
	width: 100%;
	height: 3px;
	border-radius: 2px;
	overflow: hidden;
}

.videoPlayer-controls-volumeSlider-renderSliderThumb{
	position: absolute;
	top: 10px;	
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: white;
	margin-left: -6px;
	opacity: 0;
	transition: opacity 50ms linear;
	transition-delay: 0ms;
}


.videoPlayer-controls-volumeSlider[data-is-dragging='true']
.videoPlayer-controls-volumeSlider-renderSliderThumb,
.videoPlayer-controls-volumeSlider:hover
.videoPlayer-controls-volumeSlider-renderSliderThumb{
	opacity: 1;
	transition: opacity 50ms linear;
}


.videoPlayer-controls-volumeSlider-renderSlider-activeTrack{
	position: absolute;
	left: 0px;
	top: 0px;
	height: 100%;
	background: rgba(255,255,255,0.6);
}


.videoPlayer-controls-volumeSlider-renderSlider-bgTrack{
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.2);
}


.videoPlayer-controls-volumeSlider-slider-track {
  top: 15px;
  height: 4px;
  
  border-radius: 2px;
  overflow: hidden;
}

.videoPlayer-controls-volumeSlider-slider-track-0{
	background: $invisible;
	// background: rgba(255,255,255,0.8);	
}

.videoPlayer-controls-volumeSlider-slider-track-1 {
  background: none;
}

.videoPlayer-controls-volumeSlider-slider-thumb {
  display: none;
  cursor: pointer;
  background: white;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  outline: none;
  margin-top: 11px;
  // margin-left: 5.5px;
  outline: 1px solid rgba(0,0,0,0.2);
  opacity: 0;
}