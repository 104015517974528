.videoPlayer{
	position: relative;
	width: 100%;
  height: auto; /* Maintain aspect ratio */    
  border-radius: 2px;
  cursor: pointer;  
}

.videoPlayer:fullscreen{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 9999;
}

$videoPlayerControlsInset: 6px;

.videoPlayer-bgPlayPauseBtn{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 2;	
}

.videoPlayer-posterImageContainer{
	pointer-events: none;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 10;	
}

.videoPlayer-posterImageContainer img{
	width: 100%;
	height: 100%;
	border-radius: 3px;
	overflow: hidden;
}


@import 'VideoPlayer-PrePlayControls';
@import 'VideoPlayer-Controls';

.videoPlayer-video{
	border-radius: 3px;
	overflow: hidden;
}

@import 'VideoPlayer-Subtitles';

.videoPlayer[data-hide-pointer='true']:hover,
.videoPlayer[data-hide-pointer='true']:hover *{
	 // outline: 2px solid red;
	 cursor: none !important;
}