
.libraryPage-projectListItem-collectionsContainer{
	min-width: 80px;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.libraryPage-projectListItem-collections{
	display: flex;
	// background: red;
	align-items: center;
	height: 20px;
}

.libraryPage-projectListItem-collections-dot{
	padding: 3px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;	

	position: relative;
	transition: opacity 0ms linear;
}

.libraryPage-projectListItem-collections-dot-dot{
	width: 12px;
	height: 12px;
	border-radius: 2px;
	background: red;
}


// .libraryPage-projectListItem-collections:hover
// .libraryPage-projectListItem-collections-dot:not(:hover){
// 	opacity: 0.25;
// 	transition-delay: 200ms;
// }

// .libraryPage-projectListItem-collections:hover
// .libraryPage-projectListItem-collections-dot:hover{
// 	opacity: 1;
// 	transition-delay: 0ms;
// }



.libraryPage-projectListItem-collections-dot-tooltipContainer{
	position: absolute;
	bottom: 22px;
	left: 50%;
	transform: translateX(-50%);
	visibility: hidden;

	pointer-events: none;
}


.libraryPage-projectListItem-collections
.libraryPage-projectListItem-collections-dot-tooltipContainer{
	transition: opacity 0ms linear;
	opacity: 0;
}

.libraryPage-projectListItem-collections:hover
.libraryPage-projectListItem-collections-dot-tooltipContainer{
	opacity: 1;
	transition-delay: 200ms;
}


.libraryPage-projectListItem-collections-dot:hover
.libraryPage-projectListItem-collections-dot-tooltipContainer{
	visibility: visible;
}

.libraryPage-projectListItem-collections-dot-tooltip{
	font-size: 12px;
  font-weight: $fw-medium;
  font-variation-settings: "wght" 460;
  letter-spacing: 0.014em;
  color: var(--text600);

  box-shadow: var(--shadowTooltip);

  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px;

  border-radius: 3px; 

  max-width: 250px;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  //text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}

.libraryPage-projectListItem-collections-dot-tooltip-label{
	position: relative;
	z-index: 5;
}

.libraryPage-projectListItem-collections-dot-tooltip-bg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	filter: saturate(120%);
}

.libraryPage-projectListItem-collections-dot-tooltip-bg::before{
	content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3); /* Adjust the alpha value to control darkness */
  mix-blend-mode: multiply;

}



.libraryPage-projectListItem-collections-dot--blue
.libraryPage-projectListItem-collections-dot-dot{
	background: var(--collection-blue);
}

.libraryPage-projectListItem-collections-dot--purple
.libraryPage-projectListItem-collections-dot-dot{
	background: var(--collection-purple);
}

.libraryPage-projectListItem-collections-dot--red
.libraryPage-projectListItem-collections-dot-dot{
	background: var(--collection-red);
}

.libraryPage-projectListItem-collections-dot--orange
.libraryPage-projectListItem-collections-dot-dot{
	background: var(--collection-orange);
}

.libraryPage-projectListItem-collections-dot--green
.libraryPage-projectListItem-collections-dot-dot{
	background: var(--collection-green);
}

.libraryPage-projectListItem-collections-dot--pink
.libraryPage-projectListItem-collections-dot-dot{
	background: var(--collection-pink);
}

.libraryPage-projectListItem-collections-dot--teal
.libraryPage-projectListItem-collections-dot-dot{
	background: var(--collection-teal);
}

.libraryPage-projectListItem-collections-dot--darkBlue
.libraryPage-projectListItem-collections-dot-dot{
	background: var(--collection-darkBlue);
}

.libraryPage-projectListItem-collections-dot--blue
.libraryPage-projectListItem-collections-dot-tooltip-bg{
	background: var(--collection-blue);
}

.libraryPage-projectListItem-collections-dot--purple
.libraryPage-projectListItem-collections-dot-tooltip-bg{
	background: var(--collection-purple);
}

.libraryPage-projectListItem-collections-dot--red
.libraryPage-projectListItem-collections-dot-tooltip-bg{
	background: var(--collection-red);
}

.libraryPage-projectListItem-collections-dot--orange
.libraryPage-projectListItem-collections-dot-tooltip-bg{
	background: var(--collection-orange);
}

.libraryPage-projectListItem-collections-dot--green
.libraryPage-projectListItem-collections-dot-tooltip-bg{
	background: var(--collection-green);
}

.libraryPage-projectListItem-collections-dot--pink
.libraryPage-projectListItem-collections-dot-tooltip-bg{
	background: var(--collection-pink);
}

.libraryPage-projectListItem-collections-dot--teal
.libraryPage-projectListItem-collections-dot-tooltip-bg{
	background: var(--collection-teal);
}

.libraryPage-projectListItem-collections-dot--darkBlue
.libraryPage-projectListItem-collections-dot-tooltip-bg{
	background: var(--collection-darkBlue);
}