
//
//

.addFromLibraryDialog-overlay{
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.5);
}

.addFromLibraryDialog{
	// height: 200px;
	// width: 800px;
	padding-top: 4px;
	padding-bottom: 0px;

	// padding-left: 4px;
	// padding-right: 4px;
	border-radius: 6px;

	box-shadow: none;
	// border: 1px solid rgba(255,255,255,0.125) !important;	
	// background: rgba(22,22,32,0.85);
	// backdrop-filter: blur(25px);

	// border: 1px solid rgba(255,255,255,0.125) !important;	
	// background: rgba(22,22,25,0.92);
	// backdrop-filter: blur(25px);

	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);
  box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.28);

  background: rgb(25, 27, 33);
  
	display: flex;
	flex-direction: column;

	position: absolute;
	top: 80px;
	left: 80px;
	right: 80px;
	bottom: 80px;

	overflow: hidden;
}

.addFromLibraryDialog:focus{
	outline: none;
}

.addFromLibraryDialog-measure{
	position: absolute;
	left: 0px;
	width: 100%;
	height: 0px;
	opacity: 0;
}


@import 'AddFromLibraryDialog-Header';
@import 'AddFromLibraryDialog-Scenes';

@import 'AddFromLibraryDialog-ListHeader';

.addFromLibraryDialog-scenesTitle{
	font-size: 15.5px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;

  //margin-top: 24px;
  margin-top: 12px;
  margin-bottom: 8px;

  //margin-left: 60px;
  user-select: none;
  cursor: default;
}

.addFromLibraryDialog-scenesTitle{
	margin-left: 44px;
	opacity: 0.75;
}


.addFromLibraryDialog-scrollContainer{
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: overlay;
}




.addFromLibraryDialog-list{
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 36px;
}



.addFromLibraryDialog-list-list{
	width: calc(100% - 640px);

	display: flex;
	flex-direction: column;

	flex-grow: 0;
	flex-shrink: 0;

	padding-top: 4px;
}


.addFromLibraryDialog-list-list-item{
	
	width: calc(100% - 42px);
	margin-left: 21px;
	border-radius: 5px;
	height: 36px;

	display: flex;
	justify-content: flex-start;

	box-sizing: border-box;
	padding-left: 14px;

	font-size: 14.25px;
	font-size: 13.75px;
  font-weight: $fw-medium;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 460;
  letter-spacing: 0.008em;
}

.addFromLibraryDialog-list-list-item:nth-child(even){
	background: rgba(255,255,255,0.03);
}

.addFromLibraryDialog-list-list-item:hover{
	background: rgba(255,255,255,0.09);
}

// .addFromLibraryDialog-list-list-item:nth-child(even):hover{
// 	background: rgba(255,255,255,0.05);
// }

.addFromLibraryDialog-list-list-exampleBox{
	width: 320px;
	height: 180px;
	outline: 1px solid red;
}

.addFromLibraryDialog-list-list-item-previewContainer{
	width: 32px;
	height: 32px;
	margin-right: 8px;

	display: flex;
	align-items: center;
	justify-content: center;

	
}

.addFromLibraryDialog-list-list-item-preview{
	// width: 32px;
	// height: 24px;
	// background: #4584EA;
	width: 32px;
	height: 32px;
}

img.addFromLibraryDialog-list-list-item-preview-image{
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.addFromLibraryDialog-list-list-item-label{
	margin-right: auto;
	display: flex;
	align-items: center;
}

.addFromLibraryDialog-list-list-item-label-extra{
	margin-left: 5px;
	flex-grow: 0;
	flex-shrink: 0;

	opacity: 0.5;
	font-variation-settings: "wght" 420;
}

.addFromLibraryDialog-list-list-item-type{
	width: 100px;
	text-align: left;
	opacity: 0.5;
	font-variation-settings: "wght" 420;
	font-size: 12.75px;
}

.addFromLibraryDialog-list-list-item-timestamp{
	text-align: left;
	width: 150px;
	opacity: 0.75;
	font-variation-settings: "wght" 420;
	font-size: 12.5px;
}



//
//


.addFromLibraryDialog-grid-itemOuterContainer{
	// background: $tempr;
	// outline: 1px solid red;

	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
}

.addFromLibraryDialog-grid-itemOuterContainer:hover{
	// background: rgba(255,0,0,0.5);
	cursor: pointer;
}

.addFromLibraryDialog-grid-item{
	// background: $tempb;
	background: rgba(255,255,255,0.04);
	outline: 1px solid rgba(255,255,255,0.06);

	border-radius: 2px;
	// outline: 1px solid blue;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	position: relative;
	
	// background: $tempb;
}

.addFromLibraryDialog-grid-itemOuterContainer:hover
.addFromLibraryDialog-grid-item{
	background: rgba(255,255,255,0.08);
	outline: 1px solid rgba(255,255,255,0.10);
}

.popover--addMediaPopover-list-row{
	display: flex;
	width: 100%;
	margin-left: 15px;
}

.addFromLibraryDialog-grid-item-preview{
	// background: $tempr;
	position: relative;
	z-index: 5;
}

.addFromLibraryDialog-grid-item-previewBG{
	position: absolute;
	z-index: 1;
	background: rgba(255,255,255,0.04);
	border-radius: 2px;
}

.addFromLibraryDialog-grid-item-preview-video{
	position: absolute;	
	// background: $tempr;
	width: 100%;
	height: 100%;
}

.addFromLibraryDialog-grid-item-preview-video-duration{
	position: absolute;
	z-index: 200;
	bottom: 1px;
	right: 2px;

	padding-right: 5px;
	padding-left: 5px;
	height: 18px;

	font-size: 12.25px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 500;
  font-feature-settings: "ss01", "ss02";
  letter-spacing: 0.002em;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-top: 2px;
  margin-bottom: 1.5px;

  background: rgba(44,44,50,0.8);

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 2px;

}

.addFromLibraryDialog-grid-item-preview-video-innerContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	overflow: hidden;
	// outline: 1px solid green;
}

.addFromLibraryDialog-grid-item-preview-video-progressLine{
	position: absolute;
	top: 0px;
	height: 100%;
	bottom: 0px;
	width: 2px;
	background: #ED6E75;
	border-radius: 2px;
	opacity: 0;
	z-index: 10;
}

.addFromLibraryDialog-grid-item-preview:hover
.addFromLibraryDialog-grid-item-preview-video-progressLine{
	opacity: 1;
}

.addFromLibraryDialog-grid-item-preview-video-frameStack{
	margin: 0 auto;
}


.addFromLibraryDialog-grid-item-preview-video-frameStack-frame{
	// background: $tempb;
	//outline: 1px solid blue;
}


.addFromLibraryDialog-grid-item-preview-image{
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.addFromLibraryDialog-grid-item-preview-video-frameStack-frame-img{
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.addFromLibraryDialog-grid-item-footer{
	position: absolute;
	bottom: 0px;
	left: 0px;
	z-index: 2;
	width: 100%;	

	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 10px;

	display: flex;
	flex-direction: column;	

	

	
}

.addFromLibraryDialog-grid-item-footer-title{
	font-size: 13.5px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 490;
  letter-spacing: 0.008em;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-top: 3.5px;
  margin-bottom: 1.5px;
}

.addFromLibraryDialog-grid-item-footer-title-sub{
	margin-left: 0.1em;
	opacity: 0.75;
}

.addFromLibraryDialog-grid-item-footer-subtitle{
	font-size: 12.5px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 400;
  letter-spacing: 0.008em;

  opacity: 0.65;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



.addFromLibraryDialog-grid-item-preview-linkedAddButton{
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 200;

	display: flex;
	padding: 3px;
}

.addFromLibraryDialog-grid-item-preview-summary{
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 150;

	display: flex;
	padding: 3px;
}


.addFromLibraryDialog-grid-item-preview-linkedAddButton-inner,
.addFromLibraryDialog-grid-item-preview-summary-inner{	
	height: 20px;
	padding-right: 8px;
	padding-left: 3px;

	background: rgba(32,32,36,0.8);
	border: 1px solid $invisible;
	border-radius: 3px;

	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
}

.addFromLibraryDialog-grid-item-preview-linkedAddButton:hover
.addFromLibraryDialog-grid-item-preview-linkedAddButton-inner,
.addFromLibraryDialog-grid-item-preview-summary:hover
.addFromLibraryDialog-grid-item-preview-summary-inner{
	background: rgba(32,32,36,1);
	border: 1px solid rgba(50,50,60,1);
}

.addFromLibraryDialog-grid-item-preview-linkedAddButton-iconOuterContainer,
.addFromLibraryDialog-grid-item-preview-summary-iconOuterContainer{
	width: 22px;
	height: 22px;
	position: relative;
	opacity: 0.75;
}

.addFromLibraryDialog-grid-item-preview-linkedAddButton:hover
.addFromLibraryDialog-grid-item-preview-linkedAddButton-iconOuterContainer,
.addFromLibraryDialog-grid-item-preview-summary:hover
.addFromLibraryDialog-grid-item-preview-summary-iconOuterContainer{
	opacity: 1;
}

.addFromLibraryDialog-grid-item-preview-linkedAddButton-iconContainer,
.addFromLibraryDialog-grid-item-preview-summary-iconContainer{
	position: absolute;

	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}

.addFromLibraryDialog-grid-item-preview-linkedAddButton
.addFromLibraryDialog-grid-item-preview-linkedAddButton-iconContainer--default,
.addFromLibraryDialog-grid-item-preview-summary-iconContainer{
	opacity: 1;
}

.addFromLibraryDialog-grid-item-preview-linkedAddButton
.addFromLibraryDialog-grid-item-preview-linkedAddButton-iconContainer--hover{
	opacity: 0;
}

.addFromLibraryDialog-grid-item-preview-linkedAddButton:hover
.addFromLibraryDialog-grid-item-preview-linkedAddButton-iconContainer--default{
	opacity: 0;
}

.addFromLibraryDialog-grid-item-preview-linkedAddButton:hover
.addFromLibraryDialog-grid-item-preview-linkedAddButton-iconContainer--hover{
	opacity: 1;
}

.addFromLibraryDialog-grid-item-preview-linkedAddButton-inner svg,
.addFromLibraryDialog-grid-item-preview-summary-iconContainer svg{
	width: 14px;
	height: 14px;

	fill: white;
	opacity: 0.75;
	transform: scale(1.02);
}



.addFromLibraryDialog-grid-item-preview-linkedAddButton-label,
.addFromLibraryDialog-grid-item-preview-summary-label{
	font-size: 12px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 500;  
  letter-spacing: 0.002em;
}

.addFromLibraryDialog-grid-item-preview-linkedAddButton
.addFromLibraryDialog-grid-item-preview-linkedAddButton-label--hover{
	display: none;
	margin-right: 0.25em;
}


.addFromLibraryDialog-grid-item-preview-linkedAddButton:hover
.addFromLibraryDialog-grid-item-preview-linkedAddButton-label--hover{
	display: flex;
}



.addFromLibraryDialog-grid-item-preview-summaryContents{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 100;
	

	opacity: 0;


	

  
  pointer-events: none;
}

.addFromLibraryDialog-grid-item-preview-summaryContents-label{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	box-sizing: border-box;
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 56px;

	font-size: 13px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 450;  
  letter-spacing: 0.002em;

  white-space: pre-wrap;

  display: flex;
  flex-direction: column;
  gap: 10px; 

}

.addFromLibraryDialog-grid-item-preview-summaryContents-bg{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	background: darken(#333539, 20%);
}

.addFromLibraryDialog-grid-item-preview-summaryContents
.addFromLibraryDialog-grid-item-preview-summaryContents-bg,
.addFromLibraryDialog-grid-item-preview-summaryContents
.addFromLibraryDialog-grid-item-preview-summaryContents-label{
	transition: opacity 150ms linear;
  transition-delay: 50ms;
  opacity: 0;
}

.addFromLibraryDialog-grid-item-preview-summaryContents
.addFromLibraryDialog-grid-item-preview-summaryContents-label{
	z-index: 10;
}

button.addFromLibraryDialog-grid-item-preview-summary:hover +
.addFromLibraryDialog-grid-item-preview-summaryContents{
	opacity: 1;
}

button.addFromLibraryDialog-grid-item-preview-summary:hover +
.addFromLibraryDialog-grid-item-preview-summaryContents
.addFromLibraryDialog-grid-item-preview-summaryContents-bg{
	opacity: 0.8;
	transition: opacity 100ms linear;
	transition-delay: 100ms;
}

button.addFromLibraryDialog-grid-item-preview-summary:hover +
.addFromLibraryDialog-grid-item-preview-summaryContents
.addFromLibraryDialog-grid-item-preview-summaryContents-label{
	opacity: 1;
	transition: opacity 100ms linear;
	transition-delay: 100ms;
}




// .addFromLibraryDialog-header{
// 	padding-top: 6px;
// 	padding-bottom: 6px;
// 	padding-left: 5px;
// 	padding-right: 5px;

// 	box-sizing: border-box;
// 	width: 100%;

// 	flex-grow: 0;
// 	flex-shrink: 0;
// }

// //

// .addFromLibraryDialog-listContainer{
// 	position: relative;
// }

// .addFromLibraryDialog-list{	
	
// 	max-height: 340px;
// 	max-height: 480px;
// 	overflow-y: overlay;
// 	overflow-x: hidden;
// }

// .addFromLibraryDialog-list-row{
// 	display: flex;
// }

// //
// //

// $bg-itemContainer: rgba(255,255,255,0.03);
// $bg-itemContainerHover: rgba(255,255,255,0.10);

// .addFromLibraryDialog-itemOuterContainer{
// 	position: relative;

// 	box-sizing: border-box;	
// }

// .addFromLibraryDialog-itemContainer{
// 	background: $bg-itemContainer;
// 	border-radius: 5px;
// 	cursor: pointer;

// 	width: 100%;
// 	height: 100%;

// 	box-sizing: border-box;	

// 	position: relative;
// }

// .addFromLibraryDialog-itemContainer:hover{
// 	background: $bg-itemContainerHover;
// }


// .addFromLibraryDialog-item--image{
// 	display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 100%;
// }

// .addFromLibraryDialog-item--image--transparency--darkImage{
// 	background: repeating-conic-gradient(rgb(255,255,255) 0% 25%, rgb(240,240,245) 0% 50%) 50% / 20px 20px;
// 	border-radius: 2px;
// }

// .addFromLibraryDialog-item--image--transparency--lightImage{
// 	background: repeating-conic-gradient(transparent 0% 25%, rgba(255,255,255, 0.05) 0% 50%) 50% / 20px 20px;
// 	border-radius: 2px;
// }

// .addFromLibraryDialog-item--image img{		
//   border-radius: 2px;
//   position: relative;
//   z-index: 5;
// }

// // .addFromLibraryDialog-item--image-transparentBG {
// //   // background: 
// //   //   repeating-conic-gradient(rgb(255,255,255) 0% 25%, rgb(240,240,245) 0% 50%) 
// //   //     50% / 20px 20px;

// //       background: 
// //     repeating-conic-gradient(transparent 0% 25%, rgba(255,255,255, 0.05) 0% 50%) 
// //       50% / 20px 20px;
// //   position: absolute;
// //   left: 0px;
// //   right: 0px;
// //   top: 0px;
// //   bottom: 0px;
// //   margin: auto;
// //   z-index: 1;

// //   opacity: 0;
// // }

// .addFromLibraryDialog-item--image-transparentBG--darkImage{

// }

// .addFromLibraryDialog-item--image-transparentBG--lightImage{

// }






// .addFromLibraryDialog-item--video-previewContainer video{
// 	border-radius: 2px;
// 	position: relative;
// 	top: 1px;
// }



// .addFromLibraryDialog-item--upload-iconContainer{

// }

// // .addFromLibraryDialog-item--image{
// // 	//width: 190px;
// // 	height: 90px;
// // 	width: 120px;

// // 	height: 100px;
// // 	width: 130px;
// // }

// .addFromLibraryDialog-item--video{
// 	position: relative;
// }


// .addFromLibraryDialog-itemContainer{
	
// }

// // .addFromLibraryDialog-item--upload{
// // 	background: rgba(255,255,255,0.10);
// // 	display: flex;
// // 	align-items: center;
// // 	justify-content: center;
// // 	width: 110px;
// // }





// // .addFromLibraryDialog-item--library{
// //  	// object-fit: contain;
// //  	box-sizing: border-box;
// //  	padding-top: 6px;
// //  	padding-left: 6px;
// //  	padding-right: 6px;
// //  	padding-bottom: 6px;
// // }


// //

// .addFromLibraryDialog-item--video{
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }





// .addFromLibraryDialog-item--video-videoDuration{
// 	position: absolute;
// 	bottom: 8px;
// 	right: 8px;

// 	padding-right: 4px;
// 	padding-left: 4px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	height: 20px;

// 	font-size: 12px;
// 	font-weight: $fw-medium;
// 	color: var(--text400);
// 	letter-spacing: 0.001em;

// 	border-radius: 3px;

// 	background: rgba(20,20,30,0.65);
// 	backdrop-filter: blur(5px);

// 	z-index: 10;

// 	opacity: 1;
// }

// .addFromLibraryDialog-itemContainer:hover
// .addFromLibraryDialog-item--video-videoDuration{
// 	opacity: 1;
// 	color: var(--text600);
// }


// //
// //

// .addFromLibraryDialog-footer{
// 	width: 100%;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	padding-bottom: 3px;
// 	padding-top: 3px;

// 	flex-grow: 0;
// 	flex-shrink: 0;
// }

// .addFromLibraryDialog-uploadBtn{
// 	width: 320px;
// 	height: 32px;
// 	border-radius: 5px;	
// 	background: rgba(255,255,255,0.05);
// }

// .addFromLibraryDialog-uploadBtn--large{
// 	height: 48px;
// }

// .addFromLibraryDialog-uploadBtn[data-state="hidden"]{
// 	visibility: hidden;
// 	pointer-events: none;
// }

// .addFromLibraryDialog-uploadBtn svg{
// 	width: 30px;
// 	height: 30px;
// 	fill: white;
// 	opacity: 0.8;

// 	position: relative;
// 	top: 1px;
// }

// .addFromLibraryDialog-uploadBtn--large svg{
// 	width: 34px;
// 	height: 34px;
// 	fill: white;

// 	position: relative;
// 	top: 1px;
// }

// .addFromLibraryDialog-uploadBtn:hover svg{
// 	opacity: 1;	
// }

// .addFromLibraryDialog-uploadBtn svg.icon--name--laptopUpload #laptop,
// .addFromLibraryDialog-uploadBtnLarge svg.icon--name--laptopUpload #laptop{	
// 	opacity: 0.5;	
// }

// .addFromLibraryDialog-uploadBtn:hover svg.icon--name--laptopUpload #laptop{
// 	opacity: 0.75;
// }

// .addFromLibraryDialog-uploadBtn 
// svg.icon--name--laptopUpload #arrow{
// 	opacity: 1;
// }

// //

// .addFromLibraryDialog-onboarding{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	width: 100%;
// 	height: calc(100% + 32px);

// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// }

// .addFromLibraryDialog-onboarding-vSpacer{
// 	flex-grow: 1;
// 	flex-shrink: 1;
// 	min--height: 10px;
// }

// .addFromLibraryDialog-onboarding-prefillGallery{
// 	width: 100%;
// 	display: flex;
// 	flex-wrap: wrap;

// }
// .addFromLibraryDialog-onboarding-prefillGallery-item{
// 	width: 25%;
// 	flex-grow: 0;
// 	flex-shrink: 0;

// 	box-sizing: border-box;
// 	padding: 4px;
// }

// .addFromLibraryDialog-onboarding-prefillGallery-item-inner{
// 	width: 100%;
// 	border-radius: 4px;
// 	background: $bg-itemContainer;

// 	display: flex;
// 	flex-direction: column;

// 	box-sizing: border-box;
// 	padding: 6px;

// 	position: relative;
// }

// .addFromLibraryDialog-onboarding-prefillGallery-item-inner:hover{
// 	background: $bg-itemContainerHover;
// }

// .addFromLibraryDialog-onboarding-prefillGallery-item-inner-imageContainer,
// .addFromLibraryDialog-onboarding-prefillGallery-item-inner-videoContainer{
// 	width: 100%;
// 	aspect-ratio: 16/10;
// 	position: relative;
	
// 	border-radius: 4px;
// 	overflow: hidden;
// }

// .addFromLibraryDialog-onboarding-prefillGallery-item-inner-imageContainer img,
// .addFromLibraryDialog-onboarding-prefillGallery-item-inner-videoContainer video{	
// 	width: 100%;
// 	height: 100%;
// 	object-fit: cover;
// 	border-radius: 4px;
// 	filter: brightness(0.7); /* Adjust the value to make the image darker or lighter */	
// }



// .addFromLibraryDialog-onboarding-prefillGallery-item-inner:hover
// .addFromLibraryDialog-onboarding-prefillGallery-item-inner-imageContainer img,
// .addFromLibraryDialog-onboarding-prefillGallery-item-inner:hover
// .addFromLibraryDialog-onboarding-prefillGallery-item-inner-videoContainer video{
// 	filter: brightness(1);
// }

// .addFromLibraryDialog-onboarding-prefillGallery-item-inner-label{
// 	width: 100%;
// 	// text-align: left;
// 	// padding-top: 7px;

// 	font-size: 13.5px;
// 	font-weight: $fw-medium;
// 	color: var(--text500);

// 	font-family: system-ui, sans-serif;
//   font-variation-settings: "wght" 440;
//   letter-spacing: 0.008em;
  
//   position: absolute;
//   bottom: 12px;
//   left: 0px;
// }

// // .addFromLibraryDialog-onboarding-prefillGallery-item-inner:hover
// // .addFromLibraryDialog-onboarding-prefillGallery-item-inner-label{
// // 	opacity: 0;
// // }

// //
// //

// // .addFromLibraryDialog-star{
// // 	position: absolute;
// // 	top: 10px;
// // 	right: 10px;

// // 	width: 24px;
// // 	height: 24px;

// // 	border-radius: 50%;

// // 	background: rgba(255,255,255,0.10);
	
// // }

// // .addFromLibraryDialog-star svg{
// // 	width: 16px;
// // 	height: 16px;
// // 	fill: var(--text600);
// // }

// // //



// // //

// // .addFromLibraryDialog-star[data-state='starred']{
// // 	background: rgba(255,255,255,0.15);	
// // }

// // .addFromLibraryDialog-star[data-state='starred'] svg{
// // 	opacity: 0.9;
// // }

// // .addFromLibraryDialog-star[data-state='starred']:hover{
// // 	background: rgba(255,255,255,0.2);	
// // }

// // .addFromLibraryDialog-star[data-state='starred']:hover svg{
// // 	opacity: 1;
// // }

// // //

// // .addFromLibraryDialog-star[data-state='notStarred']{
// // 	background: rgba(255,255,255,0.05);	
// // 	opacity: 0;
// // }

// // .addFromLibraryDialog-itemOuterContainer:hover
// // .addFromLibraryDialog-star[data-state='notStarred']{
// // 	opacity: 1;
// // }

// // .addFromLibraryDialog-star[data-state='notStarred'] svg{	
// // 	opacity: 0.25;
// // }

// // .addFromLibraryDialog-star[data-state='notStarred']:hover{
// // 	background: rgba(255,255,255,0.15);	
// // }

// // .addFromLibraryDialog-star[data-state='notStarred']:hover svg{
// // 	opacity: 0.75;
// // }

