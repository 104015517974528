.recordingOverlay{
	position: absolute;
	top: 0px;
	left: 0px;

	width: 100%;
	height: 100%;


	
}

.recordingOverlay-window{
	//background: rgba(255,0,0,0.05);
	
}

.recordingOverlay-window:hover{
	background: rgba(0,0,0,0.2);
	outline: 1px solid white;
	border-radius: 12px;

	display: flex;
}

.recordingOverlay-window-recordBtn{
	width: 200px;
	height: 100px;
	background: black;
	border-radius: 4px;

	color: var(--text500);
	opacity: 0.8;
}

.recordingOverlay-window-block{
	
	background: rgb(0,0,0,0.6);
	opacity: 0;
	position: fixed;

	pointer-events: none;
}

.recordingOverlay-window:hover
.recordingOverlay-window-block{
	opacity: 1;
	opacity: 0;
}