.videoPlayer-seekBar{
	position: absolute;
	top: -9px;
	height: 18px;
	// background: $tempr;
	width: 100%;
	left: 0px;
}

.videoPlayer-seekBar-seekBar{
	height: 100%;
	// background: blue;
}

.videoPlayer-seekBar-seekBar-track{
	height: 5px;
	margin-top: 9px;
	background: none;
}

.videoPlayer-seekBar-seekBar-track-0{
	background: green;
	background: #57A4FC;
	background: #8eabff;
	background: #6C64D2;
	background: #8980F3;
	background: #DA483C; // youtube red
	background: #f6768f; // a bit more tomatoee
	border-top-left-radius: 2px;
	border-bottom-left-radius: 0px;
}

.videoPlayer-seekBar-seekBar-track-1{	
	background: rgba(255,255,255,0.18);
	border-top-right-radius: 2px;
	border-bottom-right-radius: 0px;
	//border-radius: 0px;
	//background: rgb(75,75,89);
}

//


.videoPlayer-seekBar-marker{
	position: absolute;
	top: 0px;
	height: 10px;
	width: 3px;
	margin-left: -1px;

	background: white;
	outline: 1px solid rgba(0,0,0,0.12);

	pointer-events: none;
	border-radius: 2px;
	margin-top: 7px;

	transition: opacity 0ms linear;

}

.videoPlayer-seekBar-marker[data-state="visible"]{
	opacity: 1;
	transition-delay: 100ms;
}

.videoPlayer-seekBar-marker[data-state="hidden"]{
	opacity: 0;
	transition-delay: 100ms;
}



.videoPlayer-seekBar-hoverPreview{
	position: absolute;

	background: $white;
	
	pointer-events: none;
	border-radius: 2px;

	outline: 1px solid rgba(200,200,200,1);
	box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.12);

	border: none;
	overflow: hidden;
}


.videoPlayer-seekBar-hoverPreview-storyboardImageOuterContainer{
	width: 100%;
	height: 100%;
	// border-radius: 2px;

	// overflow: hidden;
}


.videoPlayer-seekBar-hoverPreview-storyboardImage-image{
	transform-origin: 0% 0%;
}

///

.videoPlayer-seekBar-hoverPreview-timestampContainer{
	position: absolute;
	bottom: 2px;
	left: 0px;
	right: 0px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.videoPlayer-seekBar-hoverPreview-timestamp{
	
	line-height: 1.3;

	background: rgba(0,0,0,0.75);
	//outline: 2px solid rgba(255,255,255,0.15);
	//border: 1px solid rgb(0,0,0,0.85);
	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--text500);

	font-size: 13px;
	font-weight: $fw-regular;
	font-variation-settings: "wght" 490;
	letter-spacing: 0.001em;

	height: 19px;
	width: 40px;
  
	background: lighten(rgba(29,29,31,0.9), 1%);	
	font-feature-settings: "tnum";
}