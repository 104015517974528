
@font-face {
    font-family: 'YBasierCircle';
    src: url(../fonts/YBasierCircle/YBasierCircle-Light.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-light;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YBasierCircle';
    src: url(../fonts/YBasierCircle/YBasierCircle-Regular.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-regular;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YBasierCircle';
    src: url(../fonts/YBasierCircle/YBasierCircle-Medium.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-medium;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YBasierCircle';
    src: url(../fonts/YBasierCircle/YBasierCircle-Semibold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-semibold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YBasierCircle';
    src: url(../fonts/YBasierCircle/YBasierCircle-Bold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YBasierCircle';
    src: url(../fonts/YBasierCircle/YBasierCircle-Heavy.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-heavy;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YBasierCircle';
    src: url(../fonts/YBasierCircle/YBasierCircle-Black.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-black;
    text-rendering: optimizeLegibility;
}
