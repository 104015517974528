.editor-timeline-clip--draggableHandle{
  // background: red;
  width: 100%;
  height: 100%;
}

.editor-timeline-clip-handle{
  position: absolute;
  width: 8px;

  margin-top: -1px;
  height: calc(100% + 2px);

  z-index: 200;
  transition: opacity 0ms linear;

}

.editor-timeline-clip
.editor-timeline-clip-handle--left{
  left: -1px;
}

.editor-timeline-clip
.editor-timeline-clip-handle--right{
  right: -1px;  
}


.editor-timeline-clip-handle[data-state='invisible']{
  visibility: hidden;
}

.editor-timeline-clip-handle-inner{
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
}

.editor-timeline-clip-handle-inner-bar{
  width: 3px;
  background: red;
  height: calc(100% - 8px);
  border-radius: 2px;

  background: white;
  opacity: 0.8;
}

.editor-timeline-clip
.editor-timeline-clip-handle--left
.editor-timeline-clip-handle-inner{
  padding-left: 1px;
}

.editor-timeline-clip
.editor-timeline-clip-handle--right{
  padding-right: 1px;
}





.editor-timeline-clip--zoom
.editor-timeline-clip-handle{
  margin-top: -3px;
  width: 10px;
  height: calc(100% + 6px);
}


.editor-timeline-clip--zoom
.editor-timeline-clip-handle
.editor-timeline-clip-handle-inner{
  top: 3px;
  height: calc(100% - 6px);  
  width: calc(100% - 1px);  
}


.editor-timeline-clip--zoom
.editor-timeline-clip-handle-inner-bar{
  height: calc(100% - 6px);
  width: 2px;  
}


.editor-timeline-clip--zoom
.editor-timeline-clip-handle--left
.editor-timeline-clip-handle-inner{
  left: 1px;
  box-sizing: border-box;
  padding-left: 1px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.editor-timeline-clip--zoom
.editor-timeline-clip-handle--right
.editor-timeline-clip-handle-inner{
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  right: 1px;
  box-sizing: border-box;
  padding-right: 1px;
}



// .editor-timeline-clip--zoom
// .editor-timeline-clip-handle-inner{
//   opacity: 1 !important;
//   background: lighten(rgb(25,27,33), 14%)  
// }


//
//


.editor-timeline-clip--render[data-clip-active='false'][data-clip-hovered='false']
.editor-timeline-clip-handle[data-hovered-state='false']
.editor-timeline-clip-handle-inner-bar{
  transition: opacity 50ms linear;
  opacity: 0;
  background: white !important;
}


.editor-timeline-clip--render[data-clip-active='false'][data-clip-hovered='true']
.editor-timeline-clip-handle[data-hovered-state='false']
.editor-timeline-clip-handle-inner-bar{
  transition: opacity 50ms linear;
  opacity: 0.2;
  //background: white !important;
}


.editor-timeline-clip--render[data-clip-active='false'][data-clip-hovered='true']
.editor-timeline-clip-handle[data-hovered-state='true']
.editor-timeline-clip-handle-inner-bar{
  transition: opacity 50ms linear;
  opacity: 0.4;
  //background: white !important;
}


.editor-timeline-clip--render[data-clip-active='true']
.editor-timeline-clip-handle[data-hovered-state='false']
.editor-timeline-clip-handle-inner-bar{
  transition: opacity 50ms linear;
  opacity: 0.6;
  //background: white !important;
}

.editor-timeline-clip--render[data-clip-active='true']
.editor-timeline-clip-handle[data-hovered-state='true']
.editor-timeline-clip-handle-inner-bar{
  transition: opacity 50ms linear;
  opacity: 0.9 !important;
  // background: white !important;
}

.editor-timeline-clip--render
.editor-timeline-clip-handle[data-resizing-state='true']
.editor-timeline-clip-handle-inner-bar{
  transition: opacity 50ms linear;
  opacity: 0.9 !important;
  background: white !important;
}


.editor-timeline-clip-handle[data-clip-type='chart']
.editor-timeline-clip-handle-inner-bar{
  background: lighten(rgba(255,169,77,1), 8%);  
}

.editor-timeline-clip-handle[data-clip-type='textSlide']
.editor-timeline-clip-handle-inner-bar{
  background: lighten(#437ce1, 18%);  
}

.editor-timeline-clip-handle[data-clip-type='screenVideo']
.editor-timeline-clip-handle-inner-bar{
  background: lighten(rgba(110,99,217,1), 16%);  
}

.editor-timeline-clip-handle[data-clip-type='basicVideo']
.editor-timeline-clip-handle-inner-bar,
.editor-timeline-clip-webcamPhase-handle-bar[data-clip-type='basicVideo']{
  background: lighten(#B47190, 18%);  
}

.editor-timeline-clip-handle[data-clip-type='image']
.editor-timeline-clip-handle-inner-bar{
  background: lighten(desaturate(rgb(48,145,72), 24%), 18%);
}

.editor-timeline-clip-handle[data-clip-type='webcamInstructions']
.editor-timeline-clip-handle-inner-bar{
  background: #ffa78e95;
}

.editor-timeline-clip-handle[data-clip-type='webcamScript']
.editor-timeline-clip-handle-inner-bar{
  background: rgba(255,255,255,0.3);
}

.editor-timeline-clip-handle[data-clip-type='webcamRecording']
.editor-timeline-clip-handle-inner-bar{
  background: lighten(#1D282B, 24%);
}



.editor-timeline-clip--render[data-clip-type='linkedWebcamRecording']
.editor-timeline-clip-handle-inner-bar{  
  background: lighten(desaturate(rgba(110,99,217,0.70), 4%), 13%);  
}


// .editor-timeline-clip--zoom
// .editor-timeline-clip-handle{
//   opacity: 0.1;
// }

// .editor-timeline-clipDrag:hover + .editor-timeline-clip--inactive .editor-timeline-clip-handle{
//   opacity: 0.8;
//   transition-delay: 200ms;
// }





// .editor-timeline-clip--inactive
// .editor-timeline-clip-handle:hover{
//   opacity: 0.8;
// }
