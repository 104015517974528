//
// LINES
// might tweak for more harmony - i.e. saturation levels are quite different

:root{
	--line700: hsl(240, 5%, 72%); // lineExtraDark
	--line600: hsl(240, 5%, 76%); // lineExtraDark
	--line550: hsl(240, 7%, 79%); // lineDarkDark
	--line500: hsl(240, 8%, 82%); // lineDarkDark
	--line450: hsl(240, 4%, 83.5%); // lineDark
	--line400: hsl(240, 4%, 85%); // lineDark
	--line375: hsl(240, 3%, 86%); // lineMidDark
	--line325: hsl(240, 12%, 90%); // lineMid
	--line300: hsl(240, 14%, 93%); // lineMid
	--line250: hsl(240, 14%, 94%); // lineLight
	--line200: hsl(240, 14%, 95%); // lineLight
	--line150: hsl(240, 14%, 96%); // lineLight
	--line100: hsl(240, 14%, 96%); // lineLight
	--line50: hsl(240, 14%, 97%); // lineLight
	//--line200: ;
	//--line100: ;
	--line000: hsl(0, 0%, 100%); // lineLightAbsolute

	--lineDocDivider: rgba(214,214,216,0.8);
}




// // TO DO - DARK THEME
// // goes brighter as you go up the stack, i.e. 600 is brightest white, 000 is pure black

[data-theme="dark"], .forceDarkTheme{
	--line700: rgb(255,255,255,0.38); // lineExtraDark
	--line600: rgb(255,255,255,0.36); // lineExtraDark
	--line550: rgb(255,255,255,0.32); // 
	--line500: rgb(255,255,255,0.28); // 
	--line450: rgb(255,255,255,0.265); // 
	--line400: rgb(255,255,255,0.25); // 
	--line375: rgb(255,255,255,0.235); // 
	--line325: rgb(255,255,255,0.210); // 
	--line300: rgb(255,255,255,0.2); // 
	--line250: rgb(255,255,255,0.175); // 
	--line200: rgb(255,255,255,0.15); // 
	--line150: rgb(255,255,255,0.10); // 
	--line100: rgb(255,255,255,0.05); // 
	--line50: rgb(255,255,255,0.03); // 
	//--line200: ;
	//--line100: ;
	--line000: ; // lineLightAbsolute

	--lineDocDivider: rgb(255,255,255,0.15);
}