

.dpToggleGrid-container {
  display: grid;
  width: 100%;
  border-radius: 3px;
  //background: var(--254gray);
}

.dpToggleGrid-container[data-disabled='true']{
	pointer-events: none;
	opacity: 0.5;
}

.dpToggleGrid-container--1wide {
  grid-template-columns: 1fr;
}

.dpToggleGrid-container--2wide {
  grid-template-columns: 1fr 1fr;
}

.dpToggleGrid-container--3wide {
  grid-template-columns: 1fr 1fr 1fr;
}

.dpToggleGrid-container--4wide {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.dpToggleGrid-container--1tall {
  grid-template-rows: $height-dpElement;
}

.dpToggleGrid-container--2tall {
  grid-template-rows: $height-dpElement $height-dpElement;
}

.dpToggleGrid-item {
  height: $height-dpElement;
  font-size: $baseFontSize-dpElement;	
	color: var(--text500);
	
  font-variation-settings: "wght" 500;
  letter-spacing: 0.008em;  

  border-radius: 3px;
}

.dpToggleGrid-item-iconContainer{
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dpToggleGrid-item--mixedLayout
.dpToggleGrid-item-iconContainer{
	margin-left: -4px;
}

.dpToggleGrid-item-iconContainer svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.75;
}


.dpToggleGrid-container[data-item-type='iconOnly']
.dpToggleGrid-item-iconContainer svg{
	width: 18px;
	height: 18px;
	transform: scale(0.95);
}

.dpToggleGrid-item-iconContainer svg.icon--name--fitImage,
.dpToggleGrid-item-iconContainer svg.icon--name--fillImage{
	width: 18px;
	height: 18px;
	position: relative;
	left: -2px;
}

.dpToggleGrid-container
.dpToggleGrid-item-iconContainer svg.icon--name--alignItems{
	width: 12px;
	height: 12px;
	opacity: 0.75;
}


.dpToggleGrid-item-iconContainer svg.icon--name--chartBar,
.dpToggleGrid-item-iconContainer svg.icon--name--chartDonut{
	width: 18px;
	height: 18px;
	position: relative;
	left: -2px;
}

.dpToggleGrid-item-iconContainer svg.icon--name--chartDonut #bg{
	opacity: 0.25;
}


.dpLayoutBox-rightContainer
.dpToggleGrid-item-iconContainer svg{

}

.dpLayoutBox-rightContainer
.dpToggleGrid-item-iconContainer svg.icon--name--arrowDown{
	position: relative;
	right: -2px;
}

.dpLayoutBox-rightContainer
.dpToggleGrid-item-iconContainer{
	margin-left: -8px;
}

.dpLayoutBox-rightContainer
.dpToggleGrid-item:first-child
.dpToggleGrid-item-iconContainer{
	margin-left: -10px;
}


.dpToggleGrid-item-iconContainer svg.icon--name--fitImage #back,
.dpToggleGrid-item-iconContainer svg.icon--name--fillImage #back{
	opacity: 0.1;
}

.dpToggleGrid-item[data-active-state='true']
.dpToggleGrid-item-iconContainer svg.icon--name--fitImage,
.dpToggleGrid-item[data-active-state='true']
.dpToggleGrid-item-iconContainer svg.icon--name--fillImage{
	opacity: 1;
}


.dpToggleGrid-container[data-disabled='true']
.dpToggleGrid-item[data-active-state='true']
.dpToggleGrid-item-iconContainer svg.icon--name--fitImage,
.dpToggleGrid-container[data-disabled='true']
.dpToggleGrid-item[data-active-state='true']
.dpToggleGrid-item-iconContainer svg.icon--name--fillImage,{
	opacity: 0.7;
}




.dpToggleGrid-item[data-active-state='true'],
.dpToggleGrid-item[data-active-state='true']:hover{
  color: var(--text600);  
  background: var(--250gray);
}

.dpToggleGrid-item[data-active-state='false']{
	opacity: 0.6;
}

.dpToggleGrid-item[data-active-state='false']:hover{
	opacity: 0.8;
	//background: var(--251gray);
}

.dpToggleGrid-item:disabled,
.dpToggleGrid-item:disabled:hover{
	opacity: 0.4 !important;

}



.editor-detailPanel-header-2rowToggleBottonVSpacer{
	height: calc(($height-dpElementRow - #{$height-dpElement})/2);
}