

//
// STATIC

.editor-transcriptPanel-staticHeader{
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	box-sizing: border-box;
	padding-left: 26px;
	padding-right: 13px;

	margin-bottom: 8px;

	position: relative;
	z-index: 12000;

	flex-grow: 0;
	flex-shrink: 0;
}

// .editor-transcriptPanel-staticHeader:hover{
// 	background: $tempb;
// }

.editor-transcriptPanel-staticHeader-titleBtnContainer{
	margin-left: -8px;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% + 16px);
}


.editor-transcriptPanel-staticHeader-bottomBorder{
	height: 1px;
	background: var(--line100);
	
	width: 100%;

	margin-top: 4px;
}


//
// FIXED

.editor-transcriptPanel-fixedHeader{
	position: absolute;
	top: 10px;
	left: 10px;
	height: 40px;

	display: flex;
	align-items: center;	
	width: calc(100% - 22px);

	background: var(--bg-editorPanel);
	border-top-right-radius: var(--borderRadius-editorPanel);
	border-top-left-radius: var(--borderRadius-editorPanel);

	z-index: 500;

	box-sizing: border-box;
	padding-left: 26px;
	padding-right: 8px;
}

.editor-transcriptPanel-fixedHeader-titleBtnContainer{
	flex-grow: 1;
	flex-shrink: 1;

	margin-left: -7px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  position: relative;
  z-index: 10;
}


.editor-transcriptPanel-fixedHeader-titleBtnContainer[data-visibility-state='visible']{
	opacity: 1;
	transition: opacity 100ms linear;
}

.editor-transcriptPanel-fixedHeader-titleBtnContainer[data-visibility-state='hidden']{
	opacity: 0;
	pointer-events: none !important;
}

.editor-transcriptPanel-fixedHeader-bottomBorder{
	position: absolute;
	bottom: 0px;
	left: 23px;
	right: 12px;
	height: 1px;
	background: var(--line100);
}

.editor-transcriptPanel-fixedHeader-bottomBorder[data-visibility-state='visible']{
	opacity: 1;
}

.editor-transcriptPanel-fixedHeader-bottomBorder[data-visibility-state='hidden']{
	opacity: 0;
}




//
//

.editor-transcriptPanel-fixedHeader-hSpacer{
	// min-width: 10px;
	// flex-grow: 1;
	// flex-shrink: 1;

	width: 4px;
}

.editor-transcriptPanel-fixedHeader-btn{
	height: 25px;

	padding-left: 8px;	
	padding-right: 8px;

	flex-grow: 0;
	flex-shrink: 0;

	border-radius: 3px;
	
	position: relative;
}

.editor-transcriptPanel-fixedHeader-btn:disabled{
	pointer-events: none !important;
	opacity: 0.65;
}

.editor-transcriptPanel-fixedHeader-btn[data-voice-state='cloneAlert']:disabled{
	pointer-events: auto !important;
	cursor: default !important;
	opacity: 1;
}

.editor-transcriptPanel-fixedHeader-btn--voicePopover{
	margin-right: -2px;
}


.editor-transcriptPanel-fixedHeader-btn-iconContainer{
	width: 22px;
	height: 22px;
	margin-left: -5px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.editor-transcriptPanel-fixedHeader-btn[data-voice-state='cloneAlert']
.editor-transcriptPanel-fixedHeader-btn-iconContainer{
	margin-right: 3px;
}

.editor-transcriptPanel-fixedHeader-btn-iconContainer svg{
	width: 12px;
	height: 12px;
	fill: white;
	opacity: 0.5;
}

.editor-transcriptPanel-fixedHeader-btn:hover
.editor-transcriptPanel-fixedHeader-btn-iconContainer svg{
	opacity: 0.75;
}

.editor-transcriptPanel-fixedHeader-btn-iconContainer svg.icon--name--warningTriangle{
	width: 16px;
	height: 16px;
	opacity: 1;
}

.editor-transcriptPanel-fixedHeader-btn-iconContainer svg.icon--name--warningTriangle #bg{
	opacity: 0.25;
}

.editor-transcriptPanel-fixedHeader-btn-iconContainer svg.icon--name--warningTriangle #front{
	opacity: 1;
	fill: white;
}


.editor-transcriptPanel-fixedHeader-btn--recordAll
.editor-transcriptPanel-fixedHeader-btn-iconContainer{
	margin-right: 3px;
	margin-left: -3px;
}
.editor-transcriptPanel-fixedHeader-btn--recordAll
.editor-transcriptPanel-fixedHeader-btn-iconContainer svg{
	width: 15px;
	height: 15px;
}


.editor-transcriptPanel-fixedHeader-btn--draft{
	opacity: 1;
}

.editor-transcriptPanel-fixedHeader-btn--addCamera
.editor-transcriptPanel-fixedHeader-btn-iconContainer{
	margin-left: -5.5px;
}

.editor-transcriptPanel-fixedHeader-btn--addCamera
.editor-transcriptPanel-fixedHeader-btn-iconContainer svg{
	width: 14px;
	height: 14px;
}



.editor-transcriptPanel-fixedHeader-btn-label{
	
	font-size: 13.25px;
	//font-size: 14.25px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 500;
  letter-spacing: 0.008em; 

  opacity: 0.75; 
}

.editor-transcriptPanel-fixedHeader-btn-label-sub{
	margin-left: 3px;
	opacity: 0.65;
}

// .editor-transcriptPanel-fixedHeader:hover
// .editor-transcriptPanel-fixedHeader-btn{
// 	opacity: 1;
// 	// background: var(--250gray);
// }

.editor-transcriptPanel-fixedHeader:hover
.editor-transcriptPanel-fixedHeader-btn:hover{
	background: var(--250gray);
}

.editor-transcriptPanel-fixedHeader:hover
.editor-transcriptPanel-fixedHeader-btn:hover
.editor-transcriptPanel-fixedHeader-btn-label{
	opacity: 1;
}

.editor-transcriptPanel-fixedHeader-btn-tooltipContainer{
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	pointer-events: none;
	margin-top: 3px;
}

.tooltip.editor-transcriptPanel-fixedHeader-btn-tooltip{
	width: 200px;
  white-space: unset;
  text-align: center;
  padding-bottom: 7px;  
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 5px;
}


.editor-transcriptPanel-fixedHeader-btn	
.editor-transcriptPanel-fixedHeader-btn-tooltipContainer
.tooltip.editor-transcriptPanel-fixedHeader-btn-tooltip{
	opacity: 0;
	transform: translateY(-2px);
	transition: all 75ms linear;
}

.editor-transcriptPanel-fixedHeader-btn:hover	
.editor-transcriptPanel-fixedHeader-btn-tooltipContainer
.tooltip.editor-transcriptPanel-fixedHeader-btn-tooltip{
	opacity: 1;
	transform: translateY(0px);
}

.editor-transcriptPanel-fixedHeader-btn:hover{

}

.editor-transcriptPanel-fixedHeader-sideNavBtn{
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	width: 55px;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 12px;
}

.editor-transcriptPanel-fixedHeader-sideNavBtn[data-position-state='farLeft']{
	display: none; // can't find good solution right now
}

.editor-transcriptPanel-fixedHeader-sideNavBtn svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.4;
	transition: opacity 100ms linear;
}

// .editor-transcriptPanel:hover
// .editor-transcriptPanel-fixedHeader-sideNavBtn svg{
// 	opacity: 0.5;
// }

.editor-transcriptPanel:hover
.editor-transcriptPanel-fixedHeader-sideNavBtn:hover svg{
	opacity: 1;
}



//

@import 'EditorTranscriptPanel-Headers-TitleBtn';

