
@font-face {
    font-family: 'YPlusJakartaSans';
    src: url(../fonts/YPlusJakartaSans/YPlusJakartaSans-Light.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-light;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YPlusJakartaSans';
    src: url(../fonts/YPlusJakartaSans/YPlusJakartaSans-Regular.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-regular;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YPlusJakartaSans';
    src: url(../fonts/YPlusJakartaSans/YPlusJakartaSans-Medium.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-medium;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YPlusJakartaSans';
    src: url(../fonts/YPlusJakartaSans/YPlusJakartaSans-Semibold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-semibold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YPlusJakartaSans';
    src: url(../fonts/YPlusJakartaSans/YPlusJakartaSans-Bold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YPlusJakartaSans';
    src: url(../fonts/YPlusJakartaSans/YPlusJakartaSans-Heavy.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-heavy;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YPlusJakartaSans';
    src: url(../fonts/YPlusJakartaSans/YPlusJakartaSans-Black.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-black;
    text-rendering: optimizeLegibility;
}
