
@font-face {
    font-family: 'YPPObjectSans';
    src: url(../fonts/YPPObjectSans/YPPObjectSans-Light.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-light;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YPPObjectSans';
    src: url(../fonts/YPPObjectSans/YPPObjectSans-Regular.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-regular;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YPPObjectSans';
    src: url(../fonts/YPPObjectSans/YPPObjectSans-Medium.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-medium;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YPPObjectSans';
    src: url(../fonts/YPPObjectSans/YPPObjectSans-Semibold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-semibold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YPPObjectSans';
    src: url(../fonts/YPPObjectSans/YPPObjectSans-Bold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YPPObjectSans';
    src: url(../fonts/YPPObjectSans/YPPObjectSans-Heavy.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-heavy;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YPPObjectSans';
    src: url(../fonts/YPPObjectSans/YPPObjectSans-Black.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-black;
    text-rendering: optimizeLegibility;
}
