.editor-propertiesBar{
	
	// background: $tempr;

	position: absolute;
	top: 50%;
	transform: translateY(-50%);

	left: 6px;

	display: flex;
	flex-direction: column;

}

.editor-propertiesBar-btn{
	width: 38px;
	height: 38px;

	border-radius: 4px;

	opacity: 0.5;
}

.editor-propertiesBar-btn[data-state="active"]{
	opacity: 1;
	background: rgba(0,0,20,0.06);
}

.editor-propertiesBar-btn:hover{
	opacity: 1;
	background: rgba(0,0,20,0.06);
}

.editor-propertiesBar-btn[data-state="open"],
.editor-propertiesBar-btn[data-state="open"]:hover{
	opacity: 1;
	background: rgba(0,0,20,0.08);
}

.editor-propertiesBar-btn svg{
	width: 24px;
	height: 24px;

	fill: var(--text500);
	opacity: 1;
}

.editor-propertiesBar-btn svg.icon--name--insertBarImage{
	transform: scale(1.15);
	position: relative;
	// left: 1px;
}

.editor-propertiesBar-btn svg.icon--name--musicNotes{
	transform: scale(1.0);
	position: relative;
	left: -1px;
}

.editor-propertiesBar-btn svg.icon--name--waveform{
	transform: scale(0.8);
	position: relative;
}

.editor-propertiesBar-btn svg.icon--name--motionEffects{
	transform: scale(1.0);
}

.editor-propertiesBar-btn svg.icon--name--captionsOutlineRegular{
	transform: scale(1.0);
}