
.dpFontFamilyPickerBtn{	
	width: 100%;
	height: $height-dpElement;


	font-size: $baseFontSize-dpElement;	
	color: var(--text500);
	
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em; 

  justify-content: flex-start;
  box-sizing: border-box;
  padding-left: 7px;

  border-radius: 3px;
  background: var(--250gray);

  border: 1px solid $invisible;
}

.dpFontFamilyPickerBtn:hover{
	background: rgba(244,240,255,0.065);
	border: 1px solid var(--248gray);
}


.dpFontFamilyPickerPopover{
	margin-top: 4px;

	width: 216px;
	
	box-sizing: border-box;
	padding: 0px;
	padding-top: 4px;
	padding-bottom: 8px;
	
	overflow: none;

	background: var(--bg-dropdown);
	background: lighten(rgb(30,30,31), 3%);

	border-radius: 4px;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;

	border: 1px solid rgb(60,60,64);
	border-top: 1px solid rgb(74,74,78);
	border-bottom: 1px solid rgb(56,56,60);

	box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.18);

	box-shadow: var(--boxShadow-dropdown);
	
	z-index: 500;

	max-height: calc(100vh - 300px);
	overflow-y: scroll;

	position: relative;

}

.dpFontFamilyPickerPopover::-webkit-scrollbar {
    width: 10px; /* Adjust this value to make the scrollbar thinner */
}

.dpFontFamilyPickerPopover::-webkit-scrollbar-track {
    background: transparent;
}

.dpFontFamilyPickerPopover::-webkit-scrollbar-thumb {
    background-color: rgba(100, 100, 100, 0.4);
    border-radius: 4px;
    border: 2px solid transparent;
    background-clip: padding-box;
}

.dpFontFamilyPickerPopover::-webkit-scrollbar-thumb:hover {
    background-color: rgba(100, 100, 100, 0.7);
}


.dpFontFamilyPickerPopover-brandFonts-divider{
	height: 1px;
	width: calc(100% - 16px);
	margin-left: 9px;
	background: var(--line200);
	opacity: 0.75;

	margin-top: 5px;
	margin-bottom: 4px;
}

.dpFontFamilyPickerPopover-item{
	height: 24px;
	width: calc(100% - 6px);
	margin-left: 4px;
	// background: $tempr;
	// outline: 1px solid red;
	border-radius: 3px;
	box-sizing: border-box;
	padding-left: 4px;
}

.dpFontFamilyPickerPopover-item[data-active-state='inactive']{	
	opacity: 0.8;
}

.dpFontFamilyPickerPopover-item[data-active-state='inactive']:hover{	
	background: var(--hoverBG-item-dropdown);
	color: var(--text600);
	opacity: 1;
}

.dpFontFamilyPickerPopover-item[data-active-state='active'],
.dpFontFamilyPickerPopover-item[data-active-state='active']:hover{
	background: var(--activeBG-suggestionItem) !important;
	color: var(--text600);
}

.dpFontFamilyPickerPopover-item-iconContainer{
	width: 20px;
	height: 20px;
	background: white;
	opacity: 0.02;
	border-radius: 3px;
	display: none; //temp
}

.dpFontFamilyPickerPopover-item-label{
	flex-grow: 1;
	flex-shrink: 1;
	text-align: left;

	font-size: $baseFontSize-dpElement;	
	color: var(--text500);
	font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;

	padding-left: 8px;
}