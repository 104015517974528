.libraryPage{
	width: 100%;
	height: 100%;

	box-sizing: border-box;	

	display: flex;  

  background: var(--bg-transcriptPanel);

  box-sizing: border-box;
  padding-top: $height-tabBar;
}


$mainBG-inset: 12px;
$library-leftInset: 96px;
$library-rightInset: 76px;


@import 'Library-SideMenu';

@import 'Library-Header';

.libraryPage-sideMenuContainer{
	height: 100%;
	width: 256px;
	// background: $tempb;
}

.libraryPage-mainOuterContainer{
	height: 100%;
	flex-grow: 1;
	flex-shrink: 1;
	// background: $tempg;

	position: relative;	
}

.libraryPage-mainContainer{
	position: absolute;

	// display: flex;
	// flex-direction: column;
  
  top: $mainBG-inset;  
  left: $mainBG-inset;
  right: $mainBG-inset;
  bottom: $mainBG-inset;  

  z-index: 1;
  
  border-radius: 5px;

  background: rgb(29,29,31);
  border: 1px solid lighten(#151619, 4%);

  overflow-y: overlay;
  scrollbar-gutter: stable;
}

//

.libraryPage-measurer{
	position: absolute;
	left: 0px;
	width: 100%;
}

//

.libraryPage-sectionHeader{
	width: 100%;

	padding-left: $library-leftInset;
	padding-right: $library-rightInset;

	box-sizing: border-box;

	display: flex;
	align-items: flex-end;

	margin-top: 48px;
	margin-bottom: 4px;
}


.libraryPage-sectionHeader-label{
	font-size: 24px;
	color: var(--text500);
  font-variation-settings: "wght" 540;
  letter-spacing: -0.002em;  

  flex-grow: 1;
	flex-shrink: 1;

	user-select: none;
	cursor: default;

	// background: $tempr;
	// border-bottom: 1px solid red;
}

.libraryPage-sectionHeader-rightContainer{
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: flex-end;

	// background: $tempr;
	height: 20px;
	// border-bottom: 1px solid red;
}

//

.libraryPage-forYouOuterContainer{
	margin-top: 8px;
	position: relative;
	width: 100%;
	margin-bottom: 14px;
}

@import 'Library-ForYou';

//
// VIDEOS GRID
.libraryPage-projectGallery--grid{
	padding-top: 12px;
	padding-left: calc(#{$library-leftInset} - 40px);

	padding-bottom: $library-rightInset;
}

@import "Library-ProjectCard";

//
// VIDEOS LIST
.libraryPage-projectGallery--list{
	padding-top: 12px;
	padding-left: calc(#{$library-leftInset} - 8px);
	padding-right: calc(#{$library-rightInset} - 8px);

	padding-bottom: $library-rightInset;
}

@import "Library-ProjectListItem";


.libraryPage-noItems{
	padding-left: $library-leftInset;
	
	margin-top: 36px;

	font-size: 16px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;

  opacity: 0.45;

  user-select: none;
}

// .libraryPage-projectGrid{
// 	flex-grow: 1;
// 	flex-shrink: 1;

// 	display: flex;
// 	flex-direction: column;

// 	height: 100%;
// 	// background: $tempb;
// }

// .libraryPage-projectGrid-header{
// 	width: 100%;
// 	height: 120px;
// 	// background: red;

// 	flex-grow: 0;
// 	flex-shrink: 0;

// 	display: flex;
// 	align-items: flex-end;

// 	box-sizing: border-box;
// 	padding-left: 24px;
// 	padding-right: 60px;

// 	padding-bottom: 10px;
// }

// .libraryPage-projectGrid-header-title{
// 	flex-grow: 1;
// 	flex-shrink: 1;	

// 	font-size: 36px;
// 	font-weight: $fw-semibold;
// 	color: var(--text500);

// 	user-select: none;
// 	cursor: default;

// }

// .libraryPage-projectGrid-header-rightContainer{
// 	display: flex;
// }

// .libraryPage-projectGrid-header-newBtn{
// 	padding-left: 20px;
// 	padding-right: 20px;
// 	height: 32px;

// 	border: 1px solid rgba(255,255,255,0.65);
// 	border-radius: 4px;

// 	background: var(--254gray);

// 	position: relative;
// 	top: -4px;


// }

// .libraryPage-projectGrid-header-newBtn:disabled{
// 	pointer-events: none;
// 	opacity: 0.25;
// }

// .libraryPage-projectGrid-header-newBtn:hover{
// 	border: 1px solid rgba(255,255,255,0.70);
// 	background: var(--250gray);
// }


// .libraryPage-projectGrid-header-newBtn-iconContainer{
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	margin-right: 3px;
// 	margin-left: -10px;
// }

// .libraryPage-projectGrid-header-newBtn-iconContainer svg{
// 	width: 18px;
// 	height: 18px;
// 	fill: var(--text600);
// 	//opacity: 0.95;

// }

// .libraryPage-projectGrid-header-newBtn-label{
// 	color: var(--text600);

// 	font-size: 15px;
//   font-family: system-ui, sans-serif;
//   font-variation-settings: "wght" 500;
//   letter-spacing: 0.008em;

// }

// .libraryPage-projectGrid-header-newBtn--upgrade{
// 	border: 1px solid rgb(50, 99, 250);
// 	background: rgb(50, 99, 250);
// 	margin-right: 8px;
// }

// .libraryPage-projectGrid-header-newBtn--upgrade:hover{
// 	border: 1px solid lighten(rgb(50, 99, 250), 2%);
// 	background: lighten(rgb(50, 99, 250), 2%);
// }

// //

// .libraryPage-projectGrid-gridContainer{
// 	flex-grow: 1;
// 	flex-shrink: 1;

// 	box-sizing: border-box;
// 	padding-left: 24px;
// 	padding-right: 24px;
// 	padding-top: 20px;

// 	overflow-y: overlay;
// }

// .libraryPage-projectGrid-grid{
// 	width: 100%;
// 	height: 100%;
	
// }


// .libraryPage-header{
// 	font-size: 24px;
// 	font-weight: $fw-bold;
// 	color: var(--text500);

// 	opacity: 0.5;

// 	margin-bottom: 18px;
// }

// .libraryPage-brandTemplates-largeHeader{
// 	font-size: 20px;
// 	font-size: 18px;
// 	font-weight: $fw-semibold;
// 	color: var(--text500);
// 	// color: #516CE8;

// 	margin-bottom: 32px;
// 	margin-top: -12px;

// 	user-select: none;

// 	opacity: 0.5;
// }

// .libraryPage-brandTemplates-largeHeader span{
// 	opacity: 0.75;
// 	font-weight: $fw-medium;
// 	// margin-left: 10px;
// }

// .libraryPage-brandTemplates-hSpacer{
// 	flex-grow: 1;
// 	flex-shrink: 1;
// }

// .libraryPage-brandTemplates{
// 	display: flex;
// 	flex-direction: column;
// 	margin-top: 24px;
// 	margin-bottom: 18px;

// 	// padding-bottom: 18px;

// 	border-bottom: 1px solid rgba(255,255,255,0.1);
// }

// .libraryPage-brandTemplates-button{
// 	margin-left: 4px;

// 	flex-grow: 0;
// 	flex-shrink: 0;

	
// 	padding-left: 8px;
// 	padding-right: 18px;

// 	height: 32px;
// 	border: 1px solid lighten(#516CE8, 5%);
	

// 	border-radius: 3px;
// 	background: rgba(255,255,255,0.01);

// 	border: none;
// 	background: #516CE8;


// 	position: relative;

// }

// .libraryPage-brandTemplates-button:hover{
// 	// border: 1px solid lighten(#516CE8, 10%);
// 	// background: rgba(255,255,255,0.02);

// 	outline: 3px solid transparentize(lighten(#516CE8, 0%), 0.9);
// }

// .libraryPage-brandTemplates-button-iconContainer{
// 	width: 24px;
// 	height: 24px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	margin-right: 0px;
// }

// .libraryPage-brandTemplates-button-iconContainer svg{
// 	width: 21px;
// 	height: 21px;

// 	fill: lighten(#516CE8, 10%);
// 	opacity: 0.8;
// 	fill: white;

// 	transform: scale(0.92);
// 	position: relative;
// 	top: 0.5px;
// }

// .libraryPage-brandTemplates-button:hover 
// .libraryPage-brandTemplates-button-iconContainer svg{
// 	fill: lighten(#516CE8, 15%);
// 	fill: white;
// 	opacity: 1;
// }

// .libraryPage-brandTemplates-button-label{
//   font-size: 16px;
//   font-weight: $fw-medium;
//   color: lighten(#516CE8, 10%);
//   color: white;

//   font-family: system-ui, sans-serif;
//   font-variation-settings: "wght" 540;
//   letter-spacing: 0.008em;
// }

// .libraryPage-brandTemplates-button:hover
// .libraryPage-brandTemplates-button-label{
// 	color: lighten(#516CE8, 15%);
// 	color: white;
// }


// .libraryPage-brandTemplates-header,
// .libraryPage-projects-header{
// 	font-size: 24px;
// 	font-weight: $fw-semibold;
// 	color: var(--text500);

// 	// margin-top: 14px;
// 	// margin-bottom: 8px;
// 	user-select: none;
// }

// .libraryPage-brandTemplates-header span{
// 	opacity: 0.5;
// 	font-weight: $fw-medium;
// }

// .libraryPage-brandTemplates-group{
// 	display: flex;
// 	align-items: center;
// 	justify-content: flex-start;

// 	flex-wrap: wrap;

// 	margin-left: -6px;

// 	margin-top: 8px;
// 	margin-bottom: 14px;
// }

// .libraryPage-projects-header{
// 	margin-bottom: 10px;
// 	margin-top: 24px;
// }

// @import 'Library-NewProjectBtn';
@import 'Library-ProjectItem';


