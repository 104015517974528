.libraryPage-sideMenu{
	width: 100%;
	height: 100%;
	// background: $tempr;
	//border-right: 1px solid var(--line100);

	display: flex;
	flex-direction: column;

	overflow-y: overlay;

	box-sizing: border-box;
	padding-top: 24px;
	padding-bottom: 22px;


}

//

.libraryPage-sideMenu-yarnLogo{
	padding-left: 24px;
	width: 26px;
	height: 26px;

	margin-bottom: 18px;
}

.libraryPage-sideMenu-yarnLogo svg{
	width: 26px;
	height: 26px;

	fill: var(--text500);
	opacity: 0.2;
	opacity: 0;
}

.libraryPage-sideMenu-personalTab{
	padding-left: 24px;

	display: flex;

	margin-bottom: 20px;
}

.libraryPage-sideMenu-smallVSpacer{
	height: 6px;
}

.libraryPage-sideMenu-updatesTrigger{
	width: 0px;
	height: 0px;	
	margin-left: 10px;
	top: 5px;
	pointer-events: none;
	position: relative;
}


.libraryPage-sideMenu-personalTab-leftContainer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	flex-direction: column;
	user-select: none;
	cursor: default;

}

.libraryPage-sideMenu-personalTab-name{
	font-size: 15.25px;	
	color: var(--text500);

  font-variation-settings: "wght" 510;
  letter-spacing: 0.008em;

  margin-bottom: 4px;
}

.libraryPage-sideMenu-personalTab-organization{
	font-size: 13.5px;
	font-weight: $fw-medium;
	color: var(--text500);

  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;

  opacity: 0.5;
}

.libraryPage-sideMenu-personalTab-rightContainer{
	flex-grow: 0;
	flex-shrink: 0;
}

.libraryPage-sideMenu-personalTab-newDraftBtn{
	// background: $tempb;
	width: 40px;
	height: 34px;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	position: relative;
	top: -8px;
	box-sizing: border-box;
	padding-right: 3px;
}

.libraryPage-sideMenu-personalTab-newDraftBtn-inner{
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	width: 32px;
	height: 27px;

	// background: $tempr;
}

.libraryPage-sideMenu-personalTab-newDraftBtn:hover
.libraryPage-sideMenu-personalTab-newDraftBtn-inner{
	background: var(--250gray);
}

.libraryPage-sideMenu-personalTab-newDraftBtn svg{
	width: 20px;
	height: 20px;
	position: relative;
	top: -0.5px;
	right: -0.5px;

	fill: var(--text500);

	opacity: 0.6;
}

.libraryPage-sideMenu-personalTab-newDraftBtn:hover svg{
	opacity: 0.9;
}


//



.libraryPage-sideMenu-vSpacer{
	flex-grow: 1;
	flex-shrink: 1;
}

//

@import 'Library-SideMenu-Tab';


//
//

.libraryPage-sideMenu-collectionsHeader{
	display: flex;

	margin-top: 15px;
	height: 20px;
	padding-right: 2px;
}

.libraryPage-sideMenu-collectionsHeader-label{
	margin-left: 25px;
  opacity: 0.5;
  user-select: none;
  cursor: default;
}

.libraryPage-sideMenu-collectionsHeader-label,
.libraryPage-sideMenu-collectionsHeader-newBtn{
	font-size: 12.5px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 500;
  letter-spacing: 0.010em;

  color: var(--text500);
}

.libraryPage-sideMenu-collectionsHeader-newBtn{
	margin-left: auto;
	// background: $tempr;
	width: 40px;
	height: 24px;
	margin-top: -4px;
	opacity: 0;

	border-radius: 4px;

	transition: opacity 50ms linear;
}

.libraryPage-sideMenu:hover
.libraryPage-sideMenu-collectionsHeader-newBtn{
	opacity: 0.4;
	transition-delay: 0ms;
}

.libraryPage-sideMenu-collectionsHeader:hover
.libraryPage-sideMenu-collectionsHeader-newBtn:hover{
	opacity: 1;
}

.libraryPage-sideMenu-collectionsHeader-newBtn:hover{
	background: var(--251gray);
}

.libraryPage-sideMenu-collectionsFooter{
	height: 12px;
}


.libraryPage-sideMenu-newCollectionBtnContainer{
	margin-left: 17px;
	margin-top: 2px;
	margin-bottom: 3px;
}

.libraryPage-sideMenu-newCollectionBtn,
.libraryPage-sideMenu-firstCollection-btn{
	font-size: 12.5px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 500;
  letter-spacing: 0.010em;

  color: var(--text500);

  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 7px;

  border-radius: 3px;

  // background: $tempr;
}


.libraryPage-sideMenu-newCollectionBtn:hover{
	background: var(--251gray);
}

.libraryPage-sideMenu-newCollectionBtn{
	opacity: 0.5;
}

.libraryPage-sideMenu:hover
.libraryPage-sideMenu-newCollectionBtn:hover{
	opacity: 1;
}

.libraryPage-sideMenu-firstCollection{
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	box-sizing: border-box;
	padding-left: 25px;
}

.libraryPage-sideMenu-firstCollection-btn{
	font-size: 13.5px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.010em;

  color: var(--text500);

  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;

  margin-left: -10px;

  border-radius: 3px;

  opacity: 0.7;
  transition: opacity 100ms linear;
}

.libraryPage-sideMenu-firstCollection:hover
.libraryPage-sideMenu-firstCollection-btn{	
	opacity: 1;
}

.libraryPage-sideMenu-firstCollection-btn:hover{
	background: var(--246gray);	
}

.libraryPage-sideMenu-firstCollection-explainer{
	font-size: 13px;
  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 400;  
  letter-spacing: 0.012em;

  color: var(--text400);
  opacity: 0.7;
  opacity: 0;
  transition: opacity 100ms linear;

  user-select: none;
  cursor: default;
}

.libraryPage-sideMenu-firstCollection:hover
.libraryPage-sideMenu-firstCollection-explainer{
	opacity: 0.7;
}