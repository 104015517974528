
.editor-transcriptPanel-webcamHeader{
  position: absolute;
  margin-top: 2px;
  left: 12px;
  width: calc(100% - 14px);
  height: 34px;
  z-index: 5000;
  // background: $tempr;

  box-sizing: border-box;
  padding-left: 7px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  pointer-events: none;
}


.editor-transcriptPanel-webcamHeader-titleBtn,
.editor-transcriptPanel-webcamHeader-dropdownBtn{
  
  margin-top: 4px;

  display: flex;
  align-items: center;

  padding-left: 5px;
  padding-right: 10px;
  height: 24px;

  border-radius: 3px;
  cursor: pointer;

  pointer-events: auto;

  opacity: 1;

  position: relative;  

  // background: $tempr;
}


.editor-transcriptPanel-webcamHeader-titleBtn--script{
  width: 75px;
}

.editor-transcriptPanel-webcamHeader-titleBtn--script:hover{
  width: 108px;
}

.editor-transcriptPanel-webcamHeader-titleBtn--instructions{
  width: 109px;
  pointer-events: none;
}

.editor-transcriptPanel-webcamHeader-titleBtn[data-camera-type='static']{
  pointer-events: none;
  width: 100px;
  // background: $tempr;
  margin-left: 1.5px; // not sure why required
}

.editor-transcriptPanel-webcamHeader-titleBtn[data-camera-type='screencastLinked']{
  // pointer-events: none;
  width: 105px;
  // background: $tempr;
  margin-left: 1.5px; // not sure why required
}

.editor-transcriptPanel-webcamHeader-titleBtn--dropdown{
  padding-right: 6px;
  padding-left: 6px;
  margin-right: 4px;
}

.editor-transcriptPanel-webcamHeader-titleBtn-inner{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 10px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.editor-transcriptPanel-webcamHeader-titleBtn
.editor-transcriptPanel-webcamHeader-titleBtn-inner--default{
  opacity: 0.75;
} 

.editor-transcriptPanel-webcamHeader-titleBtn:hover
.editor-transcriptPanel-webcamHeader-titleBtn-inner--default{
  opacity: 0;
}

.editor-transcriptPanel-webcamHeader-titleBtn
.editor-transcriptPanel-webcamHeader-titleBtn-inner--hover{
  opacity: 0;
}

.editor-transcriptPanel-webcamHeader-titleBtn:hover
.editor-transcriptPanel-webcamHeader-titleBtn-inner--hover{
  opacity: 1;
}




.editor-transcriptPanel-webcamHeader-hSpacer{
  min-width: 10px;
  flex-grow: 1;
  flex-shrink: 1;
}


.editor-transcriptPanel-webcamHeader-titleBtn-iconContainer,
.editor-transcriptPanel-webcamHeader-dropdownBtn-iconContainer{
  width: 20px;
  height: 20px;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-transcriptPanel-webcamHeader-titleBtn-iconContainer svg,
.editor-transcriptPanel-webcamHeader-dropdownBtn-iconContainer svg{
  width: 14px;
  height: 14px;
  fill: white;
  opacity: 0.65;
  position: relative;
  top: -0.5px;
}

.editor-transcriptPanel-webcamHeader-dropdownBtn-iconContainer{
  margin-right: 0px;
}


.editor-transcriptPanel-webcamHeader-dropdownBtn-iconContainer svg{
  width: 16px;
  height: 16px;
  opacity: 0.1;

  transition: opacity 100ms linear;
}

.editor-transcriptPanel:hover
.editor-transcriptPanel-webcamHeader-dropdownBtn-iconContainer svg{  
  opacity: 0.35;
}

.editor-transcriptPanel-webcamHeader-dropdownBtn:hover
.editor-transcriptPanel-webcamHeader-dropdownBtn-iconContainer svg,
.editor-transcriptPanel-webcamHeader-dropdownBtn[data-state='open']
.editor-transcriptPanel-webcamHeader-dropdownBtn-iconContainer svg{  
  opacity: 1;
}


.editor-transcriptPanel-webcamHeader-titleBtn-label{
  font-size: 12px;
  font-variation-settings: "wght" 480;
  color: var(--text500);
  letter-spacing: 0.008em;
}

span.editor-transcriptPanel-webcamHeader-titleBtn-label-sub{
  margin-left: 0.4em;
}

//
//

.editor-transcriptPanel-webcamHeader-titleBtn[data-camera-type='static']
.editor-transcriptPanel-webcamHeader-titleBtn-iconContainer svg{
  fill: lighten(#1D282B, 44%)
}

.editor-transcriptPanel-webcamHeader-titleBtn[data-camera-type='screencastLinked']
.editor-transcriptPanel-webcamHeader-titleBtn-iconContainer{
  margin-right: 3px;
}

.editor-transcriptPanel-webcamHeader-titleBtn[data-camera-type='screencastLinked']
.editor-transcriptPanel-webcamHeader-titleBtn-iconContainer svg{
  width: 15px;
  height: 15px;
  fill: lighten(#a19bff, 10%)
}

.editor-transcriptPanel-webcamHeader-titleBtn[data-camera-type='static']
.editor-transcriptPanel-webcamHeader-titleBtn-label{
  color: lighten(#1D282B, 44%)
}

.editor-transcriptPanel-webcamHeader-titleBtn[data-camera-type='screencastLinked']
.editor-transcriptPanel-webcamHeader-titleBtn-label{
  color: lighten(#a19bff, 10%)
}

.editor-transcriptPanel-webcamHeader-titleBtn[data-camera-type='screencastLinked']
.editor-transcriptPanel-webcamHeader-titleBtn-label
span.editor-transcriptPanel-webcamHeader-titleBtn-label-sub{
  //color: desaturate(darken(#a19bff, 8%), 25%)
  opacity: 0.5;
}

.editor-transcriptPanel-webcamHeader-titleBtn[data-camera-type='screencastLinked']:hover{
  background: transparentize(lighten(#a19bff, 0%), 0.92);
}




.editor-transcriptPanel-webcamHeader-titleBtn[data-instructions-type='script']{
  //background: var(--instructionsBG);
}

.editor-transcriptPanel-webcamHeader-titleBtn[data-instructions-type='script']:hover,
.editor-transcriptPanel-webcamHeader-titleBtn[data-instructions-type='script'][data-state='open']{
  //background: var(--251gray);
}


.editor-transcriptPanel-webcamHeader-titleBtn[data-instructions-type='script']
.editor-transcriptPanel-webcamHeader-titleBtn-iconContainer svg{
  fill: var(--text300);
}

.editor-transcriptPanel-webcamHeader-titleBtn[data-instructions-type='script']
.editor-transcriptPanel-webcamHeader-titleBtn-label{
  color: var(--text300);
}

.editor-transcriptPanel-webcamHeader-titleBtn[data-instructions-type='script']:hover
.editor-transcriptPanel-webcamHeader-titleBtn-iconContainer svg{
  fill: var(--text500);
}

.editor-transcriptPanel-webcamHeader-titleBtn[data-instructions-type='script']:hover
.editor-transcriptPanel-webcamHeader-titleBtn-label{
  color: var(--text500);
}

//
//

.editor-transcriptPanel-webcamHeader-titleBtn[data-instructions-type='instructions']{
  //background: var(--instructionsBG);
}

.editor-transcriptPanel-webcamHeader-titleBtn[data-instructions-type='instructions']:hover,
.editor-transcriptPanel-webcamHeader-titleBtn[data-instructions-type='instructions'][data-state='open']{
  background: var(--instructionsSoftBGHover);
}


.editor-transcriptPanel-webcamHeader-titleBtn[data-instructions-type='instructions']
.editor-transcriptPanel-webcamHeader-titleBtn-iconContainer svg{
  fill: var(--instructionsText);
}

.editor-transcriptPanel-webcamHeader-titleBtn--dropdown[data-instructions-type='instructions']{
  fill: var(--instructionsText);
}

.editor-transcriptPanel-webcamHeader-titleBtn[data-instructions-type='instructions']
.editor-transcriptPanel-webcamHeader-titleBtn-label{
  color: var(--instructionsText);
}

.editor-transcriptPanel-webcamHeader-titleBtn[data-instructions-type='instructions']:hover
.editor-transcriptPanel-webcamHeader-titleBtn-iconContainer svg{
  fill: var(--instructionsTextHover);
}

.editor-transcriptPanel-webcamHeader-titleBtn[data-instructions-type='instructions']:hover
.editor-transcriptPanel-webcamHeader-titleBtn-label{
  color: var(--instructionsTextHover);
}


//
//
.editor-transcriptPanel-webcamHeader-loadingTranscript{
  position: absolute;
  top: 100%;
  left: 0px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-top: 1px;
  width: 100%;
  height: 40px;
  //background: $tempr;

  // color: red;
  // opacity: 0.2;

  //messy
  font-size: 14.5px;
  // font-size: 14.25px;
  // font-variation-settings: "wght" 420;
  //color: var(--text500);
  line-height: 1.37;
  letter-spacing: 0.010em;
}

.editor-transcriptPanel-webcamHeader-loadingTranscript-inner{
  display: flex;
  align-items: center;
  justify-content: flex-start;  
}



.editor-transcriptPanel-webcamHeader[data-camera-type='static']
.editor-transcriptPanel-webcamHeader-loadingTranscript
.editor-transcriptPanel-webcamHeader-loadingTranscript-inner{
    background: linear-gradient(90deg, 
              lighten(rgba(29, 40, 43,1), 40%), 
              lighten(rgba(29, 40, 43,0.5), 40%), 
              lighten(rgba(29, 40, 43,1), 40%));

    background-size: 200% 100%;
  animation: loadingTextShimmer 6s infinite linear;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  position: relative;
  font-variation-settings: "wght" 450;
}

.editor-transcriptPanel-webcamHeader[data-camera-type='screencastLinked']
.editor-transcriptPanel-webcamHeader-loadingTranscript
.editor-transcriptPanel-webcamHeader-loadingTranscript-inner{
  background: linear-gradient(90deg, 
              lighten(rgba(110,99,217,1), 20%), 
              lighten(rgba(110,99,217,0.5), 20%), 
              lighten(rgba(110,99,217,1), 20%));

  background-size: 200% 100%;
  animation: loadingTextShimmer 6s infinite linear;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  position: relative;
  font-variation-settings: "wght" 450;
}



@keyframes loadingTextShimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}


//
// Maybe should do this somewhere else

.editor-transcriptPanel-transcriptGroup--processing
.editor-transcriptPanel-transcriptChunk-contents{
  pointer-events: none;
  visibility: hidden;
}