.videoPreview{
	position: relative;	
}

$videoPreview-borderRadius: 2px;

.videoPreview-staticPosterContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

}

img.videoPreview-staticPoster{
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: $videoPreview-borderRadius;
}


.videoPreview-previewContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;	
}

//

.videoPreview-player{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 5;
}

.videoPreview-player-videoContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;	

	border-radius: 3px;
	overflow: hidden;
}

.videoPreview-player-video{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;	
}




.videoPreview-player-controls{
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	z-index: 5;
}


//



$videoPreview-seekbar-height: 40px;
$videoPreview-seekbar-underhang: 8px;

.videoPreview-actionBtn{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;

	// background: $tempr;
	z-index: 20;

	height: calc(100% - #{$videoPreview-seekbar-height} + #{$videoPreview-seekbar-underhang});	
}

.videoPreview-muteToggle{
	position: absolute;
	top: 8px;
	right: 8px;
	width: 32px;
	height: 28px;

	z-index: 40;

	background: rgba(0,0,0,0.5);

	border-radius: 3px;

}

.videoPreview-muteToggle svg{
	width: 20px;
	height: 20px;
	fill: white;
	position: relative;

	opacity: 0.9;
}

.videoPreview-muteToggle svg.icon--name--speaker--muted{
	right: -3px;
}

.videoPreview-muteToggle:hover{
	background: rgba(0,0,0,0.80);
}

.videoPreview-muteToggle:hover svg{
	opacity: 1;
}

.videoPreview-player-controls-seekBar{
	position: absolute;
	height: 40px;
	// background: $tempb;
	width: 100%;
	left: 0px;
	bottom: calc(-1 * #{$videoPreview-seekbar-underhang}); // so mouse can get underneath
	z-index: 5;
}

.videoPreview-player-controls-seekBar-cover{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 2;

	background: rgba(0,0,0,0.20);
	transition: opacity 75ms linear;
}

.videoPreview-player-controls-seekBar-cover[data-state-visible='true']{
	opacity: 1;
}

.videoPreview-player-controls-seekBar-cover[data-state-visible='false']{
	opacity: 0;
}

.videoPreview-player-controls-seekBar-seekBar{
	height: 100%;
	// background: blue;
}

.videoPreview-player-controls-seekBar-seekBar-track{
	height: 5px;
	bottom: $videoPreview-seekbar-underhang;
	background: none;
	
	overflow: hidden;

}

.videoPreview-player-controls-seekBar-seekBar-track-0{
	background: green;
	background: #57A4FC;
	background: #8eabff;
	//background: #6C64D2;
	//background: #8980F3;
	// background: red;
	background: rgb(255, 144, 199);
	background: #e25f0d;
	// border-top-left-radius: 0px;
	// border-bottom-left-radius: 2px;

	
	border-bottom-left-radius: $videoPreview-borderRadius;


	z-index: 20;
}

.videoPreview-player-controls-seekBar-seekBar-track-1{	
	background: rgba(255,255,255,0.18);
	// border-top-right-radius: 2px;
	

	z-index: 5;
	//border-radius: 0px;
	//background: rgb(75,75,89);

	border-bottom-right-radius: $videoPreview-borderRadius;
}

//


.videoPreview-player-controls-seekBar-hoverBarContainer{
	position: absolute;
	bottom: $videoPreview-seekbar-underhang;
	height: 5px;
	left: 0px;
	width: 100%;

	overflow: hidden;
	border-bottom-right-radius: $videoPreview-borderRadius;
	border-bottom-left-radius: $videoPreview-borderRadius;
}

.videoPreview-player-controls-seekBar-hoverBar{
	position: absolute;
	top: 0px;
	height: 100%;
	background: rgba(255,255,255,0.5);
	z-index: 10;
}

.videoPreview-player-controls-seekBar-hoverBar[data-state="visible"]{
	opacity: 1;	
}

.videoPreview-player-controls-seekBar-hoverBar[data-state="hidden"]{
	opacity: 0;	
}




.videoPreview-player-controls-seekBar-hoverPreview{
	position: absolute;

	background: $white;
	
	pointer-events: none;
	border-radius: 1px;

	//outline: 1px solid rgba(2,2,5,0.08);
	outline: 1px solid rgba(200,200,200,1);
	box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.12);

	border: none;
	
	overflow: hidden;
}


.videoPreview-player-controls-seekBar-hoverPreview-storyboardImageOuterContainer{
	width: 100%;
	height: 100%;
}


.videoPreview-player-controls-seekBar-hoverPreview-storyboardImage-image{
	transform-origin: 0% 0%;
}

///

.videoPreview-player-controls-seekBar-hoverPreview-timestampContainer{
	position: absolute;
	//bottom: -22px;
	bottom: 2px;
	left: 0px;
	right: 0px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.videoPreview-duration{
	position: absolute;
	z-index: 4;
	bottom: 5px;
	right: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	// padding-right: 4px;
	// padding-left: 4px;
}

.videoPreview-player-controls-seekBar-hoverPreview-timestamp,
.videoPreview-duration{

	line-height: 1.3;

	background: rgba(0,0,0,0.75);
	//outline: 2px solid rgba(255,255,255,0.15);
	//border: 1px solid rgb(0,0,0,0.85);
	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--text500);

	font-size: 13px;
	font-weight: $fw-regular;
	font-variation-settings: "wght" 490;
	letter-spacing: 0.001em;

	height: 19px;
	width: 40px;
  
	background: lighten(rgba(29,29,31,0.9), 1%);	
	font-feature-settings: "tnum";
}

.videoPreview-duration{
	border: none;
	
	font-variation-settings: "wght" 550;
	background: lighten(rgba(29,29,31,0.7), 1%);	
}