
@keyframes mediaPopoverFadeInUp {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes mediaPopoverFadeOutDown {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(5px);
  }
}


.popover.popover--addMediaPopover[data-side='top'][data-state='open']{
  animation: mediaPopoverFadeInUp 100ms ease-out forwards;
}
.popover.popover--addMediaPopover[data-side='top'][data-state='closed']{
  animation: mediaPopoverFadeOutDown 100ms ease-in forwards;
}


@import 'AddMediaPopover-Search';


//


.popover.popover--addMediaPopover{
	// height: 200px;
	// width: 800px;
	padding-top: 4px;
	padding-bottom: 4px;

	padding-left: 4px;
	padding-right: 4px;
	border-radius: 6px;

	box-shadow: none;
	// border: 1px solid rgba(255,255,255,0.125) !important;	
	// background: rgba(22,22,32,0.85);
	// backdrop-filter: blur(25px);

	border: 1px solid rgba(255,255,255,0.125) !important;	
	background: rgba(26,26,32,0.92);
	backdrop-filter: blur(25px);

	display: flex;
	flex-direction: column;
	scrollbar-gutter: stable;


}

.popover.popover--addMediaPopover--modal{
	width: 920px;
	height: 600px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.popover--addMediaPopover--modalOverlay{
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;

	background: rgba(0,0,0,0.5);
}


.popover--addMediaPopover-header{
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 5px;
	padding-right: 5px;

	box-sizing: border-box;
	width: 100%;

	flex-grow: 0;
	flex-shrink: 0;
}

//

.popover--addMediaPopover-listContainer{
	position: relative;
}

.popover--addMediaPopover-list{	
	overflow-y: overlay;
	overflow-x: hidden;

	max-height: min(calc(100vh - 380px), 550px);
}

.popover--addMediaPopover-list-row{
	display: flex;
}

//
//

$bg-itemContainer: rgba(255,255,255,0.03);
$bg-itemContainerHover: rgba(255,255,255,0.10);

.popover--addMediaPopover-itemOuterContainer{
	position: relative;

	box-sizing: border-box;	
}

.popover--addMediaPopover-itemContainer{
	background: $bg-itemContainer;
	border-radius: 5px;
	cursor: pointer;

	width: 100%;
	height: 100%;

	box-sizing: border-box;	

	position: relative;
}

.popover--addMediaPopover-itemContainer:hover{
	background: $bg-itemContainerHover;
}


.popover--addMediaPopover-item--image{
	display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.popover--addMediaPopover-item--image--transparency--darkImage{
	background: repeating-conic-gradient(rgb(255,255,255) 0% 25%, rgb(240,240,245) 0% 50%) 50% / 20px 20px;
	border-radius: 2px;
}

.popover--addMediaPopover-item--image--transparency--lightImage{
	background: repeating-conic-gradient(transparent 0% 25%, rgba(255,255,255, 0.05) 0% 50%) 50% / 20px 20px;
	border-radius: 2px;
}

.popover--addMediaPopover-item--image img{		
  border-radius: 2px;
  position: relative;
  z-index: 5;
}

// .popover--addMediaPopover-item--image-transparentBG {
//   // background: 
//   //   repeating-conic-gradient(rgb(255,255,255) 0% 25%, rgb(240,240,245) 0% 50%) 
//   //     50% / 20px 20px;

//       background: 
//     repeating-conic-gradient(transparent 0% 25%, rgba(255,255,255, 0.05) 0% 50%) 
//       50% / 20px 20px;
//   position: absolute;
//   left: 0px;
//   right: 0px;
//   top: 0px;
//   bottom: 0px;
//   margin: auto;
//   z-index: 1;

//   opacity: 0;
// }

.popover--addMediaPopover-item--image-transparentBG--darkImage{

}

.popover--addMediaPopover-item--image-transparentBG--lightImage{

}






.popover--addMediaPopover-item--video-previewContainer video{
	border-radius: 2px;
	position: relative;
	top: 1px;
}



.popover--addMediaPopover-item--upload-iconContainer{

}

// .popover--addMediaPopover-item--image{
// 	//width: 190px;
// 	height: 90px;
// 	width: 120px;

// 	height: 100px;
// 	width: 130px;
// }

.popover--addMediaPopover-item--video{
	position: relative;
}


.popover--addMediaPopover-itemContainer{
	
}

// .popover--addMediaPopover-item--upload{
// 	background: rgba(255,255,255,0.10);
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	width: 110px;
// }





// .popover--addMediaPopover-item--library{
//  	// object-fit: contain;
//  	box-sizing: border-box;
//  	padding-top: 6px;
//  	padding-left: 6px;
//  	padding-right: 6px;
//  	padding-bottom: 6px;
// }


//

.popover--addMediaPopover-item--video{
	display: flex;
	align-items: center;
	justify-content: center;
}





.popover--addMediaPopover-item--video-videoDuration{
	position: absolute;
	bottom: 8px;
	right: 8px;

	padding-right: 4px;
	padding-left: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
	height: 20px;

	font-size: 12px;
	font-weight: $fw-medium;
	color: var(--text400);
	letter-spacing: 0.001em;

	border-radius: 3px;

	background: rgba(20,20,30,0.65);
	backdrop-filter: blur(5px);

	z-index: 10;

	opacity: 1;
}

.popover--addMediaPopover-itemContainer:hover
.popover--addMediaPopover-item--video-videoDuration{
	opacity: 1;
	color: var(--text600);
}


//
//

.popover--addMediaPopover-footer{
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-bottom: 3px;
	padding-top: 3px;

	flex-grow: 0;
	flex-shrink: 0;
}

.popover--addMediaPopover-uploadBtn{
	width: 320px;
	height: 32px;
	border-radius: 5px;	
	background: rgba(255,255,255,0.05);
}

.popover--addMediaPopover-uploadBtn--large{
	height: 48px;
}

.popover--addMediaPopover-uploadBtn[data-state="hidden"]{
	visibility: hidden;
	pointer-events: none;
}

.popover--addMediaPopover-uploadBtn svg{
	width: 30px;
	height: 30px;
	fill: white;
	opacity: 0.8;

	position: relative;
	top: 1px;
}

.popover--addMediaPopover-uploadBtn--large svg{
	width: 34px;
	height: 34px;
	fill: white;

	position: relative;
	top: 1px;
}

.popover--addMediaPopover-uploadBtn:hover svg{
	opacity: 1;	
}

.popover--addMediaPopover-uploadBtn svg.icon--name--laptopUpload #laptop,
.popover--addMediaPopover-uploadBtnLarge svg.icon--name--laptopUpload #laptop{	
	opacity: 0.5;	
}

.popover--addMediaPopover-uploadBtn:hover svg.icon--name--laptopUpload #laptop{
	opacity: 0.75;
}

.popover--addMediaPopover-uploadBtn 
svg.icon--name--laptopUpload #arrow{
	opacity: 1;
}

//

.popover--addMediaPopover-onboarding{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: calc(100% + 32px);

	display: flex;
	flex-direction: column;
	align-items: center;
}

.popover--addMediaPopover-onboarding-vSpacer{
	flex-grow: 1;
	flex-shrink: 1;
	min--height: 10px;
}

.popover--addMediaPopover-onboarding-prefillGallery{
	width: 100%;
	display: flex;
	flex-wrap: wrap;

}
.popover--addMediaPopover-onboarding-prefillGallery-item{
	width: 25%;
	flex-grow: 0;
	flex-shrink: 0;

	box-sizing: border-box;
	padding: 4px;
}

.popover--addMediaPopover-onboarding-prefillGallery-item-inner{
	width: 100%;
	border-radius: 4px;
	background: $bg-itemContainer;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	padding: 6px;

	position: relative;
}

.popover--addMediaPopover-onboarding-prefillGallery-item-inner:hover{
	background: $bg-itemContainerHover;
}

.popover--addMediaPopover-onboarding-prefillGallery-item-inner-imageContainer,
.popover--addMediaPopover-onboarding-prefillGallery-item-inner-videoContainer{
	width: 100%;
	aspect-ratio: 16/10;
	position: relative;
	
	border-radius: 4px;
	overflow: hidden;
}

.popover--addMediaPopover-onboarding-prefillGallery-item-inner-imageContainer img,
.popover--addMediaPopover-onboarding-prefillGallery-item-inner-videoContainer video{	
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 4px;
	filter: brightness(0.7); /* Adjust the value to make the image darker or lighter */	
}



.popover--addMediaPopover-onboarding-prefillGallery-item-inner:hover
.popover--addMediaPopover-onboarding-prefillGallery-item-inner-imageContainer img,
.popover--addMediaPopover-onboarding-prefillGallery-item-inner:hover
.popover--addMediaPopover-onboarding-prefillGallery-item-inner-videoContainer video{
	filter: brightness(1);
}

.popover--addMediaPopover-onboarding-prefillGallery-item-inner-label{
	width: 100%;
	// text-align: left;
	// padding-top: 7px;

	font-size: 13.5px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;
  
  position: absolute;
  bottom: 12px;
  left: 0px;
}

// .popover--addMediaPopover-onboarding-prefillGallery-item-inner:hover
// .popover--addMediaPopover-onboarding-prefillGallery-item-inner-label{
// 	opacity: 0;
// }

//
//

// .popover--addMediaPopover-star{
// 	position: absolute;
// 	top: 10px;
// 	right: 10px;

// 	width: 24px;
// 	height: 24px;

// 	border-radius: 50%;

// 	background: rgba(255,255,255,0.10);
	
// }

// .popover--addMediaPopover-star svg{
// 	width: 16px;
// 	height: 16px;
// 	fill: var(--text600);
// }

// //



// //

// .popover--addMediaPopover-star[data-state='starred']{
// 	background: rgba(255,255,255,0.15);	
// }

// .popover--addMediaPopover-star[data-state='starred'] svg{
// 	opacity: 0.9;
// }

// .popover--addMediaPopover-star[data-state='starred']:hover{
// 	background: rgba(255,255,255,0.2);	
// }

// .popover--addMediaPopover-star[data-state='starred']:hover svg{
// 	opacity: 1;
// }

// //

// .popover--addMediaPopover-star[data-state='notStarred']{
// 	background: rgba(255,255,255,0.05);	
// 	opacity: 0;
// }

// .popover--addMediaPopover-itemOuterContainer:hover
// .popover--addMediaPopover-star[data-state='notStarred']{
// 	opacity: 1;
// }

// .popover--addMediaPopover-star[data-state='notStarred'] svg{	
// 	opacity: 0.25;
// }

// .popover--addMediaPopover-star[data-state='notStarred']:hover{
// 	background: rgba(255,255,255,0.15);	
// }

// .popover--addMediaPopover-star[data-state='notStarred']:hover svg{
// 	opacity: 0.75;
// }