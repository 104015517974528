
@font-face {
    font-family: 'YOakesGrotesk';
    src: url(../fonts/YOakesGrotesk/YOakesGrotesk-Light.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-light;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YOakesGrotesk';
    src: url(../fonts/YOakesGrotesk/YOakesGrotesk-Regular.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-regular;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YOakesGrotesk';
    src: url(../fonts/YOakesGrotesk/YOakesGrotesk-Medium.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-medium;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YOakesGrotesk';
    src: url(../fonts/YOakesGrotesk/YOakesGrotesk-Semibold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-semibold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YOakesGrotesk';
    src: url(../fonts/YOakesGrotesk/YOakesGrotesk-Bold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YOakesGrotesk';
    src: url(../fonts/YOakesGrotesk/YOakesGrotesk-Heavy.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-heavy;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YOakesGrotesk';
    src: url(../fonts/YOakesGrotesk/YOakesGrotesk-Black.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-black;
    text-rendering: optimizeLegibility;
}
