.electronMacDots{
	position: fixed;
	z-index: 2000;

	top: 14px;
	left: 19px;

	top: 18px;
	left: 15px;

	top: 18px;
	left: 18px;

	// left: 13px;
	// top: 12px;

	left: 15px;
	top: 16px;

	left: 20px;
	top: 20px;

	// left: 18px;
	// top: 18px;

	display: flex;

	// background: $tempr;
	pointer-events: none;

	// display: none;

	// display: none; // temp
}

@keyframes apiErrorStateMacDots { 
  from { opacity: 0.5; } 
}

.electronMacDots-dot{


	width: 12px;
	height: 12px;
	border-radius: 50%;

	margin-right: 8px;

	background: var(--electronMacDotsBG);

	//display: none;
}



// .electronMacDots-dot:nth-child(1){
// 	background: #EC6A5D;
// }

// .electronMacDots-dot:nth-child(2){
// 	background: #F4BF4F;
// }

// .electronMacDots-dot:nth-child(3){
// 	background: #61C553;
// }

.electronMacDots--apiErrorState{
	width: 60px;
	height: 12px;
	background: var(--accentRed600);
	border-radius: 6px;

	animation: apiErrorStateMacDots 0.8s infinite alternate;

}

.electronMacDots--apiErrorState .electronMacDots-dot{
	visibility: hidden;
}

.electronMacDots--hoverSideMenu{
	position: absolute;
	top: 13px;
	left: 10px;

	z-index: 500;
}