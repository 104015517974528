//
// APP.SCSS 
//


/////


@import "NormalizeV8.scss";

html{
  //color-scheme: light !important; // for scrollbars
}

body {
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;  
  overflow-x: hidden;
  
}

.scrollTestButton{
  position: fixed;
  z-index: 500;
  width: 500px;
  height: 500px;
  background: red;
}

::-webkit-scrollbar-track {
  background-color: rgba(0,0,0,0); /* Light track color */
}

/* For the scrollbar handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,0.15); /* Light thumb color */
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255,255,255,0.3); /* Light thumb color */
  border-radius: 8px;
}

::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
}


$width-exportBtnContainer: 300px;
//$width-exportBtnContainer: 200px;


$height-tabBar: 38px;



.app{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;    
}

.projectlist{
  color: var(--text500); 

  overflow-y: scroll;
  height: 100%;
}

.projectListProject{
  margin-left: 24px;
  height: 30px;
  cursor: pointer;

  width: 500px;

}

.projectListProject:hover{
  background: var(--250gray);
}

.projectListNewRecording{
  background: var(--250gray);
  width: 200px;
  height: 50px;

  color: white;
}

//

@keyframes spinnerRotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
}

//

@import "colors/Colors.scss";
@import "Type.scss";

@import "mixins/Poly-Fluid-Sizing.scss";
@import "CoreLayout.scss";
@import "MacDots.scss"; // show grey mac dots until on hover

@import "ButtonReset.scss";
@import "Tooltips.scss";
@import "Dropdowns.scss";

@import "TabBar.scss";

@import "library/Library.scss";

@import "Login.scss";

@import "Editor.scss";
@import "editorLeftPanel/EditorLeftPanel.scss";
@import "editorTimeline/EditorTimeline.scss";
@import "editorToolbar/EditorToolbar.scss";
@import "editorTranscriptPanel/EditorTranscriptPanel.scss";
@import "Toast.scss";


@import "editorRightPanel/EditorRightPanel";

@import "Playground.scss";

@import "RecordingOverlay.scss";
@import "RecordingControls.scss";

@import "RecordingWindow.scss";
@import "SelectWindowModal.scss";

@import "uiKit/uiKit.scss";

@import "Settings.scss";

@import "SlideTextArea.scss";

@import "slideEditor/SlideEditor.scss";
@import "Captions.scss";

@import "Spreadsheet.scss";
@import "Export.scss";

@import "EditorPopover.scss";
@import "UpdatesPage.scss";

@import "Timeline.scss";

@import "AddFromLibraryDialog.scss";


@import "AddMediaPopover.scss";

@import "slidePreview/SlidePreview.scss";

@import "ExportProgressTray.scss";
@import "CameraEditor.scss";
@import "recordWebcam/RecordWebcam.scss";

@import "ForceUpdateModal.scss";
//



//


.calculateWordsArrayContainer{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 1080px;

  display: flex;
  align-items: center;
  justify-content: center; 

  pointer-events: none !important;
  visibility: hidden !important;

  z-index: -999;
}


.calculateWordsArrayContainer
.playground-textSlide-textArea{
  // position: relative;  
  pointer-events: none !important
}

.calculateWordsArrayContainer * {
  color: $invisible !important;
}




.editor-center-center-textEditor{
  position: absolute;
  bottom: 50px;
  right: 50px;

  width: 400px;
  height: 100px;

  z-index: 50;

  background: $tempr;
}

.editor-center-center-textEditor textarea{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  font-size: 50px;

}

