// SlideTextArea

.editor-textSlideCanvasInnerContainer{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.editor-textSlideCanvasInnerContainer[data-state="hidden"]{
  visibility: hidden;
  pointer-events: none;
}

.editor-textSlideCanvasInnerContainer[data-state="hidden"] *{
  pointer-events: none;
}

.editor-textSlideCanvasInnerContainer--inputPreview{
  z-index: 10;  
}

.editor-textSlideCanvasInnerContainer--inputPreview,
.editor-textSlideCanvasInnerContainer--inputPreview * {
  pointer-events: none !important;
}

.editor-textSlideCanvasInnerContainer--inputPreview canvas{
  background: none;
}


.editor-textSlideCanvasInnerContainer--input{
  display: flex;
  align-items: center;
  justify-content: center; 

  z-index: 5;
}



.editor-textSlide-textArea{
  position: relative;
  // background: $tempr;
  //background: rgba(0,0,0,0.05);
  border-radius: 6px;
  z-index: 2;
}

.editor-textSlide-textAreaDeactive{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.editor-textSlide-textArea:hover,
.editor-textSlide-textArea:focus-within{
  background: rgba(0,0,0,0.02);
}

.editor-textSlide-textArea:hover .ProseMirror,
.editor-textSlide-textArea:focus-within .ProseMirror{
  background: 1px solid rgba(0,0,0,0.15);
}


.editor-textSlide-textArea::selection,
.editor-textSlide-textArea input::selection,
.editor-textSlide-textArea div::selection{
	background-color: #C3D7FF !important;
}

.editor-textSlide-textArea,
.editor-textSlide-textArea *{
	//background-color: #C3D7FF !important;
	caret-color: hsl(213, 100%, 45%) !important;
}



.editor-textSlide-textArea #text_slide_pm_input{
  // color: $invisible;
  width: 100%;

  color: $invisible;
  
  box-sizing: border-box;
}

.editor-textSlide-textArea--devMode #text_slide_pm_input{
	color: blue;
  opacity: 0.75;
}



.editor-textSlide-textArea .ProseMirror{
  // outline: 1px solid rgba(0,0,0,0.1);
  outline: 1px solid lighten(rgba(91,154,255, 0.25), 5%) !important;
  

  padding-left: 30px;
  padding-right: 30px;

  font-feature-settings: 'liga' 1; // ff and things like that
  //
  // 
  //letter-spacing: 0.011em;

  // outline: none;
  // background: none;

}

$figmaBlue: #3F8AE2;
$specialYellow: #FFDB39;

.editor-textSlide-textArea--standardColors .ProseMirror{
  outline: 3px solid transparentize($figmaBlue, 0.7) !important;
}

.editor-textSlide-textArea--standardColors:hover .ProseMirror{  
  //outline: 2px solid lighten(rgba(91,154,255,0.8), 5%) !important;
  outline: 3px solid transparentize($figmaBlue, 0.5) !important;
}

.editor-textSlide-textArea--standardColors:hover .ProseMirror-focused{    
  outline: 3px solid $figmaBlue !important;
}

.editor-textSlide-textArea--defaultBlue .ProseMirror{
  outline: 3px solid transparentize($specialYellow, 0.7) !important;
}

.editor-textSlide-textArea--defaultBlue:hover .ProseMirror{    
  outline: 3px solid transparentize($specialYellow, 0.5) !important;
}

.editor-textSlide-textArea--defaultBlue:hover .ProseMirror-focused{    
  outline: 3px solid $specialYellow !important;
}

.editor-textSlide-textArea--defaultBlue,
.editor-textSlide-textArea--defaultBlue *{
  //background-color: #C3D7FF !important;
  caret-color: $specialYellow !important;
}


.editor-textSlide-textArea--devMode .ProseMirror{
	outline: 2px solid red !important;
}

.textSlide-PMContainer
.text-slide-para{
  margin-bottom: 0.05em;
}


.textSlide-PMContainer--isList
.text-slide-para{
  margin-bottom: 0.8em;
}

.textSlide-PMContainer--isList
.text-slide-para:last-child{
  margin-bottom: 0px;
}
