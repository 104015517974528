



.editor-preview{
  position: fixed;
  z-index: 100;

  box-sizing: border-box;  

  z-index: 10;

  
}


// .editor-preview-leftPanelContainer{
//   background: $tempr;
// }

// .editor-preview{
//   background: $tempb;
// }

// .editor-transcriptPanelContainer{
//   background: $tempg;
// }

.editor-preview-videoContainer{
  position: absolute;
  top: -1px;
  left: -1px;  
  width: calc(100% + 2px);
  height: calc(100% + 2px);

  // background: $tempb;
  box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.22);
  outline: 1px solid rgba(0,0,0,0.12);
  border-radius: 4px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;  

  
}

.editor-mat{
  position: fixed;
  z-index: 5;

  // background: $tempr;

  
  border: 1px solid var(--line400);
  border-bottom: 1px solid var(--line500);
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);
  background: var(--bg-transcriptPanel);
  border-radius: 6px;
  border: var(--border-panel);

}

.editor-preview-video{
  // position: absolute;
  // top: 0px;
  // left: 0px;
  
  
  overflow: hidden;
  border-radius: 4px;

  //box-shadow: 0px 2px 4px 0px rgba(65,111,226,0.75);
}



@import 'Editor-Preview-Camera';

.editor-preview-loadingPrototypeContainer{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  z-index: 10;
  // background: $tempr;
}

.editor-preview-loadingPrototypeContainer[data-state="active"]{
  // background: $tempb;
}

//

.editor-preview-animateButton{
  position: fixed;
  top: 6px;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;  

  z-index: 9999;
  height: 30px;

  border-radius: 4px;

  background: rgba(255,255,255,0.12);
}

.editor-preview-animateButton:hover{
  background: rgba(255,255,255,0.16); 
}

.editor-preview-animateButton--play{
  margin-left: -210px;
}

.editor-preview-animateButton--reset{
  margin-left: 210px;
}

.editor-preview-animateButton svg{
  width: 20px;
  height: 20px;

  fill: white;
  opacity: 0.4;
}

.editor-preview-animateButton svg.icon--name--playbackPlay{
  width: 14px;
  height: 14px;
}

.editor-preview-animateButton:hover svg{
  opacity: 0.8;
}



//

.editor-preview .gradient-circle{
  fill: url(#effectLoadGradient);
}

.editor-preview .gradient-circleInner{
  fill: url(#effectLoadGradient);
  opacity: 0.2;
}

.editor-preview .gradient-rect{
  fill: rgba(255,255,255,0.5);
}

//
// HOVER EFFECT

.editor-preview-hoverEffect{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  z-index: 0;
}

.applyEffectCircleAnimateContainer{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  z-index: 80;
}

.editor-preview-hoverEffect-screenshot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  transition: opacity linear 100ms;
  object-fit: contain;
}

.editor-preview-hoverEffect-screenshot--original{
  z-index: 50;
  background: $tempr;
}

.editor-preview-hoverEffect-screenshot[data-state="hidden"]{
  opacity: 0;
}

.editor-preview-hoverEffect-screenshot--new{
  z-index: 20;
  background: $tempb;
}

.editor-preview-hoverEffect-screenshot img{
  width: 100%;
  height: 100%;
  
}

// .editor-preview-canvasContainer{
  
//   //width: 100%;
//   width: 100%;

//   aspect-ratio: 16/9;

  
//   background: $tempr;

//   background: var(--235gray);

//   display: flex;
//   align-items: center;
//   justify-content: center;

//   position: relative;
// }

// .editor-preview-canvas{
//   // background: $tempb;
//   // display: none;

//   // border-radius: 4px;
//   // // overflow: hidden;
// }

// .editor-preview-canvasMeasurer{
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 0px;
//   left: 0px;
//   bottom: 0px;
//   right: 0px;

//   pointer-events: none;

// }
