.rightPanel{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	background: $panelBackground;
	z-index: 5;

	
  color: var(--text500);

  display: flex;
	flex-direction: column;

  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 14px;

  padding-left: 22px;
  padding-right: 22px;
  //padding-right: 24px;
  padding-top: 24px;

	padding-bottom: 8px;	

}

.rightPanel--project{
	z-index: 1;
}

.rightPanel--slide{

}

.rightPanel-dev{
	font-size: 14px;
}



// .rightPanel button{
//   width: 180px;
//   height: 30px;
//   color: var(--text500);

//   background: var(--250gray);
// }

// .rightPanel button[data-state="inactive"]{
//   opacity: 0.25;
// }


.rightPanel-header{
	display: flex;
	align-items: center;

	margin-top: -8px;

	user-select: none;
	cursor: default;

	margin-bottom: 10px;
}

.rightPanel-header-iconContainer{
	width: 24px;
	height: 24px;

	margin-right: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.rightPanel-header-iconContainer svg{
	width: 21px;
	height: 21px;

	fill: var(--text500);
	opacity: 0.2;
	position: relative;
	//top: 1px;
}

.rightPanel-header-label{
	font-size: 15.75px;
	font-weight: $fw-semibold;
	color: var(--text500);
	margin-right: auto;
}

.rightPanel-header-label-sub{
	opacity: 0.5;
}

.rightPanel-header-lightButton{	
	height: 26px;
	// background: $tempr;

	padding-right: 12px;
	padding-left: 12px;
	border-radius: 3px;

	margin-right: -8px;

	display: flex;
	align-items: center;
	justify-content: center;	

	font-size: 14px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  	font-variation-settings: "wght" 410;
  	letter-spacing: 0.008em;

  	opacity: 0.5;
}

.rightPanel-header-lightButton:hover{
	opacity: 1;
	background: var(--250gray);
}


.rightPanel-vSpacer{
	min-height: 1px;
	flex-grow: 1;
	flex-shrink: 1;
}

.rightPanel-separator{
	width: 100%;
	margin-top: 5px;
	margin-bottom: 12px;
	height: 1px;

	background: var(--line100);
}


.rightPanel-subheader{
	font-size: 13.5px;
	font-weight: $fw-semibold;
	color: var(--text500);
	opacity: 0.7;
	letter-spacing: 0.008em;

	user-select: none;

	margin-left: 6px;
	margin-top: 2px;
	margin-bottom: 8px;
}

//
//


.rightPanel-row-label{
	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;

	margin-right: 27px;

}

.rightPanel-row-label-iconContainer{
	margin-left: -5px;
	margin-right: 1px;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.rightPanel-row-label-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: white;
	opacity: 0.4;
}

.rightPanel-row-label{
	font-size: 14px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  	font-variation-settings: "wght" 440;
  	letter-spacing: 0.008em;

  	opacity: 0.75;
}

.rightPanel-row-right{
	width: 57%;
}



.rightPanel-row--transitionBy{
	margin-left: 5px;
	margin-top: -7px;
	position: relative;
	z-index: 10;
	top: 4px;

	height: 24px;
}

.rightPanel-row--transitionBy--empty{
	height: 0px;
	margin-top: -16px;
}


.rightPanel-row-smallLabel{
	//text-transform: uppercase;
  	opacity: 0.9;
  	margin-right: 4px;
  	user-select: none;

}

.rightPanel-row-smallLabelBtn,
.rightPanel-row-smallLabel{
	font-size: 12px;
	font-weight: $fw-medium;
	color: var(--text600);

	font-family: system-ui, sans-serif;
  	font-variation-settings: "wght" 500;
  	letter-spacing: 0.008em;
}


.rightPanel-row-smallLabelBtn{
	// background: $tempr;
	height: 24px;
	padding-left: 5px;
	padding-right: 5px;
	cursor: pointer;
	border-radius: 3px;
}

.rightPanel-row-smallLabelBtn:hover{
	background: var(--250gray);
}

.rightPanel-row-smallLabelBtn[data-state="active"]{
	opacity: 0.75;
}

.rightPanel:hover
.rightPanel-row-smallLabelBtn[data-state="active"]{
	opacity: 0.9;
}

.rightPanel:hover
.rightPanel-row-smallLabelBtn[data-state="active"]:hover{
	opacity: 1;
}


.rightPanel-row-smallLabelBtn[data-state="inactive"]{
	display: none;
}

.rightPanel:hover
.rightPanel-row-smallLabelBtn[data-state="inactive"]{
	display: flex;
	opacity: 0.45;
}

.rightPanel:hover
.rightPanel-row-smallLabelBtn[data-state="inactive"]:hover{
	display: flex;
	opacity: 0.8;
}




//
//

.rightPanel--zoom-rnd{
	// background: $tempr;
	margin-top: 4px;
	margin-left: 2px;

	position: relative;

	background: rgb(105, 133, 255);
	overflow: hidden;
	border-radius: 2px;
	outline: 1px solid rgba(255,255,255,0.2);

	margin-bottom: 8px;
}
	

.rightPanel--zoom-rnd-safeArea{
	overflow: hidden;
}

.rightPanel--zoom-rnd-zoomBox{
	// background: $tempr;
	// border: 1px solid red;	
	// border: 1px solid white;
	z-index: 50;

	outline: solid 400px rgba(0,0,0,0.35);
}

 .rightPanel--zoom-rnd-zoomBox:before {
    content:"";
    top:-1px;
    left:-1px;
    right:-1px;
    bottom:-1px;
    border: white 1px solid;
    position: absolute;
    border-radius: 2px;
}


.rightPanel--zoom-rnd-meshPreview{	
	pointer-events: none;
	background: white;
	border-radius: 2px;
	outline: 1px solid rgba(0,0,0,0.1);
}

.rightPanel--zoom-rnd-safeArea,
.rightPanel--zoom-rnd-meshPreview{
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
}

.rightPanel-row-test{
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.rightPanel-row-test
.uiButton--preview{
	width: calc(33% + 1px);
	text-align: center;
	justify-content: center;
	border-radius: 2px;

}

.rightPanel-row-test
.uiButton--preview[data-state="inactive"]{
	background: lighten(rgb(28,29,33), 5%);
}





@import 'EditorRightPanel-Charts';