.editor-canvas-trimVideoPlayerOuterContainer{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;  

  background: var(--bg-canvasContainer);
  outline: 2px solid var(--bg-canvasContainer);

  display: flex;
  align-items: center;
  justify-content: center;


}

.editor-canvas-trimVideoPlayerInnerContainer{


  background: blue;

  
}


.editor-canvas-trimVideoPlayer{
  
}

.tempProgressBar{
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100px;

  background: $tempr;

  z-index: 50;
}

.tempProgressBar button{
  background: red;
}