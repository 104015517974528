
.addFromLibraryDialog-list-headerContainer{
	
	width: 100%;
}

.addFromLibraryDialog-list-headerContainer--inputEmpty{
	height: 30px;
}

.addFromLibraryDialog-list-header{
	height: 30px;
	width: 100%;
	// background: green;

	flex-grow: 0;
	flex-shrink: 0;

	// position: sticky;
	// top: -2px;

	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding-left: 32px;
	padding-right: 32px;
	
	// height: -40px;

	z-index: 50;

	border-radius: 3px;
}

.addFromLibraryDialog-list-header--fixed{
	position: absolute;
	top: 14px;
	left: 0px;
}

.addFromLibraryDialog-list-header-tabsContainer{
	display: flex;
	align-items: center;
	height: 100%;

	box-sizing: border-box;
}


.addFromLibraryDialog-list-header-tab{
  padding-left: 8px;
  padding-right: 8px;

  height: 28px;

  border-radius: 3px;
  position: relative;
}

.addFromLibraryDialog-list-header-tab:hover{
  background: var(--250gray); 
}

.addFromLibraryDialog-list-header-tab[data-state="inactive"]{
  opacity: 0.5;
}

.addFromLibraryDialog-list-header-tab[data-state="inactive"]:hover{
  opacity: 1;
}

.addFromLibraryDialog-list-header-tab-label{
  font-size: 15.5px;
  font-weight: $fw-medium;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;
}

  
.addFromLibraryDialog-list-header-tab-bottomBorder{
  position: absolute;
  bottom: 0px;
  left: 4px;
  right: 4px;
  height: 1.5px;
  background: var(--primary500);
  opacity: 0;

}

.addFromLibraryDialog-list-header-tab[data-state="active"]
.addFromLibraryDialog-list-header-tab-bottomBorder{  
  opacity: 1;
}

//

.addFromLibraryDialog-list-header-hSpacer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
}

.addFromLibraryDialog-list-header-smallSpacer{
	width: 4px;
}

//


.addFromLibraryDialog-list-header-rightContainer{
	display: flex;
	align-items: center;
	justify-content: center;
}

.addFromLibraryDialog-list-header-rightContainer
.uiButton{
	background: none;
}
