$lengthMarkerYellow: #FFDC5B;

.editor-timeline-previewProgress{
  position: absolute;
  top: 2px;  
  top: 30px;  
  height: 3.5px;

  background: red;

  z-index: 200;

  user-select: none !important;

  background: rgba(255,142,7,0.25);
  border-radius: 1px;
}

.editor-timeline-previewProgress-track{
  position: absolute;
  left: 0px;
  height: 100%;
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;

  background: rgb(255,142,7);
}
//

.editor-timeline-videoLengthMarker{
  position: absolute;
  top: 2px;  
  top: 7px;  
  width: 52px;
  height: calc(#{$height-timeline-timeline} + 4px);
  height: calc(#{$height-timeline-timeline} + 2px);

  margin-left: calc(#{$width-timeline-sidePanel} - 4px);

  z-index: 600;

  cursor: grab;

  user-select: none !important;
}

.editor-timeline-videoLengthMarker-marker{
  position: absolute;
  left: 7px;
  width: 44px;
  height: calc(100% - 8px);
  height: calc(100% - 6px);
  top: 3px;
  background: red;

  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;  

  background: $lengthMarkerYellow;

  font-size: 12.5px;
  
  font-weight: $fw-semibold;

  letter-spacing: -0.02em;

  color: rgb(20,20,30);

  transform-origin: left center;

  // opacity: 1;
  // transform: translateX(-1px);
  // transition: all 75ms linear;
}

// .editor-timeline-videoLengthMarker:hover
// .editor-timeline-videoLengthMarker-marker{
//   opacity: 1; 
//   transform: translateX(0px);
// }

.editor-timeline-videoLengthMarker-line{
  position: absolute;
  left: 2px;
  top: 0px;
  bottom: 0px;
  height: 100%;
  border-radius: 2px;

  width: 3px;

  background: $lengthMarkerYellow;

}

.editor-timeline-videoLengthMarker--faded{
  opacity: 0.5;
}

.editor-timeline-videoLengthMarker--faded
.editor-timeline-videoLengthMarker-marker{
  opacity: 0;
}

