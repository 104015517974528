.uiSmallTabGroup{
	display: flex;
	height: 30px;	
	margin-bottom: 6px;

	margin-top: -1px;
}

.uiSmallTab{
	
	user-select: none;

	padding-left: 6px;
	padding-right: 6px;

	position: relative;
	
}

.uiSmallTab-label{
	font-size: 13.5px;
	font-weight: $fw-semibold;
	font-variation-settings: "wght" 580;
	color: var(--text500);	
	letter-spacing: 0.008em;
}

.uiSmallTab-underline{
	position: absolute;
	bottom: 1px;
	left: 3px;
	width: calc(100% - 6px);
	height: 1.5px;
	border-radius: 2px;
}

.uiSmallTab[data-state="active"]{
	
}

.uiSmallTab[data-state="inactive"]{	
	opacity: 0.4;
}

.uiSmallTab[data-state="inactive"]:hover{
	opacity: 0.8;
}

.uiSmallTab[data-state="inactive"]:hover
.uiSmallTab-underline{
	background: rgba(255,255,255,0.2);
}


.uiSmallTab[data-state="active"]
.uiSmallTab-underline{
	background: #516CE8;
}

.uiSmallTab[data-state="active"]
.uiSmallTab-label{
	//color: lighten(#516CE8, 20%);
}