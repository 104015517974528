

.editor-timeline-sceneHeader{
	position: absolute;
	height: 28px;

	top: -18px;
	top: -21px;
	// background: darken(rgb(67,65,76), 13%);
	// background: lighten(rgb(25,27,33), 1.5%);
	
	// border: 1px solid darken(rgb(98,100,110), 25%);
	//border-top: none;

	border-radius: 2px;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-sizing: border-box;
	padding-left: 2px;
	// padding-top: 3px;
	

  user-select: none;  

  z-index: 40;

  // background: $tempr;
}

.editor-timeline-sceneHeader:hover{
	
}

.editor-timeline-sceneHeader-label{
	font-size: 14px;
	font-weight: $fw-medium;
	color: var(--text400);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.014em;
  
  opacity: 0.95;

  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;


	// color: $invisible;
	position: sticky;
  left: -4px;
}

.editor-timeline-sceneHeader-label[data-untitled-scene='true']{
	visibility: hidden;
}

.editor-timeline-sceneHeader-label-label{
	position: relative;
	z-index: 5;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editor-timeline-sceneHeader-label-bg{
	position: absolute;
	top: -3px;
	left: -25px;
	right: -40px;
	bottom: -3px;
	background: linear-gradient(to right, var(--bg-editorTimeline) 0%, var(--bg-editorTimeline) 70%, rgba(27,28,29,0.9) 85%, transparent 100%);
}

.editor-timeline-sceneHeader-label:hover{
	background: var(--250gray);
}

.editor-timeline-sceneHeader-label-timestamp{
	margin-left: 4px;
	opacity: 0.5;
	font-variation-settings: "wght" 440;
	font-size: 14px;

	display: none;
}


.editor-timeline-deleteSceneButton{
	position: absolute;
	
	position: absolute;
	height: 28px;

	top: -18px;
	top: -21px;

	z-index: 55;
	background: $tempr;
	transform: translateX(-100%);

	background: var(--bg-editorTimeline);

	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-timeline-deleteSceneButton:hover{
	// background: $tempb;
}

.editor-timeline-deleteSceneButton-inner{
	padding-left: 6px;
	padding-right: 6px;

	display: flex;
	align-items: center;
	justify-content: center;

	height: 20px;

	border-radius: 4px;
	background: var(--252gray);
	border: 1px solid var(--line150);

	font-size: 12px;
	font-weight: $fw-medium;
	color: var(--text400);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.018em;

  opacity: 0.75;
}
	

.editor-timeline-deleteSceneButton:hover
.editor-timeline-deleteSceneButton-inner{
	background: var(--250gray);
	border: 1px solid var(--line200);
	color: var(--text400);

	opacity: 1;
}
