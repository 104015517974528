
.editor-timelineFooter-musicVolume{
  position: absolute;
  left: 100%;
  margin-left: 4px;  
  height: 28px;
  top: 0px;
  width: 36px;

  border-radius: 4px;

  display: flex;
  align-items: center;

  background: var(--251gray);
  border-radius: 3px;

  overflow: hidden;

  transition: all 0ms linear;
  transition-delay: 400ms;

  cursor: pointer;
}


.editor-timelineFooter-musicVolume:hover,
.editor-timelineFooter-musicVolume[data-state="forceVisible"]{
  width: 150px;
  transition-delay: 0ms;
  background: var(--250gray);
}

.editor-timelineFooter-musicVolume-iconContainer{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 36px;
  height: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;

  // background: $tempr;
}

.editor-timelineFooter-musicVolume-iconContainer svg{
  width: 22px;
  height: 22px;
  fill: var(--text500);
  opacity: 0.5;
  position: relative;
  //left: 1px;
  transition: opacity 100ms linear;
  transition-delay: 50ms;
}

.editor-timelineFooter-musicVolume:hover
.editor-timelineFooter-musicVolume-iconContainer svg,
.editor-timelineFooter-musicVolume[data-state="forceVisible"]
.editor-timelineFooter-musicVolume-iconContainer svg{
  opacity: 0.9;
}

.editor-timelineFooter-musicVolume-sliderContainer{
  position: absolute;
  left: 35px;

  width: 100px;
  height: 100%;
  
  position: relative;

  opacity: 0;
  transition: opacity 100ms linear;
  transition-delay: 375ms;
}


.editor-timelineFooter-musicVolume:hover
.editor-timelineFooter-musicVolume-sliderContainer,
.editor-timelineFooter-musicVolume[data-state="forceVisible"]
.editor-timelineFooter-musicVolume-sliderContainer{
  opacity: 1;
  transition: opacity 50ms linear;
  transition-delay: 75ms;
}


.editor-timelineFooter-musicVolume-slider{
  height: 100%;
  width: 100px;
  cursor: pointer;
  // background: $green;
}

.editor-timelineFooter-musicVolume-slider-track{
  margin-top: 13px;
  height: 3px;
  border-radius: 2px;
  overflow: hidden;
  // margin-top: 7px;
}

.editor-timelineFooter-musicVolume-slider-track-0{
  background: var(--text400);
  border-radius: 2px;
  // border-top-right-radius: 0px;
  // border-bottom-right-radius: 0px;
}

.editor-timelineFooter-musicVolume-slider-track-1{ 
  border-radius: 2px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  
  background: rgba(255,255,255,0.2);
}

.editor-timelineFooter-musicVolume-slider-thumb-thumb,
.editor-timelineFooter-musicVolume-slider-thumb-thumb:focus{
  display: none;

  cursor: pointer;

  width: 5px;
  height: 5px;
  background: var(--text400);
  border-radius:50px;

  // opacity: 0;
  
  margin-top: 0px;

  z-index: 10;
  outline: none;
  
  border: 1px solid var(--text400);
  outline: 1px solid rgba(255,255,255,0.8);
  
}

.editor-timelineFooter-musicVolume-slider:hover 
.editor-timelineFooter-musicVolume-slider-thumb-thumb{
  // opacity: 0.5;
}


.editor-timelineFooter-musicVolume-sliderMark{
  position: absolute;
  top: 13.5px;
  height: 6px;
  width: 2px;
  margin-top: 1px;
  margin-left: -1px;
  border-radius: 2px;
  background: var(--text400);

  transform: translateY(-50%);

  pointer-events: none;

  transition: opacity 50ms linear;
}

.editor-timelineFooter-musicVolume-sliderMark[data-state="hidden"]{
  opacity: 0;
  transition: opacity 50ms linear;
}

.editor-timelineFooter-musicVolume-sliderMark[data-state="visible"]{
  opacity: 1;
  transition: opacity 100ms linear;
  transition-delay: 100ms
}