.libraryPage-project-preview{
	width: 1920px;
	height: 1080px;

	position: relative;

}

.libraryPage-project-preview-content{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	z-index: 10;

	display: flex;
	align-items: center;
	justify-content: center;
}

//



.libraryPage-project-preview-content-textSlide{
	width: 1520px;

	box-sizing: border-box;
	padding: 24px;

	display: flex;
	align-items: center;
	justify-content: center;

	text-align: center;

	transition: transform 200ms linear;
}




//


.libraryPage-project-preview-content-screenVideo{	
	object-fit: contain;
	box-shadow: 0px 12px 36px 0px rgba(0,0,0,0.36);
	border-radius: 18px;
	overflow: hidden;

	transition: transform 200ms linear;
}

.libraryPage-project-preview-content-screenVideo video{
	width: 100%;
	height: 100%;
	border-radius: 18px;
}

.libraryPage-project-preview:hover
.libraryPage-project-preview-content-textSlide,
.libraryPage-project-preview:hover
.libraryPage-project-preview-content-screenVideo{
	// transform: scale(1.02);
	// transition: transform 100ms linear;
	
}


//

.libraryPage-project-preview-content-bg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	z-index: 5;
	border-radius: 20px;
}

.libraryPage-project-preview-content-bg--image{
	object-fit: cover;
}

.libraryPage-project-preview-content-bg--image img{
	width: 100%;
	height: 100%;
}
