.selectCaptionsDropdown{
	margin-top: 4px;
	
	//box-sizing: border-box;
	
	
	//padding-bottom: 12px;
	
	overflow: none;

	background: var(--bg-dropdown);

	border-radius: 4px;

	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);

  //padding-top: 4px;
  padding-top: 0px;
  padding-bottom: 4px;
	
	z-index: 500;

	position: relative;

	
	//width: 466px;
  width: 386px;
	display: flex;
	flex-direction: column;

	border: 1px solid rgba(255,255,255,0.055) !important;	
	//background: rgba(26,26,27,0.8);
	background: lighten(rgb(30,30,32), 1.5%);
	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.24);
	// backdrop-filter: blur(15px);
}

.selectCaptionsDropdown-header{
   height: 38px;
   display: flex;
   align-items: center;
   justify-content: center;
   box-sizing: border-box;
   padding-left: 10px;
   padding-right: 5px;
   padding-top: 5px;
}

.selectCaptionsDropdown-header-iconContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.selectCaptionsDropdown-header-iconContainer svg{
  height: 18px;
  width: 18px;
  fill: var(--text500);
  opacity: 0.55;
  position: relative;
  top: 0.5px;
}

.selectCaptionsDropdown-header-label{
  font-size: 13.75px;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.01em;

  color: var(--text500);  
  user-select: none;
  opacity: 0.8;
  
}



.selectVoiceDropdown-styleBtnList{
  width: 100%;
  display: flex;
}

//

.selectCaptionsDropdown-styleBtn{
  width: 50%;
  
  height: 70px;

  justify-content: center;
  
  font-size: 14px;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.01em;

  color: var(--text500);

  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.selectCaptionsDropdown-styleBtn-inner{
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  padding-left: 8px;
  
  //border: 1px solid var(--line100);
  border: 1px solid $invisible;

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  // padding-left: 12px;

  text-align: center;

  border-radius: 3px;
  background: var(--252gray);

  text-transform: capitalize;
}

.selectCaptionsDropdown-styleBtn:hover
.selectCaptionsDropdown-styleBtn-inner{
  background: var(--250gray);
}

.selectCaptionsDropdown-styleBtn[data-active-state='active']
.selectCaptionsDropdown-styleBtn-inner{
  border: 1px solid rgb(113,148,255);
  background: var(--251gray);
}

.selectCaptionsDropdown-styleBtn[data-active-state='active']
.selectCaptionsDropdown-styleBtn-inner:hover{
  border: 1px solid lighten(rgb(113,148,255), 5%);
  background: var(--248gray);
}



.selectCaptionsDropdown-srtBtnContainer{
   width: 100%;
   display: flex;
}

.selectCaptionsDropdown-srtBtn{
  width: 100%;
  padding-top: 8px;
  height: 36px;

  font-size: 13px;
  font-weight: $fw-medium;
  font-variation-settings: "wght" 420;
  color: var(--text500);  
  letter-spacing: 0.008em;

  opacity: 0.7;
}

.selectCaptionsDropdown-srtBtn:hover{
  opacity: 1;
}



// .captionsDropdown-header-inner:hover{
// 	//background: rgba(255,255,255,0.03);
// }




// .captionsDropdown-header-langToggleBtn,
// .captionsDropdown-header-speedToggleBtn{
	
//   opacity: 0.55;

//   padding-left: 8px;
//   padding-right: 8px;

//   height: 28px;
//   //background: $tempr;
//   border-radius: 3px;

// }



// .captionsDropdown-header-speedToggleBtn[data-state='open']{
//   background: var(--250gray);
//   opacity: 1;
// }


// .captionsDropdown-header-langToggleBtn-label,
// .captionsDropdown-header-speedToggleBtn-label{
// 	font-size: 13.5px;
// 	font-variation-settings: "wght" 440;
//   letter-spacing: 0.01em;

//   color: var(--text500);
// }


// .captionsDropdown-gridContainer-sectionHeader-langToggleTab-label,
// .captionsDropdown-gridContainer-sectionHeader{
// 	font-size: 14px;
// 	font-variation-settings: "wght" 540;
//   letter-spacing: 0.01em;

//   color: var(--text500);
// }

// .captionsDropdown-header-langToggleBtn:hover,
// .captionsDropdown-header-speedToggleBtn:hover{
// 	background: var(--251gray);
// 	opacity: 0.85;
// }



// .captionsDropdown-header-langToggleBtn{
// 	justify-content: flex-start;
// 	width: 76px;
// }

// .captionsDropdown-header-speedToggleBtn{
	
// }

// .captionsDropdown-header-langToggleBtn-iconContainer{
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

//   flex-grow: 0;
//   flex-shrink: 0;
// 	width: 20px;
// 	height: 20px;
// 	margin-left: -5px;

// }

// .captionsDropdown-header-langToggleBtn[data-global-state='true']
// .captionsDropdown-header-langToggleBtn-iconContainer{
//   //margin-left: -4.5px;
//   margin-right: 2px;
// }

// .captionsDropdown-header-langToggleBtn-iconContainer svg{
// 	width: 15px;
// 	height: 15px;
// 	fill: var(--text500);
// 	opacity: 0.75;
// }



// .captionsDropdown-header-hSpacer{
// 	flex-grow: 1;
// 	flex-shrink: 1;
// }

// .captionsDropdown-closeBtnFixedContainer{
// 	position: absolute;
// 	top: 0px;
//   height: 40px;  
// 	right: 0px;
// 	z-index: 20;
// }

// .captionsDropdown-header-closeBtn{
// 	width: 154px;
//   // background: $tempr;
// 	height: 100%;
// 	justify-content: flex-end;
// 	box-sizing: border-box;
// 	padding-right: 2px;
// 	// padding-bottom: 2px;

// 	font-size: 14px;
//   letter-spacing: 0.02em;
//   margin-bottom: 0px;

//   color: var(--text500);
  
//   font-variation-settings: "wght" 440;
//   opacity: 0.4;
//   transition: all 50ms linear;
// }

// .captionsDropdown-closeBtnFixedContainer
// .captionsDropdown-header-closeBtn{
// 	padding-right: 11px;
// }

// .captionsDropdown-header-closeBtn:hover{
// 	opacity: 0.9;
// }

// .captionsDropdown-header-closeBtn svg{
// 	width: 16px;
// 	height: 16px;
// 	fill: var(--text500);
// 	opacity: 0.5;
// 	transform: scale(1.06);
// }

// .captionsDropdown-header-closeBtn-inner{
// 	width: 32px;
// 	height: 28px;
// 	border-radius: 5px;
// 	//background: rgba(255,255,255,0.06);

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .captionsDropdown-header:hover
// .captionsDropdown-closeBtn svg{
// 	opacity: 0.6;
// }

// //

// .captionsDropdown-gridContainer{
// 	max-height: min(640px, calc(100vh - 383px));
// 	display: flex;
// 	flex-direction: column;
// 	overflow-y: overlay;
// 	scrollbar-gutter: stable;
// 	padding-bottom: 14px;
// }

// .captionsDropdown-gridContainer-customBottomSpacer{
// 	height: 12px;
// 	flex-grow: 0;
// 	flex-shrink: 0;
// }

// .captionsDropdown-gridContainer-customTopSpacer{
// 	height: 12px;
// 	flex-grow: 0;
// 	flex-shrink: 0;
// }

// .captionsDropdown-gridContainer-sectionHeader{
// 	position: sticky;
// 	top: 0px;	

// 	display: flex;
// 	align-items: center;
// 	width: 100%;
// 	box-sizing: border-box;
// 	padding-left: 10px;
// 	min-height: 40px;
// 	// background: red;
// 	z-index: 10;
// 	background: lighten(rgb(30,30,31), 2%);

// }

// .captionsDropdown-gridContainer-sectionHeader-tabs{
// 	display: flex;
// }

// .captionsDropdown-gridContainer-sectionHeader-langToggleTab{
// 	padding-left: 8px;
// 	padding-right: 8px;
// 	height: 28px;

// 	position: relative;

// }

// .captionsDropdown-gridContainer-sectionHeader-langToggleTab-label{
// 	opacity: 0.6;
// }

// .captionsDropdown-gridContainer-sectionHeader-langToggleTab[data-state='active']
// .captionsDropdown-gridContainer-sectionHeader-langToggleTab-label{
// 	opacity: 0.9;
// }

// .captionsDropdown-gridContainer-sectionHeader-langToggleTab-bottomBorder{
// 	position: absolute;
//   bottom: 0px;
//   left: 4px;
//   right: 4px;
//   height: 1.5px;
//   background: var(--primary500);
//   //background: var(--line600);
//   opacity: 0;
// }


// .captionsDropdown-gridContainer-sectionHeader-langToggleTab[data-state='active']
// .captionsDropdown-gridContainer-sectionHeader-langToggleTab-bottomBorder{
// 	opacity: 1
// }


// .captionsDropdown-gridContainer-sectionHeader-title{
// 	height: 28px;
// 	display: flex;
// 	align-items: center;
// 	justify-content: flex-start;
// 	padding-left: 8px;
// 	user-select: none;
// 	opacity: 0.6;
// }
// //
// //
// //


// .captionsDropdown-grid{
// 	display: flex;
// 	flex-direction: column;
// }

// .captionsDropdown-grid-header{
	
//   margin-bottom: 0px;
//   padding-top: 16px;
//   padding-bottom: 5px;
//   padding-left: 17px;
  
//   user-select: none;
//   font-size: 12.25px;
// 	font-variation-settings: "wght" 520;
//   letter-spacing: 0.01em;

//   color: var(--text500);
//   opacity: 0.6;
// }

// .captionsDropdown-gridContainer--hasCustomVoices
// .captionsDropdown-grid-header{
// 	font-size: 12.25px;
// 	font-variation-settings: "wght" 480;
// }



// .captionsDropdown-gridContainer
// .captionsDropdown-grid--global:first-child
// .captionsDropdown-grid-header{
// 	padding-top: 12px;
// }

// .captionsDropdown-gridContainer--hasCustomVoices
// .captionsDropdown-grid--global:nth-child(6)
// .captionsDropdown-grid-header{
// 	padding-top: 6px;
// 	display: none;
// }


// .captionsDropdown-grid-grid{
//   display: flex;
//   // flex-direction: column;
//   flex-wrap: wrap;  
//   box-sizing: border-box;
//   padding-left: 2px;

// }

// //

// .captionsDropdown-grid-item{
// 	//width: 220px;
//   width: 183px;
//   margin-left: 4px;

//   //background: var(--bg-dropdown);
//   height: 30px;
//   box-sizing: border-box;
//   padding: 0px;
//   //padding-left: 20px;
//   //font-size: 15px;
//   padding-left: 0px;
//   font-size: 14.5px;
//   letter-spacing: 0.01em;
//   margin-bottom: 0px;

//   color: var(--text500);
  
//   font-variation-settings: "wght" 440;

//   line-height: 1.8;
  
//   user-select: none;

//   display: flex;
//   position: relative;
//   justify-content: flex-start;


//   cursor: pointer;

//   border: none;
//   outline: none;
//   box-shadow: none;

//   border-radius: 3px;
// }



// .captionsDropdown-grid-item:hover:not(:has(.captionsDropdown-grid-item-sampleBtn:hover)){
//   background: var(--251gray);
//   color: var(--text600);
//   //color: $white;

//   border: none;
//   outline: none;
//   box-shadow: none;
// }



// .captionsDropdown-grid-item[data-state="active"],
// .captionsDropdown-grid-item[data-state="active"]:hover{
//   background: var(--250gray);
// }

// .dropdownMenu--selectVoice-grid
// .captionsDropdown-grid-item{
//   width: calc(50% - 8px);
//   height: 32px;
// }


// .captionsDropdown-grid-item-noSampleBtnSpacer{
// 	width: 8px;
// }




// .captionsDropdown-grid-item-sampleBtn{
//   padding-left: 5px;
//   height: 100%;
  
//   //background: var(--250gray);

//   display: flex;
//   align-items: center;
//   justify-content: center;

//   margin-right: 1px;
//   cursor: pointer;
// }

// .captionsDropdown-grid-item-sampleBtn-inner{
// 	width: 28px;
//   height: 24px;

//   border-radius: 3px;

//   display: flex;
//   align-items: center;
//   justify-content: center;
// }



// .captionsDropdown-grid-item-sampleBtn svg{
  
//   fill: white;
//   opacity: 0.2;
//   position: relative;
// }

// .captionsDropdown-grid-item-sampleBtn[data-playing-state='true'] svg{
//   width: 15px;
//   height: 15px;
// }

// .captionsDropdown-grid-item-sampleBtn[data-playing-state='false'] svg{
//   width: 20px;
//   height: 20px;
//   right: -1px;
// }

// .captionsDropdown-grid-item-sampleBtn:hover
// .captionsDropdown-grid-item-sampleBtn-inner,
// .captionsDropdown-grid-item-sampleBtn[data-playing-state='true']
// .captionsDropdown-grid-item-sampleBtn-inner{
//   background: var(--248gray);
// }


// .captionsDropdown-grid-item[data-state="active"]
// .captionsDropdown-grid-item-sampleBtn svg{
//   opacity: 0.6;
// }

// .captionsDropdown-grid-item-sampleBtn:hover svg,
// .captionsDropdown-grid-item-sampleBtn[data-playing-state='true'] svg,
// .captionsDropdown-grid-item[data-state="active"] [data-playing-state='true'] svg{
//   opacity: 1;
// }



// .captionsDropdown-grid-item-name{
//   // width: 100%;
//   margin-left: 4px;
// }

// .captionsDropdown-grid-item-sub{
//   font-variation-settings: "wght" 410;
//   opacity: 0.7;
//   margin-left: 6px;
//   opacity: 0;
//   transform: translateX(-2px);
//   transition: all 50ms linear;
// }

// .captionsDropdown-grid-item:hover
// .captionsDropdown-grid-item-sub,
// .captionsDropdown-grid-item[data-state="active"]
// .captionsDropdown-grid-item-sub,
// .captionsDropdown-gridContainer--globalLanguages
// .captionsDropdown-grid-item-sub{
// 	opacity: 0.6;
// 	transform: translateX(0px);
// 	transition-delay: 50ms;
// }

// .captionsDropdown-gridContainer--globalLanguages
// .captionsDropdown-grid-item-sub{
//   transition: all 0ms linear;
//   transition-delay: 0ms;
// }


// .captionsDropdown-grid-item-description{
//   font-variation-settings: "wght" 410;
//   opacity: 0.75;
//   margin-left: 4px;
// }

// // .captionsDropdown-grid-item-countryFlagIconContainer{
// //   margin-left: 4px;
// //   width: 30px;
// //   height: 30px;
// //   display: flex;
// //   align-items: center;
// //   justify-content: flex-start;
// // }

// // .captionsDropdown-grid-item-countryFlagIconContainer svg{
// //   width: 14px;
// //   height: 14px;

// //   position: relative;
// //   top: 2px;
// // }

// //




// .captionsDropdown-footer{
// 	height: 40px;
// 	width: 100%;
//   box-sizing: border-box;
//   padding-left: 8px;

// 	// background: $tempr;

// 	display: flex;
//   align-items: center;
// }


// //

// .dropdownMenu--selectVoice-voPlaybackRate{
//   display: flex;  

//   margin: 0 auto;
//   box-sizing: border-box;

//   align-items: center;

//   width: calc(100% - 14px - 14px);
//   margin-left: 14px;
  
//   padding-top: 10px;
//   padding-bottom: 2px;

//   // padding-left: 16px;
//   // padding-right: 16px;
//   // // padding-left: 3px;
//   // padding-right: 3px;
//   // border-radius: 4px;

//   margin-top: 11px;
//   margin-bottom: 3px;

//   //background: $tempr;
//   //background: rgba(255,255,255,0.03);

//   border-top: 1px solid var(--line100);

// }

// .dropdownMenu--selectVoice-voPlaybackRate-title{
//   font-size: 14px;
//   letter-spacing: 0.01em;
//   color: var(--text500);
//   font-variation-settings: "wght" 440;

//   display: flex;
//   align-items: center;

//   height: 28px;

//   cursor: default;

//   // background: $tempr;
// }

// .dropdownMenu--selectVoice-voPlaybackRate-title-iconContainer{
//   width: 24px;
//   height: 24px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-right: 4px;
// }

// .dropdownMenu--selectVoice-voPlaybackRate-title-iconContainer svg{
//   width: 16px;
//   height: 16px;
//   fill: var(--text500);
//   opacity: 0.7;
// }

// .dropdownMenu--selectVoice-voPlaybackRate-buttonRow{
//   margin-left: auto;


//   //width: 100%;
//   display: flex;
//   // justify-content: center;
//   opacity: 0.8;

// }

// .dropdownMenu--selectVoice-voPlaybackRate-buttonRow:hover{
//   opacity: 1;
// }


// .dropdownMenu--captionsDropdown-voPlaybackRateBtn{

//   //width: 82px;

//   //background: var(--bg-dropdown);
//   height: 27px;
//   box-sizing: border-box;
  

//   padding-left: 14px;
//   padding-right: 14px;
  
//   margin-bottom: 0px;

//   font-size: 14px;
//   letter-spacing: 0.01em;
//   color: var(--text500);
//   font-variation-settings: "wght" 440;

//   line-height: 1.8;
  
//   user-select: none;

//   display: flex;
//   position: relative;

//   cursor: pointer;

//   border: none;
//   outline: none;
//   box-shadow: none;

//   border-radius: 3px;

//   opacity: 0.75;

// }

// .dropdownMenu--captionsDropdown-voPlaybackRateBtn:hover{  
//   background: var(--hoverBG-item-dropdown);
//   color: var(--text600);  

//   border: none;
//   outline: none;
//   box-shadow: none;

//   opacity: 1;
// }

// .dropdownMenu--captionsDropdown-voPlaybackRateBtn[data-state="active"]{
//   background: var(--activeBG-suggestionItem) !important;
//   opacity: 1;
// }



// .dropdownMenu--selectVoice-banner{
//   width: calc(100% - 10px);
//   margin-left: 5px;
//   margin-top: 4px;

//   background: $tempr;

//   border-radius: 4px;

//   background: lighten(rgb(33,33,37), 3%);

//   padding-top: 4px;
//   padding-bottom: 4px;

//   cursor: default;
//   user-select: none;
// }


// .dropdownMenu--selectVoice-banner-primary{
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
// }


// .dropdownMenu--selectVoice-banner-primary-iconContainer{
//   width: 24px;
//   height: 24px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-left: 4px;
//   margin-right: 1px;
// }

// .dropdownMenu--selectVoice-banner-primary-iconContainer svg{
//   width: 18px;
//   height: 18px;
//   fill: white;
//   opacity: 0.5;
// }

// .dropdownMenu--selectVoice-banner-primary-label,
// .dropdownMenu--selectVoice-banner-primary-sub{  
//   font-size: 14px;
//   letter-spacing: 0.01em;
//   margin-bottom: 0px;

//   color: var(--text500);
  
//   font-variation-settings: "wght" 480;
//   opacity: 0.75;
// }

// .dropdownMenu--selectVoice-banner-primary-sub{
//   opacity: 0.4;
//   margin-left: 3px;
// }