
@font-face {
    font-family: 'YSFMono';        
    src: url(../fonts/YSFMono/YSFMono-Light.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-light;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YSFMono';        
    src: url(../fonts/YSFMono/YSFMono-Regular.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-regular;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YSFMono';        
    src: url(../fonts/YSFMono/YSFMono-Medium.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-medium;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YSFMono';        
    src: url(../fonts/YSFMono/YSFMono-Semibold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-semibold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YSFMono';        
    src: url(../fonts/YSFMono/YSFMono-Bold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YSFMono';        
    src: url(../fonts/YSFMono/YSFMono-Heavy.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-heavy;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YSFMono';        
    src: url(../fonts/YSFMono/YSFMono-Black.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-black;
    text-rendering: optimizeLegibility;
}
