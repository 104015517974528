.libraryPage-forYouContainer{
	width: 100%;
	
	display: flex;

	box-sizing: border-box;
	padding-left: calc(#{$library-leftInset} - 40px);

	// background: $tempr;
	position: relative;
}

.libraryPage-forYouInnerContainer{
	display: flex;	
}

.libraryPage-forYou-edgeButton{
	position: absolute;
	top: 0px;
	height: 100%;
	width: 50px;
	background: rgba(29,29,31, 0.75);
	// background: rgba(0,0,255,0.5);

	transition: opacity 75ms linear;
}

.libraryPage-forYou-edgeButton svg{
	width: 28px;
	height: 28px;
	fill: var(--text500);
	opacity: 0.75;
}

.libraryPage-forYou-edgeButton[data-state-visible="true"]{
	opacity: 0.95;
}

.libraryPage-forYou-edgeButton[data-state-visible="false"]{
	opacity: 0;
	pointer-events: none;
}

.libraryPage-forYou-edgeButton[data-state-visible="true"]:hover{
	opacity: 0.65;
}

.libraryPage-forYou-edgeButton[data-state-visible="true"]:hover svg{
	opacity: 1;
}

.libraryPage-forYou-edgeButton--left{
	left: 0px;
}

.libraryPage-forYou-edgeButton--left svg{
	transform: scaleX(-100%);
}

.libraryPage-forYou-edgeButton--right{
	right: 0px;
}