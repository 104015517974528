
//
//



.workflowPopover{
	width: 350px;
	height: 540px;

	padding-top: 4px;
	padding-bottom: 4px;
	border-radius: 5px;

	box-shadow: none;
	

	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);
  box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.28);

  background: rgb(25, 27, 33);
  
	display: flex;
	flex-direction: column;	

	overflow: hidden;
}

.workflowPopover:focus{
	outline: none;
}


.workflowPopover-header{	
	flex-grow: 0;
	flex-shrink: 0;

	padding-top: 4px;
	padding-left: 18px;	
	padding-bottom: 8px;	

	// padding-top: 10px;
	// padding-left: 32px;
	// padding-right: 32px;

	box-sizing: border-box;
	width: 100%;

	display: flex;
}

// .workflowPopover-header--scrolled{
// 	padding-left: calc(100vw - 100px - 100px - 540px);
// 	padding-right: 230px;
// }


.workflowPopover-header-tabBar{
	display: flex;
	color: white;

	
	height: 28px;
	height: 27px;
	margin-left: -5px;
}

.workflowPopover-header-tabBar-tab{
	color: white;
	// padding-left: 5px;
	// padding-right: 10px;
	padding-left: 8px;
	padding-right: 8px;
	border-radius: 3px;
	position: relative;
}

.workflowPopover-header-tabBar-tab:hover{
	background: var(--250gray);
}

.workflowPopover-header-tabBar-tab[data-active-state='active']{
	opacity: 1;
	//background: var(--251gray);
}

.workflowPopover-header-tabBar-tab[data-active-state='active']:hover{
	opacity: 1;
}

.workflowPopover-header-tabBar-tab[data-active-state='inactive']{
	opacity: 0.5;
}

.workflowPopover-header-tabBar-tab[data-active-state='inactive']:hover{
	opacity: 1;
	//background: var(--252gray);
}

.workflowPopover-header-tabBar-tab-iconContainer{
	width: 28px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.workflowPopover-header-tabBar-tab-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: white;
	opacity: 0.75;
}

.workflowPopover-header-tabBar-tab-iconContainer svg.icon--name--addFromLibrary{
	position: relative;
	top: 1px;
	transform: scale(0.85);
	transform-origin: center center;
}

.workflowPopover-header-tabBar-tab-label{
	font-size: 14px;
	font-size: 13.75px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 500;
  letter-spacing: 0.008em;
}


.workflowPopover-header-tabBar-tab-bottomBorder{
  position: absolute;
  bottom: 0px;
  left: 4px;
  right: 4px;
  height: 1.5px;
  background: var(--primary500);
  opacity: 0;
}

.workflowPopover--guestColab
.workflowPopover-header-tabBar-tab-bottomBorder{
	background: var(--guestColabText);
}

.workflowPopover-header-tabBar-tab[data-active-state="active"]
.workflowPopover-header-tabBar-tab-bottomBorder{  
  opacity: 1;
}


//

.workflowPopover-mainContainer{
	padding-left: 18px;
	padding-right: 12px;
	overflow-y: scroll;
	overflow-x: hidden;
	padding-bottom: 18px;
	scrollbar-gutter: stable;
}

//
// 
// VARIABLES


// LINK

.workflowPopover--guestColab-variables-link{
	width: calc(100% + 12px);
	margin-left: -6px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 10px;
	padding-bottom: 9px;
	border-radius: 4px;

	box-sizing: border-box;

	background: var(--251gray);
	// background: $tempr;
}

.workflowPopover--guestColab-variables-link-title,
.workflowPopover--guestColab-variables-inputs-title{
	font-size: 12.75px;
	font-weight: $fw-medium;
	color: var(--text400);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 580;
  letter-spacing: 0.008em;

  margin-bottom: 6px;
}



.workflowPopover--guestColab-variables-link-variable{
	display: flex;
	align-items: center;
	height: 24px;
}

.workflowPopover--guestColab-variables-link-variable-iconContainer{
	width: 20px;
	height: 20px;
	//border-radius: 2px;
	//background: rgba(255,255,255,0.08);

	margin-right: 6px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.workflowPopover--guestColab-variables-link-variable-iconContainer svg{
	width: 15px;
	height: 15px;
	fill: var(--text500);
}


	

.workflowPopover--guestColab-variables-link-variable-name{
	flex-grow: 1;
	flex-shrink: 1;

	font-size: 13.25px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 420;
  letter-spacing: 0.008em;	

  user-select: none;
}

.workflowPopover--guestColab-variables-link-variable-btn,
.workflowPopover--guestColab-variables-input-variable-title-linkBtn{
	height: 24px;
	font-size: 12.75px;
	font-weight: $fw-medium;
	color: var(--text500);


	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 420;
  letter-spacing: 0.008em;	

  opacity: 0.75;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: -4px;

  cursor: pointer;

  opacity: 0.55;
  transition: opacity 100ms linear;
}




// .workflowPopover--guestColab-variables-link-variable:hover
// .workflowPopover--guestColab-variables-link-variable-btn,
// .workflowPopover--guestColab-variables-input-variable:hover
// .workflowPopover--guestColab-variables-input-variable-title-linkBtn{
// 	opacity: 0.85;
// }

.workflowPopover--guestColab-variables-link-variable:hover
.workflowPopover--guestColab-variables-link-variable-btn:hover,
.workflowPopover--guestColab-variables-input-variable:hover
.workflowPopover--guestColab-variables-input-variable-title-linkBtn:hover{
	opacity: 1;
}


// INPUTS
.workflowPopover--guestColab-variables-inputs{
	margin-top: 20px;
	box-sizing: border-box;
	padding-left: 5px;
	user-select: none;
  cursor: default;
}



.workflowPopover--guestColab-variables-input-variable{
	display: flex;
	flex-direction: column;	
	align-items: flex-start;
	margin-bottom: 8px;

	padding-top: 6px;
}

.workflowPopover--guestColab-variables-input-variable-counter{
	display: flex;
	align-items: center;
	height: 18px;
	box-sizing: border-box;
	padding-left: 2px;
	padding-right: 5px;
	//background: var(--guestColabBG);

	border-radius: 3px;
	margin-left: -1px;
	margin-bottom: 2px;

}

.workflowPopover--guestColab-variables-input-variable-counter-typeIconContainer{
	width: 18px;
	height: 18px;
	margin-right: 4px;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	opacity: 0.75;
}

.workflowPopover--guestColab-variables-input-variable-counter-typeIconContainer svg{
	fill: var(--guestColabText);
	width: 15px;
	height: 15px;
}

.workflowPopover--guestColab-variables-input-variable-counter-typeIconContainer svg.icon--name--textSlideClip{
	width: 16px;
	height: 16px;
	position: relative;
	left: -2px;
}

.workflowPopover--guestColab-variables-input-variable-counter-index{
	font-size: 12px;
	font-weight: $fw-medium;
	color: var(--text500);
	color: var(--guestColabText);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 550;
  letter-spacing: 0.008em;
  font-feature-settings: "tnum", "ss01", "ss02";
}

.workflowPopover--guestColab-variables-input-variable-title{
	display: flex;
	align-items: center;	
	width: 100%;
	height: 22px;

	// background: $tempr;
}

.workflowPopover--guestColab-variables-input-variable-title-title{
	font-size: 13.75px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;	

  flex-grow: 1;
  flex-shrink: 1;


}

.workflowPopover--guestColab-variables-input-variable-title-dropdownBtn{
	width: 32px;
	height: 100%;	
	display: flex;
	align-items: center;
	justify-content: center;
	// background: $tempr;
}

.workflowPopover--guestColab-variables-input-variable-title-dropdownBtn svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
}

//

.workflowPopover--guestColab-variables-input-variable-properties{
	width: 100%;
}

.workflowPopover--guestColab-variables-input-variable-properties-row{
	width: 100%;
}

.workflowPopover--guestColab-variables-input-variable-properties-row-title{
	font-size: 13.25px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;	

  opacity: 0.75;

  flex-grow: 1;
  flex-shrink: 1;

  margin-bottom: 6px;
  margin-top: 2px;
}

//

.workflowPopover--guestColab-variables-input-variable-bottomBorder{
	margin-top: 7px;
	width: 100%;
	height: 1px;
	background: var(--line150);
}

.workflowPopover--guestColab-variables-input-variable:last-child
.workflowPopover--guestColab-variables-input-variable-bottomBorder{
	display: none;
}

