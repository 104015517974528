.generatePopover-codeBlock-container {
  margin-top: 6px;

  padding: 4px;
  box-sizing: border-box;
  background: var(--250gray);
  width: calc(100% + 8px);
  margin-left: -4px;
  border-radius: 3px;
  position: relative;  
  padding: 8px;
  padding-bottom: 32px;
}

.generatePopover-codeBlock-container[data-padding-type='tight']{
  padding-bottom: 8px;
}

.generatePopover-codeBlock-container[data-left-tag='true']{
  padding-left: 58px;
}


.generatePopover-codeBlock-container[data-min-height='true']{
  min-height: 110px;
}


.generatePopover-codeBlock-inner{
  font-size: 12.75px;
  font-weight: 500;
  color: var(--text500);
  font-family: monospace;   
  font-variation-settings: "wght" 400;
  letter-spacing: -0.011em;
  opacity: 0.95;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow-wrap: break-word;
  hyphens: none;
}

.generatePopover-codeBlock-content {
  margin: 0;
  white-space: pre-wrap;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

/* Syntax highlighting */
.generatePopover-codeBlock-syntax-plain {
  color: var(--text500);
}

.generatePopover-codeBlock-syntax-property {
  color: #ff79c6;
}

.generatePopover-codeBlock-syntax-string,
.generatePopover-codeBlock-syntax-urlVariable,
.generatePopover-codeBlock-syntax-variable {
  color: #bd93f9;
}

.generatePopover-codeBlock-syntax-urlVariable{
  color: #ffb86c;  
}



.generatePopover-codeBlock-syntax-variable{
  color: var(--text500);
}

.generatePopover-codeBlock-syntax-cellReference {
  color: #ffb86c;
}


.generatePopover-codeBlock-syntax-function {
  color: #ffb86c;
}

.generatePopover-codeBlock-syntax-url {
  color: #8be9fd;
  color: var(--text500);
}

/* Base structural elements */
.generatePopover-codeBlock-syntax-plain {
  color: #f8f8f2;  /* Light gray for braces, quotes, etc. */
  color: var(--text200);
}

/* Property names */
.generatePopover-codeBlock-syntax-property {
  color: #ff79c6;  /* Pink for property names */
  color: var(--text500);
}

/* Placeholder strings like <string> */
.generatePopover-codeBlock-syntax-placeholder {
  color: #bd93f9;  /* Purple for placeholders */
}

/* Literal string values like "queued" */
.generatePopover-codeBlock-syntax-literal {
  color: #50fa7b;  /* Green for literal strings */
}

//
//

.generatePopover-codeBlock-POSTtag,
.generatePopover-codeBlock-responseCode{
  position: absolute;
  top: 7px;
  left: 8px;
  color: white;

  background: rgba(255,255,255,0.07);
  border-radius: 2px;

  padding-left: 5px;
  padding-right: 5px;
  height: 18px;

  line-height: 1.2;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 11.5px;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 540;
  letter-spacing: 0.008em;

  user-select: none;
  cursor: pointer;

  background: var(--primary500);
  color: white;
  font-feature-settings: "tnum", "ss01", "ss02";
  
}

.generatePopover-codeBlock-responseCode{
  right: 8px;
  color: #50fa7b;
  background: transparentize(#50fa7b, 0.85);
  left: unset;
}

//
//

.generatePopover-codeBlock-copyBtn {
  position: absolute;
  bottom: 0px;
  right: 4px;
  border: none;
  background: none;
  cursor: pointer;
  
  padding-bottom: 6px;
  height: 26px;
  display: flex;
  align-items: center;
}

.generatePopover-codeBlock-copyBtn-inner {
  display: flex;
  background: rgba(255,255,255,0.08);
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  padding-right: 4px;
  padding-left: 4px;

  height: 24px;
}

.generatePopover-codeBlock-copyBtn:hover 
.generatePopover-codeBlock-copyBtn-inner {
  background: rgba(255,255,255,0.10);
}

.generatePopover-codeBlock-copyBtn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 24px;
  margin-left: -2px;
}

.generatePopover-codeBlock-copyBtn-icon svg {
  width: 12px;
  height: 12px;
  fill: var(--text500);
  opacity: 0.65;
  transform: scale(0.95);
}

.generatePopover-codeBlock-copyBtn-label {
  font-size: 12px;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;
  color: var(--text500);
  user-select: none;
  padding-right: 4px;
  position: relative;
  top: -0.5px;
}

.generatePopover-codeBlock-tooltip-container {
  position: absolute;
  bottom: 100%;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generatePopover-codeBlock-tooltip {
  background: darken($variantDarkBlue, 5%);
  color: $variantBrightBlue;

  background: rgb(80,80,85);
  color: white;

  font-size: 11.5px;
  font-weight: $fw-medium;
  font-variation-settings: "wght" 420;
  letter-spacing: 0.014em;  

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;

  border-radius: 2px; 

  display: flex;
  align-items: center;
  justify-content: center;
}