
.editor-toolbar-insertBar{
  display: flex;
  // flex-grow: 1;
  // flex-shrink: 1;

  align-items: center;
  justify-content: flex-start;
  justify-content: center;

  pointer-events: auto;

  box-sizing: border-box;  
  //padding-left: 2px;

}

.editor-toolbar-insertBar{
  opacity: 1;  
  transition: opacity 150ms linear;
}

.editor-toolbar[data-read-only='true'] .editor-toolbar-insertBar{
  opacity: 0.15;  
}

.editor-toolbar[data-read-only='true'] .editor-toolbar-insertBar,
.editor-toolbar[data-read-only='true'] .editor-toolbar-insertBar *{
  pointer-events: none !important;
}


.editor-toolbar-insertBar-inner{
  display: flex;
  align-items: center;
  justify-content: center;
  // background: rgba(255,255,255,0.03);
  // border: 1px solid rgba(255,255,255,0.04);
  // border-radius: 3px;
  // padding-left: 4px;
  // padding-right: 4px;
  // padding-top: 1px;
  // padding-bottom: 1px;
}

.editor-toolbar-insertBar-btn{
  width: 48px;
  width: 54px;
  width: 48px;
  height: 32px;
  opacity: 0.75;
  border-radius: 4px;
  position: relative;
  //background: var(--250gray);
}

.editor-toolbar-insertBar-btn--launcher{
  width: 54px;
  background: var(--251gray);
  margin-left: 5px;
  margin-right: 5px;
}

.editor-toolbar-insertBar-btn
.editor-toolbar-insertBar-btn-tooltipContainer{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 38px;
  // width: 220px;
  // height: 130px;
  opacity: 0;

  // background: $tempr;
  pointer-events: none;
}

.editor-toolbar-insertBar-btn:hover
.editor-toolbar-insertBar-btn-tooltipContainer{
  opacity: 1;
}

// .editor-toolbar-insertBar-btn:nth-child(3){
//   opacity: 1 !important;
// }

// .editor-toolbar-insertBar-btn:nth-child(3)
// .editor-toolbar-insertBar-btn-tooltipContainer{
//   opacity: 1;
// }

.editor-toolbar-insertBar-btn-tooltip{
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background: var(--bg-dropdown);

  border-radius: 4px;

  border: var(--outsideLine-dropdown);
  border-top: var(--outsideTopLine-dropdown);
  border-bottom: var(--outsideBottomLine-dropdown);

  box-shadow: var(--boxShadow-dropdown);

  // padding: 4px;
  // padding-left: 6px;
  // padding-right: 5px;
  // padding-top: 5px;
}

.editor-toolbar-insertBar-btn:hover,
.editor-toolbar-insertBar-btn[data-state="active"]{
  background: var(--240gray);
  opacity: 1;
}

.editor-toolbar-insertBar-btn svg{
  width: 26px;
  height: 26px;
  fill: var(--text600);
  transform-origin: center center;
  // transform: scale(1.01);
}



.editor-toolbar-insertBar-btn-tooltip-label{
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  margin-top: 2px;
  font-weight: $fw-medium;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 540;
  letter-spacing: 0.008em;

  color: var(--text500);
}

.editor-toolbar-insertBar-btn-tooltip-contents{
  width: 240px;
  height: 135px;
  margin-left: 6px; // not sure why
  margin-right: 5px;
  margin-top: 6px;  
  border-radius: 2px;
}

.editor-toolbar-insertBar-btn-tooltip-contents--textSlide,
.editor-toolbar-insertBar-btn-tooltip-contents--charts,
.editor-toolbar-insertBar-btn-tooltip-contents--screenVideo,
.editor-toolbar-insertBar-btn-tooltip-contents--addFromLibrary,
.editor-toolbar-insertBar-btn-tooltip-contents--media,
.editor-toolbar-insertBar-btn-tooltip-contents--record{
  background: rgba(105, 133, 255, 1);
}

.editor-toolbar-insertBar-btn-tooltip-contents--textSlide{
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "YCereal";
  color: white;

  font-size: 16px;
  font-weight: 500;
}

.editor-toolbar-insertBar-btn-tooltip-contents--charts{
  display: flex;
  align-items: flex-end;
  justify-content: center;

  box-sizing: border-box;
  padding-bottom: 12px;

  column-gap: 14px;
}

.editor-toolbar-insertBar-btn-tooltip-contents--charts-bar{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.editor-toolbar-insertBar-btn-tooltip-contents--charts-bar-block{
  width: 30px;
  height: 44px;

  border: 1px solid rgba(255,255,255,0.2);
  background: rgba(255,255,255,0.4);

  border-radius: 2px;
}


.editor-toolbar-insertBar-btn-tooltip-contents--charts-bar:nth-child(1)
.editor-toolbar-insertBar-btn-tooltip-contents--charts-bar-block{
  height: 50px;
}

.editor-toolbar-insertBar-btn-tooltip-contents--charts-bar:nth-child(2)
.editor-toolbar-insertBar-btn-tooltip-contents--charts-bar-block{
  height: 80px;
}

.editor-toolbar-insertBar-btn-tooltip-contents--charts-bar:nth-child(3)
.editor-toolbar-insertBar-btn-tooltip-contents--charts-bar-block{
  height: 40px;
}

.editor-toolbar-insertBar-btn-tooltip-contents--charts-bar:nth-child(4)
.editor-toolbar-insertBar-btn-tooltip-contents--charts-bar-block{
  height: 65px;
}

.editor-toolbar-insertBar-btn-tooltip-contents--charts-bar-labelBlob{
  width: 22px;
  height: 6px;
  background: white;
  opacity: 0.2;
  border-radius: 1px;

  margin-top: 7px;
}


//

.editor-toolbar-insertBar-btn-tooltip-contents--record{
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}

.editor-toolbar-insertBar-btn-tooltip-contents--record-window{
  
  width: 170px;
  height: 90px;

  border: 1px solid rgba(255,255,255,0.2);
  background: rgba(255,255,255,0.4);
  background: linear-gradient(to bottom, rgba(255,255,255,0.45), rgba(255,255,255,0.4));

  border-radius: 3px;

  position: relative;
  top: -6px;


}

.editor-toolbar-insertBar-btn-tooltip-contents--record-window-dots{
  position: absolute;
  top: 4px;
  left: 4px;

  display: flex;
  column-gap: 3px;
}

.editor-toolbar-insertBar-btn-tooltip-contents--record-window-dots-dot{
  width: 4px;
  height: 4px;
  border-radius: 50%;

  background: rgba(255,255,255,0.8);
  //background: rgba(105, 133, 255, 1);
}

.editor-toolbar-insertBar-btn-tooltip-contents--record-toolbar{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 12px;
  width: 80px;
  height: 10px;
  border-radius: 3px;


  //border: 1px solid rgba(255,255,255,0.2);
  background: rgba(255,255,255,0.4);
  background: lighten(#A9B5FA, 5%);

  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-toolbar-insertBar-btn-tooltip-contents--record-toolbar-circle{
  width: 12px;
  height: 12px;
  border-radius: 50%;

  background: rgba(255,255,255,0.5);
  display: none;
}

.editor-toolbar-insertBar-btn-tooltip-contents--record-webcam{
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 36px;
  height: 36px;
  border-radius: 50%;

  border: 1px solid rgba(255,255,255,0.2);
  background: rgba(255,255,255,0.4);
  background: lighten(#A9B5FA, 5%);
  outline: 4px solid rgba(105, 133, 255, 1);
}


//


.editor-toolbar-insertBar-btn-tooltip-contents--screenVideo{

  display: flex;  
  align-items: center;
  justify-content: center;
  
}


.editor-toolbar-insertBar-btn-tooltip-contents--screenVideo-device{
  width: 180px;
  height: 100px;
  background: $white;
  border: 1px solid rgba(255,255,255,0.4);
  background: rgba(255,255,255,0.7);

  border-radius: 3px;

  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.4);

}

//

.editor-toolbar-insertBar-btn-tooltip-contents--media{
  display: flex;
  align-items: center;
  justify-content: center;

  column-gap: 22px;
}

.editor-toolbar-insertBar-btn-tooltip-contents--media svg{
  width: 64px;
  height: 64px;
  opacity: 0.7;
}

//

.editor-toolbar-insertBar-btn-tooltip-contents--addFromLibrary {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 items per row */
  justify-items: center; /* horizontally center items */
  align-items: center; /* vertically center items */
  gap: 0px; /* adjust grid gap as needed */

  //background: rgba(255, 255, 255, 0.01);

  box-sizing: border-box;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.editor-toolbar-insertBar-btn-tooltip-contents--addFromLibrary-video {
  width: 58px;
  height: 34px;

  background: white;
  opacity: 0.4;
  border-radius: 2px;
}

//


.editor-toolbar-insertBar-btn-tooltip--insertBar{
  height: 24px;
  width: 90px;
  display: flex;
  padding-top: 0px;
  flex-direction: row;
}

.editor-toolbar-insertBar-btn-tooltip--insertBar
.editor-toolbar-insertBar-btn-tooltip-label{
  position: relative;
  top: -4px;
}

.editor-toolbar-insertBar-btn-tooltip-shortcut{
  display: flex;
  align-items: center;
  justify-content: center;
  background: $tempr;

  margin-left: 5px;

  padding-left: 3px;
  padding-right: 3px;
  height: 16px;

  background: rgba(255,255,255,0.04);
  border: 1px solid rgba(255,255,255,0.05);
  border-radius: 3px;

  position: relative;
  top: 3px;
}

.editor-toolbar-insertBar-btn-tooltip-shortcut-iconContainer{
  display: flex;
  width: 12px;
  height: 12px;
  align-items: center;
  justify-content: center;
}

.editor-toolbar-insertBar-btn-tooltip-shortcut-iconContainers svg{
  width: 8px;
  height: 8px;
  fill: var(--text500);
}

.editor-toolbar-insertBar-btn-tooltip-shortcut-letter{
  font-size: 12px;
  font-weight: $fw-medium;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;
}

//
//
.editor-toolbar-insertBar-insertBtnContainer{
  position: relative;
}

.editor-toolbar-insertBar-centerPositionGuide{
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 50%;
}