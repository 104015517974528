//
// TEXT COLORS
//

// maybe make more harmonious, try different approaches

:root{	
	
	--text600: 	hsl(240, 41%, 7%); // textBlackAbsolute
	--text500:	rgb(37,37,42); // textBlack
	--text400: 	hsl(240, 9%, 28%); // textDark
	--text350: 	hsl(40, 4%, 32%);  // textMidDark
	--text300: 	hsl(240, 8%, 41%); // textMid
	--text250: 	hsl(240, 7%, 43%); // textMidLight
	--text200: 	hsl(240, 7%, 46%); // textMidLight
	--text100: 	hsl(243, 10%, 59%); // textLight
	--text000: 	hsl(0, 0%, 100%); // textLightAbsolute

	--textPrimaryColor: hsl(213, 100%, 50%); // equiv to primary500
}

// DARK THEME
// goes brighter as you go up the stack, i.e. 600 is brightest white, 000 is pure black

[data-theme="dark"], :root .tabBar--yarnTheme--dark, .forceDarkTheme{
--text600: hsl(240, 20%, 	97%);
--text500: hsl(240, 12%, 	95%);
--text400: hsl(240, 12%, 	88%);
--text350: hsl(240, 9%, 	85%);
--text300: hsl(240, 8%, 	87%);
--text250: hsl(240, 8%, 	75%);
--text200: hsl(240, 8%, 	62%);
--text100: hsl(0, 	0%, 	62%);
--text000: hsl(0, 	0%, 	60%); // might need a refactor

--textPrimaryColor: hsl(213, 100%, 50%); // update from primary500
}