
.tabBar{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: $height-tabBar;
  background: lighten(#151619, 1%);
  border-bottom: 1px solid rgba(55,55,58,0.5);

  z-index: 90;

  display: flex;
  align-items: center;

  box-sizing: border-box;
  padding-top: 0px;

  -webkit-app-region: drag;
}

.tabBar-projectList{
	display: flex;
	margin-left: 5px;
	column-gap: 5px;
}

.editor-tabBar-leftSpacer{
  width: 84px;
  flex-grow: 0;
  flex-shrink: 0;
}

.editor-tabBar-rightSpacer{
  width: $width-exportBtnContainer;
  flex-grow: 0;
  flex-shrink: 0;
}

.tabBar-tab{
	padding-left: 8px;
	padding-right: 0px;

	margin-top: 2px;

	height: 28px;
	border-radius: 3px;

	// border-bottom-right-radius: 2px;
	// border-bottom-left-radius: 2px;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	cursor: pointer;
  
	position: relative;

	-webkit-app-region: no-drag;
}

.tabBar-tab[data-state="active"]{
	background: var(--243gray);
	background: lighten(rgb(42,42,48), 5%);
	color: var(--text600);
	z-index: 50;
}

.tabBar-bgClick{
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	// background: $tempr;
	z-index: 20;
	-webkit-app-region: none;
}

.tabBar-tab[data-state="active"]:focus-within{
	background: lighten(rgb(42,42,48), 16%);
	color: var(--text600);	
}

.tabBar-tab--library.tabBar-tab[data-state="active"]:focus-within{
	background: lighten(rgb(42,42,48), 5%);	
	// keep don't add special effect for home
}

.tabBar-tab[data-state="inactive"]{
	background: var(--252gray);
}

.tabBar-tab[data-state="inactive"]:hover{
	background: var(--250gray);
}

.tabBar-tab-popoverContainer{
	position: absolute;
	top: 100%;
	left: 0%;
}
.tabBar-tab-popoverTrigger{
	position: absolute;
	top: 100%;
	left: 0%;
}
//

.tabBar-newBtn{
	height: 100%;
	width: 34px;
	// background: $tempr;

	flex-grow: 0;
	flex-shrink: 0;

	position: relative;

	-webkit-app-region: no-drag;
}

.tabBar-newBtn-inner{
	width: 26px;
	height: 26px;
	// background: var(--252gray);

	border-radius: 4px;
	margin-top: 3px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}

.tabBar-newBtn-inner:hover{
	background: var(--251gray);
}

.tabBar-newBtn-inner svg{
	width: 18px;
	height: 18px;

	fill: var(--text500);
	opacity: 0.4;

	transform-origin: center center;
	transform: scale(1.05);
}

.tabBar-newBtn-inner:hover svg{
	opacity: 0.75;
	transform: scale(1.11);
}


.tabBar-newBtn-tooltip{
	position: absolute;
	left: 100%;
	width: 120px;
	pointer-events: none;
	top: 1.5px;
	margin-left: 2px;
	
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	font-size: 14px;
	color: var(--text500);
	font-weight: $fw-medium;

	opacity: 0;
	transition: all 50ms linear;
	transform: translateX(-5px);
}

.tabBar-newBtn:hover
.tabBar-newBtn-tooltip{
	opacity: 0.6;
	transform: translateX(0px);
	transition-delay: 600ms;
}

.tabBar-newBtn-tooltip-shortcut{
	opacity: 0.7;
	margin-left: 6px;
}

//

.tabBar-tab-labelContainer{
	font-size: 14px;
	font-size: 13.5px;
	//font-size: 14.25px;
	color: var(--text500);
	font-weight: $fw-medium;	

	user-select: none;
	position: relative;

	font-variation-settings: "wght" 460;
	letter-spacing: 0.008em;

	flex-grow: 1;
  flex-shrink: 1;

	height: 100%;
	display: flex;
	align-items: center;
}

.tabBar-tab--library
.tabBar-tab-labelContainer{
	width: 45px;
}

.tabBar-tab-label{
	position: absolute;
	left: 0px;
	right: 0px;
	width: 100%;

	display: flex;	
	justify-content: flex-start;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  z-index: 10;
  pointer-events: none;
}


.tabBar-tab-label-label{
	flex-shrink: 1;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.tabBar-tab-label-draft{
	margin-left: 5px;
	flex-grow: 0;
	flex-shrink: 0;
	opacity: 0.5;
	padding-right: 6px;
}




.tabBar-tab-titleInput{
	background: none;
	position: absolute;
	top: 2px;
	
	
	height: 24px;

	box-sizing: border-box;
	padding: 0px;
	padding-left: 6px;
	
	width: calc(100% + 6px);
	left: -6px;

	border: none;
	border-radius: 2px;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;

	font-size: 14px;
	font-size: 13.5px;
	//font-size: 14.25px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 460;
  letter-spacing: 0.008em;

  padding-bottom: 1px;
  //padding-bottom: 0px;


	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

	// background: rgba(255,255,255,0.09);
}

// .tabBar-tab-titleInput::selection{
// 	background-color: #1B55B2 !important;
// }

.tabBar-tab-titleInput:hover{
	border: none;
	background: rgba(255,255,255,0.09);

}

.tabBar-tab-titleInput:focus{
	outline: none;
	border: none;
	background: rgba(255,255,255,0.12);
}


.tabBar-tab[data-state="active"]
.tabBar-tab-label{
	opacity: 1;
	color: var(--text600);
}


.tabBar-tab[data-state="inactive"]
.tabBar-tab-label{
	opacity: 0.6;
}


.tabBar-tab.tabBar-tab--library[data-state="inactive"]
.tabBar-tab-label{
	opacity: 0.8;
}


.tabBar-tab[data-state="inactive"]:hover
.tabBar-tab-label{
	opacity: 0.9;
}


.tabBar-tab-labelContainer[data-active-state="inactive"]
.tabBar-tab-titleInput{
	display: none;
}


.tabBar-tab-labelContainer[data-active-state="inactive"]
.tabBar-tab-label{
	display: flex;
}

// .tabBar-tab-labelContainer[data-active-state="active"]
// .tabBar-tab-titleInput{
// 	display: none;
// }

.tabBar-tab-labelContainer[data-active-state="active"]
.tabBar-tab-label{
	display: flex;
}



.tabBar-tab-labelContainer[data-active-state="active"]:hover
.tabBar-tab-titleInput,
.tabBar-tab-labelContainer[data-active-state="active"][data-input-focused-state="true"]
.tabBar-tab-titleInput{
	display: block;
}

.tabBar-tab-labelContainer[data-active-state="active"]
.tabBar-tab-label-label{
	color: $invisible !important;
}


.tabBar-tab-labelContainer[data-active-state="active"][data-draft-state="true"][data-input-focused-state="false"]
.tabBar-tab-titleInput{
	padding-right: 44px;
}


.tabBar-tab-labelContainer[data-input-focused-state="true"]
.tabBar-tab-label{
	display: none;
}

//



.tabBar-tab--library-iconContainer{
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: -8px;
	margin-right: -2px;

	flex-grow: 0;
	flex-shrink: 0;
}

.tabBar-tab--library-iconContainer svg{
	fill: var(--text500);
	width: 15px;
	height: 15px;
	opacity: 0.75;
	transform: scale(1.05);
	position: relative;
	top: -0.5px;
}




.tabBar-tab.tabBar-tab--library{
	min-width: unset;
	width: auto;
	padding-left: 14px;
	padding-right: 14px;
}




.tabBar-tab.tabBar-tab--library
.tabBar-tab-label{
	margin-left: 3px;
}


.tabBar-tab-closeBtnContainer{
	display: flex;
	align-items: center;

	height: 100%;
	padding-right: 3px;

	flex-grow: 0;
	flex-shrink: 0;
	width: 22px;
}

.tabBar-tab-closeBtn{
	display: flex;
	align-items: center;
	justify-content: center;

	width: 22px;
	height: 22px;

	// background: $tempr;

	border-radius: 4px;
}

.tabBar-tab-closeBtn svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.4;
}

.tabBar-tab-closeBtnContainer:hover
.tabBar-tab-closeBtn{
	background: var(--250gray);
}



.tabBar-tab[data-state="active"]
.tabBar-tab-closeBtn svg{
	opacity: 0.5;
}

.tabBar-tab[data-state="inactive"]
.tabBar-tab-closeBtn svg{
	opacity: 0.1;
}

.tabBar-tab[data-state="inactive"]:hover
.tabBar-tab-closeBtn svg{
	opacity: 0.5;
}

.tabBar-tab-closeBtnContainer:hover
.tabBar-tab-closeBtn svg{
	opacity: 0.8 !important;
}