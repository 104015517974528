
// SlideTextArea
$figmaBlue: #3F8AE2;
$specialYellow: #FFDB39;





//
// Draggable Item

.editor-slideEditor-draggableItem{
  position: absolute;
  border-radius: 2px;
  // background: $tempb;
}

.editor-slideEditor-draggableItem--text{
  // background: $tempb;
}

.editor-slideEditor[data-dragging-to-select='true']
.editor-slideEditor-draggableItem,
.editor-slideEditor[data-dragging-to-select='true']
.editor-slideEditor-draggableItem *{
  pointer-events: none !important;
}

.editor-slideEditor[data-resizing-element='true']
.editor-slideEditor-draggableItem,
.editor-slideEditor[data-resizing-element='true']
.editor-slideEditor-draggableItem *{
  pointer-events: none !important;
}

// .editor-slideEditor[data-dragging-element='true']
// .editor-slideEditor-draggableItem--dragging{
//   pointer-events: auto !important;
// }

.editor-slideEditor-draggableItem--notDndDragging
.editor-slideEditor-draggableItem-textPreview{
  color: $invisible;
}

.editor-slideEditor-draggableItem--dndDragging
.editor-slideEditor-draggableItem-textPreview,
.editor-slideEditor-draggableItem--dndDragging
.editor-slideEditor-draggableItem-textPreview{
  color: black;
  opacity: 0.5;
}

.editor-slideEditor[data-dnd-dragging-element='true']
.editor-slideEditor-draggableItem--notDndDragging{
  opacity: 0;
}

//
// Image Preview

.editor-slideEditor-draggableItem--media{
  // background: $tempr;
  z-index: 900;
}

.editor-slideEditor-imagePreviewContainer{
  position: absolute;
  pointer-events: none;
  user-select: none !important;
  z-index: 200;
}

.editor-slideEditor-imagePreview {
  pointer-events: none;
  user-select: none !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
  //background: $tempr;
}

.editor-slideEditor-imagePreview-img {
  width: 100%;
  height: 100%;
}

.editor-slideEditor-imagePreview[data-fill-type='fit'] .editor-slideEditor-draggableItem-imagePreview-img {
  object-fit: contain;
}

.editor-slideEditor-imagePreview[data-fill-type='fill'] .editor-slideEditor-draggableItem-imagePreview-img {
  object-fit: cover;
}

//




//

img.editor-slideEditor-draggableItem-imagePreview{
  opacity: 0.5;
  width: 100%;
  position: absolute;
  height: 100%;
  object-fit: cover;
  pointer-events: none !important;
}





//
//

@import 'SlideEditor-Containers';
@import 'SlideEditor-TextArea';
@import 'SlideEditor-SelectionBoxes';
@import 'SlideEditor-ResizableHandle';
@import 'SlideEditor-DragSelect';
@import 'SlideEditor-GhostItems';
@import 'SlideEditor-Spacers';


//

.slideEditorContextMenu{
  position: fixed;
  z-index: 40000;
  width: 160px;
  display: flex;
  flex-direction: column  
  
}


.editor-canvasZoomUI-rnd{
  //background: $tempr;
}


.editor-canvasZoomUI-snaplineCenter{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  background: red;
  top: 0px;
  bottom: 0px;
}

.editor-canvasZoomUI-snaplineMiddle{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  background: red;
  left: 0px;
  right: 0px;
}

.editor-canvasZoomUI-snaplineLeft{
  position: absolute;
  left: 0px;  
  width: 1px;
  background: red;
  top: 0px;
  bottom: 0px;
}

.editor-canvasZoomUI-snaplineRight{
  position: absolute;
  right: 0px;  
  width: 1px;
  background: red;
  top: 0px;
  bottom: 0px;
}


.editor-canvasZoomUI-snaplineTop{
  position: absolute;
  top: 0px;
  height: 1px;
  background: red;
  left: 0px;
  right: 0px;
}

.editor-canvasZoomUI-snaplineBottom{
  position: absolute;
  bottom: 0px;
  height: 1px;
  background: red;
  left: 0px;
  right: 0px;
}