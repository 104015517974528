
.editor-statusBar{
  position: fixed;
  top: 0px;
  right: 0px;
  height: $height-tabBar;
  width: $width-exportBtnContainer;

  z-index: 100;
  // background: $tempr;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-right: 6px;
  -webkit-app-region: no-drag;

  column-gap: 4px;
}


.editor-statusBar-btn{  
  height: 26px;
  border-radius: 3px;

  background: var(--250gray);
  border: 1px solid var(--line150);

  padding-left: 9px;
  padding-right: 9px;
}



.editor-statusBar-btn:hover{
  border: 1px solid var(--line200);
}

.editor-statusBar-btn--export,
.editor-statusBar-btn--generate,
.editor-statusBar-btn--generations{
  background: var(--248gray);
  border: 1px solid var(--line250);
}

.editor-statusBar-btn--export{
  width: 90px;
}

.editor-statusBar-btn--generate{
  width: 100px;  
}

.editor-statusBar-btn--generations{
  min-width: 50px;  
  font-feature-settings: "ss01", "ss02";
}

.editor-statusBar-btn--export:hover,
.editor-statusBar-btn--generate:hover,
.editor-statusBar-btn--generations:hover{
  background: var(--243gray);
  border: 1px solid var(--line300);
}

.editor-statusBar-btn--generate[data-state='open']{
  background: var(--240gray);
  border: 1px solid var(--line400);
}

.editor-statusBar-btn--iconOnly{
  width: 34px;
  padding-left: 0px;
  padding-right: 0px;
}

.editor-statusBar-btn-iconContainer{
  width: 24px;
  height: 24px;
  margin-left: -7.5px;
  margin-right: -1.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-statusBar-btn--iconOnly
.editor-statusBar-btn-iconContainer{
  margin-left: 0px;
  margin-right: 0px;
}

.editor-statusBar-btn-iconContainer svg{
  width: 14px;
  height: 14px;
  fill: var(--text500);
  opacity: 0.75;
}

.editor-statusBar-btn--generate
.editor-statusBar-btn-iconContainer svg{
  transform: scale(0.90);
}

.editor-statusBar-btn--iconOnly
.editor-statusBar-btn-iconContainer svg{
  height: 16px;
  width: 16px;
  opacity: 0.6;
  transform: scale(1.05);
}

.editor-statusBar-btn-label{
  font-size: 13.1px; 
  color: var(--text500);
  
  font-variation-settings: "wght" 500;
  letter-spacing: 0.008em;  
  position: relative;
  // top: -0.5px;
}

// .editor-statusBar-btn--guestPage{
//   background: var(--guestColabBG);
//   border: 1px solid var(--guestColabLine);
// }

// .editor-statusBar-btn--guestPage:hover,
// .editor-statusBar-btn--guestPage[data-state='open']{
//   color: var(--guestColabTextHover);
//   background: var(--guestColabBGHover);
//   border: 1px solid var(--guestColabLineHover);
// }

// .editor-statusBar-btn--guestPage
// .editor-statusBar-btn-iconContainer svg{
//   fill: var(--guestColabText);
// }

// .editor-statusBar-btn--guestPage
// .editor-statusBar-btn-label{
//   color: var(--guestColabText);
// }


// .editor-statusBar-btn--apiConfig{
//   background: var(--apiBG);
//   border: 1px solid var(--apiLine);
// }

// .editor-statusBar-btn--apiConfig:hover{
//   background: var(--apiTextHover);
//   background: var(--apiBGHover);
//   border: 1px solid var(--apiLineHover);
// }

// .editor-statusBar-btn--apiConfig
// .editor-statusBar-btn-iconContainer svg{
//   fill: var(--apiText);
// }

// .editor-statusBar-btn--apiConfig
// .editor-statusBar-btn-label{
//   color: var(--apiText);
// }

// .editor-statusBar-btn--apiConfig:hover
// .editor-statusBar-btn-label{
//   color: var(--apiTextHover);
// }






// .editor-statusBar-btn-iconContainer svg.icon--name--upArrowCircle{
//   transform: scaleY(-100%);
// }

// .editor-statusBar-btn-iconContainer svg.icon--name--upArrowCircle #circle{
//   fill: rgba(255,255,255,0.2);
// }

// .editor-statusBar-btn-iconContainer svg.icon--name--upArrowCircle #arrow{
//   fill: var(--text500);
// }

// .editor-statusBar-motionBtn,
// .editor-statusBar-brandBtn,
// .editor-statusBar-bgBackgroundBtn,
// .editor-statusBar-exportBtn{  
//   background: none;
  
//   background: var(--251gray);
//   border: 1px solid var(--line100);
//   color: var(--text500);

//   height: 27px;
//   margin-top: 1px;

//   margin-right: 4px;

//   font-size: 14px;
//   font-family: system-ui, sans-serif;
//   font-variation-settings: "wght" 440;
//   letter-spacing: 0.008em;

//   border-radius: 3px;

//   -webkit-app-region: no-drag;

//   position: relative;
// }

// .editor-statusBar-exportBtn:disabled{
//   pointer-events: none;
//   opacity: 0.5;
// }

// .editor-statusBar-btnTooltipContainer{
//   position: absolute;
//   top: 100%;
//   left: 50%;
//   transform: translateX(-50%);
//   margin-top: 4px;

//   opacity: 0;
//   pointer-events: none;
// }

// .editor-statusBar-btnTooltipContainer
// .tooltip{
//   background: lighten(rgb(24,25,28), 6%);
// }

// .editor-statusBar-motionBtn:hover
// .editor-statusBar-btnTooltipContainer,
// .editor-statusBar-brandBtn:hover
// .editor-statusBar-btnTooltipContainer,
// .editor-statusBar-bgBackgroundBtn:hover
// .editor-statusBar-btnTooltipContainer{
//   opacity: 1;
// }

// .editor-statusBar-motionBtn[data-state="open"]
// .editor-statusBar-btnTooltipContainer,
// .editor-statusBar-brandBtn[data-state="open"]
// .editor-statusBar-btnTooltipContainer,
// .editor-statusBar-bgBackgroundBtn[data-state="open"]
// .editor-statusBar-btnTooltipContainer{
//   opacity: 0;
// }

// .editor-statusBar-brandBtn{
//   padding-left: 2px;
//   padding-right: 9px;
//   width: 100px;

//   // color: var(--text400);
// }

// .editor-statusBar-motionBtn{
//   margin-right: 8px;
// }

// .editor-statusBar-exportBtn{
//   padding-left: 18px;
//   padding-right: 18px;
//   // font-variation-settings: "wght" 500;
// }

// .editor-statusBar-motionBtn,
// .editor-statusBar-bgBackgroundBtn{
//   padding-left: 3px;
//   padding-right: 3px;
//   width: 32px;
// }

// .editor-statusBar-bgBackgroundBtn{
//   width: 36px;
// }

// .editor-statusBar-motionBtn:hover,
// .editor-statusBar-brandBtn:hover,
// .editor-statusBar-bgBackgroundBtn:hover,
// .editor-statusBar-exportBtn:hover{
//   background: var(--246gray);
//   border: 1px solid var(--line200);
//   color: var(--text600);
// }

// .editor-statusBar-exportBtn{
//   position: relative;
//   width: 100px; 
// }


// .editor-statusBar-exportBtn--iconOnly{
//   width: 36px;
//   padding-left: 0px;
//   padding-right: 0px;
// }
// .editor-statusBar-exportBtn--iconOnly svg{
//   width: 18px;
//   height: 18px;
//   fill: white;
//   opacity: 0.5;
// }



// .editor-statusBar-exportBtn--upgrade{
//   opacity: 0.9;
// }

// .editor-statusBar-exportBtn--upgrade:hover{
//   opacity: 1;
// }

// .editor-statusBar-exportBtn-label{
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   width: 100%;
//   height: 100%;

//   display: flex;
//   align-items: center;
//   justify-content: center;

//   transition: all 100ms linear;
// }

// .editor-statusBar-exportBtn--upgrade
// .editor-statusBar-exportBtn-label--disabled{
//   opacity: 1;
//   // transform: translateX(0px);
// }

// .editor-statusBar-exportBtn--upgrade
// .editor-statusBar-exportBtn-label--hover{
//   opacity: 0;
//   // transform: translateX(4px);
// }

// .editor-statusBar-exportBtn--upgrade:hover
// .editor-statusBar-exportBtn-label--disabled{
//   opacity: 0;
//   // transform: translateX(-4px);
// }

// .editor-statusBar-exportBtn--upgrade:hover
// .editor-statusBar-exportBtn-label--hover{
//   opacity: 1;
//   // transform: translateX(0px);
// }


// //

// .editor-statusBar-exportBtn--variant{
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .editor-statusBar-exportBtn--variant
// .editor-statusBar-exportBtn-label{
//   position: relative;
// }

// .editor-statusBar-exportBtn--variant[data-active-state='inactive']{
//   width: 36px;
// }

// .editor-statusBar-exportBtn--variant[data-active-state='active']{
//   width: 120px;
//   // background: #4584EA;
//   // border-color: #2968CD;
//   background: transparentize(saturate(#437ce1, 5%), 0.4);
//   border: 1px solid lighten(saturate(#437ce1, 5%), 10%);
// }

// .editor-statusBar-exportBtn--variant[data-active-state='active']
// .editor-statusBar-exportBtn-label{
//   color: white;
// }

// .editor-statusBar-exportBtn-iconContainer{
//   width: 22px;
//   height: 24px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-left: -6px;
// }

// .editor-statusBar-exportBtn--variant[data-active-state='inactive']
// .editor-statusBar-exportBtn-iconContainer{
//   margin-left: 0px;
//   opacity: 0.5;
// }

// .editor-statusBar-exportBtn-iconContainer svg{
//   width: 17px;
//   height: 17px;
//   fill: var(--text500);
//   fill: lighten(saturate(#437ce1, 5%), 20%) !important;
//   opacity: 0.9;
// }


// //

// .editor-statusBar .dpColorPicker-valueBtn{
//   margin-right: 6px;
// }


// //

// .editor-statusBar-motionBtn-iconContainer,
// .editor-statusBar-brandBtn-iconContainer{
//   width: 20px;
//   height: 20px;

//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .editor-statusBar-brandBtn-iconContainer svg{
//   width: 15px;
//   height: 15px;
//   fill: white;
//   opacity: 0.5;
//   position: relative;
//   // top: -0.5px;
//   left: -1px;
// }

// .editor-statusBar-motionBtn-iconContainer svg{
//   width: 19px;
//   height: 19px;
//   fill: white;
//   opacity: 0.6;
// }

// //

// .editor-statusBar-bgBackgroundBtn-colorPreview{
//   width: 100%;
//   height: 20px;
//   border-radius: 2px;
// }

// .editor-statusBar-bgBackgroundBtn-colorPreview--image{
//   object-fit: cover;
// }

// .editor-statusBar-bgBackgroundBtn-colorPreview--image img{
//   width: 100%;
//   height: 100%;
// }


// .editor-statusBar-dropdownBtn{
//   padding-left: 0px;
//   padding-right: 0px;  
//   border-radius: 3px;
//   margin-top: 1px;
//   height: 27px;
//   width: 32px;
//   background: var(--251gray);
//   border: 1px solid var(--line150);
// }

// .editor-statusBar-dropdownBtn:hover{
//   background: var(--246gray);
//   border: 1px solid var(--line200);  
// }

// .editor-statusBar-dropdownBtn-iconContainer{
//   width: 24px;
//   height: 24px;

//   display: flex;
//   align-items: center;
//   justify-content: center;
// }


// .editor-statusBar-dropdownBtn-iconContainer svg{
//   width: 18px;
//   height: 18px;
//   fill: var(--text500);

//   opacity: 0.8;
// }

// .editor-statusBar-dropdownBtn:hover svg{
//   opacity: 1;
// }

@import "Editor-StatusBar-ExportLinkControls";