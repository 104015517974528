
.editor-timeline-clip-inner{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  box-sizing: border-box;
  padding-left: 0px;


  user-select: none;
}

.editor-timeline-clip--draggable--push
.editor-timeline-clip-inner{
  display: none;
}


.editor-timeline-clip-inner-iconContainer{
  display: flex;
  align-items: center;
  justify-content: center;

  width: 28px;
  height: 24px;
  margin-right: 0px;
  margin-left: 3px;

  flex-grow: 0;
  flex-shrink: 0;

  opacity: 1;
  transition: all 50ms linear;
}

.editor-timeline-clip--render[data-clip-type='zoom']
.editor-timeline-clip-inner-iconContainer{
  width: 20px;
  margin-left: 0px;
}

.editor-timeline-clip-inner-iconContainer svg{
  width: 20px;
  height: 20px;
  fill: white;
  opacity: 0.3;
  opacity: 0.5;
  transition: opacity 0ms linear;
}

.editor-timeline-clipDrag:hover + .editor-timeline-clip--zoom
.editor-timeline-clip-inner-iconContainer svg{
  opacity: 1;
  transition-delay: 200ms;
}

.editor-timeline-clip--zoom.editor-timeline-clip--active
.editor-timeline-clip-inner-iconContainer svg{
  opacity: 1;
  transition-delay: 0ms !important;
}


.editor-timeline-clip--render[data-clip-type='zoom']
.editor-timeline-clip-inner-iconContainer{
  width: 100%;
}

.editor-timeline-clip--render[data-clip-type='zoom']
.editor-timeline-clip-inner-iconContainer svg{
  width: 16px;
  height: 16px;
}

.editor-timeline-clip--render[data-clip-type='screenVideo']
.editor-timeline-clip-inner-iconContainer svg{
  width: 20px;
  height: 20px;
  fill: lighten(#7065dd, 10%);
  opacity: 1;
}

.editor-timeline-clip--render[data-clip-type='webcamInstructions']
.editor-timeline-clip-inner-iconContainer svg{
  fill: lighten(#FFA78E, 10%);  
}





.editor-timeline-clip--render[data-clip-type='video']
.editor-timeline-clip-inner-iconContainer svg{
  width: 20px;
  height: 20px;
  fill: lighten(#B47190, 10%);
  opacity: 1;
}

.editor-timeline-clip--render[data-clip-type='chart']
.editor-timeline-clip-inner-iconContainer svg{
  width: 20px;
  height: 20px;
  fill: rgba(255,169,77, 1);
  opacity: 1;
}

// .editor-timeline-clip--render[data-clip-type='image']
// .editor-timeline-clip-inner-iconContainer svg{
//   width: 20px;
//   height: 20px;
//   fill: transparentize(desaturate(#A3F1C3, 14%), 0.5);
//   opacity: 1;
// }


.editor-timeline-clip-inner-label{
  font-size: 13.75px;
  // font-size: 14.25px;
  // font-weight: $fw-medium;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 420;
  font-variation-settings: "wght" 470;
  //letter-spacing: 0.012em;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  flex-grow: 1;
  flex-shrink: 1;

  opacity: 0.95;
  margin-top: -1px;  
}

.editor-timeline-clip--render[data-clip-type='webcamInstructions']
.editor-timeline-clip-inner-label{
  
}

.editor-timeline-clip--render[data-clip-type='audio']
.editor-timeline-clip-inner-label{
  opacity: 0.95;
  padding-left: 6px;
}


.editor-timeline-clip--active .editor-timeline-clip-inner-label,
.editor-timeline-clip--active:hover .editor-timeline-clip-inner-label{
  opacity: 1;    
}


//



.editor-timeline-clip-inner-chunk{
  // outline: 1px solid red;
  box-sizing: border-box;
  padding-left: 6px;
  padding-right: 4px;

  position: absolute;
  display: flex;
  align-items: center;
}

.editor-timeline-clip-inner-chunk-text{
  font-size: 13.75px;
  // font-size: 14.25px;
  // font-weight: $fw-medium;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 420;
  font-variation-settings: "wght" 470;
  //letter-spacing: 0.012em;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  flex-grow: 1;
  flex-shrink: 1;

  position: relative;
  z-index: 5;

  opacity: 0.95;
  margin-top: -1px;    
}

.editor-timeline-clip-inner-chunk-text-inner{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.editor-timeline-clip-inner-chunk-text-inner-text{
  //background: $tempr;
}


.editor-timeline-clip--render[data-clip-type='webcamRecording']
.editor-timeline-clip-inner-chunk-text-inner--loading
.editor-timeline-clip-inner-chunk-text-inner-text{
    background: linear-gradient(90deg, 
              lighten(rgba(29, 40, 43,1), 40%), 
              lighten(rgba(29, 40, 43,0.5), 40%), 
              lighten(rgba(29, 40, 43,1), 40%));

    background-size: 200% 100%;
  animation: loadingTextShimmer 6s infinite linear;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  position: relative;
  font-variation-settings: "wght" 450;
}

.editor-timeline-clip--render[data-clip-type='linkedWebcamRecording']
.editor-timeline-clip-inner-chunk-text-inner--loading
.editor-timeline-clip-inner-chunk-text-inner-text{
  background: linear-gradient(90deg, 
              lighten(rgba(110,99,217,1), 20%), 
              lighten(rgba(110,99,217,0.5), 20%), 
              lighten(rgba(110,99,217,1), 20%));

  background-size: 200% 100%;
  animation: loadingTextShimmer 6s infinite linear;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  position: relative;
  font-variation-settings: "wght" 450;
}



@keyframes loadingTextShimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}


span.editor-timeline-clip-inner-chunk-text--light{
  color: rgba(255,255,255,0.5); // make type specific
  color: lighten(rgba(110,99,217,1), 28%);
  margin-left: 0.2em;
}

.editor-timeline-clip--render[data-clip-type='screenVideo']
.editor-timeline-clip-inner[data-single-screen-chunk='false']
.editor-timeline-clip-inner-chunk{
  padding-left: 10px;
}

.editor-timeline-clip-inner-chunk-bg{
  position: absolute;
  top: -6px;
  bottom: -6px;
  right: 1px;
  left: 2px;
  
  border: 1px solid red;
  border-radius: 1px;
}

.editor-timeline-clip--render[data-clip-type='webcamScript']
.editor-timeline-clip-inner-chunk-bg{  
  //background: lighten(rgb(30,31,33), 10%);  
  background: rgba(255,255,255,0.02);
  //border: 1px solid lighten(rgb(30,31,33), 13%);  
  border: 1px solid rgba(255,255,255,0.02)
}

.editor-timeline-clip--render[data-clip-type='screenVideo']
.editor-timeline-clip-inner[data-single-screen-chunk='false']
.editor-timeline-clip-inner-chunk-bg{  
  //background: lighten(rgb(30,31,33), 10%);  
  background: rgba(255,255,255,0.12);
  //border: 1px solid lighten(rgb(30,31,33), 13%);  
  border: 1px solid rgba(255,255,255,0.14);
  border-radius: 2px;
}

.editor-timeline-clip--render[data-clip-type='screenVideo']
.editor-timeline-clip-inner[data-single-screen-chunk='true']
.editor-timeline-clip-inner-chunk-bg{  
  display: none;
}

.editor-timeline-clip--render[data-clip-type='screenVideo'][data-clip-active='false']
.editor-timeline-clip-inner[data-single-screen-chunk='false']
.editor-timeline-clip-inner-chunk-bg{
  background: rgba(255,255,255,0.05);
  border: 1px solid rgba(255,255,255,0.1);
}


// .editor-timeline-clip--render[data-clip-type='webcamInstructions']
// .editor-timeline-clip-inner-chunk-iconContainer{
//   width: 20px;
//   height: 20px;

//   display: flex;
//   align-items: center;
//   justify-content: center;

//   margin-right: 4px;
// }

// .editor-timeline-clip--render[data-clip-type='webcamInstructions']
// .editor-timeline-clip-inner-chunk-iconContainer svg{
//   width: 18px;
//   height: 18px;
//   fill: var(--instructionsText);
//   opacity: 0.75;
//   transform: scale(1.02);
// }

// .editor-timeline-clip--render[data-clip-type='webcamInstructions']
// .editor-timeline-clip-inner-chunk-text{  
//   color: white;  
//   opacity: 0.75;
// }



.editor-timeline-clip-inner-chunk:last-child
.editor-timeline-clip-inner-chunk-bg{
  right: 2px;
}



.editor-timeline-clip--render[data-clip-type='webcamRecording']
.editor-timeline-clip-inner-chunk-bg{  
  //background: lighten(rgb(30,31,33), 10%);  
  background: rgba(255,255,255,0.02);
  background: saturate(lighten(#1D282B, 6%), 5%);
  //border: 1px solid lighten(rgb(30,31,33), 13%);  
  border: 1px solid rgba(255,255,255,0.02);
  border: 1px solid lighten(#1D282B, 10%);
}



.editor-timeline-clip--render[data-clip-type='linkedWebcamRecording']
.editor-timeline-clip-inner-chunk-bg{  
  background: darken(desaturate(rgba(110,99,217,0.70), 22%), 20%);  
  border: 1px solid lighten(rgba(110,99,217,0.4), 0%);

}




// .editor-timeline-clip-inner-chunk-line{
//   position: absolute;
//   right: -1px;
//   width: 1px;
//   width: 4px;
//   height: 4px;
//   border-radius: 3px;
//   top: 5px;

//   // top: -3px;
//   // bottom: -3px;
//   background: red;

// }


// .editor-timeline-clip--render[data-clip-type='webcamDraft']
// .editor-timeline-clip-inner-chunk-line{  
//   background: lighten(rgb(30,31,33), 35%);  
// }

// .editor-timeline-clip--render[data-clip-type='webcamRecording']
// .editor-timeline-clip-inner-chunk-line{
//   background: lighten(#1D282B, 26%);  
// }

// .editor-timeline-clip-inner-chunk:last-child
// .editor-timeline-clip-inner-chunk-line{
//   opacity: 0;
// }