
.dpLayoutBox{
	display: flex;
}

.dpLayoutBox-box{
	border-radius: 5px;
	overflow: hidden;
	background: var(--251gray);

	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	box-sizing: border-box;

	cursor: pointer;

	margin-right: -10px;
}


.dpLayoutBox-box-indicatorSquare{
	position: absolute;

	z-index: 5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dpLayoutBox-indicatorSquare-inner{
	width: 16px;
	height: 16px;
	// width: 100%;
	// height: 100%;
	background: var(--text200);
	border-radius: 3px;

	transition: background 100ms linear;
	
	cursor: pointer;
}

.dpLayoutBox-leftContainer:hover
.dpLayoutBox-indicatorSquare-inner,
.dpLayoutBox-box[data-dragging-state="true"]
.dpLayoutBox-indicatorSquare-inner{
	background: var(--text400);

}

.dpLayoutBox-box-dotBox{
	display: flex;
	align-items: center;
	justify-content: center;
}

.dpLayoutBox-box-dotBox-dot{
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background: var(--text500);
	opacity: 0.25;
	transform: scale(0.9);
}


.dpLayoutBox-leftContainer{
	margin-right: 10px;
}

.dpLayoutBox-rightContainer{
	flex-grow: 1;
	flex-shrink: 1;	
}