
.editor-transcriptPanel-topBar{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: $headerHeight-transcriptPanel;
  

  display: flex;
  align-items: flex-start;

  box-sizing: border-box;
  padding-top: 16px;
  padding-left: 30px;  
  padding-right: 8px;

  z-index: 50;
  pointer-events: none;
}


//

.editor-transcriptPanel-topBar-btn{
  padding-right: 8px;
  padding-left: 0px;  

  height: 26px;  
  
  position: relative;
  top: 4px;  
  background: none;
  border: 1px solid $invisible;

  // background: $tempr;

  border-radius: 3px;

  z-index: 20;
  
  pointer-events: auto !important;
}

.editor-transcriptPanel-topBar-btn--recordCamera{
  padding-left: 2px;
  padding-right: 10px;

  z-index: 100;
}

.editor-transcriptPanel-topBar-btn--dropdown{
  width: 32px;
  padding-left: 0px;
  padding-right: 0px;
}

.editor-transcriptPanel-topBar-btn:hover{  
  // background: lighten(rgb(35,36,40), 1.5%);  
  //border: 1px solid lighten(rgb(45,46,47), 1.5%);
}


.editor-transcriptPanel-topBar-btn[data-state='open'],
.editor-transcriptPanel-topBar-btn[data-state='open']:hover{ 
  background: lighten(rgb(35,36,40), 3%); 
  border: 1px solid lighten(rgb(45,46,47), 3%);
}

.editor-transcriptPanel-topBar-btn-iconContainer{
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-transcriptPanel-topBar-btn-iconContainer svg{
  fill: var(--text500);
  opacity: 0.5;
}

.editor-transcriptPanel-topBar-btn--speakerDropdown
.editor-transcriptPanel-topBar-btn-iconContainer svg{
  //fill: #6392ff;
  width: 13px;
  height: 13px;  
  position: relative;
  right: -1px;
  top: 0.5px;  
}

.editor-transcriptPanel-topBar-btn--recordCamera 
.editor-transcriptPanel-topBar-btn-iconContainer svg{
  //fill: #6392ff;
  width: 18px;
  height: 18px;  
  position: relative;
  right: -1.5px;
  transform: scale(1.02);
}

.editor-transcriptPanel-topBar-btn--dropdown 
.editor-transcriptPanel-topBar-btn-iconContainer svg{
  width: 18px;
  height: 18px;  
}

.editor-transcriptPanel-topBar-btn--recordCamera:hover svg{
  opacity: 0.8;
}

.editor-transcriptPanel-topBar-btn-label{
  font-weight: $fw-medium;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;
  font-size: 13.5px;
}

.editor-transcriptPanel-topBar-btn--recordCamera
.editor-transcriptPanel-topBar-btn-label{
  margin-left: 4px;
  opacity: 0;
  transform: translateX(-2px);
  transition: all 50ms linear;
}

.editor-transcriptPanel-topBar-btn--recordCamera:hover
.editor-transcriptPanel-topBar-btn-label{
  opacity: 1;
  transform: translateX(0px);
}

//


.editor-transcriptPanel-topBar-hSpacer{
  min-width: 10px;
  flex-grow: 1;
  flex-shrink: 1;
}

//

.editor-transcriptPanel-topBar-btn-tooltipContainer{
  position: absolute;
  margin-top: 4px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  opacity: 0;
}


.editor-transcriptPanel-topBar-btn:hover
.editor-transcriptPanel-topBar-btn-tooltipContainer{
  opacity: 1;
}