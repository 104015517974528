$upgradeBlue: rgb(50, 99, 250);

.settingsModalContainer{
	position: fixed;
	z-index: 800;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;

	display: flex;
	align-items: center;
	justify-content: center;
}

.settingsModal{
	width:  1100px;
	height: 680px;

	position: relative;
	z-index: 10;

	display: flex;

	border-radius: 10px;
	overflow: hidden;

	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.5);
	border: 1px solid rgb(50,50,60); 
}


//


.settingsModal-sideMenu{
	width: 360px;
	height: 100%;
	background: lighten(rgb(28,30,34), 3%);

	display: flex;
	flex-direction: column;
	border-right: 1px solid rgb(50,50,60);

	box-sizing: border-box;
	padding-left: 40px;
	padding-bottom: 30px;
	padding-top: 60px;
	padding-right: 20px;
}

.settingsModal-sideMenu-profile{
	display: flex;
	align-items: center;
	width: calc(100% - 12px);

	// background: $tempr;
	background: lighten(rgb(28,30,34), 4%);

	padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 12px;
	padding-right: 12px;
	margin-left: -14px;

	border-radius: 8px;
}

.settingsModal-sideMenu-profile-leftContainer{
	padding-right: 9px;
}

.settingsModal-sideMenu-profile-avatarContainer{
	margin-left: 4px;

	width: 48px;
	height: 48px;

	object-fit: cover;
}

img.settingsModal-sideMenu-profile-avatar{
	width: 100%;
	height: 100%;
	border-radius: 5px;
}

.settingsModal-sideMenu-profile-rightContainer{
	display: flex;
	flex-direction: column;
	justify-content: center;

	user-select: none;
	cursor: default;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  width: 100%;
  max-width: 100%;
  flex-grow: 0;
}

.settingsModal-sideMenu-profile{

}

.settingsModal-sideMenu-profile-name{
	font-size: 19px;
	
	font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 560;
  letter-spacing: 0.012em;

	color: var(--text500);

	margin-bottom: 4px;
	margin-top: -1px;


  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  flex-grow: 0;
}

.settingsModal-sideMenu-profile-email{
	font-size: 15px;
	font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 420;
  letter-spacing: 0.012em;

	color: var(--text500);
	opacity: 0.65;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

	flex-grow: 0;
}

.settingsModal-sideMenu-vSpacer{
	min-height: 10px;
	flex-grow: 1;
	flex-shrink: 1;
}

.settingsModal-sideMenu-tabContainer{
	width: 100%;
	margin-left: -4px;

	display: flex;
}
.settingsModal-sideMenu-tab{
	padding-left: 8px;

	flex-grow: 1;
	flex-shrink: 1;

	height: 30px;

	justify-content: flex-start;

	font-size: 15px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.012em;

  color: var(--text600);

  opacity: 0.25;
}

.settingsModal-sideMenu-tab:hover{
	opacity: 0.8;
}



//


.settingsModal-panel{
	height: 100%;
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	background: var(--bg-editor);
	//background: rgb(24,25,28);

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	padding-left: 64px;
	padding-right: 64px;

	overflow-y: scroll;
	padding-top: 48px
}


.settingsModal-panel-section{
	width: 100%;

	padding-bottom: 32px;
}


.settingsModal-panel-section-header{
	position: relative;
	width: 100%;
	height: 40px;

	display: flex;
	align-items: center;

	margin-bottom: 10px;
	user-select: none;
}

.settingsModal-panel-section-header-label,
.settingsModal-panel-section-header-label-tab{
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 16px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 520;
  letter-spacing: 0.012em;

  color: var(--text600);
}

.settingsModal-panel-section-header-label-tab{
	padding-left: 8px;
	padding-right: 8px;
}

.settingsModal-panel-section-header-label-tab:first-child{
	margin-left: -8px;
}

.settingsModal-panel-section-header-label-tab[data-active-state='inactive']{
	opacity: 0.5;
}

.settingsModal-panel-section-header-label-tab[data-active-state='inactive']:hover{
	opacity: 0.95;
}

.settingsModal-panel-section-header-hSpacer{
	flex-grow: 1;
	flex-shrink: 1;
}

.settingsModal-panel-section-header-btn{
	min-width: 70px;

	padding-right: 8px;
	padding-left: 8px;
	height: 26px;

	font-size: 14px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.012em;

  color: var(--text600);
  background: darken(#516CE8, 1%); 
  border: 1px solid darken(#516CE8, 4%);

  background: $upgradeBlue;
  border: 1px solid darken($upgradeBlue, 4%);

  color: white;

  border-radius: 3px;
}

.settingsModal-panel-section-header-btn:hover{		
	background: darken($upgradeBlue, 3%);
}

.settingsModal-panel-section-header-btn--secondary{
	background: var(--246gray);
	border: 1px solid var(--line200);
	padding-left: 10px;
	padding-right: 10px;
}

.settingsModal-panel-section-header-btn--secondary:hover{
	background: var(--243gray);
}





//

.settingsModal-panel-section-header-divider{
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 1px;
	background: var(--line300);
	opacity: 0.6;
}

//
//

.settingsModal-panel-section--preferences{
	padding-bottom: 18px;
}

.settingsModal-panel-section--preferences
.settingsModal-panel-section-header{
	margin-bottom: 18px;
}

@import 'Settings-SubscriptionPanel';

.settingsModal-panel-section--members-rowContainer{
	width: calc(100% + 24px);
	margin-left: -12px;		
	user-select: none !important;
}

.settingsModal-panel-section--members-row{
	display: flex;
	align-items: center;
	height: 42px;

	box-sizing: border-box;
	padding-left: 12px;
	padding-right: 8px;	
}



.settingsModal-panel-section--members-row-avatarContainer{
	width: 28px;
	height: 28px;

	flex-grow: 0;
	flex-shrink: 0;

	object-fit: cover;

	padding-right: 10px;
}

.settingsModal-panel-section--members-rowContainer--user--suspended
.settingsModal-panel-section--members-row-avatarContainer{
	opacity: 0.5;
}


.settingsModal-panel-section--members-row-avatar{
	width: 100%;
	height: 100%;
	border-radius: 4px;	
}

.settingsModal-panel-section--members-row-primary{
	font-size: 14.5px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.012em;

  color: var(--text600);
  padding-right: 8px;

  user-select: none;
}

.settingsModal-panel-section--members-rowContainer--user--suspended
.settingsModal-panel-section--members-row-primary{
	opacity: 0.5;
	text-decoration: line-through;
}


.settingsModal-panel-section--members-row-secondary{
	font-size: 14.5px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 380;
  letter-spacing: 0.012em;

  color: var(--text600);
  opacity: 0.5;

  user-select: none;
  margin-right: auto;
}


.settingsModal-panel-section--members-row-suspended{
	font-size: 14px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 330;
  letter-spacing: 0.012em;
  color: var(--text600);
  opacity: 0.5;
  margin-right: 3px;

  user-select: none;
}




.settingsModal-panel-section--members-row-dropdownBtn{
	width: 32px;
	height: 30px;
	opacity: 0.5;
	border-radius: 3px;
}

.settingsModal-panel-section--members-row-dropdownBtn:hover,
.settingsModal-panel-section--members-row-dropdownBtn[aria-expanded="true"]{
	opacity: 1;
	background: var(--252gray);
}

.settingsModal-panel-section--members-row-dropdownBtn svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
}


//

.settingsModalUnderlay{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	z-index: 5;

	background: rgba(0,0,4,0.75);
}

//

.settingsModal-closeBtnContainer{
	position: absolute;
	top: 0px;
	right: 0px;

	padding: 8px;

	cursor: pointer;
}

.settingsModal-closeBtn{
	width: 42px;
	height: 42px;
	border-radius: 50%;

	// background: $tempr;
}

.settingsModal-closeBtn svg{
	width: 24px;
	height: 24px;
	position: relative;
	top: -1px;
	left: -1px;

	fill: var(--text500);
	opacity: 0.25;
}

.settingsModal-closeBtnContainer:hover
.settingsModal-closeBtn svg{
	opacity: 0.75;
}

//

.settingsModal-inviteDialogOverlay{
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	z-index: 1000;

	display: flex;
	align-items: center;
	justify-content: center;

	background: rgba(0,0,0,0.35);
}


.settingsModal-inviteDialog{
	position: fixed;
	z-index: 2000;
	top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
	width: 440px;
	border-radius: 6px;

	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);
  //box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.28);
  //background: rgb(25, 27, 33);

  outline: none;

  padding-left: 28px;
  padding-right: 28px;

  user-select: none;
  cursor: default;
}

.settingsModal-inviteDialog:focus{
	outline: none;
}

.settingsModal-inviteDialog-header{
	display: flex;
	width: 100%;
	padding-top: 16px;
	align-items: center;
}

.settingsModal-inviteDialog-header-title{
	font-size: 16px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 520;
  letter-spacing: 0.012em;

  color: var(--text600);
}

.settingsModal-inviteDialog-header-hSpacer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
}

.settingsModal-inviteDialog-header-closeBtn{
	margin-right: -12px;
	padding-right: 12px;
	padding-left: 12px;
	height: 30px;

	color: var(--text400);

	font-size: 14px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 420;
  letter-spacing: 0.012em;

  border-radius: 4px;

  opacity: 0.5;
}

.settingsModal-inviteDialog-header-closeBtn:hover{
	opacity: 1;
	background: var(--250gray);
}

//

.settingsModal-inviteDialog-guestExplanation{
	max-width: 380px;

	display: flex;
	flex-direction: column;
	row-gap: 8px;

	margin-bottom: 14px;
}

.settingsModal-inviteDialog-guestExplanation-para{
	color: var(--text400);

	font-size: 12.5px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 400;
  letter-spacing: 0.014em;

  opacity: 0.75;
}

//

.settingsModal-inviteDialog-middle{
	display: flex;
	flex-direction: column;
}

.settingsModal-inviteDialog-middle-title{	
	color: var(--text500);

	font-size: 14px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 520;
  letter-spacing: 0.012em;

  margin-top: 8px;
  margin-bottom: 8px;

  opacity: 0.7;
}

.settingsModal-inviteDialog-middle-textArea{
	width: calc(100% + 16px);
	margin-left: -8px;

	border-radius: 4px;

	box-sizing: border-box;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 12px;
	padding-right: 12px;
	resize: none;

	height: 140px;

	line-height: 1.4;

	background: var(--252gray);
	border: 1px solid var(--line200);

	color: var(--text500);

	font-size: 14px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 420;
  letter-spacing: 0.012em;
}

.settingsModal-inviteDialog-middle-textArea:focus{
	border: 1px solid $focusBorderColor-dpElement;	
	border: 1px solid lighten($upgradeBlue, 7%);
	outline: none;
}



//

.settingsModal-inviteDialog-footer{
	display: flex;
	align-items: center;
	justify-content: flex-end;

	padding-top: 16px;
	padding-bottom: 22px;
}

.settingsModal-panel-section-submitBtn{
	min-width: 70px;

	padding-right: 12px;
	padding-left: 12px;
	height: 30px;

	font-size: 14px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.012em;

  color: var(--text600);
  background: var(--primary500);
  border: 1px solid darken(#516CE8, 4%);
  color: white;

  border-radius: 3px;

	background: darken(#516CE8, 1%);

	background: $upgradeBlue;
  border: 1px solid darken($upgradeBlue, 4%);
}

.settingsModal-panel-section-submitBtn:hover{
	background: darken(#516CE8, 3%);
	background: darken($upgradeBlue, 3%);
}


.settingsModal-panel-section-submitBtn:disabled{
	pointer-events: none;
	background: rgba(255,255,255,0.08);
	border: 1px solid rgba(255,255,255,0.12);
	opacity: 0.5;
}