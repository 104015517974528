.dpTabs{		
	display: flex;
	align-items: center;
	justify-content: flex-start;

	margin-left: -3px;
}

.dpTabs-tab{	
	height: 28px;
  cursor: pointer;

  display: flex;
  align-items: center;
}

.dpTabs-tab-inner{
	height: 22px;	
	display: flex;
	align-items: center;
	justify-content: center;

	padding-left: 5px;
	padding-right: 5px;

	border-radius: 4px;

	font-size: 12.75px;	
	color: var(--text600);
	
  font-variation-settings: "wght" 520;
  letter-spacing: 0.008em;  
}

.dpTabs--tight
.dpTabs-tab-inner{
	padding-right: 3px;
	padding-left: 3px;
}

.dpTabs-tab[data-active-tab='inactive']
.dpTabs-tab-inner{
	opacity: 0.5;
}

.dpTabs-tab[data-active-tab='inactive']:hover
.dpTabs-tab-inner{
	opacity: 0.9;
	background: var(--250gray);
}

.dpTabs-tab[data-active-tab='active']
.dpTabs-tab-inner{
	
	opacity: 1;
}

.dpTabs-tab[data-active-tab='active']:hover
.dpTabs-tab-inner{
	background: var(--246gray);
}


.dpTabs--light{
	opacity: 0.75;
}

.dpTabs--light:hover{
	opacity: 1;
}

.dpTabs--light
.dpTabs-tab-inner{
	font-variation-settings: "wght" 480;
}

.dpTabs-tab[data-active-tab='inactive']
.dpTabs-tab-inner{
	opacity: 0.5;
}

.dpTabs--light
.dpTabs-tab[data-active-tab='inactive']:hover
.dpTabs-tab-inner{
	opacity: 0.9;	
	background: none;
}

.dpTabs-tab[data-active-tab='active']
.dpTabs-tab-inner{
	
	opacity: 1;
}

.dpTabs--light
.dpTabs-tab[data-active-tab='active']:hover
.dpTabs-tab-inner{
	background: none;
}
