
.tempDevLoginFlow{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100vw;
	height: 100vh;


	z-index: 500000;

	background: var(--bg-editor);
}

//

.loginPageContainer{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100vw;
	height: 100vh;

	background: var(--bg-editor);
	-webkit-app-region: drag;
}

.signUpFlowContainer{
	position: absolute;
	width: 100%;
	height: 100%;
	//background: blue;
}

.signUpFlow{	
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	
}

.signUpFlow-draggableBG{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	z-index: 0;
	
}

//

.signUpFlow-panel{
	width: 370px;
	height: 425px;	
	margin-top: -5vh;
	//background: $tempr;
	// background: red;

	
	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	-webkit-app-region: no-drag;

	position: relative;
	z-index: 10;
}

.signUpFlow-panel--stage--signUp{
	height: 220px;
	padding-bottom: 20px;
	align-items: center;
}

.signUpFlow-panel--stage--login{
	height: auto;
	align-items: center;
	padding-top: 48px;
	padding-bottom: 42px;

	background: darken(rgb(36,37,42), 1%);
	border: 1px solid var(--line150);
	border-radius: 3px;
}

//

.signUpFlow-panel-logo{
	margin-bottom: 48px;
}

.signUpFlow-panel--stage--login
.signUpFlow-panel-logo{
	margin-bottom: 28px;
	margin-top: 4px;
}


.signUpFlow-panel-logo img{
	width: 72px;
	height: 72px;
	object-fit: contain;
}

.signUpFlow-panel-logo svg{	
	width: 75px;
	height: 40px;
	opacity: 0.35;
}

.signUpFlow-panel-logo svg {
	fill: white;	
}

.signUpFlow-panel-logo svg #icon{
	opacity: 0.75;
}

.signUpFlow-panel--stage--signUp svg{
	//fill: var(--yarnBlue);
	width: 800px;
	opacity: 1;
}

.signUpFlow-panel--stage--signUp svg #icon{
	// fill: var(--primary500);
	// fill: #A5C7FF;
	// opacity: 1;
}

//

.signUpFlow-panel-title{
	font-size: 30px;
	color: var(--text500);
	letter-spacing: -0.008em;

	font-variation-settings: "wght" 540;
  letter-spacing: 0.002em;


	margin-bottom: 24px;
	user-select: none;
	cursor: default;
}

// .signUpFlow-panel--stage--signUp
// .signUpFlow-panel-title,


.signUpFlow-panel--stage--login
.signUpFlow-panel-title{
	margin-bottom: 12px;

	font-size: 36px;
	color: var(--text500);
	letter-spacing: -0.008em;

	font-variation-settings: "wght" 720;
  letter-spacing: 0.002em;

}

.signUpFlow-panel--stage--download
.signUpFlow-panel-title{
	margin-bottom: 12px;
}

//

.signUpFlow-panel-middle{
	flex-grow: 1;
	flex-shrink: 1;
	min-height: 10px;
}

.signUpFlow-panel--stage--login
.signUpFlow-panel-middle{
	height: 84px;
}

//
//

.signUpFlow-panel-textInputItem{
	display: flex;
	flex-direction: column;
	margin-bottom: 17px;
	margin-bottom: 12px;
	margin-top: 12px;
}

.signUpFlow-panel-textInputItem-label{
	font-size: 15px;
	color: var(--text400);

	font-variation-settings: "wght" 480;
	font-variation-settings: "wght" 440;
  letter-spacing: 0.003em;
  letter-spacing: 0.008em;

	margin-bottom: 8px;
	cursor: default;
	user-select: none;
}

.signUpFlow-panel-textInputItem-inputContainer{
	width: 100%;
}

input.signUpFlow-panel-textInputItem-input{
	font-size: 16px;

	font-variation-settings: "wght" 440;
  letter-spacing: 0.003em;

	width: 100%;
	height: 40px;

	box-sizing: border-box;
	padding-left: 12px;

	border: 1px solid var(--line400);
	border-radius: 3px;

	background: var(--252gray);

	color: var(--text500);
}

input.signUpFlow-panel-textInputItem-input:hover{
	border: 1px solid var(--line500);
	background: var(--251gray);
}

input.signUpFlow-panel-textInputItem-input:focus{
	border: 1px solid var(--primary500);
	outline: 2px solid var(--primary100);

	border: 1px solid var(--accentBlue600);
	outline: none;
	background: var(--250gray);
}

//
//

.signUpFlow-panel-checkboxItem{
	display: flex;
	margin-bottom: 17px;
}

.signUpFlow-panel-checkboxItem-checkboxContainer{
	padding-right: 10px;
}

.signUpFlow-panel-checkboxItem-checkbox{
	width: 14px;
	height: 14px;
	background: $tempr;
	border-radius: 3px;

	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.03);
	cursor: pointer;
}

.signUpFlow-panel-checkboxItem-checkbox[data-state="unchecked"]{
	background: var(--bg-editor);
	border: 1px solid var(--line400);
}

.signUpFlow-panel-checkboxItem-checkbox[data-state="unchecked"]:hover{
	border: 1px solid var(--line600);
}

.signUpFlow-panel-checkboxItem-checkbox[data-state="checked"]{
	background: var(--primary500);
	border: 1px solid var(--primary500);
}

.signUpFlow-panel-checkboxItem-checkbox[data-state="checked"] svg{
	position: relative;
	// left: 1px;
	top: -0.5px;

	pointer-events: none;

	width: 14px;
	height: 14px;
	stroke: white;
	opacity: 1;
	stroke-width: 0.5px;
	fill: white;
}

.signUpFlow-panel-checkboxItem-textContainer{
	display: flex;
	flex-direction: column;
	cursor: default;
	user-select: none;
	// padding-top: 2px; // optical v-center with checkbox
}

.signUpFlow-panel-checkboxItem-label{
	font-size: 15px;
	font-variation-settings: "wght" 480;
  letter-spacing: 0.003em;

	color: var(--text500);

}

.signUpFlow-panel-checkboxItem-label span.signUpFlow-panel-checkboxItem-label-light{
	color: var(--text200);
}

.signUpFlow-panel-checkboxItem-description{
	margin-top: 2px;

	font-size: 14px;
	font-weight: $fw-regular;
	color: var(--text200);
}

//

.signUpFlow-panel-middle-descriptionPara{
	font-size: 15px;
	font-weight: $fw-regular;
	color: var(--text300);	

	margin-bottom: 10px;
	user-select: none;
	cursor: default;

	line-height: 1.27;
}



//

.signUpFlow-panel-submitBtnContainer{
	position: relative;
}

.signUpFlow-panel-submitBtnContainer-smallVSpacer{
	height: 10px;
}

.signUpFlow-panel--stage--login
.signUpFlow-panel-submitBtnContainer{
	display: flex;
	flex-direction: column;	
}

.signUpFlow-panel-submitBtnErrorContainer{
	position: absolute;
	left: -20px;
	width: calc(100% + 40px);
	top: 100%;
	margin-top: 12px;

	display: flex;
	align-items: center;
	justify-content: center;

	text-align: center;

	font-size: 14.5px;
	font-weight: $fw-regular;
	color: var(--text300);	
	color: var(--accentRed500);
}


button.signUpFlow-panel-submitBtn{
	width: 100%;
	height: 47px;

	font-size: 16px;
	font-weight: $fw-medium;
	color: var(--text600);

	border: 1px solid var(--line375);
	border-bottom: 1px solid var(--line400);
	border-radius: 4px;
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.04);

	position: relative;
	z-index: 10;
	cursor: pointer;

}

.signUpFlow-panel--stage--signUp
button.signUpFlow-panel-submitBtn{
	width: 300px;
	background: var(--253gray);
	background: rgba(245,245,255,0.02);
}



button.signUpFlow-panel-submitBtn:hover{
	border: 1px solid var(--line450);
	border-bottom: 1px solid var(--line500);
	//box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.06);
	box-shadow: 0px 0px 4px 0px rgba(255,255,255,0.01);
	background: var(--250gray);
}

.signUpFlow-panel--stage--login
button.signUpFlow-panel-submitBtn{
	width: 300px;	
	height: 44px;
	border: 1px solid var(--line150);
	border-radius: 3px;

	background: lighten(rgb(36,37,41), 1%);
	
}

.signUpFlow-panel--stage--login
button.signUpFlow-panel-submitBtn:hover{		
	border: 1px solid rgb(255,255,255,0.15);
	background: lighten(rgb(36,37,41), 1.25%);
	
}


.signUpFlow-panel--stage--login
button.signUpFlow-panel-submitBtn--continueWithSSO{
	background: hsl(210, 94%, 48%);
	border: hsl(210, 94%, 44%);
}

.signUpFlow-panel--stage--login
button.signUpFlow-panel-submitBtn--continueWithSSO:hover{
	background: hsl(210, 94%, 46%);
	border: hsl(210, 94%, 40%);
}


.signUpFlow-panel-submitBtn-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	width: 20px;
	height: 20px;

	margin-right: 8px;
	margin-left: -14px;
}

.signUpFlow-panel--stage--signUp
.signUpFlow-panel-submitBtn-iconContainer{
	margin-right: 12px;
}

.signUpFlow-panel-submitBtn-iconContainer--forceLeft{
	position: absolute;
	left: 17px;
	margin-left: 0px;
	margin-right: 0px;
}

.signUpFlow-panel-submitBtn-iconContainer svg{
	width: 20px;
	height: 20px;

	fill: var(--yarnBlue);

}

.signUpFlow-panel-submitBtn--google
.signUpFlow-panel-submitBtn-iconContainer svg{
	width: 16px;
	height: 16px;
}

.signUpFlow-panel-submitBtn--sso
.signUpFlow-panel-submitBtn-iconContainer svg{
	width: 19px;
	height: 19px;
	fill: white;
	opacity: 0.5;

	position: relative;
	right: -3px;
}

