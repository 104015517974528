.editor-slideEditor-ghostContainer{
  position: fixed;
  top: 0px;
  left: 0px;
  pointer-events: none !important;
  background: $tempr;
  z-index: 10;

  visibility: hidden;
}

.editor-slideEditor-ghostTextItem{
  position: absolute;
  top: 0px;
  left: 0px;
  background: $tempr;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
}