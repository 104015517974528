//
//


.rightPanel--charts-chartTypeGroup{
	display: flex;
	flex-wrap: wrap;

	align-items: center;
	justify-content: center;
	width: calc(100% + 10px);
	margin-left: -4px;
	margin-bottom: 12px;
}


//
// NUMBER INPUT

.rightPanel--charts-numberInput{
	width: 100%;
}

.rightPanel--charts-numberInput-input{
	width: 100%;
	padding: none;
	height: 40px;

	border: none;
	background: var(--250gray);
	border: 1px solid var(--line200);
	border-radius: 3px;

	color: var(--text500);

	box-sizing: border-box;
	padding-left: 10px;

	font-size: 16px;
}

.rightPanel--charts-numberInput-input:focus{
	outline: none;
	border: 1px solid rgb(91,154,255);
}


//
// TITLE INPUT

.rightPanel--charts-titleInput{
	width: 100%;
}

.rightPanel--charts-titleInput-input{
	width: 100%;
	padding: none;
	height: 30px;

	border: none;
	background: var(--250gray);
	border: 1px solid var(--line200);
	border-radius: 2px;	

	box-sizing: border-box;
	padding-left: 8px;

	font-size: 14px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;
}

.rightPanel--charts-titleInput-input:focus{
	outline: none;
	border: 1px solid rgb(91,154,255);
}




//
//


.rightPanel--charts-spreadsheetContainer{
	width: 100%;
	height: 180px;
	position: relative;
	margin-left: 1px;
	margin-top: 6px;
	margin-bottom: 2px;

	// background: $tempr;

}

.rightPanel--charts-spreadsheet:focus,
.rightPanel--charts-spreadsheet:focus-within{
	outline: none;
	// outline: 4px solid red;
}

.rightPanel--charts-spreadsheet-rowStatesList{
	position: absolute;
	left: 0px;
	top: 0px;



}

.rightPanel--charts-spreadsheetRowBtn{
	width: 30px;
	// border-radius: 5px;
	border: 1px solid lighten(rgb(42,42,48), 10%);
	//border: 2px solid var(--bg-editorPanel);
	margin-bottom: -1px;
	background: darken(rgb(42,42,48), 4%);	
	background: darken(rgb(42,42,48), 2%);	
}

.rightPanel--charts-spreadsheetRowBtn:hover{
	background: lighten(rgb(42,42,48), 3%);
}




.rightPanel--charts-spreadsheetRowBtn svg{
	width: 22px;
	height: 22px;
	position: relative;	

	fill: white;
	opacity: 0.7;

	transform-origin: center center;
	transform: scale(0.95);
}

.rightPanel--charts-spreadsheetRowBtn--state--default svg{
	opacity: 0.15;
}


.rightPanel--charts-spreadsheetRowBtn:hover svg{
	opacity: 0.8;
}


//


.rightPanel--charts-spreadsheet{

	width: 300px;
	height: 180px;
	// background: $tempr;

	position: absolute;
	top: 0px;
	left: 30px;		

	display: flex;
	flex-direction: column;
	align-items: center;
}

.rightPanel--charts-spreadsheet-cellsContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	// background: $tempr;
}




.rightPanel--charts-spreadsheet-cell{
	border: 1px solid lighten(rgb(42,42,48), 10%);
	margin-bottom: -1px;
	margin-left: -1px;
	background: darken(rgb(42,42,48), 2%);	

	display: flex;
	align-items: center;
}

.rightPanel--charts-spreadsheet-cell--selected{
	z-index: 5;	
}

.rightPanel--charts-spreadsheet:focus
.rightPanel--charts-spreadsheet-cell--selected,
.rightPanel--charts-spreadsheet:focus-within
.rightPanel--charts-spreadsheet-cell--selected{
	z-index: 5;
	border: 1px solid rgb(91,154,255);
}

.rightPanel--charts-spreadsheet-cell--selected{	
	border: 1px solid var(--line600);
}




.rightPanel--charts-spreadsheet-cell--selected.rightPanel--charts-spreadsheet-cell--editing{
	z-index: 5;
	border: 1px solid lighten(rgb(91,154,255), 5%);
	outline: 1px solid rgba(91,154,255, 0.8);
}


.rightPanel--charts-spreadsheet-cell--unselected:hover{
	//border: 1px solid lighten(rgb(42,42,48), 14%);
	//background: darken(rgb(42,42,48), 0%);
}

.rightPanel--charts-spreadsheet-cell--unselected.rightPanel--charts-spreadsheet-cell--subSelected{	
	border: 1px solid rgba(71,105,160,0.7);
	z-index: 3;
}




.rightPanel--charts-spreadsheet-cell--subSelected{
	background: rgba(60,68,110,0.3);
}





// .rightPanel--charts-spreadsheet-cell--subSelectedHead{
// 	z-index: 10;
// 	background: $tempr !important;
// }


.rightPanel--charts-spreadsheet-cellInput{
	padding-top: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	padding-right: 0px;
	background: none;
	border: none;
	outline: none;
	box-shadow: none;
	background: rgba(255,255,255,0.08);
	z-index: 10;
}




.rightPanel--charts-spreadsheet-cellInput, .rightPanel--charts-spreadsheet-cell{
	font-size: 15px;
	color: white;

	box-sizing: border-box;
	padding-left: 7px;
	line-height: 1.1;

}

.rightPanel--charts-spreadsheet-cell{
	user-select: none !important;
}

.rightPanel--charts-spreadsheet-cellInput{	
	// padding-top: 2px;
}

input.rightPanel--charts-spreadsheet-cellInput::selection{
	background-color: #1B55B2 !important;
}

