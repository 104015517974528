
//
//

.editorInsertLauncher-overlay{
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.04);
	cursor: pointer;
}

.editorInsertLauncher{
	height: 320px;
	width: 540px;
	padding-top: 0px;
	padding-bottom: 0px;


	// padding-left: 4px;
	// padding-right: 4px;
	border-radius: 6px;

	box-shadow: none;
	// border: 1px solid rgba(255,255,255,0.125) !important;	
	// background: rgba(22,22,32,0.85);
	// backdrop-filter: blur(25px);

	// border: 1px solid rgba(255,255,255,0.125) !important;	
	// background: rgba(22,22,25,0.92);
	// backdrop-filter: blur(25px);

	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);
  box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.28);

  background: rgb(25, 27, 33);
  
	display: flex;
	flex-direction: column;
	row-gap: 10px;

	position: absolute;
	left: 50%;
	bottom: 0px;
	transform: translateX(-50%) translateY(-290px); // lame

	margin-left: calc((#{$width-centerLeftContainer} - #{$width-detailPanelContainer}) / 2);	
}

.editorInsertLauncher:focus{
	outline: none;
}

.editorInsertLauncher-devButton{
	width: 100%;
	height: 30px;
	border: 1px solid red;

	color: white;

}

//
//

.editorInsertLauncher-searchContainer{
	height: 36px;
	width: 100%;

	position: relative;

	background: rgba(255,255,255,0.04);
	border-bottom: 1px solid rgba(255,255,255,0.08);
}

input.editorInsertLauncher-searchInput{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%; 

	box-sizing: border-box;

	border: none;
	border-radius: 0px;

	padding-left: 46px;
	//padding-top: 3px;
	padding-left: 22px;
	caret-color: var(--primary600) !important;

	color: white;


	background: none;


  font-size: 14px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 500;
  letter-spacing: 0.008em;
}

input.editorInsertLauncher-searchInput:focus{
	outline: none;
	border: none;
}

input.editorInsertLauncher-searchInput::placeholder{
	color: var(--text400);
}

//

.editorInsertLauncher-resultsList{
	width: 100%;
	min-height: 10px;

	flex-grow: 1;
	flex-shrink: 1;

	overflow-y: scroll;
	scrollbar-gutter: stable;

	box-sizing: border-box;
	padding-left: 7px;
	padding-right: 2px;
}

.editorInsertLauncher-resultsList-item{
	width: 100%;
	height: 32px;
	// border: 1px solid red;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 3px;
}

.editorInsertLauncher-resultsList-item[data-active-state='active']{
	background: rgba(255,255,255,0.06);
	background: var(--primary600);
}

.editorInsertLauncher[data-mouse-moved='true']
.editorInsertLauncher-resultsList-item[data-active-state='inactive']:hover{
	background: rgba(255,255,255,0.08);
}


.editorInsertLauncher-resultsList-item-leftContainer{
	width: 36px;
	display: flex;
	align-items: center;
	justify-content: center;

	padding-left: 4px;
}

.editorInsertLauncher-resultsList-item-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	width: 24px;
	height: 24px;
}

.editorInsertLauncher-resultsList-item-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.65;
	transform: scale(1.05);
}

.editorInsertLauncher-resultsList-item[data-active-state='active']
.editorInsertLauncher-resultsList-item-iconContainer svg{
	opacity: 0.85;
}

.editorInsertLauncher-resultsList-item-iconContainer svg.icon--name--deviceiPhone{
	width: 24px;
	height: 24px;
}

.editorInsertLauncher-resultsList-item-iconContainer svg.icon--name--addFromLibrary{
	width: 16px;
	height: 16px;
}

.editorInsertLauncher-resultsList-item-centerContainer{
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	flex-grow: 1;
	flex-shrink: 1;
	min-width: 10px;
}

.editorInsertLauncher-resultsList-item-title{
	font-size: 14px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;
}

.editorInsertLauncher-resultsList-item[data-active-state='active']
.editorInsertLauncher-resultsList-item-title{
	color: white;
}

.editorInsertLauncher-resultsList-item-rightContainer{
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.editorInsertLauncher-resultsList-item-shortcutIconContainer{
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

}

.editorInsertLauncher-resultsList-item-shortcutIconContainer svg{
	width: 16px;
	height: 16px;

	fill: var(--text500);
	opacity: 0.8;
}