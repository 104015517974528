.exportProgressTrayContainer{
	position: fixed;
	z-index: 5000;
	top: calc(#{$height-tabBar} + 8px);
	right: 10px;

	// bottom: 18px;
	// left: 18px;

	// left: 50%;
	// transform: translateX(-50%);
	// bottom: 10px;

	// left: 50%;
	// transform: translateX(-50%);
	// top: calc(#{$height-tabBar} + 8px);

	// background: $tempr;
}



@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.exportProgressTrayContainer[data-active-export='false'],
.exportProgressTrayContainer[data-active-export='false'] *{
	pointer-events: none !important;
}

.exportProgressTray{
	height: 70px;
	width: 300px;

	position: relative;
	// background: $tempr;

	border: 1px solid rgba(255,255,255,0.125) !important;	
	background: rgba(34,34,35,1);
	//backdrop-filter: blur(25px);

	display: flex;
	flex-direction: column;

	color: var(--text500);

	border-radius: 5px;

	box-sizing: border-box;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 10px;
	padding-bottom: 10px;


}

.exportProgressTray-primaryRow{
	display: flex;
	user-select: none;
	cursor: default;
}

.exportProgressTray-primaryRow-title{
	font-size: 14.25px;
  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 500;
  letter-spacing: 0.008em;

  transition: opacity 100ms linear;
}

// .exportProgressTray-primaryRow-title[data-opacity-state='light']{
// 	opacity: 0.75 !important;
// }

.exportProgressTray-primaryRow-timestamp{
	font-size: 13.5px;
  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;
  opacity: 0.7;

  margin-left: auto;

  font-feature-settings: "tnum", "ss01", "ss02";

  transition: opacity 250ms linear;
}

.exportProgressTray-primaryRow-timestamp[data-visible-state='visible']{
	opacity: 1;
}

.exportProgressTray-primaryRow-timestamp[data-visible-state='hidden']{
	opacity: 0;
}

.exportProgressTray[data-export-state='completed']
.exportProgressTray-primaryRow-timestamp{
	transition: opacity 50ms linear;
}


.exportProgressTray-primaryRow-title{	
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-right: 18px;

  opacity: 0;
	animation: fadeIn 150ms ease-out;
  animation-delay: 100ms;
  animation-fill-mode: forwards;
}

.exportProgressTray-primaryRow-title-label{
	margin-right: 5px;
}

.exportProgressTray-primaryRow-title-title{
	flex-grow: 1;
	flex-shrink: 1;

	font-variation-settings: "wght" 480;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  opacity: 0;

}

.exportProgressTray:hover
.exportProgressTray-primaryRow-title-title{
	opacity: 0.5;
}


.exportProgressTray-secondaryRow{
	display: flex;
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	font-size: 13px;
  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;

  
  cursor: default;

  opacity: 0;
	animation: fadeIn 150ms ease-out;
  animation-delay: 200ms;
  animation-fill-mode: forwards;
}

.exportProgressTray-secondaryRow-label{
	opacity: 0.5;
}

.exportProgressTray-secondaryRow-button{
	margin-top: -1px;

	padding-left: 5px;
	padding-right: 5px;
	border-radius: 3px;
	height: 24px;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
	color: white;
}

.exportProgressTray-secondaryRow-button:first-child{
	margin-left: -5px;
	opacity: 0.9;
}

.exportProgressTray-secondaryRow-button:nth-child(2){
	opacity: 0.5;
}

.exportProgressTray-secondaryRow-button:hover{
	opacity: 1 !important;
}


.exportProgressTray-progressBarContainer{	
	width: calc(100% - 32px);	
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 34px;
	transition: opacity 100ms;
	opacity: 0;
	animation: fadeIn 150ms ease-out;
  animation-delay: 100ms;
  animation-fill-mode: forwards;
}

.exportProgressTray-progressBar{
	height: 5px;
	width: 100%;
	border-radius: 4px;
	overflow: hidden;
	position: relative;
}

$inProgress-export-barColor: #4584EA;
$completed-export-barColor: #72C899;

.exportProgressTray-progressBar-bar {
  height: 100%;
  background: $inProgress-export-barColor;
  position: relative;
  z-index: 5;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: repeating-linear-gradient(
      to right,
      $inProgress-export-barColor 0%,
      lighten($inProgress-export-barColor, 8%) 50%,
      $inProgress-export-barColor 100%
    );
    background-size: 200% auto;
    animation: gradientMove 2s linear infinite reverse;
    z-index: 6;  
  }
}

.exportProgressTray[data-export-state='completed'] {
  .exportProgressTray-progressBar-bar {
    background: $completed-export-barColor;

    &::before {
      background: $completed-export-barColor;
      animation: pulseGreen 2s ease-in-out infinite;
    }
  }
}

@keyframes gradientMove { 
  0% { background-position: 0 0; }
  100% { background-position: -200% 0; }
}

@keyframes pulseGreen {
  0%, 100% { background-color: $completed-export-barColor; }
  50% { background-color: lighten($completed-export-barColor, 2%); }
}




.exportProgressTray-progressBar-track{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	background: rgba(255,255,255,0.15);
}

.exportProgressTray-downloadBtnContainer{
	position: absolute;
	left: 0px;
	bottom: 0px;
	width: 100%;
	height: 40px;
	box-sizing: border-box;
	padding-bottom: 11px;

	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding-left: 16px;
	padding-right: 16px;

	opacity: 0;
}


.exportProgressTray-downloadBtnContainer[data-visible-state='visible']{
	opacity: 1;
	transition: opacity 100ms linear;
	transition-delay: 200ms;
}

.exportProgressTray-downloadBtnContainer[data-visible-state='hidden'],
.exportProgressTray-downloadBtnContainer[data-visible-state='hidden'] *{
	pointer-events: none !important;
}


.exportProgressTray-downloadBtn{
	width: 100%;
	height: 30px;
	// background: var(--250gray);
	//background: $completed-export-barColor;
	//border: 1px solid $completed-export-barColor;
	background: var(--246gray);
	color: black;

	font-size: 13.75px;
  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 500;
  letter-spacing: 0.008em;

	//border: 1px solid rgb(113,148,255);

	display: flex;
	align-items: center;
	justify-content: center;

	color: rgb(113,148,255);
	color: white;
	border-radius: 3px;


}

.exportProgressTray-downloadBtn:hover{
	background: var(--243gray);
}

//

.exportProgressTray-cancelButton{
	position: absolute;
	top: 0px;
	right: 0px;
	width: 70px;
	height: 38px;

	// background: $tempr;

	box-sizing: border-box;
	padding-right: 18px;
	padding-top: 1px;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	font-size: 13px;
  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;

  color: var(--text500);

  opacity: 0;
  transition: opacity 50ms linear;
}



.exportProgressTray:hover
.exportProgressTray-cancelButton{
	opacity: 0.6;
}

.exportProgressTray:hover
.exportProgressTray-cancelButton:hover{
	opacity: 0.8;
}



//



.exportProgressTray-closeButton{
	position: absolute;
	top: 0px;
	right: 0px;
	width: 60px;
	height: 40px;

	opacity: 0.75;
	// background: $tempr;

}

.exportProgressTray-closeButton:hover{
	opacity: 1;
}

.exportProgressTray-closeButton-inner{
	position: absolute;
	top: 8px;
	right: 15px;
	width: 20px;
	height: 20px;
	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	background: rgba(255,255,255,0.08);
}

.exportProgressTray-closeButton:hover
.exportProgressTray-closeButton-inner{
	background: rgba(255,255,255,0.12);
}

.exportProgressTray-closeButton-inner svg{
	width: 15px;
	height: 15px;
	position: relative;
	left: 0px;
	top: 0px;
	fill: white;

	opacity: 0.75;
}

.exportProgressTray-closeButton:hover
.exportProgressTray-closeButton-inner svg{
	transform: scale(1.1);
}

//


.exportProgressTrayDev{
	position: fixed;
	bottom: 20px;
	right: 20px;

	z-index: 500;

	background: white;

}

.exportProgressTrayDev button{
	padding: 5px;
	margin: 5px;
	outline: 1px solid blue;
}