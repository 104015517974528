$aspect-webcamPreview: 16/9;
$padding-recordWebcamPreview: 10px;

.recordWebcamWindow--preview {  
  display: flex;
  flex-direction: column;
  padding: $padding-recordWebcamPreview;
  box-sizing: border-box;  
  height: auto;
  // -webkit-app-region: drag;
}

$width-webcamPreviewVideo: calc(#{$width-webcamWindow} - (#{$padding-recordWebcamPreview} * 2));

.recordWebcamWindow--preview-videoContainer {
  flex-grow: 0;
  flex-shrink: 0;

  width: $width-webcamPreviewVideo;
  height: calc(#{$width-webcamPreviewVideo} / #{$aspect-webcamPreview});
  //height: calc((100% - #{2 * $padding-recordWebcamPreview}) / #{$aspect-webcamPreview});
  border-radius: 8px;
  background: rgb(22,22,23);
  position: relative;
  // -webkit-app-region: no-drag;
  overflow: hidden;

  //background: $tempr;
}

video.recordWebcamWindow--preview-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.recordWebcamWindow--preview-toolbarContainer {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  margin-top: $padding-recordWebcamPreview;

  // background: $tempb;
  // -webkit-app-region: drag;
}

.recordWebcamWindow--preview-toolbar {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  //background: rgb(22,22,23);
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;

  height: 60px;
  

  box-sizing: border-box;
  padding: 5px;
  padding-top: 0px;
  padding-bottom: 5px;
  column-gap: 5px;
}


.recordWebcamWindow--preview-toolbar-gapSpacer{
  flex-grow: 0;
  flex-shrink: 0;

  width: 48px;
}

.recordWebcamWindow--preview-toolbar-rerecordBtn,
.recordWebcamWindow--preview-toolbar-rightSpacer {
  height: 100%;
  height: 44px;
  pointer-events: auto;
  width: 140px;
  border-radius: 5px;
  
  box-sizing: border-box;
}


.recordWebcamWindow--preview-toolbar-rerecordBtn{
	background: rgb(26,26,28);
  opacity: 0.75;
  
  // -webkit-app-region: no-drag;	
}


.recordWebcamWindow--preview-toolbar-rerecordBtn-iconContainer{
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: -10px;
  margin-right: 3px;
}

.recordWebcamWindow--preview-toolbar-rerecordBtn svg{
  fill: var(--text500);
  width: 20px;
  height: 20px;
  opacity: 0.6;
  transform: scaleX(-100%);
  transition: opacity 75ms linear;
}


.recordWebcamWindow--preview-toolbar-rerecordBtn-label{
  
  color: rgba(255,255,255,0.95);
  font-size: 15px;
  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.012em;
}



.recordWebcamWindow--preview-toolbar-rerecordBtn:hover{
	background: lighten(rgb(22,22,23), 2%);
  opacity: 1;
  transition: all 100ms linear;
}

.recordWebcamWindow--preview-toolbar-rerecordBtn:hover svg{
	opacity: 0.7;
}


.recordWebcamWindow--preview-toolbar-rerecordBtn[data-visibility-state='hidden']:not(:hover){
  opacity: 0 !important;
}


.recordWebcamWindow--preview-toolbar-upload {
  border-radius: 5px;

  height: 100%;
  min-width: 10px;
  flex-grow: 1;
  flex-shrink: 1;

  background: var(--primary500);

  color: rgba(255,255,255,0.95);
  font-size: 18px;
  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 520;
  letter-spacing: 0.012em;

  // -webkit-app-region: no-drag;


}

.recordWebcamWindow--preview-toolbar-upload:hover,
.recordWebcamWindow--preview-toolbar-upload[data-submit-loading='true'] {
  background: var(--primary550);
  color: white;
}

.recordWebcamWindow--preview-toolbar-upload-bar{
  width: 260px;
  height: 5px;

  border-radius: 10px;

  position: relative;

  background: rgba(255,255,255,0.2); 

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
}

.recordWebcamWindow--preview-toolbar-upload-bar-ball{
  width: 100px;
  height: 6px;
  border-radius: 4px;
  
  background: rgba(255,255,255,0.5); 
}

  

//
//
// CONTROLS

$inset-videoPreviewPlayerControlsInset: 8px;
$height-videoPreviewPlayerControls: 32px;

.recordWebcamWindow--preview-controls,
.recordWebcamWindow--preview-preControls{
  position: absolute;
  bottom: $inset-videoPreviewPlayerControlsInset;
  left: $inset-videoPreviewPlayerControlsInset;

  display: flex;
  align-items: center;

  padding-left: 4px;
  padding-right: 10px;
  border-radius: 5px;

  background: rgba(0,0,0,0.55);
  backdrop-filter: blur(25px);

  z-index: 10;

  height: $height-videoPreviewPlayerControls;
}


.recordWebcamWindow--preview-preControls{
  pointer-events: none;
}

.recordWebcamWindow--preview-controls{
  right: $inset-videoPreviewPlayerControlsInset;
}

.recordWebcamWindow--preview-controls-playPauseBtn,
.recordWebcamWindow--preview-preControls-iconContainer{
  flex-grow: 0;
  flex-shrink: 0;

  width: 34px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

}

.recordWebcamWindow--preview-controls-playPauseBtn svg,
.recordWebcamWindow--preview-preControls-iconContainer svg{
  width: 16px;
  height: 16px;
  fill: white;
}

.recordWebcamWindow--preview[data-media-type='audio']
.recordWebcamWindow--preview-controls-playPauseBtn svg{
  fill: var(--text400)
}

//

.recordWebcamWindow--preview-controls-seekBar{
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  // background: $tempr;
  display: flex;

  box-sizing: border-box;
  padding-left: 4px;
  padding-right: 4px;
}

.recordWebcamWindow--preview-controls-seekBar-seekBar{
  width: 100%;
  height: 100%;
  position: relative;
}

.recordWebcamWindow--preview-controls-seekBar-seekBar-track{
  height: 4px;
  margin-top: 14px;
  overflow: hidden;
  background: none;
}

.recordWebcamWindow--preview-controls-seekBar-seekBar-track-0{
  background: white;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.recordWebcamWindow--preview-controls-seekBar-seekBar-track-1{
  background: white;
  opacity: 0.2;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.recordWebcamWindow--preview[data-media-type='audio']
.recordWebcamWindow--preview-controls-seekBar-seekBar-track-0{
  background: #4584EA;
  opacity: 1;
}

.recordWebcamWindow--preview[data-media-type='audio']
.recordWebcamWindow--preview-controls-seekBar-seekBar-track-1{
  background: black;
  opacity: 0.1;
}

.recordWebcamWindow--preview-preControls-duration{
  font-size: 15px;

  font-variation-settings: "wght" 520;

  letter-spacing: -0.008em;
  font-feature-settings: "tnum";

  color: var(--text600);

  color: white;
  position: relative;
  left: -2px;
}


.recordWebcamWindow--preview-controls{
  position: absolute;
  bottom: $inset-videoPreviewPlayerControlsInset;
  left: $inset-videoPreviewPlayerControlsInset;
  right: $inset-videoPreviewPlayerControlsInset;

  display: flex;
  align-items: center;

  padding-left: 8px;
  padding-right: 10px;
  border-radius: 4px;

  background: rgba(0,0,0,0.65);
  backdrop-filter: blur(25px);

  height: $height-videoPreviewPlayerControls;
}

.videoPlayer-controls-playPauseBtn{
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 4px;
}
