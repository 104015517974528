
.toastContainer{
  position: fixed;
  z-index: 9999999;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);

  padding-inline-start: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.toast{
  padding-left: 12px;
  padding-right: 12px;
  //padding-left: 13px;
  //padding-right: 13px;

  display: flex;
  align-items: center;

  height: 26.5px;
  //height: 28px;
  border-radius: 4px;
  background: rgb(30,32,36);
  
  // border: 1px solid rgb(0,0,0);
  border: 1px solid var(--line150);

  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);  

  user-select: none;
}

.toast--editor{  
  box-shadow: -20px 0px 20px 0px var(--bg-editorTimeline), 20px 0px 20px 0px var(--bg-editorTimeline);
}

//
// Animation

@keyframes toastDefaultFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.85);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes toastWiggle {
  0% {
    transform: scale(1) translateX(0px);    
  }
  30% {
    transform: scale(1) translateX(0px);    
  }
  40% {
    transform: scale(1) translateX(0px);    
  }
  45%{
    transform: scale(1.008) translateX(-2px);
  }
  50% {
    transform: scale(1.008) translateX(2px);    
  }
  55% {
    transform: scale(1.008) translateX(-2px);    
  }
  60% {
    transform: scale(1) translateX(0px);    
  }
  100% {
    transform: scale(1) translateX(0px);    
  } 
}

@keyframes toastDefaultFadeOut {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(5px);
  }
}




//
// Animation

@keyframes toastDefaultFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.85);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes toastWiggle {
  0% {
    transform: scale(1) translateX(0px);    
  }
  30% {
    transform: scale(1) translateX(0px);    
  }
  40% {
    transform: scale(1) translateX(0px);    
  }
  45%{
    transform: scale(1.008) translateX(-2px);
  }
  50% {
    transform: scale(1.008) translateX(2px);    
  }
  55% {
    transform: scale(1.008) translateX(-2px);    
  }
  60% {
    transform: scale(1) translateX(0px);    
  }
  100% {
    transform: scale(1) translateX(0px);    
  } 
}

@keyframes toastDefaultFadeOut {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(5px);
  }
}



.toast.toast--enterAnimationType--defaultFadeIn[data-state='open']{
  animation: toastDefaultFadeIn 120ms cubic-bezier(.64,.14,.22,1.46);
}

.toast.toast--exitAnimationType--defaultFadeOut[data-state='closed']{
  animation: toastDefaultFadeOut 300ms ease-in;
}

// Wiggle example
// .toast.toast--type--pingCursorAlert[data-state='open'],
// .toast.toast--type--pingCursorConfirmation[data-state='open']{
//   animation: 
//     toastDefaultFadeIn 120ms cubic-bezier(.64,.14,.22,1.46),
//     toastWiggle 2500ms linear 75ms forwards normal 5;
// }


//

.toast-emojiContainer{
  font-family: "Apple Color Emoji";
  margin-right: 6px;
  margin-left: -3px;

  font-size: 14px;
  position: relative;
  top: 0.5px;
}

.toast-iconContainer{
  width: 20px;
  height: 20px;  

  margin-right: 2px;
  margin-left: -6px;  

  // background: $tempr;

  display: flex;
  align-items: center;
  justify-content: center;
}

.toast-iconContainer svg{
  fill: lighten(#516CE8, 5%);
  width: 16px;
  height: 16px;
  position: relative;
  top: -1px;
}

.toast-iconContainer--iconColor--red svg{
  fill: #FD6E6E;
}

//

.toast-label,
.toast-collectionMarker-label{
  font-size: 13.75px;
  //font-size: 14.5px;
  font-weight: $fw-medium;
  color: var(--text250);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;

  position: relative;
  top: -0.5px;
}

span.toast-label-emph,
.toast-collectionMarker-label{
  font-variation-settings: "wght" 520;
  color: var(--text500);
}

span.toast-label-white{  
  color: var(--text500);
}

.toast-smallHSpacer{
  width: 0.25em;
}

.toast-collectionMarker{
  display: flex;
}

.toast-collectionMarker-color{
  width: 13px;
  height: 13px;
  background: red;
  border-radius: 2px;
  margin-right: 3px;
  margin-left: 0.45em;
  position: relative;
  top: 1.5px;
}

.toast-collectionMarker-color--blue{
  background: var(--collection-blue);
}

.toast-collectionMarker-color--purple{
  background: var(--collection-purple);
}

.toast-collectionMarker-color--red{
  background: var(--collection-red);
}

.toast-collectionMarker-color--orange{
  background: var(--collection-orange);
}

.toast-collectionMarker-color--green{
  background: var(--collection-green);
}

.toast-collectionMarker-color--pink{
  background: var(--collection-pink);
}

.toast-collectionMarker-color--teal{
  background: var(--collection-teal);
}

.toast-collectionMarker-color--darkBlue{
  background: var(--collection-darkBlue);
}

//
// Editor
// needs to stand out

.toast--editor{  
  box-shadow: -20px 0px 20px 0px var(--bg-editorTimeline), 20px 0px 20px 0px var(--bg-editorTimeline);
  position: relative;
  overflow: hidden;
  border: 1px solid transparentize(rgb(113,148,255), 0.20);
  background: #1f242c;
}

.toast--editor .toast-label span.toast-label-emph{  
  color: #d7e1ff;
}

// .toast--editor-border{
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   right: 0px;
//   bottom: 0px;  
//   border: 1px solid rgb(255,255,255,0.1);
//   border-radius: 4px;
// }

// .toast--editor-bg{
//   position: absolute;
//   top: 1px;
//   left: 1px;
//   right: 1px;
//   bottom: 1px;  
//   border-radius: 3px;
//   z-index: 10;
//   background: rgb(30,32,36);
// }

// @keyframes alphaAnimation { 
//   0%{ 
//     transform: translateX(-200px) rotateZ(-40deg);
//   } 
//   100%{ 
//     transform: translateX(200px) rotateZ(-40deg);
//   } 
// }

// .toast--editor-border::before {
//   background: linear-gradient(170deg, transparent 10%, rgb(113,148,255) 50%, transparent 90%);
//   position: absolute;
//   left: 0px;
//   z-index: 5;
//   top: -18px;
//   width: 80px;
//   height: 52px;
//   opacity: 1;
//   content: "\200B";
//   animation: alphaAnimation 1.5s linear 200ms forwards;
// }

// // Button
// //

// .toast-undoBtn{
  

//   height: calc(100% - 6px);
//   border-radius: 3px;
//   // background: var(--250gray);

//   font-size: 14px;
//   font-weight: $fw-medium;
//   color: var(--text250);

//   font-family: system-ui, sans-serif;
//   font-variation-settings: "wght" 440;
//   letter-spacing: 0.008em;

//   padding-left: 4px;
//   padding-right: 4px;

//   margin-left: 6px;
//   margin-right: -5px;

//   opacity: 0.75;
// }


// button.toast-actionBtn{
//   background: rgba(255,255,255,0.08);
//   height: calc(100% - 4px);
//   border: 1px solid rgba(255,255,255,0.1);

//   border-radius: 4px;
//   margin-right: -8px;

//   margin-left: 12px;

//   padding-left: 6px;
//   padding-right: 6px;
// }

// button.toast-actionBtn:hover{
//   background: rgba(255,255,255,0.15);
// }

// .toast-actionBtn-iconContainer{
//   margin-right: 3px;
//   margin-left: 10px;

//   display: flex;
//   align-items: center;
//   justify-content: center;

// }
// .toast-actionBtn-iconContainer svg{
//   fill: $white;
//   width: 16px;
//   height: 16px;
//   opacity: 0.7;
//   position: relative;
//   top: 0.5px;
// }

// .toast-actionBtn-label{
//   font-size: 14.5px;
//   ////font-weight: $lato-bold;
//   color: $white;
//   letter-spacing: 0.01em;
// }

// .toast-actionBtn-keyboardShortcut{
//   display: flex;
//   align-items: center;
//   height: 18px;

//   padding-left: 3px;
//   padding-right: 3px;

//   margin-left: 8px;

//   position: relative;
//   top: 0.5px;

//   border-radius: 3px;

//   background: rgba(255,255,255,0.05);
// }

// .toast-actionBtn-keyboardShortcut svg{
//   width: 12px;
//   height: 12px;
//   fill: rgba(255,255,255,0.6);
// }

// .toast-actionBtn-keyboardShortcut-span{
//   font-size: 13px;
//   color: rgba(255,255,255,0.6);
//   margin-left: 1px;
//   //font-weight: $lato-bold;
// }

// // Browse Templates Button

// // .toast--type--createdTemplate{
// // }

// // .toast--type--createdTemplate
// // button.toast-actionBtn{
// //   //background: rgba(255,255,255,0.08);
// //   height: 100%;
// //   height: calc(100% - 4px);
// //   border: none;
// //   background: none;
// //   // border: 1px solid rgba(255,255,255,0.1);

// //   border-radius: 4px;
// //   // margin-right: -8px;
// //   // margin-left: 12px;

// //   padding-left: 6px;
// //   padding-right: 6px;
// // // }

// // .toast--type--createdTemplate
// // .toast-actionBtn-label{
// //   //font-weight: $lato-semibold;
// // }


// //


// // .toast-closeBtn{
// //   position: absolute;
// //   top: 10px;
// //   right: 10px;
// //   width: 30px;
// //   height: 30px;

// //   background: red;
// // }

// // .toast-closeBtn svg{
// //   width: 14px;
// //   height: 14px;

// //   fill: white;
// // }
