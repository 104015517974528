

.editor-timeline-sceneGapButtonsContainer{
	position: absolute;
	top: 0px;
	
	bottom: 0px;

	z-index: 100;

	// background: $tempb;
}


.editor-timelineContainer--showGrabbingCursor
.editor-timeline-sceneGapButtonsContainer{
	pointer-events: none;
}


.editor-timeline-sceneGapButtons{
	position: absolute;
	top: 40px;
	left: 0px;
	width: 100%;
	// background: $tempr;
	bottom: 0px;

	z-index: 100;
}


.editor-timeline-endNewSceneBtnContainer{
	position: absolute;
	top: 0px;
	bottom: 0px;
	width: 100px;
	// background: $tempr;

	z-index: 40;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 8px;
	pointer-events: none;
}


.editor-timeline-sceneGapButtons-add,
.editor-timeline-endNewSceneBtn{	
	border-radius: 3px;
	background: rgba(255,255,255,0.03);
}

.editor-timeline-endNewSceneBtn{
	background: lighten(rgb(32,33,34), 4%);
}

.editor-timeline-sceneGapButtons-add{
	position: absolute;
	top: 10px;
	bottom: 10px;

	left: 5px;
	right: 5px;
}

.editor-timeline-endNewSceneBtn{
	width: 24px;
	height: 96px;
	margin-top: 32px;
	pointer-events: auto;
	// background: red;
}

.editor-timeline-sceneGapButtons-add svg,
.editor-timeline-endNewSceneBtn svg{
	width: 22px;
	height: 22px;

	// width: 20px;
	// height: 20px;

	opacity: 0.65;

	fill: var(--text500);

	transition: opacity 50ms linear;
}

.editor-timeline-sceneGapButtons
.editor-timeline-sceneGapButtons-add{
	opacity: 0;
	// transition: opacity 150ms linear;
	transition-delay: 50ms;
}

// .editor-timeline-sceneGapButtons:hover
// .editor-timeline-sceneGapButtons-add{
// 	opacity: 0.5;
// }

.editor-timeline-sceneGapButtons:hover
.editor-timeline-sceneGapButtons-add:hover{
	opacity: 1;	
	// transition: opacity 50ms linear;
	// transition-delay: 200ms;
}

.editor-timeline-sceneGapButtons-add--hide{
	opacity: 0 !important;
	transition-delay: 50ms;
}

.editor-timeline-sceneGapButtons-mergeHandle{
	position: absolute;
	right: 0px;
	height: 100%;
	width: 2px;
	background: rgba(255,255,255,0.25);	
	border-radius: 2px;
}

.editor-timeline-sceneGapButtons-mergeHandle{
	opacity: 0;
	transition: opacity 50ms linear;
}

.editor-timeline-sceneGapButtons-mergeHandle:hover,
.editor-timeline-sceneGapButtons--isDragging
.editor-timeline-sceneGapButtons-mergeHandle{
	opacity: 1;
	transition-delay: 50ms;
}

.editor-timeline-sceneGapButtons-mergeHandle-inner{
	position: absolute;
	left: 0px;
	top: 0px;
	width: 3px;
	height: 100%;
	background: white;
	opacity: 0.65;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.editor-timeline-sceneGapButtons-mergeHandle--dragging{
	cursor: grabbing;
}

.editor-timeline-sceneGapButtons-mergeHandleIconContainer{
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	pointer-events: none !important;
}

.editor-timeline-sceneGapButtons-mergeHandleIconContainer svg{
	width: 20px;
	height: 20px;
	fill: white;
	transform: scale(0.9);

	opacity: 0;
	transition: opacity 50ms linear;
}

.editor-timeline-sceneGapButtons-mergeHandle:hover + .editor-timeline-sceneGapButtons-mergeHandleIconContainer svg,
.editor-timeline-sceneGapButtons--isDragging
.editor-timeline-sceneGapButtons-mergeHandle + .editor-timeline-sceneGapButtons-mergeHandleIconContainer svg{
	opacity: 1;
	transition-delay: 50ms;
}

.editor-timeline-sceneGapButtons-mergeHandleTooltipContainer{
	position: absolute;
	left: 100%;
	margin-left: 0px;

	top: 0px;
	bottom: 2px;

	pointer-events: none;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity 100ms linear;
  opacity: 0;
}

.editor-timeline-sceneGapButtons-mergeHandleTooltipContainer--show{
	opacity: 1;
	transition-delay: 500ms;
}

.editor-timeline-sceneGapButtons-mergeHandleTooltipContainer--hide{
	opacity: 0;
}

.editor-timeline-sceneGapButtons-mergeHandleTooltip{
	display: flex;
  align-items: center;
  justify-content: flex-start;

  
  height: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  width: 140px;

  text-align: left;

  // border-radius: 4px;
  // box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.24);
  
  font-size: 13px;
  background: rgb(36,36,38);
  // border: 1px solid var(--line100);
  // border-top: 1px solid var(--line100);
  
  padding-left: 10px;

  color: var(--text500);
  font-size: 11.5px;

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 460;
  //font-variation-settings: "wght" 410;
  letter-spacing: 0.008em;
  //text-transform: uppercase;
  //letter-spacing: 0.025em;
  // opacity: 0.65;
  // margin-top: 6px;
  // margin-bottom: 6px;
  // padding-left: 6px;
  // padding-right: 6px;

  box-sizing: border-box;
  user-select: none;
  cursor: default;

  white-space: normal;
  

  background: linear-gradient(to right, rgba(27,28,29,0.85) 0%, rgba(27,28,29,0.85) 30%, rgba(27,28,29,0.75) 45%, transparent 100%);
}

.editor-timeline-sceneGapButtons-skipBtnContainer{
	position: absolute;
	left: 0px;
	right: 0px;
	top: -40px;
	bottom: -10px;

	display: flex;
}

.editor-timeline-sceneGapButtons-skipBtn{
	width: 50%;
	flex-grow: 0;
	flex-shrink: 0%;
	height: 100%;
	// background: $tempr;
	// outline: 1px solid red;
}