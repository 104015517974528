.editor-canvasMediaUI{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	// background: $tempr;
	z-index: 50;
}

.editor-canvasMediaUI--uploading-spinnerContainer{
	width: 400px;
	height: 400px;
	pointer-events: none;

	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-canvasMediaUI--uploading-spinnerContainer svg{
	width: 400px;
	height: 400px;

	fill: white;
  animation: AnimationName 2s ease infinite;
  // opacity: 0.8 !important;
  // fill: red;
}

.editor-canvasMediaUI--darkBG
.editor-canvasMediaUI--uploading-spinnerContainer svg{
	fill: white;
}

.editor-canvasMediaUI--lightBG
.editor-canvasMediaUI--uploading-spinnerContainer svg{
	fill: rgba(20,20,30, 0.5);
}

@keyframes AnimationName { 
    0%{opacity: 0.2; transform: scale(1)}
    50%{opacity: 0.35; transform: scale(1.01)}
    100%{opacity: 0.2; transform: scale(1)}    
}

//
//

.editor-canvasMediaUI--resize{
	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-canvasMediaUI--resize[data-state="dragging"],
.editor-canvasMediaUI--resize[data-state="dragging"] *{
	// background: blue;
	cursor: grabbing !important;
}

.editor-canvasMediaUI--resize[data-state="notDragging"]{
	// background: red;
}

.editor-canvasMediaUI--resize-guide{
	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempr;
}

.editor-canvasMediaUI--resize-resizeBoxContainer{
	position: relative;
	// background: $tempb;
}

.editor-canvasMediaUI--resize-resizeBox{
	// background: $tempr;
	opacity: 0;

}

.editor-canvasMediaUI--resize-resizeBoxHandleContainer{
	position: absolute;
	// background: $tempr;
	pointer-events: none;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;

}

.editor-canvasMediaUI
.editor-canvasMediaUI--resize-resizeBoxHandle{
	opacity: 0;
	// background: rgb(0,0,10);
	// background: rgba(228,228,238);
	
	// outline: 1px solid rgba(0,0,0,0.04);
	transition: opacity 350ms linear;
	//transition-delay: 200ms;
}


.editor-canvasMediaUI--darkBG
.editor-canvasMediaUI--resize-resizeBoxHandle{
	background: white;
}

.editor-canvasMediaUI--lightBG
.editor-canvasMediaUI--resize-resizeBoxHandle{
	background: rgba(0,0,5,0.35);
}



.editor-canvasMediaUI:hover
.editor-canvasMediaUI--resize-resizeBoxContainer:hover
.editor-canvasMediaUI--resize-resizeBoxHandle{	
	opacity: 0.5;
	transition: opacity 100ms linear;
}

.editor-canvasMediaUI[data-state='dragging']
.editor-canvasMediaUI--resize-resizeBoxContainer
.editor-canvasMediaUI--resize-resizeBoxHandle{
	opacity: 0.05 !important;
	transition: opacity 50ms linear;
}

// .editor-canvasMediaUI--resize[data-state="dragging"]
// .editor-canvasMediaUI--resize-resizeBoxHandle{	
// 	opacity: 0;
// 	transition: all 50ms linear;
// }

$handleOffset: 4px;

.editor-canvasMediaUI--resize-resizeBoxHandleContainer--side--top
.editor-canvasMediaUI--resize-resizeBoxHandle,
.editor-canvasMediaUI--resize-resizeBoxHandleContainer--side--bottom
.editor-canvasMediaUI--resize-resizeBoxHandle{
	height: 8px;
	width: 100%;
	max-width: 100px;
	border-radius: 2px;	
	min-width: 30px;
}

.editor-canvasMediaUI--resize-resizeBoxHandleContainer--side--bottom
.editor-canvasMediaUI--resize-resizeBoxHandle{
	margin-top: $handleOffset;
}

.editor-canvasMediaUI--resize-resizeBoxHandleContainer--side--top
.editor-canvasMediaUI--resize-resizeBoxHandle{
	margin-bottom: $handleOffset;
}

.editor-canvasMediaUI--resize-resizeBoxHandleContainer--side--left
.editor-canvasMediaUI--resize-resizeBoxHandle,
.editor-canvasMediaUI--resize-resizeBoxHandleContainer--side--right
.editor-canvasMediaUI--resize-resizeBoxHandle{
	width: 8px;
	height: 100%;
	max-height: 100px;
	border-radius: 2px;	
	min-height: 30px;
}

.editor-canvasMediaUI--resize-resizeBoxHandleContainer--side--left
.editor-canvasMediaUI--resize-resizeBoxHandle{
	margin-right: $handleOffset;
}

.editor-canvasMediaUI--resize-resizeBoxHandleContainer--side--right
.editor-canvasMediaUI--resize-resizeBoxHandle{
	margin-left: $handleOffset;
}




// .editor-canvasMediaUI--add-carouselContainer{
// 	position: absolute;
// 	bottom: 0px;
// 	left: 0px;
// 	width: 100%;
	
// 	// background: $tempr;

// 	padding-bottom: 12px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .editor-canvasMediaUI--add-carousel{
// 	background: rgba(0,0,0,0.15);

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	// column-gap: 10px;

// 	padding-left: 12px;
// 	padding-right: 12px;
// 	padding-top: 10px;
// 	padding-bottom: 10px;

// 	border-radius: 14px;
// }


// .editor-canvasMediaUI--add-carousel-item{
// 	background: rgba(0,0,0,0.15);
// 	border-radius: 8px;
// 	cursor: pointer;
// }

// .editor-canvasMediaUI--add-carousel-item--upload{
// 	background: rgba(0,0,0,0.10);
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .editor-canvasMediaUI--add-carousel-item--upload:hover{
// 	background: rgba(0,0,0,0.25);
// }

// .editor-canvasMediaUI--add-carousel-item--upload svg{
// 	width: 36px;
// 	height: 36px;
// 	fill: white;
// 	opacity: 0.8;
// }

// .editor-canvasMediaUI--add-carousel-item--upload:hover svg{
// 	opacity: 1;	
// }

// .editor-canvasMediaUI--add-carousel-item--upload 
// svg.icon--name--laptopUpload #laptop{	
// 	opacity: 0.5;	
// }

// .editor-canvasMediaUI--add-carousel-item--upload:hover
// svg.icon--name--laptopUpload #laptop{
// 	opacity: 0.75;
// }


// .editor-canvasMediaUI--add-carousel-item--upload 
// svg.icon--name--laptopUpload #arrow{
// 	opacity: 1;
// }