
//

.editor-timelineFooter{
  // position: absolute;
  // bottom: 10px;
  // left: 0px;

  width: 100%;
  height: $height-editorTimelineFooter;
  //background: $tempr;
  // background: rgba(255,255,255,0.1);

  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  align-items: center;

  box-sizing: border-box;
  padding-left: 8px;
  padding-right: 8px;


  //background: $tempr;

  margin-bottom: 1px;

  position: relative;
  z-index: 50;
}

.editor-timelineFooter-centerContainer{
  opacity: 1;  
  transition: opacity 150ms linear;
}

.editor-timelineFooter[data-read-only='true'] .editor-timelineFooter-centerContainer{
  opacity: 0.15;  
}

.editor-timelineFooter[data-read-only='true'] .editor-timelineFooter-centerContainer,
.editor-timelineFooter[data-read-only='true'] .editor-timelineFooter-centerContainer *{
  pointer-events: none !important;
}


.editor-timelineFooter[data-promote-zindex-state='true']{
  z-index: 500;
}


.editor-timelineFooter-leftContainer,
.editor-timelineFooter-rightContainer{
  width: 340px;

  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 100ms linear;
}

.editor-timelineFooter:hover
.editor-timelineFooter-leftContainer,
.editor-timelineFooter:hover
.editor-timelineFooter-rightContainer{
  opacity: 1;
  transition-delay: 100ms;
}


.editor-timelineFooter[data-pro-mode='true']
.editor-timelineFooter-leftContainer,
.editor-timelineFooter[data-pro-mode='true']
.editor-timelineFooter-rightContainer{
  opacity: 1;
}

.editor-timelineFooter-rightContainer{
  justify-content: flex-end;
}

.editor-timelineFooter-centerContainer{
  min-width: 10px;
  flex-grow: 1;
  flex-shrink: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 1;
  transition: opacity 200ms linear;

  position: relative;
}

.editor-timelineFooter--isEmpty .editor-timelineFooter-rightContainer{
  opacity: 0.25;
  // pointer-events: none;
}


.editor-timelineFooter--isEmpty .editor-timelineFooter-centerContainer .uiButton--addMusic{
  opacity: 0.25;
}

.editor-timelineFooter--isEmpty .editor-timelineFooter-centerContainer .uiButton--addMusic:hover{
  opacity: 1;
}

.editor-timelineFooter-addMusicBtnContainer{
  position: relative;
  z-index: 10;
}

//

.editor-timelineFooter-skipSegmentTooltipContainer{
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;
}

.editor-timelineFooter-skipSegmentTooltip{
  font-size: 13.65px;
  font-weight: $fw-medium;
  color: var(--text250);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 500;
  letter-spacing: 0.008em;
}

.editor-timelineFooter-skipSegmentTooltip-inner{
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;


}

.editor-timelineFooter-skipSegmentTooltip-inner-emph{
  font-variation-settings: "wght" 540;
  color: var(--text400);
  margin-left: 0.3em;
  margin-right: 0.3em;
}

.editor-timelineFooter-skipSegmentTooltip-inner-spacer{
  width: 20px;
}

.editor-timelineFooter-skipSegmentTooltip-inner-iconContainer{
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-timelineFooter-skipSegmentTooltip-inner-iconContainer svg{
  width: 16px;
  height: 16px;
  fill: white;
}

.editor-timelineFooter-skipSegmentTooltip-inner[data-visibility-state='visible']{
  opacity: 1;
  transition: opacity 75ms linear;
  transition-delay: 200ms;
}

.editor-timelineFooter-skipSegmentTooltip-inner[data-visibility-state='hidden']{
  opacity: 0;
  transition: opacity 150ms linear;
  transition-delay: 0ms;
}

//
.editor-timelineFooter-proShortcut{
  font-size: 12.25px;
  font-weight: $fw-regular;
  color: var(--text600);
  font-variation-settings: "wght" 410;
  letter-spacing: 0.01em;

  padding-left: 6px;
  padding-right: 6px;
  height: 24px;

  border-radius: 2px;

  opacity: 0.6;
  margin-top: 11px;

  user-select: none;
  cursor: default;
}

.editor-timelineFooter-proShortcut:hover{
  opacity: 1;
}


.editor-timelineFooter-supportBtn{
  font-size: 13.5px;
  font-weight: $fw-medium;
  color: var(--text600);

  padding-left: 8px;
  padding-right: 8px;
  height: 26px;

  border-radius: 2px;

  opacity: 0.6;

  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-timelineFooter-supportBtn:hover,
.editor-timelineFooter-supportBtn[data-state='active']{
  background: var(--250gray);
  border-radius: 4px;
  opacity: 1;
}

.editor-timelineFooter-supportBtn--updates--hasUnreads{
  opacity: 1; 

}

.editor-timelineFooter-supportBtn--updates-unreadIndicator{
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #72C899;
  margin-right: 5px;
}

//
.editor-timelineFooter-hSpacer{
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
}

//
// TIMELINE ZOOM

.editor-timelineFooter-zoomSliderContainer{
  
  margin-right: 6px;  
  
  display: flex;
  position: relative;
  top: 1px;
}


.editor-timelineFooter-zoomSliderLabel{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  z-index: 20;
  
  font-size: 14px;
  font-weight: $fw-medium;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;

  pointer-events: none;

  margin-top: -9px;
  margin-left: 7px;
  
  opacity: 0.75;
}

.editor-timelineFooter-zoomSliderContainer:hover
.editor-timelineFooter-zoomSliderLabel{
  opacity: 1;
}


.editor-timelineFooter-zoomSliderInnerContainer{
  // width: 124px;
  width: 146px;
  position: relative;
  top: -13px;
  //cursor: pointer;
  cursor: ew-resize;

}

.editor-timelineFooter-zoomSlider{
  height: 100%;
  margin-top: -1px;
  // background: $green;
}

.editor-timelineFooter-zoomSlider-track{
  height: 26px;
  // margin-top: 7px;
}

.editor-timelineFooter-zoomSlider-track-0{
  background: var(--timelineZoomSlider-frontTrackBG);
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.editor-timelineFooter-zoomSlider-track-1{ 
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  
  background: var(--timelineZoomSlider-backTrackBG);
}

.editor-timelineFooter-zoomSliderContainer:hover
.editor-timelineFooter-zoomSlider-track-0{
  background: var(--timelineZoomSlider-frontTrackBGHover);
}

.editor-timelineFooter-zoomSliderContainer:hover
.editor-timelineFooter-zoomSlider-track-1{ 

  background: var(--timelineZoomSlider-backTrackBGHover);
}


.editor-timelineFooter-zoomSlider-thumb,
.editor-timelineFooter-zoomSlider-thumb:focus{
  cursor: pointer;

  width: 1px;
  height: 22px;
  background: var(--text400);
  border-radius: 0px;

  opacity: 0;
  
  margin-top: 0px;

  z-index: 10;
  outline: none;

  display: none;
  // border: 1px solid var(--text400);
  // outline: 1px solid rgba(255,255,255,0.8);
  
}

.editor-timelineFooter-zoomSlider:hover 
.editor-timelineFooter-zoomSlider-thumb{
  opacity: 0.5;
}

//


.editor-timelineFooter-footerBtn{
  padding-left: 8px;
  padding-right: 8px;

  opacity: 0.75;

  border-radius: 3px;

  height: 28px;

  cursor: pointer;
  border: 1px solid $invisible;

  position: relative;
}

.editor-timelineFooter-footerBtn:hover{
  background: var(--251gray);
  opacity: 1;
}

.editor-timelineFooter-footerBtn--updates-unreadIndicator{
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #72C899;
  margin-right: 5px;
  margin-left: -1px;
}

.editor-timelineFooter-footerBtn-iconContainer{
  width: 20px;
  height: 20px;

  margin-right: 2px;
  margin-left: -4px;

  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-grow: 0;
  flex-shrink: 0;
}

.editor-timelineFooter-footerBtn svg{
  width: 16px;
  height: 16px;
  fill: var(--text500);
  opacity: 0.5;

  transform-origin: center center;
}

.editor-timelineFooter-footerBtn--shortcuts
.editor-timelineFooter-footerBtn-iconContainer{
  margin-right: 4px;
}

.editor-timelineFooter-footerBtn-label{
  font-size: 14px;
  font-weight: $fw-medium;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;
}

//

@import 'EditorTimeline-Footer-MusicVolume';
