.exportPlayer{
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	background: var(--bg-editor);

	//-webkit-app-region: drag;
	
	// background: $tempr;
}

.exportPlayer-preview{
	
	

	width: 1280px;
	height: 800px;
	
	//position: relative;

	display: flex;
}

.exportPlayer-preview--prod
.editor-center-center-canvasOuterOuterContainer-guide{
	position: absolute;
	left: -99999999px;
	bottom: -9999999px;
}

.exportPlayer-timeline{
	position: fixed;
	left: 20px;
	bottom: 20px;
	right: 20px;
	width: calc(100% - 40px);

	height: 80px;

	background: var(--bg-editorTimeline);
	border-radius: 4px;

	z-index: 500;
}



.exportPlayer-exportStatus{
	position: fixed;
	left: 300px;
	top: 20px;
	width: 200px;

	height: 80px;

	background: var(--bg-editorTimeline);
	border-radius: 4px;

	z-index: 50;
	color: white;
	

}




.exportPlayer-timeline-controls{
	display: flex;
	color: white;
}

.exportPlayer-timeline-controls button{
	color: white;
	margin-right: 10px;
	background: $tempr;
	height: 40px;
}

.exportPlayer-timeline-controls-timestamp{
	margin-right: 20px;
}

.exportPlayer-exportBtn{
	position: absolute;
	top: 50%;
	left: 50%;

	transform: translateX(-50%) translateY(-50%);

	width: 180px;
	height: 40px;

	background: lighten(rgb(28,30,34), 5%);
	border-radius: 4px;

	border: 1px solid var(--line100);

	color: var(--text500);
	font-weight: $fw-medium;
	font-size: 15px;

	-webkit-app-region: none;
}

.exportPlayer-exportBtn:hover{
	opacity: 1;
	background: lighten(rgb(28,30,34), 8%);
}

.exportPlayer-formatToggleContainer{
	position: absolute;
	top: 12px;
	right: 12px;

	-webkit-app-region: none;

	//transform: translateX(-50%) translateY(-50%);

	//margin-top: 64px;

	width: 80px;

	opacity: 0.65;


	// background: lighten(rgb(28,30,34), 5%);
	// border-radius: 4px;

	// border: 1px solid var(--line100);

	// color: var(--text500);
	// font-weight: $fw-medium;
	// font-size: 15px;
}

.exportPlayer-formatToggleContainer:hover{
	opacity: 1;
}

.exportPlayer-formatToggleContainer .uiToggleButton {
	height: 24px !important;
}

.exportPlayer-formatToggleContainer .uiToggleButton-label{
	font-size: 13px !important;
}

.exportPlayer-UIContainer{
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}

.exportPlayer-UIContainer-progressBarOuterContainer{
	position: absolute;
	width: 100%;
	height: 100%;
	pointer-events: none;

	display: flex;
	align-items: center;
	justify-content: center;
}


.exportPlayer-UIContainer-progressBarInnerContainer{
	width: 300px;
	height: 300px;
}

.exportPlayer-UIContainer-progressBarInnerContainer .CircularProgressbar {
	width: 300px;
	height: 300px;
}

.exportPlayer-UIContainer-progressBarInnerContainer .CircularProgressbar .CircularProgressbar-path {
	
	stroke-linecap: butt;
	transition: stroke 200ms linear;
}

.exportPlayer-UIContainer--state--pre
.CircularProgressbar .CircularProgressbar-path {	
	stroke: transparentize(#516CE8, 1);
}

.exportPlayer-UIContainer--state--active
.CircularProgressbar .CircularProgressbar-path {	
	stroke: transparentize(#516CE8,0.1);
}

.exportPlayer-UIContainer--state--complete
.CircularProgressbar .CircularProgressbar-path {	
	stroke: transparentize(#72C899,0.1);
}


.exportPlayer-UIContainer-progressBarInnerContainer .CircularProgressbar .CircularProgressbar-trail {  
  
  stroke-linecap: butt;

  transition: stroke 200ms linear;
}


.exportPlayer-UIContainer--state--pre
.CircularProgressbar .CircularProgressbar-trail{
	stroke: lighten(rgb(28,30,34), 2%);
}

.exportPlayer-UIContainer--state--active
.CircularProgressbar .CircularProgressbar-trail {	
	stroke: transparentize(#516CE8, 0.8);
}

.exportPlayer-UIContainer--state--complete
.CircularProgressbar .CircularProgressbar-trail {	
	stroke: $invisible;
}

.exportPlayer-percentage-value{
	font-size: 56px;
	font-feature-settings: 'tnum', 'mono';
	font-weight: $fw-semibold;
	color: var(--text500);

	position: relative;
	top: -1px;

	user-select: none;
}

.exportPlayer-percentage-value span{
	opacity: 0.5;
	margin-left: 0.01em;
}







