

.addFromLibraryDialog-suggested{
	height: 300px;
	width: 100%;
	background: $tempr;
}

.addFromLibraryDialog-header{
	
	flex-grow: 0;
	flex-shrink: 0;

	padding-top: 6px;
	padding-bottom: 8px;
	padding-left: 9px;
	padding-right: 9px;

	padding-top: 8px;
	padding-left: 24px;
	padding-right: 18px;

	// padding-top: 10px;
	// padding-left: 32px;
	// padding-right: 32px;

	box-sizing: border-box;
	width: 100%;

	display: flex;
	flex-direction: column;
}

// .addFromLibraryDialog-header--scrolled{
// 	padding-left: calc(100vw - 100px - 100px - 540px);
// 	padding-right: 230px;
// }

//


//

.addFromLibraryDialog-header-tabBar{
	display: flex;
	color: white;

	
	height: 28px;
	margin-left: -5px;
}

.addFromLibraryDialog-header-tabBar-tab{
	color: white;
	// padding-left: 5px;
	// padding-right: 10px;
	padding-left: 8px;
	padding-right: 8px;
	border-radius: 3px;
	position: relative;
}

.addFromLibraryDialog-header-tabBar-tab:hover{
	background: var(--250gray);
}

.addFromLibraryDialog-header-tabBar-tab[data-active-state='active']{
	opacity: 1;
	//background: var(--251gray);
}

.addFromLibraryDialog-header-tabBar-tab[data-active-state='active']:hover{
	opacity: 1;
}

.addFromLibraryDialog-header-tabBar-tab[data-active-state='inactive']{
	opacity: 0.5;
}

.addFromLibraryDialog-header-tabBar-tab[data-active-state='inactive']:hover{
	opacity: 1;
	//background: var(--252gray);
}

.addFromLibraryDialog-header-tabBar-tab-iconContainer{
	width: 28px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.addFromLibraryDialog-header-tabBar-tab-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: white;
	opacity: 0.75;
}

.addFromLibraryDialog-header-tabBar-tab-iconContainer svg.icon--name--addFromLibrary{
	position: relative;
	top: 1px;
	transform: scale(0.85);
	transform-origin: center center;
}

.addFromLibraryDialog-header-tabBar-tab-label{
	font-size: 14px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;
}


.addFromLibraryDialog-header-tabBar-tab-bottomBorder{
  position: absolute;
  bottom: 0px;
  left: 4px;
  right: 4px;
  height: 1.5px;
  background: var(--primary500);
  opacity: 0;

}

.addFromLibraryDialog-header-tabBar-tab[data-active-state="active"]
.addFromLibraryDialog-header-tabBar-tab-bottomBorder{  
  opacity: 1;
}


//

// .addFromLibraryDialog-header-hSpacer{
// 	flex-grow: 1;
// 	flex-shrink: 1;
// }

// .addFromLibraryDialog-header-searchInputContainer{
// 	width: 180px;
// 	height: 30px;	
// 	position: relative;
// 	background: $tempr;
// }

// input.addFromLibraryDialog-header-searchInput{
	
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	width: 100%;
// 	height: 100%;

// 	box-sizing: border-box;
// 	padding-left: 28px;

	
//   background: var(--252gray);
// }

// input.addFromLibraryDialog-header-searchInput:hover{
// 	background: var(--250gray);
// }

// input.addFromLibraryDialog-header-searchInput:focus{
// 	background: var(--248gray);
// 	outline: none;
// }

// input.addFromLibraryDialog-header-searchInput::placeholder{
// 	color: rgba(255,255,255,0.4);
// }

// .addFromLibraryDialog-header-leftSearchUI{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	height: 100%;
// 	width: 28px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: flex-end;

// 	z-index: 5;
// 	pointer-events: none;
// }

// .addFromLibraryDialog-header-leftSearchUI-iconContainer{
// 	width: 24px;
// 	height: 24px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .addFromLibraryDialog-header-leftSearchUI-iconContainer svg{
// 	width: 16px;
// 	height: 16px;
// 	fill: white;
// 	opacity: 0.5;
// 	transform: scale(1.04);
// }

// input.addFromLibraryDialog-header-searchInput:hover + 
// .addFromLibraryDialog-header-leftSearchUI
// .addFromLibraryDialog-header-leftSearchUI-iconContainer svg{
// 	opacity: 0.7;
// }

// input.addFromLibraryDialog-header-searchInput:focus + 
// .addFromLibraryDialog-header-leftSearchUI
// .addFromLibraryDialog-header-leftSearchUI-iconContainer svg{
// 	opacity: 0.8;
// }

// .addFromLibraryDialog-header-rightSearchUI{
// 	position: absolute;
// 	top: 0px;
// 	height: 100%;
// 	right: 0px;
// 	z-index: 10;

// 	display: flex;
// 	padding-right: 3px;
// 	// background: $tempb;
// }

// .addFromLibraryDialog-header-rightSearchUI-prefillSearchBtn{
// 	height: calc(100% - 2px);
// 	// margin-left: -1px;
// 	// background: $tempr;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	box-sizing: border-box;
// 	padding-right: 2px;
// 	padding-left: 2px;
// 	padding-top: 3px;
// 	padding-bottom: 1px;

// 	transition: all 50ms linear;
// }

// .addFromLibraryDialog-header-searchInputContainer--empty
// .addFromLibraryDialog-header-rightSearchUI-prefillSearchBtn{
// 	opacity: 1;
// 	transform: translateX(1px);
// }

// .addFromLibraryDialog-header-searchInputContainer--notEmpty
// .addFromLibraryDialog-header-rightSearchUI-prefillSearchBtn{
// 	opacity: 0;
// 	transform: translateX(0px);
// }

// .addFromLibraryDialog-header-rightSearchUI-prefillSearchBtn-inner{
// 	width: 100%;
// 	height: 100%;

// 	padding-left: 9px;
// 	padding-right: 9px;

// 	border-radius: 3px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;


//   background: var(--251gray);
// }

// .addFromLibraryDialog-header-rightSearchUI-prefillSearchBtn-inner-label{
// 	font-size: 13.5px;
// 	font-weight: $fw-medium;
// 	color: var(--text500);

// 	font-family: system-ui, sans-serif;
//   font-variation-settings: "wght" 440;
//   letter-spacing: 0.008em;

//   opacity: 0.6;
// }

// .addFromLibraryDialog-header-rightSearchUI-prefillSearchBtn-inner:hover{
// 	background: var(--250gray);
// }

// .addFromLibraryDialog-header:hover
// .addFromLibraryDialog-header-rightSearchUI-prefillSearchBtn:hover
// .addFromLibraryDialog-header-rightSearchUI-prefillSearchBtn-inner-label{
// 	opacity: 1;
// }


// //

// .addFromLibraryDialog-header-rightSearchUI-searchBtn{
	
// 	height: calc(100% - 2px);
// 	margin-left: -1px;
// 	// background: $tempr;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	box-sizing: border-box;
// 	padding-right: 3px;
// 	padding-top: 3px;
// 	padding-bottom: 1px;

// 	transition: all 50ms linear;
// }

// .addFromLibraryDialog-header-searchInputContainer--empty
// .addFromLibraryDialog-header-rightSearchUI-searchBtn{
// 	opacity: 0;
// 	transform: translateX(1px);
// }

// .addFromLibraryDialog-header-searchInputContainer--notEmpty
// .addFromLibraryDialog-header-rightSearchUI-searchBtn{
// 	opacity: 1;
// 	transform: translateX(0px);
// }

// .addFromLibraryDialog-header-rightSearchUI-searchBtn-inner{
// 	width: 100%;
// 	height: 100%;

// 	padding-left: 12px;
// 	padding-right: 12px;

// 	border-radius: 4px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .addFromLibraryDialog-header-rightSearchUI-searchBtn-inner:hover{
// 	background: var(--250gray);
// }

// .addFromLibraryDialog-header-rightSearchUI-label{	
// 	font-size: 13.5px;
// 	font-weight: $fw-medium;
// 	color: var(--text500);

// 	font-family: system-ui, sans-serif;
//   font-variation-settings: "wght" 440;
//   letter-spacing: 0.008em;

//   margin-right: 4px;
//   opacity: 0.5;
// }

// .addFromLibraryDialog-header-rightSearchUI-iconContainer{
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .addFromLibraryDialog-header-rightSearchUI-iconContainer svg{
// 	width: 16px;
// 	height: 16px;
// 	opacity: 0.5;
// 	fill: var(--text500);
// }

//




.addFromLibraryDialog-header-searchBar{
	
	width: calc(100% + 12px);
	margin-left: -6px;
	padding-top: 10px;
	padding-bottom: 0px;
}

.addFromLibraryDialog-header-searchBar-inputContainer{
	width: 100%;
	height: 100%;

	height: 30px;
	position: relative;
}

.addFromLibraryDialog-header-searchBar-inputContainer-leftSearchUI{
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;

	width: 32px;

	pointer-events: none;
	z-index: 5;

	display: flex;
	align-items: center;
	justify-content: center;

}

.addFromLibraryDialog-header-searchBar-leftSearchUI-iconContainer{
	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.addFromLibraryDialog-header-searchBar-leftSearchUI-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);	
}

.addFromLibraryDialog-header-searchBar[data-search-state='empty']
.addFromLibraryDialog-header-searchBar-leftSearchUI-iconContainer svg{
	opacity: 0.25;
}

.addFromLibraryDialog-header-searchBar[data-search-state='nonEmpty']
.addFromLibraryDialog-header-searchBar-leftSearchUI-iconContainer svg{
	opacity: 0.75;
}


input.addFromLibraryDialog-header-searchBar-input{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	box-sizing: border-box;

	padding-left: 28px;

	background: var(--252gray);
	border: 1px solid $invisible;
	
	border-radius: 4px;
	border: 1px solid $invisible;
	

	font-size: 14px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;

  box-sizing: border-box;
  padding-bottom: 2px;  

}

input.addFromLibraryDialog-header-searchBar-input:hover{
	background: var(--250gray);
}

input.addFromLibraryDialog-header-searchBar-input:focus{
	background: var(--248gray);
	outline: none;
}

input.addFromLibraryDialog-header-searchBar-input::placeholder{
	color: rgba(255,255,255,0.4);
}

