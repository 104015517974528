
//
//

$markerRed: #F12B2B;

.editor-timeline-playheadZone{
  position: absolute;
  top: 0px;
  left: $width-timeline-sidePanel;
  bottom: 0px;
  
  z-index: 50;
  // background: $tempr;
}


.editor-timeline-playheadZone-slider{
  width: 100%;
  height: 100%;
}

.editor-timeline-playheadZone-slider-thumb{
  width: 2px;
  height: 100%;
  // background: red;
}

.editor-timeline-playheadZone-slider-thumb:focus{
  outline: none;
}

.editor-timeline-playheadZone-slider-track{
  display: none;
}

//

.editor-timeline-skipSegmentCursorContainer{
  position: absolute;
  top: 0px;
  left: $width-timeline-sidePanel;
  bottom: 0px;
  
  z-index: 500;
  // background: $tempb;
}

.editor-timeline-skipSegmentTimePreviewContainer{
  position: absolute;
  top: 0px;
  left: $width-timeline-sidePanel;
  bottom: 0px;
  
  z-index: 500;
  pointer-events: none !important;
}

.editor-timeline-addSkipSegmentModeContainer{
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  
  z-index: 500;
  // background: $tempr;
}

.editor-timeline-skipSegment-previewSegment{
  position: absolute;
  top: 109px;
  top: 108px;
  height: 30px;
  height: 32px;
  background: rgba(0,0,0,0.4); 


  z-index: 100;
  pointer-events: none;

  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-timeline-skipSegment-previewSegment[data-track='videoClip']{
  top: 108px;
}

.editor-timeline-skipSegment-previewSegment[data-track='webcamClip']{
  top: 148px;
}


.editor-timeline-skipSegment-previewSegment[data-expanded-state='lengthening']{
  background: rgba(255,255,255,0.15); 
}

.editor-timeline-skipSegment-previewSegment-trimBadge{
  //background: rgba(255,255,255,0.5);
  //background: darken(desaturate(rgba(110,99,217,0.70), 10%), 15%);  
  //background: rgba(0,0,0,0.25);

  border-radius: 2px;
  font-size: 13.5px; 
  color: var(--text500);
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  background: transparentize(#443E88, 0.25);
  opacity: 0;
  
  font-variation-settings: "wght" 550;
  letter-spacing: 0.008em;  

  // padding-left: 5px;
  // padding-right: 5px;
  // height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

// .editor-timeline-skipSegment-previewSegment svg{
//   width: 18px;
//   height: 18px;
//   fill: white;
//   opacity: 0.75;
// }

.editor-timeline-skipSegment-slider{
  width: 100%;
  height: 100%;
}

.editor-timeline-skipSegment-slider-thumb{
  display: none;
}

.editor-timeline-skipSegment-slider-thumb:focus{
  outline: none;
}

.editor-timeline-skipSegment-slider-track{
  display: none;
}

.editor-timeline-skipSegmentCursor{
  position: absolute;  
  top: 0px;

}




.editor-timeline-skipSegment-timePreviewLine{
  position: absolute;
  top: 12px;
  bottom: 15px;
  opacity: 1;
  width: 1px;
  pointer-events: none;
}


.editor-timeline-skipSegment-timePreviewLine--resize{
  z-index: 500; 
}

.editor-timeline-skipSegment-timePreviewLine-dot{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 0px;

  background: white;
}

.editor-timeline-skipSegment-timePreviewLine-iconContainer{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  top: 0px;
}

.editor-timeline-skipSegment-timePreviewLine-iconContainer svg{
  width: 10px;
  height: 10px;
  fill: white;
}

.editor-timeline-skipSegment-timePreviewLine-line{
  position: absolute;
  z-index: 5;
  top: 96px;
  bottom: 41px;
  left: 0px;
  width: 100%;
  background: white;
  // background: red;
  opacity: 0;
}

.editor-timeline-skipSegment-timePreviewLine-bgLine{
  position: absolute;
  top: 17px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: white;
  // background: white;
  opacity: 1;
}


.editor-timeline-skipSegment-timePreviewLine--original
.editor-timeline-skipSegment-timePreviewLine-iconContainer svg{
  // transform: rotateZ(-90deg);
  // position: relative;
  // top: -2px;
  // left: 4px;
  
}

.editor-timeline-skipSegment-timePreviewLine--current
.editor-timeline-skipSegment-timePreviewLine-iconContainer svg{
  // transform: rotateZ(90deg);
  // position: relative;
  // top: -2px;
  // left: -4px;
}

.editor-timeline-skipSegment-timePreviewLine--original,
.editor-timeline-skipSegment-timePreviewLine--current{
  // top: 91px;
  // bottom: 56px;
}

.editor-timeline-skipSegment-timePreviewLine--current
.editor-timeline-skipSegment-timePreviewLine-line{
  background: none;
  //border-right: 1px dashed white;
  border-right: 1px solid white;
  margin-left: -1px;
}

.editor-timeline-skipSegment-timePreviewLine--current
.editor-timeline-skipSegment-timePreviewLine-bgLine{
  background: none;
  //border-right: 1px dashed white;
  border-right: 1px solid white;
  margin-left: -1px;
}


.editor-timeline-skipSegment-timePreviewLine--original{
  display: none;
}


//



.editor-timeline-playhead{
  position: absolute;
  top: 0px;
  height: 100%;
  margin-left: $width-timeline-sidePanel; 
  width: 2px;
  background: blue;
  z-index: 100;
  pointer-events: none !important;
}

.editor-timeline-progressClickZone{
  position: absolute;
  top: 0px;
  left: $width-timeline-sidePanel;
  height: 100%;
  width: calc(100% - #{$width-timeline-sidePanel});

  // background: $tempr;

  z-index: 20;
}

.editor-timeline-playheadMarker{
  position: absolute;
  top: 2px;
  top: 4px;
  // top: 10px;
  left: 0px;
  width: 5px;
  bottom: 24px;
  bottom: 8px;

  margin-left: calc(#{$width-timeline-sidePanel} - 2px);



  z-index: 170;
  transition: opacity 100ms linear;

  pointer-events: none !important;

  // opacity: 0.5;

}



.editor-timeline-playheadMarker[data-previewing-time='true']{
  opacity: 0.15;
}

.editor-timeline-playheadMarker-markerContainer{
  width: 9px;
  width: 18px;
  height: 26px;
  position: absolute;
  top: -2px;
  left: -6px;

  display: flex;
  align-items: center;
  justify-content: center;

  // background: red;
}

.editor-timeline-playheadMarker-marker{
  width: 9px;
  // position: absolute;
  margin-top: 6px;
  margin-left: -3px;

  //transform: scale(1);
  // background: red;
}



.editor-timeline-playheadMarker-marker svg{
  filter: drop-shadow(0px 1px 1px rgb(0 0 0 / 0.2));
}

.editor-timeline-playheadMarker-marker svg #inner{
  fill: $markerRed;
  fill: rgb(250,250,250);
}

.editor-timeline-playheadMarker-marker svg #border{
  fill: rgba(255,255,255,0.5);
}

.editor-timeline-playheadMarker-line{
  position: absolute;
  top: 20px;
  left: 2px;
  width: 1px;
  height: calc(100% - 20px);
  background: $markerRed;
  background: rgb(250,250,250);

  border-radius: 2px;
  pointer-events: none;
}


.editor-timeline-playheadMarker--phantom{
  opacity: 1;
}

.editor-timeline-playheadMarker--phantom
.editor-timeline-playheadMarker-marker svg #inner{
  fill: var(--text500);
}

.editor-timeline-playheadMarker--phantom
.editor-timeline-playheadMarker-marker svg #border{
  fill: var(--bg-editorTimeline);
}

.editor-timeline-playheadMarker--phantom
.editor-timeline-playheadMarker-line{
  background: var(--text500);
  width: 1px;
  // opacity: 0.5;
  display: none;
}

//

.editor-timeline-altModeMarker{
  position: absolute;
  top: 50px;
  left: 0px;
  width: 2px;
  height: 40px;

  z-index: 500;

  background: red;
}