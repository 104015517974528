
.editor-toolbar--minimized{
	background: none;	
	height: 40px;
	height: 40px;
	margin-top: 8px;

	box-shadow: none;

	padding-left: 2px;

}

.editor-minimizedBottomBG{
	width: 100%;
	background: $tempr;
	position: absolute;
	top: 0px;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% - 400px);
	height: calc(100% - 64px);

	border: 1px solid var(--line400);
  border-bottom: 1px solid var(--line500);
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);
  background: var(--bg-transcriptPanel);
  border-radius: 6px;
  border: var(--border-panel);

  // opacity: 0.5;

}

.editor-toolbar--minimized
button.editor-toolbar-playBtn{
	height: 38px;
	background: none;
	background: var(--250gray);
}

.editor-toolbar--minimized
button.editor-toolbar-playBtn svg{
  width: 20px;
  height: 20px;
}

.editor-toolbar--minimized
button.editor-toolbar-previewZoom{
  margin-top: -1px;
}

.editor-toolbar--minimized
button.editor-toolbar-previewZoom svg{
  width: 22px;
  height: 22px;  
  opacity: 0.6;
}



.editor-minTimeline{
	// background: $tempr;
	height: 100%;
	box-sizing: border-box;
	padding-left: 14px;
	padding-right: 14px;


}

.editor-minTimeline-timelineMeasurer{
	width: 100%;
	// height: 10px;
	// background: red;
}

.editor-minTimeline-innerContainer{
	// background: $tempg;
}

//

.editor-minTimeline-progressBar{
	height: 32px;
	width: 100%;
	cursor: pointer;

	position: relative;
	z-index: 20;
	// background: $tempb;
}






.editor-minTimeline-progressBar-slider{
  height: 32px;
  margin-top: 0px;
  // background: $tempb;
}

.editor-minTimeline-progressBar-slider-track{
  height: 5px;
  margin-top: 14px;
}

.editor-minTimeline-progressBar-slider-track-0{
  background: rgba(255,255,255,1);
  background: lighten(#8E82FF, 5%);
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.editor-minTimeline-progressBar-slider-track-1{ 
  border-radius: 2px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  
  background: rgba(255,255,255,0.2);
  background: transparentize(#8E82FF, 0.7);
}

.editor-minTimeline-progressBar-slider-thumb,
.editor-minTimeline-progressBar-slider-thumb:focus{
  cursor: pointer;

  width: 5px;
  height: 18px;
  background: rgba(255,255,255,1);
  background: lighten(#8E82FF, 15%);
  border-radius: 2px;

  opacity: 1;
  
  margin-top: 8px;

  z-index: 10;
  outline: none;

  
  // border: 1px solid var(--text400);
  outline: 3px solid rgba(32,34,42, 0.8);
  
}

.editor-minTimeline-progressBar-slider:hover 
.editor-minTimeline-progressBar-slider-thumb{
  opacity: 1;
}



//
// VOICEOVER



.editor-minTimeline-voiceoverTracks{
	min-height: 40px;
  width: 100%;  
  
  position: relative;
}

.editor-minTimeline-track{
  
  
  position: relative;

  z-index: 10; 
}

.editor-minTimeline-track--voiceover{
  // background: var(--primary500);
  // outline: 1px solid var(--primary600);

  // background: $tempr;
}

.editor-minTimeline-audioTrackBG{
  position: absolute;
  left: 0px;
  width: 100%;
  
  z-index: 1;

  //background: var(--243gray);
  //outline: 1px solid var(--235gray);
}


.editor-minTimeline-track--voiceover
.editor-minTimeline-track-inner{
  background: var(--primary500);
  outline: 1px solid var(--primary600);

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  
	height: 4px;  

  left: 0px;
  width: 100%;

  display: flex;
  align-items: center;

  box-sizing: border-box;
  // padding-left: 3px;

  font-size: 13.5px;
  font-weight: $fw-medium;
  color: white;

  user-select: none;

  border-radius: 1px;  

  transition: opacity 100ms linear;

}

.editor-minTimeline-track--voiceover
.editor-minTimeline-track-inner[data-state="active"]{
	opacity: 0.8;
	opacity: 1;
}

.editor-minTimeline-track--voiceover
.editor-minTimeline-track-inner[data-state="inactive"]{
	opacity: 0.5;
	opacity: 0.7;
}

.editor-minTimeline-track-inner[data-state="active"]:hover,
.editor-minTimeline-track-inner[data-state="inactive"]:hover{
	opacity: 1;
}



// .editor-minTimeline-track-inner-iconContainer{
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   width: 20px;
//   height: 24px;
//   margin-right: 0px;

//   flex-grow: 0;
//   flex-shrink: 0;
// }

// .editor-minTimeline-track-inner-iconContainer svg{
//   width: 12px;
//   height: 12px;
//   fill: white;
//   opacity: 0.75;
// }

// .editor-minTimeline-track--bgMusic[data-state="empty"]
// .editor-minTimeline-track-inner-iconContainer svg{
//   fill: var(--text500);
// }

// .editor-minTimeline-track-inner-iconContainer svg.icon--name--trackBacking{
//   width: 18px;
//   height: 18px;
//   position: relative;
//   top: -1px;
// }

// .editor-minTimeline-track-inner-label{
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;

//   flex-grow: 1;
//   flex-shrink: 1;
// }

// .editor-minTimeline-track--bgMusic[data-state="empty"]
// .editor-minTimeline-track-inner-label{
//   color: var(--text500);
// }
