
@import 'uiKit-Popover';
@import 'uiKit-CheckboxItem';

@import 'uiKit-LibraryPopover';

.rightPanel-row{
	display: flex;
	align-items: center;

	margin-bottom: 6px;
}

.rightPanel-row--fit2 {
	justify-content: space-between;
}

.rightPanel-row--fit2 .uiButton{	
	flex-grow: 0;
	flex-shrink: 0;

	width: calc(50% - 3px);
}

.rightPanel-row--fit2
.uiButton--projectPanel-background,
.rightPanel-row--fit2
.uiButton--projectPanel-trimVideo{
	width: calc(50% - 11px);
}

.rightPanel-row--fit2
.uiButton--projectPanel-motion{
	flex-grow: 1;
	flex-shrink: 1;
}

.rightPanel-row--fit1 .uiButton{
	width: 100%;
}

.rightPanel-row-buttonSpacer{
	width: 3px;
}

.rightPanel-row-hSpacer{
	min-width: 1px;
	flex-grow: 1;
	flex-shrink: 1;	
}

@import 'uiKit-SmallTabs';

@import 'videoPreview/VideoPreview.scss';
@import 'videoPlayer/VideoPlayer.scss';


.uiButton,
.uiToggleButton{
	padding-left: 6px;
	padding-right: 8px;

	background: var(--251gray);
	border-radius: 3px;

	height: 28px;

  cursor: pointer;
  border: 1px solid $invisible;

  position: relative;

}

.uiButton--iconOnly{
	padding-left: 0px;
	padding-right: 0px;

	width: 34px;
}

.editor-topBar .uiButton,
.libraryPage-sectionHeader .uiButton,
.libraryPage-header .uiButton,
.libraryPage-stickyHeader .uiButton{
	background: none;
}

.uiToggleButton:disabled{
	opacity: 0.25;
	pointer-events: none;
}

.uiButton:hover{
	background: var(--250gray);
}

.uiButton[data-state="open"],
.uiButton[data-state="active"]{
	background: var(--248gray);	
}

.uiButton--textColor{
	width: 100px;
}

.uiButton--textBGColor{
	width: 100px;
}

.uiButton--screenVideoBGColor{
	width: 70px;
}



.uiButton--zoomBarMotion{
	width: 100px;
}

.uiButton--textBGColor--add{
	opacity: 0.5;
}

.uiButton--playbackRate--default{
	opacity: 0.7;
}

.uiButton--textBGColor--add:hover,
.uiButton--playbackRate--default:hover{
	opacity: 1;
}

.uiButton--textBGColor--add
.uiButton-iconContainer{
	margin-left: -5px;
}

//
//

.uiButton--playbackRate{
	width: 50px;
}

.uiButton--playbackRate
.uiButton-label{
	font-size: 14px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: -0.022em;
  font-feature-settings: "tnum";

  width: 35px;
  // background: $tempr;
}

.uiButton--playbackRate
.uiButton-iconContainer{
	width: 15px;
	// background: $tempg;
	margin-right: -4px;
}

.uiButton--playbackRate
.uiButton-iconContainer svg{
	width: 13px;
	height: 13px;
	position: relative;
	top: 1.5px;
	left: -1.5px;
}

.dropdownMenu--selectPlaybackRate-list{
	width: 100%;
	display: flex;
	padding-left: 2px;
	padding-right: 2px;
}


.uiButton--selectPlaybackRateItem{
	width: 36px;
}

.uiButton--selectPlaybackRateItem[data-state="inactive"]:not(:hover){
	background: none;
}



.uiButton.uiButton--selectPlaybackRateItem:first-child{
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

.uiButton.uiButton--selectPlaybackRateItem:last-child{
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.uiButton.uiButton--selectPlaybackRateItem{
	border-top-right-radius: 1px;
	border-bottom-right-radius: 1px;
	border-top-left-radius: 1px;
	border-bottom-left-radius: 1px;
}


.uiButton--selectPlaybackRateItem
.uiButton-label{
	font-size: 14px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: -0.04em;
  font-feature-settings: "tnum";

  width: 20px;
  // margin-left: 3px;
  text-align: center;
}

.uiButton.uiButton--selectPlaybackRateItem[data-state="inactive"]:hover
.uiButton-label{
	opacity: 0.8;
}

	
.uiButton--selectPlaybackRateItem
.uiButton-iconContainer{
	width: 10px;
	// background: $tempg;
	
}

.uiButton--selectPlaybackRateItem
.uiButton-iconContainer svg{
	width: 13px;
	height: 13px;
	position: relative;
	top: 1.5px;
	left: 2.8px;
}



// .uiButton--textBGColor[data-state="noValue"],
// .uiButton--screenVideoBGColor[data-state="noValue"]{
// 	opacity: 0.5;
// }

// .uiButton--textBGColor[data-state="noValue"]:hover,
// .uiButton--screenVideoBGColor[data-state="noValue"]:hover{
// 	opacity: 1;
// }


.uiButton--light{
	opacity: 0.5;
}
.uiButton--midLight{
	opacity: 0.75;
}

.uiButton--light:hover,
.uiButton--midLight:hover{
	opacity: 1;
}

.uiButton--chartsData{
	background: none;
	padding-right: 10px;
	padding-left: 6px;
	margin-right: -9px;
}

.uiButton--chartsData
.uiButton-iconContainer{
	margin-right: 3px;
}

.rightPanel-row .uiButton{
	justify-content: flex-start;
}

.rightPanel-right-colorDropdownContainer .uiButton--textBGColor{
	justify-content: flex-start;
}

.rightPanel-row .uiButton--centered{
	justify-content: center;
}



.uiButton--chartType{
	width: calc(50% - 4px);
	width: calc(25% - 4px);
	width: calc(33% - 4px);
	height: 32px;
	margin: 1px;	
}


.uiButton--motionStyle{
	position: relative;
	// top: -3.5px;
	// margin-left: auto;
	// background: none;
	// margin-right: -5px;
	// margin-left: -2px;
	width: 32px;
	padding-left: 2px;
	padding-right: 0px;

	align-items: center;

}

.uiButton--motionStyle
.uiButton-iconContainer svg{
	transform-origin: scale(1.1);
}


.uiButton--screenDevice
.uiButton-iconContainer{
	width: 24px;
	margin-right: 3px;
}

.uiButton--screenDevice
.uiButton-iconContainer svg{
	width: 20px;
	height: 20px;
}

.uiButton--screenDevice
.uiButton-iconContainer svg #screen{
	opacity: 0.2;
}


.uiButton--textStyle{
	padding-right: 8px;
}

.uiButton--textStyle
.uiButton-iconContainer{
	margin-right: 3px;
}
.uiButton--textStyle
.uiButton-iconContainer svg{
	width: 18px;
	height: 18px;
}

// .uiButton--motionStyle .uiButton-label{
// 	opacity: 0.5;
// 	font-weight: $fw-semibold;
// 	font-variation-settings: "wght" 580;
// 	font-size: 13.5px;
// 	text-transform: capitalize;
// }

// .uiButton--motionStyle:hover .uiButton-label{
// 	opacity: 0.8;
// }



.uiButton--chartType .uiButton-label{
	// font-variation-settings: "wght" 540;
	// font-size: 15px;
}

.uiButton--chartType:disabled{
	opacity: 0.35;
	cursor: default;
}


.uiButton-colorPreview{
	width: 14px;
	height: 14px;

	margin-left: 1px;
	margin-right: 5px;

	border-radius: 2px;

	display: flex;
	align-items: center;
	justify-content: center;

	border: 1px solid rgba(255,255,255,0.15);
}

.uiButton-colorPreview--image{
	object-fit: cover;
}

.uiButton-colorPreview--image img{
	width: 100%;
	height: 100%;
}


.uiButton--addMusic{
	width: 220px;
	height: 28px;
	border: 1px solid rgba(255,255,255,0.08)
}

.uiButton--addMusic--empty:not(:hover){
	background: none;
	opacity: 0.7;
	background: rgba(255,255,255,0.025);
	border: 1px solid rgba(255,255,255,0.06)
}

.uiButton--addMusic--empty:hover{
	opacity: 1;
	border: 1px solid rgba(255,255,255,0.14)
}

.uiButton--addMusic .uiButton-iconContainer{
	margin-right: 3px;	
	transform: scale(0.95);
}

.uiButton--addMusic--active{
	background: rgba(80,181,125,0.25);
  outline: 1px solid rgba(113,214,159,0.6);

  background: rgba(255,255,255,0.14);
  outline: 1px solid rgba(255,255,255,0.3);

  opacity: 0.75;
  // outline: 1px solid desaturate(rgba(80,181,125,0.75), 5%);
}

.uiButton--addMusic .uiButton-label{  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 13.75px;
}

.uiButton--addMusic--active:hover{
	opacity: 1;

	background: rgba(255,255,255,0.17);
  outline: 1px solid rgba(255,255,255,0.36);
}


.uiButton-iconContainer{
	width: 20px;
	height: 20px;

	margin-right: 2px;
	margin-left: -2px;

	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;
}

.uiButton--iconOnly
.uiButton-iconContainer{
	margin-right: 0px;
	margin-left: 0px;
}

.uiButton-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.5;

	transform-origin: center center;
}

.uiButton-iconContainer svg.icon--name--stopwatch{
	transform: scale(1.2);
	opacity: 0.65;
}

.uiButton-iconContainer svg.icon--name--wand{
	transform: scale(1.05);
}

.uiButton-iconContainer svg.icon--name--filter{
	transform: scale(0.93);
}

.uiButton--iconOnly:hover
.uiButton-iconContainer svg{
	opacity: 0.75;
}




//
//

.uiButton-label,
.uiToggleButton-label,
.uiButton-keyboardShortcut{
	font-size: 14px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;
}

.uiButton[data-state="inactive"] .uiButton-label,
.uiToggleButton[data-state="inactive"] .uiToggleButton-label{
	opacity: 0.55;
}

.uiButton-label-sub{
	opacity: 0.5;
	margin-left: 0.25em;
	
}

.uiButton--trimVideo{
	// width: 320px;
	
}

.uiButton--trimVideo .uiButton-label{
	font-variation-settings: "wght" 480;
	// margin-left: 3px;
}

.uiButton-keyboardShortcut{
	// position: absolute;
	// right: 12px;

	display: flex;
	height: 100%;
	align-items: center;
	//font-size: 14px;

	margin-left: 6px;

	opacity: 0.5;

	color: white;
}

//
// Toggle

.uiToggleGroup{
	display: flex;
	column-gap: 1px;
}

.uiToggleGroup--fullWidth{
	width: 100%;
}

.uiToggleButton{
	padding-left: 0px;
	padding-right: 0px;
	border-radius: 0px;

	height: 27px;

	flex-grow: 0;
	flex-shrink: 0;
}

.uiToggleGroup--fullWidth--toggleCount--4
.uiToggleButton{
	width: 25%;
}

.uiToggleGroup--fullWidth--toggleCount--3
.uiToggleButton{
	width: 33%;
}

.uiToggleGroup--fullWidth--toggleCount--2
.uiToggleButton{
	width: 50%;
}

.editor-topBar--zoom
.uiToggleGroup{
	justify-content: flex-start;
	width: 160px;
}




// .editor-topBar--zoom-motionGroup
// .uiToggleButton{
// 	width: 70px;
// }

// .editor-topBar--zoom-type
// .uiToggleButton{
// 	width: 70px;
// }


.uiToggleButton--iconOnly{
	width: 32px;
}

.uiToggleGroup
.uiToggleButton:first-child{
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.uiToggleGroup
.uiToggleButton:last-child{
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}


// .uiToggleGroup--rightPanel--charts-type
// .uiToggleButton{
// 	height: 30px;
// }


.uiToggleButton--iconOnly
.uiToggleButton-iconContainer{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.uiToggleButton--iconOnly
.uiToggleButton-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.5;

	transform-origin: center center;
	transform: scale(1.05);
}


.uiToggleButton--mixed
.uiToggleButton-iconContainer{
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: -8px;
}

.uiToggleButton--mixed
.uiToggleButton-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.3;

	// transform-origin: center center;
	// transform: scale(1.05);
}





.uiToggleButton[data-state="active"]{
	background: lighten(rgb(28,29,33), 5%);
	//background: #516CE8;
}

.uiToggleButton--iconOnly[data-state="active"] svg{
	opacity: 0.9;
}

.uiToggleButton--mixed[data-state="active"] svg{
	opacity: 0.5;
}

.uiToggleButton[data-state="inactive"]{
	// background: var(--251gray);
	background: none;
}

.uiToggleButton[data-state="inactive"]:hover{
	background: var(--248gray);
}

.uiToggleButton[data-state="inactive"]:hover svg{
	opacity: 0.7;
}

.uiButton--zoomBarMotion
.uiButton-iconContainer svg.icon--name--stopwatch{
	transform: scale(1);
}


.editor-topBar--slideAlign
.uiToggleButton{
	width: 36px;
	border-radius: 2px;
}

.editor-topBar--slideAlign
.uiToggleButton svg{
	transform: scale(0.97);
}

.editor-topBar--slideAlign
.uiToggleButton:hover svg{
	opacity: 0.8;
}

.editor-topBar--slideAlign
.uiToggleButton svg #line{
	opacity: 0.4;	
}


//
//



.uiButton-leftTooltip{
	position: absolute;
	top: 0px;
	height: 100%;
	right: 100%;
	
	display: flex;
	align-items: center;

	white-space: nowrap;

	font-size: 14px;
	color: var(--text500);
	opacity: 0.35;

	opacity: 0;
	transform: translateX(-10px);
	transition: all 75ms linear;

	pointer-events: none;
}


.uiButton:hover .uiButton-leftTooltip{
	opacity: 0.35;
	transform: translateX(-12px);
	transition: all 75ms linear;

	transition-delay: 300ms;
}

.uiButton[data-state="open"] .uiButton-leftTooltip{
	opacity: 0;
	transform: translateX(-10px);
	transition: all 75ms linear;
}

// .uiButton-iconContainer svg.icon--name--wand{
// 	transform: scale(1.05);
// }


//
//

.uiButton--galleryDisplay[data-active-state='true']{

}

.uiButton--galleryDisplay[data-active-state='false']:not(:hover){
	opacity: 0.5;
}


.uiButton.uiButton--galleryDisplay--grid,
.uiButton.uiButton--galleryDisplay--list{
	padding-right: 10px;
}

.uiButton--galleryDisplay--grid
.uiButton-iconContainer{
	width: 24px;
	height: 24px;
	margin-right: 5px;
	margin-left: 0px;
}

.uiButton--galleryDisplay--grid
.uiButton-iconContainer svg{
	width: 22px;
	height: 22px;
	transform: scale(0.98);
}

.uiButton--galleryDisplay--list
.uiButton-iconContainer{
	margin-right: 5px;
	margin-left: 0px;
}

.uiButton--galleryDisplay--list .uiButton-iconContainer svg{
	width: 20px;
	height: 20px;
	transform: scale(0.93);
}	

.uiButton--sort .uiButton-iconContainer svg{
	width: 20px;
	height: 20px;
	transform: scale(0.90);
}

.uiButton--sort[data-state='active']{
	background: var(--246gray);
}

.uiButton--sort[data-state='active'] .uiButton-iconContainer svg{
	opacity: 0.8;
}




.uiButton--galleryDisplay[data-active-state='true'] svg{
	opacity: 0.8;
}


//
// SLIDER

.uiSlider{
	background: var(--251gray);
	border-radius: 3px;

	height: 26px;
}

.uiSlider{   
  display: flex;
  position: relative;

  opacity: 1;

  flex-grow: 1;
  flex-shrink: 1;
}

.uiSlider:hover{
  opacity: 1;
}

.uiSlider-label{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  z-index: 20;
  
  font-size: 14px;
  font-weight: $fw-medium;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;

  pointer-events: none;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 8px;
 
}

.uiSlider-edgeLabel{
  position: absolute;
  top: 0px;  
  width: 50%;
  height: 100%;

  z-index: 20;
  
  font-size: 14px;
  font-weight: $fw-medium;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;

  pointer-events: none;

  display: flex;
  align-items: center;
  
  
}

.uiSlider-edgeLabel--min{
	left: 0px;
	justify-content: flex-start;
	margin-left: 8px;
}

.uiSlider-edgeLabel--max{
	right: 0px;
	justify-content: flex-end;
	margin-right: 8px;
}

//

.uiSlider-edgeIcon{
  position: absolute;
  top: 0px;  
  width: 50%;
  height: 100%;

  z-index: 20;  

  pointer-events: none;

  display: flex;
  align-items: center;

  opacity: 0.5;
}

.uiSlider-edgeIcon svg{
	width: 20px;
	height: 20px;
	fill: var(--text500);
}

.uiSlider-edgeIcon--min{
	left: 0px;
	justify-content: flex-start;
	margin-left: 8px;
}

.uiSlider-edgeIcon--max{
	right: 0px;
	justify-content: flex-end;
	margin-right: 8px;
}


//


.uiSlider-slider{
  // width: 124px;
  width: 100%;
  position: relative;
  //cursor: pointer;
  cursor: ew-resize;

  border-radius: 3px;
  overflow: hidden;
  // opacity: 0.75;
}

.uiSlider-slider:hover{
	opacity: 1;
}

.uiSlider-slider-slider{
  height: 100%;
  margin-top: 0px;
  // background: $green;
}

.uiSlider-slider-slider-track{
  height: 100%;
  // margin-top: 7px;
}

.uiSlider-slider-slider-track-0{
  background: lighten(rgb(28,29,33), 13%);
  //background: #516CE8;
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.uiSlider-slider-slider-track-1{ 
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  
  // background: lighten(rgb(28,29,33), 5%);
  background: var(--251gray);
}

.uiSlider-slider-slider-thumb,
.uiSlider-slider-slider-thumb:focus{
  cursor: pointer;

  width: 1px;
  height: 22px;
  background: var(--text400);
  border-radius: 0px;

  opacity: 0;
  
  margin-top: 0px;

  z-index: 10;
  outline: none;

  display: none;
  // border: 1px solid var(--text400);
  // outline: 1px solid rgba(255,255,255,0.8);
  
}

.uiSlider-slider-slider:hover 
.uiSlider-slider-slider-thumb{
  opacity: 0.5;
}

	




//
// Custom





.dropdownMenu--selectColor-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0;
}

.dropdownMenu--selectColor--wide
.dropdownMenu--selectColor-grid {
  grid-template-columns: repeat(4, 1fr);
}

.dropdownMenu--selectColor-grid-item {
  padding: 4px;  
  position: relative;

  // outline: 1px solid red;
}


.dropdownMenu--selectColor-grid-item--auto
.dropdownMenu--selectColor-grid-item-inner,
.dropdownMenu--selectColor-grid-item--none
.dropdownMenu--selectColor-grid-item-inner{
	background: var(--bg-dropdown);
	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--text500);
	font-weight: $fw-medium;
	font-size: 14px;
}


.dropdownMenu--selectColor-grid-item--none
.dropdownMenu--selectColor-grid-item-inner{
	color: var(--text200);	
}



.dropdownMenu--selectColor-grid-item-noneLine{
	position: absolute;
	top: 23px;
	left: 0px;
	height: 1px;
	width: 69px;
	transform: rotateZ(-34deg);
	background: red;
	z-index: 5;
	background: rgba(255,255,255,0.15);
}



.dropdownMenu--selectColor-grid-item-bg{
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 4px;	
}

.dropdownMenu--selectColor-grid-item[data-state="active"]
.dropdownMenu--selectColor-grid-item-bg{
	background: var(--activeBG-suggestionItem);
}

.dropdownMenu--selectColor-grid-item[data-state="inactive"]:hover
.dropdownMenu--selectColor-grid-item-bg{
	background: var(--hoverBG-item-dropdown);
}


.dropdownMenu--selectColor-grid-item-border{
	position: absolute;
	top: 4px;
	left: 4px;
	width: calc(100% - 10px);
	height: calc(100% - 10px);

	border-radius: 2px;
	border: 1px solid white;

	z-index: 5;
}

.dropdownMenu--selectColor-grid-item[data-state="active"]
.dropdownMenu--selectColor-grid-item-border{
	opacity: 0.6;
}

.dropdownMenu--selectColor-grid-item[data-state="inactive"]:hover
.dropdownMenu--selectColor-grid-item-border{
	opacity: 0.35;
}

.dropdownMenu--selectColor-grid-item[data-state="inactive"]
.dropdownMenu--selectColor-grid-item-border{
	opacity: 0.15;
}


.dropdownMenu--selectColor-grid-item-inner{
	width: 60px;
	height: 40px;
	border-radius: 2px;
	position: relative;
	z-index: 5;
}


.dropdownMenu--selectColor-grid-item--image
.dropdownMenu--selectColor-grid-item-inner{
	object-fit: cover;
}

.dropdownMenu--selectColor-grid-item--image
.dropdownMenu--selectColor-grid-item-inner img{
	width: 100%;
	height: 100%;
	border-radius: 2px;
}

//

.uiButton.uiButton--elementColorSelector{
	position: relative;
	width: 40px;
	height: 26px;
	padding-left: 0px;
	padding-right: 0px;
}

.uiButton--elementColorSelector
.uiButton-iconContainer{
	position: relative;
	top: -0.5px;
	width: 100%;
	margin-left: 0px;
	margin-right: 0px;
}

.uiButton--elementColorSelector
.uiButton-iconContainer svg{
	opacity: 0.8;
	width: 22px;
	height: 22px;
}

.uiButton--elementColorSelector:hover 
.uiButton-iconContainer svg{
	opacity: 1;
}

.uiButton--elementColorSelector
.uiButton--elementColorSelector-colorStripe{
	position: absolute;
	bottom: 2px;
	left: 8px;	
	width: calc(100% - 16px);
	height: 1px;
	background: white;
	border-radius: 1px;
	// opacity: 0.75;
}

//

.dropdownMenu--selectSounds-inner{
	width: 100%;
	height: 100%;

	// background: $tempr;

	display: flex;
	flex-direction: column;

	min-height: 282px;
	min-width: 490px;
}


.dropdownMenu--selectMusic-grid{
	min-height: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	height: 100%;
	width: 100%;
	flex-direction: column;	
}

.dropdownMenu--selectMusic-grid-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0;
  position: relative;
  right: -2px;

  max-height: 400px;
  overflow-y: scroll;
}

.dropdownMenu--selectMusic-grid-item {
  padding: 4px;  
  position: relative;

  width: 240px;
  width: 240px;

  // outline: 1px solid red;
}


.dropdownMenu--selectMusic-grid-item
.dropdownMenu--selectMusic-grid-item-inner{
	
	background: var(--bg-dropdown);
	display: flex;
	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;
	padding-left: 12px;

	color: var(--text500);
	font-weight: $fw-medium;
	font-size: 14px;

	width: 100%;
	height: 40px;
	height: 32px;
	border-radius: 2px;
	position: relative;
	z-index: 5;

	border: 1px solid rgba(255,255,255,0.08);

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownMenu--selectMusic-grid-item[data-state="active"]
.dropdownMenu--selectMusic-grid-item-inner{
	background: var(--activeBG-suggestionItem);
	border: 1px solid rgba(255,255,255,0.5);
}

.dropdownMenu--selectMusic-grid-item[data-state="inactive"]:hover
.dropdownMenu--selectMusic-grid-item-inner{
	background: var(--hoverBG-item-dropdown);
	border: 1px solid rgba(255,255,255,0.2);
}



.dropdownMenu--selectMusic-grid-item-inner-playPauseBtn{
	flex-grow: 0;
	flex-shrink: 0;
	width: 24px;
	height: 20px;
	
	background: var(--250gray);

  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 6px;
  margin-left: -6px;
}


.dropdownMenu--selectMusic-grid-item-inner-playPauseBtn svg{
  width: 8px;
  height: 8px;
  fill: white;
  opacity: 0.7;
}

.dropdownMenu--selectMusic-grid-item-inner-playPauseBtn:hover{
  background: var(--246gray);
}

.dropdownMenu--selectMusic-grid-item-inner-playPauseBtn:hover svg{
  opacity: 1;
}


.dropdownMenu--selectMusic-grid-item-inner-title{
	flex-grow: 1;
	flex-shrink: 1;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  text-align: left;
}


.dropdownMenu--selectMusic-grid-uploadLarge{
	width: calc(100% - 12px);
	margin-left: 6px;
	border-radius: 5px;
	height: 236px;
	display: flex;
	flex-direction: column;

	background: var(--250gray);

	position: relative;
	top: 4px;
}


.dropdownMenu--selectMusic-grid-uploadLarge-iconContainer{
	width: 40px;
	height: 40px;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: -4px;
	margin-bottom: 12px;
}

.dropdownMenu--selectMusic-grid-uploadLarge-iconContainer svg{
	width: 40px;
	height: 40px;
	fill: var(--text500);
}

.dropdownMenu--selectMusic-grid-uploadLarge-iconContainer svg #laptop{
	opacity: 0.5;
}

.dropdownMenu--selectMusic-grid-uploadLarge-title{
	font-size: 14.75px;
	color: var(--text600);
	font-variation-settings: "wght" 520;
  letter-spacing: 0.008em;
}

.dropdownMenu--selectMusic-grid-uploadLarge-small{
	font-size: 13.25px;
	color: var(--text400);
	font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;
  opacity: 0.5;
  margin-top: 6px;
}

.dropdownMenu--selectMusic-grid-uploadLarge:hover{
	background: var(--246gray);
}

.dropdownMenu--selectMusic-grid-uploadLarge-spinnerContainer{
	position: absolute;

	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0px;
	width: 100%;
	height: 100%;

	box-sizing: border-box;
	padding-bottom: 2px;
}

.dropdownMenu--selectMusic-grid-uploadLarge-spinner{
	height: 48px;
	width: 48px;
	
  border-radius: 56px;

  border: 6px var(--line100) solid;
  border-top: 6px var(--line600) solid;

  opacity: 1;

  animation: spinnerRotate 1s infinite linear;
}



//


.dropdownMenu--selectMusic-grid-vSpacer{
	min-height: 1px;
	flex-grow: 1;
	flex-shrink: 1;
}

.dropdownMenu--selectMusic-grid-upload{
	width: calc(100% - 12px);
	margin-left: 6px;
	border-radius: 5px;
	height: 40px;
	display: flex;
	align-items: center;

	background: var(--250gray);
	margin-bottom: 4px;
	margin-top: 5px;

	position: relative;
}


.dropdownMenu--selectMusic-grid-upload-iconContainer{
	width: 32px;
	height: 32px;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;
}

.dropdownMenu--selectMusic-grid-upload-iconContainer svg{
	width: 32px;
	height: 32px;
	fill: var(--text500);
}

.dropdownMenu--selectMusic-grid-upload-iconContainer svg #laptop{
	opacity: 0.5;
}

.dropdownMenu--selectMusic-grid-upload-title{
	font-size: 14.75px;
	color: var(--text600);
	font-variation-settings: "wght" 520;
  letter-spacing: 0.008em;
}

.dropdownMenu--selectMusic-grid-upload-small{
	font-size: 13.25px;
	color: var(--text400);
	font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;
  opacity: 0.5;
  margin-top: 6px;
}

.dropdownMenu--selectMusic-grid-upload:hover{
	background: var(--246gray);
}


.dropdownMenu--selectMusic-grid-upload-spinnerContainer{
	position: absolute;

	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0px;
	width: 100%;
	height: 100%;

	box-sizing: border-box;
	padding-bottom: 2px;
}

.dropdownMenu--selectMusic-grid-upload-spinner{
	height: 20px;
	width: 20px;
	
  border-radius: 20px;

  border: 3px var(--line100) solid;
  border-top: 3px var(--line600) solid;

  opacity: 1;

  animation: spinnerRotate 0.65s infinite linear;
}



//
//

.uiButton--transitionDropdown{
	width: 94px;
	opacity: 0.8;
	border-radius: 3px !important;
}

.uiButton--transitionDropdown:hover{
	opacity: 1;
}

.uiButton--transitionDropdown[data-state="open"]{
	background: var(--activeBG-suggestionItem);
}

.uiButton--transitionDropdown--enter{
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;	
	margin-right: 1px;
}

.uiButton--transitionDropdown--exit{
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}


//
//


.uiButton--preview{
	height: 28px;
	// height: 36px;
	
	justify-content: flex-start;	
	box-sizing: border-box;
	// padding-left: 8px;
	//margin-bottom: 3px;
	margin-bottom: 0px;
	margin-left: 6px;

	width: calc(100% - 12px);

	// margin-left: -4px;

}

.uiButton--preview[data-state="active"]{
	border: 1px solid var(--line100);
	background: lighten(rgb(28,29,34), 10%);
}

.uiButton--preview[data-state="inactive"]{
	border: 1px solid var(--line100);
	background: lighten(rgb(28,29,33), 10%);
	border: 1px solid $invisible;
	background: none;

}

.uiButton--preview[data-state="inactive"]
.uiButton-label{
	// color: var(--text400);
	opacity: 0.65;
}

.uiButton--preview
.uiButton-label{
	font-variation-settings: "wght" 470;
  letter-spacing: 0.008em;
}


.uiButton--preview[data-state="inactive"]:hover{
	//border: 1px solid var(--line100);
	background: lighten(rgb(28,29,33), 5%);
	// border: none;
	// background: none;
}

.uiButton--preview-previewBtn{
	
	margin-left: auto;

	display: flex;
	align-items: center;
	justify-content: center;

	height: 20px;
	// background: $tempr;

	padding-right: 5px;
	padding-left: 1px;
	border-radius: 2px;

	border: 1px solid var(--line200);

	background: var(--250gray);

	opacity: 0;
	margin-right: -4px;

}

.uiButton--preview:hover
.uiButton--preview-previewBtn{
	opacity: 0.6;
}

.uiButton--preview:hover
.uiButton--preview-previewBtn:hover{
	opacity: 1;
}


.uiButton--preview-previewBtn-iconContainer{
	display: flex;

	align-items: center;
	justify-content: center;

	width: 18px;
	height: 18px;
}

.uiButton--preview-previewBtn-iconContainer svg{
	width: 8px;
	height: 8px;
	fill: var(--text500);
}

.uiButton--preview-previewBtn-label{
	font-size: 13.5px;
	font-weight: $fw-medium;
	color: var(--text600);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;

	color: var(--text500);
}


@import 'SoundEffects.scss';