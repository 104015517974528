.dpCheckboxContainer,
.dpCheckboxContainer *{
	cursor: pointer;
}

.editor-detailPanel-row-checkbox{
	width: 14px;
	height: 14px;
	border-radius: 3px;
}

.dpCheckboxContainer[data-checked-state='checked']
.editor-detailPanel-row-checkbox{
	// /background: transparentize($focusBorderColor-dpElement, 0.2);
	background: #4362c1;	
	border: 1px solid $focusBorderColor-dpElement;
	//border: 1px solid #4362c1;
	

}

.dpCheckboxContainer[data-checked-state='unchecked']
.editor-detailPanel-row-checkbox{	
	border: 1px solid rgba(255,255,255,0.3);
	background: rgba(255,255,255,0.02);
}

.dpCheckboxContainer[data-checked-state='checked']
.editor-detailPanel-row-label{
	color: var(--text600);
}

.dpCheckboxContainer[data-checked-state='unchecked']
.editor-detailPanel-row-label{
	opacity: 0.8;
}

.dpCheckboxContainer
.editor-detailPanel-row-checkbox
.editor-detailPanel-row-checkbox-iconContainer{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dpCheckboxContainer
.editor-detailPanel-row-checkbox
.editor-detailPanel-row-checkbox-iconContainer svg{
	width: 11.5px;
	height: 11.5px;
	stroke: white;
	stroke-width: 9px;
	position: relative;
	left: 0.25px;
	top: 0.25px;
}

.dpCheckboxContainer[data-checked-state='unchecked']
.editor-detailPanel-row-checkbox-iconContainer svg{
	opacity: 0;
}