$height-dpElement: 25px;
$height-dpElementRow: 40px;

.editor-detailPanel{
	position: absolute;
	// top: -30px;
	top: -1px;
	right: 8px;
	bottom: 0px;
	//bottom: -60px;
	left: 6px;

	border-radius: var(--borderRadius-editorPanel);
  background: var(--bg-editorPanel);
  border: 1px solid var(--borderColor-editorPanel);

  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: system-ui, sans-serif;

  display: flex;
  flex-direction: column;
}


.editor-detailPanel[data-detail-panel-type='none']{
	background: var(--bg-editorPanel);
	border: 1px solid $invisible;
	opacity: 0.5;
}

.editor-detailPanel-tempHide{
	visibility: hidden;
	height: 0px;
}

.editor-detailPanel-header{
	display: flex;
	align-items: center;
	height: $height-dpElement;
	margin-bottom: 2px;

	box-sizing: border-box;
	padding-left: 2px;
}

.editor-detailPanel-header-alignVSpacer{
	height: 8px;
}


.editor-detailPanel-header-vSpacer{
	flex-grow: 1;
	flex-shrink: 1;
}

.editor-detailPanel-vSpacer{
	min-height: 10px;
	flex-grow: 1;
	flex-shrink: 1;
}

.editor-detailPanel-row-hSpacer{
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
}

.editor-detailPanel-header-label{
	font-size: 13px;

	color: var(--text500);
	
  font-variation-settings: "wght" 540;
  letter-spacing: 0.008em;

  cursor: default;
  user-select: none;
}

.editor-detailPanel-screenLabels-primaryLabel{
	font-size: 13px;

	color: var(--text500);
	
  font-variation-settings: "wght" 540;
  letter-spacing: 0.004em;

  cursor: default;
  user-select: none;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editor-detailPanel-screenLabels-primaryLabel-sub{
	font-variation-settings: "wght" 540;
	margin-left: 0.4em;
	opacity: 0.4;
}

.editor-detailPanel-header-subLabel{
	font-size: 13px;

	color: var(--text500);
	
  font-variation-settings: "wght" 520;
  letter-spacing: 0.004em;

  opacity: 0.7; 
  cursor: default;
  user-select: none;

  margin-top: -2.5px;
  margin-top: 18px;
  margin-left: 2px;

  margin-bottom: 4px;
}

//
// 

.editor-detailPanel-header-button{
	width: 28px;
	height: $height-dpElement;
	position: relative;
	border-radius: 3px;
}

.editor-detailPanel-header-button--details{
	margin-right: -6px;
}

.editor-detailPanel-header-button--start{
	margin-left: -7px;
	margin-right: none;
}

.editor-detailPanel-header-button svg{
	width: 18px;
	height: 18px;

	fill: var(--text500);
	opacity: 0.75;
	transition: opacity 50ms linear;
}

.editor-detailPanel-header-button svg.icon--name--speakerRectangleMedium{
	transform: scale(0.95);
}

.editor-detailPanel-header-button--details svg,
.editor-detailPanel-header-button--camera svg{
	opacity: 0.4;
}

.editor-detailPanel-header-button svg.icon--name--laptop #screen{
	fill: rgba(255,255,255,0.06);
}

.editor-detailPanel-header-button svg.icon--name--laptop{	
	transform: scale(1.05);
}

.editor-detailPanel-header-button svg.icon--name--window{	
	transform: scale(0.95);
}


.editor-detailPanel-header-button:hover svg{
	opacity: 1;
}

.editor-detailPanel-header-button[data-active-state='active'],
.editor-detailPanel-header-button[data-state='open']{
	background: var(--250gray);
}

.editor-detailPanel-header-button[data-active-state='active'] svg,
.editor-detailPanel-header-button[data-state='open'] svg{
	opacity: 1;
}

//

.editor-detailPanel-screenLabels-input,
.editor-detailPanel-screenLabels-mixed{
	height: 26px;
	margin-top: -2px;
	
	position: relative;
	border-radius: 3px;

	margin-left: -6px;
	width: calc(100% + 6px + 6px);	

	margin-bottom: 3px;
}

.editor-detailPanel-screenLabels-input:hover{
	//background: rgba(244,240,255,0.065);
}

input.editor-detailPanel-screenLabels-input-input,
.editor-detailPanel-screenLabels-input-preview,
.editor-detailPanel-screenLabels-mixed-label{
	font-size: 13px;
	font-variation-settings: "wght" 540;
  letter-spacing: 0.004em;
  color: var(--text500);

	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	border-radius: 3px;

	box-sizing: border-box;
	padding-left: 6px;
	padding-right: 6px;

}

.editor-detailPanel-screenLabels-input-preview{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	pointer-events: none;	
}

.editor-detailPanel-screenLabels-input-preview-primaryLabel{
	flex-grow: 0;
	flex-shrink: 0;
}

.editor-detailPanel-screenLabels-input-preview-secondaryLabel{
	opacity: 0.65;
	flex-grow: 1;
	flex-shrink: 1;
	margin-left: 0.5em;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input.editor-detailPanel-screenLabels-input-input{
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  background: none;
  color: red;
  border: none;
  box-shadow: none;

  border: 1px solid $invisible;
}

input.editor-detailPanel-screenLabels-input-input{
	color: $invisible;
}

input.editor-detailPanel-screenLabels-input-input:focus{
	color: var(--text500);
}

.editor-detailPanel-screenLabels-input-preview{
	color: var(--text500);
}

input.editor-detailPanel-screenLabels-input-input:focus +
.editor-detailPanel-screenLabels-input-preview{
	color: $invisible;
}

input.editor-detailPanel-screenLabels-input-input:focus{
	border: 1px solid $focusBorderColor-dpElement;
	outline: none;
	background: rgba(244,240,255,0.065);
}
.editor-detailPanel-screenLabels-mixed{
	pointer-events: none;
	margin-top: 4px;
	margin-bottom: -3px;
}

// temporary for left edge with dropdown
.editor-detailPanel-header-button-tooltipContainer{
	position: absolute;
	left: 100%;
	margin-left: 2px;
	top: 0px;
	bottom: 0px;
	display: flex;
	align-items: center;
	pointer-events: none !important;

	opacity: 0;
}

.editor-detailPanel-header-button:hover
.editor-detailPanel-header-button-tooltipContainer{
	opacity: 1;
}


//
//

.editor-detailPanel-row{
	display: flex;
	align-items: center;
	height: $height-dpElementRow;
}

.editor-detailPanel-row--dynamicHeight{
	height: auto;
	padding-top: 10px;
	padding-bottom: 10px;
}

.editor-detailPanel-row--layoutBox{
	align-items: flex-start;
	height: auto;
	padding-top: 10px;
	padding-bottom: 10px;
}

.editor-detailPanel-row--secondDoubleRowUplift{
	margin-top: -6px;
}

.editor-detailPanel-row--donutDataInput{
	padding-top: 0px;
	height: 30px;
	padding-bottom: 0px;
	margin-bottom: -2px;
	margin-top: -3px;
}



.editor-detailPanel-row--customTextControls{
	height: auto;
	padding-top: 3px;
	padding-bottom: 3px;
	justify-content: space-between;
}

.editor-detailPanel-negativeTopMargin{
	margin-top: -6px;
}

.editor-detailPanel-row--tight{
	height: 36px;
}

.editor-detailPanel-row--extraTight{
	height: 30px;
}

.editor-detailPanel-row--customTextControls-bottomSpacer{
	height: 4px;
}

//

.editor-detailPanel-row-label{
	margin-left: 2px;	

	font-size: 12.75px;	
	color: var(--text500);
	opacity: 0.9;
	
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;
  user-select: none;
  cursor: default;
}

.editor-detailPanel-row-label[data-disabled='true']{
	opacity: 0.5;
}


.editor-detailPanel-row-hSpacer{
	flex-grow: 1;
	flex-shrink: 1;
}

.editor-detailPanel-divider{
	width: 100%;
	height: 1px;
	background: rgb(255,255,255);
	opacity: 0.03;

}

.editor-detailPanel-devSwitchButton{
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100px;
	// background: $tempr;
}

.editor-detailPanel-smallVSpacer{
	height: 3px;
}

.editor-detailPanel-row-smallHSpacer{
	width: 4px;
}







@import 'dpKit';
@import 'SlideAnimationPanel';
@import 'EditorDetailPanel-Spreadsheet';
@import 'EditorDetailPanel-WebcamCarousel';
