:root{		
	--254gray: 	rgb(252,252,255);
	--253gray: 	rgb(250,250,253);
	--252gray: 	rgb(249,249,252);
	--251gray: 	rgb(248,248,251);
	--250gray: 	rgb(246,246,250);
	--248gray: 	rgb(245,245,248);

	--246gray: 	rgb(242,242,246);
	
	--243gray: 	rgb(240,240,244);

	--240gray: 	rgb(235,234,240);

	--235gray: 	rgb(229,228,238);

	--220gray: 	rgb(212,212,220);	

	--210gray: 	rgb(202,202,210);
	
	--125gray: 	rgb(119,119,125);

	--250grayTransparent: rgba(13,13,125,0.04);

}

// DARK THEME

[data-theme="dark"], .forceDarkTheme {
	--254gray: 	rgba(244,240,255,0.01);
	//--253gray: 	rgba(244,240,255,0.03);
	--253gray: 	rgb(36,33,42);
	--252gray: 	rgba(244,240,255,0.03);
	--251gray: 	rgba(244,240,255,0.04);
	--250gray: 	rgba(244,240,255,0.06);
	--250grayTransparent: rgba(244,240,255,0.06);	
	--248gray: 	rgba(244,240,255,0.07);
	--246gray: 	rgba(244,240,255,0.09);
	--243gray: 	rgba(244,240,255,0.10);
	--240gray: 	rgba(244,240,255,0.12);
	--235gray: 	rgba(244,240,255,0.14);

	--220gray: 	rgba(244,240,255,0.22);

	--210gray: 	rgba(244,240,255,0.28);

	--210gray: 	rgba(244,240,255,0.2); // update for selecion side panel

	--125gray: 	rgb(128,128,134);
}