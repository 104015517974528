$height-libraryStickHeader: 64px;

.libraryPage-header{
	width: 100%;
	box-sizing: border-box;

	padding-left: $library-leftInset;
	padding-right: $library-rightInset;

	display: flex;
	align-items: flex-end;

	padding-top: 55px;
	margin-bottom: 14px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.libraryPage-header-leftContainer,
.libraryPage-stickyHeader-leftContainer{
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;

	align-items: flex-end;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  padding-right: 10px;
}



.libraryPage-header-iconContainer{
	width: 38px;
	height: 38px;

	margin-right: 18px;

	flex-grow: 0;
  flex-shrink: 0;
}

.libraryPage-header-iconContainer svg{
	width: 38px;
	height: 38px;

	fill: var(--text500);
	opacity: 0.45;

	position: relative;
	top: -1px;
}


.libraryPage-header-iconContainer--collection svg{
	width: 32px;
	height: 32px;

	top: 1px;
	left: 2px;
	opacity: 1;
}



.libraryPage-header-title{
	font-size: 36px;
	color: var(--text600);
  font-variation-settings: "wght" 570;
  letter-spacing: -0.008em;  

  user-select: none;
  cursor: default;

  flex-grow: 1;
  flex-shrink: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.libraryPage-header-rightContainer,
.libraryPage-stickyHeader-rightContainer{
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	align-items: flex-end;

	column-gap: 6px;

	position: relative;
	top: -5px;
}

.libraryPage-header-rightContainer .libraryPage-header-galleryDisplay,
.libraryPage-stickyHeader-rightContainer .libraryPage-header-galleryDisplay{
	margin-right: -6px; // messy, but looks weird 
}

//

.libraryPage-header-galleryDisplay{
	display: flex;
}

//

.libraryPage-stickyHeaderContainer{
	position: sticky;
	top: 0px;
	left: 0px;
	width: calc(100% - 9px); // for scrollbar
	

	background: $tempb;

	z-index: 10;
}



.libraryPage-stickyHeader{
	position: absolute;
	top: $mainBG-inset;
	left: $mainBG-inset;
	right: $mainBG-inset;
	height: $height-libraryStickHeader;
	border-top-left-radius: 5px;

	display: flex;
	align-items: center;
	background: rgba(29,29,31, 0.98);


	box-sizing: border-box;

	padding-left: $library-leftInset;
	padding-right: $library-rightInset;

	opacity: 0;
}

.libraryPage-stickyHeader-iconContainer{
	width: 26px;
	height: 26px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 10px;

	position: relative;
}

.libraryPage-stickyHeader-iconContainer svg{
	width: 26px;
	height: 26px;
	fill: var(--text500);

	opacity: 0.5;
}

.libraryPage-stickyHeader-iconContainer--collection svg{
	width: 22px;
	height: 22px;
	opacity: 1;
}


.libraryPage-stickyHeader-label{
	font-size: 26px;
	color: var(--text600);
  font-variation-settings: "wght" 570;
  letter-spacing: -0.000em;  
  user-select: none;
  cursor: default;
}


.libraryPage-header-smallSpacer{
	width: 6px;
}

.libraryPage-header-tinySpacer{
	width: 1px;
}

//
//

.libraryPage-header-search{
	position: relative;
	height: 28px;
	width: 200px;


}

input.libraryPage-header-search-input{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	background: var(--251gray);
	box-shadow: none;
	border: none;
	border-radius: 3px;

	padding-left: 26px;
	box-sizing: border-box;

	border: 1px solid $invisible;
}

.libraryPage-header-search-ui{
	position: absolute;
	left: 0px;
	width: 28px;
	height: 28px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.libraryPage-header-search-ui svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	position: relative;
	opacity: 0.4;
	margin-left: 3px;
}

input.libraryPage-header-search-input:focus{
	outline: none;
	background: var(--250gray);
	border: 1px solid var(--line150);
}

input.libraryPage-header-search-input:focus svg{
	opacity: 0.7;
}

//
//

.libraryPage-header-search-input,
.uiButton--primaryNewBtn .uiButton-label,
.uiButton--secondaryNewBtn .uiButton-label,
.uiButton.uiButton--galleryDisplay .uiButton-label{
	font-size: 15px;
	color: var(--text500);

  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;

}

.uiButton--primaryNewBtn .uiButton-label,
.uiButton--secondaryNewBtn .uiButton-label{
	font-variation-settings: "wght" 480;
	letter-spacing: 0.008em;
}


.uiButton.uiButton--primaryNewBtn,
.uiButton.uiButton--secondaryNewBtn{
	padding-right: 12px;
	padding-left: 8px;
}

.uiButton--collectionHeaderDropdownBtn{
	margin-left: -6px; // for space for collection dropdown
}

.libraryPage-header .uiButton.uiButton--primaryNewBtn,
.libraryPage-stickyHeader .uiButton.uiButton--primaryNewBtn{
	background: #516CE8;
}

.uiButton.uiButton--primaryNewBtn:hover{
	background: darken(#516CE8, 3%);
}

.uiButton.uiButton--primaryNewBtn .uiButton-iconContainer,
.uiButton.uiButton--secondaryNewBtn .uiButton-iconContainer{
	margin-right: 3px;
}

.uiButton.uiButton--primaryNewBtn .uiButton-iconContainer svg,
.uiButton.uiButton--secondaryNewBtn .uiButton-iconContainer svg{
	transform: scale(1.15);
	opacity: 0.75;
}

//
//

.libraryPage-header-iconContainer--collection--blue svg,
.libraryPage-stickyHeader-iconContainer--collection--blue svg{
	fill: var(--collection-blue);
}

.libraryPage-header-iconContainer--collection--purple svg,
.libraryPage-stickyHeader-iconContainer--collection--purple svg{
	fill: var(--collection-purple);
}

.libraryPage-header-iconContainer--collection--red svg,
.libraryPage-stickyHeader-iconContainer--collection--red svg{
	fill: var(--collection-red);
}

.libraryPage-header-iconContainer--collection--orange svg,
.libraryPage-stickyHeader-iconContainer--collection--orange svg{
	fill: var(--collection-orange);
}

.libraryPage-header-iconContainer--collection--green svg,
.libraryPage-stickyHeader-iconContainer--collection--green svg{
	fill: var(--collection-green);
}

.libraryPage-header-iconContainer--collection--pink svg,
.libraryPage-stickyHeader-iconContainer--collection--pink svg{
	fill: var(--collection-pink);
}

.libraryPage-header-iconContainer--collection--teal svg,
.libraryPage-stickyHeader-iconContainer--collection--teal svg{
	fill: var(--collection-teal);
}

.libraryPage-header-iconContainer--collection--darkBlue svg,
.libraryPage-stickyHeader-iconContainer--collection--darkBlue svg{
	fill: var(--collection-darkBlue);
}