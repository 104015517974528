
.editor-preview-leftPanelContainer{
  position: fixed;
  z-index: 100;

  box-sizing: border-box;  

  z-index: 10;
  
  color: var(--text500);

}




.editor-leftPanel{
	position: absolute;
	top: 0px;
  width: calc(100% - 20px);
  left: 2px;
	// left: -13px;
	// width: calc(100% - 16px + 14px);

	height: 100%;

	// background: $tempr;

	color: var(--text500);

  display: flex;
  flex-direction: column;
}


.editor-leftPanel-header{
  height: 40px;
  flex-grow: 0;
  flex-shrink: 0;  
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.editor-leftPanel-header-icon{
  margin-left: 12px;  
  margin-left: 10px;  

  // width: 40px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.4;

  position: relative;
  top: -4px;
  left: -3px;
}


.editor-leftPanel-header-icon svg{
  width: 26px;
  height: 26px;

  fill: var(--text500);
}


.editor-leftPanel-scrollArea{
  min-height: 10px;
  flex-grow: 1;
  flex-shrink: 1;

  overflow-y: scroll;

  // background: $tempb;

  display: flex;
  flex-direction: column;
}

.editor-leftPanel--ai-section{
  display: flex;
  flex-direction: column;

  cursor: pointer;

  position: relative;
}

$height-aiSectionHeader: 36px;

.editor-leftPanel--ai-section-header{
  height: $height-aiSectionHeader;

  display: flex;
  align-items: center;

  padding-left: 8px;
  padding-right: 8px;

  box-sizing: border-box;

  border-radius: 3px;

  font-size: 15px;
  font-weight: $fw-medium;
  color: var(--text500);

  text-transform: capitalize;

  cursor: pointer;

  // outline: 1px solid red;
}

.editor-leftPanel--ai-section-header:hover{
  background: rgba(255,255,255,0.01);
}

.editor-leftPanel--ai-section-header--minimized{
  opacity: 0.5;
}

.editor-leftPanel--ai-section-header--minimized:hover{
  opacity: 1;
}

.editor-leftPanel--ai-section-header-title{
  flex-grow: 1;
  flex-shrink: 1;

}


//



//

//


.editor-leftPanel--ai-vSpacer{
  min-height: 10px;
  flex-grow: 1;
  flex-shrink: 1;
}



//

.editor-leftPanel--ai-section-item{
	width: 100%;
	height: 30px;
  transform-origin: center center;

  position: absolute;
  top: $height-aiSectionHeader;

	display: flex;

  box-sizing: border-box;  

  transform-origin: 50% -30%;



}

.editor-leftPanel--ai-section-item-applyBtn-iconContainer{
  width: 20px;
  height: 20px;
  background: rgba(255,255,255,0.01);
  border-radius: 2px;

  margin-right: 7px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.editor-leftPanel--ai-section-item-applyBtn-iconContainer svg{
  width: 18px;
  height: 18px;
  fill: var(--text500);

  opacity: 0.5;
}

.editor-leftPanel--ai-section-item:hover 
.editor-leftPanel--ai-section-item-applyBtn-iconContainer svg{
  opacity: 0.8;
}


.editor-leftPanel--ai-section-item-applyBtn-iconContainer
svg.icon--name--assistZoom{

}

.editor-leftPanel--ai-section-item-applyBtn-iconContainer
svg.icon--name--assistShorten{
 transform: scale(1.1);  
}

.editor-leftPanel--ai-section-item-applyBtn-iconContainer
svg.icon--name--assistFaceBox{
  transform: scale(1.15); 
}

.editor-leftPanel--ai-section-item-applyBtn-iconContainer
svg.icon--name--musicNotes{
  transform: scale(0.9); 
}

.editor-leftPanel--ai-section-item-applyBtn-iconContainer
svg.icon--name--assistDuplicate{
  transform: scale(1.02);  
}

.editor-leftPanel--ai-section-item-applyBtn-iconContainer
svg.icon--name--assistTitle{
  transform: scale(1.15); 
}

.editor-leftPanel--ai-section-item-applyBtn-iconContainer
svg.icon--name--assistType{
  transform: scale(1.05);  
}

.editor-leftPanel--ai-section-item-applyBtn-iconContainer
svg.icon--name--transcript{
  transform: scale(0.85);  
}

//

.editor-leftPanel--ai-section-item-bg{
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 0px;
  right: 0px;
  
  border-radius: 3px;

  pointer-events: none;

  background: lighten(rgb(38,38,44), 3%);
  background: darken(rgb(38,38,44), 2%);

  transition: all 100ms linear;
}

.editor-leftPanel--ai-section-item--blinking
.editor-leftPanel--ai-section-item-bg{
  //background: var(--primary500) !important;
  background: lighten(rgb(38,38,44), 18%) !important;

}

.editor-leftPanel--ai-section-item--applied
.editor-leftPanel--ai-section-item-bg{
  background: $invisible;
}

.editor-leftPanel--ai-section-item:hover
.editor-leftPanel--ai-section-item-bg{
  background: lighten(rgb(38,38,44), 6%);
}

.editor-leftPanel--ai-section-item-applyBtn,
.editor-leftPanel--ai-section-item-cancelBtn{
  font-size: 15.5px;
  font-size: 14.75px;
  font-size: 14.25px;
  font-size: 14px;

  font-weight: $fw-medium;
  font-weight: $fw-regular;
  color: var(--text600);
  line-height: 1.32;
  letter-spacing: 0.012em;

  cursor: pointer;
}

.editor-leftPanel--ai-section-item-applyBtn{
  padding-left: 6px;
  min-width: 10px;
  flex-grow: 1;
  flex-shrink: 1;

  position: relative;
   z-index: 5;

  justify-content: flex-start;
}

.editor-leftPanel--ai-section-item-cancelBtn{
  flex-grow: 0;
  flex-shrink: 0;
  font-weight: $fw-regular;

  font-size: 13.75px;

  height: 100%;
  padding-right: 8px;

  position: relative;
   z-index: 5;

  opacity: 0;
  transition: opacity 100ms linear;
}

.editor-leftPanel:hover
.editor-leftPanel--ai-section-item-cancelBtn{
  opacity: 0.15;
}

.editor-leftPanel--ai-section-item:hover
.editor-leftPanel--ai-section-item-cancelBtn{
  opacity: 0.4;
}

.editor-leftPanel--ai-section-item
.editor-leftPanel--ai-section-item-cancelBtn:hover{
  opacity: 0.8;
}


.editor-assistant-item button{
  width: 100px;
  height: 40px;

}

