
.editor-timeline-timeline{
  
  height: $height-timeline-timeline;  

  display: flex;

  background: var(--bg-editorTimeline);

  position: relative;

  user-select: none;
  border-bottom: 1px solid var(--line400);
  border-left: 1px solid var(--line400);

  // dark
  border-bottom: 1px solid var(--line100);
  border-left: 1px solid var(--line100);

  border-bottom: none;
  border-left: none;

  background: var(--bg-editorTimelineTimeline);
  background: none;

  pointer-events: none;

  // margin-top: 12px;
  // margin-bottom: 14px;
  
  margin-top: 6px;
  margin-bottom: 7px;

  border-radius: 2px;

  pointer-events: none;
}

.editor-timeline-timeline-segment{
  position: absolute;
  top: 2px;
  bottom: 0px;

  display: flex;
  // background: $tempr;

  font-size: 11.5px;
  font-weight: $fw-regular;
  font-weight: $fw-medium;
  color: var(--text300);
  box-sizing: border-box;
  padding-left: 2px;
  align-items: center;



  opacity: 0.8;
}

.editor-timeline-timeline-segment:first-child{
  margin-left: 2px;
}

.editor-timeline-timeline-segment:first-child
.editor-timeline-timeline-segment-label:first-letter{
  opacity: 0;
}



.editor-timeline-timeline-segment-label{
  position: relative;
  z-index: 10;
  height: 100%;
  padding-top: 1px;
  
  //background: var(--bg-editorTimelineTimeline);
  

  
  padding-left: 4px;
  padding-right: 4px;

  opacity: 1;

  text-align: center;
  //background: red;

  width: 40px;
  margin-left: -26px;

  // opacity: 0;
  opacity: 0.6;
}

.editor-timeline-timeline-segment-mainMarker{
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  
  margin-top: -4px;
  height: 4px;
  width: 4px;
  border-radius: 4px;
  left: 0px;
  
  background: var(--text500);
  opacity: 0.5;
  color: red;

  opacity: 0;

  // opacity: 0.04;
  // dark mode
  // opacity: 0.0;
}

.editor-timeline-timeline-segment-marker{
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  
  margin-top: -4px;
  height: 6px;
  width: 2px;
  border-radius: 1px;
  left: 0px;
  
  background: var(--text500);
  opacity: 0.12;

  z-index: 2;
}


.editor-timeline-voiceoverTracks{
  // flex-grow: 1;
  // flex-shrink: 1;
  //min-height: 40px;
  width: 100%;
  padding-bottom: 2px;
  
  position: relative;
}