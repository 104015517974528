
// BG OPTIONS
// background: rgb(217,216,216);

:root{		
	//--bg-editor: hsl(240,0%,100%);	
	--bg-editor: rgb(246,246,246);
	--bg-transcriptPanel: rgb(255,255,255);
	

	--bg-dialog: rgb(255,255,255);

	//--bg-editorToolbar: rgb(244,244,255);	
	--bg-editorToolbar: rgb(255,255,255);	
	--bg-editorTimeline: rgb(236,234,240);	

	//--bg-zoomedMediaModals: rgba(1,0,2,0.88);	
	--bg-zoomedMediaModals: rgba(8,7,9,0.90);		
}

[data-theme="dark"], .forceDarkTheme{	
	//--bg-editor: rgb(28,26,32); 

	--bg-editor: rgb(4,4,5); // need to manually update in some places if change	
	--bg-editor: rgb(15,16,19); // need to manually update in some places if change	
	--bg-editor: rgb(24,25,28); // need to manually update in some places if change	
	--bg-editor: rgb(28,30,34); // need to manually update in some places if change	
	//--bg-editor: rgb(24,25,28); // need to manually update in some places if change	

	// --bg-editorToolbar: rgb(32,32,38);	
	// --bg-transcriptPanel: rgb(32,34,42);
	// --bg-editorTimeline: rgb(32,34,42);	

	--bg-editorPanel: rgb(30,31,33);
	--borderColor-editorPanel: rgb(35,37,42);	
	--borderRadius-editorPanel: 6px;

	--bg-canvasContainer: rgb(10,10,12);

	//--bg-editorToolbar: rgb(38,38,44);	
	--bg-editorToolbar: rgb(22,23,25);	
	//--bg-transcriptPanel: rgb(38,38,44);
	--bg-transcriptPanel: rgb(25,27,33);
	--bg-transcriptPanel: #151619;

	//--bg-editorTimeline: rgb(38,39,44);		
	//--bg-editorTimeline: rgb(25,27,33);	
	//--bg-editorTimeline: rgb(22,23,25);	
	--bg-editorTimeline: rgb(27,28,29);	
	


	//--bg-tabBar: rgb(32,32,43);
	--bg-tabBar: rgb(42,42,48);
	--bg-tabBar: rgb(32,33,34);

	--bg-editorTimelineTimeline: rgba(60,60,64, 0.8);	


}