
.projectlist{
  background: black;
}
.vPerfTest{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vPerfTest canvas{
  pointer-events: none !important;
}

.playground{
  width: 100vw;
  top: $height-tabBar;
  height: calc(100vh - #{$height-tabBar});
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: black;  
}

.playgroundBackBtn{
  position: fixed;
  top: 40px;
  left: 10px;

  color: var(--text500);
  background: var(--240gray);
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
}

.playground-selectExperimentBtns{
  position: fixed;
  top: 20px;
  left: 0px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  column-gap: 10px;

  z-index: 200;
}

.playground-selectExperimentBtns button{
  width: 140px;
  height: 40px;
  border-radius: 4px;
  color: rgb(255,255,255);
}

.playground-selectExperimentBtns button[data-state="active"]{
  background: rgba(255,255,255,0.08);
}

.playground-selectExperimentBtns button[data-state="inactive"]{
  opacity: 0.25;
}

.playground-selectExperimentBtns button[data-state="inactive"]:hover{
  opacity: 0.5;
  background: rgba(255,255,255,0.08);
}




.playground-timeline{
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  row-gap: 2px;

  position: fixed;
  bottom: 40px;
  left: 400px;
  right: 10px;

  z-index: 500;

  // background: $tempr;
}

.playground-timeline-buttons{
  display: flex;
  column-gap: 10px;
}

.playground-timeline button{
  width: 160px;
  height: 40px;
  background: rgba(255,255,255,0.04);
  color: white;
}

.playground-timeline button svg{
  width: 20px;
  height: 20px;
  fill: white;
}

.playground-timeline input{
  width: 600px;
  margin-top: 18px;
  margin-bottom: 18px;
}

.timeline button:hover{
  background: $tempr;
}


.playground-timeline-timestamp{
  color: white;
  font-family: 'SF Mono' !important;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 600px;
}

.playground-timeline-timestamp-time,
.playground-timeline-timestamp-frame{
  width: 200px;
  text-align: center;
}

.playground-timeline-timestamp-frame{
  opacity: 0.5;
}


//
//

@import 'Playground-TextSlide';
@import 'Playground-CursorGame';
@import 'Playground-ChartsPanel';


//

.playground-timestretchTimeline{
  position: fixed;
  top: 120px;
  left: 0px;
  right: 0px;

  height: 180px;
  
  display: flex;

  z-index: 100;

}

.playground-timestretchTimeline-segment{
  font-size: 12px;
  color: white;
  width: 100%;
  opacity: 0.75;
 

  margin-top: 5px;

  margin-bottom: 20px;
}

//
//
