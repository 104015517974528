.VOPage{
	display: flex;
	align-items: center;
	justify-content: center;

	height: 100%;
	width: 100%;

	box-sizing: border-box;
	padding-right: 40px;

	
}


.VOPage-settings{
	height: 100%;
	width: 400px;
	margin-right: 40px;

	display: flex;
	flex-direction: column;

	// background: $tempr;

	flex-grow: 0;
	flex-shrink: 0;

	box-sizing: border-box;
	padding-top: 100px;
	padding-left: 20px;

	background: var(--bg-editorPanel);
}




.VOPage-settings-segment{
	height: 120px;
	width: 100%;
	background: rgba(255,255,255,0.02);
	margin-bottom: 32px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 10px;
}

.VOPage-settings-segment-title{
	color: var(--text500);
	font-size: 15px;
	font-weight: $fw-bold;
	
	margin-top: 4px;
}

.VOPage-settings-segment
.uiSlider-slider-slider-track.uiSlider-slider-slider-track-0{
	background: blue;
}

.VOPage-timeline{
	flex-grow: 1;
	flex-shrink: 1;
	height: 100px;

	background: $tempb;
	position: relative;
}

.VOPage-timeline-segment,
.VOPage-timeline-gap{
	position: absolute;
	height: 100px;

}

.VOPage-timeline-segment{
	background: blue;
	color: white;
}

.VOPage-timeline-gap{
	background: red;
}