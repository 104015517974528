
@font-face {
    font-family: 'YArchivo';
    src: url(../fonts/YArchivo/YArchivo-Light.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-light;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YArchivo';
    src: url(../fonts/YArchivo/YArchivo-Regular.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-regular;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YArchivo';
    src: url(../fonts/YArchivo/YArchivo-Medium.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-medium;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YArchivo';
    src: url(../fonts/YArchivo/YArchivo-Semibold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-semibold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YArchivo';
    src: url(../fonts/YArchivo/YArchivo-Bold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YArchivo';
    src: url(../fonts/YArchivo/YArchivo-Heavy.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-heavy;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YArchivo';
    src: url(../fonts/YArchivo/YArchivo-Black.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-black;
    text-rendering: optimizeLegibility;
}
