


.recordingControlsDevContainer{
  position: fixed;
  
  top: calc(100% - 80px);

  left: 50%;
  transform: translateX(-50%);
  width: 380px;
  height: 50px;

  z-index: 500;


}


.recordingWindow{
	width: 100%;
	height: 100%;

	//background: var(--bg-editorPanel);

	
	border-radius: 10px;

	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 3px;
	padding-right: 3px;

	column-gap: 2px;

	
}

@media (prefers-color-scheme: dark){
	.recordingWindow{
		background: rgba(255,255,255,0.02);
		border: 1px solid rgba(255,255,255,0.13);
		border-top: 1px solid rgba(255,255,255,0.17);
		border-bottom: 1px solid rgba(255,255,255,0.10);
	}
}

@media (prefers-color-scheme: light){
	.recordingWindow{
		background: rgba(0,0,20,0.85);
		border-radius: 0px;
		// border: 1px solid rgba(0,0,20,0.90);
		// border-top: 1px solid rgba(0,0,20,0.80);
		// border-bottom: 1px solid rgba(0,0,20,0.80);
	}
}




.recordingWindow-smallBtn{
	width: 100px;
	height: 100%;

	flex-grow: 0;
	flex-shrink: 0;

	font-size: 14.5px;
	font-weight: $fw-medium;

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 400;
  letter-spacing: 0.008em;

  border-radius: 5px;

  transition: opacity 100ms linear;
}

.recordingWindow-smallBtn{
	color: rgba(255,255,255,0.5);
	background: rgba(0,0,0,0.0);

}


.recordingWindow-smallBtn:hover{
	color: rgba(255,255,255,1);	
	background: rgba(0,0,0,0.12);
}


.recordingWindow-smallBtn--cancel{
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.recordingWindow-smallBtn--resize,
.recordingWindow-smallBtn--counter{
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.recordingWindow-smallBtn--resize:disabled{
	opacity: 0.25;
	pointer-events: none;
}

.recordingWindow-smallBtn--counter{
	font-feature-settings: 'tnum', 'mono';
}

.recordingWindow-smallBtn--counter{
	pointer-events: none !important;
}

.recordingWindow--countdownActive
.recordingWindow-smallBtn{
	opacity: 0.25;
}

.recordingWindow-smallBtn-iconContainer{
	width: 22px;
	height: 22px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 2px;
	margin-left: -5px;
}

.recordingWindow-smallBtn-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: rgba(255,255,255, 1);
	opacity: 0.5;
}

.recordingWindow-primaryBtn{
	
	display: flex;
	align-items: center;
	justify-content: center;

	height: 100%;

	flex-grow: 1;
	flex-shrink: 1;

	background: $tempr;

	border-radius: 5px;

	font-size: 15px;
	font-weight: $fw-medium;
	

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;

  

  background: rgba(0,0,0,0.14);

  cursor: pointer;


}

.recordingWindow-primaryBtn{
	color: rgba(255,255,255,0.8);
	background: rgba(0,0,0,0.13);
}

.recordingWindow-primaryBtn:hover{
	color: rgba(255,255,255,1);
	background: rgba(0,0,0,0.18);
}

.recordingWindow-primaryBtn--countdownActive{
	background: rgba(0,0,0,0.05);	
}


.recordingWindow-primaryBtn--countdownActive-progressBar{
	width: calc(100% - 48px);

	height: 5px;
	position: relative;

	border-radius: 5px;
	overflow: hidden;
}

@keyframes progressBarAnimation {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.recordingWindow-primaryBtn--countdownActive-progressBar-bar {
    background: rgba(255,248,248,0.6);
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 50%; /* Initial width */
    z-index: 10;

    /* Apply the animation */
    animation: progressBarAnimation 800ms linear forwards;
}





.recordingWindow-primaryBtn--countdownActive-progressBar-track{
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;

	z-index: 5;

	background: rgba(255,248,248,0.2);
}


  

.recordingWindow-primaryBtn--stop-square{
	width: 26px;
	height: 26px;

	border-radius: 5px;

	background: rgba(255,255,255,0.8);
}

.recordingWindow-primaryBtn:hover 
.recordingWindow-primaryBtn--stop-square{
	background: rgba(255,255,255,01);
}

// .recordingWindow-primaryBtn--countdown{
// 	width: calc(100% - 8px);
// 	left: 4px;
// }

// .recordingWindow-primaryBtn--countdown-number{
// 	width: 24px;

// 	font-size: 22px;
// 	color: var(--text500);
// 	font-weight: $fw-semibold;
// }

// .recordingWindow-primaryBtn--countdown-number--after{
// 	opacity: 0.6;
// }

// .recordingWindow-primaryBtn--countdown-number--during{
// 	opacity: 1;
// }

// .recordingWindow-primaryBtn--countdown-number--before{
// 	opacity: 0.2;
// }


// .recordingWindow-primaryBtn--record-circle{
// 	width: 24px;
// 	height: 24px;
// 	border-radius: 50%;
// 	background: #FF9494;
// 	background: $white;
// 	opacity: 0.95;
// }

// .recordingWindow-primaryBtn--record:hover
// .recordingWindow-primaryBtn--record-circle{
// 	background: saturate(#FF9494, 5);
// 	background: $white;
// 	transform: scale(1.04);
// }

// .recordingWindow-primaryBtn--stop-square{
// 	width: 22px;
// 	height: 22px;
// 	border-radius: 4px;
// 	background: var(--text600);
// 	opacity: 0.8;
// }

// .recordingWindow-primaryBtn--stop:hover
// .recordingWindow-primaryBtn--stop-square{
// 	opacity: 1;
// }





// //
// // TEMP

// .recordingWindow-primaryBtn--record{
// 	background: #31292B;
// 	background: #FF7070;
// }
// .recordingWindow-primaryBtn--record:hover{
// 	background: lighten(#31292B, 2%);	
// 	background: darken(#FF7070, 2%);	
// }

// .recordingWindow-primaryBtn--countdown{
// 	background: var(--250gray);
// }

// .recordingWindow-primaryBtn--stop{
// 	background: var(--240gray);
// }

// .recordingWindow-primaryBtn--stop:hover{
// 	background: var(--235gray);	
// }