
.recordWebcamWindow-toolbar{
  width: 100%;
  height: 100%;
  border-radius: 8px;

  background: $webcamWindow-panelBG;

  -webkit-app-region: no-drag;
  position: relative;
}

//
// Devices


.recordWebcamWindow-devices{
  position: absolute;
  left: calc(#{$webcamWindow-padding} + 12px);
  bottom: $webcamWindow-padding;
  height: calc(#{$webcamWindow-toolbarHeight} - #{$webcamWindow-padding});
  display: flex;
  align-items: center;

  z-index: 2000;
}

.recordWebcamWindow-devices-btn{
  width: 48px;
  height: 48px;
  opacity: 0.5;
  // background: $tempr;
}

.recordWebcamWindow-devices-btn:hover,
.recordWebcamWindow-devices-btn[data-state='open']{
  opacity: 1;
}

.recordWebcamWindow-devices-btn svg{
  fill: var(--text500);
  width: 24px;
  height: 24px;
}

.recordWebcamWindow-devices[data-visibility-state='hidden']{
  opacity: 0;
  transition: opacity 75ms linear;
  transition-delay: 100ms;
}

.recordWebcamWindow-devices[data-visibility-state='visible']{
  opacity: 1;
  transition: opacity 150ms linear;
  transition-delay: 200ms;
}


//
// Start Stop Btn

.recordWebcamWindow-toolbar-startStopBtn{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  opacity: 1;
  transition: opacity 200ms linear;
  transition-delay: 500ms; // when switching cameras or loading
}

.videoRecordModal--script-toolbar-startStopBtn{
  z-index: 300;
}

.recordWebcamWindow-toolbar-startStopBtn:disabled{
  opacity: 0.25;
  transition: opacity 75ms linear;
  transition-delay: 0ms;
}


.recordWebcamWindow-toolbar-startStopBtn-inner{
  width: 56px;
  height: 56px;

  border-radius: 50%;
  border: 4px solid rgba(255,255,255,0.25);
  background: rgba(0,0,0,0.2);

  position: relative;
}

.recordWebcamWindow-toolbar-startStopBtn-inner-circle{
  position: absolute;
  top: 50%;
  left: 50%;

  width: 52px;
  height: 52px;

  border-radius: 50%; 
  background: rgba(255,255,255,0.9);
}

.recordWebcamWindow-toolbar-startStopBtn-inner-square{
  position: absolute;
  top: 50%;
  left: 50%;

  width: 28px;
  height: 28px;
  border-radius: 4px;
  background: rgba(255,255,255,0.75);
}


//
// Restart Button

.recordWebcamWindow-toolbar-restartBtn{
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translateX(-50%) translateY(-50%);

  width: 52px;
  height: 52px;

  border-radius: 50%;
  //border: 4px solid rgba(255,255,255,0.25);
  background: rgba(255,255,255,0.06);  

  display: flex;
  align-items: center;
  justify-content: center;
}

.recordWebcamWindow-toolbar-restartBtn svg{
  width: 28px;
  height: 28px;
  fill: var(--text500);
  opacity: 0.75;
  position: relative;
  top: -1px;
}

.recordWebcamWindow-toolbar-restartBtn:hover{
  background: rgba(255,255,255,0.18);  
}

.recordWebcamWindow-toolbar-restartBtn:hover svg{
  opacity: 1;  
  transform: scale(1.05);
}

.recordWebcamWindow-toolbar-restartBtn[data-visibility-state='hidden']{
  opacity: 0;
  transition: opacity 75ms linear;
  transition-delay: 100ms;
}

.recordWebcamWindow-toolbar-restartBtn[data-visibility-state='visible']{
  transition: opacity 150ms linear;
  transition-delay: 200ms;
}

//
// SELECT CAMERA BUTTON

.recordWebcamWindow-selectDeviceBtn{
  padding-left: 10px;
  padding-right: 10px;
  height: 28px;
  border-radius: 4px;

  background: $tempr;

  // justify-content: flex-start;

  position: relative;
  overflow: hidden;

  opacity: 1;
  transition: opacity 50ms linear;
}

.recordWebcamWindow-selectDeviceBtn[data-visibility-state='hidden']{
  opacity: 0;
  transition: opacity 75ms linear;
  transition-delay: 100ms;
  pointer-events: none !important;
}

.recordWebcamWindow-selectDeviceBtn[data-visibility-state='visible']{
  transition: opacity 150ms linear;
  transition-delay: 200ms;
}


.recordWebcamWindow-selectDeviceBtn[data-view-type='clean'],
.recordWebcamWindow-selectDeviceBtn[data-view-type='instructions']{
  position: absolute;
  
  z-index: 100;

  border: 1px solid rgba(255,255,255,0.125); 
  background: rgba(0,0,0,0.4);
  backdrop-filter: blur(25px) saturate(150%);
}

.recordWebcamWindow-selectDeviceBtn[data-view-type='clean'][data-device-type='camera'],
.recordWebcamWindow-selectDeviceBtn[data-view-type='instructions'][data-device-type='camera']{
  top: 4px;
  left: 4px;
  height: 32px;

  // bottom: 4px;
  // height: 32px;
  // left: 4px;
  // right: calc(50% + 4px);
}

.recordWebcamWindow-selectDeviceBtn[data-view-type='clean'][data-device-type='microphone']{
  // top: 0px;
  //height: calc(100% - #{$webcamWindow-padding});
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 260px;
  width: 320px;
  height: 38px;
  padding-bottom: 6px;
  // height: 32px;
  // left: calc(50% + 4px);
  //right: 4px;
}


.recordWebcamWindow-selectDeviceBtn[data-view-type='instructions'][data-device-type='microphone']{
  height: 38px;
  padding-bottom: 6px;
  bottom: 4px;
  left: 4px;
  right: 4px;
}

.recordWebcamWindow-selectDeviceBtn[data-view-type='script'][data-device-type='microphone']{
  position: absolute;
  
  bottom: 32px;
  height: 44px;
  min-width: 240px;
  left: 20px;  
  background: none;

  z-index: 500;
  //background: $tempr;
  //background: var(--gray250);
  //background: rgba(255,255,255,0.04);
}

.recordWebcamWindow-selectDeviceBtn[data-view-type='script'][data-device-type='camera']{
  position: absolute;
  bottom: 24px;
  height: 34px;
  min-width: 220px;
  
  left: 50%;
  transform: translateX(-50%);
  
  border: 1px solid rgba(255,255,255,0.125); 
  background: rgba(0,0,0,0.4);
  backdrop-filter: blur(25px) saturate(150%);

  z-index: 600;
}

.recordWebcamWindow-main--script-preview-videoContainer[data-expanded-state='default']
.recordWebcamWindow-selectDeviceBtn[data-view-type='script'][data-device-type='camera']{
  visibility: hidden;
  pointer-events: none !important;
}

.recordWebcamWindow-main--script-preview-videoContainer[data-expanded-state='expanded']
.recordWebcamWindow-selectDeviceBtn[data-view-type='script'][data-device-type='camera']{
  visibility: visible;
  pointer-events: auto !important;
}



.recordWebcamWindow-selectDeviceBtn-iconContainer{
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: -4px;
  margin-right: 2px;

  position: relative;
  z-index: 5;
}

.recordWebcamWindow-selectDeviceBtn-iconContainer svg{
  width: 16px;
  height: 16px;
  fill: white;
  opacity: 0.5;
}

.recordWebcamWindow-selectDeviceBtn-label{
  font-size: 13.65px;
  font-weight: $fw-medium;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 520;
  letter-spacing: -0.008em;  

  position: relative;
  z-index: 5;
}

.recordWebcamWindow-selectDeviceBtn--microphoneInputBarContainer{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.recordWebcamWindow-selectDeviceBtn--microphoneInputBar{
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  background: rgba(255,255,255,0.12);
}

.recordWebcamWindow-selectDeviceBtn--microphoneSegmentedVolumeContainer{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 160px;
  bottom: 6px;
  // top: 50%;
  // transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 3px;
}

.recordWebcamWindow-selectDeviceBtn--microphoneSegmentedVolume{
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 5px;
  height: 2px;
  border-radius: 1px;
  background: white;
}

.recordWebcamWindow-selectDeviceBtn--microphoneSegmentedVolume[data-state='active']{
  opacity: 1;
  transition: opacity 50ms linear;
}

.recordWebcamWindow-selectDeviceBtn--microphoneSegmentedVolume[data-state='inactive']{
  opacity: 0.2;
  transition: opacity 100ms linear;
}

.recordWebcamWindow-selectDeviceBtn[data-view-type='script']
.recordWebcamWindow-selectDeviceBtn--microphoneSegmentedVolumeContainer{
  bottom: 2px;
  opacity: 0.5;
}