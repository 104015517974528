.editor-canvasZoomUI{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	// background: $tempr;
	z-index: 50;
}



.editor-canvasZoomUI-draggable{
	width: 100%;
	height: 100%;

	object-fit: cover;
	background: $tempg;

	opacity: 0;
}

.editor-canvasZoom-full img{
	width: 100%;
	height: 100%;
}


.editor-canvasZoomUI-sliderContainer{

}

.editor-canvasZoomUI-sliderContainer{
	position: absolute;
	bottom: 0px;
	margin-bottom: 3px;

	left: 50%;
	transform: translateX(-50%);
	// left: 8px;
	width: 300px;
	border-radius: 6px;
	overflow: hidden;

	//background: rgba(0,0,0,0.5);

	display: flex;

}

.editor-canvasZoomUI-sliderContainer
.uiSlider{
	height: 28px;
	background: rgba(0,0,0,0.5);
}

.editor-canvasZoomUI-sliderContainer
.uiSlider-label{
	justify-content: center;

	font-size: 14.5px;
  font-weight: $fw-medium;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 520;
  letter-spacing: 0.008em;

}

.editor-canvasZoomUI-sliderBtn{
	height: 30px;
	width: 30px;

	margin-left: 4px;
	margin-right: 4px;

	background: rgba(0,0,0,0.5);
}


	

	.editor-canvasZoomUI-tipOverlayContainer{
		position: absolute;
		bottom: 0px;
		left: 0px;
		right: 0px;
		// background: $tempr;
		padding-bottom: 8px;
		display: flex;
		justify-content: center;
		pointer-events: none !important;
	}

	.editor-canvasZoomUI-tipOverlay{		
		height: 28px;
		

		display: flex;
		align-items: center;
		justify-content: center;

		border-radius: 5px;		
		padding-left: 10px;
		padding-right: 10px;

		border: 1px solid rgba(255,255,255,0.125) !important; 
  	background: rgba(26,26,32,0.72);
  	backdrop-filter: blur(25px);
  	user-select: none !important;
	}

	.editor-canvasZoomUI-tipOverlay-iconContainer{
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;

		margin-right: 3px;
		margin-left: -2px;
	}

	.editor-canvasZoomUI-tipOverlay-iconContainer svg{
		width: 20px;
		height: 20px;
		fill: white;
		opacity: 0.75;
	}

	.editor-canvasZoomUI-tipOverlay-label{
		font-size: 13.5px;	
		color: var(--text500);

  	font-variation-settings: "wght" 480;
  	letter-spacing: 0.008em;  
	}

