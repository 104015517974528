
// BG OPTIONS
// background: rgb(217,216,216);

:root{		
	--toolbarBtnBG: rgb(255,255,255);	
	--toolbarBtnBorder: 1px solid rgb(200,200,210);
	--toolbarBtnBorderTop: 1px solid rgb(210,210,220);
	--toolbarBtnBorderBottom: 1px solid rgb(190,190,200);
	--toolbarBtnBoxShadow: 0px 1px 2px 0px rgba(0,0,0,0.03);

	--toolbarBtnBorderHover: 1px solid rgb(190,190,200);
	--toolbarBtnBorderTopHover: 1px solid rgb(200,200,210);
	--toolbarBtnBorderBottomHover: 1px solid rgb(180,180,190);
}

[data-theme="dark"], .forceDarkTheme{	
	//--bg-editor: rgb(28,26,32); 
	--toolbarBtnBG: rgb(255,255,255, 0.010);	
	--toolbarBtnBorder: 1px solid rgb(200,200,210);
	--toolbarBtnBorderTop: 1px solid rgb(210,210,220);
	--toolbarBtnBorderBottom: 1px solid rgb(190,190,200);
	--toolbarBtnBoxShadow: 0px 1px 2px 0px rgba(0,0,0,0.03);
	
}