


input.editor-transcriptPanel-header-titleInput,
.editor-transcriptPanel-titleBtn{


	font-size: 15.5px;	
	color: var(--text500);
	font-weight: $fw-medium;
	
	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 580;
  letter-spacing: 0.008em;
  border: 1px solid $invisible;

  box-sizing: border-box;
	padding-left: 8px;

	border-radius: 2px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  width: 100%;

  // background: $tempr;
}



input.editor-transcriptPanel-header-titleInput{	
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	background: var(--250gray);
	border: 1px solid var(--line100);
}

.editor-transcriptPanel-header-titleInput-wrapper{
	width: 360px;
	height: 30px;
	background: var(--bg-editorPanel);
	margin-top: -0.5px;
}

.editor-transcriptPanel-titleBtn{
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	height: 30px;

	
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  cursor: pointer;

	display: flex;	
	align-items: center;
	justify-content: flex-start;

  // background: $tempb;
  padding-right: 8px;

  z-index: 10;

  border-radius: 3px;

  color: var(--text500);
}

.editor-transcriptPanel-titleBtn[data-visibility-state='visible']{
	opacity: 1;
}

.editor-transcriptPanel-titleBtn[data-visibility-state='hidden']{
	opacity: 0;
}


.editor-transcriptPanel-titleBtn-label{
	max-width: 100%;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editor-transcriptPanel-titleBtn-draft{
	opacity: 0.5;
	margin-left: 5px;
	font-variation-settings: "wght" 520;
}

input.editor-transcriptPanel-header-titleInput:hover{
	background: var(--250gray);
	border: 1px solid var(--line100);
}

input.editor-transcriptPanel-header-titleInput:focus{
	outline: none;
	background: var(--250gray);
	border: 1px solid var(--line100);
}

.editor-transcriptPanel-titleBtn:hover,
.editor-transcriptPanel-titleBtn[data-state='open'],
.editor-transcriptPanel-titleBtn[data-main-modal-state='open']{
	background: var(--251gray);
}

input.editor-transcriptPanel-header-titleInput[data-visibility-state='hidden'],
.editor-transcriptPanel-titleBtn[data-visibility-state='hidden']{
	visibility: hidden;
	pointer-events: none !important;
}

.editor-transcriptPanel-titleBtn-iconContainer{
	margin-left: auto;
	margin-right: -6px;	
	height: 100%;
	width: 28px;
	// background: $tempr;
	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-transcriptPanel-titleBtn-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.5;
}

.editor-transcriptPanel-titleBtn:hover
.editor-transcriptPanel-titleBtn-iconContainer svg{
	opacity: 0.9;
}

.editor-transcriptPanel-fixedHeader
.editor-transcriptPanel-titleBtn-iconContainer{
	display: none;
}


//

.editor-transcriptPanel-header-popoverContainer{
	position: relative;
	z-index: 10;
}

