//

.editor-transcriptPanel-list-skipMarkerContainer{
  position: absolute;
  
  height: 20px;
  
  z-index: 10000;
  
  cursor: pointer;

  
}



.editor-transcriptPanel-list-skipMarker{
  width: 100%;
  height: 100%;

  padding-left: 3px;
  padding-right: 3px;
  padding-top: 1px;
  padding-bottom: 1px;

  height: 18px;  

  margin-left: 2px;
  margin-top: 2px;
}

.editor-transcriptPanel-list-skipMarker[data-line-start='true']{
  margin-left: -7px;
}

.editor-transcriptPanel-list-skipMarker[data-state='open']{
  pointer-events: none;
}

.editor-transcriptPanel-list-skipMarker-inner{
  height: 18px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.editor-transcriptPanel-list-skipMarkerContainer:hover
.editor-transcriptPanel-list-skipMarker-inner-line{
  opacity: 1;
  background: lighten(#1D282B, 55%);
}

.editor-transcriptPanel-list-skipMarkerContainer[data-skip-marker-type='screencastLinked']:hover
.editor-transcriptPanel-list-skipMarker-inner-line{
  background: lighten(rgb(42, 39, 78), 55%) !important;
}


.editor-transcriptPanel-list-skipMarkerContainer
.editor-transcriptPanel-list-skipMarker[data-state='open']
.editor-transcriptPanel-list-skipMarker-inner-line{
  opacity: 1;
  background: lighten(#1D282B, 75%) !important;
}

.editor-transcriptPanel-list-skipMarkerContainer[data-skip-marker-type='screencastLinked']
.editor-transcriptPanel-list-skipMarker[data-state='open']
.editor-transcriptPanel-list-skipMarker-inner-line{
  background: lighten(rgb(42, 39, 78), 55%) !important;
}



.editor-transcriptPanel-list-skipMarker-inner-line{
  border-radius: 2px;
  width: 1.5px;
  background: lighten(#1D282B, 44%);
  opacity: 0.3;

  height: 18px;
}

.editor-transcriptPanel-list-skipMarkerContainer[data-skip-marker-type='screencastLinked']
.editor-transcriptPanel-list-skipMarker-inner-line{
  background: lighten(rgb(42, 39, 78), 54%);
}

.editor-transcriptPanel-list-skipMarker-tooltipContainer{
  position: absolute;
  bottom: calc(100% + 2px);
  
  pointer-events: none !important;
}

.editor-transcriptPanel-list-skipMarker-tooltipContainer[data-alignment-state='center']{
  left: 50%;
  transform: translateX(-50%);
}

.editor-transcriptPanel-list-skipMarker-tooltipContainer[data-alignment-state='left']{
  left: -20px;
}

.editor-transcriptPanel-list-skipMarker-tooltipContainer[data-alignment-state='right']{
  right: -14px;
}


.tooltip.editor-transcriptPanel-list-skipMarker-tooltip{
  background: lighten(#1D282B, 6%);
  border: 1px solid lighten(#1D282B, 18%);
  font-variation-settings: "wght" 520;

  padding-left: 7px;
  padding-right: 7px;

  opacity: 0;

  transition: all 100ms linear;
  transform: translateY(1px);
}

.editor-transcriptPanel-list-skipMarkerContainer[data-skip-marker-type='screencastLinked']
.tooltip.editor-transcriptPanel-list-skipMarker-tooltip{
  background: lighten(rgb(42, 39, 78), 6%);
  border: 1px solid lighten(rgb(42, 39, 78), 18%);
}



.editor-transcriptPanel-list-skipMarker[data-state='closed']:hover
.tooltip.editor-transcriptPanel-list-skipMarker-tooltip{
  opacity: 1;
  transition-delay: 100ms;
  transform: translateY(0px);
}



//

.editor-transcriptPanel-list-skipMarker-popover{
  width: auto;
  min-width: 200px;
  max-width: 290px;

  background: lighten(#1D282B, 5%);
  border: 1px solid lighten(#1D282B, 20%);
  border-top: 1px solid lighten(#1D282B, 24%);

  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 5px;
  padding-bottom: 10px;

  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 500;

  border-radius: 5px;

  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.12);
}

.editor-transcriptPanel-list-skipMarker-popover--screencastLinked{
  background: lighten(rgb(42, 39, 78), 5%);
  border: 1px solid lighten(rgb(42, 39, 78), 20%);
  border-top: 1px solid lighten(rgb(42, 39, 78), 24%);
}



.editor-transcriptPanel-list-skipMarker-header{
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: lighten(#1D282B, 84%);
  font-size: 11.5px;
  font-weight: $fw-medium;
  font-variation-settings: "wght" 540;
  letter-spacing: 0.014em; 
  user-select: none;

  margin-bottom: 1px;
}

.editor-transcriptPanel-list-skipMarker-popover--screencastLinked
.editor-transcriptPanel-list-skipMarker-header{
  color: lighten(rgb(42, 39, 78), 84%);
}



.editor-transcriptPanel-list-skipMarker-header-title{  
  opacity: 0.95;
  cursor: default;

}

.editor-transcriptPanel-list-skipMarker-header-restoreBtn{
  color: lighten(#1D282B, 64%);
  //background: rgba(255,255,255,0.04);
  opacity: 0.75;

  padding-left: 6px;
  padding-right: 6px;

  height: 20px;
  border-radius: 3px;

  font-variation-settings: "wght" 540;

  margin-right: -6px;
}

.editor-transcriptPanel-list-skipMarker-popover--screencastLinked
.editor-transcriptPanel-list-skipMarker-header-restoreBtn{
  color: lighten(rgb(42, 39, 78), 64%);
}


.editor-transcriptPanel-list-skipMarker-header-restoreBtn:hover{
  opacity: 1;
  background: rgba(255,255,255,0.08);

  color: lighten(#1D282B, 84%);
}


.editor-transcriptPanel-list-skipMarker-popover--screencastLinked
.editor-transcriptPanel-list-skipMarker-header-restoreBtn:hover{
  color: lighten(rgb(42, 39, 78), 84%);
}


.editor-transcriptPanel-list-skipMarker-content{
  color: lighten(#1D282B, 84%);
  opacity: 0.75;

  font-size: 13px;
  font-variation-settings: "wght" 420;
 
  line-height: 1.30;
  letter-spacing: 0.010em; 

  user-select: none;
  cursor: default;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editor-transcriptPanel-list-skipMarker-popover--screencastLinked
.editor-transcriptPanel-list-skipMarker-content{
  color: lighten(rgb(42, 39, 78), 84%);
}



// @keyframes skipMarkerPopoverFadeIn {
//   from {
//     opacity: 0;
//     transform: translateY(2px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0px);
//   }
// }

// @keyframes skipMarkerPopoverFadeOut {
//   from {
//     opacity: 1;
//     transform: translateY(0px);
//   }
//   to {
//     opacity: 0;
//     transform: translateY(2px);
//   }
// }


// .editor-transcriptPanel-list-skipMarker-popover[data-state='open']{
//   animation: skipMarkerPopoverFadeIn 25ms ease-out forwards;
// }


// .editor-transcriptPanel-list-skipMarker-popover[data-state='closed']{
//   animation: skipMarkerPopoverFadeOut 25ms ease-in forwards;
// }
