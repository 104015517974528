.updates-majorReleaseBanner{
	position: fixed;
	bottom: 12px;
	left: 12px;

	
	background: rgb(32,33,34);
	border-radius: 6px;

	padding: 8px;
	padding-bottom: 0px;
	display: flex;
	flex-direction: column;
	border: 1px solid var(--line200);

	z-index: 500;

	cursor: pointer;
	box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.32);
}

.updates-majorReleaseBanner:hover:not(:has(.updates-majorReleaseBanner-footer-hideBtn:hover)){
	background: lighten(rgb(32,33,34), 2%);
	border: 1px solid var(--line250);
}

.updates-majorReleaseBanner-banner{
	width: 390px;
	aspect-ratio: 16/9;
	border-radius: 3px;
	overflow: hidden;	

	background: #6F84F7; // temp

	transform: scale(1);
	transition: transform 75ms linear;

	position: relative;

	// background: red;
}

.updates-majorReleaseBanner-banner-border{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	border: 1px solid rgba(255,255,255,0.2);

	border-radius: 3px;
}

.updates-majorReleaseBanner-banner img{
	width: 100%;
	height: 100%;
	object-fit: cover;
	filter: brightness(1.1);

	// opacity: 0;
}

.updates-majorReleaseBanner:hover:not(:has(.updates-majorReleaseBanner-footer-hideBtn:hover))
.updates-majorReleaseBanner-banner{
	transform: scale(1.005);
}

.updates-majorReleaseBanner-footer{
	height: 46px;
	display: flex;
	align-items: center;	

	box-sizing: border-box;
	padding-left: 4px;
}

.updates-majorReleaseBanner-footer-iconContainer{
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 4px;
}

.updates-majorReleaseBanner-footer-iconContainer svg{
	width: 20px;
	height: 20px;
	fill: var(--text500);
	opacity: 0.5;
}

.updates-majorReleaseBanner-footer-title{
	

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.updates-majorReleaseBanner-footer-title-newTag{
	background: transparentize(#0c4325, 0.3);
  color: white;
  color: lighten(#72C899, 8%);
  font-size: 14px;

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 520;  
  letter-spacing: 0.008em; 

  height: 22px;
  padding-left: 6px;
  padding-right: 6px;

  border-radius: 3px;
  margin-right: 7px;
  margin-left: -2px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}

.updates-majorReleaseBanner-footer-title-label{
	font-size: 15px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 580;
  letter-spacing: 0.008em;
}


.updates-majorReleaseBanner-footer-hSpacer{
	flex-grow: 1;
	flex-shrink: 1;
}


.updates-majorReleaseBanner-footer-hideBtn{
	height: 100%;
	padding-right: 16px;
	padding-left: 16px;
	margin-right: -6px;

	// background: $tempr;

	font-size: 13.5px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;

  opacity: 0.5;
}

.updates-majorReleaseBanner-footer-hideBtn:hover{
	opacity: 1;
}