.popoverContainerPortal{
	z-index: 2000;
}
.popoverContainer{	
	z-index: 2000;	
}

.popoverContainer:focus{
	outline: none;
}

.popoverContainer--hidden,
.popoverContainer--hidden *{
	pointer-events: none !important;
}

//

.editorPopover{
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;

	align-items: flex-start;

	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);
  box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.28);

	border-radius: 5px;
	
	padding-left: 4px;
	padding-right: 4px;

	background: rgb(25, 27, 33);

}

.editorPopover--screenClip{
	width: 500px;
  width: 520px;
	//height: 300px;
  height: 340px;
}

.editorPopover--updatesPopover{
  width: 330px;
  //height: 350px;
  //min-height: 300px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 16px;
}

.editorPopover--shortcutsPopover{
  width: 270px;
  height: 400px;
  //height: 350px;
  //min-height: 300px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 16px;
}

@keyframes editorPopoverFadeInDown {
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes editorPopoverFadeOutUp {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(-4px);
  }
}




@keyframes editorPopoverFadeInUp {
  from {
    opacity: 0;
    transform: translateY(4px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes editorPopoverFadeOutDown {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(4px);
  }
}

.editorPopover[data-side='bottom'][data-state='open']{
  animation: editorPopoverFadeInDown 75ms ease-out forwards;
}
.editorPopover[data-side='bottom'][data-state='closed']{
  animation: editorPopoverFadeOutUp 75ms ease-in forwards;
}

.editorPopover[data-side='top'][data-state='open']{
  animation: editorPopoverFadeInUp 75ms ease-out forwards;
}
.editorPopover[data-side='top'][data-state='closed']{
  animation: editorPopoverFadeOutDown 75ms ease-in forwards;
}



///
/// TABS

.editorPopover-header{
  
  width: 100%;
  // background: $tempr;

  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 4px;

  box-sizing: border-box;

  display: flex;

  flex-grow: 0;
  flex-shrink: 0;
}

.editorPopover-header-tabs{
  display: flex;
}

.editorPopover-header-tabs-tab{
  padding-left: 8px;
  padding-right: 8px;

  height: 28px;

  border-radius: 3px;
  position: relative;
}

.editorPopover-header-tabs-tab:hover{
  background: var(--250gray); 
}

.editorPopover-header-tabs-tab[data-state="inactive"]{
  opacity: 0.5;
}

.editorPopover-header-tabs-tab[data-state="inactive"]:hover{
  opacity: 1;
}

.editorPopover-header-tabs-tab-label,
.editorPopover-header-label{
  font-size: 14px;
  font-weight: $fw-medium;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;

  user-select: none;
  pointer: default;
}

/*
.editorPopover-header-label{
  font-size: 13px;
  //margin-left: -6px;
  font-variation-settings: "wght" 540;
  margin-bottom: 2px;
  margin-top: 2px;
  opacity: 0.5;
  letter-spacing: 0.006em;
  user-select: none;
}
*/

.editorPopover--updatesPopover
.editorPopover-header-label{
  opacity: 0.5;
}

.editorPopover-header-tabs-tab-label-sub{
  opacity: 0.4;
  margin-left: 0.25em;
  margin-right: 0.25em;
  position: relative;
  top: -0.07em;
}
  
.editorPopover-header-tabs-tab-bottomBorder{
  position: absolute;
  bottom: 0px;
  left: 4px;
  right: 4px;
  height: 1.5px;
  background: var(--primary500);
  opacity: 0;

}

.editorPopover-header-tabs-tab[data-state="active"]
.editorPopover-header-tabs-tab-bottomBorder{  
  opacity: 1;
}

//

.editorPopover-header-tabs-tab--iPhone-recordIconContainer{
  width: 14px;
  height: 14px;
  margin-left: 1px;
  margin-right: 1px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.editorPopover-header-tabs-tab--iPhone-recordIcon{
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 5px;
}


.editorPopover-header-tabs-tab--iPhone-recordIcon--active{
  background: #72C899;
}

.editorPopover-header-tabs-tab--iPhone-recordIcon--inactive,
.editorPopover-header-tabs-tab--iPhone-recordIcon--searching{
  display: flex;
  align-items: center;
  justify-content: center;

  width: 12px;
  height: 12px;
}

.editorPopover-header-tabs-tab--iPhone-recordIcon--inactive-spinner,
.editorPopover-header-tabs-tab--iPhone-recordIcon--searching-spinner{
  height: 8px;
  width: 8px;
  
  border-radius: 8px;

  border: 1.5px var(--line200) solid;
  border-top: 1.5px var(--text300) solid;

  opacity: 1;

  animation: spinnerRotate 1s infinite linear;
}

.editorPopover-header-tabs-tab--iPhone-recordIcon--inactive-spinner{
  border: 1.5px var(--line200) solid;
  border-top: 1.5px var(--line200) solid;
  animation: none;
}

//


.editorPopover-body{
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  // background: $tempb;
  display: flex;
  flex-direction: column;
}

.editorPopover-body--scrollable{
  overflow-y: overlay;
  align-items: center;
}

.editorPopover--screenClip
.editorPopover-body--scrollable{
  padding-top: 14px;
  padding-bottom: 20px;
}




//
// Phone Setup

.editorPopover-body--iPhoneSetup{
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.editorPopover-body--iPhoneSetup-panel{
  width: 31%;
  // background: $tempr;
  // outline: 1px solid red;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  height: 210px;
  user-select: none;

  margin-top: 48px;
}

.editorPopover-body--iPhoneSetup-panel-illustrationContainer{
  width: 100%;
  height: 130px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.editorPopover-body--iPhoneSetup-panel-illustrationContainer svg{
  fill: var(--text500);
}

.editorPopover-body--iPhoneSetup-panel-illustrationContainer svg #cable{
  opacity: 0.4;
}

.editorPopover-body--iPhoneSetup-panel-label{
  font-size: 14.75px;
  font-weight: $fw-medium;
  color: var(--text600);

  text-align: center;

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 500;
  letter-spacing: 0.008em;
}

.editorPopover-body--iPhoneSetup-panel-description{
  margin-top: 5px;

  font-size: 13px;
  font-weight: $fw-medium;
  color: var(--text400);

  opacity: 0.75;

  text-align: center;

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 400;  
  letter-spacing: 0.012em; 
}

.editorPopover-body--iPhoneSetup-panel-description emph{
  font-variation-settings: "wght" 500;  
}

//


.editorPopover--screenClip-recordPhoneBtnContainer{
  flex-grow: 1;
  flex-shrink: 1;

  width: 100%;
  // background: $tempr;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}

.editorPopover--screenClip-recordPhoneBtn{
  width: calc(100% - 36px);
  height: 64px;
  

  position: relative;
  background: var(--250gray);
  border-radius: 8px;
}


.editorPopover--screenClip-recordPhoneBtn:hover{
  background: var(--248gray);
}

.editorPopover--screenClip-recordPhoneBtn-iconContainer{
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: -2px;
  margin-left: -6px;
}

.editorPopover--screenClip-recordPhoneBtn-iconContainer svg{
  width: 36px;
  height: 36px;
  fill: var(--text500);
  opacity: 0.5;
}

.editorPopover--screenClip-recordPhoneBtn:hover
.editorPopover--screenClip-recordPhoneBtn-iconContainer svg{
  opacity: 0.65;
}

.editorPopover--screenClip-recordPhoneBtn-label{
  font-size: 16px;
  font-weight: $fw-medium;
  color: var(--text600);

  text-align: center;

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;  
  letter-spacing: 0.008em; 
}

.editorPopover--screenClip-recordPhoneBtn--stop-square{
  width: 32px;
  height: 32px;
  border-radius: 6px;

  background: var(--text500);
  opacity: 0.85;
}

.editorPopover--screenClip-recordPhoneBtn:hover
.editorPopover--screenClip-recordPhoneBtn--stop-square{
  opacity: 1;
}


@keyframes phoneRecordActiveTimer {
  from {
    transform: translateY(-2px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.editorPopover--screenClip-recordPhoneBtn-activeTimer{
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;

  margin-top: 14px;
  pointer-events: none;
  font-feature-settings: "tnum";

  font-size: 15px;
  font-weight: $fw-medium;
  color: var(--text600);

  text-align: center;

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;  
  letter-spacing: 0.008em; 

  animation: phoneRecordActiveTimer 200ms ease-in forwards;
}



// @keyframes progressBarPopoverAnimation {
//   0% {
//     width: 0%;
//   }
//   2%{
//     width: 5%;
//   }
//   100% {
//     width: 100%;
//   }
// }


// .editorPopover--screenClip-recordPhoneBtn--countdownActive-progressBar-bar {
//     background: rgba(255,248,248,0.6);
//     position: absolute;
//     top: 0px;
//     left: 0px;
//     height: 100%;
//     width: 50%; /* Initial width */
//     z-index: 10;

//     /* Apply the animation */
//     animation: progressBarPopoverAnimation 4800ms linear forwards;
// }

// .editorPopover--screenClip-recordPhoneBtn--countdownActive-progressBar{
//   width: calc(100% - 128px);

//   height: 5px;
//   position: relative;

//   border-radius: 5px;
//   overflow: hidden;
// }

// .editorPopover--screenClip-recordPhoneBtn--countdownActive-progressBar-track{
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   height: 100%;
//   width: 100%;

//   z-index: 5;

//   background: rgba(255,248,248,0.2);
// }


.editorPopover--screenClip-recordPhoneBtn-countdown{
  width: 100%;
  height: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
}

.editorPopover--screenClip-recordPhoneBtn-countdown-digit{
  font-feature-settings: "tnum";

  font-size: 24px;
  font-weight: $fw-medium;
  color: var(--text600);

  text-align: center;

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 580;  
  letter-spacing: 0.008em;

  width: 60px; 

  display: flex;
  align-items: center;
  justify-content: center;
}



@keyframes countdownGeneralFade {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }  
}

.editorPopover--screenClip-recordPhoneBtn-countdown{
  animation: countdownGeneralFade 200ms linear forwards;
}

@keyframes recordPhoneCountdownDigit {
  0% {
    opacity: 30%;
  }
  15% {
    opacity: 100%;
  }
  75% {
    opacity: 100%;
  }
  100% {
    opacity: 15%;
  }
}


.editorPopover--screenClip-recordPhoneBtn-countdown-digit{
  font-feature-settings: "tnum";

  font-size: 26px;
  font-weight: $fw-medium;
  color: var(--text600);

  text-align: center;

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 580;  
  letter-spacing: 0.008em;

  width: 55px; 

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 30%;
  animation: recordPhoneCountdownDigit 1100ms linear forwards;
}

.editorPopover--screenClip-recordPhoneBtn-countdown-digit:nth-child(1){
  animation-delay: 0ms;
}

.editorPopover--screenClip-recordPhoneBtn-countdown-digit:nth-child(2){
  animation-delay: 1000ms;
}

.editorPopover--screenClip-recordPhoneBtn-countdown-digit:nth-child(3){
  animation-delay: 2000ms;
}

.editorPopover--screenClip-recordPhoneBtn-countdown-digit:nth-child(4){
  animation-delay: 3000ms;
}

.editorPopover--screenClip-recordPhoneBtn-countdown-digit:nth-child(5){
  animation-delay: 4000ms;
}




//

.editorPopover-body-footer{
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  justify-content: center;

  
  height: 20px;
}

.editorPopover-body-footer-iconContainer{
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 4px;
  user-select: none;
}

.editorPopover-body-footer-iconContainer svg{
  width: 16px;
  height: 16px;
  fill: var(--text500);
  opacity: 0.75;
}

.editorPopover-body-footer-label{

  font-size: 13px;
  font-weight: $fw-medium;
  color: var(--text400);

  opacity: 0.75;

  text-align: center;

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 400;  
  letter-spacing: 0.012em; 
  user-select: none;
}

.editorPopover-body-footer--cancelRecording{
  cursor: pointer;
}



@keyframes animateInKeepUnlockedButton {
  0% {    
    opacity: 0;
  }  
  50% {    
    opacity: 0;
  }  
  100% {    
    opacity: 1;
  }  
}

.editorPopover-body-footer--keepUnlocked{
  animation: animateInKeepUnlockedButton 400ms linear forwards;
}


@keyframes animateStartUnlockedButton {
  0% {    
    opacity: 0;
  }  
  50% {    
    opacity: 0;
  }  
  100% {    
    opacity: 1;
  }  
}

.editorPopover-body-footer--startUnlocked{
  animation: animateStartUnlockedButton 400ms linear forwards;
}




@keyframes animateInCancelButton {
  0% {    
    opacity: 0;
  }
  75% {    
    opacity: 0;
  }
  100% {    
    opacity: 0.75;
  }  
}

.editorPopover-body-footer--cancelRecording
.editorPopover-body-footer-label{
  animation: animateInCancelButton 800ms linear forwards;  

}

.editorPopover-body-footer--cancelRecording:hover
.editorPopover-body-footer-label{
  opacity: 1;   
}


@keyframes errorLabelWiggle {
  0% {
    transform: translateX(0px);        
    opacity: 0;
  }
  20% {
    transform: translateX(0px);        
    opacity: 1;
  }
  30%{
    transform: translateX(2px);    
    opacity: 1;
  }
  40%{
    transform: translateX(-2px);
    opacity: 1;
  }
  50%{
    transform: translateX(0px);
    opacity: 1;
  }
  100%{
    transform: translateX(0px);
    opacity: 1;
  }
  
}

.editorPopover-body-footer--error
.editorPopover-body-footer-label{
  animation: errorLabelWiggle 1200ms ease-in-out forwards;
  opacity: 1;
}






//
// WINDOW

.editorPopover--screenClip-windowChromeRow{
  display: flex;
  align-items: center;
  justify-content: flex-start;

  user-select: none;
  height: 52px;

  width: calc(100% - 18px);
  border-radius: 4px;  

  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;

  flex-grow: 0;
  flex-shrink: 0;

  // background: $tempr;
}

.editorPopover--screenClip-windowChromeRow-iconContainer{
  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 10px;
  margin-left: -1px;

  flex-grow: 0;
  flex-shrink: 0;
}

.editorPopover--screenClip-windowChromeRow-iconContainer svg{
  width: 24.5px;
  height: 24.5px;
}

.editorPopover--screenClip-windowChromeRow-iconContainer  img{
  width: 24px;
  height: 24px;
}

.editorPopover--screenClip-windowChromeRow-rightContainer{
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  flex-grow: 1;
  flex-shrink: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editorPopover--screenClip-windowChromeRow-title{
  font-size: 14.25px;
  font-weight: $fw-medium;
  color: var(--text500);  

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 460;  
  letter-spacing: 0.008em;
}

.editorPopover--screenClip-windowChromeRow:hover
.editorPopover--screenClip-windowChromeRow-title{
  color: var(--text600);  
}

.editorPopover--screenClip-windowChromeRow-label{
  margin-top: 3px;

  font-size: 14.25px;
  font-weight: $fw-medium;
  color: var(--text500);  
  opacity: 0.75;

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 460;  
  letter-spacing: 0.008em;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: 100%;
}

.editorPopover--screenClip-windowChromeRow:hover
.editorPopover--screenClip-windowChromeRow-label{
  opacity: 0.85;
}



//

.editorPopover--screenClip-windowDefaultRow,
.editorPopover--updates-row{
  display: flex;
  align-items: center;
  justify-content: flex-start;

  user-select: none;
  height: 30px;

  width: calc(100% - 18px);
  border-radius: 4px;  

  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;

  flex-grow: 0;
  flex-shrink: 0;
}

.editorPopover--updates-row{
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding-left: 12px;
  height: 44px;
  position: relative;
}

.editorPopover--screenClip-windowChromeRow:hover,
.editorPopover--screenClip-windowDefaultRow:hover,
.editorPopover--updates-row:hover{
  background: var(--250gray);
}

.editorPopover--updates-row-indicatorContainer{
  width: 9px;
  height: 9px;
  
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -2px;

  top: 10px;
  left: 10px;

  position: absolute;
}

.editorPopover--updates-row-indicator{
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #72C899;
}

.editorPopover--screenClip-windowDefaultRow-primaryLabel,
.editorPopover--updates-row-primaryLabel{
  font-size: 14.25px;
  font-weight: $fw-medium;
  color: var(--text500);  

  text-align: center;

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 460;  
  letter-spacing: 0.008em; 

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editorPopover--updates-row-primaryLabel{
  display: flex;
}

.editorPopover--updates-row-primaryLabel-newTab{
  background: transparentize(#0c4325, 0.3);
  color: white;
  color: lighten(#72C899, 5%);
  font-size: 11px;

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 520;  
  letter-spacing: 0.008em; 

  height: 17px;
  padding-left: 3px;
  padding-right: 3px;

  border-radius: 3px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  //top: -0.5px;
}



.editorPopover--updates-row[data-state='read']:not(:hover)
.editorPopover--updates-row-primaryLabel{
  opacity: 0.65;
}

.editorPopover--screenClip-windowDefaultRow:hover
.editorPopover--screenClip-windowDefaultRow-primaryLabel{
  color: var(--text600);  
}

.editorPopover--screenClip-windowDefaultRow-secondaryLabel{
  font-size: 14.25px;
  font-weight: $fw-medium;
  color: var(--text500);  
  opacity: 0.75;

  text-align: center;

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 420;  
  letter-spacing: 0.008em; 

  padding-left: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



.editorPopover--updates-row-date{
  // margin-left: auto;
  font-size: 11.75px;
  position: relative;
  top: 1px;
  font-weight: $fw-medium;
  color: var(--text500);  
  opacity: 0.5;  

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 420;  
  letter-spacing: 0.005em;   

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 1px;
}

.editorPopover--updates-row[data-state='read']:not(:hover)
.editorPopover--updates-row-date{
  opacity: 0.35;
}


.editorPopover--screenClip-windowDefaultRow:hover
.editorPopover--screenClip-windowDefaultRow-secondaryLabel{
  opacity: 0.85;
}

.editorPopover--screenClip-windowRowDivider{
  flex-grow: 0;
  flex-shrink: 0;

  font-size: 11.5px;
  font-weight: $fw-medium;
  color: var(--text500);  
  opacity: 0.6;

  text-align: center;
  user-select: none;

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;  
  letter-spacing: 0.008em; 

  box-sizing: border-box;
  padding-left: 20px;
  padding-top: 18px;
  padding-bottom: 8px;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

//
// PERMISSIONS

.editorPopoverOverlay{  
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
}


//



.popover--addFromLibrary-header{
  font-size: 17px;
  font-weight: $fw-semibold;
  color: var(--text500);
  padding-top: 14px;
  padding-bottom: 6px;

  margin-left: 18px;

}

.popover--addFromLibrary{
  height: 380px;
  height: 480px;
  padding-bottom: 0px;

  padding-left: 12px;
  padding-right: 12px;
}

.popover--addFromLibrary-grid{  
  display: grid;
  //grid-template-columns: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0;  
  min-width: calc((240 + 16) * 3px);

  position: relative;
  padding-bottom: 8px;

  overflow-y: overlay; /* This line is changed */
}

.popover--addFromLibrary-grid-item{
  display: flex;
  flex-direction: column;

  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}


.popover--addFromLibrary-grid-item:hover
.popover--addFromLibrary-grid-item-inner{
  background: var(--243gray);
}


.popover--addFromLibrary-grid-item-inner{ 
  
  background: var(--250gray);
  
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  color: var(--text500);
  position: relative;
}

.popover--addFromLibrary-grid-item-inner-videoContainer{
  overflow: hidden; 
  position: relative;
}

.popover--addFromLibrary-grid-item-inner-videoContainer video{
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}


.popover--addFromLibrary-grid-item-inner-videoContainer-playhead{
  position: absolute;
  top: 0px;
  height: 100%;
  width: 2px;
  margin-left: -1px;

  z-index: 10;

  background: red;
  pointer-events: none;
}

.popover--addFromLibrary-grid-item-inner-footer{
  height: 32px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  box-sizing: border-box;
  padding-left: 8px;

  font-size: 13.5px;
  font-weight: $fw-medium;

  color: var(--text500);

  opacity: 0.8;

}

.popover--addFromLibrary-grid-item:hover
.popover--addFromLibrary-grid-item-inner-footer{
  opacity: 1;
}


.popover--addFromLibrary-grid-item-inner-videoContainer-duration{
  position: absolute;
  bottom: 5px;
  right: 5px;

  padding-right: 4px;
  padding-left: 4px;

  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12.5px;
  font-weight: $fw-medium;
  color: white;

  border-radius: 3px;

  z-index: 20;

  background: rgba(0,0,0,0.75);
}