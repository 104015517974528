.playground-textSlideToolbar{
  position: fixed;
  left: 100px;
  right: 100px;
  top: 0px;
  height: 60px;

  z-index: 50;

  // background: $tempr;

  display: flex;
  align-items: center;
  justify-content: center;

  column-gap: 2px;
}

.playground-textSlideToolbar-btn{
  //width: 110px;
  padding-left: 12px;
  padding-right: 12px;
  height: 30px;
  background: rgba(255,255,255,0.25);
  color: white;

  font-size: 14.5px;

  border-radius: 4px;
}

.playground-textSlideToolbar-spacer{
  width: 40px;
}

.playground-textSlideToolbar-btn[data-state="active"]{
  opacity: 1; 
}

.playground-textSlideToolbar-btn[data-state="inactive"]{
  opacity: 0.25; 
}

.playground-textSlideToolbar-btn[data-state="inactive"]:hover{
  opacity: 0.75;
}


.playground-textSlideOuterContainer{
  position: fixed;
  top: 50px;
  bottom: 100px;
  left: 100px;
  right: 100px;
  background: $tempr;

  display: flex;
}

.playground-textSlideOuterContainer{

}

.playground-textSlideContainer{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  transform-origin: top left;

  background: green;
}

.playground-textSlideCanvasInnerContainer{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.playground-textSlideCanvasInnerContainer--inputPreview{
  z-index: 10;  
}

.playground-textSlideCanvasInnerContainer--inputPreview,
.playground-textSlideCanvasInnerContainer--inputPreview * {
  pointer-events: none !important;
}

.playground-textSlideCanvasInnerContainer--inputPreview canvas{
  background: none;
}


.playground-textSlideCanvasInnerContainer--default{
  pointer-events: none;
}

.playground-textSlideCanvasInnerContainer--default canvas{
  background: blue;
}


//

.playground-textSlideCanvasInnerContainer--defocus{
  z-index: 1;  
  background: $tempr;
}

.playground-textSlideCanvasInnerContainer--defocus:hover{
  background: $tempb;
}



//
//

.playground-textSlideCanvasInnerContainer--input{
  display: flex;
  align-items: center;
  justify-content: center; 

  z-index: 5;
}

.playground-textSlide-textArea{
  position: relative;
  // background: $tempr;
  background: rgba(0,0,0,0.05);
  border-radius: 6px;
  z-index: 2;
}

.playground-textSlide-textAreaDeactive{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.playground-textSlide-textArea:hover,
.playground-textSlide-textArea:focus-within{
  background: rgba(0,0,0,0.12);
}


.playground-textSlide-textArea #text_slide_pm_input{
  // color: $invisible;
  width: 100%;

  color: blue;
  opacity: 0.75;

  
  box-sizing: border-box;
}


.playground-textSlide-textArea .ProseMirror{
  outline: 2px solid red;
  padding-left: 30px;
  padding-right: 30px;

  font-feature-settings: 'liga' 1; // ff and things like that
  //
  // 
  letter-spacing: 0.011em;
}

.textSlide-PMContainer--isList
.text-slide-para{
  margin-bottom: 0.8em;
}

.textSlide-PMContainer--isList
.text-slide-para:last-child{
  margin-bottom: 0px;
}