


//
// POPOVERS


.popoverContainerPortal{
	z-index: 2000;
}
.popoverContainer{	
	z-index: 2000;	
}

.popoverContainer:focus{
	outline: none;
}

.popoverContainer--hidden,
.popoverContainer--hidden *{
	pointer-events: none !important;
}

//

.popover{
	display: flex;
	flex-direction: column;

	align-items: flex-start;

	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);

	border-radius: 5px;

	padding-top: 4px;
	padding-left: 6px;
	padding-right: 6px;
	padding-bottom: 4px;

}

.selectColorPopover{
	padding-top: 6px;
	padding-bottom: 6px;
	box-sizing: border-box;
}





@keyframes popoverFadeInDown {
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes popoverFadeOutUp {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(-4px);
  }
}




@keyframes popoverFadeInUp {
  from {
    opacity: 0;
    transform: translateY(4px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes popoverFadeOutDown {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(4px);
  }
}

.popover[data-side='bottom'][data-state='open']{
  animation: popoverFadeInDown 75ms ease-out forwards;
}
.popover[data-side='bottom'][data-state='closed']{
  animation: popoverFadeOutUp 75ms ease-in forwards;
}

.popover[data-side='top'][data-state='open']{
  animation: popoverFadeInUp 75ms ease-out forwards;
}
.popover[data-side='top'][data-state='closed']{
  animation: popoverFadeOutDown 75ms ease-in forwards;
}




// .popover--addFromLibrary-header{
// 	font-size: 17px;
// 	font-weight: $fw-semibold;
// 	color: var(--text500);
// 	padding-top: 14px;
// 	padding-bottom: 6px;

// 	margin-left: 18px;

// }

// .popover--addFromLibrary{
// 	height: 380px;
// 	height: 480px;
// 	padding-bottom: 0px;

// 	padding-left: 12px;
// 	padding-right: 12px;
// }

// .popover--addFromLibrary-grid{	
//   display: grid;
//   //grid-template-columns: repeat(2, 1fr);
//   grid-template-columns: repeat(3, 1fr);
//   grid-gap: 0;	
//   min-width: calc((240 + 16) * 3px);

//   position: relative;
//   padding-bottom: 8px;

//   overflow-y: overlay; /* This line is changed */
// }

// .popover--addFromLibrary-grid-item{
// 	display: flex;
// 	flex-direction: column;

// 	padding-left: 8px;
// 	padding-right: 8px;
// 	padding-top: 8px;
// 	padding-bottom: 8px;
// }


// .popover--addFromLibrary-grid-item:hover
// .popover--addFromLibrary-grid-item-inner{
// 	background: var(--243gray);
// }


// .popover--addFromLibrary-grid-item-inner{	
	
// 	background: var(--250gray);
	
// 	border-radius: 3px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	flex-direction: column;

// 	color: var(--text500);
// 	position: relative;
// }

// .popover--addFromLibrary-grid-item-inner-videoContainer{
// 	overflow: hidden;	
// 	position: relative;
// }

// .popover--addFromLibrary-grid-item-inner-videoContainer video{
// 	width: 100%;
// 	height: 100%;
// 	object-fit: cover !important;
// }


// .popover--addFromLibrary-grid-item-inner-videoContainer-playhead{
// 	position: absolute;
// 	top: 0px;
// 	height: 100%;
// 	width: 2px;
// 	margin-left: -1px;

// 	z-index: 10;

// 	background: red;
// 	pointer-events: none;
// }

// .popover--addFromLibrary-grid-item-inner-footer{
// 	height: 32px;
// 	width: 100%;

// 	display: flex;
// 	align-items: center;
// 	justify-content: flex-start;

// 	box-sizing: border-box;
// 	padding-left: 8px;

// 	font-size: 13.5px;
// 	font-weight: $fw-medium;

// 	color: var(--text500);

// 	opacity: 0.8;

// }

// .popover--addFromLibrary-grid-item:hover
// .popover--addFromLibrary-grid-item-inner-footer{
// 	opacity: 1;
// }


// .popover--addFromLibrary-grid-item-inner-videoContainer-duration{
// 	position: absolute;
// 	bottom: 5px;
// 	right: 5px;

// 	padding-right: 4px;
// 	padding-left: 4px;

// 	height: 20px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	font-size: 12.5px;
// 	font-weight: $fw-medium;
// 	color: white;

// 	border-radius: 3px;

// 	z-index: 20;

// 	background: rgba(0,0,0,0.75);
// }
