.libraryPage-projectListItem{
	height: 40px;
	width: calc(100% + 4px + 4px);
	margin-left: -4px;

	box-sizing: border-box;
	padding-left: 12px;
	padding-right: 0px;

	display: flex;
	align-items: center;

	border-radius: 4px;
	cursor: pointer;
	user-select: none;

	position: relative;
}

.libraryPage-projectListItem:hover{
	background: var(--252gray);
}

.libraryPage-projectListItem[data-dropdown-open='true'],
.libraryPage-projectListItem[data-dropdown-open='true']:hover{
	background: var(--251gray);
}

.libraryPage-projectListItem[data-promotezindex-state='true']{
	z-index: 10;
}


.libraryPage-projectListItem[data-state="open"],
.libraryPage-projectListItem[data-state="open"]:hover{
	background: var(--250gray);
}

.libraryPage-projectGallery--list[data-item-menu-open="true"]
.libraryPage-projectListItem[data-dropdown-open='false']{
	pointer-events: none !important;
}


.libraryPage-projectListItem-preview{
	height: 28px;
	aspect-ratio: 16/9;

	background: #4584EA;

	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 2px;

	margin-right: 12px;
}


.libraryPage-projectListItem-preview-cardExample{
	height: 70%;
	aspect-ratio: 16/10;
	background: white;
	border-radius: 1px;

	border: 1px solid var(--line400);
	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.24);
}

.libraryPage-projectListItem-loadingIndicatorContainer{
	position: absolute;
	left: 13px;
	top: 0px;
	bottom: 0px;

	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: flex-start;

}

.libraryPage-projectListItem-loadingIndicator{
	width: 32px;
	height: 32px;

	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempr;
}

.libraryPage-projectListItem-loadingIndicator-spinner{
  height: 12px;
  width: 12px;
  
  border-radius: 12px;

  border: 2px var(--line200) solid;
  border-top: 2px var(--text100) solid;

  opacity: 1;

  animation: spinnerRotate 1s infinite linear;
}


.libraryPage-projectListItem-draftIcon{
	width: 18px;
	height: 18px;
	margin-left: -2px;
	margin-right: 3px;
}

.libraryPage-projectListItem-draftIcon svg{
	width: 18px;
	height: 18px;

	fill: var(--text500);
	opacity: 0.5;
}


.libraryPage-projectListItem-label{
	display: flex;
	align-items: baseline;

	flex-grow: 1;
	flex-shrink: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  position: relative;

  height: 30px;
}


.libraryPage-projectListItem-label-label{
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  box-sizing: border-box;
  padding-left: 7px;

}
	

.libraryPage-projectListItem-label-label,
.libraryPage-projectListItem-label-isDraft,
.libraryPage-projectListItem-label-isEditing{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

	position: relative;
  top: 1px;
}


.libraryPage-projectListItem-label-label,
.libraryPage-projectListItem-label-isDraft,
.libraryPage-projectListItem-label-isEditing,
.libraryPage-projectListItem-label-input{
	font-size: 16px;	
	color: var(--text500);
  font-variation-settings: "wght" 470;
  letter-spacing: 0.004em;
  margin-right: 8px;
}

.libraryPage-projectListItem-label-isEditing{
	opacity: 0.75;
}

.libraryPage-projectListItem-label-isDraft{
	opacity: 0.5;
	margin-left: -3px;	

	flex-grow: 1;
	flex-shrink: 0;
}


.libraryPage-projectListItem-label-isDraft{
	margin-right: 4px;
}

.libraryPage-projectListItem-label[data-promotezindex-state='true']{
	z-index: 1000;
}
	
.libraryPage-projectListItem-label-inputContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;

	z-index: 100;

	width: 100%;

	opacity: 1;
}


.libraryPage-projectListItem[data-input-active="false"]
.libraryPage-projectListItem-label-label{
	opacity: 1;
}

.libraryPage-projectListItem[data-input-active="false"]
.libraryPage-projectListItem-label-isDraft{
	opacity: 0.5;
}




.libraryPage-projectListItem[data-input-active="false"]
.libraryPage-projectListItem-label-inputContainer{
	opacity: 0;
	pointer-events: none;
}

.libraryPage-projectListItem[data-input-active="true"]
.libraryPage-projectListItem-label-label,
.libraryPage-projectListItem[data-input-active="true"]
.libraryPage-projectListItem-label-isDraft{
	opacity: 0;
	pointer-events: none;
}

.libraryPage-projectListItem[data-input-active="true"]
.libraryPage-projectListItem-label-inputContainer{
	opacity: 1;
	z-index: 100;
}


.libraryPage-projectListItem-renameBGClick{
	position: fixed;
	top: -999px;
	left: -999px;
	right: -999px;
	bottom: -999px;
	z-index: 50;
}

.libraryPage-projectListItem-label-input{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	width: 100%;
	height: 100%;

	margin-right: 0px;

	box-sizing: border-box;
	padding-left: 6px;
	border-radius: 3px;

	background: var(--251gray);	
	border: 1px solid $invisible;
	box-shadow: none;

	line-height: 1;
	// padding-bottom: 2px;
	// color: red !important;
	position: relative;

	// color: red !important;
	top: 0.5px;
}

.libraryPage-projectListItem-label-input:focus{
	outline: none;
}

@import 'Library-ProjectListItem-Collections';

.libraryPage-projectListItem-duration{

	font-size: 14px;	
	color: var(--text500);
  font-variation-settings: "wght" 390;
  letter-spacing: -0.008em;

  width: 60px;
  font-feature-settings: "tnum";

  text-align: right;
  flex-grow: 0;
	flex-shrink: 0;
}





.libraryPage-projectListItem-timestamp{
	flex-grow: 0;
	flex-shrink: 0;
	width: 240px;

	text-align: right;

	font-size: 14px;	
	color: var(--text500);
  font-variation-settings: "wght" 390;
  letter-spacing: 0.012em;
}

.libraryPage-projectListItem-dropdownContainer{
	flex-grow: 0;
	flex-shrink: 0;

	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-left: 6px;
	padding-right: 6px;
}

.libraryPage-projectListItem-dropdownMenuBtn{
	width: 30px;
	height: 100%;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempr;
}


.libraryPage-projectListItem-dropdownMenuBtn-inner{
	width: 28px;
	height: 28px;
	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempr;
}

.libraryPage-projectListItem-dropdownMenuBtn svg{

}


.libraryPage-projectListItem-dropdownMenuBtn svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.45;
	//transform: scale(1.05);
}

.libraryPage-projectListItem-dropdownMenuBtn{
	opacity: 0;
}

.libraryPage-projectListItem:hover
.libraryPage-projectListItem-dropdownMenuBtn,
.libraryPage-projectListItem[data-dropdown-open='true']
.libraryPage-projectListItem-dropdownMenuBtn{
	opacity: 1;
}

.libraryPage-projectListItem-dropdownMenuBtn:hover svg{
	opacity: 0.95;
}



.libraryPage-projectListItem[data-dropdown-open='true']
.libraryPage-projectListItem-dropdownMenuBtn-inner,
.libraryPage-projectListItem-dropdownMenuBtn[data-state="open"]
.libraryPage-projectListItem-dropdownMenuBtn-inner{
	background: var(--250gray);
}

.libraryPage-projectListItem-dropdownMenuBtn[data-state="open"] svg{
	opacity: 1;
}




//
// Locked State

.libraryPage-projectListItem[data-locked-state="true"]{
	pointer-events: none !important;
	opacity: 0.5;
}

.libraryPage-projectListItem[data-loading-state="true"]{
	pointer-events: none !important;
}