.videoPlayer-subtitlesContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: flex-end;
	justify-content: center;

	box-sizing: border-box;
	padding-bottom: 14px;

	z-index: 5;
	pointer-events: none;
}


.videoPlayer-subtitleBox{
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 6px;
	padding-bottom: 6px;

	border-radius: 5px;

	background: rgba(0,0,0,0.7);

	font-size: 28px;

  font-variation-settings: "wght" 440;

  letter-spacing: 0.004em;

	color: white;

	word-wrap: pre-wrap;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	line-height: 1.12;

}

.videoPlayer:fullscreen
.videoPlayer-subtitleBox{
	font-size: 40px;
	padding-right: 14px;
	padding-left: 14px;
	padding-top: 10px;
	padding-bottom: 10px;
}

