.tooltip{
  background: $tempr;
  border: 1px solid var(--borderColorTooltip);
  border: 1px solid var(--line150);
  //background: var(--bgTooltip);
  background: var(--bg-tabBar);

  color: var(--text500);
  color: var(--textTooltip);

  // font-size: 13.5px;
  // font-weight: $fw-semibold;
  // letter-spacing: 0.02em;

  font-size: 11.75px;
  font-weight: $fw-medium;
  font-variation-settings: "wght" 460;
  letter-spacing: 0.014em;

  box-shadow: var(--shadowTooltip);

  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px;

  border-radius: 3px; 

  max-width: 250px;

  user-select: none;
  cursor: default;

  white-space: nowrap;

  //text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;  
}

.tooltip[data-side="top"]{
  margin-bottom: 2px;
}

.tooltip[data-side="bottom"]{
  margin-top: 2px;
}

.tooltip[data-side="left"]{
  margin-right: 2px;
}

.tooltip[data-side="right"]{
  margin-left: 2px;
}

.tooltip--textSelectionUI{
  max-width: 500px;
}

.tooltip--makeBGImage{
  // width: 60px;
  // white-space: unset;
  // text-align: center;
  // padding-bottom: 3px;
  // padding-top: 4px;
  margin-bottom: 4px !important;
  margin-right: 10px !important;
  // background: none !important;
  // border: none !important;
  // box-shadow: none !important;
}




.tooltip--uploadRecordingButton{
  max-width: 210px;
  white-space: unset;
  text-align: center;
  padding-bottom: 5px;
}


.tooltip.tooltip--infoBox{
  max-width: 200px;
  white-space: unset;
  text-align: center;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  pointer-events: none;

  background: rgb(35,35,37);

  font-size: 11.25px;
  font-weight: $fw-medium;
  font-variation-settings: "wght" 380;
  letter-spacing: 0.014em;
}




.tooltip.tooltip--addToLinear[data-side="top"]{
  margin-bottom: -4px;
}



.tooltip--activityRoadmap{
  max-width: 120px;
  white-space: unset;
  text-align: center;
  padding-bottom: 5px;
}


.tooltip--addSpecialZoom{
  max-width: 190px;
  white-space: unset;
  text-align: center;
  padding-bottom: 5px;
}


.tooltip--addToCalendarEmails{
  white-space: unset;
  text-align: center;
  width: 160px;
  padding-top: 5px;
  padding-bottom: 6px;
}


.tooltip--newTeamSyncTeamBtn{
  width: 220px;
  white-space: unset;
  text-align: center;
  padding-bottom: 5px;
}

.tooltip--projectsSideControls{
  max-width: 200px;
  white-space: unset;
  margin-left: 6px !important;
}

.tooltip.tooltip--mediaHoverMenu[data-side="top"]{
  margin-top: -2px;
}

.tooltip--warning{
  //background: var(--accentRed600);
  //border-color: var(--accentRed700);
  color: var(--accentRed200);
}

span.tooltip-light{
  opacity: 0.7;
  margin-left: 4px;
}


.tooltip.tooltip--chunkHandle{
  width: 115px;
  white-space: unset;
  text-align: center;

  flex-direction: column;
  padding-top: 4px;
  padding-bottom: 4px;

  font-family: system-ui, sans-serif;
  
  font-variation-settings: "wght" 520;
  background: rgb(40,41,44);
}

.tooltip--chunkHandle-line:first-child{
  margin-bottom: 1px;
}

.tooltip--chunkHandle-line
span.tooltip-light{
  margin-left: 0px;
  font-variation-settings: "wght" 380;
}


.tooltip.tooltip--textSelectionUI.tooltip--tableHoverBtn[data-side="top"]{
  margin-top: -5px;
}

.tooltip.tooltip--textSelectionUI.tooltip--tableRowCol[data-side="top"]{
  margin-top: -3px;
}


//
// KEYBOARD SHORTCUTS FOR TOOLTIPS
.tooltip .keyboardShortcutTagContainer{
  width: 100%;
  margin-left: 5px;
  margin-right: -2px;

  display: flex;
  align-items: center;
  justify-content: center;
}


// .tooltip--tableHoverRowCol{
//   padding-left: 0px; // override of the shortcut offset
// }
// .tooltip--tableHoverRowCol:first-child
// .keyboardShortcutTagContainer{
//   margin-right: 0px;
// }



.tooltip .keyboardShortcutTag{
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;

  font-size: 13px;

  background: var(--bgKeyboardShortcutTooltip);
  color: var(--textKeyboardShortcutTooltip);

  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: $fw-bold;
}

.tooltip.tooltip--withSquareBracket .keyboardShortcutTag{
  // square brackets look a bit off
  font-size: 12.5px;
}

.tooltip .keyboardShortcutTag svg{
  width: 12px;
  height: 12px;
  fill: var(--textKeyboardShortcutTooltip);
}

.tooltip .keyboardShortcutTag-item{  
  height: 12px;
  width: 13px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip .keyboardShortcutTagContainer--keyboardShortcutOnly{
  margin-top: 0px;
  width: 100%;
  margin-left: -4px;
  margin-right: -4px;
}

.tooltip .keyboardShortcutTagContainer--keyboardShortcutOnly .keyboardShortcutTag-item--letter{
  margin-right: -1px; // not sure why this is required, for move to doc dialog
}

.tooltip .keyboardShortcutTagContainer--keyboardShortcutOnly
.tooltip .keyboardShortcutTag{
  // background: $invisible;
  width: 100%;
}

//

.tooltip--detailsMode{
  border: none;
  background: none;
  font-size: 11.5px;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em; 
  opacity: 0.75;
  padding-right: 0px;
  padding-left: 0px;
  
  margin-right: 4px !important;
}

.tooltip--detailPanel{
  // border: none;
  border: 1px solid rgba(255,255,255,0.05);
  background: lighten(#151619, 5%);
  // background: red;
  font-size: 11.5px;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em; 
  // opacity: 0.5;
  // padding-right: 4px;
  // padding-left: 0px;
}



//
// HIDE TOOLTIP IF WRAPPING DROPDOWN IS OPEN
button[data-state="open"] span[data-state="closed"]{
  pointer-events: none;
}

// FORCE HIDE TOOLTIP
.tooltip--forceHide{
  display: none;
}




//
//

.tooltip--transcriptPanelTopBar{
  background: lighten(rgb(30,31,33), 18%);
  

  border: 1px solid rgba(255,255,255,0.03);
  background: lighten(#151619, 5%);
  // background: red;
  font-size: 11.5px;
  font-variation-settings: "wght" 500;
  letter-spacing: 0.008em; 

  color: var(--text500);
}

.uiButton--captionsDropdown-tooltipContainer,
.uiButton--speakerDropdown-tooltipContainer{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;

  opacity: 0;
  pointer-events: none !important;

  margin-top: 4px;
}

.uiButton--captionsDropdown:hover
.uiButton--captionsDropdown-tooltipContainer{
  opacity: 1;
}

.uiButton--speakerDropdown:hover
.uiButton--speakerDropdown-tooltipContainer{
  opacity: 1;
}

