.editor-templateBar{
  flex-grow: 1;
  flex-shrink: 1;

  min-width: 10px;

  display: flex;
  align-items: center;
  justify-content: center;  

  cursor: pointer;
  padding-top: 1px;
  pointer-events: none;
}

.editor-templateBar-templatesOuter{
  overflow-x: hidden;
  display: flex;
  justify-content: flex-start;
  
}

.editor-templateBar-templatesInner{
  display: flex;
  align-items: center;
  justify-content: center;  
  
}


//

.editor-templateBar-centerContainer{
  display: flex;
  align-items: center;
  pointer-events: auto;
}

.editor-templateBar-template{
  
  padding: 3px;
  position: relative;

  opacity: 0.6;
  transition: all 150ms linear;
  transition-delay: 150ms;
}

.popover--allTemplatesPopover-list
.editor-templateBar-template{
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.editor-templateBar-template-slidePreviewContainer{
  
  border-radius: 2px;
  overflow: hidden;

  aspect-ratio: 16/9;
}

.editor-templateBar-centerContainer:hover
.editor-templateBar-template,
.popover--allTemplatesPopover .editor-templateBar-template{
  opacity: 1;  
  transition-delay: 150ms;
  transition: opacity 150ms linear;
}

.editor-templateBar-centerContainer[data-popover-open='true']
.editor-templateBar-template{
  opacity: 0.6;
  transition: 75ms linear;
  transition-delay: 0ms;
}


.editor-templateBar-template-hoverBorder{
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  right: 1px;
  border-radius: 4px;

  border: 1.5px solid white;
  opacity: 0;
}

.popover--allTemplatesPopover
.editor-templateBar-template-hoverBorder{
  border-radius: 5px;
}

.editor-templateBar-templatesOuter:hover
.editor-templateBar-template:hover
.editor-templateBar-template-hoverBorder,
.popover--allTemplatesPopover
.editor-templateBar-template:hover
.editor-templateBar-template-hoverBorder{
  opacity: 0.9;  
  transition-delay: 0ms;
  transition: opacity 50ms linear;
}

.popover--allTemplatesPopover
.editor-templateBar-template[data-disabled-state='true']:hover
.editor-templateBar-template-hoverBorder{
  opacity: 0;  
}

//
//

.editor-templateBar-btn{

  height: 40px;

  padding-left: 2px;
  padding-right: 2px;


  width: 170px;
  // background: $tempr;

  border-radius: 3px;
  // border: 1px solid $invisible;
  // transition: all 50ms linear;
  opacity: 1;  
}

// .editor-templateBar-btn:hover{
//   background: var(--250gray);
//   border: 1px solid var(--line100);
//   transition-delay: 100ms;
// }

.editor-templateBar-centerContainer:hover
.editor-templateBar-btn{
  opacity: 1;
  transition-delay: 150ms;
  transition: opacity 150ms linear;
}

.editor-templateBar-btn-label{
  font-size: 13.25px; 
  color: var(--text500);
  
  font-variation-settings: "wght" 480;
  font-variation-settings: "wght" 520;
  letter-spacing: 0.008em;
  user-select: none;  

  padding-left: 4px;
  padding-right: 4px;

  opacity: 0;

  position: relative;
  
  // left: -4px;
  // background: $tempr;

  transition: all 100ms linear;  
}

.editor-templateBar-btn--left
.editor-templateBar-btn-label{
  transform: translateX(3px);
}

.editor-templateBar-btn--right
.editor-templateBar-btn-label{
  transform: translateX(-3px);
}


.editor-templateBar-btn-hSpacer{
  flex-grow: 1;
  flex-shrink: 1;
}

.editor-templateBar-btn:hover
.editor-templateBar-btn-label{
  opacity: 0.9;
  transition: 100ms linear;
  transition-delay: 300ms;
  transform: translateX(0px);
}

.editor-templateBar-btn-iconContainer{
  width: 28px;
  height: 24px;
  margin-left: 4px;
  margin-right: 4px;

  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: rgba(255,255,255,0.08);
  background: rgba(255,255,255,0.02);
  // background: none;
  transition: all 50ms linear;
}

// .editor-templateBar:hover
// .editor-templateBar-btn
// .editor-templateBar-btn-iconContainer{
//   background: rgba(255,255,255,0.02);
//   transition-delay: 0ms;
// }

.editor-templateBar:hover
.editor-templateBar-btn:hover
.editor-templateBar-btn-iconContainer{
  background: rgba(255,255,255,0.14);
  transition-delay: 0ms;
}

.editor-templateBar-btn-iconContainer svg{
  width: 18px;
  height: 18px;
  fill: white;
  opacity: 0.5;
  transition: all 50ms linear;
  transform: scale(1.08);
}

.editor-templateBar-btn:hover
.editor-templateBar-btn-iconContainer svg{
  opacity: 0.8;
  // transition-delay: 100ms;
}


.editor-templateBar-btn:disabled{
  pointer-events: none !important;
  visibility: hidden !important;
  cursor: default;
}

  

.editor-templateBar-firstTemplateBtn{
  font-size: 13.25px; 
  color: var(--text500);
  
  font-variation-settings: "wght" 480;
  font-variation-settings: "wght" 520;
  letter-spacing: 0.008em;
  user-select: none;  
  border-radius: 3px;

  height: 30px;

  padding-left: 3px;
  padding-right: 12px;

  // background: $tempr;
  opacity: 0.75;
  //background: rgba(255,255,255,0.02);
}

.editor-templateBar-firstTemplateBtn:hover{
  background: rgba(255,255,255,0.06);
  opacity: 1;
}



.editor-templateBar-firstTemplateBtn-iconContainer{
  width: 28px;
  height: 24px;
  margin-right: -2px;

  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  // background: rgba(255,255,255,0.02);
  
}



.editor-templateBar-firstTemplateBtn-iconContainer svg{
  width: 16px;
  height: 16px;
  fill: white;
  opacity: 0.8;
  transition: all 50ms linear;
  transform: scale(1.08);
}



// .editor-templateBar-galleryIcon{
//   display: grid;
//   grid-template-columns: repeat(3, 8px);
//   grid-template-rows: repeat(3, 6px);
//   gap: 2.5px;
//   width: fit-content;
//   position: relative;
//   left: 4px;

//   transform: scale(0.8);  

// }

// .editor-templateBar-galleryIcon-item{
//   width: 8px;
//   height: 6px;
//   border-radius: 1px;
//   background: white;
//   opacity: 0.3;
//   transition: opacity 50ms linear;
// }

// .editor-templateBar-btn:hover
// .editor-templateBar-galleryIcon-item{
//   opacity: 0.8;
// }


//
//

.popover.popover--allTemplatesPopover {
  max-height: 600px;
  min-width: 860px;
  width: auto;
  box-sizing: border-box;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 6px;
  box-shadow: none;
  border: 1px solid rgba(255,255,255,0.125) !important; 
  background: rgba(26,26,32,0.92);
  backdrop-filter: blur(25px);
  
  display: flex;
  flex-direction: column;
}

.popover--allTemplatesPopover-list{
  display: grid;
  grid-template-columns: repeat(4, 1fr);  
  overflow-y: auto;
  align-content: start;
  min-height: 500px;
}

.popover--allTemplatesPopover-header{
  padding-top: 8px;
  padding-bottom: 6px;
  padding-left: 5px;
  padding-right: 5px;

  box-sizing: border-box;
  width: 100%;

  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  user-select: none;
}

.popover--allTemplatesPopover-header-title{
  font-size: 14.5px;
  font-weight: $fw-medium;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 600;
  letter-spacing: 0.008em;

  opacity: 0.9;

  margin-left: 6px;
}

.popover--allTemplatesPopover-header-btn{
  margin-left: auto;

  font-size: 13px;
  font-weight: $fw-medium;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;

  opacity: 0.5;

  height: 100%;
  padding-right: 6px;
  padding-left: 6px;

  cursor: pointer;
}

.popover--allTemplatesPopover-header-btn:hover{
  opacity: 0.9;
}