

.editor-slideEditor,
.editor-slideEditorCanvasContainer{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  overflow-x: hidden !important;
  overflow-y: hidden !important;  
  z-index: 300;
}

.editor-slideEditor[data-dnd-dragging-element='true']{
  z-index: 750; // above 500 for the canvas preview
}

// .editor-slideEditor[data-dragging-element='true'],
// .editor-slideEditor[data-dragging-element='true'] *{
//   user-select: none !important;
//   cursor: pointer;
// }

.editor-slideEditor[data-dnd-dragging-element='true'],
.editor-slideEditor[data-dnd-dragging-element='true'] *{
  user-select: none !important;
  cursor: grabbing;
}

.editor-slideEditor[data-dragging-to-select='true'],
.editor-slideEditor[data-dragging-to-select='true'] *{  
  cursor: pointer !important;
}

.editor-slideEditor[data-state="hidden"]{
  visibility: hidden;
  pointer-events: none;
}

.editor-slideEditor[data-state="hidden"] *{
  pointer-events: none;
}

.editor-slideEditorCanvasContainer{
  z-index: 1500; 
}

.editor-slideEditorCanvasContainer,
.editor-slideEditorCanvasContainer * {
  pointer-events: none !important;
}

.editor-slideEditorCanvasContainer canvas{
  background: none;
}