//

.editor-toolbar-zoomSliderContainer{
  
  margin-right: 0px;
  margin-left: 1px;

  background: red;
  display: flex;
  position: relative;

  opacity: 0.7;

}

.editor-toolbar-zoomSliderContainer:hover{
  opacity: 1;
}

.editor-toolbar-zoomSliderLabel{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  z-index: 20;
  font-size: 14px;
  font-weight: $fw-medium;
  letter-spacing: 0.012em;
  color: var(--text600);

  pointer-events: none;

  margin-top: -8px;
  margin-left: 7px;
  
}


.editor-toolbar-zoomSliderInnerContainer{
  width: 124px;
  width: 132px;
  position: relative;
  top: -13px;
  //cursor: pointer;
  cursor: ew-resize;

}

.editor-toolbar-zoomSlider{
  height: 100%;
  margin-top: 0px;
  // background: $green;
}

.editor-toolbar-zoomSlider-track{
  height: 26px;
  // margin-top: 7px;
}

.editor-toolbar-zoomSlider-track-0{
  background: var(--timelineZoomSlider-frontTrackBG);
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.editor-toolbar-zoomSlider-track-1{ 
  border-radius: 2px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  
  background: var(--timelineZoomSlider-backTrackBG);
}

.editor-toolbar-zoomSlider-thumb,
.editor-toolbar-zoomSlider-thumb:focus{
  cursor: pointer;

  width: 1px;
  height: 22px;
  background: var(--text400);
  border-radius: 0px;

  opacity: 0;
  
  margin-top: 0px;

  z-index: 10;
  outline: none;

  display: none;
  // border: 1px solid var(--text400);
  // outline: 1px solid rgba(255,255,255,0.8);
  
}

.editor-toolbar-zoomSlider:hover 
.editor-toolbar-zoomSlider-thumb{
  opacity: 0.5;
}

