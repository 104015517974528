//$width-dpNumberInputLeft: 25px;

.dpTextArea{
	height: 80px; // improve
	// // width is set in JS
	position: relative;

	background: var(--250gray);
	border-radius: 3px;

	box-sizing: border-box;
	border: 1px solid $invisible;
}

.dpTextArea[data-auto-resizing='true']{
	min-height: 80px;
	height: auto;
	overflow-y: hidden;
}

.dpTextArea:hover{
	background: rgba(244,240,255,0.065);
	border: 1px solid var(--248gray);
}

.dpTextArea[data-input-focused='true']{
	//background: rgba(244,240,255,0.065);
	border: 1px solid $focusBorderColor-dpElement;
}

.dpTextArea[data-disabled='true']{
	opacity: 0.5;
	pointer-events: none;
}


//


textarea.dpTextArea-input{
	position: absolute;
	left: 0px;
	right: 0px;	
	height: 100%;
  
  border: none;
  outline: none;
  box-shadow: none;

  padding: 0px;
  background: none;

  resize: none;
}

.dpTextArea[data-auto-resizing='true']
textarea.dpTextArea-input{	
	overflow-y: hidden;
}


.dpTextArea-ghost,
textarea.dpTextArea-input{
	padding-top: 8px;
	padding-left: 8px;
	
  box-sizing: border-box;
  padding-bottom: 2.5px;

	font-size: $baseFontSize-dpElement;	
	color: var(--text500);
	
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;
}

.dpTextArea-ghost{
	padding-top: 6px;
	padding-left: 6px;

	position: relative;
	z-index: 200;
	color: red;
	color: $invisible;
	white-space: pre-wrap;
	
	pointer-events: none !important;
	padding-bottom: 12px;

}

.editor-detailPanel-row--donutDataInput
.dpTextArea{
	height: 28px;
}

.editor-detailPanel-row--donutDataInput
input.dpTextArea-input{
	height: 28px;
	padding-bottom: 3.5px;
}