

//

.editor-transcriptPanel-draggableGutter{
  position: absolute;
  top: 0px;
  left: 0px;

  width: 100%;
  bottom: 0px;
  height: 100%;

  overflow-x: hidden;
  overflow-y: hidden;
}

.nearBottomTest{
  position: absolute;
  left: 0px;
  top: 0px;
  height: 20px;
  width: 20px;
  pointer-events: none;
}

.editor-transcriptPanel-draggableGutter[data-chunk-dragging='true'],
.editor-transcriptPanel-draggableGutter[data-chunk-dragging='true'] *{
  cursor: grabbing !important
}

.editor-transcriptPanel-draggableContainer{  
  position: absolute;
  left: 0px;
  width: 0px;
  z-index: 50;
  opacity: 0;
}

.editor-transcriptPanel-draggableContainer[data-dropdown-open='true']{
  z-index: 55;
}

.editor-transcriptPanel-draggableContainer
.editor-transcriptPanel-handle{
  opacity: 0;
  transition: opacity 100ms linear;
}

.editor-transcriptPanel-draggableGutter[data-chunk-longDelayedEnd-dragging='false']
.editor-transcriptPanel-draggableContainer[data-chunk-hovered-state="true"]
.editor-transcriptPanel-handle{
  opacity: 1;
  z-index: 10;
  transition: opacity 100ms linear;
}

.editor-transcriptPanel-draggableGutter[data-chunk-dragging='true']
.editor-transcriptPanel-draggableContainer[data-dragging-state="true"]
.editor-transcriptPanel-handle{
  opacity: 1;
  transition: opacity 0ms linear;
}


.editor-transcriptPanel-handle{
  

  width: 22px;
  height: 24px;

  // background: $tempr;

  border-radius: 3px;

  margin-left: 3px;
  margin-top: 2px;

  //border-radius: 4px;

  //margin-left: 7px;
  //margin-top: 4.5px;

  cursor: grab;
  position: relative;
}

.editor-transcriptPanel-dropdownTrigger{
  position: absolute;
  width: 20px;
  height: 24px;

  left: 18px;
  top: 4px;

  pointer-events: none;
}

.editor-transcriptPanel-handle svg{
  width: 14px;
  height: 14px;
  fill: white;
  opacity: 0.25;
  //transform: scale(0.9);
}
//

.editor-transcriptPanel-handle-tooltipContainer{
  position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  // left: 100%;
  // margin-left: 2px;


  
  left: 0px;
  margin-left: -6px;

  width: 110px;
  height: 40px;
  

  pointer-events: none;
  // background: $tempr;
  opacity: 0;
  transition: opacity 50ms linear;
}

.editor-transcriptPanel-handle-tooltipContainer[data-position='below']{
  margin-top: 2px;
  top: 100%;
}

.editor-transcriptPanel-handle-tooltipContainer[data-position='above']{
  margin-top: -2px;
  bottom: 100%;
}

.editor-transcriptPanel-draggableGutter[data-chunk-longDelayedEnd-dragging='false']
.editor-transcriptPanel-draggableContainer[data-dropdown-open='false'][data-dragging-state='false']
.editor-transcriptPanel-handle:hover
.editor-transcriptPanel-handle-tooltipContainer{
  opacity: 1;
  transition-delay: 400ms;
}

//

.editor-transcriptPanel-draggableGutter[data-chunk-dragging='false']
.editor-transcriptPanel-handle:hover,
.editor-transcriptPanel-draggableContainer[data-dropdown-open='true']
.editor-transcriptPanel-handle,
.editor-transcriptPanel-draggableGutter[data-chunk-dragging='true']
.editor-transcriptPanel-draggableContainer[data-dragging-state="true"]
.editor-transcriptPanel-handle{
  opacity: 1;
  background: var(--250gray);
  z-index: 100;
}


.editor-transcriptPanel-draggableGutter[data-chunk-dragging='false']
.editor-transcriptPanel-handle:hover svg,
.editor-transcriptPanel-draggableContainer[data-dropdown-open='true']
.editor-transcriptPanel-handle svg,
.editor-transcriptPanel-draggableGutter[data-chunk-dragging='true']
.editor-transcriptPanel-draggableContainer[data-dragging-state="true"]
.editor-transcriptPanel-handle svg{
  opacity: 0.75;
}

.editor-transcriptPanel-draggableGutter[data-chunk-dragging="true"]
.editor-transcriptPanel-draggableContainer[data-chunk-dragging-state="false"]
.editor-transcriptPanel-handle{
  opacity: 0.5 !important;
}

//


.editor-transcriptPanel-draggableCover{
  position: absolute;
  z-index: 8;
  background: rgb(30,30,31);
  
  transition: opacity 100ms linear;
}

.editor-transcriptPanel-draggableCover[data-visible='true']{
  opacity: 0.5;
}

.editor-transcriptPanel-draggableCover[data-visible='false']{
  opacity: 0;
}


.editor-transcriptPanel-draggablePreview{
  position: absolute;
  left: 0px;
  width: 412px; // messy
  top: 0px;

  padding-left: 34px;
  margin-top: 0px;

  pointer-events: none;
  // background: $tempr;
  //color: red !important;
  white-space: pre-wrap;

  opacity: 0;
}

.editor-transcriptPanel-draggableContainer[data-dragging-state='true']
.editor-transcriptPanel-draggablePreview{
  opacity: 1;
}


//
// LINE

.editor-transcriptPanel-draggableLine{
  position: absolute;
  left: 32px;
  right: 10px;
  height: 2px;
  background: red;
  border-radius: 1px;
  background: var(--primary500);
  opacity: 0;
  z-index: 20;

  pointer-events: none !important;
}

.editor-transcriptPanel-draggableLine[data-closest-line='true']{
  opacity: 1;
}

//
// TOOLTIP 

.editor-transcriptPanel-draggableContainer-tooltip{
  position: absolute;
  bottom: 28px;
  left: 27.5px;
  //left: 34px;
  width: 200px;

  display: flex;
  align-items: center;

  font-size: 13px;
  color: var(--text500);
  font-variation-settings: "wght" 410;
  letter-spacing: 0.014em;

  pointer-events: none;
  user-select: none;

  height: 22px;
  
}

.editor-transcriptPanel-draggableContainer-tooltip-iconContainer{
  width: 22px;
  height: 22px;

  margin-left: -2px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-transcriptPanel-draggableContainer-tooltip-iconContainer svg{
  width: 13px;
  height: 13px;
  fill: var(--text500);
  position: relative;
  top: 0.5px;
}

.editor-transcriptPanel-draggableContainer-tooltip{
  opacity: 0;
  transform: translateX(-3px);
  transition: all 75ms linear;
}

.editor-transcriptPanel-list
.editor-transcriptPanel-draggableContainer-tooltip[data-visible='true']{
  opacity: 0.65;
  transform: translateX(0px);
}


.editor-transcriptPanel-draggableContainer-actionBar{
  position: absolute;
  bottom: 20px;
  bottom: 24px;
  left: 38px;  
  width: 280px;

  display: flex;
  align-items: center;

  margin-left: -11.5px;
  margin-left: -20.5px;
  // margin-left: -10px;
  
  user-select: none;

  display: none; // temp
}

.editor-transcriptPanel-draggableContainer-actionBar{
  opacity: 0;
  // transform: translateX(-3px);
  transition: all 75ms linear;
}

// .editor-transcriptPanel-list:hover
.editor-transcriptPanel-draggableContainer-actionBar[data-visible='true']{
  opacity: 1;
  // transform: translateX(0px);
  //transition: all 150ms linear;
}

.editor-transcriptPanel-draggableGutter[data-chunk-dragging='true']
.editor-transcriptPanel-draggableContainer-actionBar{
  opacity: 0 !important
}




.editor-transcriptPanel-draggableContainer-actionBar-btn{
  padding-left: 2px;
  padding-right: 8px;

  height: 22px;
  height: 24px;

  font-size: 12.75px;
  color: var(--text500);
  font-variation-settings: "wght" 420;
  letter-spacing: 0.012em;

  opacity: 0.6;
  border-radius: 3px;
  
}

.editor-transcriptPanel-draggableContainer-actionBar-btn:hover{
  opacity: 1;
  background: var(--251gray);
}



.editor-transcriptPanel-draggableContainer-actionBar-btn-iconContainer{
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// .editor-transcriptPanel-draggableContainer-actionBar-btn:first-child
// .editor-transcriptPanel-draggableContainer-actionBar-btn-iconContainer{
//   width: 20px;
//   margin-left: 1px;
// }

.editor-transcriptPanel-draggableContainer-actionBar-btn-iconContainer svg{
  width: 14px;
  height: 14px;
  fill: var(--text500);
  //transform: scale(1.04);
}

.editor-transcriptPanel-draggableContainer-actionBar-btn-iconContainer svg.icon--name--enterKey{
  transform: scaleX(-100%);
}

.editor-transcriptPanel-draggableContainer-actionBar-btn-iconContainer svg.icon--name--hash{
  transform: scale(0.85);
  position: relative;
  top: -0.5px;
}