.selectVoiceDropdown{
	margin-top: 4px;
	
	box-sizing: border-box;
	padding: 0px;
	padding-top: 4px;
	//padding-bottom: 12px;
	
	overflow: none;

	background: var(--bg-dropdown);

	border-radius: 4px;

	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);
	
	z-index: 500;

	position: relative;

	padding-top: 0px;
	//width: 466px;
  width: 390px;
	//width: 466px;
	display: flex;
	flex-direction: column;

	border: 1px solid rgba(255,255,255,0.055) !important;	
	//background: rgba(26,26,27,0.8);
	background: lighten(rgb(30,30,32), 1.5%);
	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.24);
	// backdrop-filter: blur(15px);
}

//
//

.selectVoiceDropdown-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  box-sizing: border-box;
  padding-left: 18px; 
  padding-top: 14px;

  padding-right: 67px;

  cursor: default;
  user-select: none;
}

.selectVoiceDropdown-header-title{
  font-size: 13.75px;
  font-variation-settings: "wght" 520;
  letter-spacing: 0.008em;

  color: var(--text500);
}

.selectVoiceDropdown-header-description{
  font-size: 12px;
  font-variation-settings: "wght" 400;
  letter-spacing: 0.015em;

  color: var(--text500);
  opacity: 0.75;
  line-height: 1.23;

  margin-top: 3px;
}

.selectVoiceDropdown-header-bar{
  width: 40px;
  height: 3px;
  border-radius: 3px;
  background: var(--text500);
  opacity: 0.08;

  margin-top: 10px;
  margin-bottom: 4px;
}

.selectVoiceDropdown-noHeaderVSpacer{
  height: 6px;
}


.selectVoiceDropdown-closeBtnFixedContainer{
  position: absolute;
  top: 0px;
  height: 40px;  
  right: 0px;
  z-index: 20;
}

.selectVoiceDropdown-header-closeBtn{
  position: absolute;
  top: 0px;
  right: 0px;

  width: 132px;
  // background: $tempr;
  //width: 184px;
  height: 100%;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-right: 2px;
  // padding-bottom: 2px;

  font-size: 14px;
  letter-spacing: 0.02em;
  margin-bottom: 0px;

  color: var(--text500);
  
  font-variation-settings: "wght" 440;
  opacity: 0.4;
  transition: all 50ms linear;
}

.selectVoiceDropdown-closeBtnFixedContainer
.selectVoiceDropdown-header-closeBtn{
  padding-right: 11px;
  padding-top: 2px;
}

.selectVoiceDropdown-header-closeBtn:hover{
  opacity: 0.9;
}

.selectVoiceDropdown-header-closeBtn svg{
  width: 16px;
  height: 16px;
  fill: var(--text500);
  opacity: 0.5;
  transform: scale(1.06);
}

.selectVoiceDropdown-header-closeBtn-inner{
  width: 32px;
  height: 28px;
  border-radius: 5px;
  //background: rgba(255,255,255,0.06);

  display: flex;
  align-items: center;
  justify-content: center;
}

.selectVoiceDropdown-header:hover
.selectVoiceDropdown-closeBtn svg{
  opacity: 0.6;
}

//


.selectVoiceDropdown-actionBar{
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 5px;	
  margin-bottom: 2px;
}

.selectVoiceDropdown-actionBar-langToggleBtn,
.selectVoiceDropdown-actionBar-speedToggleBtn{
	
  opacity: 0.6;

  padding-left: 8px;
  padding-right: 8px;

  height: 26px;
  //background: $tempr;
  border-radius: 4px;

}



.selectVoiceDropdown-actionBar-speedToggleBtn[data-state='open']{
  background: var(--250gray);
  opacity: 1;
}


.selectVoiceDropdown-actionBar-langToggleBtn-label,
.selectVoiceDropdown-actionBar-speedToggleBtn-label{
	font-size: 12.75px;
	font-variation-settings: "wght" 480;
  letter-spacing: 0.01em;

  color: var(--text500);
}


.selectVoiceDropdown-gridContainer-sectionHeader-langToggleTab-label,
.selectVoiceDropdown-gridContainer-sectionHeader{
	font-size: 13px;
	font-variation-settings: "wght" 540;
  letter-spacing: 0.01em;

  color: var(--text500);
}

.selectVoiceDropdown-actionBar-langToggleBtn:hover,
.selectVoiceDropdown-actionBar-speedToggleBtn:hover{
	background: var(--251gray);
	opacity: 0.85;
}



.selectVoiceDropdown-actionBar-langToggleBtn{
	justify-content: flex-start;
	width: 76px;
}

.selectVoiceDropdown-actionBar-speedToggleBtn{
	
}

.selectVoiceDropdown-actionBar-langToggleBtn-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;

  flex-grow: 0;
  flex-shrink: 0;
	width: 20px;
	height: 20px;
	margin-left: -5px;
  margin-right: 1px;

}

.selectVoiceDropdown-actionBar-langToggleBtn[data-global-state='true']
.selectVoiceDropdown-actionBar-langToggleBtn-iconContainer{
  //margin-left: -4.5px;
  margin-right: 2px;
}

.selectVoiceDropdown-actionBar-langToggleBtn-iconContainer svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.75;
  transform: scale(0.95);
}



.selectVoiceDropdown-actionBar-hSpacer{
	flex-grow: 1;
	flex-shrink: 1;
}



//

.selectVoiceDropdown-gridContainer{
	max-height: min(640px, calc(100vh - 383px));
	display: flex;
	flex-direction: column;
	overflow-y: overlay;
	scrollbar-gutter: stable;
	padding-bottom: 14px;
}

.selectVoiceDropdown-gridContainer-customBottomSpacer{
	height: 12px;
	flex-grow: 0;
	flex-shrink: 0;
}

.selectVoiceDropdown-gridContainer-customTopSpacer{
	height: 12px;
	flex-grow: 0;
	flex-shrink: 0;
}

.selectVoiceDropdown-gridContainer-sectionHeader{
	position: sticky;
	top: 0px;	

	display: flex;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
	padding-left: 10px;
	min-height: 36px;
	// background: red;
	z-index: 10;
	background: lighten(rgb(30,30,31), 2%);
}

.selectVoiceDropdown-gridContainer-sectionHeader--custom{
  min-height: 32px;  
}

.selectVoiceDropdown-gridContainer-sectionHeader-tabs{
	display: flex;
}

.selectVoiceDropdown-gridContainer-sectionHeader-langToggleTab{
	padding-left: 8px;
	padding-right: 8px;
	height: 28px;

	position: relative;

}

.selectVoiceDropdown-gridContainer-sectionHeader-langToggleTab-label{
	opacity: 0.6;
}

.selectVoiceDropdown-gridContainer-sectionHeader-langToggleTab[data-state='active']
.selectVoiceDropdown-gridContainer-sectionHeader-langToggleTab-label{
	opacity: 0.9;
}

.selectVoiceDropdown-gridContainer-sectionHeader-langToggleTab-bottomBorder{
	position: absolute;
  bottom: 0px;
  left: 4px;
  right: 4px;
  height: 1.5px;
  background: var(--primary500);
  //background: var(--line600);
  opacity: 0;
}


.selectVoiceDropdown-gridContainer-sectionHeader-langToggleTab[data-state='active']
.selectVoiceDropdown-gridContainer-sectionHeader-langToggleTab-bottomBorder{
	opacity: 1
}


.selectVoiceDropdown-gridContainer-sectionHeader-title{
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 8px;
	user-select: none;
	opacity: 0.6;
}

.selectVoiceDropdown-gridContainer-sectionHeader--custom
.selectVoiceDropdown-gridContainer-sectionHeader-title{
  position: relative;
  bottom: -4px;
}
//
//
//


.selectVoiceDropdown-grid{
	display: flex;
	flex-direction: column;
}

.selectVoiceDropdown-grid-header{
	
  margin-bottom: 0px;
  padding-top: 16px;
  padding-bottom: 5px;
  padding-left: 17px;
  
  user-select: none;
  font-size: 12.25px;
	font-variation-settings: "wght" 520;
  letter-spacing: 0.01em;

  color: var(--text500);
  opacity: 0.6;
}

.selectVoiceDropdown-gridContainer--hasCustomVoices
.selectVoiceDropdown-grid-header{
	font-size: 12.25px;
	font-variation-settings: "wght" 480;
}



.selectVoiceDropdown-gridContainer
.selectVoiceDropdown-grid--global:first-child
.selectVoiceDropdown-grid-header{
	padding-top: 12px;
}

.selectVoiceDropdown-gridContainer--hasCustomVoices
.selectVoiceDropdown-grid--global:nth-child(6)
.selectVoiceDropdown-grid-header{
	padding-top: 6px;
	display: none;
}


.selectVoiceDropdown-grid-grid{
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;  
  box-sizing: border-box;
  padding-left: 2px;

}

//

.selectVoiceDropdown-grid-item{
	//width: 220px;
  width: 183px;
  margin-left: 4px;

  //background: var(--bg-dropdown);
  height: 27px;
  box-sizing: border-box;
  padding: 0px;
  //padding-left: 20px;
  //font-size: 15px;
  padding-left: 0px;
  font-size: 13.5px;
  letter-spacing: 0.01em;
  margin-bottom: 0px;

  color: var(--text500);
  
  font-variation-settings: "wght" 440;

  line-height: 1.7;
  
  user-select: none;

  display: flex;
  position: relative;
  justify-content: flex-start;


  cursor: pointer;

  border: none;
  outline: none;
  box-shadow: none;

  border-radius: 3px;
}



.selectVoiceDropdown-grid-item:hover:not(:has(.selectVoiceDropdown-grid-item-sampleBtn:hover)){
  background: var(--251gray);
  color: var(--text600);
  //color: $white;

  border: none;
  outline: none;
  box-shadow: none;
}



.selectVoiceDropdown-grid-item[data-state="active"],
.selectVoiceDropdown-grid-item[data-state="active"]:hover{
  background: var(--250gray);
}

.dropdownMenu--selectVoice-grid
.selectVoiceDropdown-grid-item{
  width: calc(50% - 8px);
  height: 32px;
}


.selectVoiceDropdown-grid-item-noSampleBtnSpacer{
	width: 9px;
}




.selectVoiceDropdown-grid-item-sampleBtn{
  padding-left: 3px;
  height: 100%;
  
  //background: var(--250gray);

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 1px;
  cursor: pointer;
}

.selectVoiceDropdown-grid-item-sampleBtn-inner{
	width: 28px;
  height: 24px;

  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;
}



.selectVoiceDropdown-grid-item-sampleBtn svg{
  
  fill: white;
  opacity: 0.2;
  position: relative;
}

.selectVoiceDropdown-grid-item-sampleBtn[data-playing-state='true'] svg{
  width: 15px;
  height: 15px;
}

.selectVoiceDropdown-grid-item-sampleBtn[data-playing-state='false'] svg{
  width: 18px;
  height: 18px;
  right: -1px;
}

.selectVoiceDropdown-grid-item-sampleBtn:hover
.selectVoiceDropdown-grid-item-sampleBtn-inner,
.selectVoiceDropdown-grid-item-sampleBtn[data-playing-state='true']
.selectVoiceDropdown-grid-item-sampleBtn-inner{
  background: var(--248gray);
}


.selectVoiceDropdown-grid-item[data-state="active"]
.selectVoiceDropdown-grid-item-sampleBtn svg{
  opacity: 0.6;
}

.selectVoiceDropdown-grid-item-sampleBtn:hover svg,
.selectVoiceDropdown-grid-item-sampleBtn[data-playing-state='true'] svg,
.selectVoiceDropdown-grid-item[data-state="active"] [data-playing-state='true'] svg{
  opacity: 1;
}



.selectVoiceDropdown-grid-item-name{
  // width: 100%;
  margin-left: 3px;
}

.selectVoiceDropdown-grid-item-sub{
  font-variation-settings: "wght" 410;
  opacity: 0.7;
  margin-left: 6px;
  opacity: 0;
  transform: translateX(-2px);
  transition: all 50ms linear;
}

.selectVoiceDropdown-grid-item:hover
.selectVoiceDropdown-grid-item-sub,
.selectVoiceDropdown-grid-item[data-state="active"]
.selectVoiceDropdown-grid-item-sub,
.selectVoiceDropdown-gridContainer--globalLanguages
.selectVoiceDropdown-grid-item-sub{
	opacity: 0.6;
	transform: translateX(0px);
	transition-delay: 50ms;
}

.selectVoiceDropdown-gridContainer--globalLanguages
.selectVoiceDropdown-grid-item-sub{
  transition: all 0ms linear;
  transition-delay: 0ms;
}


.selectVoiceDropdown-grid-item-description{
  font-variation-settings: "wght" 410;
  opacity: 0.75;
  margin-left: 4px;
}

// .selectVoiceDropdown-grid-item-countryFlagIconContainer{
//   margin-left: 4px;
//   width: 30px;
//   height: 30px;
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
// }

// .selectVoiceDropdown-grid-item-countryFlagIconContainer svg{
//   width: 14px;
//   height: 14px;

//   position: relative;
//   top: 2px;
// }

//




.selectVoiceDropdown-footer{
	height: 32px;
	width: 100%;
  box-sizing: border-box;
  padding-left: 8px;
  padding-bottom: 8px;

	// background: $tempr;

	display: flex;
  align-items: center;
}


//

.dropdownMenu--selectVoice-voPlaybackRate{
  display: flex;  

  margin: 0 auto;
  box-sizing: border-box;

  align-items: center;

  width: calc(100% - 14px - 14px);
  margin-left: 14px;
  
  padding-top: 10px;
  padding-bottom: 2px;

  // padding-left: 16px;
  // padding-right: 16px;
  // // padding-left: 3px;
  // padding-right: 3px;
  // border-radius: 4px;

  margin-top: 11px;
  margin-bottom: 3px;

  //background: $tempr;
  //background: rgba(255,255,255,0.03);

  border-top: 1px solid var(--line100);

}

.dropdownMenu--selectVoice-voPlaybackRate-title{
  font-size: 14px;
  letter-spacing: 0.01em;
  color: var(--text500);
  font-variation-settings: "wght" 440;

  display: flex;
  align-items: center;

  height: 28px;

  cursor: default;

  // background: $tempr;
}

.dropdownMenu--selectVoice-voPlaybackRate-title-iconContainer{
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}

.dropdownMenu--selectVoice-voPlaybackRate-title-iconContainer svg{
  width: 16px;
  height: 16px;
  fill: var(--text500);
  opacity: 0.7;
}

.dropdownMenu--selectVoice-voPlaybackRate-buttonRow{
  margin-left: auto;


  //width: 100%;
  display: flex;
  // justify-content: center;
  opacity: 0.8;

}

.dropdownMenu--selectVoice-voPlaybackRate-buttonRow:hover{
  opacity: 1;
}


.dropdownMenu--selectVoiceDropdown-voPlaybackRateBtn{

  //width: 82px;

  //background: var(--bg-dropdown);
  height: 27px;
  box-sizing: border-box;
  

  padding-left: 14px;
  padding-right: 14px;
  
  margin-bottom: 0px;

  font-size: 14px;
  letter-spacing: 0.01em;
  color: var(--text500);
  font-variation-settings: "wght" 440;

  line-height: 1.8;
  
  user-select: none;

  display: flex;
  position: relative;

  cursor: pointer;

  border: none;
  outline: none;
  box-shadow: none;

  border-radius: 3px;

  opacity: 0.75;

}

.dropdownMenu--selectVoiceDropdown-voPlaybackRateBtn:hover{  
  background: var(--hoverBG-item-dropdown);
  color: var(--text600);  

  border: none;
  outline: none;
  box-shadow: none;

  opacity: 1;
}

.dropdownMenu--selectVoiceDropdown-voPlaybackRateBtn[data-state="active"]{
  background: var(--activeBG-suggestionItem) !important;
  opacity: 1;
}



.dropdownMenu--selectVoice-banner{
  width: calc(100% - 10px);
  margin-left: 5px;
  margin-top: 4px;

  background: $tempr;

  border-radius: 4px;

  background: lighten(rgb(33,33,37), 3%);

  padding-top: 4px;
  padding-bottom: 4px;

  cursor: default;
  user-select: none;
}


.dropdownMenu--selectVoice-banner-primary{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.dropdownMenu--selectVoice-banner-primary-iconContainer{
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  margin-right: 1px;
}

.dropdownMenu--selectVoice-banner-primary-iconContainer svg{
  width: 18px;
  height: 18px;
  fill: white;
  opacity: 0.5;
}

.dropdownMenu--selectVoice-banner-primary-label,
.dropdownMenu--selectVoice-banner-primary-sub{  
  font-size: 14px;
  letter-spacing: 0.01em;
  margin-bottom: 0px;

  color: var(--text500);
  
  font-variation-settings: "wght" 480;
  opacity: 0.75;
}

.dropdownMenu--selectVoice-banner-primary-sub{
  opacity: 0.4;
  margin-left: 3px;
}