//
//



.libraryPage-projects-grid{	
  position: relative;
  // flex-wrap: wrap;
  width: calc(100% + 20px);
  margin-left: -10px;
  // justify-content: flex-start;

  // position: relative;
  // background: $tempr;
}

.libraryPage-projects-group-measurer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
}

//


.libraryPage-projectContainer{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

}

.libraryPage-project--loading .libraryPage-project-previewOuterContainer,
.libraryPage-project--loading .libraryPage-project-footerContainer,
.libraryPage-project--locked .libraryPage-project-previewOuterContainer,
.libraryPage-project--locked .libraryPage-project-footerContainer{
	pointer-events: none;
}

.libraryPage-project-previewOuterContainer{
	cursor: pointer;
	position: relative;
	border-radius: 3px;
	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.4);
}



.libraryPage-project-previewInnerContainer{
	transform-origin: top left;
	position: absolute;
	top: 0px;
	left: 0px;
	overflow: hidden;
	will-change: transform;
}

@import 'Library-ProjectItem-Preview';



.libraryPage-projects-item-preview-duration{
	position: absolute;
	bottom: 8px;
	right: 8px;

	display: flex;
	align-items: center;
	justify-content: center;

	height: 22px;
	padding-right: 6px;
	padding-left: 6px;

	background: rgba(0,0,0,0.25);
	color: var(--text500);
	font-weight: $fw-medium;
	border-radius: 2px;

	font-size: 13.5px;
}

.libraryPage-projects-item-preview:hover{
	background: rgba(255,255,255,0.15);
}


//
//

.libraryPage-project-footerContainer{
	// background: red;
}

@import 'Library-ProjectItem-Footer';


//
// ITEM UI

.libraryPage-project-previewUI{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	z-index: 5;

	//background: $tempr;
}

.libraryPage-project-previewUI-spinnerContainer{
	position: absolute;

	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0px;
	width: 100%;
	height: 100%;

	box-sizing: border-box;
	padding-bottom: 2px;
}

.libraryPage-project-previewUI-spinner{
	height: 36px;
	width: 36px;
	
  border-radius: 36px;

  border: 4px var(--line100) solid;
  border-top: 4px var(--line600) solid;

  opacity: 1;

  animation: spinnerRotate 1.5s infinite linear;
}

//

.libraryPage-project-previewUI-lockedBannerContainer{
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: -1px;

	width: 100%;
	height: calc(100% + 1px);

	background: rgba(0,0,1,0.75);

	display: flex;
	align-items: center;
	justify-content: center;

	// display: flex;
	// align-items: flex-end;
	// justify-content: flex-start;

	box-sizing: border-box;
	padding-right: 4px;
	padding-bottom: 4px;
}

.libraryPage-project-previewUI-lockedBanner{
	// min-width: 100px;
	// padding-left: 10px;
	// padding-right: 10px;
	// border-radius: 4px;

	// height: 30px;



	// border: 1px solid rgba(255,255,255,0.08);

	// background: rgba(0,0,20,0.75);	
  // backdrop-filter: blur(5px);

  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.libraryPage-project-previewUI-lockedBanner-avatar{

	//margin-left: -10px;

	//background: white;
	width: 48px;
	height: 48px;
	border-radius: 50%;

	//margin-right: 8px;

	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);

	object-fit: contain;
	overflow: hidden;
}

.libraryPage-project-previewUI-lockedBanner-avatar img{
	width: 100%;
	height: 100%;
}

.libraryPage-project-previewUI-lockedBanner-avatar svg{
	width: 48px;
	height: 48px;
	fill: var(--text300);
}

.libraryPage-project-previewUI-lockedBanner-label{
	position: absolute;
	left: 0px;
	width: 100%;
	top: 100%;

	margin-top: 10px;
	text-align: center;
	
	font-size: 14.5px;
	font-weight: $fw-medium;
	color: var(--text400);

	//font-family: system-ui, sans-serif;
	font-variation-settings: "wght" 440;
	letter-spacing: 0.008em;

	opacity: 0;
	transform: translateY(-3px);

	transition: all 75ms ease-in-out;

	transition-delay: 0ms;
}

.libraryPage-project--locked:hover
.libraryPage-project-previewUI-lockedBanner-label{
	opacity: 1;
	transform: translateY(0px);
	transition: all 100ms ease-in-out;

	transition-delay: 0ms;
}

.libraryPage-project-previewUI-lockedBanner-name{
	color: var(--text500);
	font-variation-settings: "wght" 480;
	text-transform: capitalize;
}

