.videoPlayer-prePlayControls{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	z-index: 10;
}

.videoPlayer-prePlayControls-playBtn{
	position: absolute;
	left: $videoPlayerControlsInset;
	bottom: $videoPlayerControlsInset;

	display: flex;
	align-items: center;
	justify-content: center;

	background: $tempr;

	background: rgba(0,0,0,0.5);
	backdrop-filter: blur(25px) saturate(110%);

	border-radius: 4px;

	height: 28px;
	padding-left: 8px;
	padding-right: 8px;
}

.videoPlayer-prePlayControls-playBtn-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	width: 20px;
	height: 20px;

	margin-left: -4px;
}

.videoPlayer-prePlayControls-playBtn-iconContainer svg{
	height: 11px;
	width: 11px;

	fill: var(--text500);
}

.videoPlayer-prePlayControls-playBtn-label{

	font-size: 16px;

  font-variation-settings: "wght" 540;
  letter-spacing: 0.012em;

  color: var(--text600);

}