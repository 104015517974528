
.editor-timeline-clip-segment{
  position: absolute;
  top: $topPadding-clip; // dirty, accounting for outline 
  height: $segmentHeight;

  z-index: 800;
  
  user-select: none;

  color: $invisible;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
}

.editor-timeline-clip-segmentBounds{
  position: absolute;
  top: $topPadding-clip; // dirty, accounting for outline 

  z-index: 10;

  background: yellow;
  
  height: 70px;

  opacity: 0;
}

.editor-timeline-clip-segmentBounds[data-dev-mode='true']{
  opacity: 0.2;
}

// .editor-timeline-clip-segment:hover{
//   background: red !important;
// }

.editor-timeline-clip-segment--cameraVoiceover--hideText{
  padding-left: 0px;
  padding-right: 0px;
}

// .editor-timeline-clip-segment--cameraVoiceover{
//   outline: 1px solid red;
// }


//
// INTERIOR BUTTON

.editor-timeline-clip-segment-button{
  position: absolute;
  left: 2px;
  right: 2px;
  width: calc(100% - 4px);
  height: 100%;
  background: rgba(255,255,255,0.08);
  border-radius: 2px;

  overflow: hidden;

  justify-content: flex-start;

  box-sizing: border-box;
  padding-left: 6px;
  padding-right: 4px;
}

.editor-timeline-clip-segment-button:hover{
  background: rgba(255,255,255,0.12);
}

.editor-timeline-clip-segment-button-label{
  font-size: 13.5px;
  font-weight: $fw-medium;
  color: white;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}


//
// HANDLE

.editor-timeline-clip-segmentHandle{
  pointer-events: auto;

  position: absolute;
  width: 14px;
  top: 0px;
  height: 100%;  
  
  cursor: grab;    

  z-index: 50;

  display: flex;
  align-items: center;
}

.editor-timeline-clip-segmentHandle--start{
  left: -4px;
  
  justify-content: flex-end;

  box-sizing: border-box;
  padding-right: 4px;
}


.editor-timeline-clip-segmentHandle--end{
  right: -4px;

  // margin-right: -4px;
  justify-content: flex-start;

  box-sizing: border-box;
  padding-left: 4px;
}

.editor-timeline-clip-segmentHandle-bar{
  width: 2px;
  height: calc(100% - 4px);
  background: $white;
  border-radius: 2px;

  opacity: 0.1;
  transition: opacity 50ms linear;
}

.editor-timeline-clip:hover
.editor-timeline-clip-segment
.editor-timeline-clip-segmentHandle-bar{
  opacity: 0.2;
}

// .editor-timeline-clip:hover
// .editor-timeline-clip-segment:hover
// .editor-timeline-clip-segmentHandle-bar{
//   opacity: 0.4;
// }

.editor-timeline-clip:hover
.editor-timeline-clip-segment:hover
.editor-timeline-clip-segmentHandle-bar:hover{
  opacity: 0.8;
}