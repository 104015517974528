.recordWebcamWindowOverlay{
	position: fixed;
	z-index: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;

	background: rgba(0,0,0,0.7);
}

$width-webcamWindow: 840px;

.recordWebcamWindow{
	position: fixed;
	width: $width-webcamWindow;
	height: 480px;
	height: 560px;
	
	left: 50%;
	top: 4px;
	transform: translateX(-50%);
	//background: red;
	border: none;
	background: rgba(10,10,11,0.95);
	box-shadow: 
		0px 6px 12px 0px rgba(0,0,0,0.28),
		0px 12px 24px 0px rgba(0,0,0,0.20),
		0px 24px 48px 0px rgba(0,0,0,0.12),
	;
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	border-radius: 8px;
}
.recordWebcamWindow:focus{
	outline: none;
}


$webcamWindow-padding: 10px;
$webcamWindow-toolbarHeight: 90px;
$webcamWindow-microphoneHeight: 0px;
$webcamWindow-panelBG: rgb(25,25,26);

.recordWebcamWindow-mainContainer{
	position: absolute;
	top: 0px;
	bottom: calc(#{$webcamWindow-toolbarHeight} + #{$webcamWindow-microphoneHeight}) ; 	
	left: 0px;
	width: 100%;

	box-sizing: border-box;
	padding-left: $webcamWindow-padding;
	padding-bottom: $webcamWindow-padding;
	padding-top: $webcamWindow-padding;
	padding-right: $webcamWindow-padding;

	-webkit-app-region: drag;

}

// .recordWebcamWindow-microphoneContainer{	
// 	position: absolute;
// 	bottom: $webcamWindow-toolbarHeight;
// 	height: $webcamWindow-microphoneHeight;
// 	left: 0px;
// 	width: 100%;

// 	box-sizing: border-box;

// 	padding-left: $webcamWindow-padding;
// 	padding-right: $webcamWindow-padding;
// 	padding-bottom: $webcamWindow-padding;

// }

.recordWebcamWindow-toolbarContainer{
	position: absolute;
	bottom: 0px;
	height: $webcamWindow-toolbarHeight;
	left: 0px;
	width: 100%;

	box-sizing: border-box;

	padding-left: $webcamWindow-padding;
	padding-right: $webcamWindow-padding;
	padding-bottom: $webcamWindow-padding;

	-webkit-app-region: drag;
}

.recordWebcamWindow-main{
	width: 100%;
	height: 100%;
	border-radius: 8px;

	background: $webcamWindow-panelBG;
	position: relative;

	-webkit-app-region: no-drag;
}


//
// CLEAN

.recordWebcamWindow-main--clean-preview-videoContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

video.recordWebcamWindow-main--clean-preview-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

//
// INSTRUCTIONS


.recordWebcamWindow-main--instructions{
	display: flex;
	background: none;
}

.recordWebcamWindow-main--instructions-panel{
	background: $webcamWindow-panelBG;
	flex-grow: 1;
	flex-shrink: 1;
	// background: $tempr;
	height: 100%;
	border-radius: 8px;
	position: relative;
}

.recordWebcamWindow-main--instructions-panel-title{
	position: absolute;
  left: 38px;
  top: 50px;

  color: var(--text500);
  font-size: 16px;
  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 520;
  letter-spacing: 0.014em;  
  opacity: 0.55;

  user-select: none;
  cursor: default;
}

.recordWebcamWindow-main--instructions-panel-innerContainer{
  position: absolute;
  color: var(--text500);
}

.recordWebcamWindow-main--instructions-panel-innerText{
  width: 100%;
  height: 100%;
  white-space: pre-wrap;  
  cursor: default;
}



.recordWebcamWindow-main--instructions-hSpacer{
	flex-grow: 0;
  flex-shrink: 0;
  width: $webcamWindow-padding;
}

.recordWebcamWindow-main--instructions-preview-videoContainer{
  width: 40%;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

video.recordWebcamWindow-main--instructions-preview-video{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

//
//

.recordWebcamWindow-main--script-preview-videoContainer{
	position: fixed;
	// background: $tempr;
	background: rgba(24,24,24);
	cursor: pointer;
	border-radius: 4px;
	overflow: hidden;
	-webkit-app-region: no-drag;

	z-index: 500;
}

.recordWebcamWindow-main--script-preview-videoContainer[data-expanded-state='expanded']{
	width: 820px;
	height: 530px;

	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.recordWebcamWindow-main--script-preview-videoContainer[data-expanded-state='default']{
	
	height: calc(#{$webcamWindow-toolbarHeight} - (18px * 2) + #{$webcamWindow-padding});
	aspect-ratio: 16/9;
	
	bottom: 18px;
	right: 18px;	

}

.recordWebcamWindow-main--script-preview-videoContainer[data-expanded-state='default']:hover{
	transform: scale(1.04);
}


video.recordWebcamWindow-main--script-preview-video{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}


.recordWebcamWindow-main--script-preview-videoContainer[data-visibility-state='hidden']{
  opacity: 0;
  transition: opacity 75ms linear;
  transition-delay: 100ms;
}

.recordWebcamWindow-main--script-preview-videoContainer[data-visibility-state='visible']{
  opacity: 1;
  transition: opacity 150ms linear;
  transition-delay: 200ms;
}



@import "RecordWebcam-Toolbar.scss";
@import "RecordWebcam-Preview.scss";
@import "RecordWebcam-Tele.scss";