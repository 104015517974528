
// BG OPTIONS
// background: rgb(217,216,216);

:root{		
	
}

[data-theme="dark"], .forceDarkTheme{	
	--collection-blue: #72A8FF;
	--collection-purple: #A688FF;
	--collection-red: #F17A7A;
	--collection-orange: #FFAF88;
	--collection-green: #6DB98F;	
	--collection-pink: #F88DC2;
	--collection-teal: #7EDEFF;
	--collection-darkBlue: #4B8DF6;

}