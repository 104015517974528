$width-timeline-sidePanel: 16px;
$height-timeline-footer: 30px;
$height-timeline-timeline: 20px;
$height-toolbar: 44px;

$width-leftEdgeSpacer: 0px;
$width-centerRightContainer: 295px;
$width-centerLeftContainer: 410px;
//$width-centerLeftContainer: 440px;
$height-copilotInputContainer: 38px;
$width-detailPanelContainer: 258px;

$width-PMPanel: 500px;

$panelBorder: rgb(40,40,50);
$panelBackground: rgb(28,29,33);

.editor{
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  
  background: darken(rgb(28,29,33), 3%);
  // background: red;
}

.editor:focus{
  outline: none;
}

.editor:focus{
  outline: none;
}

.editor--showPMPanel{
  width: calc(100% - #{$width-PMPanel});
}

.editor[data-prevent-selection='true'],
.editor[data-prevent-selection='true'] *{
  user-select: none !important;
  cursor: grabbing !important;
}

.editor[data-dragging-slide-editor-spacer='right'],
.editor[data-dragging-slide-editor-spacer='right'] *{
  user-select: none !important;
  cursor: ew-resize;  
}

.editor[data-dragging-slide-editor-spacer='bottom'],
.editor[data-dragging-slide-editor-spacer='bottom'] *{
  user-select: none !important;
  cursor: ns-resize;
}


//


.editor-backgroundClick{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  bottom: 240px;
  
  z-index: 1;
}


//

.editor-projectIDDev{
  position: fixed;
  z-index: 9999;
  bottom: 14px;
  left: 100px;
  width: 200px;  
  color: white;
}

.editor-PMPanelContainer{
  position: fixed;
  top: $height-tabBar;
  right: 0px;
  bottom: 0px;
  width: $width-PMPanel;
  background: var(--bg-editor);

  z-index: 500;
}

.editor-PMPanel{
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  border-radius: 5px;
  border: 1px solid var(--line200);

  overflow-y: scroll;

  background: var(--bg-editorToolbar);

  color: var(--text500);

  box-sizing: border-box;
  padding-left: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 12px;

  font-size: 13.5px;

}

//

.editor-tabBarSpacer{
  height: $height-tabBar;
  width: 100%;
}

//

.editor-centerContainer{  
  display: flex;
  align-items: center;
  justify-content: center;

  //box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.10);

  position: relative;
  z-index: 40;
}

//


// 


.editor-center-showTranscriptPanelBtn{
  position: fixed;
  top: $height-tabBar;
  left: 0px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 10px;
  padding-right: 24px;

  transition: opacity 50ms linear;

  z-index: 100;
}

.editor-center-showTranscriptPanelBtn{
  opacity: 0.5;
}

.editor-center-showTranscriptPanelBtn:hover{
  opacity: 1;
}

.editor-center-showTranscriptPanelBtn-iconContainer{
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 4px;

}

.editor-center-showTranscriptPanelBtn-iconContainer svg{
  width: 16px;
  height: 16px;
  fill: white;
}

.editor-center-showTranscriptPanelBtn-label{
  font-size: 13.25px;
  //font-size: 14.25px;
  font-weight: $fw-medium;
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.010em;  
}


$panelBorder: rgb(40,40,50);
$panelBackground: rgb(28,29,33);

.editor-center{
  display: flex;
  width: 100%;
}

.editor-center-leftEdgeSpacer{
  width: $width-leftEdgeSpacer;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;

  border-right: 1px solid $panelBorder;
  height: 100%;
  background: $panelBackground;
}


.editor-center-rightPanelBackBtn{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50px;
  height: 100%;

  z-index: 50;
  background: $tempr;
  background: lighten(rgb(28,29,33), 1%);
  

  display: flex;
  align-items: flex-start;
  justify-content: center;

  border-top: 1px solid $panelBorder;
  border-bottom: 1px solid $panelBorder;

  box-sizing: border-box;
  height: calc(100% + 1px);
  // padding: 6px;
  
}

.editor-center-rightPanelBackBtn:hover{
  background: lighten(rgb(28,29,33), 2%);
}

.editor-center-rightPanelBackBtn-inner{
  width: 100%;
  height: 100%;

  // border-radius: 4px;  

  box-sizing: border-box;
  padding-top: 17px;
}

.editor-center-rightPanelBackBtn svg{
  width: 19px;
  height: 19px;

  // width: 20px;
  // height: 20px;

  fill: white;
  opacity: 0.25;

  //transform: scaleX(-100%);
}

.editor-center-rightPanelBackBtn:hover svg{
  opacity: 0.5;
}


.editor-center-rightPanelContainer{
  position: absolute;
  top: 0px;
  left: 50px;  

  z-index: 52;

  width: calc(100% - 50px);
  height: calc(100% - 1px);


  border: 1px solid $panelBorder;
  border-left: 1px solid rgba(50,50,60,0.5);
  // border-left: 40px solid rgba(28,29,33,0.2);
  // margin-left: -40px;
  
  background: $panelBackground;
}

.editor-center-rightPanelContainer--hide{
  opacity: 0;
  pointer-events: none;
}

.editor-center-leftContainer{
  width: $width-centerLeftContainer;
  flex-grow: 0;
  flex-shrink: 0;

  //border-right: 1px solid $panelBorder;
  height: 100%;
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
}

.editor-center-panelContainer{
  position: relative;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;

  background: $panelBackground;
}

.editor[data-read-only='true'] .editor-center-panelContainer,
.editor[data-read-only='true'] .editor-center-panelContainer *{
  pointer-events: none !important;
}

.editor-center-panelContainer{
  opacity: 1;  
  transition: opacity 150ms linear;
}

.editor[data-read-only='true'] .editor-center-panelContainer{
  opacity: 0.75;
}

.editor-center-copilotInputContainer{
  height: $height-copilotInputContainer;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;

  border-top: 1px solid $panelBorder;
  background: $panelBackground;
}

.editor-center-center{
  min-width: 10px;
  flex-grow: 1;
  flex-shrink: 1;

  height: 100%;
  
  display: flex;
  flex-direction: column;
  padding-left: 4px;
  padding-right: 4px;
}

.editor-center-center-topbarContainer{
  flex-grow: 0;
  flex-shrink: 0;

  padding-top: 0px;
  box-sizing: border-box;
  height: 38px;
  width: 100%;

  //border-bottom: 1px solid $panelBorder;
  // background: var(--bg-canvasContainer);
  //border-bottom: 1px solid darken(rgb(50,50,60), 6%);
  // background: $panelBackground; 
  //background: darken(rgb(28,29,33), 2%);


  position: relative;
  z-index: 80;
}

.editor-topBar{
  pointer-events: auto;
}


.editor-center-center-topbarContainer[data-transcriptpanel-state='hidden']{
  padding-left: 50px;
}

.editor-center-center-mainContainer{
  flex-grow: 1;
  flex-shrink: 1;

  min-height: 10px;

  display: flex;
  position: relative;
  box-sizing: border-box;
  // padding-right: 6px;
  padding-right: 6px;
}

.editor-center-center-mainContainer-shortcutsPopoverTrigger{
  position: absolute;
  top: 50%;
  right: 0px;
  height: 1px;
  width: 1px;
  background: none;
  //background: red;
}

.editor-center-center-canvasOuterOuterContainer{
  flex-grow: 1;
  flex-shrink: 1;
  
  min-width: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  box-sizing: border-box;
  padding: 0px;
}

.editor[data-read-only='true'] .editor-center-center-canvasOuterOuterContainer,
.editor[data-read-only='true'] .editor-center-center-canvasOuterOuterContainer *{
  pointer-events: none !important;
}


.editor-center-center-canvasOuterOuterContainer-guide{
  position: relative;

  border-radius: 2px;
  z-index: 2;
  // overflow: hidden; // temp add back in

}

.editor-center-center-canvasOuterContainer{  
  
}


 $canvasOuterGutter: 4px;


.editor-center-center-canvasContainer{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  transform-origin: top left;
}


.editor-center-center-canvasInnerContainer{
  width: 100%;
  height: 100%;
  // background: $tempr;
  background: rgba(255,255,255,0.05);
  border-radius: 5px;
  overflow: hidden;
}

.editor-center-center-canvasMeasurer{
  position: absolute;
  top: 0px;
  left: $canvasOuterGutter;
  right: $canvasOuterGutter;
  bottom: 0;
  width: calc(100% - #{$canvasOuterGutter} - #{$canvasOuterGutter});
  height: 100%;

  pointer-events: none;
}


.editor-center-center-detailPanelContainer{
  width: $width-detailPanelContainer;
  height: 100%;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 2;
}

//
//

.editor-center-center-templateBarContainer{
  flex-grow: 0;
  flex-shrink: 0;

  width: 100%;
  height: 57px;

  display: flex;
  position: relative;
  z-index: 2;
  pointer-events: none; // overwritten later, for bg click
}

@import 'Editor-TemplateBar';

.editor-center-center-templateBarContainer-edgeContainer{
  width: $width-detailPanelContainer;
  height: 100%;

  flex-grow: 0;
  flex-shrink: 0;
}

.editor-center-center-toolbarContainer{
  flex-grow: 0;
  flex-shrink: 0;

  height: $height-toolbar;
  width: 100%;

  //border-top: 1px solid $panelBorder;
  //border-top: 1px solid darken(rgb(50,50,60), 6%);
  background: $panelBackground; 
  //background: darken(rgb(28,29,33), 2%);
  // background: var(--bg-canvasContainer);

  position: relative;

  z-index: 50;
  z-index: 150;

  position: relative;
}

.editor-devPanel{
  position: fixed;
  top: calc(#{$height-toolbar} + 4px);
  bottom: 0px;
  right: 0px;
  width: 430px;

  background: $panelBackground;
  border-left: 1px solid $panelBorder;
  z-index: 99999;

  overflow-y: overlay;
  font-size: 12.25px;
}


@import 'Editor-TrimBar';
@import 'Editor-TrimVideoPlayer';

@import 'Editor-CanvasZoomUI';
@import 'Editor-CanvasMediaUI';

@import 'Editor-Topbar';
@import 'Editor-Preview';
@import 'Editor-PropertiesBar';
@import 'Editor-MinTimeline';

@import 'Editor-SelectBGMusicPopover';

@import 'Editor-StatusBar';
@import 'Editor-VariantPopover';

@import 'editorDetailPanel/EditorDetailPanel';

// RESIZE HANDLE

.editor-timelineResize{
  z-index: 250;
  background: $invisible;
  //visibility: hidden;
  margin-top: -10px; // hacky
  cursor: row-resize !important;
}

.tempResizeThing{
  position: fixed;
  z-index: 200;
  top: 20px;
  right: 20px;
  width: 100px;
  height: 50px;

  background: red;
  color: white;
}

.exampletopbutton{
  position: fixed;
  top: 0px;
  left: 200px;
  height: 40px;
  background: $tempr;

  z-index: 50;
}

//

//


.editor-previewZoomControls{
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;

  width: 100%;
  height: 100%;

  z-index: 800;
}

.editor-previewZoomControls-bg{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

   z-index: 800;

  background: rgb(0,0,0,0.9);
}


.editor--isDragResizingMedia .editor-toolbar,
.editor--isDragResizingMedia .editor-timelinePanel,
.editor--isDragResizingMedia .editor-center-leftContainer,
.editor--isDragResizingMedia .editor-timelineResize{
  pointer-events: none !important;
}

.editor--isDragResizingMedia{
  cursor: grabbing !important;
}

.editor--isDragResizingNumberInput,
.editor--isDragResizingNumberInput *{
  cursor: ew-resize !important;
  user-select: none !important;
}

.editor--isDragResizingMedia .editor-toolbar,
.editor--isDragResizingMedia .editor-timelinePanel,
.editor--isDragResizingMedia .editor-center-leftContainer,
.editor--isDragResizingMedia .editor-timelineResize{
  pointer-events: none !important
}


//

@import 'Editor-Loading';
@import 'Editor-WorkflowPopover';
@import 'Editor-GeneratePopover';
@import 'Editor-GenerationsDialog';
@import 'Editor-ReadOnlyToggle';
@import 'Editor-InsertLauncher';
