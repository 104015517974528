.editor-detailPanel--webcam-carousel{	
	height: $height-dpElement;
	height: 28px;
	border-radius: 5px;

	width: calc(100% + 16px);
	margin-left: -8px;
	margin-bottom: -8px;
	padding-bottom: 8px;

	display: flex;
	align-items: center;

	//background: lighten(rgb(30,31,33), 2%); // sorry
	// overflow: hidden;
}

.editor-detailPanel--webcam-carousel-navBtn{
	// background: $tempb;
	width: 32px;
	height: 100%;

	border-radius: 3px;

	opacity: 0;
	transition: all 150ms linear;
}

.editor-detailPanel--webcam-carousel:hover
.editor-detailPanel--webcam-carousel-navBtn{
	opacity: 0.5;
	transition: all 50ms linear;
}

.editor-detailPanel--webcam-carousel-navBtn svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
}

.editor-detailPanel--webcam-carousel:hover
.editor-detailPanel--webcam-carousel-navBtn:hover{
	background: lighten(rgb(30,31,33), 5%); // sorry
	opacity: 1;
	transition: all 50ms linear;
}

.editor-detailPanel--webcam-carousel-navBtn--prev{
	transform: scaleX(-100%);
}

.editor-detailPanel--webcam-carousel-dotsContainer{
	display: flex;

	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.editor-detailPanel--webcam-carousel-dot{
	min-width: 5px;
	border-radius: 3px;
	
	flex-grow: 1;
	flex-shrink: 1;

	max-width: 16px;

	height: 100%;
}



.editor-detailPanel--webcam-carousel-dot-dot{
	width: 5px;
	height: 5px;
	border-radius: 50%;

	background: white;
}

button.editor-detailPanel--webcam-carousel-dot[data-active-state='active']{
	opacity: 0.8;
}

button.editor-detailPanel--webcam-carousel-dot[data-active-state='active']:hover{
	opacity: 1;
	background: lighten(rgb(30,31,33), 5%);
}

button.editor-detailPanel--webcam-carousel-dot[data-active-state='inactive']{
	opacity: 0.25;
}

button.editor-detailPanel--webcam-carousel-dot[data-active-state='inactive']:hover{
	opacity: 0.7;	
	background: lighten(rgb(30,31,33), 5%);
}