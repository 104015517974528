
@font-face {
    font-family: 'YHeebo';        
    src: url(../fonts/YHeebo/YHeebo-Light.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-light;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YHeebo';        
    src: url(../fonts/YHeebo/YHeebo-Regular.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-regular;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YHeebo';        
    src: url(../fonts/YHeebo/YHeebo-Medium.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-medium;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YHeebo';        
    src: url(../fonts/YHeebo/YHeebo-Medium.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-semibold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YHeebo';        
    src: url(../fonts/YHeebo/YHeebo-Bold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YHeebo';        
    src: url(../fonts/YHeebo/YHeebo-Bold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-heavy;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YHeebo';        
    src: url(../fonts/YHeebo/YHeebo-Black.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-black;
    text-rendering: optimizeLegibility;
}
