.cursorGame{
	position: absolute;
	top: $height-tabBar;
	top: 0px;
	left: 0px;
	width: 100%;
	height: calc(100% - #{$height-tabBar});
	height: 100%;
	background: rgb(50,0,0);
}

.cursorGame-outerBorder{
	position: absolute;
	top: 20px;
	left: 20px;
	bottom: 20px;
	right: 20px;

	border: 1px solid rgba(255,0,0,0.5);
	border-radius: 2px;
	background: rgb(4,4,5);
	z-index: 1;
}

.cursorGame-dimensions{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	pointer-events: none;

	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 10px;

	z-index: 10;

	font-family: 'SF Mono' !important;
	font-weight: 500;
	color: var(--text500);
	opacity: 0.15;
	opacity: 0;

	font-size: 18px;


}

.cursorGame-stopwatch{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	pointer-events: none;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding-top: 40px;
	column-gap: 10px;

	z-index: 10;

	font-family: 'SF Mono' !important;
	font-weight: 500;
	color: var(--text500);
	opacity: 0.8;

	font-size: 20px;
}

.cursorGame-fakeCursor{
	width: 40px;
	height: 40px;
	
	outline: 2px solid green;

	z-index: 500;
	transform: translateX(-50%) translateY(-50%);
	border-radius: 50%;

	pointer-events: none;

}

.cursorGame-button{
	
	background: red;
	z-index: 20;
	// border-radius: 50%;
	opacity: 0.2;

	z-index: 20;
}

.cursorGame-button:hover{
	opacity: 1;
}

.cursorGame-button--coordinate{
	position: absolute;

	text-align: left;
	display: flex;

	color: var(--text500);
	font-family: 'SF Mono' !important;

	font-size: 12px;	

	opacity: 0;

	pointer-events: none;
}

.cursorGame-button:hover
.cursorGame-button--coordinate{
	//opacity: 1;
}

.cursorGame-hoverAreas{
	//background: rgba(0,0,255,0.15);
	background: rgba(0,0,255,0.3);
	border-radius: 50%;

	z-index: 50;
}

.cursorGame-hoverAreas:hover{
	background: rgba(0,0,255,1);
}

.cursorGame-videoBG{
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: $tempb;	
	object-fit: cover;
}

.cursorGame-videoBG video{
	width: 100%;
	height: 100%;
	object-fit: cover;

	opacity: 0.25;
}