.variantPopover{
	margin-top: 4px;
	
	box-sizing: border-box;
	padding: 0px;
	padding-top: 16px;
	padding-left: 16px;
	padding-right: 16px;
	padding-bottom: 18px;
	//padding-bottom: 12px;
	
	overflow: none;

	background: var(--bg-dropdown);

	border-radius: 4px;

	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);
	
	z-index: 500;

	position: relative;

	width: 400px;

	display: flex;
	flex-direction: column;

	border: 1px solid rgba(255,255,255,0.055) !important;	
	//background: rgba(26,26,27,0.8);
	background: lighten(rgb(30,30,32), 1.5%);
	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.24);
	// backdrop-filter: blur(15px);

  color: var(--text500);
}


//

.variantPopover-sectionHeader,
.variantPopover-sectionHeaderTabs-tab{
	font-size: 14.5px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;

  cursor: default;
  user-select: none;

}

//

$variantDarkBlue: #303B5F;
$variantBrightBlue: lighten(#67A1FF, 5%);

$variantDarkBlueHover: saturate(#303B5F, 5%);
$variantBrightBlueHover: lighten(#67A1FF, 10%);

.variantPopover-sectionHeader{
	display: flex;
}

.variantPopover-sectionDescription{
	font-size: 13.5px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.012em;

  opacity: 0.5;
  margin-top: 3px;
  margin-bottom: 3px;

  cursor: default;
  user-select: none;
}

.variantPopover-sectionHeader-hSpacer{
	flex-grow: 1;
	flex-shrink: 1;
}

.variantPopover-sectionHeader-variableCountPill{
	height: 20px;
	width: 25px;
	position: relative;
	top: -2px;
	border-radius: 3px;
	

	background: var(--248gray);
	color: var(--text400);

	display: flex;
	align-items: center;
	justify-content: center;
}

//


.variantPopover-variableList{
	min-height: 160px;
	margin-top: 5px;
}

.variantPopover-variableRow{
	width: 100%;
	display: flex;
	align-items: center;

	height: 30px;
	cursor: default;
}

.variantPopover-variableRow-checkmarkContainer{
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background: desaturate(lighten(#235443, 4%), 12%);

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 7px;

	flex-grow: 0;
	flex-shrink: 0;
	user-select: none;
}


.variantPopover-variableRow-checkmarkContainer svg{
	position: relative;
	// left: 1px;
	top: 0.5px;

	pointer-events: none;

	width: 12px;
	height: 12px;
	stroke: #7BFFB7;
	opacity: 1;
	stroke-width: 10px;
}


.variantPopover-variableRow-variableName{
	text-align: left;
	flex-grow: 1;
	flex-shrink: 1;

	font-size: 14px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;
}

.variantPopover-variableRow-variableType{
	width: 130px;
	flex-grow: 0;
	flex-shrink: 0;
	user-select: none;

	font-size: 14px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;

  opacity: 0.6;
}

//

.variantPopover-divider{
	width: 100%;
	height: 1px;
	background: var(--text500);
	opacity: 0.05;
	margin-bottom: 16px;
}

//

.variantPopover-linkUrl{
	width: calc(100% + 8px);
	margin-left: -4px;
	height: 116px;

	margin-top: 8px;

	position: relative;

	margin-bottom: 16px;
}


//


.variantPopover-sectionHeaderTabs{
	display: flex;
	width: calc(100% + 10px);
	margin-left: -5px;
	margin-bottom: 4px;
}

.variantPopover-sectionHeaderTabs-tab{
	padding-left: 6px;
  padding-right: 6px;

  height: 28px;

  border-radius: 3px;
  position: relative;
  cursor: pointer;

}

.variantPopover-sectionHeaderTabs-tab:hover{
  background: var(--250gray); 
}

.variantPopover-sectionHeaderTabs-tab[data-active-state="inactive"]{
  opacity: 0.5;
}

.variantPopover-sectionHeaderTabs-tab[data-active-state="inactive"]:hover{
  opacity: 1;
}

.variantPopover-sectionHeaderTabs-tab-bottomBorder{
	position: absolute;
  bottom: 0px;
  left: 4px;
  right: 4px;
  height: 1.5px;
  background: var(--primary500);
  opacity: 0;	
}


.variantPopover-sectionHeaderTabs-tab[data-active-state="active"]
.variantPopover-sectionHeaderTabs-tab-bottomBorder{
	opacity: 1;
}

.variantPopover-sectionHeaderCopyArea{
	width: calc(100% + 8px);
	margin-left: -4px;	
	height: 165px;
	margin-top: 6px;

	position: relative;
}

.variantPopover-formulaArea{	
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;

	background: var(--252gray);
	border-radius: 3px;
}

.variantPopover-formulaArea-formula{
	font-size: 13.75px;
	font-weight: $fw-medium;
	color: var(--text500);

	position: absolute;
	left: 10px;
	right: 10px;
	top: 8px;
	height: 105px;

	font-family: monospace;   
  font-variation-settings: "wght" 440;
  letter-spacing: -0.011em;

  line-height: 1.3;

    /* Line clamping for Chrome */
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-all;
  overflow-wrap: break-word;
  hyphens: none;
}

.variantPopover-linkUrl
.variantPopover-formulaArea-formula{
	height: 56px;
	-webkit-line-clamp: 3;
}

.variantPopover-formulaArea-copyToClipboardBtn{
	position: absolute;
	bottom: 10px;
	right: 10px;

	padding-right: 10px;
	padding-left: 10px;

	height: 27px;
	border-radius: 4px;

	background: $variantDarkBlue;
	color: $variantBrightBlue;

	font-size: 14px;
	font-weight: $fw-medium;

	font-variation-settings: "wght" 480;
	letter-spacing: 0.008em;
	
}

.variantPopover-formulaArea-copyToClipboardBtn-tooltipContainer{
	position: absolute;
	bottom: 100%;
	left: 0px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.variantPopover-formulaArea-copyToClipboardBtn-tooltip{
  
  background: darken($variantDarkBlue, 5%);
  color: $variantBrightBlue;

  background: rgb(50,50,55);
  color: white;

  font-size: 13.5px;
  font-weight: $fw-medium;
  font-variation-settings: "wght" 420;
  letter-spacing: 0.014em;  

  padding-left: 12px;
  padding-right: 12px;
  padding-top: 3px;
  padding-bottom: 3px;

  border-radius: 3px; 

  display: flex;
  align-items: center;
  justify-content: center;
}

.variantPopover-formulaArea-copyToClipboardBtn:hover{
	background: $variantDarkBlueHover;
	color: $variantBrightBlueHover;	
}

//


