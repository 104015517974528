.addFromLibraryDialog-scenesMeasure{
	flex-grow: 0;
	flex-shrink: 0;
	width: 100%;

	padding-bottom: 28px;
}

.addFromLibraryDialog-scenesOuterContainer{
	width: 100%;
	position: relative;
}

.addFromLibraryDialog-scenesContainer{
	width: 100%;
	
	display: flex;

	box-sizing: border-box;
	
	position: relative;
}

.addFromLibraryDialog-scenesInnerContainer{
	display: flex;	
}


.addFromLibraryDialog-scenes-edgeButton{
	position: absolute;
	top: 0px;
	height: 100%;
	width: 50px;
	background: rgba(29,29,31, 0.75);

	transition: opacity 75ms linear;


}

.addFromLibraryDialog-scenes-edgeButton svg{
	width: 20px;
	height: 20px;
	fill: var(--text500);
	opacity: 0.75;
}

.addFromLibraryDialog-scenes-edgeButton[data-state-visible="true"]{
	opacity: 0.95;
}

.addFromLibraryDialog-scenes-edgeButton[data-state-visible="false"]{
	opacity: 0;
	pointer-events: none;
}

.addFromLibraryDialog-scenes-edgeButton[data-state-visible="true"]:hover{
	opacity: 0.65;
}

.addFromLibraryDialog-scenes-edgeButton[data-state-visible="true"]:hover svg{
	opacity: 1;
}

.addFromLibraryDialog-scenes-edgeButton--left{
	left: 0px;
}

.addFromLibraryDialog-scenes-edgeButton--left svg{
	transform: scaleX(-100%);
}

.addFromLibraryDialog-scenes-edgeButton--right{
	right: 0px;
}

//
//

.addFromLibraryDialog-scenes-scenePreview{
	display: flex;
	flex-direction: column;	

	box-sizing: border-box;

	padding-top: 8px;
	padding-left: 8px;
	padding-right: 8px;
	background: rgba(255,255,255,0.03);
	border-radius: 6px;

	cursor: pointer;
}

.addFromLibraryDialog-scenes-scenePreview:hover{
	background: rgba(255,255,255,0.04);
}

.addFromLibraryDialog-scenes-scenePreview-preview{
	width: 100%;
	aspect-ratio: 16/9;
	background: #4584EA;

	flex-grow: 0;
	flex-shrink: 0;

	border-radius: 3px;

}

.addFromLibraryDialog-scenes-scenePreview-footer{
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;
	padding-left: 4px;

	padding-bottom: 2px;
}

.addFromLibraryDialog-scenes-scenePreview-footer-iconContainer{
	width: 22px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 6px;
}

.addFromLibraryDialog-scenes-scenePreview-footer-iconContainer svg{
	width: 18px;
	height: 18px;

	fill: var(--text500);
	opacity: 0.3;
}

.addFromLibraryDialog-scenes-scenePreview-footer-label{
	font-size: 15px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;

  user-select: none;
}