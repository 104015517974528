.popover--addMediaPopover-header-searchInputContainer{
	width: 100%;
	height: 32px;	
	position: relative;
}

input.popover--addMediaPopover-header-searchInput{
	background: var(--252gray);
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	box-sizing: border-box;
	padding-left: 28px;

	border-radius: 5px;
	border: 1px solid $invisible;
	color: var(--text600);

	font-size: 15px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;

  box-sizing: border-box;
  padding-bottom: 2px;
}

input.popover--addMediaPopover-header-searchInput:hover{
	background: var(--250gray);
}

input.popover--addMediaPopover-header-searchInput:focus{
	background: var(--248gray);
	outline: none;
}

input.popover--addMediaPopover-header-searchInput::placeholder{
	color: rgba(255,255,255,0.4);
}

.popover--addMediaPopover-header-leftSearchUI{
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 28px;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	z-index: 5;
	pointer-events: none;
}

.popover--addMediaPopover-header-leftSearchUI-iconContainer{
	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.popover--addMediaPopover-header-leftSearchUI-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: white;
	opacity: 0.5;
	transform: scale(1.04);
}

input.popover--addMediaPopover-header-searchInput:hover + 
.popover--addMediaPopover-header-leftSearchUI
.popover--addMediaPopover-header-leftSearchUI-iconContainer svg{
	opacity: 0.7;
}

input.popover--addMediaPopover-header-searchInput:focus + 
.popover--addMediaPopover-header-leftSearchUI
.popover--addMediaPopover-header-leftSearchUI-iconContainer svg{
	opacity: 0.8;
}

.popover--addMediaPopover-header-rightSearchUI{
	position: absolute;
	top: 0px;
	height: 100%;
	right: 0px;
	z-index: 10;

	display: flex;
	padding-right: 3px;
	// background: $tempb;
}

.popover--addMediaPopover-header-rightSearchUI-prefillSearchBtn{
	height: calc(100% - 2px);
	// margin-left: -1px;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding-right: 2px;
	padding-left: 2px;
	padding-top: 3px;
	padding-bottom: 1px;

	transition: all 50ms linear;
}

.popover--addMediaPopover-header-searchInputContainer--empty
.popover--addMediaPopover-header-rightSearchUI-prefillSearchBtn{
	opacity: 1;
	transform: translateX(1px);
}

.popover--addMediaPopover-header-searchInputContainer--notEmpty
.popover--addMediaPopover-header-rightSearchUI-prefillSearchBtn{
	opacity: 0;
	transform: translateX(0px);
}

.popover--addMediaPopover-header-rightSearchUI-prefillSearchBtn-inner{
	width: 100%;
	height: 100%;

	padding-left: 9px;
	padding-right: 9px;

	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;


  background: var(--251gray);
}

.popover--addMediaPopover-header-rightSearchUI-prefillSearchBtn-inner-label{
	font-size: 13.5px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;

  opacity: 0.6;
}

.popover--addMediaPopover-header-rightSearchUI-prefillSearchBtn-inner:hover{
	background: var(--250gray);
}

.popover--addMediaPopover-header:hover
.popover--addMediaPopover-header-rightSearchUI-prefillSearchBtn:hover
.popover--addMediaPopover-header-rightSearchUI-prefillSearchBtn-inner-label{
	opacity: 1;
}


//

.popover--addMediaPopover-header-rightSearchUI-searchBtn{
	
	height: calc(100% - 2px);
	margin-left: -1px;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding-right: 3px;
	padding-top: 3px;
	padding-bottom: 1px;

	transition: all 50ms linear;
}

.popover--addMediaPopover-header-searchInputContainer--empty
.popover--addMediaPopover-header-rightSearchUI-searchBtn{
	opacity: 0;
	transform: translateX(1px);
}

.popover--addMediaPopover-header-searchInputContainer--notEmpty
.popover--addMediaPopover-header-rightSearchUI-searchBtn{
	opacity: 1;
	transform: translateX(0px);
}

.popover--addMediaPopover-header-rightSearchUI-searchBtn-inner{
	width: 100%;
	height: 100%;

	padding-left: 12px;
	padding-right: 12px;

	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.popover--addMediaPopover-header-rightSearchUI-searchBtn-inner:hover{
	background: var(--250gray);
}

.popover--addMediaPopover-header-rightSearchUI-label{	
	font-size: 13.5px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;

  margin-right: 4px;
  opacity: 0.5;
}

.popover--addMediaPopover-header-rightSearchUI-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;
}

.popover--addMediaPopover-header-rightSearchUI-iconContainer svg{
	width: 16px;
	height: 16px;
	opacity: 0.5;
	fill: var(--text500);
}