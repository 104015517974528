
.editor-detailPanel--slide-animationPanel-header{
	display: flex;
	align-items: center;	
	padding-top: 14px;
	padding-bottom: 6px;

	width: calc(100% + 0px); // for button overhang
}

.editor-detailPanel--slide-animationPanel-header-title{
	margin-left: 2px;	

	font-size: 13.25px;	
	color: var(--text500);
	// opacity: 0.9;
	
  font-variation-settings: "wght" 480;
  font-variation-settings: "wght" 520;
  letter-spacing: 0.008em;
  user-select: none;
  cursor: default;
}

.editor-detailPanel--slide-animationPanel-list{
	width: calc(100% + 2px);
	margin-left: -3px;
	position: relative;
	cursor: pointer;
}	

//
//

$dragHandleWidth-animationPanelItem: 34px;
$delayInputWidth-animationPanelItem: 42px;

.editor-detailPanel--slide-animationPanel-item{
	
	width: 100%;	
	cursor: pointer;

	box-sizing: border-box;
	padding-top: 2px;
	padding-bottom: 2px;	

	position: relative;

}

.editor-detailPanel--slide-animationPanel-item-inner{
	height: 100%;
	width: 100%;
	background: rgb(33,33,34);
	border: 1px solid rgb(38,38,41);
	border: 1px solid lighten(rgb(38,38,41), 1%);
	border-radius: 3px;
	display: flex;
	align-items: center;

	box-sizing: border-box;
}

.editor-detailPanel--slide-animationPanel-item[data-menu-open='true']
.editor-detailPanel--slide-animationPanel-item-inner{
	background: lighten(rgb(32,32,33), 2%);
	border: 1px solid lighten(rgb(38,38,41), 5%);
}

.editor-detailPanel--slide-animationPanel-item-dragHandle{
	position: absolute;
	left: 0px;
	top: 0px;
	height: 100%;
	width: $dragHandleWidth-animationPanelItem;

	box-sizing: border-box;
	padding-left: 3px;

	z-index: 10;
	// background: $tempb;
	//position: relative;


}

.editor-detailPanel--slide-animationPanel-item-dragHandle-itemIconContainer,
.editor-detailPanel--slide-animationPanel-item-dragHandle-dragIconContainer{	
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	// background: $tempr;
	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-detailPanel--slide-animationPanel-item-dragHandle-itemIconContainer svg,
.editor-detailPanel--slide-animationPanel-item-dragHandle-dragIconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.5;

	flex-grow: 0;
	flex-shrink: 0;
}

.editor-detailPanel--slide-animationPanel-item-dragHandle-dragIconContainer svg{
	width: 12px;
	height: 12px;
}

.editor-detailPanel--slide-animationPanel-item-dragHandle-itemIconContainer svg{
	display: block;
}

.editor-detailPanel--slide-animationPanel-item-dragHandle-dragIconContainer svg{
	display: none;
}

.editor--isNotDragResizingNumberInput
.editor-detailPanel--slide-animationPanel-item-dragHandle:hover
.editor-detailPanel--slide-animationPanel-item-dragHandle-itemIconContainer svg,
.editor--isNotDragResizingNumberInput
.editor-detailPanel--slide-animationPanel-item[data-menu-open='true']
.editor-detailPanel--slide-animationPanel-item-dragHandle-itemIconContainer svg{
	display: none;
}

.editor--isNotDragResizingNumberInput
.editor-detailPanel--slide-animationPanel-item-dragHandle:hover
.editor-detailPanel--slide-animationPanel-item-dragHandle-dragIconContainer svg,
.editor--isNotDragResizingNumberInput
.editor-detailPanel--slide-animationPanel-item[data-menu-open='true']
.editor-detailPanel--slide-animationPanel-item-dragHandle-dragIconContainer svg{
	display: block;
}

.editor-detailPanel--slide-animationPanel-item-dragHandle:hover
.editor-detailPanel--slide-animationPanel-item-dragHandle-dragIconContainer svg{
	opacity: 0.8;
}

.editor-detailPanel--slide-animationPanel-item-dragHandle-itemIconContainer
svg.icon--name--chartDonut #bg{
	opacity: 0.25;
}

.editor-detailPanel--slide-animationPanel-item-content{
	position: absolute;
	left: $dragHandleWidth-animationPanelItem;
	top: 0px;
	height: 100%;
	bottom: 0px;
	right: 0px;

	display: flex;
	align-items: center;
	flex-direction: row;

	box-sizing: border-box;

}

.editor-detailPanel--slide-animationPanel-item-content[data-state='open']{
	pointer-events: none;
}

.editor-detailPanel--slide-animationPanel-item-content--type,
.editor-detailPanel--slide-animationPanel-item-content--type *{
	cursor: pointer !important;
}

.editor-detailPanel--slide-animationPanel-item-content--delay{
	//padding-bottom: 6px;
}

.editor-detailPanel--slide-animationPanel-item-label{
	
	
	// margin-left: 8px;	
	font-size: 12.75px;	
	color: var(--text500);
	opacity: 0.9;

	text-align: left;
	
  font-variation-settings: "wght" 480;  
  letter-spacing: 0.008em;
  user-select: none;
  cursor: default;

  flex-grow: 1;
  flex-shrink: 1;  

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  padding-right: 8px;
}

.editor-detailPanel--slide-animationPanel-item-content--delay
.editor-detailPanel--slide-animationPanel-item-label{
	position: relative;
	top: -3px;
}

.editor-detailPanel--slide-animationPanel-item-sub{
	font-size: 12.75px;	
	color: var(--text500);
	opacity: 0.6;

	text-align: left;
	
  font-variation-settings: "wght" 460;  
  letter-spacing: 0.008em;
  user-select: none;
  cursor: default;

  flex-grow: 0;
  flex-shrink: 0;  

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  padding-right: 8px;
  padding-left: 0px;

  display: none;
}

.editor-detailPanel:hover
.editor-detailPanel--slide-animationPanel-item-sub--animationType,
.editor-detailPanel
.editor-detailPanel--slide-animationPanel-item-sub--delay{
	display: flex;
}

.editor-detailPanel
.editor-detailPanel--slide-animationPanel-item-sub--delay{
	display: flex;
	position: relative;
	top: -3px;
	font-size: 12px;
	font-feature-settings: "ss01", "ss02";
}


.editor-detailPanel:hover
.editor-detailPanel--slide-animationPanel-item:hover
.editor-detailPanel--slide-animationPanel-item-sub--delayEmpty{
	display: flex;
}


.editor-detailPanel--slide-animationPanel-item-buttonTrigger{
	position: absolute;
	top: 10px;
	bottom: 9px;
	left: 0px;
	right: 5px;
	// background: $tempr;
	
}

.editor-detailPanel--slide-animationPanel-item-buttonTrigger * {
	visibility: hidden;
}

.editor-detailPanel--slide-animationPanel-list-dropLine {
  position: absolute;
  left: -5px;
  right: -6px;
  height: 2px;
  background: $focusBorderColor-dpElement;  
  z-index: 20;
  opacity: 0;
  border-radius: 1px;
}

.editor-detailPanel--slide-animationPanel-list-dropLine[data-active-state='true'] {
  opacity: 1;
}



.editor-detailPanel--slide-animationPanel-item-delayInputContainer{
	
	position: absolute;
	top: 0px;
	left: 0px;	
	height: 100%;
	width: calc(100% - 6px);

	
	user-select: none;

	display: flex;
	align-items: center;
	justify-content: center;
	
	z-index: 10;
}

.editor-detailPanel--slide-animationPanel-item-delayInput-sliderContainer{
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 4px;
	cursor: ew-resize;
}

.editor-detailPanel--slide-animationPanel-item-delayInput-sliderContainer .track{
	height: 2px;	
	border-radius: 0px;
	// border-top-right-radius: 0px;
	// border-bottom-right-radius: 0px;

	z-index: 5;

	position: relative;
	top: 25px;

	width: 100%;
	
	background: var(--text400);	
	
}

.editor-detailPanel--slide-animationPanel-item-delayInput-sliderContainer .track-0{
	height: 2px;
	border-top-left-radius: 1px;
	border-bottom-left-radius: 1px;	

	z-index: 5;
	
	background: var(--text400);	
}

.editor-detailPanel--slide-animationPanel-item-delayInputContainer[data-no-delay='true']
.track-0{
	opacity: 0;
}

.editor-detailPanel--slide-animationPanel-item-delayInput-sliderContainer .track-1{
	
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;

	border-top-right-radius: 1px;
	border-bottom-right-radius: 1px;

	background: blue;
	background: rgb(50,50,60);

}

// .editor-detailPanel--slide-animationPanel-item-delayInput{
// 	user-select: none;
	
// 	width: 100%;
// 	height: 100%;

// 	cursor: ew-resize;
	
// 	border-radius: 3px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
  
//   position: relative;
//   z-index: 20;
// }


// .editor-detailPanel--slide-animationPanel-item-delayInput[data-has-delay='false']{
// 	opacity: 0;
// }

// .editor-detailPanel--slide-animationPanel-item:hover
// .editor-detailPanel--slide-animationPanel-item-delayInput[data-has-delay='false']{
// 	opacity: 1;
// }

// .editor-detailPanel--slide-animationPanel-item-delayInput[data-has-delay='true']{
// 	background: var(--250gray);
// 	opacity: 1;
// }


// .editor-detailPanel--slide-animationPanel-item-delayInput-iconContainer{
// 	width: 16px;
// 	height: 16px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	// margin-left: -4px;
// 	// margin-right: 1px;
// }

// .editor-detailPanel--slide-animationPanel-item-delayInput-iconContainer svg{
// 	width: 14px;
// 	height: 14px;
// 	fill: var(--text500);
// 	transform: scale(1.2);
// 	opacity: 0.5;
// 	transform-origin: center center;
// 	position: relative;
// 	top: -0.5px;
// }

// .editor-detailPanel--slide-animationPanel-item-delayInput-label{
// 	font-size: 12px;	
// 	color: var(--text500);
// 	font-variation-settings: "wght" 480;
// 	opacity: 0.8;
// 	font-feature-settings: "tnum";
// 	letter-spacing: -0.018em;
// }