

.editor-preview-cameraContainer{
  position: absolute;
  z-index: 100;

  box-sizing: border-box;
}

.editor-preview-cameraInnerContainer{
  position: relative;
  width: 100%;
  height: 100%;
}

.editor-preview-camera{
  position: absolute;
  background: white;
  border-radius: 10px;
  //border: 6px solid rgba(0,0,0,0.12);
  //border: 6px solid white;
  outline: 1px solid rgba(0,0,0,0.12);


  z-index: 120;
  user-select: none;

  pointer-events: none;

  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.32);

  transform-origin: center center;
}

.editor-preview-camera--fullScreen{
  border-radius: 0px;
}

.editor-preview-fakeTouch{
  position: absolute;
  // background: $tempb;
  background: $invisible;

  z-index: 100;
  cursor: grab !important;
}

.editor-preview-cameraContainer--isDragging,
.editor-preview-cameraContainer--isDragging *,
.editor-preview-cameraContainer--isDragging .editor-preview-fakeTouch{
  cursor: grabbing !important;
}


//
//


.editor-preview-camera-buttonTrayContainer{
  position: absolute;
  left: 0px;
  bottom: 0px;
  transform-origin: bottom left;

  z-index: 50;
//

  pointer-events: auto;
    
  box-sizing: border-box;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 10px;
  

  // background: $tempb; 

  transition: opacity 100ms linear;
  transition-delay: 0ms;
  opacity: 0;
  // opacity: 1;

  cursor: pointer;
}

.editor-preview-cameraContainer--isDragging *
.editor-preview-camera-buttonTrayContainer{
  opacity: 0 !important;
  transition-delay: 0ms;
}


.editor-preview-camera-buttonTrayContainer:hover{
  // background: $tempr; 
  opacity: 1;
  transition-delay: 500ms;
}

.editor-preview-fakeTouch:hover ~ 
.editor-preview-camera
.editor-preview-camera-buttonTrayContainer{
  opacity: 1;
  transition-delay: 500ms;
}

.editor-preview-camera-buttonTray{
  width: 100%;
  display: flex;
  height: 100%;

  background: rgba(35,30,40,0.7);
  backdrop-filter: blur(10px);

  box-sizing: border-box;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 6px;
  padding-top: 6px;

  border-radius: 10px;
}


.editor-preview-camera-button{
  width: 33.3%;
  flex-grow: 0;
  flex-shrink: 0;

  height: 48px;

  border-radius: 6px;
}

$smallBorderRadius-cameraButton: 2px;

.editor-preview-camera-button:nth-child(1){
  border-top-right-radius: $smallBorderRadius-cameraButton;
  border-bottom-right-radius: $smallBorderRadius-cameraButton;
}

.editor-preview-camera-button:nth-child(2){
  border-radius: $smallBorderRadius-cameraButton;
}

.editor-preview-camera-button:nth-child(3){
  border-top-left-radius: $smallBorderRadius-cameraButton;
  border-bottom-left-radius: $smallBorderRadius-cameraButton;
}

.editor-preview-camera-button[data-state="active"]{
  background: rgba(255,255,255,0.12);
}

.editor-preview-camera-button[data-state="active"]:hover{
  background: rgba(255,255,255,0.3);
}

.editor-preview-camera-button[data-state="inactive"]{
  opacity: 0.5;
}



.editor-preview-camera-button[data-state="inactive"]:hover{
  opacity: 0.8;
  background: rgba(255,255,255,0.125);
}

.editor-preview-camera-button svg{
  width: 36px;
  height: 36px;
  fill: rgba(255,255,255,1);
}

.editor-preview-camera-button svg.icon--name--expandVideo{
  width: 28px;
  height: 28px;
}

.editor-preview-camera-button[data-state="active"] svg,
.editor-preview-camera-button:hover svg{
  transform: scale(1.1);
  transform-origin: center center;
}

.editor-preview-camera-videoOuterContainer{
  width: 100%;
  height: 100%;
  // overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}
.editor-preview-camera-videoInnerContainer{  
  position: relative;
}

.editor-preview-camera-video{
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
}

.editor-preview-camera-video video{
  object-fit: cover;
  border-radius: 10px;
}

.editor-preview-camera--fullScreen
.editor-preview-camera-video video{
  border-radius: 0px;
}