//$width-dpNumberInputLeft: 25px;

.dpTextInput{
	height: $height-dpElement;
	// // width is set in JS
	position: relative;

	background: var(--250gray);
	border-radius: 3px;

	box-sizing: border-box;
	border: 1px solid $invisible;
}

.dpTextInput:hover{
	background: rgba(244,240,255,0.065);
	border: 1px solid var(--248gray);
}

.dpTextInput[data-input-focused='true']{
	//background: rgba(244,240,255,0.065);
	border: 1px solid $focusBorderColor-dpElement;
}

.dpTextInput[data-disabled='true']{
	opacity: 0.5;
	pointer-events: none;
}


//


input.dpTextInput-input{
	position: absolute;
	left: 0px;
	right: 0px;	



	height: $height-dpElement;
  font-size: $baseFontSize-dpElement;	
	color: var(--text500);
	
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;

  border: none;
  outline: none;
  box-shadow: none;

  padding: 0px;
  background: none;

	padding-left: 8px;
	
  box-sizing: border-box;
  padding-bottom: 2.5px;
}

.editor-detailPanel-row--donutDataInput
.dpTextInput{
	height: 28px;
}

.editor-detailPanel-row--donutDataInput
input.dpTextInput-input{
	height: 28px;
	padding-bottom: 3.5px;
}