
:root{		
	--guestColabText: #FFA78E;
	--guestColabTextHover: #ffb5a0;
	--guestColabBG: 	#33221D;
	--guestColabBGHover: 	#392620;
	--guestColabLine: #ffa78e54;
	--guestColabLineHover: #ffa78e65;

	--apiText: #daa1ff;
	--apiTextHover: #e2b7ff;
	--apiBG: 	#312539;
	--apiBGHover: 	#3a2b44;
	--apiLine: #D595FF50;
	--apiLineHover: #D595FF65;

	--instructionsText: #FFA78E;
	--instructionsTextLight: #FFD3C6;
	--instructionsTextHover: #ffb5a0;
	--instructionsSoftBG: 	#292522;
	--instructionsSoftBGHover: 	rgba(59, 48, 41, 0.75);
	--instructionsLineLight: #292522;
	--instructionsBG: 	#33221D;
	--instructionsBGHover: 	#392620;
	--instructionsLine: #ffa78e54;
	--instructionsLineHover: #ffa78e65;
}