
//
//


.editor-detailPanel-spreadsheetContainer{
	width: 100%;
	height: 137px;
	position: relative;
	margin-left: 1px;
	margin-top: -4px;
	margin-bottom: 2px;

	// background: $tempr;

}

.editor-detailPanel-spreadsheet:focus,
.editor-detailPanel-spreadsheet:focus-within{
	outline: none;
	// outline: 4px solid red;
}

.editor-detailPanel-spreadsheet-rowStatesList{
	position: absolute;
	left: -3px;
	top: 0px;
}

.editor-detailPanel-spreadsheetRowBtn{
	width: 12px;
	// border-radius: 5px;
	border: 1px solid lighten(rgb(42,42,48), 10%);
	//border: 2px solid var(--bg-editorPanel);
	margin-bottom: -1px;
	// background: darken(rgb(42,42,48), 4%);	
	// background: darken(rgb(42,42,48), 2%);	
	position: relative;

	// background: $tempr;
}

.editor-detailPanel-spreadsheetRowBtn:hover{
	// background: lighten(rgb(42,42,48), 3%);
}



.editor-detailPanel-spreadsheetRowBtn
.editor-detailPanel-spreadsheetRowBtn--inner{
	position: absolute;
	right: 0px;
	left: 0px;
	top: 0px;
	bottom: 0px;
	// width: 0px;
	// height: 18px;

	border-radius: 0px;
}

.editor-detailPanel-spreadsheetRowBtn[data-highlight-status='default']
.editor-detailPanel-spreadsheetRowBtn--inner{
	opacity: 0.35;
}

.editor-detailPanel-spreadsheetRowBtn[data-highlight-status='default']:hover
.editor-detailPanel-spreadsheetRowBtn--inner{
	opacity: 0.40;
}

.editor-detailPanel-spreadsheetRowBtn[data-highlight-status='highlight']
.editor-detailPanel-spreadsheetRowBtn--inner{
	opacity: 0.7;
}

.editor-detailPanel-spreadsheetRowBtn[data-highlight-status='lowlight']
.editor-detailPanel-spreadsheetRowBtn--inner{
	opacity: 0.15;
}

.editor-detailPanel-spreadsheetRowBtn[data-highlight-status='lowlight']:hover
.editor-detailPanel-spreadsheetRowBtn--inner{
	opacity: 0.2;
}

.editor-detailPanel-spreadsheetRowBtn[data-above-max-row='true']{
	// opacity: 0.5;
	pointer-events: none;
}

.editor-detailPanel-spreadsheetRowBtn[data-above-max-row='true']
.editor-detailPanel-spreadsheetRowBtn--inner{
	opacity: 0.02;
}

.editor-detailPanel-spreadsheetRowBtn[data-above-max-row='true']{
	opacity: 0.3;
}



.editor-detailPanel-spreadsheetRowBtn svg{
	width: 22px;
	height: 22px;
	position: relative;	

	fill: white;
	opacity: 0.7;

	transform-origin: center center;
	transform: scale(0.95);
}

.editor-detailPanel-spreadsheetRowBtn--state--default svg{
	opacity: 0.15;
}


.editor-detailPanel-spreadsheetRowBtn:hover svg{
	opacity: 0.8;
}


//


.editor-detailPanel-spreadsheet{

	//width: 300px;
	//height: 180px;
	//background: $tempr;

	position: absolute;
	top: 0px;
	right: -5px;
	bottom: -5px;
	left: 9px;		

	display: flex;
	flex-direction: column;
	align-items: center;
}

.editor-detailPanel-spreadsheet-cellsContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	// background: $tempr;
}




.editor-detailPanel-spreadsheet-cell{
	border: 1px solid lighten(rgb(42,42,48), 10%);
	margin-bottom: -1px;
	margin-left: -1px;
	background: darken(rgb(42,42,48), 2%);	

	display: flex;
	align-items: center;
}

.editor-detailPanel-spreadsheet-cell--selected{
	z-index: 5;	
}

.editor-detailPanel-spreadsheet:focus
.editor-detailPanel-spreadsheet-cell--selected,
.editor-detailPanel-spreadsheet:focus-within
.editor-detailPanel-spreadsheet-cell--selected{
	z-index: 5;
	border: 1px solid rgb(91,154,255);
}

.editor-detailPanel-spreadsheet-cell--selected{	
	border: 1px solid var(--line600);
}




.editor-detailPanel-spreadsheet-cell--selected.editor-detailPanel-spreadsheet-cell--editing{
	z-index: 5;
	border: 1px solid lighten(rgb(91,154,255), 5%);
	outline: 1px solid rgba(91,154,255, 0.8);
}


.editor-detailPanel-spreadsheet-cell--unselected:hover{
	//border: 1px solid lighten(rgb(42,42,48), 14%);
	//background: darken(rgb(42,42,48), 0%);
}

.editor-detailPanel-spreadsheet-cell--unselected.editor-detailPanel-spreadsheet-cell--subSelected{	
	border: 1px solid rgba(71,105,160,0.7);
	z-index: 3;
}




.editor-detailPanel-spreadsheet-cell--subSelected{
	background: rgba(60,68,110,0.3);
}





// .editor-detailPanel-spreadsheet-cell--subSelectedHead{
// 	z-index: 10;
// 	background: $tempr !important;
// }


.editor-detailPanel-spreadsheet-cellInput{
	padding-top: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	padding-right: 0px;
	background: none;
	border: none;
	outline: none;
	box-shadow: none;
	background: rgba(255,255,255,0.08);
	z-index: 10;
}




.editor-detailPanel-spreadsheet-cellInput, .editor-detailPanel-spreadsheet-cell{
	font-size: 12.75px !important;
	color: white;

	box-sizing: border-box;
	padding-left: 7px;
	line-height: 1.1;

}

.editor-detailPanel-spreadsheet-cell{
	user-select: none !important;
}

.editor-detailPanel-spreadsheet-cellInput{	
	// padding-top: 2px;
}

input.editor-detailPanel-spreadsheet-cellInput::selection{
	background-color: #1B55B2 !important;
}

