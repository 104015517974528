
.editor-readOnlyToggleContainer{
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  cursor: pointer;
  padding-bottom: 5px;
  z-index: 20;

  box-sizing: border-box;
  padding-right: $width-centerRightContainer;
  // pointer-events: auto !important;
}

.editor-readOnlyToggle {
  display: inline-flex;
  align-items: center;
  // gap: 12px;
}

.editor-readOnlyToggle-lockedBanner-avatar{
  width: 24px;
  height: 24px;
  //background: $tempr;
  margin-right: 8px;
  border-radius: 4px;
}

.editor-readOnlyToggle-lockedBanner-avatar img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
}

.editor-readOnlyToggle-buttons{
  display: flex;
  gap: 8px;
  margin-left: 8px;
  pointer-events: auto !important;
}



.editor-readOnlyToggle-label,
.editor-readOnlyToggle-btn-label{
  font-size: 13.5px;
  font-weight: $fw-medium;
  color: var(--text500);
  
  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;
}

.editor-readOnlyToggle-label{
  margin-right: 16px;
  font-size: 14.5px;
  color: var(--text600);
  //font-variation-settings: "wght" 520;
}

.editor-readOnlyToggle-btn--refresh
.editor-readOnlyToggle-label{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-readOnlyToggle-label-name{
  color: var(--text600);
  // font-variation-settings: "wght" 480;
}

.editor-readOnlyToggle-btn{
  height: 26px;
  border-radius: 3px;
  position: relative;
  
  color: white;
}

.editor-readOnlyToggle-btn--control{
  width: 120px;
}

.editor-readOnlyToggle-btn--refresh{
  width: 150px;
  will-change: transform;
}

.editor-readOnlyToggle-btn-iconContainer{
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-readOnlyToggle-btn-iconContainer svg{
  width: 12px;
  height: 12px;
  fill: white;
  opacity: 0.75;

  transform: scaleX(-100%);
}

.editor-readOnlyToggle-btn--control{
  background: var(--primary500);
  border: 1px solid var(--primary500);
}

.editor-readOnlyToggle-btn--control:hover{
  background: var(--primary600);
  border: 1px solid var(--primary600);
}

.editor-readOnlyToggle-btn--control
.editor-readOnlyToggle-btn-label{
  font-variation-settings: "wght" 490;
}

.editor-readOnlyToggle-btn--refresh{
  background: var(--250gray);
  border: 1px solid var(--line100);
  opacity: 0.95;
}

.editor-readOnlyToggle-btn--refresh:hover{
  background: var(--246gray);
  border: 1px solid var(--line150);
  opacity: 1;
}


.editor-readOnlyToggle-btn[data-refresh-loading='true']
.editor-readOnlyToggle-btn-label{
  // transform: translateX(15px);
  opacity: 0.5;
  transition-delay: 50ms;
  transition: all 150ms linear;
}

.editor-readOnlyToggle-btn[data-refresh-loading='false']
.editor-readOnlyToggle-btn-label{ 
  opacity: 1;
  transition: all 150ms linear;
}


// //

// .editor-readOnlyToggle-btn[data-refresh-loading='true']{
//   opacity: 0.5;
//   transform: scale(0.98);
//   transition: all 150ms ease-out;
  
// }

// .editor-readOnlyToggle-btn[data-refresh-loading='false']{
//   opacity: 1;
//   transform: scale(1);
//   transition: all 250ms ease-in;
// }

// .editor-readOnlyToggle-btn[data-refresh-loading='true']
// .editor-readOnlyToggle-btn-label{
//   // transform: translateX(15px);
//   opacity: 0.75;
//   transition-delay: 50ms;
//   transition: all 100ms linear;
// }

// .editor-readOnlyToggle-btn[data-refresh-loading='false']
// .editor-readOnlyToggle-btn-label{
//   // transform: translateX(0px);
//   opacity: 1;
//   transition: all 100ms linear;
// }

// // .editor-readOnlyToggle-btn[data-refresh-loading='true']
// .editor-readOnlyToggle-btn--refresh-spinnerContainer{
//   // transform: translateX(-4px);
//   opacity: 1;
  
//   transition: all 100ms linear;
// }

// .editor-readOnlyToggle-btn[data-refresh-loading='false']
// .editor-readOnlyToggle-btn--refresh-spinnerContainer{
//   transform: translateX(0px);
//   opacity: 0;
//   transition-delay: 50ms;
//   transition: all 100ms linear;
// }


// .editor-readOnlyToggle-btn--refresh-spinnerContainer{
//   position: absolute;

//   display: flex;
//   align-items: center;
//   justify-content: center;
//   top: 0;
//   left: 0px;
//   width: 100%;
//   height: 100%;

//   margin-left: -32px;

//   box-sizing: border-box;
//   padding-bottom: 2px;
// }

// .editor-readOnlyToggle-btn--refresh-spinner{
//   height: 12px;
//   width: 12px;
  
//   border-radius: 12px;

//   border: 1.5px rgba(255,255,255,0.2) solid;
//   border-top: 1.5px rgba(255,255,255,1) solid;

//   opacity: 1;

//   animation: spinnerRotate 1s infinite linear;
// }
