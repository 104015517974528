.selectWindowModalContainer{	
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.selectWindowModalContainer:focus{
	outline: none;
}

.selectWindowModalContainer--hidden,
.selectWindowModalContainer--hidden *{
	pointer-events: none !important;
}


//

.selectWindowModal{	

	margin-top: -10px;

	width: 360px;	
	min-height: 340px;

	box-sizing: border-box;	

	border-radius: 6px;

	position: relative;

	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dialog);

	display: flex;
	flex-direction: column;

	user-select: none !important;
}

.selectWindowModal-header{
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 10px;

	margin-top: 8px;

}

.selectWindowModal-header-iconContainer{
	width: 44px;
	height: 44px;

	margin-top: 6px;
	margin-left: 8px;

	 
	display: flex;
	align-items: center;
	justify-content: center;
}

.selectWindowModal-header-iconContainer svg{
	width: 30px;
	height: 30px;
	fill: #97BFFF;
	// fill: white;
	opacity: 0.75;
}

.selectWindowModal-header-cancelBtn{

	padding-left: 20px;
	padding-right: 20px;
	height: 30px;

	border-radius: 4px;

	position: relative;
	top: -6px;

	font-size: 13.5px;
	font-weight: $fw-medium;
	letter-spacing: 0.008em;
	color: var(--text500);
	opacity: 0.5;

	cursor: pointer;
}

.selectWindowModal-header-cancelBtn:hover{
	opacity: 1;
	background: var(--250gray);
}

.selectWindowModal-label{
	font-size: 13.5px;
	font-weight: $fw-regular;
	color: var(--text500);
	opacity: 0.6;

	margin-left: 26px;
	margin-top: 14px;

	margin-bottom: 8px;
	pointer-events: none;
}

.selectWindowModal-windowList{
	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	padding-left: 16px;
	padding-right: 16px;

	padding-bottom: 24px;

}


.selectWindowModal-windowList-window{
	justify-content: flex-start;
	height: 30px;

	width: 100%;
	// background: $tempr;

	font-size: 15px;
	font-weight: $fw-medium;
	color: var(--text500);

	border-radius: 3px;

	padding-left: 10px;
	padding-right: 10px;

  cursor: pointer;

  opacity: 1;

  cursor: pointer;

}

.selectWindowModal-windowList-window-label{
	text-align: left;
}

.selectWindowModal-windowList-window-label{
	flex-shrink: 0;
}


.selectWindowModal-windowList-window-label--windowName{
	opacity: 0.5;
	flex-grow: 1;
	flex-shrink: 1;

	padding-left: 5px;


  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

	

	
}

.selectWindowModal-windowList-window-label--windowName--onlyWindow{
	opacity: 0;
}



.selectWindowModal-windowList-window:hover{
	background: var(--250gray);
}

.selectWindowModal-windowList-windowList:hover
.selectWindowModal-windowList-window{
	opacity: 0.6;
}

.selectWindowModal-windowList-windowList:hover
.selectWindowModal-windowList-window:hover{
	opacity: 1;
}



//
// DIALOG OVERLAY


.selectWindowModalOverlay{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;

	//background: $tempr;
	
	background: rgba(0,0,0,0.12);
	background: rgba(0,0,0,0.24);
}

.selectWindowModalTestOverlay{
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;

	background: $tempr;
}


[data-theme="dark"]{
	.selectWindowModalOverlay{
		background: rgba(0,0,0,0.24);	
	}
}

//
//

.selectWindowModal-permissionsHeader
.selectWindowModal-header-cancelBtn{
	position: absolute;
	top: 16px;
	right: 14px;

	z-index: 10;
}


.selectWindowModal-permissionsHeader{
	width: 100%;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;

	align-items: flex-start;
	justify-content: center;

	padding-top: 52px;
	padding-left: 28px;
	padding-right: 28px;

	user-select: none;

	margin-bottom: 17px;

}

.selectWindowModal-permissionsHeader-label{
	font-size: 18px;
	font-weight: $fw-semibold;
	color: var(--text500);
}

.selectWindowModal-permissionsHeader-explainer{
	font-size: 14.4px;
	font-weight: $fw-regular;
	color: var(--text500);
	opacity: 0.75;
	line-height: 1.25;
	letter-spacing: 0.008em;

	margin-top: 10px;
}

.selectWindowModal-permissionsRow{
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-left: 20px;
	margin-right: 20px;

	border-radius: 5px;

	width: calc(100% - 40px);

	padding-left: 14px;
	padding-right: 14px;

	height: 48px;

	margin-bottom: 10px;
	box-sizing: border-box;
	


	background: rgba(255,255,255,0.03);
	// background: $tempr;
}

.selectWindowModal-permissionsRow-label{
	
	font-size: 15.25px;
	color: var(--text500);
	font-variation-settings: "wght" 530;
}

.selectWindowModal-permissionsRow-checkboxContainer{
	height: 24px;
	width: 24px;


	position: relative;
	top: 1px;


	// background: $tempr;
}

.selectWindowModal-permissionsRow-checkbox{
	width: 22px;
	height: 22px;

	background: transparentize(#72C899, 0.8);
	background: #72C899;
	border-radius: 11px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.selectWindowModal-permissionsRow-checkbox svg{
	width: 12px;
	height: 12px;

	width: 14px;
	height: 14px;
	stroke: black;


	z-index: 10;
	position: relative;	
	top: 0.5px;
	left: 0.5px;

	stroke-width: 12px;
  

}

.selectWindowModal-permissionsRow-rightContainer .uiButton{
	width: 120px;
}

// .selectWindowModal-permissionsRow-border{
// 	width: 100%;
// 	height: 1px;
// 	margin-bottom: 18px;
// 	background: var(--line100);
// }

.selectWindowModal-vSpacer{
	min-height: 10px;
	flex-grow: 1;
	flex-shrink: 1;
}

.selectWindowModal-permissionsDoneBtnContainer{
	width: 100%;
	box-sizing: border-box;

	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 16px;
}

.selectWindowModal-permissionsDoneBtn{
	width: 100%;
	height: 48px;
	border-radius: 5px;

	display: flex;
	background: rgba(255,255,255,0.05);
}

.selectWindowModal-permissionsDoneBtn{
	font-size: 16px;
	font-weight: $fw-medium;
	color: var(--text600);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 500;
  letter-spacing: 0.008em;
}

.selectWindowModal-permissionsRowBottomSpacer{
	height: 32px;
}