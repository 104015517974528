$maxWidth-post: 860px;
$innerWidth-post: 650px;

@import 'UpdatesPage-MajorReleaseBanner';

.updatesPage{
	width: 100%;
	height: 100%;

	box-sizing: border-box;	

	display: flex;  
	flex-direction: column;
	align-items: center;
	position: relative;

  background: var(--bg-editor);
  // background: none;
  // background: rgba(5,5,8,0.5);

  overflow-y: scroll;
  scrollbar-gutter: stable;

  overflow-x: hidden;
}

.updatesPage-header{
	-webkit-app-region: drag;
	height: $height-tabBar;
	width: 100%;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	z-index: 100;
	background: $tempr;	

	display: flex;
	align-items: center;
	justify-content: center;

	height: $height-tabBar;
  background: var(--bg-tabBar);
  background: var(--bg-editor);
  //border-bottom: 1px solid rgb(60,60,68);
}

.updatesPage-header-label{
	width: $innerWidth-post;
	font-size: 16px;
	color: var(--text600);
	margin-left: -8px;
	font-variation-settings: "wght" 540;
	opacity: 0.75;
}

.updatesPage-navigation{
	display: flex;
	margin-top: 100px;
	column-gap: 20px;
}

.updatesPage-list{
	display: flex;
	flex-direction: column;

	align-items: center;
	

	width: $maxWidth-post;


}



.updatesPage-post{
	width: $innerWidth-post;
	margin-bottom: 60px;

	color: var(--text500);
	cursor: default;
	font-family: system-ui, sans-serif;
	padding-top: 80px;
}

.updatesPage-post-date{
	font-size: 14px;
	color: var(--text500);
	opacity: 0.5;

	//margin-bottom: -12px;
}

.updatesPage-post-title{
	font-size: 28px;
	color: var(--text600);
	font-variation-settings: "wght" 620;
	margin-top: 24px;
	margin-bottom: 16px;


}

// .updatesPage-post h1{
// 	font-size: 24px;
// 	color: var(--text600);
// 	font-variation-settings: "wght" 580;
// 	margin-top: 24px;
// 	margin-bottom: 6px;
//   // letter-spacing: -0.022em;
// }

.updatesPage-post h2{
	font-size: 24px;
	color: var(--text600);
	font-variation-settings: "wght" 580;
	margin-top: 0px;
	padding-top: 26px;
	padding-bottom: 10px;
	margin-bottom: 0px;
	cursor: text;
	//border-top: 1px solid rgba(255,255,255,0.05);
  // letter-spacing: -0.022em;
}


// .updatesPage-post h4{
// 	margin-top: 32px;
// 	margin-bottom: -8px;
// 	font-size: 17px;
// 	color: var(--text600);
// 	font-family: system-ui, sans-serif;
// 	font-variation-settings: "wght" 570;
//   // letter-spacing: -0.022em;
// }

.updatesPage-post p,
.updatesPage-post li,
.updatesPage-post h4{
	font-size: 16.5px;
	font-variation-settings: "wght" 420;
  color: var(--text500);
  line-height: 1.35;
  line-height: 1.42;
  letter-spacing: 0.008em;
  cursor: text;
}

.updatesPage-post em{
	font-style: normal; 
	color: var(--text400);

	text-decoration: none;

	box-sizing: border-box;

	padding-left: 3px;
	padding-right: 3px;
	padding-top: 2px;
	padding-bottom: 1px;

	background: var(--248gray);
	border: 1px solid var(--246gray);
	border-radius: 2px;

	font-size: 13.5px;
	font-weight: 500;

	font-family: monospace;

}

.updatesPage-post h4{
	margin-top: 8px;
	margin-bottom: 8px;
	opacity: 0.75;
}

.updatesPage-post ul{
	margin-top: -1px;
	padding-inline-start: 0px;
	margin-left: -6px;
	margin-bottom: 0px;
}



.updatesPage-post li {
  padding-left: 6px;
  padding-top: 6px;
	padding-bottom: 6px;
}

.updatesPage-post li:last-child{
	padding-bottom: 32px;
}

.updatesPage-post li::marker{
	color: rgba(255,255,255,0.2);
	position: relative;
	left: -4px;
}

.updatesPage-post p a{
	color: var(--text500);
}

.updatesPage-post p strong{
	font-size: 16px;
	color: var(--text500);	
	font-variation-settings: "wght" 440;  
}


.updatesPage-post-borderBottom{
	height: 1px;
	width: 100%;
	background: var(--line200);
	opacity: 0.25;
	margin-top: 36px;
}


.updatesPage-post-videoPlayerOuterContainer {
  width: 100vw;
  max-width: 1400px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 0px;
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box;
}

.updatesPage-post-videoPlayerMiddleContainer {
  //max-width: 1100px; /* 1200px - 100px for padding */
  margin: 0 auto;
}

.updatesPage-post-videoPlayerInnerContainer {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  max-height: calc(100vh - 200px);
  // background: $tempr;
}

