

//
// GLOBAL
$invisible: rgba(0,0,0,0);
$tempr: rgba(255,0,0,0.25);
$tempb: rgba(0,0,255,0.25);
$tempg: rgba(0,255,0,0.25);

// PART REFACTOR INTO GREY SCALE
$white: rgb(255,255,255); // I think e.g. for dark buttons we want to keep them white
$black: rgb(0,0,0);
$red: #FD6E6E;

$focusBorderColor-dpElement: rgb(113,148,255);

@import 'Colors-PrimaryColor';
@import 'Colors-BG';
@import 'Colors-Text';
@import 'Colors-Lines';
@import 'Colors-Grays';
@import 'Colors-AccentColors';
@import 'Colors-TextSelection';

@import 'Colors-Workflows';

@import 'Colors-Timeline';

@import 'Colors-Dropdowns';
@import 'Colors-Buttons';
@import 'Colors-Collections';

@import 'Colors-Tooltips';

@import 'Colors-Custom'; // for components without their own definitions file

//
// APP BG COLORING
// we put the app background color on a separate div instead of on the app div itself to prevent issues with backdrops from radix

.app-bg{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 0;

  //transition: background 50ms linear;
}


.app-bg{
  background: var(--appBG-sideMenuMode);
}

.app--sideMenuHidden .app-bg{
  background: var(--appBG-sideMenuHidden);
}


// VLIGHT COLORS OPAUE
$blue-extraLight--opaque: #EDF5FE;

// GRAYS
$gray-800: rgb(236,239,243);
$gray-900: rgb(252,252,252); // lightest gray



//


//

$lightGray-bg: rgb(250,248,248);

//





///
//// REFACTOR OUT
$accentColor-purple: 	rgb(181,120,221);



:root{    
  //--bg-editor: hsl(240,0%,100%);  
  --border-panel: 1px solid rgb(200,200,210);
}

[data-theme="dark"], .forceDarkTheme{ 
  --border-panel: 1px solid rgb(50,50,60);
}





