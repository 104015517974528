$fw-light:      300;
$fw-regular:    400;
$fw-medium:     500;
$fw-semibold:   600;
$fw-bold:       700;
$fw-heavy:      800;
$fw-black:      900;

.App, body, input, textarea, button {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    -webkit-font-smoothing: antialiased;
}

.App, body::selection, input::selection, textarea::selection, div::selection, span::selection{    
    background-color: var(--textSelectionBlue) !important; 
    // I think this is right
    // chrome is adding special text-selection colors if dark mode
}

@import 'Type-Cereal';
@import 'Type-SFProDisplay';
@import 'Type-InterDisplay';
@import 'Type-Inter';
@import 'Type-Gilroy';
@import 'Type-UntitledSans';
@import 'Type-Kalam';
@import 'Type-ABCMarfa';
@import 'Type-Oxygen';
@import 'Type-StabilGrotesk';
@import 'Type-UncutSans';
@import 'Type-GreycliffCF';
@import 'Type-Relative';
@import 'Type-Poppins';
@import 'Type-MonaSans';
@import 'Type-Archivo';
@import 'Type-WorkSans';
@import 'Type-SpaceGrotesk';
@import 'Type-Urbanist';
@import 'Type-MarlideDisplay';
@import 'Type-IBMPlexSans';
@import 'Type-Matter';
@import 'Type-Sora';
@import 'Type-Heebo';
@import 'Type-Muli';
@import 'Type-GothamRounded';
@import 'Type-PlusJakartaSans';
@import 'Type-RobotoSlab';
@import 'Type-WixMadeforDisplay';
@import 'Type-PPNeueMachinaInktrap';
@import 'Type-Manrope';
@import 'Type-Lausanne';
@import 'Type-Recoleta';
@import 'Type-Montserrat';
@import 'Type-Switzer';
@import 'Type-Sohne';
@import 'Type-Nantes';
@import 'Type-Utopia';
@import 'Type-PPObjectSans';
@import 'Type-Circular';
@import 'Type-DMSans';
@import 'Type-NeueMontreal';
@import 'Type-OakesGrotesk';
@import 'Type-Aeonik';
@import 'Type-BasierCircle';
@import 'Type-AGP';
@import 'Type-ProximaNova';
@import 'Type-Raptor';
@import 'Type-SpecifyExpanded';
@import 'Type-Graphik';
@import 'Type-ClashGrotesk';
@import 'Type-Outfit';
@import 'Type-Onest';
@import 'Type-SuisseIntl';
@import 'Type-LabGrotesque';
@import 'Type-PlayfairDisplay';
@import 'Type-WaldenburgCondensed';
@import 'Type-Roobert';
@import 'Type-Raleway';
@import 'Type-Geist';
@import 'Type-GeistMono';
@import 'Type-ShantellSans';
@import 'Type-NeueHaasGroteskDisplay';
@import 'Type-NeueHaasGroteskText';
@import 'Type-SFMono';
@import 'Type-STKBureauSerif';
@import 'Type-STKBureauSans';
@import 'Type-WFVisualSans';