.forceUpdateModal-overlay{
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.5);
}

.forceUpdateModal{

	padding-top: 4px;
	padding-bottom: 0px;
	
	border-radius: 6px;

	box-shadow: none;	

	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);
  box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.28);

  background: rgb(25, 27, 33);
  
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	margin: auto;

	width: 750px;
	height: 400px;

	overflow: hidden;
	cursor: default;
	user-select: none;
}

.forceUpdateModal:focus{
	outline: none;
}



.forceUpdateModal-iconContainer{
	width: 96px;
	height: 96px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: -32px;
}

.forceUpdateModal-iconContainer svg{
	width: 72px;
	height: 72px;
	fill: var(--text500);
	opacity: 0.4;
}

.forceUpdateModal-title{
	margin-top: 18px;

	font-size: 32px;
	font-weight: $fw-medium;
	color: var(--text600);

	font-family: system-ui, sans-serif;  
  font-variation-settings: "wght" 560;
  letter-spacing: 0.008em;


  margin-bottom: 18px;  
}

.forceUpdateModal-description{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	max-width: 440px;
}

.forceUpdateModal-description-para{
	font-size: 16px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;  
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;

  opacity: 0.75;
	margin-bottom: 10px;

	text-align: center;
}