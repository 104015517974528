
@font-face {
    font-family: 'YDMSans';
    src: url(../fonts/YDMSans/YDMSans-Light.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-light;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YDMSans';
    src: url(../fonts/YDMSans/YDMSans-Regular.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-regular;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YDMSans';
    src: url(../fonts/YDMSans/YDMSans-Medium.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-medium;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YDMSans';
    src: url(../fonts/YDMSans/YDMSans-Semibold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-semibold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YDMSans';
    src: url(../fonts/YDMSans/YDMSans-Bold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YDMSans';
    src: url(../fonts/YDMSans/YDMSans-Heavy.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-heavy;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YDMSans';
    src: url(../fonts/YDMSans/YDMSans-Black.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-black;
    text-rendering: optimizeLegibility;
}
