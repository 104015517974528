

.miniPopover--moveTo{
	width: 240px;
	//height: 300px;
  //min-height: 220px;
}


.miniPopover-smallHSpacer{
	height: 4px;
}

.miniPopover-item--collection-bar{
	width: 3px;
	height: 15px;
	background: $tempr;
	margin-right: 5px;
	border-radius: 1px;
	margin-left: -4px;

	display: none;
}

.miniPopover-item--collection{
	display: flex;
	align-items: center;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// .miniPopover-item--collection:hover{
// 	background: none !important;
// }

.miniPopover-item-checkboxContainer{
	padding-right: 10px;
	margin-left: -2px;

	flex-grow: 0;
	flex-shrink: 0;
}

.miniPopover-item-checkbox{
	width: 14px;
	height: 14px;
	background: $tempr;
	border-radius: 2.5px;

	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.03);
	cursor: pointer;
	position: relative;

	background: rgba(255,255,255,0.01);
	border: 1px solid var(--line400);
}

.miniPopover-item-checkbox[data-active-state="false"]:not(:hover){
	opacity: 0.75;
}

.miniPopover-item-checkbox[data-active-state="false"]:hover{
	opacity: 1;
}

.miniPopover-item-checkbox svg{	
	position: relative;
	//left: -0.5px;
	top: -4.5px;

	pointer-events: none;

	width: 14px;
	height: 14px;
	stroke: black;
	opacity: 1;
	stroke-width: 0.5px;
	fill: black;
}

.miniPopover-item-label{
	flex-grow: 1;
	flex-shrink: 1;
	
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
