

.recordWebcamWindow-main--script-measurer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}



.recordWebcamWindow-main--script-tele{
	position: absolute;
	top: 0px;
	bottom: 0px;
	width: 440px;
	left: 50%;
	transform: translateX(-50%);
	// background: $tempr;	
}

.recordWebcamWindow-main--script-tele-content{
	color: rgba(255,255,255,0.95);
	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.006em;
}

.recordWebcamWindow-main--script-tele-title{
	display: flex;
	align-items: center;

	color: var(--text500);
  font-size: 17px;
  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 520;
  letter-spacing: 0.014em;    
}            



.recordWebcamWindow-main--script-tele-title[data-visibility-state='hidden']{
  opacity: 0;
  transition: opacity 75ms linear;
  transition-delay: 100ms;
}

.recordWebcamWindow-main--script-tele-title[data-visibility-state='visible']{
  opacity: 0.55;
  transition: opacity 150ms linear;
  transition-delay: 200ms;
}



.recordWebcamWindow-main--script-tele-content-para{
	margin-bottom: 24px;
}

.recordWebcamWindow-main--script-teleDev{
	position: fixed;
	left: 0px;
	bottom: 0px;
	display: flex;
	column-gap: 10px;

	z-index: 2000;
}

.recordWebcamWindow-main--script-teleDev button{
	width: 100px;
	height: 40px;
	background: $tempr;
	color: white;
}

.recordWebcamWindow-main--script-teleScrim {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    height: 230px;
    z-index: 50;
    background: linear-gradient(
        to bottom,
        rgba(20,20,22,0) 0%,
        rgba(20,20,22,0.2) 10%,
        rgba(20,20,22,0.5) 30%,
        rgba(20,20,22,0.75) 40%,        
        rgba(20,20,22,0.98) 100%  /* Dark but not completely opaque at bottom */
    );
}
.recordWebcamWindow-main--script-teleBlock {
	position: absolute;
  left: 0px;
  right: 0px;
  top: 280px;
  bottom: 0px;
  background: rgba(20,20,22);
  z-index: 50;
  border-radius: 6px;
}

//

.recordWebcamWindow-main--script-tele-recordProgressContainer{
	position: absolute;
	left: 0px;
	right: 0px;
	z-index: 400;
	top: 0px;
	height: 30px;	
	border-radius: 6px;
 	background: linear-gradient(
      to top,
      rgba(20,20,22,0) 0%,	      
      rgba(20,20,22,0.7) 100%  /* Dark but not completely opaque at bottom */
  );
  display: none;
  
}

.recordWebcamWindow-main--script-tele-recordProgressContainer[data-visibility-state='hidden']{
  opacity: 0;
  transition: opacity 75ms linear;
  transition-delay: 100ms;
}

.recordWebcamWindow-main--script-tele-recordProgressContainer[data-visibility-state='visible']{
  opacity: 1;
  transition: opacity 150ms linear;
  transition-delay: 200ms;
}





.recordWebcamWindow-main--script-tele-recordProgress{
	position: absolute;
	left:  50%;
	transform: translateX(-50%);
	top: 0px;
	padding-top: 8px;	
}

.recordWebcamWindow-main--script-tele-recordProgress-bar{
	width: 200px;
	border-radius: 3px;
	height: 4px;
	background: rgba(120,120,120,0.9);	
	position: relative;
	overflow: hidden;
}

.recordWebcamWindow-main--script-tele-recordProgress-bar-bar{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	background: #F86565;
}


//



//
// TELE SLIDER

.recordWebcamWindow-main--script-controlsContainer{
	position: absolute;
	bottom: 5px;
	left: 30px;
	right: 30px;
	height: 40px;

	z-index: 400;

	//background: $tempr;
}

.recordWebcamWindow-main--script-teleSlider{
	position: absolute;	
	bottom: 10px;
	//bottom: 50px;
	left: 50%;
	transform: translateX(-50%);
	height: 40px;

	//background: $tempr;
	border-radius: 6px;

	background: rgba(255,255,255,0.04);
	border: 1px solid rgba(255,255,255,0.02);
	z-index: 200;
	//box-shadow: inset 2px 0px 10px rgba(0, 0, 0, 0.15);

	opacity: 1;
	transition: opacity 100ms linear;
}


.recordWebcamWindow-main--script-teleSlider[data-recording-state='true'][data-dragging-state='false']{
	opacity: 0.75;
}

.recordWebcamWindow-main--script-teleSlider-thumb{
	position: absolute;
	//background: $tempr;
	height: 100%;
	border-radius: 4px;

	background: rgba(80,80,90,0.9);
	border: 1px solid rgba(255,255,255,0.2);
	box-shadow: 0px 0px 16px 4px rgba(20,20,22,0.2);

	position: relative;
	transition: transform 50ms ease-in-out;
}

.recordWebcamWindow-main--script-teleSlider-thumb svg{
	width: 28px;
	height: 28px;
	fill: white;
	position: relative;
	left: -5px;
	//transform: scale(1.04);
	opacity: 0.8;
}



.recordWebcamWindow-main--script-teleSlider-thumb:hover{
	background: rgba(80,80,90,0.98);
	transform: scale(1.01);
}

.recordWebcamWindow-main--script-teleSlider[data-dragging-state='true']
.recordWebcamWindow-main--script-teleSlider-thumb{
	transform: scale(1.02);
	background: rgba(90,90,100,0.95);
}


.recordWebcamWindow-main--script-teleSlider-thumb-edgeIcon{
	position: absolute;
	top: 0px;
	bottom: 0px;

	display: flex;
	align-items: center;
	justify-content: center;

	pointer-events: none;
	transition: opacity 100ms linear;
	opacity: 0;
}

.recordWebcamWindow-main--script-teleSlider-thumb-edgeIcon[data-space-state='false']{
	opacity: 0 !important;
	transition: opacity 75ms linear;
	transition-delay: 200ms;
}

.recordWebcamWindow-main--script-teleSlider-thumb:hover
.recordWebcamWindow-main--script-teleSlider-thumb-edgeIcon[data-space-state='true']{
	opacity: 1;
}

.recordWebcamWindow-main--script-teleSlider[data-dragging-state='true']
.recordWebcamWindow-main--script-teleSlider-thumb:hover
.recordWebcamWindow-main--script-teleSlider-thumb-edgeIcon{
	opacity: 0.35;
}



.recordWebcamWindow-main--script-teleSlider-thumb-edgeIcon svg{
	width: 24px;
	height: 24px;
	fill: white;
	opacity: 0.2;
}

.recordWebcamWindow-main--script-teleSlider-thumb-edgeIcon--left{
	right: 100%;
	box-sizing: border-box;
	padding-left: 5px;
}
.recordWebcamWindow-main--script-teleSlider-thumb-edgeIcon--right{
	left: 100%;
	box-sizing: border-box;
	padding-left: 10px;
}

.recordWebcamWindow-main--script-teleSlider-thumb-edgeIcon--right svg{	
	
}

.recordWebcamWindow-main--script-teleSlider-incrementBtn,
.recordWebcamWindow-main--script-teleSlider-decrementBtn{
	position: absolute;
	top: 0px;
	height: 100%;

	// outline: 1px solid blue;
	// background: rgba(0,0,255,0.5);
	z-index: 200;
}

.recordWebcamWindow-main--script-teleSlider-labelContainer{
	position:  absolute;
	top: 0px;
	bottom: 0px;
	//left: 50%;
	// transform: translateX(-50%);
	// margin-top: -10px;

	z-index: 50;

	pointer-events: none !important;
}

.recordWebcamWindow-main--script-teleSlider-label{
	position: absolute;
	left: 0px;
	right: 0px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--text500);
  font-size: 15px;
  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.014em;  
  opacity: 0.55;

  user-select: none;
  cursor: default;

}


.recordWebcamWindow-main--script-teleSlider-label--hover,
.recordWebcamWindow-main--script-teleSlider-label--drag{
	opacity: 0;
	transition: opacity 150ms;
	transition-delay: 0ms;
}

.recordWebcamWindow-main--script-teleSlider[data-dragging-state='false'][data-recording-state='false']:hover
.recordWebcamWindow-main--script-teleSlider-label--hover{
	opacity: 0.8;
	transition-delay: 0ms;
}


.recordWebcamWindow-main--script-teleSlider[data-dragging-state='true'][data-recording-state='false']
.recordWebcamWindow-main--script-teleSlider-label--drag{
	opacity: 0.5;
	transition-delay: 200ms;

}