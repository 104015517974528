.settingsModal-panel--subscription-upgrade{
	display: flex;
	flex-direction: column;

	margin-top: -3px;
}

.settingsModal-panel--subscription-upgrade-top{
	display: flex;
	align-items: flex-end;
}

.settingsModal-panel--subscription-upgrade-price{
	display: flex;
	align-items: flex-end;
	user-select: none;
}

.settingsModal-panel--subscription-upgrade-price-price{
	font-size: 64px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 660;
  letter-spacing: 0.004em;

  color: var(--text600);

  padding-right: 12px;
  position: relative;
  margin-left: -4px;
  top: 10px; // not sure why needed
}

.settingsModal-panel--subscription-upgrade-price-sub{
	display: flex;
	flex-direction: column;
}

.settingsModal-panel--subscription-upgrade-price-sub-sub{
	font-size: 13px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 420;
  letter-spacing: 0.012em;

  color: var(--text600);
  opacity: 0.5;
}



.settingsModal-panel--subscription-upgrade-btn{
	margin-left: auto;
	width: 120px;
	height: 34px;

	border-radius: 4px;

	background: $upgradeBlue;
	color: white;

	font-size: 15px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 450;
  letter-spacing: 0.012em;

  position: relative;
  top: -4px;
}

.settingsModal-panel--subscription-upgrade-btn:hover{
	background: lighten(rgb(50, 99, 250), 2%);
}

.settingsModal-panel--subscription-upgrade-checklist{
	display: flex;
	margin-top: 14px;
	margin-bottom: 12px;
	margin-left: -5px;
}
.settingsModal-panel--subscription-upgrade-checklist-col{
	display: flex;
	flex-direction: column;
}

.settingsModal-panel--subscription-upgrade-checklist-item{
	width: 220px;
	height: 30px;

	display: flex;
	align-items: center;
	user-select: none;
}

.settingsModal-panel--subscription-upgrade-checklist-item-iconContainer{
	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-right: 4px;
	position: relative;
}

.settingsModal-panel--subscription-upgrade-checklist-item-iconContainer svg{
	position: relative;
	// left: 1px;
	top: 0.5px;

	pointer-events: none;

	width: 14px;
	height: 14px;

	stroke: $upgradeBlue;
	
	stroke-width: 6px;
	
}

.settingsModal-panel--subscription-upgrade-checklist-item-label{
	font-size: 14px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 400;
  letter-spacing: 0.012em;

  color: var(--text600);
  opacity: 0.85;
}

.settingsModal-panel--subscription-upgrade-footer{
	font-size: 13.5px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 400;
  letter-spacing: 0.012em;

  color: var(--text600);
  opacity: 1;

  margin-top: 8px;
  user-select: none;
}

.settingsModal-panel--subscription-upgrade-footer span{
	opacity: 0.55;
	//margin-left: 0.4em;
}

//
// PLAN

.settingsModal-panel--subscription-plan{

	user-select: none;
}

.settingsModal-panel--subscription-plan-top{
	display: flex;
	position: relative;
}

.settingsModal-panel--subscription-plan-current{
	font-size: 15.5px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.012em;

  color: var(--text600);
  opacity: 1;

  margin-bottom: 7px;
  margin-top: 5px;
}

.settingsModal-panel--subscription-plan-current span{
	opacity: 0.75;
}

.settingsModal-panel--subscription-plan-cancelBtn{
	position: absolute;
	top: 2px;
	right: -6px;

	padding-right: 12px;
	padding-left: 12px;
	height: 30px;

	font-size: 14px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 400;
  letter-spacing: 0.012em;

  color: var(--text600);
  opacity: 0.5;

  border-radius: 4px;

  // background: $tempr;  
}

.settingsModal-panel--subscription-plan-cancelBtn:hover{
	background: transparentize($red, 0.9);
	color: darken($red, 20%);
	opacity: 1;
}

.settingsModal-panel--subscription-plan-mid{
	font-size: 14.5px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 400;
  letter-spacing: 0.012em;

  color: var(--text600);
  opacity: 0.65;
  
  user-select: none;

  margin-bottom: 5px;
}

.settingsModal-panel--subscription-plan-low{
	font-size: 14.5px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 400;
  letter-spacing: 0.012em;

  color: var(--text600);
  opacity: 0.55;
  
  user-select: none;
}
