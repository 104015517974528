
.editor-slideEditor-spacerOutline{
  position: absolute;  
  z-index: 4000;
  // pointer-events: none;
  // opacity: 0.25;
  background: rgba(0,0,0,0.06);
  border-radius: 2px;
  box-sizing: border-box;
  opacity: 0;
  // opacity: 1;
  transition: opacity 50ms linear;  

  background: transparentize(#E245AF, 0.8);
}

.editor[data-dragging-to-reorder='true']
.editor-slideEditor-spacerOutline{
  opacity: 0 !important;
}

.editor-slideEditor-spacerOutline:hover,
.editor-slideEditor-spacerOutline[data-force-visible-state='true']{
  // opacity: 0.2;
  opacity: 1;
  transition-delay: 100ms;
}

.editor-slideEditor-spacerOutline--right{
  // background: green;
}




.editor-slideEditor-spacerHandle{  
  background: rgba(0,0,0,0.26);
  z-index: 5000;  
  border: 1px solid white;  
  // opacity: 1;
  transition: opacity 50ms linear;  
}

.editor-slideEditor-spacerHandle--dnd{
  position: absolute;
  background: red;
  opacity: 0;
}

.editor-slideEditor-spacerHandle--render{
  background: blue;
  background: #E245AF;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 1px;
}


// .editor-slideEditor-spacerOutline:hover +
// .editor-slideEditor-spacerHandle{
//   opacity: 0.7;
// }

// .editor-slideEditor-spacerHandle:hover{
//   opacity: 1;
// }
