.dropdownMenu--selectSounds-effects{
	width: 100%;

	display: flex;
	flex-direction: column;
	row-gap: 10px;

	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 8px;
	padding-bottom: 8px;
}

.dropdownMenu--selectSounds-effects-type{
	display: flex;

	border: 1px solid var(--line200);
	border-radius: 3px;

	background: rgba(255,255,255,0.02);
}

.dropdownMenu--selectSounds-effects-type[data-active-state='active']{
	border: 1px solid var(--line250);
	background: rgba(255,255,255,0.04);
}

.dropdownMenu--selectSounds-effects-type[data-active-state='inactive']{
	opacity: 0.75;
}

.dropdownMenu--selectSounds-effects-type[data-active-state='inactive']:hover{
	opacity: 0.95;
}


.dropdownMenu--selectSounds-effects-type-primary{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	align-items: center;
	cursor: pointer;

	height: 48px;

	box-sizing: border-box;
	padding-left: 14px;
	padding-right: 14px;
	// padding-top: 4px;
	// padding-bottom: 4px;

	user-select: none;

	// background: $tempr;
}

//

.dropdownMenu--selectSounds-effects-type-primary-checkbox{
	width: 12px;
	height: 12px;
	border-radius: 3px;
	margin-right: 8px;
}

.dropdownMenu--selectSounds-effects-type[data-active-state='active']
.dropdownMenu--selectSounds-effects-type-primary-checkbox{
	background: #4362c1;	
	border: 1px solid $focusBorderColor-dpElement;
}

.dropdownMenu--selectSounds-effects-type[data-active-state='inactive']
.dropdownMenu--selectSounds-effects-type-primary-checkbox{
	border: 1px solid rgba(255,255,255,0.3);
	background: rgba(255,255,255,0.02);
}


.dropdownMenu--selectSounds-effects-type-primary-checkbox
.dropdownMenu--selectSounds-effects-type-primary-checkbox-iconContainer{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dropdownMenu--selectSounds-effects-type-primary-checkbox
.dropdownMenu--selectSounds-effects-type-primary-checkbox-iconContainer svg{
	width: 10.5px;
	height: 10.5px;
	stroke: white;
	stroke-width: 9px;
	position: relative;
	left: 0.25px;
	top: 0.25px;
}

.dropdownMenu--selectSounds-effects-type[data-active-state='inactive']
.dropdownMenu--selectSounds-effects-type-primary-checkbox-iconContainer svg{
	opacity: 0;
}



//

.dropdownMenu--selectSounds-effects-type-primary-label{
	flex-grow: 1;
	flex-shrink: 1;

	font-size: 13px;	
	color: var(--text500);
	
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;  

}

.dropdownMenu--selectSounds-effects-type-secondary{
	width: 220px;
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	flex-direction: column;

	padding-top: 8px;
	padding-bottom: 8px;
}

.dropdownMenu--selectSounds-effects-type-value{
	display: flex;
	width: 100%;
	height: 32px;
	align-items: center;
	user-select: none;
}

.dropdownMenu--selectSounds-effects-type-value-label{
	
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	font-size: 12.75px;	
	color: var(--text500);
	opacity: 0.65;
	
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;  

	text-align: right;
	box-sizing: border-box;
	padding-right: 10px;

	users-select: none;
	cursor: default;
}

.dropdownMenu--selectSounds-effects-type-value-value{
	width: 130px;
	flex-grow: 0;
	flex-shrink: 0;
}