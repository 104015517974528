.dpItemAnimationPanel{
	margin-top: 6px;
}

.dpItemAnimationPanel-header{
	display: flex;
	align-items: center;
	justify-content: space-between;
}


.dpItemAnimationPanel-typeBtnContainer{
	width: calc(100% + 10px);
	margin-left: -5px;
	position: relative;

	display: flex;
	flex-direction: column;
}

.dpItemAnimationPanel-typeBtn{
	display: flex;
	
	width: 100%;	

	align-items: center;	
	background: $tempr;

	height: 26px;
	height: 30px;
	border-radius: 4px;

	background: var(--251gray);	
	box-sizing: border-box;
	padding-left: 4px;

	cursor: pointer;
}

.dpItemAnimationPanel-typeBtn:hover{
	background: var(--250gray);	
}

.dpItemAnimationPanel-typeBtn[data-state='open']{
	background: var(--248gray);	
}


.dpItemAnimationPanel-typeBtn-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	width: 24px;
	height: 24px;
	// background: $tempr;

	flex-grow: 0;
	flex-shrink: 0;

	display: none;
}

.dpItemAnimationPanel-typeBtn-iconContainer svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.65;
}

.dpItemAnimationPanel-typeBtn-label{
	font-size: $baseFontSize-dpElement;		
	color: var(--text500);
	
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;  

  flex-grow: 1;
	flex-shrink: 1;

	text-align: left;
	box-sizing: border-box;
	padding-left: 4px;
}

.dpItemAnimationPanel-typeBtnSettingsToggle{
	position: absolute;
	right: 0px;
	top: 0px;
	height: 30px;
	z-index: 5;

	padding-right: 4px;
	padding-left: 4px;
}

.dpItemAnimationPanel-typeBtnSettingsToggle-inner{
	width: 28px;
	height: 22px;	
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dpItemAnimationPanel-typeBtnSettingsToggle-inner svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.5;
	transform: scale(1.04);
}

.dpItemAnimationPanel-typeBtnSettingsToggle:hover
.dpItemAnimationPanel-typeBtnSettingsToggle-inner{
	background: var(--246gray);
}

.dpItemAnimationPanel-typeBtnSettingsToggle:hover
.dpItemAnimationPanel-typeBtnSettingsToggle-inner svg{
	opacity: 0.95;
}

.dpItemAnimationPanel-typeBtnSettingsToggle[data-active-state='active']
.dpItemAnimationPanel-typeBtnSettingsToggle-inner{
	background: var(--243gray);
}

.dpItemAnimationPanel-typeBtnSettingsToggle[data-active-state='active']
.dpItemAnimationPanel-typeBtnSettingsToggle-inner svg{
	opacity: 1;
}

.dpItemAnimationPanel-settingsToggleList{
	display: flex;
	flex-direction: column;
	margin-left: 2px;
	margin-top: 2px;	
}

.dpItemAnimationPanel-settingsToggleList[data-visibility-state='false']{
	display: none;
}

.dpItemAnimationPanel-settingsToggleList
.dpSlider{
	margin-top: 3px;
	margin-bottom: 3px;
	background: var(--254gray);
}
.dpItemAnimationPanel-settingsToggleList
.dpSlider-mainBar,
.dpItemAnimationPanel-settingsToggleList
.dpSlider-valueBar{
	top: 1px;
	bottom: 1px;
}

//
//

@import 'dpKit-ItemAnimationPanel-TransitionPopover';

//

.dpItemAnimationPanel-hintOuterContainer{
	margin-top: 8px;
	width: 100%;
	position: relative;
}

.dpItemAnimationPanel-hintContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;

}

.dpItemAnimationPanel-hintContainer[data-visibility-state='hidden']{
	opacity: 0;
	pointer-events: none !important;
	transform: scale(0.95);
	transition: all 200ms linear;	
}

.dpItemAnimationPanel-hintContainer[data-visibility-state='visible']{
	transform: scale(1);
	opacity: 1;
	transition: all 100ms linear;
	transition-delay: 100ms;
}

.dpItemAnimationPanel-hintContainer[data-visibility-state='fading']{	
	opacity: 0;
	transition: opacity 200ms ease-out;
	pointer-events: none !important;
}

.dpItemAnimationPanel-hintContainer[data-visibility-state='clicked']{	
	opacity: 0;
	transition: opacity 50ms linear;
	pointer-events: none !important;
}


.dpItemAnimationPanel-hintContainer.dpItemAnimationPanel-hintContainer--done[data-visibility-state='hidden'] {
  transition-delay: 0ms;
  transition: all 150ms linear;
  transform: scale(0.95);
  opacity: 0;
}


.dpItemAnimationPanel-hintContainer.dpItemAnimationPanel-hintContainer--done[data-visibility-state='visible']{
	transition-delay: 1000ms;
	transition: all 50ms linear;
	transform: scale(1);
	opacity: 1;
}


.dpItemAnimationPanel-hint{
	padding-left: 10px;
	padding-right: 10px;
	height: 24px;
	border-radius: 3px;
	background: var(--250gray);
	border: 1px solid var(--line200);

	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0.75;
}

.dpItemAnimationPanel-hint:hover{
	opacity: 1;
}

.dpItemAnimationPanel-hintContainer--done,
.dpItemAnimationPanel-hintContainer--done *{
	pointer-events: none !important;
	cursor: default;
}



.dpItemAnimationPanel-hint-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	margin-left: -6px;
	margin-right: -1px;
}

.dpItemAnimationPanel-hint-iconContainer svg{
	width: 12px;
	height: 12px;
	fill: var(--text500);
	opacity: 0.7;
}

.dpItemAnimationPanel-hint-label{
	font-size: 11.55px;	
	color: var(--text500);
	
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;  	
}