
.editor-topBar{
	width: 100%;
	height: 38px;
	// height: 100%;
	box-sizing: border-box;
	padding-left: 6px;
	padding-right: 6px;

	display: flex;
	align-items: center;

	// opacity: 0.9;
	transition: all 150ms linear;
	//transition-delay: 0ms;	
	//opacity: 0; // as we transition over
}

.editor-topBar--disabled{
	opacity: 0.25;
	pointer-events: none;
}

.editor-topBar:hover{
	opacity: 1;
	transition: all 50ms linear;
}

.editor-topBar-smallSpacer{
	width: 6px;
}

.editor-topBar-hSpacer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
}

.editor-topBar-rightEdgeContainer{
	width: $width-detailPanelContainer;
}

.editor-topBar-rightContainer,
.editor-topBar-leftContainer{
	width: 350px;
	height: 100%;
	
	display: flex;
	
	align-items: center;
}

.editor-topBar--zoom .editor-topBar-rightContainer,
.editor-topBar--zoom  .editor-topBar-leftContainer,
.editor-topBar--slideAlign .editor-topBar-rightContainer,
.editor-topBar--slideAlign  .editor-topBar-leftContainer{
	width: 200px;	
}

.editor-topBar--addZoom .editor-topBar-rightContainer,
.editor-topBar--addZoom  .editor-topBar-leftContainer{
	width: 50px;
}

.editor-topBar--screenClip
.editor-topBar-leftContainer{
	width: 400px;
}


.editor-topBar-leftContainer{
	justify-content: flex-start;
}

.editor-topBar-rightContainer{
	justify-content: flex-end;
}

.editor-topBar-centerContainer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	align-items: center;
	justify-content: center;

}

//
// DROPDOWN FOR TRANSITION

.dropdownMenu--selectTransition-divider{
	margin-left: 13px;
	width: calc(100% - 13px);
	height: 1px;
	background: var(--line100);
	margin-top: 12px;
	margin-bottom: 6px;
}

.dropdownMenu--selectTransition-groupingRow{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;
	padding-left: 8px;
	//padding-right: 5px;
	padding-bottom: 0px;
	padding-top: 0px;

	margin-top: -3px;
	margin-bottom: -4px;




	//border-top: 1px solid var(--line100);
	//background: rgba(255,255,255,0.01);
}

.dropdownMenu--selectTransition-groupingRow-smallLabelBtn{
	font-size: 12px;
	font-weight: $fw-medium;
	color: var(--text600);

	font-family: system-ui, sans-serif;
  	font-variation-settings: "wght" 500;
  	letter-spacing: 0.008em;

	// background: $tempr;
	height: 24px;
	padding-left: 5px;
	padding-right: 5px;
	cursor: pointer;
	border-radius: 3px;

	// flex-grow: 1;
	// flex-shrink: 1;
}

.dropdownMenu--selectTransition-groupingRow-smallLabelBtn:hover{
	background: var(--250gray);
}

.dropdownMenu--selectTransition-groupingRow-smallLabelBtn[data-state="active"]{
	opacity: 0.75;
}

.dropdownMenu--selectTransition-groupingRow-smallLabelBtn[data-state="active"]:hover{
	opacity: 0.95;
}

// .rightPanel:hover
// .dropdownMenu--selectTransition-groupingRow-smallLabelBtn[data-state="active"]{
// 	opacity: 0.9;
// }

// .rightPanel:hover
// .dropdownMenu--selectTransition-groupingRow-smallLabelBtn[data-state="active"]:hover{
// 	opacity: 1;
// }

.dropdownMenu--selectTransition-groupingRow-smallLabelBtn[data-state="inactive"]{
	display: flex;
	opacity: 0.45;
}


.dropdownMenu--selectTransition-groupingRow-smallLabelBtn[data-state="inactive"]:hover{
	display: flex;
	opacity: 0.8;
}


//
// SLIDE

.editor-topBar--slide-textOpacitySliderContainer{
	width: 150px;
}


//
// ZOOM

.editor-topBar--zoom-zoomLevel{
	width: 100%;
	display: flex;
	align-items: center;
	height: 28px;
	max-width: 320px;
}

.editor-topBar--zoom-zoomLevel-button{
	width: 32px;
	height: 28px;
	//background: var(--251gray);
	margin-left: 2px;
	margin-right: 2px;
	border-radius: 4px;
	// background: red;
}

.editor-topBar--zoom-zoomLevel-button:hover{
	background: var(--248gray);	
}

.editor-topBar--zoom-zoomLevel-button svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
}

.editor-topBar--zoom-zoomLevel-button:hover
.editor-topBar--zoom-zoomLevel-button svg{
	fill: var(--text600);
}

.editor-topBar--zoom-zoomLevel
.uiSlider{
	height: 25px;	
}

.editor-topBar--zoom-zoomLevel
.uiSlider-label{
	justify-content: center;
	margin-left: 0px;

	font-size: 14px;
  font-weight: 500;
  color: var(--text500);
  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;
}

.editor-topBar--zoom-zoomLevel
.uiSlider-slider-slider-track.uiSlider-slider-slider-track-0{
	background: lighten(#3a3d45, 10%)
}

.editor-topBar--zoom-zoomLevel .uiSlider:hover
.uiSlider-slider-slider-track.uiSlider-slider-slider-track-0,
.editor-topBar--zoom-zoomLevel[data-state="dragging"] .uiSlider
.uiSlider-slider-slider-track.uiSlider-slider-slider-track-0{
	background: lighten(#3a3d45, 25%)
}


.editor-topBar--slideBar-input{
	width: 250px;
	height: 24px;
	padding-left: 8px;
	box-shadow: none;
	border: none;
	border-radius: 2px;

	background: var(--250gray);

	font-size: 14px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;

}

input.editor-topBar--slideBar-input:focus{
	outline: none;
	background: var(--248gray);
	//outline: 1px solid var(--line200);
}

.editor-topBar--slideBar-input::placeholder{
	color: var(--text400);
}


.dpButton--addZoomBtn[data-state='pending']{
	opacity: 0.5;
	cursor: default;
}

.dpButton--addZoomBtn[data-state='exists']{
	opacity: 1;
}

.dpButton--addZoomBtn[data-state='none']{
	opacity: 0.75;
}