
:root{	
	--textSelectionBlue: #C3D7FF;
}

// DARK THEME
// goes brighter as you go up the stack, i.e. 600 is brightest white, 000 is pure black

[data-theme="dark"], .forceDarkTheme{
 --textSelectionBlue: #3B5B8D;
}