

.editor-timeline-clip-webcamPhaseContainer{
  position: absolute;
  top: 0px;
  margin-top: 1px;
  height: 100%;

  pointer-events: none;

  z-index: 150;

}

.editor-timeline-clip-webcamPhase-handle--draggable{
	background: $tempr;
	z-index: 150;
	pointer-events: auto;
	opacity: 0;
}

.editor-timeline-clip-webcamPhase-handle--render{
	// background: pink;
	
}

.editor-timeline-clip-webcamPhase-handle-inner{
	width: 100%;
	height: 100%;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

}

.editor-timeline-clip-webcamPhase-handle-bar{
	width: 3px;
  // background: red;
  height: calc(100% - 8px);

  border-radius: 2px;

  // background: red;
  opacity: 0.6;
}

.editor-timeline-clip-webcamPhase-handle-bar[data-clip-type='screenVideo']{
  background: lighten(rgba(110,99,217,1), 24%);
}

.editor-timeline-clip-webcamPhase-handle--draggable:hover + 
.editor-timeline-clip-webcamPhase-handle--render[data-dragging-state='notDragging']
.editor-timeline-clip-webcamPhase-handle-bar{
	opacity: 0.8;	
	background: lighten(rgba(110,99,217,1), 28%);
}

.editor-timeline-clip-webcamPhase-handle--render[data-dragging-state='dragging']
.editor-timeline-clip-webcamPhase-handle-bar{
	opacity: 1;
	background: lighten(rgba(110,99,217,1), 32%);
}

.editor-timeline-clip-webcamPhase-handle:hover{
	background: green;
}

.editor-timeline-clip-webcamPhase-buttonContainer{
	// background: $tempb;
	// outline: 1px solid red;

	display: flex;
	align-items: center;
	justify-content: center;


}

.editor-timeline-clip-webcamPhase-button{
	padding-left: 12px;
	padding-right: 12px;
	height: 24px;

	flex-grow: 0;
	flex-shrink: 1;


	border-radius: 2px;

	pointer-events: auto;
	opacity: 0.9;
	position: relative;
	z-index: 5;

	 max-width: 100%;    /* Ensure button doesn't exceed container width */
  overflow: hidden;   /* Hide overflow content */
  display: flex;      /* Enable flexbox for better control of children */
  align-items: center;
}



.editor-timeline-clip-webcamPhase-button-iconContainer{
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -2px;

	margin-right: 5px;
}

.editor-timeline-clip-webcamPhase-button-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: white;

	opacity: 0.65;
}

.editor-timeline-clip-webcamPhase-button-label{
	font-size: 13.75px;  
  color: var(--text500);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 420;
  font-variation-settings: "wght" 470;
  //letter-spacing: 0.012em;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;


  flex-grow: 1;
  flex-shrink: 1;

  // margin-top: -1px;  
}

.editor-timeline-clip-webcamPhase-button-label-sub{
	opacity: 0.75;
	margin-left: 0.4em;
}

.editor-timeline-clip-webcamPhase-button:hover{	
	background: rgba(255,255,255,0.07);
	opacity: 1;
}

.editor-timeline-clip-webcamPhase-splitButton{
	position: absolute;
	left: 6px; // half of handle width
	top: 2px;
	bottom: 2px;

	background: tempr;
	outline: 1px solid red;
	border-radius: 3px;
	width: 24px;

	margin-left: 5px;

	pointer-events: auto;
}

.editor-timeline-clip-webcamPhase-splitButton[data-first-phase='true']
.editor-timeline-clip-webcamPhase-splitButton{
	left: 0px;
}

.editor-timeline-clip-webcamPhase-buttonContainer-lineContainer{
	flex-grow: 1;
	flex-shrink: 1;
	min-width: 10px;

	// background: $tempr;
	height: 100%;
	box-sizing: border-box;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-left: 10px;
	padding-right: 10px;
}


.editor-timeline-clip-webcamPhase-buttonContainer-line{
	width: 100%;
	height: 1.5px;	
	border-radius: 2px;
	opacity: 0.5;
}

.editor-timeline-clip-webcamPhase-buttonContainer[data-clip-type='screenVideo']
.editor-timeline-clip-webcamPhase-buttonContainer-line{
	background: lighten(rgba(110,99,217,1), 4%);
}


.editor-timeline-clip-webcamPhase-buttonContainer-lineContainer--right{
	padding-left: 4px;
}

.editor-timeline-clip-webcamPhase-buttonContainer-lineContainer--left{
	padding-right: 4px;
}