
.editor-slideEditor-dragSelect{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 25;
  cursor: pointer;
}

.editor-slideEditor-dragSelect-area{
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;  
  // background: rgba(255,255,255, 0.15);
  // border: 1px solid rgba(255,255,255,0.75);
  background: transparentize($figmaBlue, 0.9);
  border: 1px solid transparentize($figmaBlue, 0.25);
  // border: 1px solid $figmaBlue;
}


.editor-slideEditor[data-ui-color-mode='yellow']
.editor-slideEditor-dragSelect-area{
  background: rgba(255,255,255, 0.10);
  border: 1px solid rgba(255,255,255,0.25);
}