
.editor-statusBar-exportLinkBtnContainer{
  width: 110px;  
  height: 27px;
  margin-top: 1px;
  margin-right: 4px;
  position: relative;

  // background: $tempr;
}

.editor-statusBar-exportLinkBtn{  
  background: none;
  
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100%;

  font-size: 14px;
  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 500;
  letter-spacing: 0.008em;

  border-radius: 3px;

  -webkit-app-region: no-drag;
}

.editor-statusBar-exportLinkBtn:disabled{
  pointer-events: none;
  opacity: 0.5;
}

.editor-statusBar-exportLinkBtn[data-export-status='pre']{
  background: var(--250gray);
  border: 1px solid var(--line200);
  color: var(--text500);
}

.editor-statusBar-exportLinkBtn[data-export-status='pre']:hover{
  background: var(--246gray);
  border: 1px solid var(--line300);
  color: var(--text600);
}

.editor-statusBar-exportLinkBtn{
  background: #4584EA;
  border: 1px solid #7EAFFF;
  color: var(--text500);
}


// .editor-statusBar-exportLinkBtn[data-export-status='completed']:hover{
//   background: var(--246gray);
//   border: 1px solid var(--line300);
//   color: var(--text600);
// }



// .editor-statusBar-exportLinkBtn:hover{
//   background: var(--246gray);
//   border: 1px solid var(--line300);
//   color: var(--text600);
// }


.editor-statusBar-exportLinkBtn
.editor-statusBar-exportLinkBtn-label{
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}


//
// SPINNER FOR ENQUEUED AND DOWNLOADING

.editor-statusBar-exportLinkBtn-spinnerContainer{
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  // background: $tempr;
}

.editor-statusBar-exportLinkBtn-spinner{
  height: 14px;
  width: 14px;
  
  border-radius: 36px;

  border: 2.5px rgba(255,255,255,0.3) solid;
  border-top: 2.5px rgba(255,255,255,1) solid;

  opacity: 1;

  animation: spinnerRotate 0.6s infinite linear;
}


//
// COMPLETED

$width-exportBtn-openFolder: 28px;

.editor-statusBar-exportLinkBtn[data-export-status='completed']{
  background: #329C62;
  border: 1px solid #72C899;
  color: var(--text500);
  position: absolute;
  left: 0px;
  top: 0px;
  right: $width-exportBtn-openFolder;
  height: 100%;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.editor-statusBar-exportLinkBtn[data-export-status='completed']:hover{
  background: lighten(#329C62, 3%);
}

.editor-statusBar-exportLinkBtn[data-export-status='completed']
.editor-statusBar-exportLinkBtn-label{
  color: white;
}

.editor-statusBar-openFolderBtn{
  position: absolute;
  right: 0px;
  top: 0px;  
  height: calc(100% + 0px);
  border-top: 1px solid #72C899;
  border-right: 1px solid #72C899;
  border-left: 1px solid #72C899;
  border-bottom: 1px solid #72C899;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  // background: red;
  width: calc($width-exportBtn-openFolder + 1px);
  background: #329C62;
}

.editor-statusBar-openFolderBtn:hover{
  background: lighten(#329C62, 3%);  
}

.editor-statusBar-openFolderBtn
.editor-statusBar-openFolderBtn-iconContainer{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-statusBar-openFolderBtn
.editor-statusBar-openFolderBtn-iconContainer svg{
  width: 18px;
  height: 18px;
  opacity: 0.75;
  transform: scale(0.9);
  fill: white;
}


//

// .editor-statusBar-exportLinkBtn[data-export-status='generating']{
//   background: #4584EA;
//   border: 1px solid darken(#4584EA, 5%);
//   color: white;
//   font-feature-settings: 'tnum';
//   letter-spacing: -0.012em;
//   font-variation-settings: "wght" 540;
//   font-size: 14.5px;
// }

//
//

.editor-statusBar-exportLinkBtn-progressBarContainer{
  position: absolute;
  left: 0px;
  right: 0px;
  height: 100%;
  top: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding-bottom: 1px;
  
}

.editor-statusBar-exportLinkBtn-progressBarInnerContainer{
  width: 18px;
  height: 18px;
  // background: $tempr;
  transform: translateX(-20px);
}

.editor-statusBar-exportLinkBtn-progressBarContainer 
.CircularProgressbar {
  width: 18px;
  height: 18px;
}

.editor-statusBar-exportLinkBtn-progressBarContainer 
.CircularProgressbar .CircularProgressbar-path {  
  stroke: rgba(255,255,255,0.8);
  stroke-linecap: butt;
  transition: stroke 200ms linear;
}

.editor-statusBar-exportLinkBtn-progressBarContainer 
.CircularProgressbar .CircularProgressbar-trail { 
  // stroke: transparentize(#516CE8, 0.8);
  // stroke: rgba(255,255,255,0.2);
  stroke: rgba(255,255,255,0.15);
  stroke-linecap: butt;
  transition: stroke 200ms linear;
}

.editor-statusBar-exportLinkBtn[data-export-status='inprogress']
.editor-statusBar-exportLinkBtn-label{
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -0.5px;
  box-sizing: border-box;  
  transform: translateX(10px);
}

// //

.editor-statusBar-exportLinkBtn-devContainer{
  position: absolute;
  top: 100%;
  left: 0px;

  margin-top: 10px;

  width: 140px;
  height: 100px;
  background: black;
  border: 1px solid white;
}

.editor-statusBar-exportLinkBtn-devContainer button{
  color: white;
}

// .editor-statusBar-exportLinkBtn[data-export-status='ready']{
//   background: darken(#549F76, 8%);
//   border: 1px solid #79DFA7;
//   color: white;
//   font-variation-settings: "wght" 540;
// }

// .editor-statusBar-exportLinkBtn-iconContainer{
//   position: absolute;
//   left: 16px;
//   top: 5px;
//   width: 15px;
//   height: 15px;
// }

// .editor-statusBar-exportLinkBtn-iconContainer svg{
//   fill: white;
//   height: 15px;
//   width: 15px;
//   opacity: 0.95;
// }

// .editor-statusBar-exportLinkBtn[data-export-status='ready']
// .editor-statusBar-exportLinkBtn-label{
//   position: absolute;
//   left: 35%;
//   right: 0px;
//   top: 0px;
//   height: 100%;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   margin-top: -0.5px;
// }