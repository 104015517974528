.libraryPage-project-footer{
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	padding-left: 6px;
	padding-right: 6px;

	opacity: 1;
	transition: opacity 200ms linear;
}


.libraryPage-project--loading .libraryPage-project-footer,
.libraryPage-project--locked .libraryPage-project-footer{
  opacity: 0.5;
  transition: opacity 200ms linear;
}


.libraryPage-project-footer-primary{
	display: flex;
	padding-top: 12px;
	margin-bottom: 1px;
}

.libraryPage-project-footer-primary-title{
	flex-grow: 1;
	flex-shrink: 1;

	font-size: 15.5px;
	font-weight: $fw-medium;
	color: var(--text600);

	cursor: pointer;
}

.libraryPage-project-footer-primary-dropdownBtn{
	width: 32px;
	height: 26px;
	margin-top: -4px;
	margin-right: -4px;
	margin-bottom: -2px;

	opacity: 0;
	
	border-radius: 2px;
}

.libraryPage-project--loaded.libraryPage-project--notLocked:hover
.libraryPage-project-footer-primary-dropdownBtn{
	opacity: 1;
	
}

.libraryPage-project-footer-primary-dropdownBtn:hover{
	background: var(--252gray);
}

.libraryPage-project-footer-primary-dropdownBtn svg{
	width: 18px;
	height: 18px;

	fill: white;
	opacity: 0.6;
}

.libraryPage-project-footer-secondary{
	display: flex;

	font-size: 15px;
	font-weight: $fw-regular;
	color: var(--text600);

	opacity: 0.7;
	pointer: default;
	user-select: none;
}