
.editor-transcriptPanel-voiceHeaderGutter{
  position: absolute;
  top: 0px;
  left: 0px;

  width: 100%;
  bottom: 0px;
  height: 100%;

  overflow-x: hidden;
  overflow-y: hidden;
}

.editor-transcriptPanel-voiceHeaderGutter-dropdownBtn{
  position: absolute;
  left: 0px;

  width: 22px;
  
  box-sizing: border-box;
  
  display: flex;
  align-items: flex-start;
  justify-content: center;

  box-sizing: border-box;


  z-index: 100;
  opacity: 0.25;

  user-select: none !important;
  padding-top: 8px;
  padding-left: 4px;
}



.editor-transcriptPanel-voiceHeaderGutter-dropdownBtn-rect{
  width: 10px;
  height: 10px;
  border-radius: 3px;
  //border: 1px solid var(--line600);
  background: var(--text400);  
  transform: scale(0.85);
}

.editor-transcriptPanel-voiceHeaderGutter-dropdownBtn:hover{
  opacity: 0.7;
}

.editor-transcriptPanel-voiceHeaderGutter-dropdownBtn[data-state='open']{
  opacity: 0.9;
}

// .editor-transcriptPanel-voiceHeaderGutter-dropdownBtn-rect[data-near-top='true']{
//   background: red;
// }