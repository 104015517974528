.scenePlayground{
	width: 100%;
	height: 100%;
	// background: $tempr;

	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.scenePlayground-timeline{
	//width: calc(100% - 100px);
	width: calc(100%);
	
	background: $tempr;
	position: relative;

	height: 200px;
}

.scenePlayground-modeToggle{
	position: absolute;
	top: 50px;
	left: 50px;
	width: 200px;
	height: 100px;
	background: $tempr;
}

.scenePlayground-modeToggle[data-state="active"]{
	background: red;
	color: white;
}

//


.editor-timeline-snapLine,
.editor-timeline-dropLine{
	position: absolute;
	width: 1px;
	
	background: rgb(200,200,205, 0.95);
	opacity: 1;

	top: 14px;
	bottom: 5px;

	// height: 100%;

	z-index: 60;
}

.editor-timeline-dropLine{
	background: blue;
	opacity: 0;
}

$activeDropLineBG: rgb(220,220,225);

.editor-timeline-dropLine--active{
	// background: green;

	top: 38px;
	width: 2.5px;

	background: $activeDropLineBG;
	margin-left: -2px;
	opacity: 1;
}

.editor-timeline-dropLine--end{
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}


.editor-timeline-dropLine--start{
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}


.editor-timeline-dropLine--dragMid{
	background: red;
}

.editor-timeline-dropLine--originalMid{
	background: red;
	opacity: 0.25;
	opacity: 0;
}

.editor-timeline-dropLine--original{
	background: yellow;
}

.editor-timeline-dropLine-type{
	position: absolute;
	top: 0px;
	height: 100%;
	left: 0px;
}

.editor-timeline-dropLine-type-topBar{
	position: absolute;
	top: 0px;
	width: 8px;
	height: 2.5px;
	background: $activeDropLineBG;
}

.editor-timeline-dropLine-type-bottomBar{
	position: absolute;
	bottom: 0px;
	width: 8px;
	height: 2.5px;
	background: $activeDropLineBG;
}

.editor-timeline-dropLine-type--end{

}

.editor-timeline-dropLine-type--end
.editor-timeline-dropLine-type-topBar{
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.editor-timeline-dropLine-type--end
.editor-timeline-dropLine-type-bottomBar{
	border-bottom-right-radius: 2px;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.editor-timeline-dropLine-type--start
.editor-timeline-dropLine-type-topBar{
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.editor-timeline-dropLine-type--start
.editor-timeline-dropLine-type-bottomBar{
	border-bottom-left-radius: 2px;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}



.editor-timeline-dropLine-type--end
.editor-timeline-dropLine-type-topBar,
.editor-timeline-dropLine-type--end
.editor-timeline-dropLine-type-bottomBar{
	right: 0px;
}

.editor-timeline-dropLine-type--start
.editor-timeline-dropLine-type-topBar,
.editor-timeline-dropLine-type--start
.editor-timeline-dropLine-type-bottomBar{
	left: 0px;
}

//

.draggableTrackClip-inner,
.DNDPreviewTrackClip,
.DNDActiveTrackClip{
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
}

.draggableTrackClip-inner{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}


.draggableTrackClip{
	height: 30px;
	background: $tempr;
	border: 2px solid red;	
	box-sizing: border-box;
	z-index: 50;
}



.DNDPreviewTrackClip,
.DNDActiveTrackClip{
	height: 30px;
	background: $tempg;
	position: absolute;
	top: 40px;
	pointer-events: none;
	border: 2px solid green;	
	box-sizing: border-box;
	z-index: 50;
}

.DNDActiveTrackClip{
	background: green;
}

//




@import 'Timeline-VOPage';