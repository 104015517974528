
.editor-slideEditor-resizeableHandleRnd{
  position: absolute;
  // background: $tempr;
  z-index: 9000; 
}

.editor-slideEditor-resizeableHandle{
  position: absolute;
  

  pointer-events: none;
  z-index: 9000;
  // background: $tempb;
  // opacity: 0;
}

.editor-slideEditor-resizeableHandle--corner{
  //background: green;
}


.editor-slideEditor-resizeableHandle:hover{
  //background: $specialYellow;
}


.editor-slideEditor-resizeableHandleBlock{
  position: absolute;
  //background: $tempb;
  pointer-events: none;

  z-index: 20;
}

.editor-slideEditor-resizeableHandle-inner{
  // background: $tempr;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-slideEditor-resizeableHandle-inner-cornerBox{
  // width: 10px;
  // height: 10px;
  // background: $specialYellow;
  // background: $figmaBlue;
  background: white;
  border: 1px solid $figmaBlue;
}

.editor-slideEditor[data-ui-color-mode='yellow']
.editor-slideEditor-resizeableHandle-inner-cornerBox{
  background: black;
  background: $specialYellow;
  border: 1px solid $specialYellow;
}

.editor-slideEditor-resizeableHandle-inner-textBoxHandle{
  // width: 10px;
  // height: 10px;
  // background: $specialYellow;
  // background: $figmaBlue;
  background: white;
  border: 1px solid $figmaBlue;
  
}


.editor-slideEditor[data-ui-color-mode='yellow']
.editor-slideEditor-resizeableHandle-inner-textBoxHandle{
  background: black;
  background: $specialYellow;
  border: 1px solid $specialYellow;
}


