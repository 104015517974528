.editor-toolbar{
	height: $height-toolbar;
  width: 100%;  	


	display: flex;
	align-items: center;  

  flex-grow: 0;
  flex-shrink: 0;  

  box-sizing: border-box;
  padding-left: 4px;
  padding-right: 4px;

  position: relative;
  z-index: 10; 

  background: var(--bg-editorToolbar);
  background: var(--bg-transcriptPanel);


}


// TEST OVERLAP LEFT
// .editor-toolbar-farLeftContainer{
//   position: absolute;
//   left: calc(-1 * #{$width-centerLeftContainer});
//   width: $width-centerLeftContainer;
//   top: 0px;
//   height: 100%;
  

//   display: flex;
//   align-items: center;

//   box-sizing: border-box;
//   padding-left: 4px;
// }

.editor-toolbar-hSpacer{
  min-width: 10px;
  flex-grow: 1;
  flex-shrink: 1;
}

.editor-toolbar-leftContainer,
.editor-toolbar-rightContainer{  
  height: 100%;

  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  align-items: center;

  opacity: 1;
  transition: opacity 200ms linear;
}

.editor-toolbar-centerContainer{
  position: absolute;
  left: 0px;
  right: $width-detailPanelContainer;
  pointer-events: none;
  top: 0px;
  bottom: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

}

.editor-toolbar-farRightContainer{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: $width-detailPanelContainer;
  height: 100%;

  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-right: 4px;
}

.editor-toolbar--isEmpty .editor-toolbar-leftContainer,
.editor-toolbar--isEmpty .editor-toolbar-btn--dropdown,
.editor-toolbar--isEmpty .editor-toolbar-btn--splitClip,
.editor-toolbar--isEmpty .editor-toolbar-btn--addTrim{
  opacity: 0.25;
  pointer-events: none;
}

.editor-toolbar-rightContainer{
  justify-content: flex-end;
  box-sizing: border-box;
  padding-right: 6px;

  column-gap: 0px;
}


.editor-toolbar-rightContainer{
  opacity: 1;  
  transition: opacity 150ms linear;
}

.editor-toolbar[data-read-only='true'] .editor-toolbar-rightContainer{
  opacity: 0.15;  
}

.editor-toolbar[data-read-only='true'] .editor-toolbar-rightContainer,
.editor-toolbar[data-read-only='true'] .editor-toolbar-rightContainer *{
  pointer-events: none !important;
}


.editor-toolbar-rightEdgeContainer{
  width: $width-centerRightContainer;
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  box-sizing: border-box;
  padding-right: 8px;
}


@import 'EditorToolbar-InsertBar';




//

.editor-toolbar-playBtn,
.editor-toolbar-restartBtn{  
  //height: calc(100% - 15px);
  height: 28px;

  background: var(--246gray);
  border-radius: 3px;
  position: relative;
}

.editor-toolbar-playBtn{
  width: 54px;
}

.editor-toolbar--beginnerMode .editor-toolbar-playBtn{
  width: 52px;
  margin-right: 12px;
}





.editor-toolbar-restartBtn{
  margin-left: 2px;
  width: 40px;
  background: none;
  // background: $tempr;
}


.editor-toolbar-playBtn svg,
.editor-toolbar-restartBtn svg{
  width: 16px;
  height: 16px;

  fill: var(--text500);
}

.editor-toolbar-playBtn svg.icon--name--playbackPlay,
.editor-toolbar-playBtn svg.icon--name--playbackPause{
  position: relative;  
}

.editor-toolbar--proMode
.editor-toolbar-playBtn svg.icon--name--playbackPause{
  left: -1px;
}

.editor-toolbar--proMode
.editor-toolbar-playBtn svg.icon--name--playbackPlay{
  left: 0px;
}


.editor-toolbar--beginnerMode
.editor-toolbar-playBtn svg.icon--name--playbackPause{
  left: 0px;
}

.editor-toolbar--beginnerMode
.editor-toolbar-playBtn svg.icon--name--playbackPlay{
  left: 1px;
}



//

.editor-toolbar-restartBtn svg{
  height: 18px;
  width: 18px;
  position: relative;
  top: 1px;

  opacity: 0.5;
}

.editor-toolbar-restartBtn:hover svg{
  opacity: 1;
}

.editor-toolbar-toolbarBtnShortcutContainer{
  position: absolute;
  width: 100px;
  left: 50%;
  transform: translateX(-50%) translateY(4px);

  bottom: 100%;
  margin-bottom: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;
  opacity: 0;
  transition: all 50ms linear;
}

.editor-toolbar-playBtn:hover
.editor-toolbar-toolbarBtnShortcutContainer,
.editor-toolbar-restartBtn:hover
.editor-toolbar-toolbarBtnShortcutContainer,
.editor-toolbar-btn--addZoom:hover
.editor-toolbar-toolbarBtnShortcutContainer,
.editor-toolbar-btn--splitClip:hover
.editor-toolbar-toolbarBtnShortcutContainer{
  transform: translateX(-50%) translateY(0px);
  opacity: 1;
  transition: all 50ms linear;
}



.editor-toolbar-btn--addZoom:hover
.editor-toolbar-toolbarBtnShortcutContainer,
.editor-toolbar-btn--splitClip:hover
.editor-toolbar-toolbarBtnShortcutContainer{
  transition-delay: 1000ms;
}

.editor-toolbar-playBtn
.editor-toolbar-toolbarBtnShortcutContainer,
.editor-toolbar-restartBtn
.editor-toolbar-toolbarBtnShortcutContainer{
  margin-bottom: 3px;
}


.editor-toolbar-toolbarBtnShortcut{


  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  background: var(--bg-tabBar);
  height: 21px;
  // padding-left: 6px;
  // padding-right: 6px;
  border: 1px solid var(--line150);
  border-radius: 3px;

  width: 50px;
  // opacity: 0;
  // transform: translateX(3px);
  // transition: all 50ms linear;
}


.editor-toolbar-toolbarBtnShortcutContainer--space
.editor-toolbar-toolbarBtnShortcut{
  width: 60px;
}


.editor-toolbar-toolbarBtnShortcut-iconContainer{
  width: 13px;
  height: 13px;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-toolbar-toolbarBtnShortcut-iconContainer svg{
  width: 13px;
  height: 13px;
  fill: var(--text600);
}

.editor-toolbar-toolbarBtnShortcut-letter{
  font-size: 13.5px;
  font-weight: $fw-medium;
  color: var(--text500);
  margin-right: 3px;
}

//

.editor-toolbar-toolbarBtnFixedShortcut{
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  
  // background: $tempr;
  margin-right: -3px;
  margin-left: 4px;

  opacity: 0.5;
  transform: scale(0.95);
}

.editor-toolbar-toolbarBtnFixedShortcut{
  margin-left: 5px;
  margin-right: -4px;
}

.editor-toolbar-toolbarBtnFixedShortcut-iconContainer{
  width: 14px;
  height: 14px;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-toolbar-toolbarBtnFixedShortcut-iconContainer svg{
  width: 14px;
  height: 14px;
  fill: var(--text600);
}

.editor-toolbar-toolbarBtnFixedShortcut-letter{
  font-size: 14px;
  font-weight: $fw-medium;
  color: var(--text500);
}

//

.editor-toolbar-timestampsContainer{
  margin-left: 0px;
  display: flex;
  pointer-events: none;
  user-select: none;
}

.editor-toolbar-timestamp{
  font-size: 14px;
  color: var(--text500);

  width: 46px;

  font-variation-settings: "wght" 440;
  font-feature-settings: "tnum", "ss01", "ss02";
  // letter-spacing: -0.003em;  
}

.editor-toolbar--proMode
.editor-toolbar-timestamp{
  width: 75px;
}

.editor-toolbar-timestamp--current{
  opacity: 0.9;
  font-variation-settings: "wght" 480;

}

.editor-toolbar-timestamp--duration{
  opacity: 0.5;
}


//



//@import 'EditorToolbar-Playback';

//

.editor-toolbar-separator{
  height: 24px;  

  width: 1px;
  background: var(--text500);
  opacity: 0.2;
}

//
// Button

.editor-toolbar-btn{
  padding-left: 5px;
  padding-right: 11px;

  height: 28px;

  font-size: 14px;
  font-weight: $fw-medium;
  color: var(--text300);
  
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;
  
  border-radius: 3px;

  // opacity: 0.75;
  background: var(--251gray);
  position: relative;
  // background: var(--toolbarBtnBG);
  // border: var(--toolbarBtnBorder);
  // border-top: var(--toolbarBtnBorderTop);
  // border-bottom: var(--toolbarBtnBorderBottom);

  // box-shadow: var(--toolbarBtnBoxShadow);
  //background: var(--240gray);
}




.editor-toolbar-btn.editor-toolbar-btn--noBG{
  background: none;
}

.editor-toolbar-btn--insert{
  padding-right: 12px;
}

.editor-toolbar-btn--textOnly{
  padding-left: 10px;
  padding-right: 10px;
  min-width: 38px;
}

.editor-toolbar-btn--iconOnly{
  width: 34px;
  padding-left: 0px;
  padding-right: 0px;
}

.editor-toolbar-btn--splitClip{
  padding-right: 13px;
  position: relative;

  font-size: 13.5px;
}

.editor-toolbar-btn--addTrim{
  padding-right: 13px;
  position: relative;

  font-size: 13.5px;
  margin-right: 12px;
}

.editor-toolbar-btn:hover{
  background: var(--248gray);
  opacity: 1;
  color: var(--text500);

  // border: var(--toolbarBtnBorderHover);
  // border-top: var(--toolbarBtnBorderTopHover);
  // border-bottom: var(--toolbarBtnBorderBottomHover);
}

.editor-toolbar-btn[data-state="open"]{
  background: var(--246gray);
  opacity: 1;
}

.editor-toolbar-btn--addTrim{
  transition: opacity 50ms linear;
}

.editor-toolbar-btn--addTrim[data-active-state='active']{
  background: var(--246gray);
  opacity: 1;
}

.editor-toolbar-btn:disabled{
  pointer-events: none !important;
  opacity: 0.3 !important;
}

.editor-toolbar-btn--compress{
  padding-left: 2px;
  margin-left: 0px;
  padding-right: 12px;
  margin-right: 2px;
}


.editor-toolbar-btn-iconContainer{
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
}


.editor-toolbar-btn--insert
.editor-toolbar-btn-iconContainer{
  margin-left: -2px;
  width: 23px;
}




.editor-toolbar-btn-backdropPreview{
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background: #416FE2; // temp
  background: white;

  margin-right: 5px;
}

.editor-toolbar-btn-iconContainer svg{
  width: 16px;
  height: 16px;
  fill: var(--text500);

  opacity: 0.6;
}

.editor-toolbar-btn:hover
.editor-toolbar-btn-iconContainer svg{
  opacity: 0.9;
}

.editor-toolbar-btn[data-state="open"]
.editor-toolbar-btn-iconContainer svg{  
  opacity: 1;
}


.editor-toolbar-btn-iconContainer svg.icon--name--fitTimeline{
  width: 20px;
  height: 20px;
  transform: scale(1.04);
}

.editor-toolbar-btn-iconContainer svg.icon--name--eraser{
  width: 18px;
  height: 18px;
}


.editor-toolbar-btn-iconContainer svg.icon--name--transcript{
  width: 18px;
  height: 18px;
}

.editor-toolbar-btn-iconContainer svg.icon--name--musicNotes{
  transform: scale(1.04);
}

.editor-toolbar-btn-iconContainer svg.icon--name--insertBarImage{
  width: 20px;
  height: 20px;
  position: relative;
  left: -1px;
}

.editor-toolbar-btn-iconContainer svg.icon--name--splitClip{
  position: relative;
  //top: 2px;
  //left: 1px;
  width: 20px;
  height: 20px;

  transform: scale(0.8);
  transform-origin: center center;
}

.editor-toolbar-btn--splitClip
.editor-toolbar-btn-iconContainer{
  margin-right: 3px;
}

.editor-toolbar-btn-iconContainer svg.icon--name--splitClip #sides{
  opacity: 0.7;
}

.editor-toolbar-btn-iconContainer svg.icon--name--trackBacking{
  width: 18px;
  height: 18px;
}

.editor-toolbar-btn-label-sub{
  opacity: 0.6;
}


.editor-toolbar-btn--library{
  //background: $tempr !important;
  padding-right: 15px;
  padding-left: 9px;
}

.editor-toolbar-btn--library
.editor-toolbar-btn-label,
.editor-toolbar-btn--addTrim
.editor-toolbar-btn-label{
  font-size: 13.75px;
  font-size: 14px;
  font-weight: $fw-medium;
  color: var(--text500);
  
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;
}

.editor-toolbar-btn--library
.editor-toolbar-btn-iconContainer svg.icon--name--addFromLibrary{
  //transform: scale(0.9);
  position: relative;
  top: 1.2px;
  margin-right: 4px;
  opacity: 0.85;
}



.editor-toolbar-btn--addTrim[data-active-state='active']
.editor-toolbar-btn-iconContainer svg{
  opacity: 1;
}

.editor-toolbar-btn--addTrim[data-active-state='active']
.editor-toolbar-btn-label{
  color: var(--text600);
  opacity: 1;
}

.editor-toolbar-btn--addTrim
.editor-toolbar-btn-iconContainer svg{
  transform: scale(0.92);
}

.editor-toolbar-btn--addTrim
.editor-toolbar-btn-iconContainer{
  margin-right: 2px;
}



// ZOOM

.editor-toolbar-zoomBtnSet{
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 4px;
}

.editor-toolbar-zoomIconContainer{
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 0px;
  margin-right: 2px;
}

.editor-toolbar-zoomIconContainer svg{
  width: 16px;
  height: 16px;

  fill: var(--text500);
  opacity: 0.8;
}


.editor-toolbar-btn--zoomBtn{
  padding-left: 4px;
  padding-right: 4px;
  min-width: 28px;
  height: 24px;
  // background: $tempr;
}

.editor-toolbar-btn--zoomBtn[data-state="active"]{
  color: var(--text600);
  opacity: 1;
  background: var(--246gray);
}

.editor-toolbar-btn--zoomBtn[data-state="inactive"]:not(:hover){
  opacity: 0.4;  
}

.editor-toolbar-btn--zoomBtnPrimary{
  padding-left: 8px;
  padding-right: 8px;
}

@import 'EditorToolbar-ZoomSlider';

@import 'EditorToolbar-AddMediaPopover';

