$width-dpNumberInputLeft: 25px;
$relaxedWidth-dpNumberInputLeft: 27px;

.dpNumberInput{
	height: $height-dpElement;
	// // width is set in JS
	position: relative;

	background: var(--250gray);
	border-radius: 3px;

	box-sizing: border-box;
	border: 1px solid $invisible;
}

.dpNumberInput:hover{
	background: rgba(244,240,255,0.065);
	border: 1px solid var(--248gray);
}

.dpNumberInput[data-input-focused='true']{
	//background: rgba(244,240,255,0.065);
	border: 1px solid $focusBorderColor-dpElement;
}

.dpNumberInput[data-disabled='true']{
	opacity: 0.5;
	pointer-events: none;
}


//


.dpNumberInput-dragContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: $width-dpNumberInputLeft;
	// background: $tempr;
	cursor: ew-resize;

	opacity: 0.5;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;	
}

.dpNumberInput[data-relaxed-width='true']
.dpNumberInput-dragContainer{
	width: $relaxedWidth-dpNumberInputLeft;
}



.dpNumberInput[data-disabled='true']{
	opacity: 0.5;
	pointer-events: none;
}



.dpNumberInput-dragContainer:hover,
.dpNumberInput-dragContainer[data-dragging='true']{
	opacity: 0.7;
}

.dpNumberInput-dragContainer-label{
	font-size: 12px;
	color: var(--text500);
  font-variation-settings: "wght" 520;    
  position: relative;
  top: 1px;
}

.dpNumberInput-dragContainer svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
}

.dpNumberInput-dragContainer svg.icon--name--cornerRadius{
	transform: scale(0.9);
}

.dpNumberInput-dragContainer svg.icon--name--speaker{
	width: 16px;
	height: 16px;
	transform: scale(1.04);
	position: relative;
	left: 1px;
	top: 0.5px;
}

.dpNumberInput-dragContainer svg.icon--name--aaText{
	transform: scale(1.2);
	position: relative;
	top: 0.5px;
}


input.dpNumberInput-input{
	position: absolute;
	left: $width-dpNumberInputLeft;
	right: 0px;	

	height: $height-dpElement;
  font-size: $baseFontSize-dpElement;	
	color: var(--text500);
	
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;

  border: none;
  outline: none;
  box-shadow: none;

  padding: 0px;
  background: none;

  box-sizing: border-box;
  padding-bottom: 2.5px;
  font-feature-settings: "ss01", "ss02";
}

.dpNumberInput[data-relaxed-width='true']
input.dpNumberInput-input{	
	left: $relaxedWidth-dpNumberInputLeft;
}


.dpNumberInput[data-state-mixed='true']
input.dpNumberInput-input{
	color: var(--text300);
}