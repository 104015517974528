.editor-timeline-sceneBounds{
	position: absolute;
	top: 0px;
	bottom: 0px;

	// background: darken(rgb(32,34,42), 2%);
	// border: 1px solid rgba(255,255,255,0.02);
	// background: $tempr;	

	z-index: 5;
}


.editor-timeline-sceneBoundsOutline{
	position: absolute;
	pointer-events: none;

	top: 36px;
	top: 39px;
	bottom: 0px;
	margin-left: -1px;


	background: darken(rgb(32,34,36), 2%);
	border: 1px solid lighten(rgb(32,34,36), 2%);


	// background: $tempr;

}

.editor-timeline--devMode
.editor-timeline-sceneBounds{
	background: $tempg;
	box-sizing: border-box;
	border: 2px solid green;
}



.editor-timeline-trackBG{
  width: 100%;
  margin-left: -1px;
  margin-bottom: 1px;
  // background: $tempr;
}

.editor-timeline-trackBG-inner{
  width: 100%;
  margin-top: -1px;
  background: $tempr;


  background: rgb(31,32,34);  
  border: 1px solid lighten(rgb(32,34,36), 2%);


  // opacity: 0.5;
}

.editor-timeline-trackBG:first-child{
	// opacity: 0;
	margin-bottom: 1px;
}

.editor-timeline-trackBG:first-child
.editor-timeline-trackBG-inner{
	position: relative;
	top: 13px;
	height: 18px;
	background: $tempr;
	border: none;
	
	opacity: 1;

	border-radius: 3px;
	background: rgba(255,255,255,0.02);
}


.editor-timeline-sceneTitleBtnContainer{
	position: absolute;
	height: 28px;

	top: -18px;
	top: -21px;
	// background: darken(rgb(67,65,76), 13%);
	// background: lighten(rgb(25,27,33), 1.5%);
	
	// border: 1px solid darken(rgb(98,100,110), 25%);
	//border-top: none;

	border-radius: 2px;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-sizing: border-box;
	padding-left: 2px;
	// padding-top: 3px;

  user-select: none;

  z-index: 10;
}

.editor-timeline-sceneTitleBtnContainer{
	position: absolute;
	height: 28px;

	top: -21px;

	z-index: 50;
	pointer-events: none;
}

.editor-timeline-sceneTitleBtn{
	pointer-events: none;

	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 14px;
	font-weight: $fw-medium;
	color: var(--text400);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.014em;


  opacity: 0.95;

  white-space: nowrap;
  
	position: sticky;
  left: -4px;
}

.editor-timeline-sceneTitleBtn-label{
	pointer-events: none;
	margin-right: 2px;
}

.editor-timeline-sceneTitleBtn-btn{
	height: 24px;
	width: 28px;
	border-radius: 3px;

	position: relative;
	right: -4px;

	pointer-events: auto;

	display: flex;
	align-items: center;
	justify-content: center;

	// position: sticky;
  // right: -8px;
	
	background: var(--bg-editorTimeline);
	z-index: 50;

	// background: $tempr;
}

.editor-timeline-sceneTitleBtn-btn svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.5;
}

.editor-timeline-sceneTitleBtn-btn{
	margin-left: auto;
}

.editor-timeline-sceneTitleBtn:hover
.editor-timeline-sceneTitleBtn-btn{
	opacity: 1;
}

.editor-timeline-sceneTitleBtn-btn:hover{
	background: lighten(rgb(27,28,29), 5%);
}

.editor-timeline-sceneTitleBtn-btn:hover svg{
	opacity: 0.8;
}

.editor-timeline-sceneTitleBtn-btn:hover{
	color: var(--text600);
}
	





@import 'EditorTimeline-Scenes-SceneHeader';

@import 'EditorTimeline-Scenes-SceneGapButtons';

.renderSceneHeader{
	position: absolute;

	height: 30px;
	background: rgba(255,0,255,0.25);
	width: 100%;
	
	top: -80px;
	pointer-events: none;
	outline: 2px solid rgba(255,0,255);	
	box-sizing: border-box;
	z-index: 20;
	pointer-events: none;
}

@import 'EditorTimeline-Scenes-TimeRuler';
