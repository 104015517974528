
//
// SEGMENT SLIDERS

.editor-timeline-clip-segmentContainer{
  position: absolute;
  top: 0px;
  height: 100%;

  pointer-events: none;

  z-index: 150;
}

.editor-timeline-clip-segment,
.editor-timeline-clip-segment--skip-openSkipBtn{  
  position: absolute;
  z-index: 150;  
  pointer-events: auto;
}

.editor-timeline-clip-segment-fill{
  position: absolute;
  // top: 0px;
  left: 0px;
  width: 100%;
  top: 0px;
  height: 100%;
  // height: 100%;

  
  border-radius: 2px;
}

.editor-timeline-clip-segment.editor-timeline-clip-segment--freeze
.editor-timeline-clip-segment-fill{
  top: 6px;
  height: 19px;
}

.editor-timeline-clip-segment-fill--skip-label{
  color: white;
  color: var(--text500);
  font-size: 13px;

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  //font-variation-settings: "wght" 410;
  letter-spacing: 0.008em;
  opacity: 0.85;

  position: absolute;
  
  top: 2px;
  bottom: 2px;
  left: 8px;
  right: 8px;

  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  //background: rgba(255,255,255,0.08);

  // background: $tempr;
}





.editor-timeline-clip-segment--skip
.editor-timeline-clip-segment-fill{
  height: 20px;
  top: 6px;
}

.editor-timeline-clip-segment--skip--render,
.editor-timeline-clip-segment--skip--render *{  
  pointer-events:  none !important;
}


.editor-timeline-clip-segment-handle--skip--render.editor-timeline-clip-segment-handle--left{
  position: absolute;
  left: -5px;
  top: -3px;
}

.editor-timeline-clip-segment-handle--skip--render.editor-timeline-clip-segment-handle--right{
  position: absolute;
  right: -3px;
  top: -3px;
}




.editor-timeline-clip-segmentContainer[data-clip-type='screenVideo']
.editor-timeline-clip-segment
.editor-timeline-clip-segment-fill{   
  background: transparentize(#8E82FF, 0.15);
}

.editor-timeline-clip-segmentContainer[data-clip-type='screenVideo']
.editor-timeline-clip-segment:hover
.editor-timeline-clip-segment-fill{  
  background: transparentize(#8E82FF, 0.05);
}

.editor-timeline-clip-segmentContainer[data-clip-type='screenVideo']
.editor-timeline-clip-segment[data-state="open"]
.editor-timeline-clip-segment-fill,
.editor-timeline-clip-segmentContainer[data-clip-type='screenVideo']
.editor-timeline-clip-segment[data-state="open"]:hover
.editor-timeline-clip-segment-fill{  
  background: transparentize(#8E82FF, 0.3);
}



.editor-timeline-clip-segmentContainer[data-clip-type='basicVideo']
.editor-timeline-clip-segment
.editor-timeline-clip-segment-fill{   
  background: transparentize(#B47190, 0.7);
  background: transparentize(#B47190, 0.5);
}

.editor-timeline-clip-segmentContainer[data-clip-type='basicVideo']
.editor-timeline-clip-segment:hover
.editor-timeline-clip-segment-fill{  
  background: transparentize(#B47190, 0.45);
}

.editor-timeline-clip-segmentContainer[data-clip-type='basicVideo']
.editor-timeline-clip-segment[data-state="open"]
.editor-timeline-clip-segment-fill,
.editor-timeline-clip-segmentContainer[data-clip-type='basicVideo']
.editor-timeline-clip-segment[data-state="open"]:hover
.editor-timeline-clip-segment-fill{  
  background: transparentize(#B47190, 0.3);
}

.editor-timeline-clip-segmentContainer
.editor-timeline-clip-segment.editor-timeline-clip-segment--skip.editor-timeline-clip-segment--skip--drag
.editor-timeline-clip-segment-fill{
  background: $invisible !important;
}



//

.editor-timeline-clip-segment-handle{
  margin-left: -6px;
  width: 14px;
  height: calc(100% + 11px);
  margin-top: -3px;
  border-radius: 1px;
  pointer-events: auto;
  
  //background: $tempr;
  
  opacity: 1;
  cursor: grab;

  // background: rgba(0,0,0,0);
  // // opacity: 1;
  
  box-sizing: border-box;
  padding-left: 8px;


  z-index: 10;
}

.editor-timeline-clip-segment--skip
.editor-timeline-clip-segment-handle{
  height: calc(100% + 15px);
}


.editor-timeline-clip-segment-handle-inner{
  background: lighten(#8E82FF, 10%);
  width: 4px;
  height: 15px;
  // margin-top: 6px;
  border-radius: 1px;
  margin-left: -1px;
  position: relative;
  top: 5px;
}

.editor-timeline-clip-segment-handle--left{
  margin-left: -2px;
}

.editor-timeline-clip-segment--skip
.editor-timeline-clip-segment-handle-inner{
  height: 20px;
  top: 3px;
}


.editor-timeline-clip-segmentContainer[data-clip-type='screenVideo']
.editor-timeline-clip-segment-handle-inner{
  background: lighten(#8E82FF, 10%);
}

.editor-timeline-clip-segmentContainer[data-clip-type='basicVideo']
.editor-timeline-clip-segment-handle-inner{
  background: lighten(#B47190, 15%);
  background: lighten(#B47190, 24%);
}

.editor-timeline-clip-segment-handle-inner{
  opacity: 0.5;
}

.editor-timeline-clip-segment-handle--skip
.editor-timeline-clip-segment-handle-inner{
  opacity: 0.25;
}

.editor-timeline-clip:hover ~
.editor-timeline-clip-segmentContainer
.editor-timeline-clip-segment-handle-inner{
  opacity: 1;
}

.editor-timeline-clip-segment--freeze:hover
.editor-timeline-clip-segment-handle-inner{
  opacity: 1;
}

.editor-timeline-clip-segment-handle:hover
.editor-timeline-clip-segment-handle-inner{
  opacity: 1;
}

.editor-timeline-clip-segment--freeze[data-state="open"]
.editor-timeline-clip-segment-handle-inner{
  //background: lighten(#8E82FF, 20%);
  opacity: 1;
}



.editor-timeline-clip-segment-handle--skip[data-active-state="true"]
.editor-timeline-clip-segment-handle-inner{
  background: white !important;
  opacity: 0.9 !important;
}

//
//


.editor-timeline-clip-segment--skip-openSkipBtn{
  position: absolute;
  height: 24px;
  // background: rgba(255,255,255,0.1);  
  width: 12px;
  border-radius: 3px;

  margin-top: 4px;
  transform: translateX(-50%);
}



//


.editor-timeline-clip-segment-fill--skip-iconSet,
.editor-timeline-clip-segment--skip-openSkipBtn-iconSet{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  opacity: 0.75;
}

.editor-timeline-clip-segment-fill--skip-iconSet{
  opacity: 0.5;
}

.editor-timeline-clip-segment-fill:hover
.editor-timeline-clip-segment-fill--skip-iconSet,
.editor-timeline-clip-segment--skip-openSkipBtn:hover
.editor-timeline-clip-segment--skip-openSkipBtn-iconSet{
  opacity: 1;
}

.editor-timeline-clip-segment-fill--skip-iconSet-center,
.editor-timeline-clip-segment--skip-openSkipBtn-iconSet-center{
  width: 4px;
  display: flex;
  justify-content: center;
}

.editor-timeline-clip-segment-fill--skip-iconSet-center-line,
.editor-timeline-clip-segment--skip-openSkipBtn-iconSet-center-line{
  width: 2px;
  height: 15px;
  background: white;
  border-radius: 2px;
  margin-top: -1px;

  opacity: 0.75;
}

.editor-timeline-clip-segment--skip-openSkipBtn:hover
.editor-timeline-clip-segment--skip-openSkipBtn-iconSet-center-line{
  opacity: 1;
  transition: all 50ms linear;
  transition-delay: 100ms;
}

.editor-timeline-clip-segment--skip-openSkipBtn-iconSet-center-line{
  height: 20px;
}

.editor-timeline-clip-segmentContainer[data-clip-type='screenVideo']
.editor-timeline-clip-segment-fill--skip-iconSet-center-line,
.editor-timeline-clip-segmentContainer[data-clip-type='screenVideo']
.editor-timeline-clip-segment--skip-openSkipBtn-iconSet-center-line{
  background: lighten(#8E82FF, 10%);
}

.editor-timeline-clip-segmentContainer[data-clip-type='basicVideo']
.editor-timeline-clip-segment-fill--skip-iconSet-center-line,
.editor-timeline-clip-segmentContainer[data-clip-type='basicVideo']
.editor-timeline-clip-segment--skip-openSkipBtn-iconSet-center-line{
  background: lighten(#B47190, 15%);
}

.editor-timeline-clip-segment-fill--skip-iconSet-left,
.editor-timeline-clip-segment-fill--skip-iconSet-right,
.editor-timeline-clip-segment--skip-openSkipBtn-iconSet-left,
.editor-timeline-clip-segment--skip-openSkipBtn-iconSet-right{
  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -1px;
}

.editor-timeline-clip-segment-fill--skip-iconSet-left svg,
.editor-timeline-clip-segment-fill--skip-iconSet-right svg,
.editor-timeline-clip-segment--skip-openSkipBtn-iconSet-left svg,
.editor-timeline-clip-segment--skip-openSkipBtn-iconSet-right svg{
  width: 16px;
  height: 16px;

  fill: white;
  opacity: 0.75;
}

.editor-timeline-clip-segment-fill--skip-iconSet-right svg{
  transform: scaleX(-100%);
}

.editor-timeline-clip-segment--skip-openSkipBtn-iconSet-left svg{
  transform: scaleX(-100%);
}

.editor-timeline-clip-segment--skip-openSkipBtn-iconSet-left,
.editor-timeline-clip-segment--skip-openSkipBtn-iconSet-right{
  opacity: 0;
  transition: all 50ms linear;
}

.editor-timeline-clip-segment--skip-openSkipBtn-iconSet-left{
  transform: translateX(3px);
}

.editor-timeline-clip-segment--skip-openSkipBtn-iconSet-right{
  transform: translateX(-3px);
}

.editor-timeline-clip-segment--skip-openSkipBtn:hover
.editor-timeline-clip-segment--skip-openSkipBtn-iconSet-left,
.editor-timeline-clip-segment--skip-openSkipBtn:hover
.editor-timeline-clip-segment--skip-openSkipBtn-iconSet-right{
  opacity: 0.75;
  transform: translateX(0px);
  transition: all 50ms linear;
  transition-delay: 100ms;

}




@keyframes arrowBounceLeft {
  from {    
    transform: translateX(0px);
  }

  to {    
    transform: translateX(-2px);
  }
}

@keyframes arrowBounceRight {
  from {    
    transform: translateX(0px);
  }

  to {    
    transform: translateX(2px);
  }
}



.editor-timeline-clip-segment-fill
.editor-timeline-clip-segment-fill--skip-iconSet-right{
  animation: arrowBounceRight .4s ease-in-out infinite;
  animation-direction: alternate;
}

.editor-timeline-clip-segment-fill
.editor-timeline-clip-segment-fill--skip-iconSet-left{
  animation: arrowBounceLeft .4s ease-in-out infinite;
  animation-direction: alternate;
}


//
// HUDE FREEZE IF INACTIVE

.editor-timeline-clip-segmentContainer[data-clip-active='false']
.editor-timeline-clip-segment--freeze{
  visibility: hidden;
  pointer-events: none !important;
}


//
//

.editor-timeline-clip-tooltipContainer,
.editor-timeline-clip-segment-handle-tooltipContainer,
.editor-timeline-clip-segment-fill-tooltipContainer,
.editor-timeline-clip-segment--skip-openSkipBtn-tooltipContainer{
  position: absolute;
  bottom: 8px;
  
  left: 50%;  

  pointer-events: none;

  display: flex;
  align-items: center;
  justify-content: center;

}

.editor-timeline-clip-segment-fill-tooltipContainer{
  bottom: 20px;
  height: 20px;
}

.editor-timeline-clip-segment--skip-openSkipBtn-tooltipContainer{
  bottom: 20px;
  //height: 20px;
}

.editor-timeline-clip-tooltipContainer{
  bottom: unset;
  top: 100%;
}


.editor-timeline-clip-tooltipContainer,
.editor-timeline-clip-segment-handle-tooltipContainer,
.editor-timeline-clip-segment-fill-tooltipContainer,
.editor-timeline-clip-segment--skip-openSkipBtn-tooltipContainer{
  transform: translateY(1px) translateX(-50%);
  opacity: 0;

  transition: all 75ms linear;
}

.editor-timeline-clip-tooltipContainer{
  transform: translateY(-1px) translateX(-50%);
}


.editor-timeline-clip-segment-handle:hover
.editor-timeline-clip-segment-handle-tooltipContainer,
.editor-timeline-clipDrag:hover + .editor-timeline-clip--notDragging
.editor-timeline-clip-tooltipContainer,
.editor-timeline-clip-segment-fill:hover
.editor-timeline-clip-segment-fill-tooltipContainer,
.editor-timeline-clip-segment--skip-openSkipBtn:hover
.editor-timeline-clip-segment--skip-openSkipBtn-tooltipContainer{
  transform: translateY(0px) translateX(-50%);
  opacity: 1;
  transition: all 75ms linear;
  transition-delay: 150ms;
}

.editor-timeline-clip-segment--skip-openSkipBtn:hover
.editor-timeline-clip-segment--skip-openSkipBtn-tooltipContainer{
  transition-delay: 200ms;
}


.editor-timeline-clipDrag:hover + .editor-timeline-clip--notDragging
.editor-timeline-clip-tooltipContainer{
  transition-delay: 2000ms;
}

.editor-timeline-clipDrag:hover + .editor-timeline-clip--dragging
.editor-timeline-clip-tooltipContainer{  
  transition-delay: 0ms;
}




.editor-timeline-clip-tooltip,
.editor-timeline-clip-segment-handle-tooltip,
.editor-timeline-clip-segment-fill-tooltip,
.editor-timeline-clip-segment--skip-openSkipBtn-tooltip{
  display: flex;
  align-items: center;
  justify-content: center;

  width: 170px;
  height: 22px;

  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.24);
  
  font-size: 13px;
  background: rgb(36,36,38);
  border: 1px solid var(--line100);
  border-top: 1px solid var(--line100);
  

  color: var(--text500);
  font-size: 11.5px;

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 460;
  //font-variation-settings: "wght" 410;
  letter-spacing: 0.008em;
  //text-transform: uppercase;
  //letter-spacing: 0.025em;
  // opacity: 0.65;
  margin-top: 6px;
  margin-bottom: 6px;
  padding-left: 6px;
  padding-right: 6px;

  box-sizing: border-box;
  user-select: none;
  cursor: default;

  white-space: normal;
  text-align: center;
}


.editor-timeline-clip-tooltip{
  width: 200px;
}

.editor-timeline-clip-segment-fill-tooltip{
  width: 160px;
}



.editor-timeline-clip-segment--skip-openSkipBtn-tooltip{
  width: 120px;
  padding-left: 6px;
  padding-right: 6px;
}

.editor-timeline-clip-segment--skip-openSkipBtn-tooltip--edit{
  width: 80px;
}



.editor-timeline-clip-segment-handle--skip.editor-timeline-clip-segment-handle--left
.editor-timeline-clip-segment-handle-tooltip{
  width: 118px;
}

.editor-timeline-clip-segment-handle--skip.editor-timeline-clip-segment-handle--right
.editor-timeline-clip-segment-handle-tooltip{
  width: 110px;
}