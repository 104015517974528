

.cameraEditor-rndCamera{
	background: $tempr;
	outline: 4px solid red;
}

.cameraEditor-rndCamera--hide{
	opacity: 0;
}

.cameraEditor-resizeHandle{
	background: $tempb;
}

.cameraEditor-preview{
	position: absolute;
	//background: $tempb;
	user-select: none;
	opacity: 1;
	transition: opacity 100ms linear;	
	display: none;
}

.cameraEditor-preview[data-state-resizing='true']{
	opacity: 0.5;
}

.cameraEditor-devPanel{
	position: absolute;
	right: 1200px;
	top: 0px;

	display: flex;
	flex-direction: column;

	z-index: 9999999;
	transform-origin: top left;

	background: white;

}

.cameraEditor-devPanel-sizes,
.cameraEditor-devPanel-positions{
	display: flex;
	flex-direction: column;
}

.cameraEditor-devPanel-positions{
	margin-top: 20px;
}

.cameraEditor-devPanel-btn{
	height: 20px;
	width: 200px;
	background: $tempr;
	font-size: 12px;
}

.cameraEditor-devPanel-positions
.cameraEditor-devPanel-btn{
	background: $tempb;
}

.cameraEditor-devPanel-btn[data-selected='true']{
	background: rgba(255,0,0,0.5);
}

.cameraEditor-devPanel-positions
.cameraEditor-devPanel-btn[data-selected='true']{
	background: rgba(0,0,255,0.5);
}


.cameraEditor-devPanel-btn:disabled{
	opacity: 0.25;
	pointer-events: none;
}

//

.cameraEditor-positionOption{
	position: absolute;
	outline: 1px solid green;
	background: rgba(0,255,0,0.05);

	outline: 1px solid white;
	background: rgba(255,255,255,0.25);
	opacity: 0.5;
	opacity: 0;
}

.cameraEditor-positionOption[data-preview-drop='true']{
	outline: 1px solid white;
	background: rgba(255,255,255,0.25);
	opacity: 0.25;
}

// blue

.cameraEditor-positionOption[data-preview-drop='true'][data-light-bg-state='lightBG']{
	background: transparentize($figmaBlue, 0.6);
  border: 1px solid transparentize($figmaBlue, 0);
}


//

.cameraEditor-sizeOption{
	position: absolute;
	outline: 1px solid red;
	background: rgba(255,0,0,0.05);
	opacity: 0.25;
	opacity: 0;
}

.cameraEditor-sizeOption[data-preview-drop='true']{
	outline: 1px solid red;
	background: rgba(255,0,0,0.35);
	opacity: 0.25;

	outline: 1px solid white;
	background: rgba(255,255,255,0.25);
	opacity: 0.5;
}

// blue

.cameraEditor-sizeOption[data-preview-drop='true'][data-light-bg-state='lightBG']{
	background: transparentize($figmaBlue, 0.6);
  border: 1px solid transparentize($figmaBlue, 0);
}


//

.cameraEditor-dragHandle{
	//background: $invisible;
	// background: $tempr;
	z-index: 140;
	//background: red;
	// background: rgba(255,0,0,0.5);

	transition: opacity 50ms linear;
	pointer-events: auto;
}



.cameraEditor-dragHandle-iconContainer{
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}




.cameraEditor-dragHandle-iconContainer svg{
	width: 48px;
	height: 48px;

	position: relative;
	top: -12px;
	left: -12px;
	filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));

	transition: transform 75ms ease-in;
}

.cameraEditor-dragHandle:hover
.cameraEditor-dragHandle-iconContainer svg{
	transform: scale(1.1);
	transition: transform 150ms ease-in-out;
}


.cameraEditor-dragHandle-iconContainer{
	opacity: 0;
	transition: opacity 100ms linear;
}

.editor-slideEditor:hover
.cameraEditor-dragHandle-iconContainer{
	opacity: 0.35;
}

.editor-slideEditor:hover
.cameraEditor-dragHandle:hover
.cameraEditor-dragHandle-iconContainer{
	opacity: 1;
}

.cameraEditor-dragHandle--hide
.cameraEditor-dragHandle-iconContainer{
	opacity: 0 !important;
}

.editor-slideEditor:hover
.cameraEditor-dragHandle.cameraEditor-dragHandle--bgIsLight:hover
.cameraEditor-dragHandle-iconContainer{
	opacity: 0.5;
}



.cameraEditor-positionHandle{
	pointer-events: auto;
	z-index: 60;
	// background: $tempr;
	//background: $invisible;
	// opacity: 0;
}

// .cameraEditor-sizeAnchor{
// 	position: absolute;
// 	width: 10px;
// 	height: 10px;
// 	margin-left: -5px;
// 	margin-top: -5px;
// 	background: red;
// 	opacity: 0.02;	
// }

//
//

.cameraEditor-uiControlsOuterContainer{
	position: absolute;
	z-index: 500;
	pointer-events: none;
	// background: $tempr;

	opacity: 0;
	transition: opacity 0ms linear;
	
}


.cameraEditor-uiControlsOuterContainer[data-visibility-state='visible']{
	opacity: 1;
	transition: opacity 100ms linear;
	transition-delay: 500ms;
}


.cameraEditor-uiControlsInnerContainer{
	// background: $tempb;
}

.cameraEditor-uiControlsInnerContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	// background: $tempb;
	transform-origin: 0% 0%;
}

.cameraEditor-uiControls-dropdownBtn{
	position: absolute;
	top: 8px;
	right: 8px;
	width: 28px;
	height: 22px;
	
	border-radius: 5px;

	border: 1px solid rgba(255,255,255,0.05); 
  background: rgba(0,0,0,0.4);
  //backdrop-filter: blur(25px) saturate(150%);
  // backdrop-filter: blur(25px) brightness(70%);
	
	font-size: 13px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 520;
  letter-spacing: 0.008em;  

  pointer-events: auto;

  opacity: 0;
}

.cameraEditor-uiControlsOuterContainer[data-visibility-state='visible'][data-mouseOver-state='mouseOver']
.cameraEditor-uiControls-dropdownBtn{	
  opacity: 0.7;
  transition: opacity 100ms linear;
}

.cameraEditor-uiControlsOuterContainer[data-visibility-state='visible']
.cameraEditor-uiControls-dropdownBtn:hover{	
  opacity: 1;
  transition: opacity 100ms linear;
}


.cameraEditor-uiControls-dropdownBtn svg{
	width: 20px;
	height: 20px;	
	fill: white;
	opacity: 1;
}

// .cameraEditor-uiControlsOuterContainer[data-visibility-state='visible'][data-mouseOver-state='mouseOver']
// .cameraEditor-uiControls-dropdownBtn svg,
// .cameraEditor-uiControlsOuterContainer[data-visibility-state='visible']:hover
// .cameraEditor-uiControls-dropdownBtn svg{
// 	opacity: 1;
// 	transition: opacity 100ms linear;
// }

// .cameraEditor-uiControlsOuterContainer[data-visibility-state='visible']
// .cameraEditor-uiControls-dropdownBtn:hover{
// 	border: 1px solid rgba(255,255,255,0.125); 
// 	backdrop-filter: blur(25px) brightness(60%);
// }

