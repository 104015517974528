.slidePreviewPlayground{
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: black;
}

.slidePreviewContainer{
		
}

.slidePreview{	
	transform-origin: top left;
	position: relative;
}

.slidePreview, 
.slidePreview *{
	user-select: none !important;
	pointer-events: none !important;
} 

.slidePreview-background{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	z-index: 1;
}

img.slidePreview-background-image{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.slidePreview-background-color{
	width: 100%;
	height: 100%;	
}

.slidePreview-text{
	position: absolute;		
}

.slidePreview-image{
	position: absolute;
}