.playground-chartCanvasOuterContainer{
	position: absolute;
	//left: 40px;
	left: 500px;
	top: 100px;


	z-index: 20;
}

.playground-chartsPanelContainer{
	position: absolute;
	top: 60px;
	left: 10px;	

	width: calc(100% - 20px);
	height: calc(100% - 80px);
	
	background: var(--bg-canvasContainer);
	border-radius: 4px;
	border: 1px solid var(--line300);

}

.playground-chartsPanel{
	//opacity: 0; // temp

	position: absolute;
	top: 10px;
	left: 10px;
	height: calc(100% - 20px);
	width: 440px;

	background: var(--bg-editorPanel);
	border: 1px solid var(--line300);
	border-radius: 2px;

	box-sizing: border-box;
	padding-left: 18px;
	padding-right: 18px;

	padding-top: 24px;
}

.playground-chartsPanel-dataContainer{
	width: 100%;
	position: relative;

	display: flex;
	justify-content: flex-start;

	// background: $tempr;
}

.playground-chartsPanel-chartType{
	display: flex;
	flex-wrap: wrap;

	align-items: center;
	justify-content: center;
}

.playground-chartsPanel-analysis{
	color: white;
}

.playground-chartsPanel-analysis-header{
	font-size: 15px;
	font-weight: $fw-bold;
	margin-top: 16px;
	margin-bottom: 4px;
}
.playground-chartsPanel-analysis-body{
	opacity: 0.75;
	font-size: 14px;
}

//

.playground-chartsPanel-rowBtnGroupList{
	display: flex;
	// flex-grow: 1;
	// flex-shrink: 1;
	flex-direction: column;

	box-sizing: border-box;
	margin-right: -1px;
}



$cell-width: 174px;
$cell-height: 28px;

.playground-chartsPanel-rowBtnGroup{
	display: flex;
	// flex-direction: column;
	width: 36px;
	height: calc($cell-height + 1px);
	margin-bottom: -1px;


	justify-content: space-between;
	box-sizing: border-box;
	// padding-bottom: 2px;
	// padding-top: 2px;

}

// Customization
.playground-chartsPanel-rowBtn{
	width: calc(50% - 1px);
	width: 100%;

	height: 100%;

	background: var(--252gray);
	border: 1px solid var(--line100);

	display: flex;
	flex-direction: column;

	position: relative;
	z-index: 2;
}

.playground-chartsPanel-rowBtn:disabled{
	background: var(--252gray) !important;
	border: 1px solid var(--line100) !important;
	opacity: 0.5;
	pointer-events: none;
}

.playground-chartsPanel-rowBtn:disabled svg{
	opacity: 0.25 !important;
}

.playground-chartsPanel-rowBtn:hover{
	background: var(--250gray);
	border: 1px solid var(--line200);
}

.playground-chartsPanel-rowBtn[data-state="active"]{
	background: var(--246gray);
	border: 1px solid var(--line300)
}

.playground-chartsPanel-rowBtn svg{
	width: 16px;
	height: 16px;

	fill: white;
	opacity: 0.25;
}

.playground-chartsPanel-rowBtn:hover svg{
	opacity: 0.85;
}

.playground-chartsPanel-rowBtn[data-state="active"] svg{
	opacity: 1;
}

.playground-chartsPanel-rowBtn-bar{
	width: 14px;
	height: 3px;
	margin-bottom: 1px;
	margin-top: 1px;
	border-radius: 1px;
	background: white;
	opacity: 1;
}

.playground-chartsPanel-rowBtn-bar[data-state="active"]{
	opacity: 0.4;
}

.playground-chartsPanel-rowBtn-bar[data-state="inactive"]{
	opacity: 0.1;
}


// width and height

.playground-chartsPanel-dataContainer .Spreadsheet__cell,
.playground-chartsPanel-dataContainer .Spreadsheet__header{
	width: $cell-width;
	min-width: $cell-width;	
	max-width: $cell-width;	
	height: $cell-height;
	max-height: $cell-height;
	padding-left: 2px;
}

.playground-chartsPanel-dataContainer tr:first-child .Spreadsheet__header:first-child,
.playground-chartsPanel-dataContainer tr:not(:first-child) .Spreadsheet__header,{
	width: 3px;
	min-width: 3px;
	display: none;
}

.playground-chartsPanel-dataContainer tr:first-child .Spreadsheet__header:first-child{
	border-top: none;
	border-left: none;
	background: var(--bg-editorPanel);
}

.playground-chartsPanel-dataContainer tr:first-child .Spreadsheet__header{
	height: 3px;
	display: none;
}

.playground-chartsPanel-dataContainer .Spreadsheet,
.playground-chartsPanel-dataContainer .Spreadsheet .Spreadsheet__cell{
	 --background-color: lighten(rgb(28,29,33), 2%);

	background: lighten(rgb(28,29,33), 2%);

	font-size: 14.25px;
	font-family:  BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif !important;
	color: var(--text500) !important;
}

.playground-chartsPanel-dataContainer .Spreadsheet .Spreadsheet__activeCell{
	background: $invisible !important;
	background-color: $invisible !important; 
}

.playground-chartsPanel-dataContainer .Spreadsheet__cell{
	border: 1px solid lighten(rgb(28,29,33), 10%);
}

.playground-chartsPanel-dataContainer .Spreadsheet__header{
	border: 1px solid lighten(rgb(28,29,33), 10%);
}