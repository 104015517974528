.editor-trimbar{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 25;

  border-top: 1px solid $panelBorder;
  background: $panelBackground; 

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
}


.editor-trimbar-playBtn{
  width: 52px;
  height: calc(100% - 8px);
  background: var(--246gray);
  border-radius: 3px;

  flex-grow: 0;
  flex-shrink: 0;

}

.editor-trimbar-playBtn svg{
  width: 16px;
  height: 16px;

  fill: var(--text500);
}

.editor-trimbar-playBtn svg.icon--name--playbackPlay{
  position: relative;
  right: -1px;
}

.editor-trimbar-timestamp{
  flex-grow: 0;
  flex-shrink: 0;

  font-size: 14.5px;
  color: var(--text500);
  font-weight: $fw-regular;

  width: 75px; 

  display: flex;
  align-items: center;
  justify-content: center;


  letter-spacing: 0.012em;

  user-select: none;
  cursor: default;

  font-feature-settings: 'tnum';
}

.editor-trimbar-timestamp--current{
  margin-left: 10px;
  margin-right: 18px;
}

.editor-trimbar-timestamp--duration{
  margin-left: 18px;
  margin-right: 10px;
}

.editor-trimbar-timestamp-milliseconds{
  opacity: 0.5;
}

.editor-trimbar-timestamp-colon{
  position: relative;
  // margin-left: 0.8px;
  // margin-right: 0.8px;
  margin-top: -2px;
  
}

.editor-trimbar-timestamp-colon--miliseconds{
  opacity: 0.5;
}





.editor-trimbar-doneBtn{
  

  width: 80px;
  height: 28px;

  background: #516CE8;
  font-weight: $fw-medium;
  color: white;
  font-size: 15px;

  border-radius: 3px;

  flex-grow: 0;
  flex-shrink: 0;
}

.editor-trimbar-doneBtn:hover{
  background: darken(#516CE8, 3%);
}


//
//



.editor-trimbar-dragArea{
  margin-left: 0px;
  margin-right: 0px;

  flex-grow: 1;
  flex-shrink: 1;

  height: calc(100% - 8px);

  background: rgba(255,255,255,0.4);
  background: darken(#5C5E62, 5%);
  border-radius: 2px;

  position: relative;

  cursor: pointer;
}

.editor-trimbar-slider{
  position: absolute;
  left: 0px;  
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 5;
  // background: $tempr;
}

.editor-trimbar-slider-track{
  opacity: 0;
}

.editor-trimbar-slider-thumb{
  width: 2px;
  height: 100%;
  background: red;  
}

.editor-trimbar-slider-thumb:focus{
  outline: none;
}


//



.editor-trimbar-trimHandle{
  // background: red;
  
}

.editor-trimbar-trimHandle{
  position: relative;
  z-index: 10;
  //margin-left: -20px; 
}

$width-trimBarHandle: 8px;

.editor-trimbar-trimHandle-handle{
  position: absolute;
  width: $width-trimBarHandle;
  height: 100%;
  background: #FFDC5B;
  background: #FFE779;

  opacity: 0.85;

  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

}

.editor-trimbar-trimHandle-handle:hover{
  opacity: 1;
}

.editor-trimbar-trimHandle-handle-bar{
  width: 2px;
  //background: rgba(0,0,0,0.5);
  height: 80%;

  border-radius: 2px;
}

.editor-trimbar-trimHandle--start
.editor-trimbar-trimHandle-handle{
  margin-left: #{$width-trimBarHandle * -1};
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.editor-trimbar-trimHandle--end
.editor-trimbar-trimHandle-handle{
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.editor-trimbar-trimHandle-handle-jumpBtn{
  position: absolute;
  top: 5px;
  height: 20px;  
  background: red;
  background: rgba(40,40,50,0.6);
  border: 1px solid rgba(100,100,110,0.5);
  border-radius: 3px;

  z-index: 10;

  opacity: 0.8;
}

.editor-trimbar-trimHandle-handle-jumpBtn:hover{
  opacity: 1;
}

.editor-trimbar-trimHandle--start
.editor-trimbar-trimHandle-handle-jumpBtn--left{
  right: 12px;
}

.editor-trimbar-trimHandle--start
.editor-trimbar-trimHandle-handle-jumpBtn--right{
  left: 12px;
}

.editor-trimbar-trimHandle--start
.editor-trimbar-trimHandle-handle-jumpBtn--none{
  display: none;
}

.editor-trimbar-trimHandle--end
.editor-trimbar-trimHandle-handle-jumpBtn--left{
  right: 12px;
}

.editor-trimbar-trimHandle--end
.editor-trimbar-trimHandle-handle-jumpBtn--right{
  left: 12px;
}

.editor-trimbar-trimHandle--end
.editor-trimbar-trimHandle-handle-jumpBtn--none{
  display: none;
}




.editor-trimbar-trimHandle-handle-jumpBtn svg{
  width: 14px;
  height: 14px;
  fill: var(--text500);
}


.editor-trimbar-leftBlock,
.editor-trimbar-rightBlock{
  position: absolute;
  top: 0px;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 1;
  pointer-events: none;
}

.editor-trimbar-leftBlock{  
  left: 0px;  
}

.editor-trimbar-rightBlock{  
  right: 0px;  
}