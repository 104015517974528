$width-dropdownMenu: 195px;
$height-dropdownMenu-item: 25px;
$width-dropdownMenu-visual: 24px;

//
//
// DROPDOWNS

.dropdownMenu,
.contextMenu,
.selectMenu,
.dropdownMenu-subMenu,
.miniPopover{
	margin-top: 4px;

	width: $width-dropdownMenu;
	
	box-sizing: border-box;
	padding: 0px;
	padding-top: 4px;
	padding-bottom: 8px;
	
	overflow: none;

	background: var(--bg-dropdown);

	border-radius: 4px;

	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);
	
	z-index: 500;

	position: relative;
	-webkit-app-region: no-drag;
	
}


.contextMenu,
.miniPopover{
	margin-top: 0px;	
	padding-top: 5px;
}

.contextMenu--timelineClip{
	width: 200px;
	position: relative;
}

.contextMenu--timeRuler{
	width: 160px;
	position: relative;
	margin-left: 6px;
	//margin-top: -12px;
}

.contextMenu--skipSegment{
	width: 200px;
}

.contextMenu--generationsDialogList,
.dropdownMenu--generationsDialog{
	width: 230px;
}


.dropdownMenu--collabPreviewVideo{
	max-height: calc(100vh - 400px); // messy
	overflow-y: overlay;
}



.contextMenu-subMenu.contextMenu-subMenu--playbackRate{
	width: 100px;
	margin-left: 2px;
	overflow-y: hidden;
}

// .contextMenu-subMenu--playbackRate
// {
// 	font-feature-settings: "tnum";
// 	width: calc(100% + 8px);
// }

.contextMenu div[role='menuitem'].contextMenu-subMenu-item--playbackRate{
	// background: $tempr;
	font-feature-settings: "tnum";
	letter-spacing: -0.02em;
	// width: 100%;
}

.contextMenu div[role='menuitem'].contextMenu-subMenu-item--playbackRate--inactive:not(:hover){
	opacity: 0.6;
}

.contextMenu div[role='menuitem'].contextMenu-subMenu-item--playbackRate--active,
.contextMenu div[role='menuitem'].contextMenu-subMenu-item--playbackRate--active:hover{
	background: var(--activeBG-suggestionItem) !important // dangerous;
}

.contextMenu-subMenu-item--playbackRate-iconContainer{

}

.contextMenu-subMenu-item--playbackRate-iconContainer svg{
	fill: var(--text500);
	width: 12px;
	height: 12px;	
	opacity: 0.6;
	position: relative;
	top: 2.5px;
	left: -0.5px;
	transform: scale(0.95);
}




.contextMenu--timelineClip[data-side="left"]{
	right: 4px;
}

.contextMenu--timelineClip[data-side="right"]{
	right: -4px;
}

.miniPopover--tabBar,
.miniPopoverWrapper--tabBar
.miniPopover--moveTo{
	width: 228px;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}


.dropdownMenu--sceneHeader{
	width: 210px;
}

.dropdownMenu--memberTable{
	width: 140px;
	padding-top: 6px;
}

.dropdownMenu--webcamHeader{
	width: 180px;
}

.dropdownMenu--selectBrand,
.dropdownMenu--selectMotion{
	width: 200px;
}

.dropdownMenu--selectTextStyle{
	width: 140px;
}

.dropdownMenu--collectionHeader{
	width: 170px;
}

.dropdownMenu--selectMotionPanel{
	width: 180px;
}

.dropdownMenu--transcriptChunk{
	width: 150px;
	padding-bottom: 4px;
}



// .miniPopover--moveTo{
// 	width: 240px;
// }

.dropdownMenuWrapper--libraryProject{
	width: 240px;
	display: flex;
	justify-content: flex-end;
}

.dropdownMenu--voiceoverSpeed{
	background: lighten(rgb(30,30,32), 3%);
	width: 180px;
}

.dropdownMenu--screenDevice{
	width: 180px;
}

.dropdownMenu--selectTransition{
	width: 220px;
}

.dropdownMenu--libraryProjectItem{
	width: 120px;
}

.dropdownMenu--timelineSelect{
	width: 180px;
}

.dropdownMenu--screenRecordingDisplay{
	width: 170px;
}

.dropdownMenu--selectColor,
.dropdownMenu--selectMusic,
.dropdownMenu--selectPlaybackRate{
	width: auto;
	padding-left: 4px;
	padding-right: 4px;
	padding-top: 4px;
	padding-bottom: 4px;
}

.dropdownMenu--selectPlaybackRate{
	//margin-left: 140px;
}

.dropdownMenu--selectSounds{
	padding-left: 4px;
	padding-right: 4px;
	padding-top: 4px;
	padding-bottom: 4px;

	width: auto;
}

.dropdownMenu:focus,
.suggestionMenu:focus{
	outline: none;
}

//
// DROPDOWN MENU ITEM

.dropdownMenu div[role='menuitem'],
.dropdownMenu div[role='menuitemradio'],
.dropdownMenu div[role='menuitemcheckbox'],
.contextMenu div[role='menuitem'],
.selectMenu div[role='menuitem'],
.selectMenu div[role='menuitemradio'],
.contextMenu div[role='menuitemradio'],
.dropdownMenu-item,
.contextMenu-label,
.popoverMenu-item,
.suggestionMenu-item,
.contextMenu-item--disabled-hint,
.miniPopover-item{
	width: calc(100% - 8px);
	margin-left: 4px;

	background: var(--bg-dropdown);
	height: $height-dropdownMenu-item;
	box-sizing: border-box;
	padding: 0px;
	//padding-left: 20px;
	//font-size: 15px;
	padding-left: 8px;
	font-size: 13px;
	letter-spacing: 0.01em;
	margin-bottom: 0px;

	color: var(--text500);
	
	font-variation-settings: "wght" 440;	

	line-height: 1.85;
	
	user-select: none;

	display: flex;
	justify-content: flex-start;
	position: relative;



	cursor: pointer;

	border: none;
	outline: none;
	box-shadow: none;

	border-radius: 3px;
}

.contextMenu div[role='menuitem'],
.contextMenu div[role='menuitemradio'],
.contextMenu-label{
	// font-size: 13.65px;
	// font-variation-settings: "wght" 400;
}

.dropdownMenu div[role='menuitem']:hover,
.dropdownMenu div[role='menuitemradio']:hover,
.dropdownMenu div[role='menuitemcheckbox']:hover,
.contextMenu div[role='menuitem']:hover,
.contextMenu div[role='menuitemradio']:hover,
.popoverMenu-item:hover,
.miniPopover-item:hover{
	// background: $blue;
	// color: $white;

	//background: rgb(244,240,240);
	background: var(--hoverBG-item-dropdown);
	color: var(--text600);
	//color: $white;

	border: none;
	outline: none;
	box-shadow: none;
}

.dropdownMenu-item--active,
.contextMenu-item--active,
.suggestionMenu-item--active,
.suggestionMenu-item--active:hover,
.doc-zoomedFigmaModal-status-menu-btn--active,
.doc-zoomedFigmaModal-status-menu-btn--active:hover,
.popoverMenu-item--active,
.popoverMenu-item--active:hover,
.miniPopover-item--active,
.miniPopover-item--active:hover{
	background: var(--activeBG-suggestionItem) !important // dangerous;
}

.dropdownMenu-item--active--bright,
.dropdownMenu-item--active--bright:hover{
	background: var(--primary500) !important // dangerous;
}



.dropdownMenu-item-labelSub{
	opacity: 0.4;
	//margin-left: 0.2em;
	margin-left: 4px;
	margin-right: 8px;
	//font-size: 13px;
}

.dropdownMenu-item-hSpacer{
	min-width: 1px;
	flex-grow: 1;
	flex-shrink: 1;
}


.dropdownMenu--voiceoverSpeed .popoverMenu-item{
	background: lighten(rgb(30,30,32), 3%);
}

.dropdownMenu--voiceoverSpeed .popoverMenu-item:hover{
	background: lighten(rgb(30,30,32), 5%);
}

.dropdownMenu--voiceoverSpeed .popoverMenu-item--active,
.dropdownMenu--voiceoverSpeed .popoverMenu-item--active:hover{
	background: lighten(rgb(30,30,32), 7%);
}


// //
// .dropdownMenu--projectSortOrder div[role='menuitem']{
// 	justify-content: flex-end;
// 	padding-right: 8px;
// }

//
// DISABLED
.dropdownMenu div[role='menuitem'].dropdownMenu-item--disabled,
.contextMenu div[role='menuitem'].contextMenu-item--disabled{
	pointer-events: none;
	opacity: 0.5;
}

//
// LIGHT
.contextMenu div[role='menuitem'].contextMenu-item--light,
.contextMenu div[role='menuitemradio'].contextMenu-item--light,
.dropdownMenu div[role='menuitem'].dropdownMenu-item--light,
.miniPopover .miniPopover-item--light{
	opacity: 0.7;
}
.contextMenu div[role='menuitem'].contextMenu-item--light:hover,
.contextMenu div[role='menuitemradio'].contextMenu-item--light:hover,
.contextMenu div[role='menuitemradio'].contextMenu-item--light:hover,
.miniPopover .miniPopover-item--light:hover{
	opacity: 1;
}


.miniPopover .miniPopover-item--light svg{
	opacity: 0.5;
}

.miniPopover .miniPopover-item--light:hover svg{
	opacity: 0.8;
}

//
// WARNING

.dropdownMenu div[role='menuitem'].dropdownMenu-item--warning:hover,
.contextMenu div[role='menuitem'].contextMenu-item--warning:hover,
.miniPopover .miniPopover-item--warning:hover{
	background: transparentize($red, 0.9);
	color: darken($red, 30%);
}


// WRAPPED
.dropdownMenu div[role='menuitem'].dropdownMenu-item--wrapped{
	height: 47px;
	//background: $tempr;
	line-height: 1.3;
	padding-top: 4px;
}

//
// TRANSCRIPT HEADER

.miniPopover--transcriptHeader{
	width: 228px;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
	margin-left: -8px;

	border: 1px solid rgba(255,255,255,0.055) !important;	
	//background: rgba(26,26,27,0.8);
	background: lighten(rgb(30,30,32), 2.5%) !important;
	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.24);
}

.miniPopover--transcriptHeader .miniPopover-item{
	background: lighten(rgb(30,30,32), 2.5%) !important;
}

.miniPopover--transcriptHeader .miniPopover-item:hover{
	background: lighten(rgb(30,30,32), 6%) !important;
}

.miniPopover--transcriptHeader
.dropdownMenu-subHeader{
	margin-top: 8px;
}

.miniPopover--transcriptHeader .miniPopover-item--active{
	background: lighten(rgb(30,30,32), 7%) !important;
}

.miniPopover--transcriptHeader .miniPopover-item--active:hover{
	background: lighten(rgb(30,30,32), 8%) !important;
}


.miniPopover-item--captions[data-captions-active='true']{
	background: var(--primary500) !important;
}

.miniPopover-item--captions[data-captions-active='true']:hover{
	background: var(--primary600) !important;
}



// TRANSCRIPT
// can have two lines with keyboard shortcuts
.dropdownMenu div[role='menuitem'].dropdownMenu-item-transcript{
	position: relative;
	height: 37px;
	box-sizing: border-box;
	padding-bottom: 15px;

	// outline: 1px solid red;
	opacity: 1;
	transition: opacity 100ms linear;
}

.dropdownMenu div[role='menuitem'].dropdownMenu-item-transcript--previewPlaying
.dropdownMenu-item-iconContainer svg{
	opacity: 0.7;
}

.dropdownMenu div[role='menuitem'].dropdownMenu-item-transcript:hover
.dropdownMenu-item-iconContainer svg{
	opacity: 0.8;
}

.dropdownMenu-item-transcript--disabled{
	opacity: 0.7 !important;
	pointer-events: none !important;
}

.dropdownMenu-item-transcript-label{
	position: relative;
	font-size: 13.25px;
	font-variation-settings: "wght" 480;	
	color: var(--text600);	
	top: -2px;
}

.dropdownMenu-item-transcript-shortcut{
	position: absolute;
	bottom: 0px;
	left: 29px;

	font-size: 11.25px;
	opacity: 0.65;
}

.dropdownMenu-item-transcript-regenSpinnerContainer{
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;
	left: -1px;
}

.dropdownMenu-item-transcript-regenSpinner{
	height: 10px;
  width: 10px;


  
  border-radius: 8px;

  border: 1.5px var(--line200) solid;
  border-top: 1.5px var(--text300) solid;

  opacity: 1;

  animation: spinnerRotate 1s infinite linear;
}



// LIGHT

.dropdownMenu div[role='menuitem'] span.dropdownMenu-item-lightSpan{
	margin-left: 4px;
	opacity: 0.5;
}

//RADIO

.dropdownMenu div[role='menuitemradio'][data-state='checked'],
.dropdownMenu div[role='menuitemcheckbox'][data-state='checked']{
	//background: $blue;
	background: var(--primary500);
	color: $white;
}

.dropdownMenu div[role='menuitemradio'][data-state='checked']:hover,
.dropdownMenu div[role='menuitemcheckbox'][data-state='checked']:hover{
	//background: darken($blue, 5%);
	background: var(--primary600);
	color: $white;
}

// CHECKMARK
.contextMenu div[role='menuitemradio']{
	display: flex;
}

.contextMenu div[role='menuitemradio'][data-state='checked']{
	font-weight: $fw-bold;
}

.contextMenu div[role='menuitemradio'][data-state='unchecked']{
	// opacity: 0.8;
}

.contextMenu div[role='menuitemradio'] span[data-state='checked']{
	margin-left: auto;
	background: rgba(0,0,0,0.03);
	width: 22px;
	height: 22px;

	border-radius: 5px;

	margin-right: 4px;
	margin-top: 3px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.contextMenu div[role='menuitemradio'] span[data-state='checked'] svg{
	width: 16px;
	height: 16px;
	fill: var(--text600);
}

// LABEL

.contextMenu-label{
	opacity: 0.72;
	cursor: default;
	pointer-events: none;
}

.contextMenu-label-visual{
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}

.contextMenu-label-visual--channelHash{
	width: 20px;
	margin-right: 0px;
	margin-left: -2px;

}

.contextMenu-label-visual--channelHash svg{
	width: 14px;
	height: 14px;
	opacity: 0.5;
	margin-top: 1px;

	fill: var(--text600);
}



.contextMenu-item--collection-colorBar{
	height: 16px;
	margin-top: 5px;
	width: 4px;
	background: red;
	border-radius: 1px;
	margin-right: 8px;
}



// SEPARATOR

.dropdownMenu div[role='separator'],
.contextMenu div[role='separator'],
.popoverMenu-separator,
.miniPopover-separator{
	height: 3px;
	border-bottom: 1px solid var(--separatorBG-dropdown);
	width: calc(100% - 14px);
	margin-left: 12px;
	margin-top: 3px;
	margin-bottom: 3px;
	pointer-events: none;

	width: 100%;
	margin-left: 0px;
}

.popoverMenu-separator--spacer{
	border-bottom: 0px;
	height: 3px;
	margin-bottom: 2px;
}

.slideEditorContextMenu.contextMenu div[role='separator']{
	width: calc(100% - 14px) !important;
	margin-left: 12px !important;
	height: 1px;
}

// SUBMENU

.dropdownMenu-subMenu,
.contextMenu-subMenu{
	margin-top: -10px;
	overflow-y: scroll;

	max-height: min(455px, 80vh);

}

// Active state on submenu item
.contextMenu div[role='menuitem'][aria-haspopup='menu'][aria-expanded='true']{
	background: var(--expandedSubBG-item-dropdown);
}
.contextMenu div[role='menuitem'][aria-haspopup='menu'][aria-expanded='true']:hover{
	background: var(--hoverBG-item-dropdown);
}

// SUBMENU ARROW

.dropdownMenu-item-subMenuArrow,
.contextMenu-item-subMenuArrow{
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-right: 2px;

	opacity: 0.7;
}

.dropdownMenu-item-subMenuArrow svg,
.contextMenu-item-subMenuArrow svg{
	width: 12px;
	height: 12px;
	fill: var(--text200);
	opacity: 0.8;
	opacity: 0.6;
}


//
// VISUAL

.dropdownMenu-item-iconContainer,
.contextMenu-item-visual,
.suggestionMenu-item--insert-iconContainer,
.suggestionMenu-item--emoji-emojiContainer,
.suggestionMenu-item--mention--user-avatarOuterContainer,
.suggestionMenu-item--mention--docLink--emojiContainer{
	width: $width-dropdownMenu-visual;
	height: 100%;

	margin-right: 3px;
	margin-left: -2px;

	display: flex;
	align-items: center;
	justify-content: center;
}


.dropdownMenu-item-iconContainer{	
	margin-left: -6px;
	margin-right: 3px;
}

.dropdownMenu-item-iconContainer svg{
	width: 15px;
	height: 15px;
	fill: var(--text600);
	opacity: 0.5;
}

.dropdownMenu-item-iconContainer svg.icon--name--skipSegment{
	transform: scale(1.2);
}

.dropdownMenu-item-iconContainer svg.icon--name--playbackPause{
	transform: scale(0.7);
}

.dropdownMenu-item-iconContainer svg.icon--name--laptop #screen{
	fill: rgba(255,255,255,0.06);
}

.dropdownMenu-item-iconContainer svg.icon--name--laptop{
	transform: scale(1.05);
}

.dropdownMenu-item-iconContainer svg.icon--name--rectangle{
	transform: scale(1.05);
}



.dropdownMenu-item-keyboardShortcut,
.contextMenu-item-keyboardShortcut{
	margin-left: auto;
	opacity: 0.5;
	margin-right: 3px;
	font-size: 12.5px;
}

//
.dropdownMenu-smallVSpacer{
	height: 8px;
}

.dropdownMenu-tinyVSpacer{
	height: 4px;
}

.dropdownMenu-extraTinyVSpacer{
	height: 2px;
}

.dropdownMenu-subHeader{
	width: 100%;

	color: var(--text500);
	font-size: 11px;

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;
	//text-transform: uppercase;
	//letter-spacing: 0.025em;
	opacity: 0.65;

	margin-top: 5px;
	margin-bottom: 4px;
	padding-left: 12px;

	box-sizing: border-box;
	user-select: none;
	cursor: default;
}

.dropdownMenu--selectMotionPanel
.dropdownMenu-subHeader{
	margin-bottom: 4px;
	padding-left: 12px;
}

.dropdownMenu-explainer{
	width: 100%;

	color: var(--text500);
	font-size: 11.5px;

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 410;
  letter-spacing: 0.008em;
	//text-transform: uppercase;
	//letter-spacing: 0.025em;
	opacity: 0.65;

	margin-top: 4px;
	margin-bottom: 8px;
	padding-left: 12px;
	padding-right: 10px;

	box-sizing: border-box;
	user-select: none;
	cursor: default;
}

.dropdownMenu-explainer--short{
	margin-bottom: 4px;
	margin-top: 4px;
	font-variation-settings: "wght" 480;
}

.dropdownMenu--voiceoverSpeed
.dropdownMenu-explainer{
	margin-top: 6px;
	margin-bottom: 6px;
}


.dropdownMenu-title{
	width: 100%;

	color: var(--text500);
	font-size: 14px;

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 510;
  letter-spacing: 0.008em;
	//text-transform: uppercase;
	//letter-spacing: 0.025em;
	opacity: 0.95;

	margin-top: 8px;
	margin-bottom: 5px;
	padding-left: 13px;
	padding-right: 10px;

	box-sizing: border-box;
	user-select: none;
	cursor: default;
}





//
// ANIMATE IN OUT 

@keyframes dropdownMenuFadeIn {
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes dropdownMenuFadeOut {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(-4px);
  }
}


.dropdownMenu[data-state='open'],
.miniPopover[data-state='open'],
.miniPopover[data-visibility-state='open'],
.dpItemAnimationPanel-transitionPopover[data-state='open']{
  animation: dropdownMenuFadeIn 75ms ease-out forwards;
}



.dropdownMenu[data-state='closed'],
.miniPopover[data-state='closed'],
.dpItemAnimationPanel-transitionPopover[data-state='closed']{
  animation: dropdownMenuFadeOut 75ms ease-in forwards;
}

.miniPopoverWrapper[data-state='open']{
	animation: none;
}

.miniPopoverWrapper[data-state='open']
.miniPopover[data-state='open']{
	animation: none;
}


.miniPopoverWrapper.miniPopoverWrapper--transcriptHeader[data-state='open']
.miniPopover[data-state='open']{
	animation: dropdownMenuFadeIn 75ms ease-out forwards !important;
}

.miniPopoverWrapper[data-state='closed']{
	animation: dropdownMenuFadeOut 75ms ease-in forwards;
}

.miniPopover[data-visibility-state='closed']{
	opacity: 0;
	pointer-events: none !important;
}

.dropdownMenuWrapper--libraryProject
.miniPopover--moveTo[data-visibility-state="open"]{
	animation-delay: 75ms;
	animation-fill-mode: backwards;
}


.dropdownMenuWrapper--libraryProject[data-state="closed"]{
	animation: dropdownMenuFadeOut 75ms ease-in forwards;	
}

.dropdownMenuWrapper--libraryProject[data-state="closed"]
.miniPopover--moveTo[data-visibility-state="closed"]{
	opacity: unset !important;
	animation: dropdownMenuFadeOut 75ms ease-in forwards;	
}


.dropdownMenuWrapper--libraryProject[data-state="closed"]
.dropdownMenu--libraryProject[data-state="open"] + .miniPopover--moveTo[data-visibility-state="closed"]{	
	opacity: 0 !important;
}




// .miniPopoverWrapper--tabBar
// .miniPopover--moveTo[data-visibility-state='open']{
//   animation: none;
// }

.miniPopover{
	position: absolute;
	top: 0px;
	left: 0px;
}

//
// ANIMATE IN OUT INVERTED

@keyframes dropdownMenuInvertFadeIn {
  from {
    opacity: 0;
    transform: translateY(4px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes dropdownMenuInvertFadeOut {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(7px);
  }
}

.dropdownMenu[data-state='open'][data-side='top']{
  animation: dropdownMenuInvertFadeIn 75ms ease-out forwards;
}
.dropdownMenu[data-state='closed'][data-side='top']{
  animation: dropdownMenuInvertFadeOut 75ms ease-in forwards;
}


.dropdownMenu.dropdownMenu--forceAnimateDown[data-state='open'][data-side='top']{
  animation: dropdownMenuFadeIn 75ms ease-out forwards;
}
.dropdownMenu.dropdownMenu--forceAnimateDown[data-state='closed'][data-side='top']{
  animation: dropdownMenuFadeOut 75ms ease-in forwards;
}









//
//

.dropdownMenu--selectSounds-groupingRow{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;
	padding-left: 8px;
	//padding-right: 5px;
	padding-top: 4px;
	padding-bottom: 4px;
	

	// margin-top: -3px;
	// margin-bottom: -4px;





	//border-top: 1px solid var(--line100);
	//background: rgba(255,255,255,0.01);
}

.dropdownMenu--selectSounds-groupingRow-smallLabelBtn{
	font-size: 13px;
	font-weight: $fw-medium;
	color: var(--text600);

	font-family: system-ui, sans-serif;
  	font-variation-settings: "wght" 500;
  	letter-spacing: 0.008em;

	// background: $tempr;
	height: 24px;
	padding-left: 5px;
	padding-right: 5px;
	cursor: pointer;
	border-radius: 3px;

	// flex-grow: 1;
	// flex-shrink: 1;
}

.dropdownMenu--selectSounds-groupingRow-smallLabelBtn:hover{
	background: var(--250gray);
}

.dropdownMenu--selectSounds-groupingRow-smallLabelBtn[data-state="active"]{
	opacity: 0.95;
}

.dropdownMenu--selectSounds-groupingRow-smallLabelBtn[data-state="active"]:hover{
	opacity: 1;
}

// .rightPanel:hover
// .dropdownMenu--selectTransition-groupingRow-smallLabelBtn[data-state="active"]{
// 	opacity: 0.9;
// }

// .rightPanel:hover
// .dropdownMenu--selectTransition-groupingRow-smallLabelBtn[data-state="active"]:hover{
// 	opacity: 1;
// }

.dropdownMenu--selectSounds-groupingRow-smallLabelBtn[data-state="inactive"]{
	display: flex;
	opacity: 0.45;
}


.dropdownMenu--selectSounds-groupingRow-smallLabelBtn[data-state="inactive"]:hover{
	display: flex;
	opacity: 0.8;
}


.dropdownMenu--selectSounds-groupingRow-hSpacer{
	flex-grow: 1;
	flex-shrink: 1;
}

.dropdownMenu .dropdownMenu--selectSounds-groupingRow .dropdownMenu--selectSounds-groupingRow-settingsSub{
	// width: 24px !important;
	// height: 24px !important;
	//background: blue !important;
	background: var(--bg-dropdown);
	width: 140px;
	
	padding-top: 7px;
	position: relative;
	top: -2px;
	right: -4px;

	// padding-left: 2px;
	//padding-right: 0px;
}


.dropdownMenu .dropdownMenu--selectSounds-groupingRow .dropdownMenu--selectSounds-groupingRow-settingsSub div[role=menuitem]{
	width: calc(100% - 6px);
	margin-left: 6px;
}

.dropdownMenu .dropdownMenu--selectSounds-groupingRow .dropdownMenu--selectSounds-groupingRow-settingsSub
.dropdownMenu-explainer{
	padding-bottom: 0px;
	margin-bottom: 4px;
}

.dropdownMenu .dropdownMenu--selectSounds-groupingRow .dropdownMenu--selectSounds-groupingRow-settingsSub-btn{
	width: 30px !important;
	height: 24px !important;
	margin-right: 2px;
	padding: 0px;

	display: flex;
	align-items: center;
	justify-content: center;
	// background: red !important;
}

.dropdownMenu--selectSounds-groupingRow-settingsSub-btn svg{
	fill: white;
	opacity: 0.4;
	width: 16px;
	height: 16px;
}

.dropdownMenu--selectSounds-groupingRow-settingsSub-btn:hover svg{
	opacity: 0.7;
}
//

// .popoverMenu-item--selectVoiceSpeed-counts{
// 	margin-right: 5px;
// 	margin-left: -3px;
// 	margin-left: auto;
// 	display: flex;
// 	align-items: center;
// 	column-gap: 2px;
// 	height: 100%;
// }

// .popoverMenu-item--selectVoiceSpeed-count{
// 	width: 2px;
// 	height: 10px;
// 	border-radius: 0px;

// 	background: white;
// 	opacity: 0.5;
// }

// .popoverMenu-item--selectVoiceSpeed-counts--0{
// 	.popoverMenu-item--selectVoiceSpeed-count:nth-child(n+2){
// 		opacity: 0.1;
// 	}
// }

// .popoverMenu-item--selectVoiceSpeed-counts--1{
// 	.popoverMenu-item--selectVoiceSpeed-count:nth-child(n+3){
// 		opacity: 0.1;
// 	}
// }

// .popoverMenu-item--selectVoiceSpeed-counts--2{
// 	.popoverMenu-item--selectVoiceSpeed-count:nth-child(n+4){
// 		opacity: 0.1;
// 	}
// }

// .popoverMenu-item--selectVoiceSpeed-counts--3{
// 	.popoverMenu-item--selectVoiceSpeed-count:nth-child(n+5){
// 		opacity: 0.1;
// 	}
// }

// .popoverMenu-item--selectVoiceSpeed-counts--4{
// 	.popoverMenu-item--selectVoiceSpeed-count{
// 		// opacity: 0.5;
// 	}
// }