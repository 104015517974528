
.libraryProjectCardOuter{
	flex-grow: 0;
	flex-shrink: 0;
	box-sizing: border-box;

	padding-top: 8px;
	padding-left: 8px;
	padding-right: 8px;
	background: rgba(255,255,255,0.03);
	border-radius: 6px;

	position: relative;
}

.libraryProjectCardOuter[data-state="open"]{
	background: rgba(255,255,255,0.05);
}

.libraryProjectCardOuter--promoteZIndex{
	z-index: 10;
}

.libraryProjectCard{

	display: flex;
	flex-direction: column;

	cursor: pointer;

}

.libraryProjectCard-preview{
	width: 100%;
	aspect-ratio: 16/9;

	//background: #4584EA;
	//background: white;


	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;

	font-size: 30px;

	position: relative;
}

.libraryProjectCard-preview-duration{
	position: absolute;
	bottom: 4px;
	right: 4px;

	z-index: 10;
	
	display: flex;
	align-items: center;

	height: 22px;
	padding-left: 4px;
	padding-right: 4px;

	border-radius: 3px;

	font-size: 13.5px;	
	color: var(--text500);
  font-variation-settings: "wght" 520;
  letter-spacing: 0.004em;
  
  background: rgba(0,0,0,0.5);
  opacity: 0;
  // opacity: 1;

  transition: opacity 50ms linear;


  // background: $tempr;
	// background: lighten(rgba(29,29,31,0.9), 10%);
  //backdrop-filter: blur(30px) saturate(120%);
}


.libraryProjectCard-preview:hover
.libraryProjectCard-preview-duration{
	opacity: 1;
	transition-delay: 500ms;
}


.libraryProjectCard-preview-cardExample{
	width: 80%;
	aspect-ratio: 16/9;
	background: white;
	border-radius: 4px;

	border: 1px solid var(--line400);
	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.24);
}

.libraryProjectCard-footer{
	height: 60px;
	display: flex;
	position: relative;
	width: 100%;

	box-sizing: border-box;

}

//


.libraryProjectCard-footer-leftContainer{
	flex-grow: 1;
	flex-shrink: 1;

	box-sizing: border-box;
	padding-left: 2px;
	padding-top: 4px;

	user-select: none;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  height: 100%;

}


.libraryProjectCard-footer-label{
	display: flex;
	align-items: baseline;
	width: 100%;
	height: 33px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  position: relative;

  // background: $tempr;
}

.libraryProjectCard-footer-label-name{
	position: absolute;
	top: -1px;
	left: -1px;
	width: 100%;
	height: 100%;

	width: 100%;
	height: 30px;
	display: flex;
	align-items: center;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  border: 1px solid $invisible;

  padding-left: 5px;
}

.libraryProjectCard-footer-label-inputContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	width: 100%;	
	// background: $tempr;
}

.libraryProjectCard-footer-label-input{
	width: 100%;
	height: 28px;

	padding: none;
	box-sizing: border-box;
	padding-left: 5px;
	border-radius: 3px;

	background: var(--251gray);	
	border: 1px solid $invisible;
	box-shadow: none;

	line-height: 1;
	// color: red !important;
	position: relative;

	// color: red !important;
	top: 1px;
	// left: -0.5px;
}


.libraryProjectCard-footer-label-name[data-input-active="false"]{
	opacity: 1;
}

.libraryProjectCard-footer-label-inputContainer[data-input-active="false"]{
	opacity: 0;
	pointer-events: none;
}

.libraryProjectCard-footer-label-name[data-input-active="true"]{
	opacity: 0;
	pointer-events: none;
}

.libraryProjectCard-footer-label-inputContainer[data-input-active="true"]{
	opacity: 1;

	z-index: 100;
}




//


// .libraryProjectCard-footer-label-label,
.libraryProjectCard-footer-label-name-isDraft,
.libraryProjectCard-footer-label-name-name,
.libraryProjectCard-footer-label-input{
	font-size: 16px;	
	color: var(--text500);
  font-variation-settings: "wght" 470;
  letter-spacing: 0.006em;

  margin-right: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



.libraryCardRenamePopover{	
	// background: $red;
	background: rgb(36,36,38); // messy
}

.libraryProjectCard-rename-bgClick{
	position: fixed;
	top: -999px;
	left: -999px;
	right: -999px;
	bottom: -999px;
	z-index: 50;
}


.libraryProjectCard-footer-label-input:focus{
	outline: none;
	border: 1px solid var(--line100);
	background: var(--248gray);
}

.libraryProjectCard-footer-label-name-name{
	margin-right: 8px;	
	border: 1px solid $invisible;
}

.libraryProjectCard-footer-label-name-isDraft{
	opacity: 0.5;
	margin-left: -3px;	
	flex-grow: 0;
	flex-shrink: 0;
}

//

.libraryProjectCard-footer-sub{
	margin-top: -5px;
	font-size: 14.5px;	
	color: var(--text500);
  font-variation-settings: "wght" 390;
  letter-spacing: 0.008em;

  padding-left: 6px;

  opacity: 0.65;
}

.libraryProjectCard-footer-rightContainer{
	flex-grow: 0;
	flex-shrink: 0;
}


.libraryProjectCard-footer-dropdownMenuBtn{
	width: 40px;
	height: 52px;

	margin-right: -4px;

	// background: $tempr;

	box-sizing: border-box;
	padding-top: 3px;
	align-items: flex-start;



	margin-top: 4px; //messy
}

.libraryProjectCard-footer-dropdownMenuBtn-inner{
	// background: var(--250gray);
	width: 32px;
	height: 26px;
	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.libraryProjectCard-footer-dropdownMenuBtn svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.45;
	//transform: scale(1.05);
}

.libraryProjectCard-footer-dropdownMenuBtn:hover svg{
	opacity: 0.95;
}

.libraryProjectCard-footer-dropdownMenuBtn[data-state="open"]
.libraryProjectCard-footer-dropdownMenuBtn-inner{
	background: var(--250gray);
}

.libraryProjectCard-footer-dropdownMenuBtn[data-state="open"] svg{
	opacity: 1;
}

//

.libraryProjectCard-footer-loadingIndicatorContainer{
	position: absolute;
	left: 3px;
	top: 0px;
	bottom: 0px;

	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: flex-start;

}

.libraryProjectCard-footer-loadingIndicator{
	width: 32px;
	height: 32px;

	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempr;
}

.libraryProjectCard-footer-loadingIndicator-spinner{
  height: 18px;
  width: 18px;
  
  border-radius: 36px;

  border: 2.5px var(--line200) solid;
  border-top: 2.5px var(--text100) solid;

  opacity: 1;

  animation: spinnerRotate 1s infinite linear;
}

