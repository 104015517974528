
//
// GROUPS

.editor-transcriptPanel-transcriptGroup{
  position: relative;
}


.editor-transcriptPanel-transcriptGroup-bg{
  position: absolute;
  top: 2px;
  bottom: 2px;  
  left: 12px;
  //left: 6px;
  right: 2px;
  border-radius: 4px;
  border-radius: 5px;
}



.editor-transcriptPanel-transcriptGroup--cameraDraft,
.editor-transcriptPanel-transcriptGroup--variableWebcam,
.editor-transcriptPanel-transcriptGroup--cameraRecording,
.editor-transcriptPanel-transcriptGroup--screenCastLinkedCamera,
.editor-transcriptPanel-transcriptGroup--screenClipCameraRecording{
  padding-top: 26px;
  padding-top: 30px;
  padding-top: 34px;
  margin-top: 14px;
  margin-bottom: 14px;
  padding-bottom: 14px;
}


.editor-transcriptPanel-sceneHeader +
.editor-transcriptPanel-transcriptGroup{
  margin-top: 4px;
}


.editor-transcriptPanel-transcriptGroup-bg{
  opacity: 0.4 !important;
  // opacity: 0 !important;
}

.editor-transcriptPanel-transcriptGroup--cameraRecording
.editor-transcriptPanel-transcriptGroup-bg{  
  
  // background: rgba(29, 40, 43, 0.5);
  background: lighten(saturate(rgba(29, 44, 43, 0.5), 5%), 3%);

  // outline: 10px solid desaturate(#1D282B, 2%);
  border: 1px solid lighten(#1D282B, 1%);  


  // background: rgba(29, 40, 43, 0.5);
  background: lighten(saturate(rgba(29, 44, 43, 0.5), 5%), 3%);

}


.editor-transcriptPanel-transcriptGroup--screenCastLinkedCamera
.editor-transcriptPanel-transcriptGroup-bg{
  // outline: 10px solid desaturate(#1D282B, 2%);
  border: 1px solid lighten(#1D282B, 1%); 

  background: saturate(rgba(42, 39, 78, 0.7), 0%); 
  border: 1px solid rgba(66, 61, 132, 0.2);
}



.editor-transcriptPanel-transcriptGroup--variableWebcam--script
.editor-transcriptPanel-transcriptGroup-bg{
  background: lighten(rgb(30,31,33), 2.5%);
  border: 1px solid lighten(rgb(30,31,33), 3%);

  background: rgba(255,255,255,0.03);
}

.editor-transcriptPanel-transcriptGroup--variableWebcam--instructions
.editor-transcriptPanel-transcriptGroup-bg{
  background: var(--instructionsSoftBG);
  border: 1px solid lighten(#292522, 1%);
  background: transparentize(#292522, 0);
}

.editor-transcriptPanel-transcriptGroup-titleOuterContainer{
  position: absolute;
  top: 0px;
  left: 20px;
  width: calc(100% - 40px);

  // top: 14px;
  // left: 27px;
  // height: 18px;
  // width: 100px;

  

  // pointer-events: none !important;
  // user-select: none !important;
}


.editor-transcriptPanel-transcriptGroup--variableWebcam--instructions
.editor-transcriptPanel-transcriptChunk-contents{
  //color: var(--instructionsTextLight);
}

.editor-transcriptPanel-transcriptGroup--screenCastLinkedCamera
.editor-transcriptPanel-transcriptGroup--cameraRecording-word{
  //color: lighten(saturate(rgba(42, 39, 78, 1), 15%), 70%); 
  // color: red !important;
}



// .editor-transcriptPanel-transcriptGroup--cameraDraft
// .editor-transcriptPanel-transcriptGroup-title{
//   color: lighten(rgb(30,31,33), 45%);
// }

// .editor-transcriptPanel-transcriptGroup--cameraRecording
// .editor-transcriptPanel-transcriptGroup-title{
//   color: lighten(#1D282B, 40%);
// }

// .editor-transcriptPanel-transcriptGroup--cameraDraft
// .editor-transcriptPanel-transcriptGroup-title::before{
//   //content: "Type voiceover or # for new scene";
//   //content: "Type voiceover or # for new scene or - for webcam";
//   // content: "Input Camera";  
// }

// .editor-transcriptPanel-transcriptGroup--cameraRecording
// .editor-transcriptPanel-transcriptGroup-title::before{
//   //content: "Type voiceover or # for new scene";
//   //content: "Type voiceover or # for new scene or - for webcam";
//   // content: "Camera";  
// }




//
// CHUNK

.editor-transcriptPanel-transcriptChunk{
  position: relative;
  display: flex;    
  width: 100%;

  box-sizing: border-box;
  padding-left: $leftGutter-transcriptChunk;
  padding-right: $rightGutter-transcriptChunk;
}

.editor-transcriptPanel-transcriptChunk--requiresUpdate
.editor-transcriptPanel-transcriptChunk-contents{
  opacity: 0.5;
}

.editor-transcriptPanel-transcriptChunk-contents{
  opacity: 1;
  transition: opacity 100ms linear;
}

.editor-transcriptPanel-transcriptChunk--empty .doc-para::before{
  //content: "Type voiceover or # for new scene";
  //content: "Type voiceover or # for new scene or - for webcam";
  content: "Type script...";
  position: absolute;
  height: 0px;
  width: 280px;
  pointer-events: none;
  //-webkit-text-fill-color: var(--toDoItem-placeholderColor); // to do, refactor
  -webkit-text-fill-color: var(--text500);
  opacity: 0.4;
}


.editor-transcriptPanel-transcriptGroup--cameraDraft
.editor-transcriptPanel-transcriptChunk--empty .doc-para::before{  
  content: "Type camera script..." !important; 
}


.editor-transcriptPanel-transcriptChunk:nth-child(n+5).editor-transcriptPanel-transcriptChunk--empty .doc-para::before{
  content: "# for new scene or @ for camera";
}


.editor-transcriptPanel-transcriptGroup--variableWebcam--instructions
.editor-transcriptPanel-transcriptChunk--empty .doc-para::before{
  content: "Type instructions..." !important; 
  -webkit-text-fill-color: var(--instructionsTextLight);
}




.editor-transcriptPanel-transcriptChunk-contents,
.editor-transcriptPanel-draggablePreview{
  min-width: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14.5px;
  // font-size: 14.25px;
  font-variation-settings: "wght" 420;
  color: var(--text500);
  line-height: 1.37;
  letter-spacing: 0.010em;
  //padding-top: 6px;
  //padding-bottom: 6px;
  //font-size: 15.5px;
  //font-variation-settings: "wght" 420;
  //color: var(--text500);
  //line-height: 1.35;
  //letter-spacing: 0.008em;


  //background: $tempr;

  transition: opacity 75ms linear;
}

// .editor-transcriptPanel-transcriptChunk--requiresUpdate
// .editor-transcriptPanel-transcriptChunk-contents {
//   opacity: 0.5;
// }

.doc-para {
  display: inline;
  white-space: pre-wrap; /* This allows the text to wrap */  
  width: 100%;
}

.editor-transcriptPanel-transcriptGroup:last-child
.editor-transcriptPanel-transcriptChunk:last-child{
  padding-bottom: 48px;
}


//
// WEBCAM RECORDING WORDS
.editor-transcriptPanel-transcriptGroup--cameraRecording-word {
  display: inline;
  padding-left: 0.14em;
  padding-right: 0.14em;
  caret-color: $invisible;
  //caret-color: red;
  position: relative;

  white-space: nowrap;
  word-break: keep-all;
  hyphens: none;       

  border-radius: 1px;
}

.editor-transcriptPanel-transcriptGroup--cameraRecording-word--skipBefore{
  margin-left: 0.3em;
}


.editor-transcriptPanel-transcriptGroup--cameraRecording-word--pause{
  color: rgba(255,255,255,0.6);
  color: lighten(saturate(#1D282B, 5%), 30%);  
  padding-left: 0.10em;
  padding-right: 0.10em;
}

.editor-transcriptPanel-transcriptGroup--screenCastLinkedCamera
.editor-transcriptPanel-transcriptGroup--cameraRecording-word--pause{  
  color: lighten(saturate(#423d84, 2%), 30%);
}

/////NICOLE DID THIS SKIP STYLING
.editor-transcriptPanel-transcriptGroup--cameraRecording-word--skip {
    opacity: 0.5;
    text-decoration: line-through;
  color: #999;
}

.editor-transcriptPanel-transcriptGroup--cameraRecording-skipLine{
  display: inline;
  position: relative;
  border-right: 3px solid red;
  margin: 0 2px;
  padding: 0;
  width: 0;  
}

////////////////////

.editor-transcriptPanel-transcriptGroup--cameraRecording-gapCursor {
  display: inline;
  padding-left: 1px;
  margin-left: -1px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  background: white;
  height: 4px;
  position: relative;
  left: -1px;
  //caret-color: red;
  
  // opacity: 0;
  animation: cursorBlink 1s step-end infinite;
}


.editor-transcriptPanel-transcriptGroup--cameraRecording-word--active{
  // background: red;
  //border-radius: 2px;
  // background: lighten(saturate(#1D282B, 10%), 8%);
  // outline: 1px solid lighten(saturate(#1D282B, 10%), 8%);
  // color: white !important;
  // caret-color: red;
  // color: yellow;
  
  // padding-left: 0.1em;
  // padding-right: 0.1em;
  // margin-left: -0.1em;
  // margin-right: -0.1em;
  // padding-top: 0.1em;
  // padding-bottom: 0.1em;
  // margin-top: -0.1em;

  // padding-left: 0.4em
}

// .editor-transcriptPanel-transcriptGroup--cameraRecording-word--active:after {
//   content: "";
//   position: absolute;
//   left: calc(100% - 0.14em); /* Position from left edge, accounting for padding */
//   top: 1px;
//   height: 17px;
//   width: 1px;
//   background: white;
//   animation: cursorBlink 1s step-end infinite;
//   pointer-events: none;
// }

@keyframes cursorBlink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}


// .editor-transcriptPanel-transcriptGroup--cameraRecording-space{
//   display: inline;  
//   //caret-color: $invisible;
//   caret-color: red;
//   // width: 10px;
//   display: none;
// }