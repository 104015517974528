


//

.generatePopover{
	width: 360px;
	width: 400px;
	max-height: calc(100vh - 100px);

	border-radius: 5px;

	box-shadow: none;
	

	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);
  box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.28);

  background: rgb(28, 28, 31);
  
	display: flex;
	flex-direction: column;	

	overflow: hidden;
}

@keyframes popoverMenuFadeIn {
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes popoverMenuFadeOut {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(-4px);
  }
}


.generatePopover[data-state='open']{
  animation: dropdownMenuFadeIn 75ms ease-out forwards;
}

.generatePopover[data-state='closed']{
  animation: dropdownMenuFadeOut 75ms ease-in forwards;
}




.generatePopover:focus{
	outline: none;
}

.generatePopover-infoBoxBtn{
	display: flex;
	align-items: center;
	justify-content: center;
}

.generatePopover-section--previewVideo
.generatePopover-infoBoxBtn{
	position: relative;
	margin-top: -4px;
}

.generatePopover-section--previewVideo[data-active-state='disabled']
.generatePopover-infoBoxBtn{
	margin-top: 0px;
}


.generatePopover-infoBoxBtn svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.65;
}

.generatePopover-infoBoxBtn:hover svg{
	opacity: 1;
}

//

.generatePopover-main{
	flex-grow: 1;
	flex-shrink: 1;
	overflow-y: scroll;
	overflow-x: hidden;
  scrollbar-gutter: stable;

  box-sizing: border-box;
  padding-left: 22px;
  padding-right: 14px;
  padding-bottom: 4px;
}



//
// TABS

// .generatePopover-tabs{
// 	display: flex;
// 	flex-direction: column;
// 	box-sizing: border-box;	
// 	padding-top: 6px;
// 	padding-bottom: 12px;
// 	width: calc(100% + 20px);
// 	margin-left: -10px;	
// }

// .generatePopover-tabs-tab{
// 	display: flex;
// 	padding-top: 8px;
// 	padding-bottom: 8px;
// 	border-radius: 4px;
// 	align-items: stretch;
// }

// .generatePopover-tabs-tab:hover{
// 	background: var(--251gray);
// }

// .generatePopover-tabs-tab-iconContainer{
// 	width: 33px;
// 	height: 100%;
// 	display: flex;
// 	align-items: flex-start;
// 	justify-content: center;
// 	flex-grow: 0;
// 	flex-shrink: 0;
// }

// .generatePopover-tabs-tab-iconContainer svg{
// 	width: 18px;
// 	height: 18px;
// 	fill: var(--text500);
// 	opacity: 0.7;
// 	opacity: 1;
// 	position: relative;
// 	top: -0.5px;
// }


// .generatePopover-tabs-tab-iconContainer svg.icon--name--speakerRectangleMedium{
// 	transform: scale(0.95);
// }

// .generatePopover-tabs-tab-iconContainer svg.icon--name--apiPlug{
// 	transform: scale(0.86);
// }

// .generatePopover-tabs-tab-main{
// 	display: flex;
// 	flex-direction: column;
// 	align-items: flex-start;
// 	flex-grow: 1;
// 	flex-shrink: 1;
// }

// .generatePopover-tabs-tab-main-label{	
  
//   font-size: 13.5px;
//   font-variation-settings: "wght" 480;
//   letter-spacing: 0.008em;
//   color: var(--text500);
//   user-select: none;
//   margin-bottom: 2px;
// }



// .generatePopover-tabs-tab:first-child
// .generatePopover-tabs-tab-iconContainer svg{
// 	fill: var(--guestColabText);
// }

// .generatePopover-tabs-tab:nth-child(2)
// .generatePopover-tabs-tab-iconContainer svg{
// 	fill: var(--apiText);
// }

// // .generatePopover-tabs-tab:first-child
// // .generatePopover-tabs-tab-main-label{
// // 	color: var(--guestColabText);
// // }

// // .generatePopover-tabs-tab:nth-child(2)
// // .generatePopover-tabs-tab-main-label{
// // 	color: var(--apiText);
// // }


// .generatePopover-tabs-tab-main-description{
// 	font-size: 12.5px;
//   font-variation-settings: "wght" 340;
//   line-height: 1.25;
//   letter-spacing: 0.012em;
//   color: var(--text500);
//   user-select: none;
//   opacity: 0.7;
//   text-align: left;
// }

// .generatePopover-tabs-tab-chevron{
// 	width: 40px;
// 	height: 100%;
// 	display: flex;
// 	align-items: flex-start;
// 	justify-content: flex-end;
// 	flex-grow: 0;
// 	flex-shrink: 0;

// 	padding-right: 8px;
// }

// .generatePopover-tabs-tab-chevron svg{
// 	width: 14px;
// 	height: 14px;
// 	fill: var(--text500);	
// 	opacity: 0.5;
// 	postion: relative;
// 	top: 1px;
// }

//
//

.generatePopover-header{
	flex-grow: 0;
	flex-shrink: 0;

	width: 100%;
	margin-top: 6px;

	height: 28px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;
	

	padding-left: 14px;

	position: relative;	

	margin-bottom: 0px;
}




.generatePopover-header-tab{	
	height: 100%;

	color: white;
	// padding-left: 5px;
	// padding-right: 10px;
	padding-left: 8px;
	padding-right: 8px;
	border-radius: 3px;
	position: relative;

}

.generatePopover-header-tab:hover{
	background: var(--250gray);
}

.generatePopover-header-tab[data-active-state='active']{
	opacity: 1;
	//background: var(--251gray);
}

.generatePopover-header-tab[data-active-state='active']:hover{
	opacity: 1;
}

.generatePopover-header-tab[data-active-state='inactive']{
	opacity: 0.5;
}

.generatePopover-header-tab[data-active-state='inactive']:hover{
	opacity: 1;
	//background: var(--252gray);
}


.generatePopover-header-tab-label{
	font-size: 14px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;
}


.generatePopover-header-tab-bottomBorder{
  position: absolute;
  bottom: 0px;
  left: 4px;
  right: 4px;
  height: 1.5px;
  background: var(--primary500);
  opacity: 0;

}

.generatePopover-header-tab[data-active-state="active"]
.generatePopover-header-tab-bottomBorder{  
  opacity: 1;
}






.generatePopover-header-backBtn{
	position: absolute;
	top: 0px;
	height: 100%;
	bottom: 0px;
	left: 0px;
	width: 40px;
	box-sizing: border-box;
	padding-right: 5px;
}

.generatePopover-header-backBtn svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	transform: scaleX(-100%);
	opacity: 0.7;
	position: relative;
	top: 2px;
	left: 2px;
}	

.generatePopover-header-label{
	font-size: 14.5px;
  font-variation-settings: "wght" 540;
  letter-spacing: 0.008em;
  color: var(--text500);
  user-select: none;
  // margin-top: 24px;
}

.generatePopover-header-bottomBorder{
	position: absolute;
	bottom: 0px;
	left: 10px;
	right: 10px;
	height: 1px;
	// background: var(--line100);
}



.generatePopover-header--home
.generatePopover-header-label{
	opacity: 0.5;
}

.generatePopover-homeHeader{
	// background: $tempr;
	width: calc(100% - 24px);
	margin: 0 auto;
	margin-top: 8px;

	box-sizing: border-box;
	padding-left: 8px;
	padding-right: 8px;
	// padding-top: 6px;
	// padding-bottom: 6px;

	padding-top: 4px;
	padding-bottom: 2px;

	border-radius: 3px;

	text-align: center;

	font-size: 12.25px;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.012em;
  color: var(--text400);
  user-select: none;
  opacity: 0.75;

  //background: var(--251gray);

  margin-bottom: 3px;

}

.generatePopover-tabDescription{
	box-sizing: border-box;
	padding-top: 6px;
	padding-right: 12px;

	font-size: 12.5px;
  font-variation-settings: "wght" 420;
  letter-spacing: 0.012em;
  color: var(--text400);
  opacity: 0.95;
  user-select: none;
  margin-bottom: 5px;
  position: relative;
}

.generatePopover-tabDescription-para{
	margin-bottom: 4px;
}



//

.generatePopover-section{
	width: 100%;
	box-sizing: border-box;	
	padding-top: 10px;
	padding-bottom: 8px;
}

.generatePopover-section[data-active-state='disabled'],
.generatePopover-section[data-active-state='inactive']{
	height: 30px;
	padding-top: 10px;
}

.generatePopover-section--inputs{
	position: relative;
	z-index: 20;
}

.generatePopover-section-smallVSpacer{
	height: 8px;
}

.generatePopover-section-header{
	font-size: 13.5px;
  font-variation-settings: "wght" 500;
  letter-spacing: 0.008em;
  color: var(--text400);
  user-select: none;
  margin-bottom: 5px;
  position: relative;

  display: flex;
}

.generatePopover-section-header-light{
	margin-left: 6px;
	opacity: 0.5;
	font-variation-settings: "wght" 440;
	font-size: 12.75px;
	position: relative;
	top: 1px;
}

.generatePopover-section--previewVideo[data-active-state='disabled']
.generatePopover-section-header{
	color: rgba(255,255,255,0.4); // sorry	
}


.generatePopover-section-header--tabs{
	margin-left: -6px;
	margin-top: 5px;
}

.generatePopover-section--pageHeader-subtitle{
	margin-top: 10px;
}

.generatePopover-section-header-tab{
	// background: $tempr;
	display: flex;
	align-items: center;
	height: 26px;
	padding-left: 6px;
	padding-right: 6px;

	position: relative;
	cursor: pointer;
}

.generatePopover-section-header-tab[data-active-state='active']
.generatePopover-section-header-tab-border{
	position: absolute;
	bottom: 0px;
	left: 3px;
	right: 3px;
	height: 1.5px;
	background: #516CE8;
	background: var(--primary500);
}

.generatePopover-section-header-tab[data-active-state='inactive']:not(:hover){
	opacity: 0.65;
}


//

.generatePopover-section-header
.generatePopover-infoBoxBtn{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: -8px;	
	width: 32px;
	height: 32px;

	// background: $tempr;
}

.generatePopover-section-header
.generatePopover-section-header-jsonTag{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	// right: -8px;	
	right: 0px;
	height: 22px;
	padding-left: 4px;
	padding-right: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
	// background: $tempr;

	opacity: 0.75;
	font-size: 12.25px;
}

//
.generatePopover-section-header-btn{
	margin-left: auto;

	// background: $tempr;

	border-radius: 3px;

	margin-right: 24px;
	position: relative;
	top: -4px;
	// margin-top: -6px;

	height: 26px;
	padding-left: 8px;
	padding-right: 8px;

	font-size: 12.75px;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;
  color: var(--text500);
  
  opacity: 0.7;
}

.generatePopover-section-header-btn:hover{
	opacity: 0.9;
	background: var(--251gray);
}

.generatePopover-section-header-btn[data-state='open']{
	opacity: 1;
	background: var(--250gray);
}


//


.generatePopover-section--pageHeader-textAreaContainer{
	width: calc(100% + 8px);
	margin-left: -4px;

	margin-bottom: 10px;
}

.generatePopover-section--pageHeader-textAreaContainer
textarea.dpTextArea-input{
	padding-left: 6px;
	padding-top: 6px;
}

//


.generatePopover-section--inputs-input{

}

.generatePopover-section--inputs-input-row{
	display: flex;
	align-items: stretch;
	height: 27px;
}

.generatePopover-section--inputs-input-row-typeIcon{
	width: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	// padding-top: 6px;
	margin-right: 5px;
}

// .generatePopover-section--inputs-input
// .generatePopover-section--inputs-input-row-typeIcon{
// 	align-items: flex-start;
// 	box-sizing: border-box;
// 	padding-top: 2.5px;
// }


.generatePopover-section--inputs-input-row-typeIcon svg{
	width: 15px;
	height: 15px;
	fill: var(--text500);
	opacity: 0.6;
}

.generatePopover-section--inputs-input-row-typeIcon svg.icon--name--waveform{
	transform: scale(0.95);
}

.generatePopover-section--inputs-input-row-title{
	font-size: 13.25px;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;
  color: var(--text500);
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: flex-start;  

  flex-grow: 0;
  flex-shrink: 0;

  // margin-top: 5px;
}

.generatePopover-section--inputs-input-contents{
	padding-bottom: 8px;	
}

// .generatePopover-section--inputs-input[data-expanded-state='expanded']
// .generatePopover-section--inputs-input-row-title{
// 	font-size: 12.25px;
// }

//

.generatePopover-section-footer{
	display: flex;
	
	margin-left: -6px;
}

.generatePopover-section-footer--vertical{
	flex-direction: column;
	align-items: flex-start;
}


.generatePopover-section-footer-lightBtn{
	height: 26px;
	padding-left: 6px;
	padding-right: 6px;

	font-size: 12px;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;
  color: var(--text500);
  
  opacity: 0.7;
}

.generatePopover-section-footer-lightBtn:hover{
	opacity: 0.9;
}

.generatePopover-section-footer--vertical
.generatePopover-section-footer-lightBtn{
	height: 22px;
}

//
//

.generatePopover-divider{
	width: 100%;
	height: 1px;
	background: var(--line150);
	opacity: 0.5;
	margin-top: 2px;
	margin-bottom: 4px;
}


.generatePopover-section--pageURL-urlBox{
	padding-left: 4px;
	padding-right: 4px;
	padding-top: 4px;
	padding-bottom: 4px;

	box-sizing: border-box;

	background: var(--250gray);
	width: calc(100% + 8px);
	margin-left: -4px;

	border-radius: 3px;
	position: relative;
}

.generatePopover-section--pageURL-urlBox--single{
	display: flex;	
	height: 32px;
}

.generatePopover-section--pageURL-urlBox--multi{	
	padding: 8px;
	padding-bottom: 32px;
	min-height: 100px;
}

.generatePopover-section--pageURL-urlBox--single
.generatePopover-section--pageURL-urlBox-url{
	flex-grow: 1;
	flex-shrink: 1;
	padding-left: 4px;
	box-sizing: border-box;
}

.generatePopover-section--pageURL-urlBox-copyLink{
	flex-grow: 0;
	flex-shrink: 0;

	height: 32px;
	display: flex;
	align-items: center;
}

.generatePopover-section--pageURL-urlBox--single
.generatePopover-section--pageURL-urlBox-copyLink{
	position: relative;
	top: -4px;
}

.generatePopover-section--pageURL-urlBox--multi
.generatePopover-section--pageURL-urlBox-copyLink{
	position: absolute;
	bottom: 0px;
	right: 4px;
}



.generatePopover-section--pageURL-urlBox-copyLink-inner{
	display: flex;
	background: rgba(255,255,255,0.08);
	border-radius: 3px;
	align-items: center;
	justify-content: center;

	padding-right: 4px;
	padding-left: 4px;
}

.generatePopover-section--pageURL-urlBox-copyLink:hover
.generatePopover-section--pageURL-urlBox-copyLink-inner{
	background: rgba(255,255,255,0.10);
}

.generatePopover-section--pageURL-urlBox-copyLink-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	width: 20px;
	height: 24px;

	margin-left: -2px;
}

.generatePopover-section--pageURL-urlBox-copyLink-iconContainer svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.65;
	transform: scale(0.95);
}

.generatePopover-section--pageURL-urlBox-copyLink-label{
	font-size: 12.75px;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;
  color: var(--text500);
  user-select: none;
  padding-right: 4px;
  position: relative;
  top: -0.5px;
}

.generatePopover-section--pageURL-urlBox-url{
// position: absolute;
	// left: 10px;
	// right: 10px;
	// top: 8px;
	// height: 105px;

	font-size: 12.75px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: monospace;   
  font-variation-settings: "wght" 400;
  letter-spacing: -0.011em;

  opacity: 0.95;
}


.generatePopover-section--pageURL-urlBox--single
.generatePopover-section--pageURL-urlBox-url{
	display: flex;
	align-items: center;
	justify-content: flex-start;



	overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
}

.generatePopover-section--pageURL-urlBox--multi
.generatePopover-section--pageURL-urlBox-url{
	 line-height: 1.3;

    /* Line clamping for Chrome */
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;

  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-all;
  overflow-wrap: break-word;
  hyphens: none;
}

.generatePopover-section--pageURL-viewPageBtn{
  
	margin-top: 10px;
	margin-bottom: 6px;

  width: 100%;
  height: 32px;

  background: #516CE8;
  background: var(--primary500);

  font-size: 14px;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;
  color: var(--text500);
  user-select: none;


  border-radius: 3px;
}

.generatePopover-section--pageURL-viewPageBtn:hover{
  background: darken(#516CE8, 1%);
  background: var(--primary500);
}

.generatePopover-section--urlFormula{
	margin-top: -10px;
	margin-bottom: 15px;
}

//

@import "Editor-GeneratePopover-CodeBox.scss";

//

.generatePopover-section--previewVideo-video{
	position: relative;
	background: $tempr;
	width: 356px;
	aspect-ratio: 16/9;
	margin-top: -6px;
	margin-bottom: 4px;
}