.editor-timeline-track-zoomPhase{
  position: absolute;
  //top: -24px;
  bottom: $zoomBottomMargin;
  height: $zoomPhaseHeight;
  left: -14px;
  right: -14px;
  // background: rgba(160,160,160,0.95);
  background: var(--text200);
//dark
  background: var(--line200);
  
  // background: var(--primary100);

  //background: rgba(255,255,255,0.75);
  // background: rgba(0,0,0,0.20);
  opacity: 1;

  border-radius: 2px;

  box-sizing: border-box;
  padding-left: 4px;
  padding-right: 4px;

  z-index: 20;

  display: flex;

  align-items: center;
  justify-content: center;

  opacity: 0.4;
}

.editor-timeline-track-zoomPhase:hover{
  opacity: 1;
  background: var(--text300);
  background: var(--line300);
}

.editor-timeline-track-zoomPhase-iconContainer{
  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-timeline-track-zoomPhase-iconContainer svg{
  width: 14px;
  height: 14px;

  width: 18px;
  height: 18px;

  position: relative;
  fill: white;

  opacity: 0.8;
  // opacity: 0;
}

.editor-timeline-track-zoomPhase-label{
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.editor-timeline-track-zoomPhase-label-label{
  font-size: 12px;
  font-weight: $fw-medium;
  color: white;
  position: relative;
  
}

.editor-timeline-track-zoomPhase-label-iconContainer{
  width: 13px;
  height: 14px;
}

.editor-timeline-track-zoomPhase-label-iconContainer svg{
  width: 10px;
  height: 10px;
  fill: white;  
  position: relative;
  top: -1px;
}