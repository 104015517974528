
.editor-transcriptPanel-sceneHeader{
  position: relative;
  display: flex;    
  // width: calc(100% - 8px);

  padding-top: 26px;
  padding-bottom: 1px;

  box-sizing: border-box;
  padding-left: $leftGutter-transcriptChunk;

  position: relative;
  // top: -1px;  
}

.editor-transcriptPanel-sceneHeader,
.editor-transcriptPanel-sceneHeaderGutter-sceneHeader-title{
  font-size: 14px;
  font-variation-settings: "wght" 500;
  color: var(--text600);
  letter-spacing: 0.012em;
  opacity: 0.6;

  line-height: 1.3;
}

.editor-transcriptPanel-sceneHeader-contents .doc-para{
   
  min-width: 200px;
  // background: $tempr;
  height: 20px;

  display: block;
  white-space: unset;
}

.editor-transcriptPanel-sceneHeader--empty::before{
  content: "Untitled Scene";  
  position: absolute;
  height: 0px;
  width: 280px;
  pointer-events: none;
  //-webkit-text-fill-color: var(--toDoItem-placeholderColor); // to do, refactor
  -webkit-text-fill-color: var(--text500);
  opacity: 0.4;
}



.editor-transcriptPanel-sceneHeader[contenteditable="false"]:first-child{
  padding-top: 0px;
}

.editor-transcriptPanel-sceneHeader[contenteditable="true"]:first-child{
  padding-top: 10px;
}


//
// Outside PM

.editor-transcriptPanel-sceneHeaderGutter{
  position: absolute;
  top: 0px;
  left: 0px;

  width: 100%;
  bottom: 0px;
  height: 100%;

  overflow-x: hidden;
  overflow-y: hidden;
}

.editor-transcriptPanel-sceneHeaderGutter-sceneHeader{
  position: absolute;
  left: 0px;
  width: 100%;
  // background: $tempr;
  height: 30px;
  box-sizing: border-box;
  padding-left: 27px;  

  display: flex;
  align-items: flex-end;
  padding-bottom: 5.5px;

  pointer-events: none;
  align-items: baseline;
  z-index: 100;

  user-select: none !important;

}

.editor-transcriptPanel-sceneHeaderGutter-sceneHeader:first-child{
  margin-top: -12px;
}



.editor-transcriptPanel-sceneHeaderGutter-sceneHeader-title{
  color: $invisible !important;
  // color: red;
  position: relative;
}

.editor-transcriptPanel-sceneHeaderGutter-sceneHeader-duration{
  margin-left: 8px;
  margin-right: 13px;
  font-size: 12.5px;
  font-variation-settings: "wght" 440;
  opacity: 0.8;

  color: var(--text600);
  letter-spacing: 0.012em;
  opacity: 0.35;

  user-select: none;
  pointer-events: none;

}

.editor-transcriptPanel-sceneHeaderGutter-sceneHeader[data-first-header='false']
.editor-transcriptPanel-sceneHeaderGutter-sceneHeader-duration{
  position: relative;
  top: 4px;
}



.editor-transcriptPanel-sceneHeaderGutter-sceneHeader-hSpacer{
  flex-grow: 1;
  flex-shrink: 1;
}


.editor-transcriptPanel-sceneHeaderGutter-sceneHeader-deleteSceneButton{
  z-index: 50;
  cursor: pointer;

  pointer-events: auto;
  position: absolute;
  right: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 5px;
}

.editor-transcriptPanel-sceneHeaderGutter-sceneHeader-deleteSceneButton:hover{
  // background: $tempb;
}

.editor-transcriptPanel-sceneHeaderGutter-sceneHeader-deleteSceneButton-inner{
  padding-left: 6px;
  padding-right: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 20px;

  border-radius: 4px;
  background: var(--252gray);
  border: 1px solid $invisible;

  font-size: 12px;
  font-weight: $fw-medium;
  color: var(--text400);

  font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.018em;

  opacity: 0.75;
}
  

.editor-transcriptPanel-sceneHeaderGutter-sceneHeader-deleteSceneButton:hover
.editor-transcriptPanel-sceneHeaderGutter-sceneHeader-deleteSceneButton-inner{
  background: var(--250gray);
  border: 1px solid var(--line200);
  color: var(--text400);

  opacity: 1;
}




// .editor-transcriptPanel-sceneHeaderGutter-sceneHeader-dropdownBtn{
//   pointer-events: auto;
//   z-index: 50;

//   // background: $tempr;
//   width: 34px;
//   height: 28px;

//   cursor: default;
//   border-radius: 3px;

//   cursor: pointer;
//   margin-right: 3px;

//   display: none;

// }

// .editor-transcriptPanel-sceneHeaderGutter-sceneHeader-dropdownBtn svg{
//   width: 16px;
//   height: 16px;
//   fill: var(--text500);
//   opacity: 0.75;
// }

// .editor-transcriptPanel-sceneHeaderGutter-sceneHeader-dropdownBtn:hover,
// .editor-transcriptPanel-sceneHeaderGutter-sceneHeader-dropdownBtn[data-state='open']{
//   background: var(--250gray);
// }

// .editor-transcriptPanel-sceneHeaderGutter-sceneHeader-dropdownBtn:hover svg,
// .editor-transcriptPanel-sceneHeaderGutter-sceneHeader-dropdownBtn[data-state='open'] svg{
//   opacity: 0.90;
// }

