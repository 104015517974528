$width-dpNumberInputLeft: 25px;
$relaxedWidth-dpNumberInputLeft: 27px;

.dpSlider{
	
	margin-left: -2px;
	height: calc(#{$height-dpElement} + 2px);	
	// height: $height-dpElement;	
	margin-top: 6px;
	margin-bottom: 6px;
	
	position: relative;

	background: var(--252gray);
	border-radius: 3px;

	box-sizing: border-box;
	border: 1px solid $invisible;

	cursor: pointer;
}

.dpSlider *,
.dpSlider *:hover{
	cursor: pointer !important;
}

.dpSlider:hover{
	background: rgba(244,240,255,0.065);
	//border: 1px solid var(--248gray);
}


//



.dpSlider-label{
	position: absolute;
	top: 0px;
	bottom: 0px;
	width: 50%;
	pointer-events: none;	
	user-select: none;
	
  font-size: $baseFontSize-dpElement;	
	color: var(--text500);
	font-variation-settings: "wght" 420;
	letter-spacing: 0.014em;

	display: flex;
	align-items: center;

	z-index: 5;
}

.dpSlider-label--title{
	justify-content: flex-start;
	left: 6px;
	opacity: 0.85;
}

.dpSlider[data-dragging-state='dragging']
.dpSlider-label--title{
	opacity: 0.95 !important;
}


.dpSlider-label--value{
	justify-content: flex-end;
	right: 6px;
	opacity: 0.5;
	transition: opacity 50ms linear;
	font-feature-settings: "ss01", "ss02", "tnum";
}

.dpSlider:hover
.dpSlider-label--value{
	opacity: 0.75;
}

.dpSlider[data-dragging-state='dragging']
.dpSlider-label--value{
	opacity: 0.9 !important;
}

.dpSlider-sliderContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	// background: $tempr;
}
	

.dpSlider-slider{
	width: 100%;
	height: 100%;
}

.dpSlider-slider-track{
	height: 100%;	
}

.dpSlider-slider-track-0{
	background: none;
}

.dpSlider-slider-track-0{
	background: none;
}

.dpSlider-slider-thumb{
	// background: red;
}

//

.dpSlider-suggestedBar{
	pointer-events: none;	
	position: absolute;
	top: 4px;
	bottom: 4px;
	width: 1.5px;
	margin-left: -0.5px;

	background: rgba(255,255,255,0.25);
	z-index: 5;
	opacity: 0;
}

.editor-detailPanel:hover
.dpSlider-suggestedBar{
	opacity: 1;
}

//
// Visual Stuff you can see

.dpSlider-valueBar{
	position: absolute;
	top: 2px;
	bottom: 2px;

	width: 3px;
	margin-left: -1.5px;
	
	border-radius: 1px;
	pointer-events: none;
	z-index: 10;

	opacity: 0;
	transition: opacity 75ms linear;

	background: $focusBorderColor-dpElement;
}

.editor-detailPanel:hover
.dpSlider-valueBar{
	opacity: 1;
}

.dpSlider[data-white-balance='true']
.dpSlider-valueBar[data-value-state='positive']{
	background: #FFEC64;
}

.dpSlider[data-white-balance='true']
.dpSlider-valueBar[data-value-state='negative']{
	background: #95E4FF;
}

.dpSlider-mainBar{
	position: absolute;
	top: 2px;
	bottom: 2px;	
	
	border-radius: 1px;
	background: var(--246gray);
	pointer-events: none;
	z-index: 2;
	min-width: 3px;

	transition: background 75ms linear;
	//background: saturate($focusBorderColor-dpElement, 50%);
	//background: transparentize(#1d3889, 0.3);
}

.dpSlider[data-dragging-state='dragging']
.dpSlider-mainBar,
.dpSlider:hover
.dpSlider-mainBar{
	background: #3C5177;
}

.dpSlider[data-dragging-state='dragging'][data-white-balance='true']
.dpSlider-mainBar[data-value-state='positive'],
.dpSlider:hover[data-white-balance='true']
.dpSlider-mainBar[data-value-state='positive']{
	background: transparentize(#B09B4C, 0.5);
}

.dpSlider[data-dragging-state='dragging'][data-white-balance='true']
.dpSlider-mainBar[data-value-state='negative'],
.dpSlider:hover[data-white-balance='true']
.dpSlider-mainBar[data-value-state='negative']{	
	background: transparentize(#6B97AB, 0.5);
}
