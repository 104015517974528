
.dpColorPicker-valueBtn{
	height: $height-dpElement;
	border-radius: 3px;

	display: flex;

	background: var(--250gray);
	position: relative;
	padding-left: 2px;
	padding-right: 2px;
	border: 1px solid $invisible;
}

.dpColorPicker-valueBtn[data-statusbar-btn='true']{
	height: 27px;	
	border: 1px solid var(--line150);
	background: var(--251gray);
}	

.dpColorPicker-valueBtn[data-spreadsheet-btn='true']{
	position: absolute;
	top: 0px;
	height: 100%;
	width: 100%;
	padding-left: 0px;
	padding-right: 0px;
	border: none !important;
}

.editor-detailPanel-spreadsheetRowBtn[data-above-max-row='true']
.dpColorPicker-valueBtn[data-spreadsheet-btn='true']{
	opacity: 0.5 !important;
}

.dpColorPicker-valueBtn[data-color-type='text']{
	padding-left: 0px;
	padding-right: 0px;
	width: $height-dpElement;
}

.dpColorPicker-valueBtn[data-color-type='text'][data-value-type='extendSelection']:not(:hover){
	//background: var(--252gray);
	background: none;
}

.dpColorPicker-valueBtn[data-color-type='text'][data-value-type='color']:not(:hover){
	background: var(--246gray);
	// background: none;
}


.dpColorPicker-valueBtn:hover{
	background: var(--246gray);
}

.dpColorPicker-valueBtn[data-color-type='text'][data-value-type='extendSelection']:hover{
	background: var(--250gray);
}

.dpColorPicker-valueBtn[data-statusbar-btn='true']:hover{	
	background: var(--246gray);
  border: 1px solid var(--line200);
}	


.dpColorPicker-valueBtn[data-state="open"]{
	border: 1px solid rgba(255,255,255,0.25);
	background: var(--246gray);
}


.dpColorPicker-valueBtn[data-color-type='text'][data-value-type='extendSelection'][data-state="open"]{	
	background: var(--246gray);
}


.dpColorPicker-valueBtn-label{	
  font-size: $baseFontSize-dpElement;	
	color: var(--text500);
	
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;  
  
  box-sizing: border-box;
}

.dpColorPicker-valueBtn-label--auto,
.dpColorPicker-valueBtn-label--none{
	width: 60px;
}

.dpColorPicker-valueBtn-label--extend{
	display: flex;
	align-items: center;
	justify-content: center;
}

.dpColorPicker-valueBtn-label--extend svg{
	width: 16px;
	height: 16px;
	fill: white;
	opacity: 0.45;
}
.dpColorPicker-valueBtn-label--extendSelection{

}

.dpColorPicker-valueBtn-label--none:not(:hover){
	opacity: 0.65;
}

.dpColorPicker-valueBtn-label[data-light-state='true']{
	opacity: 0.65;
}

.dpColorPicker-valueBtn-solid,
.dpColorPicker-valueBtn-image{
	height: 20px;
	aspect-ratio: 16/9;

	border-radius: 1px;	
}

.dpColorPicker-valueBtn[data-statusbar-btn='true']
.dpColorPicker-valueBtn-solid,
.dpColorPicker-valueBtn[data-statusbar-btn='true']
.dpColorPicker-valueBtn-image{
	height: 22px;
	border-radius: 2px;
}	


.dpColorPicker-valueBtn[data-color-type='text']
.dpColorPicker-valueBtn-solid{
	width: 18px;
	height: 18px;
	border-radius: 2px;
}

.dpColorPicker-valueBtn[data-spreadsheet-btn='true']
.dpColorPicker-valueBtn-solid{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	border-radius: 0px;
}

.dpColorPicker-valueBtn-image{
	object-fit: cover;
	overflow: hidden;
}

.dpColorPicker-valueBtn-image img{
	width: 100%;
	height: 100%;	
}



// .dpColorPicker-valueBtn-iconContainer{
// 	width: 24px;
// 	height: 100%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	flex-grow: 0;
// 	flex-shrink: 0;
// }

// .dpColorPicker-valueBtn-iconContainer svg{
// 	width: 14px;
// 	height: 14px;
// 	fill: var(--text500);
// 	opacity: 0.35;
// }

// .dpColorPicker-valueBtn:hover{
// 	background: rgba(244,240,255,0.065);
// }

// .dpColorPicker-valueBtn:hover
// .dpColorPicker-valueBtn-iconContainer svg{
// 	opacity: 0.6;
// }

.dpColorPicker-popover{		

	display: flex;
	flex-direction: column;
	
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 4px;
	padding-right: 4px;
	
	background: lighten(rgb(30,30,31), 3%);

	overflow: none;

	border-radius: 4px;

	border: 1px solid rgb(60,60,64);
	border-top: 1px solid rgb(74,74,78);
	border-bottom: 1px solid rgb(56,56,60);

	box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.18);
	
	box-shadow: var(--boxShadow-dropdown);

	z-index: 500;

	position: relative;
	// opacity: 0.5;
}


.dpColorPicker-popover-grid{
	display: grid;
  grid-template-columns: repeat(4, 1fr);  
}

.dpColorPicker-popover--textColors
.dpColorPicker-popover-grid{
	grid-template-columns: repeat(5, 1fr);
}



.dpColorPicker-popover-option{	
	padding: 3px;

	border-radius: 1px;

	display: flex;
	align-items: center;
	justify-content: center;

  cursor: pointer;
  user-select: none; 

  position: relative;
  border-radius: 3px;
}


.dpColorPicker-popover-option-bg{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-radius: 2px;
}

.dpColorPicker-popover-option:hover
.dpColorPicker-popover-option-bg{
	background: rgba(255,255,255,0.2);
}

.dpColorPicker-popover-option[data-option-auto-state='true']:hover
.dpColorPicker-popover-option-bg,
.dpColorPicker-popover-option[data-option-none-state='true']:hover
.dpColorPicker-popover-option-bg{
		background: rgba(255,255,255,0.08);
}

.dpColorPicker-popover-option[data-color-type='text']:hover
.dpColorPicker-popover-option-bg{
	background: rgba(255,255,255,0.14);
}

.dpColorPicker-popover-option-special,
.dpColorPicker-popover-option-solid,
.dpColorPicker-popover-option-image{
	height: 36px;
	aspect-ratio: 16/9;

	border-radius: 1px;	
	position: relative;
	z-index: 5;
}


.dpColorPicker-popover-option[data-color-type='text']
.dpColorPicker-popover-option-solid{
	width: 24px;
	height: 26px;
	aspect-ratio: 1/1;
	border-radius: 2px;
}


.dpColorPicker-popover-option-special{
	display: flex;
	align-items: center;
	justify-content: center;	

	box-sizing: border-box;
	padding: 2px;
	overflow: hidden;

	position: relative;
}

.dpColorPicker-popover-option-special-label{	
  width: 100%;
  height: 100%;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255,255,255,0.04);

  font-size: $baseFontSize-dpElement;		
	color: white;
	
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;  

  position: relative;
  z-index: 5;
}

.dpColorPicker-popover-option[data-option-auto-state='true']:hover
.dpColorPicker-popover-option-special-label{
	background: rgba(255,255,255,0.10);
}


.dpColorPicker-popover-option[data-option-none-state='true']
.dpColorPicker-popover-option-special-label{
	color: rgba(255,255,255,0.55);	
	background: rgba(255,255,255,0.02);
	// background: repeating-linear-gradient(
  //   -45deg,
  //   transparent,
  //   transparent 4px,
  //   rgba(255, 255, 255, 0.04) 4px,
  //   rgba(255, 255, 255, 0.04) 8px
  // );
}

.dpColorPicker-popover-option[data-option-none-state='true']:hover
.dpColorPicker-popover-option-special-label{
	color: rgba(255,255,255,0.85);	
	background: rgba(255,255,255,0.03);
	// background: repeating-linear-gradient(
  //   -45deg,
  //   transparent,
  //   transparent 2px,
  //   rgba(255, 255, 255, 0.08) 4px,
  //   rgba(255, 255, 255, 0.08) 8px
  // );
}	



.dpColorPicker-popover-option-image{
	object-fit: cover;
	overflow: hidden;
}

.dpColorPicker-popover-option-image img{
	width: 100%;
	height: 100%;	
}

.dpColorPicker-popover-option-activeBorder{
	position: absolute;
	top: 1px;
	left: 1px;
	bottom: 1px;
	right: 1px;
	border: 1px solid rgba(255,255,255,0.95);
	border-radius: 3px;

	opacity: 0;
}

.dpColorPicker-popover-option[data-selected-state='selected']
.dpColorPicker-popover-option-activeBorder{
	opacity: 1;
}

//

.dpColorPicker-popover-chartColorLabel{
	width: 100%;
	margin-top: 12px;
	margin-bottom: 3px;
	font-size: 12.5px;

	margin-left: 4px;

	color: white;
	
  font-variation-settings: "wght" 500;
  letter-spacing: 0.008em;

  cursor: default;
  user-select: none;

  opacity: 0.75;
}

.dpColorPicker-popover-chartBottomSpacer{
	height: 4px;
}

@keyframes popoverFadeInDown {
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes popoverFadeOutUp {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(-4px);
  }
}




@keyframes popoverFadeInUp {
  from {
    opacity: 0;
    transform: translateY(4px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes popoverFadeOutDown {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(4px);
  }
}

.dpColorPicker-popover[data-side='bottom'][data-state='open']{
  animation: popoverFadeInDown 75ms ease-out forwards;
}
.dpColorPicker-popover[data-side='bottom'][data-state='closed']{
  animation: popoverFadeOutUp 75ms ease-in forwards;
}

.dpColorPicker-popover[data-side='top'][data-state='open']{
  animation: popoverFadeInUp 75ms ease-out forwards;
}
.dpColorPicker-popover[data-side='top'][data-state='closed']{
  animation: popoverFadeOutDown 75ms ease-in forwards;
}



// .dpColorPicker-popover-option-solid,
// .dpColorPicker-popover-option-image,
// .dpColorPicker-valueBtn-image,
// .dpColorPicker-valueBtn-solid{
// 	position: absolute;
// 	top: 2px;
// 	height: 20px;
// 	width: 33px;
// 	border-radius: 2px;	
// 	left: 6px;
// 	background: $tempr;
// 	box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
// }

// .dpColorPicker-valueBtn-image,
// .dpColorPicker-valueBtn-solid{
// 	top: 3px;
// 	left: 7px;
// }

// .dpColorPicker-popover-option-image,
// .dpColorPicker-valueBtn-image{
// 	object-fit: cover;
// 	overflow: hidden;
// }

// .dpColorPicker-popover-option-image img,
// .dpColorPicker-valueBtn-image img{
// 	width: 100%;
// 	height: 100%;
// }

// .dpColorPicker-popover-option:focus{
// 	outline: none;
// 	border: none;
// }

// .dpColorPicker-popover-option[data-state='checked']{
// 	font-variation-settings: "wght" 540;
// 	color: var(--text600);
// 	background: lighten(rgb(30,30,31), 9%);	
// }

// .dpColorPicker-popover-option[data-state='unchecked']{
// 	opacity: 0.65;
// }



// .dpColorPicker-popover-option[data-state='unchecked']:hover{
// 	opacity: 0.9;
// 	background: lighten(rgb(30,30,31), 6%);	
// }

// .dpColorPicker-popover-option.dpColorPicker-popover-option--colorPicker[data-state='unchecked'],
// .dpColorPicker-popover-option.dpColorPicker-popover-option--colorPicker[data-state='unchecked']:hover{
// 	opacity: 1;
// }