
@font-face {
    font-family: 'YWaldenburgCondensed';
    src: url(../fonts/YWaldenburgCondensed/YWaldenburgCondensed-Light.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-light;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YWaldenburgCondensed';
    src: url(../fonts/YWaldenburgCondensed/YWaldenburgCondensed-Regular.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-regular;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YWaldenburgCondensed';
    src: url(../fonts/YWaldenburgCondensed/YWaldenburgCondensed-Medium.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-medium;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YWaldenburgCondensed';
    src: url(../fonts/YWaldenburgCondensed/YWaldenburgCondensed-Semibold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-semibold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YWaldenburgCondensed';
    src: url(../fonts/YWaldenburgCondensed/YWaldenburgCondensed-Bold.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YWaldenburgCondensed';
    src: url(../fonts/YWaldenburgCondensed/YWaldenburgCondensed-Heavy.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-heavy;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'YWaldenburgCondensed';
    src: url(../fonts/YWaldenburgCondensed/YWaldenburgCondensed-Black.woff) format('woff');
    font-style: normal;
    font-display: auto;
    font-weight: $fw-black;
    text-rendering: optimizeLegibility;
}
