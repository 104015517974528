.checkboxItem{
	display: flex;
	margin-bottom: 17px;
	cursor: pointer;
}

.checkboxItem-checkboxContainer{
	padding-right: 10px;
}

.checkboxItem-checkbox{
	width: 14px;
	height: 14px;
	background: $tempr;
	border-radius: 3px;

	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.03);
	cursor: pointer;
	position: relative;
	top: 1px;
}

.checkboxItem-checkbox[data-state="unchecked"]{
	background: var(--bg-editor);
	border: 1px solid var(--line400);
}

.checkboxItem-checkbox[data-state="unchecked"]:hover{
	border: 1px solid var(--line600);
}

.checkboxItem-checkbox[data-state="checked"]{
	background: var(--primary500);
	border: 1px solid var(--primary500);
}

.checkboxItem-checkbox[data-state="checked"] svg{
	position: relative;
	// left: 1px;
	top: -0.5px;

	pointer-events: none;

	width: 14px;
	height: 14px;
	stroke: white;
	opacity: 1;
	stroke-width: 0.5px;
	fill: white;
}

.checkboxItem-textContainer{
	display: flex;
	flex-direction: column;	
	user-select: none;
	// padding-top: 2px; // optical v-center with checkbox
}



.checkboxItem-label{
	font-size: 15px;
	font-variation-settings: "wght" 480;
  letter-spacing: 0.003em;

	color: var(--text600);

	transition: opacity 50ms linear;
}

.checkboxItem[data-state="unchecked"]
.checkboxItem-label{
	opacity: 0.8;
}

.checkboxItem-label span.checkboxItem-label-light{
	color: var(--text200);
}

.checkboxItem-description{
	margin-top: 2px;

	font-size: 14px;
	font-weight: $fw-regular;
	color: var(--text200);

	transition: opacity 50ms linear;
}

.checkboxItem[data-state="unchecked"]
.checkboxItem-description{
	opacity: 0.8;
}
