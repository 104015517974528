

.editor-generationsDialog-topSpacer {
  height: $topSpacerHeight-generationsModal;
  min-height: $topSpacerHeight-generationsModal;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
}


.editor-generationsDialog-videoPreview {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  
  position: relative;
  // background: $tempr;
}

.editor-generationsDialog-videoPreview-video{
	pointer-events: none;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.editor-generationsDialog-videoPreview-video video{
	border-radius: 4px;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

//
//

.editor-generationsDialog-controlsContainer {
  height: $controlsHeight-generationsModal;
  width: 100%;
  position: relative;

  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}


$videoPlayerControlsInset: 24px;

.editor-generationsDialog-videoPreview-bgPlayPauseBtn{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 2;	
}

.editor-generationsDialog-videoPreview-posterImageContainer{
	pointer-events: none;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 10;	
}

.editor-generationsDialog-videoPreview-posterImageContainer img{
	width: 100%;
	height: 100%;
	border-radius: 3px;
	overflow: hidden;
}


//


//

.editor-generationsDialog-controls{
	display: flex;

	align-items: center;
	justify-content: center;

	margin-top: 8px;
	height: 54px;
	width: 100%;

	position: relative;

	z-index: 20;

	box-sizing: border-box;
	padding-left: 24px;
	padding-right: 24px;
}

//

$bottomBtnBarOffsetPadding-generationsVideoPlayer: 4px;

.editor-generationsDialog-controls-btnBar{
	width: 100%;
	height: 100%;
	// background: $tempr;

	box-sizing: border-box;
	padding-top: 9px;
	padding-bottom: 0px;


	display: flex;
	align-items: center;

	box-sizing: border-box;
	// padding-left: 4px;
	padding-right: 8px;
}

.editor-generationsDialog-controls-playPauseBtn{
	width: 48px;
	box-sizing: border-box;
	height: 100%;	
	padding-bottom: $bottomBtnBarOffsetPadding-generationsVideoPlayer;	
}



.editor-generationsDialog-controls-playPauseBtn svg{
	width: 17px;
	height: 17px;
	fill: white;
	opacity: 0.85;
}

.editor-generationsDialog-controls-playPauseBtn:hover svg{
	opacity: 1;
}

.editor-generationsDialog-controls-playPauseBtn svg.icon--name--playbackPlay{
	position: relative;
	left: 1px;
}

.editor-generationsDialog-controls-hSpacer{
	flex-grow: 1;
	flex-shrink: 1;
}

.editor-generationsDialog-controls-timestamps{
	display: flex;
	cursor: default;
	user-select: none;
	pointer-events: none;
}

.editor-generationsDialog-controls-timestamps-timestamp{
	font-size: 15.25px;

  font-variation-settings: "wght" 480;

  letter-spacing: -0.008em;
  font-feature-settings: "tnum";

  color: var(--text600);

	color: white;
	position: relative;
	top: -0.5px;

	padding-right: 7px;
	padding-bottom: $bottomBtnBarOffsetPadding-generationsVideoPlayer;

}


.editor-generationsDialog-controls-timestamps-timestamp--total{
	opacity: 0.75;
}


//


.editor-generationsDialog-controls-btn{
	width: 36px;
	height: 100%;
	box-sizing: border-box;
	padding-bottom: $bottomBtnBarOffsetPadding-generationsVideoPlayer;

	// background: $tempr;

}



.editor-generationsDialog-controls-btn svg{
	width: 20px;
	height: 20px;
	fill: var(--text500);
	opacity: 0.75;
}

.editor-generationsDialog-controls-btn:hover svg{
	opacity: 1;
}

.editor-generationsDialog-controls-btn svg.icon--name--upArrowCircle{
	transform: scaleY(-100%);
	height: 24px;
	width: 24px;
}

.editor-generationsDialog-controls-btn svg.icon--name--upArrowCircle #circle{
	opacity: 0.15;
}


//
// SEEK BAR

.editor-generationsDialog-controls-seekBar{
	position: absolute;
	top: -9px;
	height: 22px;
	// background: $tempr;
	width: calc(100% - 60px);
	left: 30px;

	cursor: pointer;
}

.editor-generationsDialog-controls-seekBar-seekBar{
	height: 100%;
	// background: blue;
}

.editor-generationsDialog-controls-seekBar-seekBar-track{
	height: 5px;
	margin-top: 9px;
	background: none;
}

.editor-generationsDialog-controls-seekBar-seekBar-track-0{
	// background: green;
	// background: #57A4FC;
	// background: #8eabff;
	// background: #6C64D2;
	// background: #8980F3;
	// background: #DA483C; // youtube red
	
	background: var(--primary500);
	//background: white;
	// background: #f6768f; // a bit more tomatoee
	border-radius: 2px;
	// border-top-left-radius: 2px;
	// border-bottom-left-radius: 0px;
}

.editor-generationsDialog-controls-seekBar-seekBar-track-1{	
	background: rgba(255,255,255,0.18);
	// border-top-right-radius: 2px;
	// border-bottom-right-radius: 0px;
	border-radius: 2px;
	//border-radius: 0px;
	//background: rgb(75,75,89);
}

//


.editor-generationsDialog-controls-seekBar-marker{
	position: absolute;
	top: 0px;
	height: 10px;
	width: 3px;
	margin-left: -1px;

	background: white;
	outline: 1px solid rgba(0,0,0,0.12);

	pointer-events: none;
	border-radius: 2px;
	margin-top: 7px;

	transition: opacity 0ms linear;

}

.editor-generationsDialog-controls-seekBar-marker[data-state="visible"]{
	opacity: 1;
	transition-delay: 100ms;
}

.editor-generationsDialog-controls-seekBar-marker[data-state="hidden"]{
	opacity: 0;
	transition-delay: 100ms;
}



.editor-generationsDialog-controls-seekBar-hoverPreview{
	position: absolute;

	background: $white;
	
	pointer-events: none;
	border-radius: 2px;

	outline: 1px solid rgba(200,200,200,1);
	box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.12);

	border: none;
	overflow: hidden;
}


.editor-generationsDialog-controls-seekBar-hoverPreview-storyboardImageOuterContainer{
	width: 100%;
	height: 100%;
	// border-radius: 2px;

	// overflow: hidden;
}


.editor-generationsDialog-controls-seekBar-hoverPreview-storyboardImage-image{
	transform-origin: 0% 0%;
}

///

.editor-generationsDialog-controls-seekBar-hoverPreview-timestampContainer{
	position: absolute;
	bottom: 2px;
	left: 0px;
	right: 0px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-generationsDialog-controls-seekBar-hoverPreview-timestamp{
	
	line-height: 1.3;

	background: rgba(0,0,0,0.75);
	//outline: 2px solid rgba(255,255,255,0.15);
	//border: 1px solid rgb(0,0,0,0.85);
	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--text500);
	color: white;

	font-size: 13px;
	font-weight: $fw-regular;
	font-variation-settings: "wght" 490;
	letter-spacing: 0.001em;

	height: 19px;
	width: 40px;
  
	background: lighten(rgba(29,29,31,0.9), 1%);	
	font-feature-settings: "tnum";
}