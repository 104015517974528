// Variables
$dialogEdgeMargin-generationsModal: 50px;
$leftPanelWidth-generationsModal: 340px;
$topSpacerHeight-generationsModal: 50px;
$controlsHeight-generationsModal: 80px;
$maxVideoWidth-generationsModal: 1100px;
$total-vertical-space: $topSpacerHeight-generationsModal + $controlsHeight-generationsModal; 
$leftVideoPadding-generationsModal: 10px;
$rightVideoPadding-generationsModal: 20px;

.editor-generationsDialog-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
}



.editor-generationsDialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(min(
    calc($maxVideoWidth-generationsModal + #{$leftPanelWidth-generationsModal} + 40px),
    calc((100vh - #{2 * $dialogEdgeMargin-generationsModal} - #{$total-vertical-space}) * 16 / 9 + #{$leftPanelWidth-generationsModal} + #{$leftVideoPadding-generationsModal} + #{$rightVideoPadding-generationsModal}),
    calc(100vw - #{2 * $dialogEdgeMargin-generationsModal})
  ));
  height: calc(min(
    calc($maxVideoWidth-generationsModal * 9 / 16 + #{$total-vertical-space}),
    calc(100vh - #{2 * $dialogEdgeMargin-generationsModal}),
    calc((100vw - #{2 * $dialogEdgeMargin-generationsModal} - #{$leftPanelWidth-generationsModal} -  #{$leftVideoPadding-generationsModal} - #{$rightVideoPadding-generationsModal}) * 9 / 16 + #{$total-vertical-space})
  ));
  display: flex;
  flex-direction: row;
  background: rgb(25, 27, 33);
  border-radius: 6px;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.28);
  overflow: hidden;

  border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

}


.editor-generationsDialog:focus {
  outline: none;
}

.editor-generationsDialog-leftPanel {
  width: $leftPanelWidth-generationsModal;
  min-width: $leftPanelWidth-generationsModal;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  flex-grow: 0;
  flex-shrink: 0;
}


.editor-generationsDialog-title {  
  height: 50px;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;

  box-sizing: border-box;
  padding-top: 10px;
  padding-left: 24px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  user-select: none;
  cursor: default;

}

.editor-generationsDialog-title-iconContainer{
	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 4px;
}

.editor-generationsDialog-title-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.65;
}

.editor-generationsDialog-title-label,
.editor-generationsDialog-title-count{
  font-size: 15px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 540;
  letter-spacing: 0.008em;	
}

.editor-generationsDialog-title-count{
	margin-left: auto;
	opacity: 0.5;
	font-feature-settings: "ss01", "ss02";
	margin-right: 14px;
}





.editor-generationsDialog-list {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  scrollbar-gutter: stable;

  box-sizing: border-box;
  padding-left: 18px;
  padding-right: 4px;

  padding-bottom: 24px;
}


.editor-generationsDialog-list::-webkit-scrollbar {
  width: 8px;
}

.editor-generationsDialog-list::-webkit-scrollbar-track {
  background: transparent;
}

.editor-generationsDialog-list::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
}

.editor-generationsDialog-list::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.3);
}


.editor-generationsDialog-item{
	height: 64px;
	flex-grow: 0;
	flex-shrink: 0;
	width: 100%;
	border-radius: 4px;

	display: flex;

	box-sizing: border-box;

}

.editor-generationsDialog-item[data-active-state='active']{
	//background: var(--250gray);
	background: var(--primary600);
}

.editor-generationsDialog-item[data-state='open'][data-active-state='active']{
  //background: var(--250gray);
  background: var(--primary700);
}


.editor-generationsDialog-item[data-state='open'][data-active-state='inactive']{
  background: var(--250gray);
}


.editor-generationsDialog-item-previewContainer{
	padding-left: 10px;
	padding-right: 10px;

	flex-grow: 0;
	flex-shrink: 0;
}

.editor-generationsDialog-item-preview{
	height: 38px;
	aspect-ratio: 16/9;
	// background: $tempr;
  position: relative;
}

.editor-generationsDialog-item-preview img{
	object-fit: cover;
	width: 100%;
	height: 100%;
	border-radius: 1px;
}

// .editor-generationsDialog-item[data-active-state='active']
// .editor-generationsDialog-item-preview-border{
//   border: 1px solid rgba(255,255,255,0.35);
// }

// .editor-generationsDialog-item-preview-border{
//   position: absolute;
//   top: -1px;
//   left: -1px;
//   right: -1px;
//   bottom: -1px;
//   border: 1px solid rgba(255,255,255,0);
//   border-radius: 3px;
//   z-index: 5;
// }

.editor-generationsDialog-item-rightContainer{
	
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	box-sizing: border-box;
	padding-bottom: 2px;

	flex-grow: 1;
	flex-shrink: 1;
}

.editor-generationsDialog-item-rightTopContainer{
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  width: 100%;
}

.editor-generationsDialog-item-timestamp{
	font-size: 13.75px;
	font-weight: $fw-medium;
	color: var(--text500);

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;  
}

.editor-generationsDialog-item-duration{
	font-size: 12.75px;
	font-weight: $fw-medium;
	color: var(--text500);
	opacity: 0.65;

	font-family: system-ui, sans-serif;
  font-variation-settings: "wght" 440;
  letter-spacing: 0.008em;
}

.editor-generationsDialog-item-previewTag{
  margin-left: auto;
  margin-right: 7px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 14px;
  line-height: 1.3;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 2px;
  background: rgba(255,255,255,0.15);
  color: white;
  font-size: 9.5px;
  font-weight: $fw-medium;
  font-variation-settings: "wght" 520;
  letter-spacing: 0.00128em;

  text-transform: uppercase;
}

//


.editor-generationsDialog-leftPanel {
  width: $leftPanelWidth-generationsModal;
  min-width: $leftPanelWidth-generationsModal;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}



//

.editor-generationsDialog-rightPanel {
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding-left: $leftVideoPadding-generationsModal;  
  padding-right: $rightVideoPadding-generationsModal;  
  flex-grow: 1;
  flex-shrink: 1;
}

@import 'Editor-GenerationsDialog-VideoPlayer';