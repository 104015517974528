
//
//


.editor-timelineContainer{  
  flex-grow: 1;
  flex-shrink: 1;

  width: 100%;
  background: var(--bg-editorTimeline);

  position: relative;
  z-index: 55;
  box-sizing: border-box;

  overflow-y: hidden;
}

.editor-timelineContainer{

}

.editor-timelineContainer[data-read-only='true']{
  .editor-timeline-innerContainer{
    pointer-events: none !important;
  }
  .editor-timeline-clip-handle{
    visibility: hidden !important;
  }
}

.editor-timelineContainer--showGrabbingCursor{  
  cursor: grabbing !important;
}

.editor-timelineContainer--showGrabbingCursor * {
  cursor: grabbing !important;
}

.editor-timelineContainer--showGrabCursor{  
  cursor: grab !important;
}

.editor-timelineContainer--showGrabCursor * {
  cursor: grab !important;
}


$height-editorTimelineFooter: 40px;

.editor-timelinePanel{
  display: flex;
  flex-direction: column;
    
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;

  height: 100%;

  box-sizing: border-box;  

  overflow: hidden;

  border: var(--border-panel);
  border: none;
  background: var(--bg-editorTimeline);

  position: relative;
}


.editor-timelinePanel-trimModeBlocker{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  //background: var(--bg-editorTimeline);
  // opacity: 0.8;
  z-index: 1000;


}


.editor-timelineSidePanel{
  position: absolute;
  top: 0px;
  left: 0px;
  width: $width-timeline-sidePanel;
  height: 100%;
  //height: calc(100% - #{$height-timeline-footer});
  background: $tempg;
}

.editor-timeline{
  position: absolute;  
  top: 0px;
  left: 0px;  
  width: 100%;
  //height: calc(100% - #{$height-timeline-footer});
  height: 100%;
  padding-left: $width-timeline-sidePanel;
  padding-right: $width-timeline-sidePanel;
  box-sizing: border-box;
  

  //border-top: 1px solid var(--line500);  
  border-top: 1px solid rgb(255,255,255,0.08);

  overflow-x: scroll;
  overflow-y: hidden;

  display: flex;
  cursor: pointer;
}



.editor-timeline::-webkit-scrollbar {
  height: 12px; /* Adjust the height of the scrollbar for horizontal scrolling */  
  background: rgba(255,255,255,0.0);
  border-radius: 2px; /* Rounded corners of the scrollbar thumb */
  // transition: opacity 200ms linear;  
  // transition-delay: 50ms;
}

.editor-timelinePanel:hover
.editor-timeline::-webkit-scrollbar{
  background: rgba(255,255,255,0.04);
}

.editor-timeline::-webkit-scrollbar:hover{
  background: rgba(255,255,255,0.08);
}



.editor-timeline--isScrolling::-webkit-scrollbar{
  background-color: rgba(255,255,255,0.08); /* Color of the scrollbar thumb */
}


/* Style the scrollbar thumb (the draggable part) */
.editor-timeline::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,0.0); /* Color of the scrollbar thumb */
  border-radius: 2px; /* Rounded corners of the scrollbar thumb */
  transition: opacity 100ms linear;  
}

.editor-timelinePanel:hover
.editor-timeline::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,0.08);
}

/* Style the scrollbar thumb when hovering over it */
.editor-timelinePanel:hover
.editor-timeline::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255,255,255,0.24); /* Color of the scrollbar thumb */
  transition: opacity 50ms linear;  
  transition-delay: 50ms;
}

.editor-timeline--isScrolling::-webkit-scrollbar-thumb{
  background-color: rgba(255,255,255,0.24); /* Color of the scrollbar thumb */
}



.editor-timelineMeasurer{
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  width: 100%;
  height: 100%;
  pointer-events: none;
}

.editor-timeline-innerContainer{
  position: absolute;
  top: 36px; // 
  top: 28px; // 
  // top: 40px;
  // top: 74px;
  // // top: 90px;
  left: $width-timeline-sidePanel;
  width: 100%;
  bottom: 0px;

  //background: $tempb;
}


//

@import 'EditorTimeline-Timeline';
@import 'EditorTimeline-Playhead';
@import 'EditorTimeline-LengthMarker';

//
//

$topPadding-clip: 4px;
$bottomPadding-clip: 4px;
$leftPadding-clip: 3px;
$segmentHeight: 24px;

$zoomPhaseHeight: 16px;
$zoomTopMargin: 4px;
$zoomBottomMargin: 4px;

$height-track: calc(#{$topPadding-clip} + #{$segmentHeight} + #{$bottomPadding-clip});  
$height-trackContainer: calc(#{$height-track});  
$height-trackContainerZoom: calc(#{$height-trackContainer} + #{$zoomPhaseHeight} + #{$zoomTopMargin} + #{$zoomTopMargin});  

$height-clip: $height-track;

.editor-timeline-track{
  width: 100%;
  height: $height-trackContainer;

  //background: lighten(rgb(32,34,42), 4%);
  background: lighten(rgb(32,34,42), 1%);
  // /border: 1px solid lighten(rgb(32,34,42), 6%);
  outline: 1px solid var(--bg-editorTimeline);
  outline: 1px solid lighten(rgb(32,34,42), 5%);

  position: relative;  
  margin-top: 7px;
  border-radius: 2px;

  cursor: pointer;   
}

.editor-timeline-track--zoom{
  background: none;
  outline: none;
  margin-top: -2px;
  margin-bottom: 4px;  
}


.editor-timeline-track--voiceover{
  z-index: 5;
}



@import 'EditorTimeline-Scenes'; // Initial Move

@import 'EditorTimeline-Clips'; // Initial Move



@import 'EditorTimeline-Segments';
@import 'EditorTimeline-ZoomPhases';

@import 'EditorTimeline-Footer';

//

.editor-timeline-leftEdgeBtn{
  position: absolute;
  left: calc(#{$width-timeline-sidePanel} * -1);
  width: $width-timeline-sidePanel;
  top: -40px;
  bottom: 0px;
  //background: $tempr;

  z-index: 50;
}

//$endMarkerBG: darken(#B578DD, 5%);
//$endMarkerBG: #725CB9;
$endMarkerBG: rgb(215,215,215);


.editor-timeline-endMarker{
  position: absolute;
  top: -17.5px;
  bottom: 6px;
  margin-left: -1px;
  // width: 10px;
  // background: $tempr;
  z-index: 50;

  pointer-events: none;

  transition: opacity 100ms linear;
}

.editor-timeline-endMarker[data-visibility-state="true"]{
  opacity: 1;
}

.editor-timeline-endMarker[data-visibility-state="false"]{
  opacity: 0;
}

.editor-timeline-endMarker-marker{
  position: absolute;
  z-index: 5;
  top: 0px;
  height: 22px;
}

.editor-timeline-endMarker-marker-topLine{
  position: absolute;
  left: 0px;
  height: 100%;
  top: 0px;
  width: 3px;
  border-radius: 2px;
  background: $endMarkerBG;
}

.editor-timeline-endMarker-marker-label{
  position: absolute;
  left: 6px;
  top: 2.5px;
  bottom: 2.5px;

  background: $endMarkerBG;
  padding-left: 3.5px;
  padding-right: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 2px;

  font-size: 12.75px;
  color: white;
  color: rgb(30,30,40);

  font-variation-settings: "wght" 600;
  letter-spacing: -0.004em;

  // font-feature-settings: "tnum";
}

.editor-timeline-endMarker-line{
  position: absolute;
  // width: 20000px;
  left: 1px;
  top: -20px;
  bottom: -1px;
  border-radius: 2px;

  opacity: 0.55;
  // background: $tempr;

  background: $endMarkerBG;
  background: rgb(27,28,29);

  pointer-events: none !important;

}


// AutoZoomButton




// .editor-timeline-track{
  
//   border-radius: 2px;  
//   position: relative;

//   z-index: 10; 
// }

// .editor-timeline-track--voiceover{
//   background: var(--primary500);
//   outline: 1px solid var(--primary600);
//   outline: 1px solid #468CFF;

//   background: var(--timelineCamera-bg);
//   outline: 1px solid var(--timelineCamera-outline);
// }

// .editor-timeline-track--slide{
//   background: var(--timelineSlides-bg);
//   outline: 1px solid var(--timelineSlides-outline);
// }

// .editor-timeline-track--bgMusic{
//   height: 29px;
// }

// .editor-timeline-track--bgMusic[data-state="hasValue"]{
//   background: var(--accentOrange600);
//   outline: 1px solid var(--accentOrange700);
// }

// .editor-timeline-track--bgMusic[data-state="empty"]{
//   background: var(--line200);
//   outline: 1px solid var(--line300);  
//   opacity: 0.65;
// }

// .editor-timeline-track--bgMusic[aria-expanded="true"]{
//   pointer-events: none;
// }

// .editor-timeline-track--bgMusic[data-state="empty"]:hover{
//   opacity: 1;
// }

// .editor-timeline-audioTrackBG{
//   position: absolute;
//   left: 0px;
//   width: 100%;
  
//   z-index: 1;

//   background: var(--243gray);
//   outline: 1px solid var(--235gray);

//   background: lighten(rgb(32,34,42), 4%);
//   outline: 1px solid lighten(rgb(32,34,42), 6%);
// }

// .editor-timeline-track-inner{
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   width: 100%;
//   height: 100%;

//   display: flex;
//   align-items: center;

//   box-sizing: border-box;
//   padding-left: 2px;


//   user-select: none;
// }


// .editor-timeline-track-inner-iconContainer{
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   width: 20px;
//   height: 24px;
//   margin-right: 0px;

//   flex-grow: 0;
//   flex-shrink: 0;
// }

// .editor-timeline-track-inner-iconContainer svg{
//   width: 12px;
//   height: 12px;
//   fill: white;
//   opacity: 0.5;
// }

// .editor-timeline-track--bgMusic[data-state="empty"]
// .editor-timeline-track-inner-iconContainer svg{
//   fill: var(--text500);
// }


// .editor-timeline-track--slide
// .editor-timeline-track-inner-iconContainer{
//   width: 24px;
//   margin-right: -1px;
//   margin-left: -1px;
// }

// .editor-timeline-track--slide
// .editor-timeline-track-inner-iconContainer svg{
//   width: 18px;
//   height: 18px;
//   opacity: 0.5;
// }


// .editor-timeline-track-inner-iconContainer svg.icon--name--trackBacking{
//   width: 18px;
//   height: 18px;
//   position: relative;
//   top: -1px;
// }

// .editor-timeline-track-inner-label{
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;

//   flex-grow: 1;
//   flex-shrink: 1;
// }

// .editor-timeline-track--bgMusic[data-state="empty"]
// .editor-timeline-track-inner-label{
//   color: var(--text500);
// }
