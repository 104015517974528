

.recordingControls-selectWindow{
	position: absolute;
	top: 0px;
	left: 0px;

	width: 100%;
	height: 100%;	

	z-index: 100;

	background: $tempr;

	display: flex;
	flex-direction: column;

	background: var(--bg-editorPanel);
	border: 1px solid rgba(255,255,255,0.10);
	border-bottom: 1px solid rgba(255,255,255,0.10);
	border-top: 1px solid rgba(255,255,255,0.2);
	border-radius: 8px;

	box-shadow: 
		0px 4px 8px 0px rgba(0,0,0,0.5),
		0px 8px 16px 0px rgba(0,0,0,0.25),
		0px 16px 32px 0px rgba(0,0,0,0.125),
		0px 32px 64px 0px rgba(0,0,0,0.07),
}


.recordingControls-countdownContainer{
	position: absolute;
	top: 0px;
	left: 0px;

	width: 100%;
	height: 100%;

	background: var(--bg-editorPanel);
	border: 1px solid rgba(255,255,255,0.10);
	border-bottom: 1px solid rgba(255,255,255,0.10);
	border-top: 1px solid rgba(255,255,255,0.2);
	border-radius: 8px;

	box-shadow: 
		0px 4px 8px 0px rgba(0,0,0,0.5),
		0px 8px 16px 0px rgba(0,0,0,0.25),
		0px 16px 32px 0px rgba(0,0,0,0.125),
		0px 32px 64px 0px rgba(0,0,0,0.07),
}


.recordingControls-selectWindow-header{
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 10px;

	margin-top: 8px;

}

.recordingControls-selectWindow-header-iconContainer{
	width: 44px;
	height: 44px;

	margin-top: 6px;
	margin-left: 8px;

	 
	display: flex;
	align-items: center;
	justify-content: center;
}

.recordingControls-selectWindow-header-iconContainer svg{
	width: 30px;
	height: 30px;
	fill: #97BFFF;
	// fill: white;
	opacity: 0.75;
}

.recordingControls-selectWindow-header-cancelBtn{

	padding-left: 20px;
	padding-right: 20px;
	height: 30px;

	border-radius: 4px;

	position: relative;
	top: -6px;

	font-size: 13.5px;
	font-weight: $fw-medium;
	letter-spacing: 0.008em;
	color: var(--text500);
	opacity: 0.5;

	cursor: pointer;
}

.recordingControls-selectWindow-header-cancelBtn:hover{
	opacity: 1;
	background: var(--250gray);
}

.recordingControls-label{
	font-size: 13.5px;
	font-weight: $fw-regular;
	color: var(--text500);
	opacity: 0.6;

	margin-left: 26px;
	margin-top: 14px;

	margin-bottom: 8px;
	pointer-events: none;
}

.recordingControls-selectWindow-windowList{
	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	padding-left: 16px;
	padding-right: 16px;

	padding-bottom: 48px;

}



.recordingControls-selectWindow-windowList-window{
	justify-content: flex-start;
	height: 30px;

	width: 100%;
	// background: $tempr;

	font-size: 15px;
	font-weight: $fw-medium;
	color: var(--text500);

	border-radius: 3px;

	padding-left: 10px;
	padding-right: 10px;

  cursor: pointer;

  opacity: 1;

  cursor: pointer;

}

.recordingControls-selectWindow-windowList-window-label{
	text-align: left;
}

.recordingControls-selectWindow-windowList-window-label{
	flex-shrink: 0;
}


.recordingControls-selectWindow-windowList-window-label--windowName{
	opacity: 0.5;
	flex-grow: 1;
	flex-shrink: 1;

	padding-left: 5px;


  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

	

	
}

.recordingControls-selectWindow-windowList-window-label--windowName--onlyWindow{
	opacity: 0;
}



.recordingControls-selectWindow-windowList-window:hover{
	background: var(--250gray);
}

.recordingControls-selectWindow-windowList-windowList:hover
.recordingControls-selectWindow-windowList-window{
	opacity: 0.6;
}

.recordingControls-selectWindow-windowList-windowList:hover
.recordingControls-selectWindow-windowList-window:hover{
	opacity: 1;
}

//




//

.recordingControls-stopBtn{
	box-sizing: border-box;

	height: 56px;
	width: 100%;

	padding-left: 4px;
	padding-right: 4px;
	padding-top: 4px;
	padding-bottom: 4px;
	border-radius: 6px;

	background: $tempb;

	cursor: pointer;
	

	z-index: 10;

	background: var(--bg-editorPanel);
	// border: 1px solid rgba(255,255,255,0.10);
	// border-bottom: 1px solid rgba(255,255,255,0.10);
	// border-top: 1px solid rgba(255,255,255,0.2);
	// border-radius: 8px;

	// box-shadow: 
	// 	0px 4px 8px 0px rgba(0,0,0,0.5),
	// 	0px 8px 16px 0px rgba(0,0,0,0.25),
	// 	0px 16px 32px 0px rgba(0,0,0,0.125),
	// 	0px 32px 64px 0px rgba(0,0,0,0.07),
}

.recordingControls-stopBtn-btn{
	width: 100%;
	height: 100%;
	border-radius: 4px;

	background: rgba(255,255,255,0.04);
	border: 1px solid rgba(255,255,255,0.06);

	font-size: 16px;
	font-weight: $fw-semibold;
	letter-spacing: 0.004em;
	color: var(--text500);

	opacity: 0.8;
	position: relative;

	transition: opacity 150ms linear;
}

.recordingControls-stopBtn-btn:hover{
	opacity: 1;
	background: rgba(255,255,255,0.06);
	border: 1px solid rgba(255,255,255,0.08);

	transition: opacity 75ms linear;
}

.recordingControls-stopBtn-btn-countdown{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	column-gap: 18px;

	font-size: 20px;
}

.recordingControls-stopBtn-btn-countdown-letter{
	transition: opacity 200ms linear;
}

.recordingControls-stopBtn-btn-countdown-letter--before{
	opacity: 0.15;
}

.recordingControls-stopBtn-btn-countdown-letter--during{
	opacity: 1;
}

.recordingControls-stopBtn-btn-countdown-letter--after{
	opacity: 0.5;		
}


.recordingControls-stopBtn-btn-done,
.recordingControls-stopBtn-btn-stopwatch{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}

.recordingControls-stopBtn-btn-stopwatch{
	font-variant-numeric: tabular-nums;
}







// .recordingControlsContainer{
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;

// 	// padding-top: 24px;
// 	// background: $tempr;
// 	display: flex;
// 	align-items: flex-start;
// 	justify-content: center;

// 	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
// }

// .recordingControls{

// 	box-sizing: border-box;
// 	padding-left: 12px;
// 	padding-right: 3px;

// 	display: flex;
// 	align-items: center;
// 	min-width: 780px;

// 	height: 40px;
// 	border-radius: 5px;
	

// 	box-shadow: 
// 		0px 4px 8px 0px rgba(0,0,0,0.5),
// 		0px 8px 16px 0px rgba(0,0,0,0.3),
// 		0px 16px 32px 0px rgba(0,0,0,0.1),
// 	;

// 	background: rgb(28,29,33);
// 	border: 1px solid lighten(rgb(28,29,33), 8%);
// 	border-top: 1px solid lighten(rgb(28,29,33), 20%);
// 	border-bottom: 1px solid lighten(rgb(28,29,33), 6%);

// 	font-size: 14px;
// 	color: white;

// 	font-weight: $fw-medium;

// 	user-select: none;
// 	cursor: default;

// 	transform-origin: 20% 50%;

// }

// .recordingControls-label{
// 	font-weight: $fw-regular;
// 	letter-spacing: 0.008em;
// 	font-size: 14.5px;
// 	opacity: 0.75;
// }



// .recordingControls-hSpacer{
// 	flex-grow: 1;
// 	flex-shrink: 1;
// }

// .recordingControls-cancelBtn{
// 	width: 110px;
// 	height: 30px;
// 	border-radius: 3px;
// 	color: white;

// 	cursor: pointer;


// 	color: rgba(255,255,255,1);
// 	background: rgba(255,255,255,0.08);

// 	font-weight: $fw-medium;
// }

// .recordingControls-cancelBtn:hover{
// 	background: rgba(255,255,255,0.12);
// }