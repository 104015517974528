//
// CLIP

.editor-timeline-clip{  
  position: absolute;
  top: 0px;

  border-radius: 3px;  
  
  box-sizing: border-box;
  z-index: 50;
}

.editor-timeline-clip--render{
  // background: $tempg;
  // border: 1px solid green;
  pointer-events: none;
}

.editor-timeline-clip--render[data-clip-promotezindex-state='true']{
  z-index: 150; 
}

.editor-timeline-clip--render--activePreview{
  opacity: 0.5;
  display: none;
}

.editor-timeline-clip--draggable{
  background: $invisible;
  border: 1px solid $invisible;

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  
  z-index: 55;
}

.editor-timeline-clip--draggableContainer{
  z-index: 60;
}

.editor-timeline-clip--draggable--push{
  // background: $tempr;
  // border: 1px solid $red;
  z-index: 55;
}

.editor-timeline-clip--draggable--dnd{
  // background: $tempg;
  // border: 1px solid green;
  z-index: 55;

  transform: scale(1.02);
  transition: transform 50ms linear;
  // opacity: 0;
  opacity: 0.7;
}


// .editor-timeline-clip[data-clip-type='video']{
//   background: red;
// }

.editor-timeline-clip--draggable-handle{
  position: absolute;
  background: $tempr;
  top: 0px;
  height: 100%;

  width: 10px;

  z-index: 60;
  cursor: grab !important;
}

.editor-timeline-clip-draggable--handle--left{
  left: 0px;
}
.editor-timeline-clip-draggable--handle--right{
  right: 0px;
}

//
// Default Styling

.editor-timeline-clip--render[data-clip-type='screenVideo'],
.editor-timeline-clip--draggable--screenVideo.editor-timeline-clip--draggable--dnd{ 
  background: saturate(rgba(110,99,217,0.70), 6%);  
  border: 1px solid lighten(rgba(110,99,217,1), 9%);
}

.editor-timeline-clip--render[data-clip-type='basicVideo'],
.editor-timeline-clip--draggable--basicVideo.editor-timeline-clip--draggable--dnd{  
  // background: rgba(110,99,217,0.80);  
  // border: 1px solid lighten(rgba(110,99,217,1), 10%);
  background: #642E2E;
  border: 1px solid #CB6F6F;

  background: #763F5E;
  border: 1px solid #B47190;
  
}

.editor-timeline-clip--render[data-clip-type='textSlide']{
  background: transparentize(saturate(#437ce1, 5%), 0.4);
  border: 1px solid lighten(saturate(#437ce1, 5%), 10%);

  // background: darken(transparentize(#437ce1, 0), 2%);  
  // border: 1px solid lighten(saturate(#437ce1, 5%), 10%);
}

.editor-timeline-clip--draggable--textSlide.editor-timeline-clip--draggable--dnd{
  border: 1px solid #7598E6;
  background: #4A75D9; // active opaque  
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.48) !important;
}


.editor-timeline-clip--render[data-clip-type='chart'],
.editor-timeline-clip--draggable--chart.editor-timeline-clip--draggable--dnd{
  background: transparentize(desaturate(rgba(255,138,0,0.61), 12%), 0.12);
  border: 1px solid desaturate(rgba(255,138,0,0.95), 2%); 
}

.editor-timeline-clip--render[data-clip-type='image'],
.editor-timeline-clip--draggable--image.editor-timeline-clip--draggable--dnd{
  // background: transparentize(saturate(#437ce1, 5%), 0.4);
  // border: 1px solid lighten(saturate(#437ce1, 5%), 10%);
  //background: rgb(29,98,46);
  // background: rgb(39,96,53);
  // border: 1px solid desaturate(rgb(48,145,72), 5%);
  background: #225040;
  background: desaturate(lighten(#235443, 4%), 12%);
  border: 1px solid desaturate(rgb(48,145,72), 5%);
}

.editor-timeline-clip--render[data-clip-type='audio']{  
  // background: lighten(rgb(25,27,33), 10%);  
  // border: 1px solid lighten(rgb(25,27,33), 22%);  
  background: lighten(rgb(25,25,29), 8%);  
  border: 1px solid lighten(rgb(25,25,29), 18%);  
}

.editor-timeline-clip--render[data-clip-type='webcamScript']{  
  background: lighten(rgb(25,25,29), 10%);  
  border: 1px solid lighten(rgb(25,25,29), 18%);  
}

.editor-timeline-clip--render[data-clip-type='webcamInstructions']{  
  background: var(--instructionsBG);  
  background: #4d321b;
  border: 1px solid var(--instructionsLine);  
}

.editor-timeline-clip--render[data-clip-type='webcamRecording']{  
  background: lighten(#1D282B, 3%);
  border: 1px solid lighten(#1D282B, 16%);

  background: lighten(#1D282B, 3%);
  border: 1px solid lighten(#1D282B, 16%);
}

.editor-timeline-clip--render[data-clip-type='linkedWebcamRecording']{  
  background: darken(desaturate(rgba(110,99,217,0.70), 18%), 24%);  
  border: 1px solid lighten(rgba(110,99,217,0.8), 0%);
}



//
// Active Styling

.editor-timeline-clip--render[data-clip-type='screenVideo'][data-clip-active='true']{
  background: darken(rgba(110,99,217,1), 1%);    
  border: 1px solid lighten(rgba(110,99,217,1), 10%);
}

.editor-timeline-clip--render[data-clip-type='screenVideo'][data-clip-active='true'][data-clip-context-menu-open='true']{
  background: lighten(saturate(rgba(110,99,217,1), 5%), 2%);
  border: 1px solid lighten(rgba(110,99,217,1), 13%);
}

//

.editor-timeline-clip--render[data-clip-type='basicVideo'][data-clip-active='true']{    
  // background: #9E4D77;
  border: 1px solid #B47190; 
  background: darken(#A74C7B, 2%); 
}

.editor-timeline-clip--render[data-clip-type='basicVideo'][data-clip-active='true'][data-clip-context-menu-open='true']{
  background: lighten(#A74C7B, 4%); 
  border: 1px solid lighten(#B47190, 2%);
}

//

.editor-timeline-clip--render[data-clip-type='textSlide'][data-clip-active='true']{
  background: darken(transparentize(#437ce1, 0), 2%);  
  border: 1px solid lighten(saturate(#437ce1, 5%), 10%);
}

.editor-timeline-clip--render[data-clip-type='textSlide'][data-clip-active='true'][data-clip-context-menu-open='true']{
  background: lighten(#437ce1, 3%);  
  border: 1px solid lighten(saturate(#437ce1, 5%), 13%);
}

//

.editor-timeline-clip--render[data-clip-type='image'][data-clip-active='true']{
  background: darken(rgb(43,144,68), 2%);
  border: 1px solid desaturate(rgb(48,145,72), 5%);
  background: desaturate(#2B9063, 5%);
}

.editor-timeline-clip--render[data-clip-type='image'][data-clip-active='true'][data-clip-context-menu-open='true']{
  background: lighten(desaturate(#2B9063, 2%), 2%);
  border: 1px solid lighten(desaturate(rgb(48,145,72), 2%), 3%);
}

//

.editor-timeline-clip--render[data-clip-type='chart'][data-clip-active='true']{
  background: darken(desaturate(rgba(255,138,0,0.80), 10%), 3%);  
  border: 1px solid desaturate(rgba(255,138,0,1), 0%); 
}

.editor-timeline-clip--render[data-clip-type='chart'][data-clip-active='true'][data-clip-context-menu-open='true']{
  background: lighten(desaturate(rgba(255,138,0,0.80), 5%), 2%);  
  border: 1px solid lighten(desaturate(rgba(255,138,0,1), 0%), 4%); 
}



.editor-timeline-clip--render[data-clip-type='webcamInstructions'][data-clip-active='true']{  
  background: var(--instructionsBG);  
  background: lighten(#4d321b, 5%);
  border: 1px solid var(--instructionsLine);  
}


.editor-timeline-clip--render[data-clip-type='webcamRecording'][data-clip-active='true']{  
  background: lighten(#1D282B, 4%);
  border: 1px solid lighten(#1D282B, 20%);
}



.editor-timeline-clip--render[data-clip-type='linkedWebcamRecording'][data-clip-active='true']{  
  background: darken(desaturate(rgba(110,99,217,0.70), 18%), 18%);  
  border: 1px solid lighten(rgba(110,99,217,0.8), 6%);
}



//

.editor-timeline-clip--active.editor-timeline-clip--voiceover{
  background: transparentize(#005cff8f, 0.2);
  //outline: 1px solid transparentize(#5791f8, 0.5); 

  background: transparentize(#437ce1, 0);
  outline: 1px solid transparentize(#437ce1, 0);
}

//
// Zoom Clip

.editor-timeline-clip--draggable--zoom,
.editor-timeline-clip--render[data-clip-type='zoom']{
  //margin-top: 26px;
  height: 20px;
  //outline: 1px solid red !important;
}


.editor-timeline-clip--render[data-clip-type='zoom']{
  border-radius: 4px;
}

.editor-timeline-clip--render[data-clip-type='zoom'][data-clip-active='false']{  
  background: lighten(rgb(25,27,33), 11%);  
  // border: 1px solid lighten(rgb(25,27,33), 12%); 
  border: 1px solid var(--bg-editorTimeline); 
}

// .editor-timeline-clip--render[data-clip-type='zoom'].editor-timeline-clip--inactive.editor-timeline-clip--zoomPlayActive{
//   background: lighten(rgb(25,27,33), 13%);
//   border: 1px solid var(--bg-editorTimeline)
// }

.editor-timeline-clip--render[data-clip-type='zoom'][data-clip-active='false']:hover{
  background: lighten(rgb(25,27,33), 16%);
  border: 1px solid var(--bg-editorTimeline)
}

.editor-timeline-clip--render[data-clip-type='zoom'][data-clip-active='true']{
  background: lighten(rgb(25,27,33), 22%);
  border: 1px solid var(--bg-editorTimeline)
}





// VoiceOver Animation

.editor-timeline-clip--render[data-clip-type='audio']{
  transition: opacity 150ms linear;
  opacity: 1;
}

.editor-timelineContainer[data-command-down-state='false']
.editor-timeline-clip--render[data-clip-type='audio'][data-clip-voiceover-updating='true'],
.editor-timelineContainer[data-command-down-state='true']
.editor-timeline-clip--render[data-clip-type='audio'][data-clip-voiceover-updating='true']{
  opacity: 0.5;
}

.editor-timeline-clip[data-clip-voiceover-webcam='true']{
  background: blue;
  opacity: 0.5;
  display: none;
}

//
//



//
// Inner

@import 'EditorTimeline-Clips-Inner';


//
// HANDLES

@import 'EditorTimeline-Clips-Handles';


//
// Segments

@import 'EditorTimeline-Clips-SegmentSliders';
@import 'EditorTimeline-Clips-Segments';

@import 'EditorTimeline-Clips-WebcamPhases';

.editor-timelineContainer[data-command-down-state='false']
.editor-timeline-clip--render[data-clip-type='audio'],
.editor-timelineContainer[data-command-down-state='false']
.editor-timeline-clip--render[data-clip-type='zoom']{
  transition: opacity 150ms linear;
  opacity: 1;
}

.editor-timelineContainer[data-command-down-state='true']
.editor-timeline-clip--render[data-clip-type='audio'],
.editor-timelineContainer[data-command-down-state='true']
.editor-timeline-clip--render[data-clip-type='zoom']{
  transition: opacity 150ms linear;
  opacity: 0.35 !important;
}