.dpTextColorPicker{
	width: calc(100% + 8px);
	display: flex;	
	align-items: center;
	height: $height-dpElement;
	flex-grow: 0;
	flex-shrink: 0;
}

.dpTextColorPicker-autoBtn,
.dpTextColorPicker-manualColorBtn,
.dpTextColorPicker-addColorBtn{	
	height: $height-dpElement;
	border-radius: 3px;
}

.dpButton[data-active-state='true']{
	background: var(--250gray);
}

.dpTextColorPicker-smallVSpacer{	
	flex-grow: 1;
	flex-shrink: 1;
}


.dpTextColorPicker-tinyVSpacer{
	width: 2px;
}
.dpTextColorPicker-autoBtn{
	width: 81px;
	margin-right: 2px;

	font-size: $baseFontSize-dpElement;	
	color: var(--text600);
	
  font-variation-settings: "wght" 500;
  letter-spacing: 0.008em;  
}

.dpTextColorPicker-autoBtn[data-active-state='false']{
	background: var(--252gray);
}

.dpTextColorPicker-autoBtn[data-active-state='true'],
.dpTextColorPicker-manualColorBtn[data-active-state='true']{
	background: var(--250gray);
}

.dpTextColorPicker-manualColorBtn[data-active-state='true']{
	background: var(--246gray);
}

.dpTextColorPicker-autoBtn[data-active-state='false']{
	opacity: 0.5;
}

.dpTextColorPicker-autoBtn[data-active-state='false']:hover{
	opacity: 1;
}

.dpTextColorPicker-autoBtn[data-active-state='false']:hover,
.dpTextColorPicker-manualColorBtn[data-active-state='false']:hover,
.dpTextColorPicker-addColorBtn:hover{
	background: var(--251gray);
}


.dpTextColorPicker-autoBtn-secondaryLabel{
	opacity: 0.7;
	margin-left: 4px;	
}

//


//

.dpTextColorPicker-manualColorBtn{
	width: 27px;

	flex-grow: 0;
	flex-shrink: 0;

	border-radius: 3px;
	position: relative;
}

.dpTextColorPicker-addColorBtn{
	width: 28px;
	flex-grow: 0;
	flex-shrink: 0;

}

.dpTextColorPicker-manualColorBtn-innerBorder{
	position: absolute;
	top: 3px;
	left: 4px;
	right: 4px;
	bottom: 3px;

	border-radius: 2px;
	border: 1px solid rgba(255,255,255,0.1);
}

.dpTextColorPicker-manualColorBtn{
	
}

.dpTextColorPicker-manualColorBtn-inner{
	width: 18px;
	height: 18px;
	border-radius: 2px;
	background: $tempr;
}


.dpTextColorPicker-addColorBtn svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.5;
	transform: scale(1.08);
}

.dpTextColorPicker-addColorBtn:hover svg{
	opacity: 0.8;
}