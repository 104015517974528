
.editor-slideEditor-selectionBox{  
  position: absolute;
  pointer-events: none !important;

  z-index: 2500;
}

.editor-slideEditor-selectionBox[data-selection-box='normal']{  
  outline: 2px solid $figmaBlue;
  z-index: 2600;
}

.editor-slideEditor[data-ui-color-mode='yellow']
.editor-slideEditor-selectionBox[data-selection-box='normal']{  
  outline: 2px solid $specialYellow; 
}

.editor-slideEditor-selectionBox[data-selection-box='light']{
  outline: 2px solid rgb(200,200,210,0.75); 
  z-index: 2400;
}