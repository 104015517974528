
.dpSelectBtn{
	height: $height-dpElement;
	border-radius: 3px;

	display: flex;

	background: var(--250gray);
	position: relative;
}

.dpSelectBtn-label{	
  font-size: $baseFontSize-dpElement;	
	color: var(--text500);
	
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;  

  flex-grow: 1;
  flex-shrink: 1;

  text-align: left;
  box-sizing: border-box;
  padding-left: 7px;
}

.dpSelectBtn-label[data-light-state='true']{
	opacity: 0.65;
}

.dpSelectBtn-iconContainer{
	width: 24px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;
}

.dpSelectBtn-iconContainer svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.35;
}

.dpSelectBtn:hover{
	background: rgba(244,240,255,0.065);
}

.dpSelectBtn:hover
.dpSelectBtn-iconContainer svg{
	opacity: 0.6;
}

.dpSelectMenu{	
	
	box-sizing: border-box;
	
	padding-top: 4px;
	padding-bottom: 4px;
	
	overflow: none;

	border-radius: 4px;

	border: 1px solid rgb(60,60,64);
	border-top: 1px solid rgb(74,74,78);
	border-bottom: 1px solid rgb(56,56,60);

	box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.18);
	
	z-index: 500;

	position: relative;
	
	background: lighten(rgb(30,30,31), 3%);

	// opacity: 0.5;
}


.dpSelectMenu-viewport{
	// background: $tempr;
}

.dpSelectMenu-item{
	height: 24px;
	width: calc(100% - 6px);
	margin-left: 3px;

	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-sizing: border-box;

	padding-left: 6px;

	font-size: $baseFontSize-dpElement;	
	color: var(--text500);
	
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em; 

  cursor: pointer;
  user-select: none; 

  position: relative;
}

.dpSelectMenu-item-select-innerLabel{
	flex-grow: 1;
	flex-shrink: 1;
	// background: $tempr;
	height: 100%;
	display: flex;
	align-items: center;
}

.dpSelectMenu-item-solid,
.dpSelectMenu-item-image,
.dpSelectBtn-image,
.dpSelectBtn-solid{
	position: absolute;
	top: 2px;
	height: 20px;
	width: 33px;
	border-radius: 2px;	
	left: 6px;
	background: $tempr;
	box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}

.dpSelectBtn-image,
.dpSelectBtn-solid{
	top: 3px;
	left: 7px;
}

.dpSelectMenu-item-image,
.dpSelectBtn-image{
	object-fit: cover;
	overflow: hidden;
}

.dpSelectMenu-item-image img,
.dpSelectBtn-image img{
	width: 100%;
	height: 100%;
}

.dpSelectMenu-item:focus{
	outline: none;
	border: none;
}

.dpSelectMenu-item[data-state='checked']{
	font-variation-settings: "wght" 540;
	color: var(--text600);
	background: lighten(rgb(30,30,31), 9%);	
}

.dpSelectMenu-item[data-state='unchecked']{
	opacity: 0.65;
}



.dpSelectMenu-item[data-state='unchecked']:hover{
	opacity: 0.9;
	background: lighten(rgb(30,30,31), 6%);	
}

.dpSelectMenu-item.dpSelectMenu-item--colorPicker[data-state='unchecked'],
.dpSelectMenu-item.dpSelectMenu-item--colorPicker[data-state='unchecked']:hover{
	opacity: 1;
}

//
// Audio Preview

.dpSelectBtn-audioPreview,
.dpSelectMenu-item-audioPreview{
	width: 24px;
	height: 22px;
	background: none;
	border-radius: 3px;

	opacity: 0.45;
}


.dpSelectBtn-audioPreview:hover,
.dpSelectMenu-item-audioPreview:hover{
	opacity: 1;
	background: var(--250gray);
}


.dpSelectBtn-audioPreview{
	margin-left: 2px;
	margin-right: -6px;
}

.dpSelectMenu-item-audioPreview{
	margin-left: -4px;
	margin-right: 1px;
}

.dpSelectBtn-audioPreview svg,
.dpSelectMenu-item-audioPreview svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 1;
	position: relative;	
}

.dpSelectBtn-audioPreview svg.icon--name--speaker,
.dpSelectMenu-item-audioPreview svg.icon--name--speaker{
	left: 1px;
}

.dpSelectBtn-audioPreview svg.icon--name--chunkPause,
.dpSelectMenu-item-audioPreview svg.icon--name--chunkPause{
	transform: scale(0.8);
}

