.dpButton{	
	height: $height-dpElement;
	border-radius: 3px;

	&:disabled {
		opacity: 0.5;
		pointer-events: none;
		cursor: not-allowed;
	}
}

.dpButton[data-active-state='true']{
	background: var(--250gray);
}

.dpButton-iconContainer{
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dpButton--fullWidth{
	width: 100%;
	background: var(--251gray);
}

.dpButton--iconOnly{
	padding-left: 0px;
	padding-right: 0px;
	width: $height-dpElement;
}

.dpButton--mixed{
	padding-left: 1px;
	padding-right: 10px;	
}

.editor-detailPanel-header .dpButton--mixed{
	margin-right: -6px;
}

.dpButton--detailMode{
	width: 32px;
	margin-right: -2px;
}

.dpButton--labelOnly{
	padding-left: 8px;
	padding-right: 8px;	
}

.dpButton--iconOnly
.dpButton-iconContainer{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dpButton-iconContainer svg{
	width: 14px;
	height: 14px;

	fill: var(--text500);
	opacity: 0.5;
}

.dpButton-iconContainer svg.icon--name--locked,
.dpButton-iconContainer svg.icon--name--unlocked{
	width: 14px;
	height: 14px;

	fill: var(--text500);
	opacity: 0.8;
}


.dpButton-iconContainer svg.icon--name--sliders{
	width: 18px;
	height: 18px;
	transform: scale(0.95)
}

.dpButton-iconContainer svg.icon--name--galleryDisplayList{
	width: 18px;
	height: 18px;
	transform: scale(0.95)
}

// .dpItemAnimationPanel .dpButton-iconContainer{
// 	opacity: 0.5;
// 	transition: opacity 75ms linear;
// }

// .dpItemAnimationPanel:hover .dpButton-iconContainer{
// 	opacity: 1;
// 	transition: opacity 75ms linear;
// }



.dpButton-label,
.dpButton-keyboardShortcut{	
  font-size: $baseFontSize-dpElement;	
	color: var(--text500);
	
  font-variation-settings: "wght" 480;
  letter-spacing: 0.008em;  
}


.dpButton[data-active-state='false']:hover
.dpButton-iconContainer svg{
	opacity: 0.7;
}

.dpButton[data-active-state='true']
.dpButton-iconContainer svg{
	opacity: 0.8;
}

.dpButton[data-active-state='true']:hover
.dpButton-iconContainer svg{
	opacity: 1;
}

.dpButton--backgroundImage{
	width: 32px;
}



.dpButton--strong{
	background: var(--248gray);
	opacity: 0.9;
}

.dpButton--strong:hover{
	opacity: 1;
}

.dpButton--mid[data-active-state='false']:hover{
	background: var(--250gray);
}

.dpButton--mid[data-active-state='true'],
.dpButton--mid[data-active-state='true']:hover{
	background: var(--246gray);
}


.dpButton--light{	
	opacity: 0.6;
}

.dpButton--light:hover{
	background: var(--250gray);
	opacity: 1;
}



.dpButton--backgroundImage
.dpButton-iconContainer svg{
	width: 20px;
	height: 20px;
	transform: scale(1.11);
}

.dpButton--backgroundImage
.dpButton-iconContainer svg.icon--name--variants{
	width: 18px;
	height: 18px;
}



.dpButton--backgroundImage
.dpButton-iconContainer svg #front{
	fill: none;
	stroke: var(--text500);
}

.dpButton--backgroundImage
.dpButton-iconContainer svg #back{
	opacity: 0.5;
}

.dpButton--backgroundImage[data-active-state='false']:hover
.dpButton-iconContainer svg{
	opacity: 0.85;
}

.dpButton--backgroundImage[data-active-state='true']
.dpButton-iconContainer svg,
.dpButton--backgroundImage[data-active-state='true']:hover
.dpButton-iconContainer svg{
	opacity: 1;
}

// .dpButton--backgroundImage[data-active-state='true']
// .dpButton-iconContainer svg #back,
// .dpButton--backgroundImage[data-active-state='true']:hover
// .dpButton-iconContainer svg #back{
// 	opacity: 0.7;
// }

.dpButton--backgroundImage[data-active-state='false']:hover
.dpButton-iconContainer svg #front{
	transform: scale(1.05);
	transform-origin: 50% 50%;
}

.dpButton--backgroundImage[data-active-state='true']
.dpButton-iconContainer svg{
	transform: scale(1.4);
	transform-origin: 50% 50%;
}

.dpButton--backgroundImage[data-active-state='true']
.dpButton-iconContainer svg.icon--name--variants{
	transform: scale(1);
}


.dpButton--backgroundImage[data-active-state='true']
.dpButton-iconContainer svg #back{
	opacity: 0;
}

.dpButton--backgroundImage[data-active-state='true']
.dpButton-iconContainer svg #front{
	fill: rgba(255,255,255,0.3);
}

.dpButton--listItem{
	width: 30px;
}

.dpButton--listItem[data-active-state='true'],
.dpButton--listItem[data-active-state='true']:hover{
	background: var(--246gray);
}

.dpButton--listItem[data-active-state='false']:hover
.dpButton-iconContainer svg{
	opacity: 0.8;
}

.dpButton--listItem[data-active-state='true']
.dpButton-iconContainer svg,
.dpButton--listItem[data-active-state='true']:hover
.dpButton-iconContainer svg{
	opacity: 1;
}

.dpButton--listItem
.dpButton-iconContainer svg{
	width: 16px;
	height: 16px;
	transform: scale(1.04);
}

//


.dpButton-keyboardShortcut{
	// position: absolute;
	// right: 12px;

	display: flex;
	height: 100%;
	align-items: center;
	//font-size: 14px;

	margin-left: 6px;

	opacity: 0.5;

	color: white;
}

.dpButton--addZoomBtn[data-state='pending']{
	opacity: 0.75;
	cursor: default;
}

.dpButton--addZoomBtn[data-state='exists']{
	opacity: 1;
}

.dpButton--addZoomBtn[data-state='empty']{
	opacity: 0.5;
	cursor: default;
}