
.editor-loading{
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100vw;
  height: 100vh;

  z-index: 2000;


  background: var(--bg-editor);

  display: flex;
  align-items: center;
  justify-content: center;

}


@keyframes opacityPulse {
  0%, 100% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.6;
  }
}


.editor-loading-content{
  display: flex;  

  align-items: center;
  justify-content: center;

  z-index: 5;

  column-gap: 4px;  

}

.editor-loading-bar{
  width: 10px;
  height: 24px;

  border-radius: 1px;

  background: var(--text600);
  opacity: 0.4;

  animation: opacityPulse 4s infinite; /* 2s duration is just an example. Adjust as needed */


}


.editor-loading-bar:nth-child(1) {
  animation-delay: 0s; /* Starts immediately */
}

.editor-loading-bar:nth-child(2) {
  animation-delay: 1s; /* Starts 0.5s later than the first */
}

.editor-loading-bar:nth-child(3) {
  animation-delay: 2s; /* Starts 1s later than the first */
}
