
$leftGutter-transcriptChunk: 27px;
$rightGutter-transcriptChunk: 18px;
//$leftGutter-transcriptChunk: 34px;
//$rightGutter-transcriptChunk: 22px;

$headerHeight-transcriptPanel: 50px;


.editor-transcriptPanelContainer{
  position: fixed;
  z-index: 1000;
}

$listBG-inset: 10px;

.editor-transcriptPanel{
  position: absolute;

  top: 0px;
  left: 0px;

  width: 100%;
  height: 100%;
  
  background: var(--bg-transcriptPanel);
}

@import 'EditorTranscriptPanel-TopBar';



.editor-transcriptPanel-listBG{
  pointer-events: none;

  z-index: 1;

  border-radius: var(--borderRadius-editorPanel);
  background: var(--bg-editorPanel);
  border: 1px solid var(--borderColor-editorPanel);
  //border-radius: 6px;

  //background: lighten(#151619, 3%);
  // background: lighten(#151619, 4%);
  //background: rgb(30,30,31);
  //border: 1px solid lighten(#151619, 6%);
}

//

.editor-transcriptPanel-listBG,
.editor-transcriptPanel-list{
  position: absolute;

  left: $listBG-inset;
  right: 4px;
  bottom: 9px;
}


.editor-transcriptPanel-listBG{
  top: $listBG-inset;
}

.editor-transcriptPanel-list{
  top: $listBG-inset;
  //top: $headerHeight-transcriptPanel;
}



.editor-transcriptPanel-list{
  z-index: 5;

  flex-grow: 1;
  flex-shrink: 1;  

  overflow-y: overlay;
  scrollbar-gutter: stable;
  

  display: flex;
  flex-direction: column;

  // background: $tempr;
}

.editor-transcriptPanel
.editor-transcriptPanel-list::-webkit-scrollbar-thumb{
  background-color: rgba(255,255,255,0);  
  transiton: all 50ms linear;
}

.editor-transcriptPanel:hover
.editor-transcriptPanel-list::-webkit-scrollbar-thumb{
  background-color: rgba(255,255,255,0.08);
  transiton: all 50ms linear;
}

.editor-transcriptPanel-list #editor{   
  flex-grow: 1;
  flex-shrink: 1;
  
  width: 100%;
  min-height: 10px;

  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 5;

  transition: opacity 100ms linear;

  // background: $tempb;
}

.editor-transcriptPanel--isDragging,
.editor-transcriptPanel--isDragging * {
  cursor: grabbing;
}









.editor-transcriptPanel--isDragging
.editor-transcriptPanel-list #editor{  
  opacity: 0.5;
}

.editor-transcriptPanel-list .ProseMirror{
  flex-grow: 1;
  flex-shrink: 1;
  
  width: 100%;
  min-height: 10px;

  text-align: left;  

  box-sizing: border-box;
  // padding-top: 24px;  

  // background: $tempr;
}

.editor-transcriptPanel-list .ProseMirror:focus{
  outline: none;
}

// 


@import 'EditorTranscriptPanel-Headers';

@import 'EditorTranscriptPanel-SceneHeader';
@import 'EditorTranscriptPanel-TranscriptChunks';

@import 'EditorTranscriptPanel-Handles';

@import 'EditorTranscriptPanel-VoiceDropdown';
@import 'EditorTranscriptPanel-CaptionsDropdown';

@import 'EditorTranscriptPanel-SkipMarker';


//


//
// HOVER MENU
// @import 'EditorTranscriptPanel-SelectionMenu';



//
// WEBCAM HEADERS

@import 'EditorTranscriptPanel-WebcamHeaders';



//
// SELECTIONS

.editor-transcriptPanel-transcriptGroup--cameraRecording-word::selection,
.editor-transcriptPanel-transcriptChunk-contents .doc-para::selection,
.editor-transcriptPanel-transcriptChunk-contents .ProseMirror-trailingBreak::selection{
  background: green !important;
  background: $invisible !important;
}

$vPadding-transcriptPanel-selectionRect: 2px;
$hPadding-transcriptPanel-selectionRect: 1px;

.editor-transcriptPanel-list-selectionRect{
  position: absolute;
  background: red;
  background: white;
  
  border-radius: 0px;
  pointer-events: none;
  
  z-index: 2;
}

.editor-transcriptPanel-list-selectionRect[data-selection-type='camera']{
  opacity: 0.8;
  background: lighten(desaturate(#1D282B, 5%), 16%);
}

.editor-transcriptPanel-list-selectionRect[data-selection-type='screencastLinked']{
  opacity: 0.8;
  background: lighten(desaturate(rgb(42, 39, 78), 5%), 20%);
  // background: red;
}

.editor-transcriptPanel-list-selectionRect[data-selection-type='default'],
.editor-transcriptPanel-list-selectionRect[data-selection-type='script']{
  
  background: lighten(saturate(#151619, 5%), 16%);
  // opacity: 0.2;
  // background: red;
  //background: lighten(saturate(#1D282B, 0%), 18%);
}

.editor-transcriptPanel-list-selectionRect[data-selection-type='instructions']{
  opacity: 0.8;
  background: lighten(saturate(#292522, 2%), 18%);
  //background: lighten(saturate(#1D282B, 0%), 18%);
}


//
//

@import 'EditorTranscriptPanel-VoiceHeaderGutter';
