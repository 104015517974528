:root{		
	// 


}

// DARK THEME

[data-theme="dark"] {
	// 
	
	--timelineScreenRecording-bg: rgb(110,99,217);
	--timelineScreenRecording-bg: rgba(110,99,217,0.5);
	--timelineScreenRecording-outline: #8E82FF;
	--timelineScreenRecording-outline: #7065dd;
	
	//--timelineScreenRecording-outline: darken(#8E82FF, 5%);

	--timelineCamera-bg: rgb(69,132,234);
	--timelineCamera-outline: rgb(140,182,250);
	--timelineCamera-outline: rgb(105,159,247);

	//--timelineSlides-bg: rgb(114,200,153);
	//--timelineSlides-bg: rgb(80,181,125);
	--timelineSlides-bg: rgba(80,181,125,0.6);
	// --timelineSlides-outline: rgb(150,251,196);
	// --timelineSlides-outline: rgb(113,214,159);
	--timelineSlides-outline: rgba(113,214,159,0.8);


}




