.libraryPage-sideMenu-tabContainer{
	width: 100%;
	box-sizing: border-box;
	padding-left: 14px;
	padding-right: 4px;
}

.libraryPage-sideMenu-tab{
	width: 100%;
	height: 29px;
	justify-content: flex-start;

	box-sizing: border-box;
	padding-left: 7px;

	border-radius: 4px;
	position: relative;
}

.libraryPage-sideMenu-tab-visual{
	width: 24px;
	height: 24px;

	flex-grow: 0;
	flex-shrink: 0;

	margin-right: 4px;
}

.libraryPage-sideMenu-tab-orgTabSpacer{
	width: 4px;
}

.libraryPage-sideMenu-tab-visual-iconContainer,
.libraryPage-sideMenu-tab-visual-collectionColorContainer{
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	
	
}

.libraryPage-sideMenu-tab-visual-iconContainer svg{
	width: 18px;
	height: 18px;

	fill: var(--text500);
	opacity: 0.5;
}

.libraryPage-sideMenu-tab-visual-iconContainer svg.icon--name--slackIcon{
	width: 16px;
	height: 16px;
	transform: scale(0.9);
	transform-origin: 50% 50%;
	position: relative;
	left: -0.5px;
}

.libraryPage-sideMenu-tab-label{
	font-size: 14.25px;
	font-size: 14.5px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 460;
  letter-spacing: 0.012em;

  color: var(--text600);
  text-align: left;
  flex-grow: 1;
  flex-shrink: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  box-sizing: border-box;
  padding-right: 3px;
}


.libraryPage-sideMenu-tab--orgLibraryTab
.libraryPage-sideMenu-tab-label{
	font-variation-settings: "wght" 520;	
}

.libraryPage-sideMenu-tab[data-state="active"]{
	background: var(--251gray);
}

.libraryPage-sideMenu-tab[data-state="active"]
.libraryPage-sideMenu-tab-label{
	color: var(--text600);
}

.libraryPage-sideMenu-tab--Deleted[data-state="inactive"]:not(:hover){
	opacity: 0.5;
}


.libraryPage-sideMenu-tabContainer[data-state="closed"]
.libraryPage-sideMenu-tab[data-state="inactive"]
.libraryPage-sideMenu-tab-label{
	color: var(--text600);
	opacity: 0.85;
}

.libraryPage-sideMenu-tab[data-state="inactive"]:hover{
	background: var(--252gray)
}

.libraryPage-sideMenu-tabContainer[data-state="open"]
.libraryPage-sideMenu-tab{
	background: var(--246gray) !important;	
}


.libraryPage-sideMenu-tab:disabled{
	pointer-events: none !important;
}

.libraryPage-sideMenu-tab[data-popover-state="open"]
.libraryPage-sideMenu-tab-label--untitled{
	color: var(--text200);
}


.libraryPage-sideMenu-tab-dropdownBtn{
	display: none;

	margin-left: auto;
	margin-right: 4px;
	width: 22px;
	height: 20px;
	border-radius: 3px;
	background: var(--252gray);
	
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;
}



.libraryPage-sideMenu-tab:hover
.libraryPage-sideMenu-tab-dropdownBtn{
	display: flex;
}

.libraryPage-sideMenu-tab-dropdownBtn svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.5;
}

.libraryPage-sideMenu-tab-dropdownBtn:hover{
	background: var(--248gray);
}

.libraryPage-sideMenu-tab-dropdownBtn[data-state="open"],
.libraryPage-sideMenu-tab-dropdownBtn[data-state="open"]:hover{
	background: var(--246gray);
	display: flex;
}


.libraryPage-sideMenu-tab-dropdownBtn:hover svg{
	opacity: 0.8;
}

.libraryPage-sideMenu-tab-dropdownBtn[data-state="open"] svg,
.libraryPage-sideMenu-tab-dropdownBtn[data-state="open"]:hover svg{
	opacity: 1;
}

//
//

.libraryPage-sideMenu-tab-popoverTrigger{
	position: absolute;
	top: 100%;
	left: 0px;
}

.miniPopover--editCollection{
	width: 240px;
	padding-bottom: 4px;
	// border-top-right-radius: 3px;
	// border-top-left-radius: 3px;
}

.miniPopover--editCollection-inputContainer{
	width: calc(100% - 10px);	
	margin-left: 5px;
	margin-top: 1px;
	position: relative;
	height: 27px;
}

input.miniPopover--editCollection-input{
	position: absolute;
	left: 0px;
	width: 100%;
	top: 0px;
	height: 100%;

	box-sizing: border-box;
	padding: 0px;	
	padding-left: 6px;
	letter-spacing: 0.01em;	

	color: var(--text600);
	
	font-size: 14.5px;
  font-family: system-ui, sans-serif;
  //font-variation-settings: "wght" 440;
  font-variation-settings: "wght" 460;
  letter-spacing: 0.012em;

	line-height: 1.9;
	
	user-select: none;

	display: flex;
	justify-content: flex-start;
	position: relative;


	cursor: text;

	background: none;
	border: none;
	outline: none;
	box-shadow: none;

	background: var(--250gray);
	border: 1px solid $invisible;

	border-radius: 2px;

}

input.miniPopover--editCollection-input:hover{
	background: var(--248gray);
}

input.miniPopover--editCollection-input:focus{
	background: var(--248gray);
	border: 1px solid var(--line150);
}


.miniPopover--editCollection-colorBtnCarousel{
	display: flex;	
	margin-top: 5px;
	box-sizing: border-box;
	padding-left: 5px;
	padding-right: 5px;
	margin-bottom: 2px;
}

.miniPopover--editCollection-colorBtn{
	position: relative;
	flex-grow: 1;
	flex-shrink: 1;

	height: 28px;
	position: relative;

	border-radius: 5px;
}



.miniPopover--editCollection-colorBtn-inner{
	position: absolute;
	top: 4.5px;
	left: 4.5px;
	bottom: 4.5px;
	right: 4.5px;
	border-radius: 2px;

	background: $tempr;
}


.miniPopover--editCollection-colorBtn-outline{
	position: absolute;
	top: 1.5px;
	right: 1.5px;
	left: 1.5px;
	bottom: 1.5px;

	border-radius: 4px;
}

// .miniPopover--editCollection-colorBtn[data-state="active"]{
// 	background: var(--248gray);
// }

.miniPopover--editCollection-colorBtn[data-state="active"]
.miniPopover--editCollection-colorBtn-outline{
	background: var(--248gray);
	border: 1px solid var(--line200);
}

.miniPopover--editCollection-colorBtn[data-state="inactive"]:hover
.miniPopover--editCollection-colorBtn-outline{
	background: var(--246gray);
	border: 1px solid $invisible;
}

.miniPopover--editCollection-colorBtn--blue
.miniPopover--editCollection-colorBtn-inner,
.miniPopover-item--collection--blue
.miniPopover-item--collection-bar,
.miniPopover-item--collection--blue
.miniPopover-item-checkbox[data-active-state="true"]{
	background: var(--collection-blue);
}

.miniPopover--editCollection-colorBtn--purple
.miniPopover--editCollection-colorBtn-inner,
.miniPopover-item--collection--purple
.miniPopover-item--collection-bar,
.miniPopover-item--collection--purple
.miniPopover-item-checkbox[data-active-state="true"]{
	background: var(--collection-purple);
}

.miniPopover--editCollection-colorBtn--red
.miniPopover--editCollection-colorBtn-inner,
.miniPopover-item--collection--red
.miniPopover-item--collection-bar,
.miniPopover-item--collection--red
.miniPopover-item-checkbox[data-active-state="true"]{
	background: var(--collection-red);
}

.miniPopover--editCollection-colorBtn--orange
.miniPopover--editCollection-colorBtn-inner,
.miniPopover-item--collection--orange
.miniPopover-item--collection-bar,
.miniPopover-item--collection--orange
.miniPopover-item-checkbox[data-active-state="true"]{
	background: var(--collection-orange);
}

.miniPopover--editCollection-colorBtn--green
.miniPopover--editCollection-colorBtn-inner,
.miniPopover-item--collection--green
.miniPopover-item--collection-bar,
.miniPopover-item--collection--green
.miniPopover-item-checkbox[data-active-state="true"]{
	background: var(--collection-green);
}

.miniPopover--editCollection-colorBtn--pink
.miniPopover--editCollection-colorBtn-inner,
.miniPopover-item--collection--pink
.miniPopover-item--collection-bar,
.miniPopover-item--collection--pink
.miniPopover-item-checkbox[data-active-state="true"]{
	background: var(--collection-pink);
}

.miniPopover--editCollection-colorBtn--teal
.miniPopover--editCollection-colorBtn-inner,
.miniPopover-item--collection--teal
.miniPopover-item--collection-bar,
.miniPopover-item--collection--teal
.miniPopover-item-checkbox[data-active-state="true"]{
	background: var(--collection-teal);
}

.miniPopover--editCollection-colorBtn--darkBlue
.miniPopover--editCollection-colorBtn-inner,
.miniPopover-item--collection--darkBlue
.miniPopover-item--collection-bar,
.miniPopover-item--collection--darkBlue
.miniPopover-item-checkbox[data-active-state="true"]{
	background: var(--collection-darkBlue);
}


.libraryPage-sideMenu-tab-visual-collectionColorContainer--blue svg{
	fill: var(--collection-blue);
}

.libraryPage-sideMenu-tab-visual-collectionColorContainer--purple svg{
	fill: var(--collection-purple);
}

.libraryPage-sideMenu-tab-visual-collectionColorContainer--red svg{
	fill: var(--collection-red);
}

.libraryPage-sideMenu-tab-visual-collectionColorContainer--orange svg{
	fill: var(--collection-orange);
}

.libraryPage-sideMenu-tab-visual-collectionColorContainer--green svg{
	fill: var(--collection-green);
}

.libraryPage-sideMenu-tab-visual-collectionColorContainer--pink svg{
	fill: var(--collection-pink);
}

.libraryPage-sideMenu-tab-visual-collectionColorContainer--teal svg{
	fill: var(--collection-teal);
}

.libraryPage-sideMenu-tab-visual-collectionColorContainer--darkBlue svg{
	fill: var(--collection-darkBlue);
}

.miniPopover-item--collection--blue
.miniPopover-item-checkbox{
	border-color: var(--collection-blue);
}

.miniPopover-item--collection--purple
.miniPopover-item-checkbox{
	border-color: var(--collection-purple);
}

.miniPopover-item--collection--red
.miniPopover-item-checkbox{
	border-color: var(--collection-red);
}

.miniPopover-item--collection--orange
.miniPopover-item-checkbox{
	border-color: var(--collection-orange);
}

.miniPopover-item--collection--green
.miniPopover-item-checkbox{
	border-color: var(--collection-green);
}

.miniPopover-item--collection--pink
.miniPopover-item-checkbox{
	border-color: var(--collection-pink);
}

.miniPopover-item--collection--teal
.miniPopover-item-checkbox{
	border-color: var(--collection-teal);
}

.miniPopover-item--collection--darkBlue
.miniPopover-item-checkbox{
	border-color: var(--collection-darkBlue);
}