
.editor-slideEditor-textAreaContainer{
  position: absolute;
  z-index: 900;  
  
}

.editor-slideEditor-textAreaContainer--draggable,
.editor-slideEditor-textAreaContainer--draggable *{
  pointer-events: none !important; // temporary
}
// .editor-slideEditor-textAreaContainer{
//   outline: 5px solid red;
// }

// .editor-slideEditor-draggableItem{
//   outline: 15px solid green;
// }

.editor-slideEditor-draggableItem--unfocusable
.editor-slideEditor-textAreaContainer,
.editor-slideEditor-draggableItem--unfocusable
.editor-slideEditor-textAreaContainer *{
  pointer-events: none !important;
}

.editor-slideEditor[data-resizing-element='true']
.editor-slideEditor-textAreaContainer,
.editor-slideEditor[data-resizing-element='true']
.editor-slideEditor-textAreaContainer *,
.editor-slideEditor[data-resizing-element='true']
.editor-slideEditor-textAreaContainer
.ProseMirror .text-slide-para::selection,
.editor[data-dragging-to-reorder='true']
.editor-slideEditor-textAreaContainer,
.editor[data-dragging-to-reorder='true']
.editor-slideEditor-textAreaContainer *{
  pointer-events: none !important;
}


.editor-slideEditor-draggableItem--focused
.editor-slideEditor-textAreaContainer[data-focused-state='true']{
  cursor: text;
}

.editor-slideEditor-textArea{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;  
  z-index: 2;  
}

.editor-slideEditor-textArea[data-hide-selections='true']
.ProseMirror .text-slide-para::selection{
  background-color: $invisible !important;
}

.editor-slideEditor-textArea
.editor-slideEditor-PMContainer{
  height: 100%;
  
}

.editor-slideEditor-textArea
.ProseMirror{
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.editor-slideEditor-textArea[data-h-align='left'] .ProseMirror{  
  align-items: flex-start;
  text-align: left;
}

.editor-slideEditor-textArea[data-h-align='center'] .ProseMirror{  
  align-items: center;
  text-align: center;
}

.editor-slideEditor-textArea[data-h-align='right'] .ProseMirror{  
  align-items: flex-end;
  text-align: right;  
}

.editor-slideEditor-textArea[data-h-align='left'][data-resize-type='autoWidth'] .ProseMirror,
.editor-slideEditor-textArea[data-h-align='center'][data-resize-type='autoWidth'] .ProseMirror,
.editor-slideEditor-textArea[data-h-align='right'][data-resize-type='autoWidth'] .ProseMirror{  
  align-items: flex-start;
  text-align: left;
  width: calc(100% + 120px);
}

.editor-slideEditor-PMContainer
.ProseMirror-focused{
  outline: none;
  cursor: text;
}


.editor-slideEditor-textArea .ProseMirror-selected,
.editor-slideEditor-textArea .ProseMirror *::selection {
  //background-color: #BAD6FB !important;
  //background-color: #5D8ECF !important;
  background-color: rgba(0, 110, 255, 0.2) !important;
}

.editor-slideEditor[data-dragging-element='true'] .editor-slideEditor-textArea .ProseMirror-selected,
.editor-slideEditor[data-dragging-element='true'] .editor-slideEditor-textArea .ProseMirror *::selection {
  background-color: $invisible !important;  
}

.editor-textSlide-textArea #text_slide_pm_input{  
  width: 100%;  
  box-sizing: border-box;
}

.editor-slideEditor-textArea .ProseMirror{
  border-radius: 4px;
  font-feature-settings: 'liga' 1; // ff and things like that  
  box-sizing: border-box;

  // padding-left: 30px;
  // padding-right: 30px;
  overflow-wrap: break-word;
  word-break: break-word;

  display: flex;
  flex-direction: column;
}

.editor-slideEditor-textArea[data-show-ligatures='false'] .ProseMirror{
  font-feature-settings: 'liga' 0;
}

.editor-slideEditor-textArea
.ProseMirror .text-slide-para{
  color: $invisible; // we only show text on canvas  
  // color: red;
  max-width: 100%; // Ensure it doesn't exceed parent width
  overflow-wrap: break-word;
  word-break: break-word;
  width: 100%;
}

.editor-slideEditor-textArea,
.editor-slideEditor-textArea *{  
  caret-color: $figmaBlue !important;
}

.editor-slideEditor[data-ui-color-mode='yellow'] .editor-slideEditor-textArea,
.editor-slideEditor[data-ui-color-mode='yellow'] .editor-slideEditor-textArea *{  
  caret-color: $specialYellow !important;
}



.editor-slideEditor-textArea
.variable-highlight{
  opacity: 0.5;
}

.editor-slideEditor-textArea
.selection-span{
  //background: #C3D7FF;  
  background-color: #A8C1FA !important;
  //background-color: invisible !important;
}


.editor-slideEditor-textArea[data-variable-text-no-wrap='true']{
  // outline: 2px solid green;
}

.editor-slideEditor-textArea[data-variable-text-no-wrap='true']
.ProseMirror{  
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.editor-slideEditor-textArea[data-variable-text-no-wrap='true']
.editor-slideEditor-PMContainer{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.editor-slideEditor-textArea[data-variable-text-no-wrap='true']
.ProseMirror
.text-slide-para{
  position: absolute;
  top: 0px;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  // outline: 2px solid red;
}

// .editor-slideEditor-textArea[data-variable-text-no-wrap='true']
// .ProseMirror{
//   display: flex;
//   align-items: flex-start;
// }

// .text-slide-para{
//   text-align: top;
// }

